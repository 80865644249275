import React from 'react';

import { Container } from 'react-bootstrap';
import { useFeature } from 'flagged';

import { Header, Footer, BootingWrapper, BootstrapWrapper } from '@shared';
import { FeatureFlag } from '@enums';

import useGlobalState from '../../useGlobalStore';

type Props = {
  children: React.ReactNode;
};

const Landing: React.FC<Props> = (props) => {
  const { children } = props;
  const portalConfig = useGlobalState((state) => state.portalConfig);
  const widgetLandingPageFeatureFlag = useFeature(FeatureFlag.useWidgetLandingPage);
  const widgetShowsLandingPage = widgetLandingPageFeatureFlag && !portalConfig?.minimalLayout;
  // INFO  when feature flag is removed we will only check for minimalLayout, no longer for portalConfig header and footer

  return (
    <BootingWrapper>
      {(portalConfig?.header || widgetShowsLandingPage) && (
        <BootstrapWrapper>
          <Container className="bg-white">
            <Header />
          </Container>
        </BootstrapWrapper>
      )}
      {children}
      {(portalConfig?.footer || widgetShowsLandingPage) && (
        <BootstrapWrapper>
          <Footer />
        </BootstrapWrapper>
      )}
    </BootingWrapper>
  );
};

export default Landing;
