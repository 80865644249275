import { useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Destructor = () => void | any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Deps = any[];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useOnDepsChange = (effect: () => void | Destructor, deps: Deps) => {
  const [firstRender, setFirstRender] = useState(false);

  useEffect(() => {
    if (!firstRender) {
      setFirstRender(true);
    } else {
      const umount = effect();
      return () => umount && umount();
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useOnDepsChange;
