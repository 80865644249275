import { useFormContext } from 'react-hook-form';

import { BookingFormTitle, BookingInquiryHash, BookingInputName } from '@enums';
import { FormBlockDto, OfferDtoMethodEnum } from '@api/booking';
import useStore from '@pages/Booking/zustand';

export const useBookingShouldRenderBlock = (formBlock: FormBlockDto): boolean => {
  const { watch } = useFormContext();

  const paymentMethodsValues = useStore((state) => state.paymentMethodsValues);

  const valuesWithSepa = paymentMethodsValues.find(
    (i) => i?.value === OfferDtoMethodEnum.DirectDebit
  );

  switch (formBlock.name) {
    case BookingFormTitle.BankDetails:
      return !!valuesWithSepa;
    case BookingFormTitle.ConfigureCustomerEmailDocs:
    case BookingFormTitle.ConfigureCustomerEmailInsurerDocs: {
      const watchedFieldName = `${BookingInquiryHash.General}[${BookingInputName.SendCustomerEmail}]`;
      return watch(watchedFieldName);
    }
    default:
      return true;
  }
};

export default useBookingShouldRenderBlock;
