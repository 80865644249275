import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { API, helpers } from '@helpers';
import { ApiQuery } from '@enums';

export interface PoolDesign {
  poolId: number;
  poolTag: string;
  primaryColor: string;
  secondaryColor: string;
  thirdColor: string;
  fourthColor: string;
  fifthColor: string;
  bodyBgColor: string;
  mainFontFamily: string;
  landingPageBodyBackgroundColor: string;
  landingPageHeaderBoxBackgroundColor: string;
  landingPageHeaderBoxTextColor: string;
  headerContactHoverColor: string;
  faviconUrl: string;
}

const usePoolDesign = (
  poolId?: number,
  options: UseQueryOptions<PoolDesign[], Error> = {}
): QueryObserverResult<PoolDesign[], Error> => {
  return useQuery<PoolDesign[], Error>(
    [ApiQuery.PoolDesign, poolId],
    async () => {
      const response = await API.get<PoolDesign[]>(
        helpers.isWidget
          ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1/pools/designs.json?pool_id=${poolId}`
          : `/api/e2e/v1/pools/designs.json?pool_id=${poolId}`
      );

      return response;
    },
    {
      ...options,
      enabled: (options.enabled ?? true) && typeof poolId === 'number',
    }
  );
};

export default usePoolDesign;
