import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useGlobalState } from '@hooks';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const { brokerSettings } = useGlobalState();
  const currentYear = new Date().getFullYear();
  if (!brokerSettings) {
    return null;
  }
  return (
    <Container fluid as="footer" className="bg-color-primary footer">
      <Container>
        <Row>
          <Col className="text-right text-white" data-qa="page-footer-copyright">
            {brokerSettings?.pool?.company} {t('general.copyright')} {currentYear}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
