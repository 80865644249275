/* tslint:disable */
/* eslint-disable */
/**
 * QuestionAnswer API
 * QuestionAnswer API provides data relevant for coverage suite management.
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * CategoryError is an Error Representation object
 * @export
 * @interface CategoryError
 */
export interface CategoryError {
    /**
     * 
     * @type {string}
     * @memberof CategoryError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof CategoryError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface CategoryUpdateQuestionAndAnswer
 */
export interface CategoryUpdateQuestionAndAnswer {
    /**
     * 
     * @type {string}
     * @memberof CategoryUpdateQuestionAndAnswer
     */
    answerId?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryUpdateQuestionAndAnswer
     */
    answerText?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryUpdateQuestionAndAnswer
     */
    questionId: string;
}
/**
 * CategoryUpdateQuestionsAndAnswersRequest request for update
 * @export
 * @interface CategoryUpdateQuestionsAndAnswersRequest
 */
export interface CategoryUpdateQuestionsAndAnswersRequest {
    /**
     * CategoryUpdateQuestionsAndAnswers slice of CategoryUpdateQuestionAndAnswer
     * @type {Array<CategoryUpdateQuestionAndAnswer>}
     * @memberof CategoryUpdateQuestionsAndAnswersRequest
     */
    answers?: Array<CategoryUpdateQuestionAndAnswer>;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryUpdateQuestionsAndAnswersRequest
     */
    ignoreInvalidData?: boolean;
}
/**
 * 
 * @export
 * @interface Condition
 */
export interface Condition {
    /**
     * in: query
     * @type {string}
     * @memberof Condition
     */
    error: string;
    /**
     * in: query
     * @type {string}
     * @memberof Condition
     */
    id: string;
    /**
     * in: query
     * @type {string}
     * @memberof Condition
     */
    itemId: string;
    /**
     * in: query
     * @type {string}
     * @memberof Condition
     */
    name: string;
    /**
     * 
     * @type {Array<Rule>}
     * @memberof Condition
     */
    rules: Array<Rule>;
    /**
     * in: query
     * @type {string}
     * @memberof Condition
     */
    type?: string;
}
/**
 * Document document
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * version
     * @type {number}
     * @memberof Document
     */
    __v?: number;
    /**
     * MongoDB ObjectId
     * @type {string}
     * @memberof Document
     */
    _id: string;
    /**
     * created date
     * @type {string}
     * @memberof Document
     */
    created?: string;
    /**
     * extension
     * @type {string}
     * @memberof Document
     */
    extension: string;
    /**
     * use for the name file stored in S3
     * @type {string}
     * @memberof Document
     */
    hash?: string;
    /**
     * is deleted
     * @type {boolean}
     * @memberof Document
     */
    isDeleted?: boolean;
    /**
     * mime type
     * @type {string}
     * @memberof Document
     */
    mimeType: string;
    /**
     * name
     * @type {string}
     * @memberof Document
     */
    name: string;
    /**
     * path
     * @type {string}
     * @memberof Document
     */
    path: string;
    /**
     * status
     * @type {number}
     * @memberof Document
     */
    status?: number;
    /**
     * document type
     * @type {string}
     * @memberof Document
     */
    type?: string;
    /**
     * url
     * @type {string}
     * @memberof Document
     */
    url?: string;
}
/**
 * EmbeddedQuestionAnswerTeasers is a Representation object of QuestionAnswerTeasers
 * @export
 * @interface EmbeddedQuestionAnswerTeasers
 */
export interface EmbeddedQuestionAnswerTeasers {
    /**
     * QuestionsAnswers represents slice of QuestionAnswer
     * @type {Array<QuestionAnswerSetQuestionAnswer>}
     * @memberof EmbeddedQuestionAnswerTeasers
     */
    sets: Array<QuestionAnswerSetQuestionAnswer>;
}
/**
 * FileImportTemplate is a struct that describes file import template data
 * @export
 * @interface FileImportTemplate
 */
export interface FileImportTemplate {
    /**
     * 
     * @type {string}
     * @memberof FileImportTemplate
     */
    extension?: string;
    /**
     * 
     * @type {string}
     * @memberof FileImportTemplate
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof FileImportTemplate
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FileImportTemplate
     */
    name?: string;
}
/**
 * GroupError is an Error Representation object
 * @export
 * @interface GroupError
 */
export interface GroupError {
    /**
     * 
     * @type {string}
     * @memberof GroupError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof GroupError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface GroupInsertCategory
 */
export interface GroupInsertCategory {
    /**
     * 
     * @type {number}
     * @memberof GroupInsertCategory
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupInsertCategory
     */
    categoryId: string;
    /**
     * 
     * @type {string}
     * @memberof GroupInsertCategory
     */
    fileID?: string;
}
/**
 * 
 * @export
 * @interface GroupUpdateQuestionAndAnswer
 */
export interface GroupUpdateQuestionAndAnswer {
    /**
     * 
     * @type {string}
     * @memberof GroupUpdateQuestionAndAnswer
     */
    answerId?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupUpdateQuestionAndAnswer
     */
    answerText?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupUpdateQuestionAndAnswer
     */
    questionId: string;
}
/**
 * GroupUpdateQuestionsAndAnswersRequest request for update
 * @export
 * @interface GroupUpdateQuestionsAndAnswersRequest
 */
export interface GroupUpdateQuestionsAndAnswersRequest {
    /**
     * GroupUpdateQuestionsAndAnswers slice of GroupUpdateQuestionAndAnswer
     * @type {Array<GroupUpdateQuestionAndAnswer>}
     * @memberof GroupUpdateQuestionsAndAnswersRequest
     */
    answers?: Array<GroupUpdateQuestionAndAnswer>;
    /**
     * 
     * @type {boolean}
     * @memberof GroupUpdateQuestionsAndAnswersRequest
     */
    ignoreInvalidData?: boolean;
}
/**
 * QuestionAnswerError is an Error Representation object
 * @export
 * @interface QuestionAnswerError
 */
export interface QuestionAnswerError {
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof QuestionAnswerError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * QuestionAnswerList is a Representation object
 * @export
 * @interface QuestionAnswerList
 */
export interface QuestionAnswerList {
    /**
     * 
     * @type {EmbeddedQuestionAnswerTeasers}
     * @memberof QuestionAnswerList
     */
    _embedded: EmbeddedQuestionAnswerTeasers;
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerList
     */
    total: number;
}
/**
 * Category is dto for Category
 * @export
 * @interface QuestionAnswerSetCategory
 */
export interface QuestionAnswerSetCategory {
    /**
     * Categories are dto for Categories
     * @type {Array<QuestionAnswerSetCategory>}
     * @memberof QuestionAnswerSetCategory
     */
    children: Array<QuestionAnswerSetCategory>;
    /**
     * 
     * @type {FileImportTemplate}
     * @memberof QuestionAnswerSetCategory
     */
    fileImportTemplate?: FileImportTemplate;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetCategory
     */
    fillingStatus: QuestionAnswerSetCategoryFillingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetCategory
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerSetCategory
     */
    index: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetCategory
     */
    inquiryHash?: string;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionAnswerSetCategory
     */
    isTemplate: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetCategory
     */
    mappingType?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetCategory
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetCategory
     */
    originalId: string;
    /**
     * Questions is dto for Questions
     * @type {Array<QuestionAnswerSetQuestion>}
     * @memberof QuestionAnswerSetCategory
     */
    questions: Array<QuestionAnswerSetQuestion>;
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerSetCategory
     */
    rank: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetCategory
     */
    type: QuestionAnswerSetCategoryTypeEnum;
    /**
     * 
     * @type {Array<Condition>}
     * @memberof QuestionAnswerSetCategory
     */
    visibilityConditions: Array<Condition>;
}

/**
    * @export
    * @enum {string}
    */
export enum QuestionAnswerSetCategoryFillingStatusEnum {
    Full = 'full',
    Half = 'half',
    Empty = 'empty'
}
/**
    * @export
    * @enum {string}
    */
export enum QuestionAnswerSetCategoryTypeEnum {
    Object = 'object',
    Simple = 'simple'
}

/**
 * ContentBlock is dto for ContentBlock
 * @export
 * @interface QuestionAnswerSetContentBlock
 */
export interface QuestionAnswerSetContentBlock {
    /**
     * 
     * @type {Array<QuestionAnswerSetContentBlock>}
     * @memberof QuestionAnswerSetContentBlock
     */
    children: Array<QuestionAnswerSetContentBlock>;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetContentBlock
     */
    id: string;
    /**
     * Questions is dto for Questions
     * @type {Array<QuestionAnswerSetQuestion>}
     * @memberof QuestionAnswerSetContentBlock
     */
    questions: Array<QuestionAnswerSetQuestion>;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetContentBlock
     */
    title: string;
}
/**
 * Group is dto for Group
 * @export
 * @interface QuestionAnswerSetGroup
 */
export interface QuestionAnswerSetGroup {
    /**
     * Categories are dto for Categories
     * @type {Array<QuestionAnswerSetCategory>}
     * @memberof QuestionAnswerSetGroup
     */
    categories: Array<QuestionAnswerSetCategory>;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetGroup
     */
    fillingStatus: QuestionAnswerSetGroupFillingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetGroup
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetGroup
     */
    inquiryHash?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetGroup
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetGroup
     */
    originalId: string;
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerSetGroup
     */
    rank: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetGroup
     */
    type: QuestionAnswerSetGroupTypeEnum;
    /**
     * 
     * @type {Array<Condition>}
     * @memberof QuestionAnswerSetGroup
     */
    visibilityConditions: Array<Condition>;
}

/**
    * @export
    * @enum {string}
    */
export enum QuestionAnswerSetGroupFillingStatusEnum {
    Full = 'full',
    Half = 'half',
    Empty = 'empty'
}
/**
    * @export
    * @enum {string}
    */
export enum QuestionAnswerSetGroupTypeEnum {
    Object = 'object',
    Simple = 'simple',
    Mixed = 'mixed'
}

/**
 * PossibleAnswer is dto for PossibleAnswer
 * @export
 * @interface QuestionAnswerSetPossibleAnswer
 */
export interface QuestionAnswerSetPossibleAnswer {
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetPossibleAnswer
     */
    DefaultValue: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetPossibleAnswer
     */
    ID: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetPossibleAnswer
     */
    OriginalID: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetPossibleAnswer
     */
    Text: string;
}
/**
 * SelectedAnswer is dto for SelectedAnswer
 * @export
 * @interface QuestionAnswerSetPossibleSelectedAnswer
 */
export interface QuestionAnswerSetPossibleSelectedAnswer {
    /**
     * 
     * @type {Document}
     * @memberof QuestionAnswerSetPossibleSelectedAnswer
     */
    FileInfo?: Document;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetPossibleSelectedAnswer
     */
    ID: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetPossibleSelectedAnswer
     */
    OriginalID: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetPossibleSelectedAnswer
     */
    Text: string;
}
/**
 * 
 * @export
 * @interface QuestionAnswerSetProfessionAlias
 */
export interface QuestionAnswerSetProfessionAlias {
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetProfessionAlias
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionAnswerSetProfessionAlias
     */
    isSuggested?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetProfessionAlias
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetProfessionAlias
     */
    originalID: string;
}
/**
 * Question is dto for Question
 * @export
 * @interface QuestionAnswerSetQuestion
 */
export interface QuestionAnswerSetQuestion {
    /**
     * 
     * @type {boolean}
     * @memberof QuestionAnswerSetQuestion
     */
    acceptNoAnswer: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionAnswerSetQuestion
     */
    answered: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestion
     */
    defaultValue: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestion
     */
    dinQuestionCode: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestion
     */
    fieldType: QuestionAnswerSetQuestionFieldTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestion
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestion
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestion
     */
    originalId: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestion
     */
    parentOriginalId: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestion
     */
    placeholder?: string;
    /**
     * 
     * @type {Array<QuestionAnswerSetPossibleAnswer>}
     * @memberof QuestionAnswerSetQuestion
     */
    possibleAnswers: Array<QuestionAnswerSetPossibleAnswer>;
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerSetQuestion
     */
    rank: number;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionAnswerSetQuestion
     */
    readOnly: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestion
     */
    recommendedAnswer?: string;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionAnswerSetQuestion
     */
    required?: boolean;
    /**
     * 
     * @type {Array<QuestionAnswerSetPossibleSelectedAnswer>}
     * @memberof QuestionAnswerSetQuestion
     */
    selectedAnswers: Array<QuestionAnswerSetPossibleSelectedAnswer>;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestion
     */
    text: string;
    /**
     * 
     * @type {Array<Condition>}
     * @memberof QuestionAnswerSetQuestion
     */
    validations: Array<Condition>;
    /**
     * 
     * @type {ViewSettings}
     * @memberof QuestionAnswerSetQuestion
     */
    viewSettings?: ViewSettings;
    /**
     * 
     * @type {Array<Condition>}
     * @memberof QuestionAnswerSetQuestion
     */
    visibilityConditions: Array<Condition>;
}

/**
    * @export
    * @enum {string}
    */
export enum QuestionAnswerSetQuestionFieldTypeEnum {
    Checkbox = 'checkbox',
    Checkboxlist = 'checkboxlist',
    Radio = 'radio',
    Textarea = 'textarea',
    Input = 'input',
    String = 'string',
    Date = 'date',
    Integer = 'integer',
    Boolean = 'boolean',
    Currency = 'currency',
    File = 'file',
    Select = 'select',
    InsurerList = 'insurerList',
    ProfessionEntity = 'professionEntity',
    Api = 'api'
}

/**
 * 
 * @export
 * @interface QuestionAnswerSetQuestionAnswer
 */
export interface QuestionAnswerSetQuestionAnswer {
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    accountId: string;
    /**
     * 
     * @type {{ [key: string]: Array<QuestionAnswerSetPossibleSelectedAnswer>; }}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    answerMap?: { [key: string]: Array<QuestionAnswerSetPossibleSelectedAnswer>; };
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    baseObjectID: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    baseObjectType: QuestionAnswerSetQuestionAnswerBaseObjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    fillingStatus: QuestionAnswerSetQuestionAnswerFillingStatusEnum;
    /**
     * 
     * @type {Array<QuestionAnswerSetGroup>}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    groups: Array<QuestionAnswerSetGroup>;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    inquiryHash?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    inquiryId?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    origin: QuestionAnswerSetQuestionAnswerOriginEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    predecessorId: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    productName: string;
    /**
     * ProfessionAliases represents slice of ProfessionAlias
     * @type {Array<QuestionAnswerSetProfessionAlias>}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    professionAliases: Array<QuestionAnswerSetProfessionAlias>;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    status: QuestionAnswerSetQuestionAnswerStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    updateAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum QuestionAnswerSetQuestionAnswerBaseObjectTypeEnum {
    Inquiry = 'inquiry',
    Consultation = 'consultation',
    DinAnalysis = 'din_analysis'
}
/**
    * @export
    * @enum {string}
    */
export enum QuestionAnswerSetQuestionAnswerFillingStatusEnum {
    Full = 'full',
    Half = 'half',
    Empty = 'empty'
}
/**
    * @export
    * @enum {string}
    */
export enum QuestionAnswerSetQuestionAnswerOriginEnum {
    RiskAssessment = 'riskAssessment',
    RiskAnalysis = 'riskAnalysis',
    ContentAnalysis = 'contentAnalysis',
    BuildingAnalysis = 'buildingAnalysis',
    Tendering = 'tendering',
    Booking = 'booking',
    NormedRiskAnalysis = 'normedRiskAnalysis'
}
/**
    * @export
    * @enum {string}
    */
export enum QuestionAnswerSetQuestionAnswerStatusEnum {
    IsLocked = 'isLocked',
    IsEditable = 'isEditable',
    IsCompleted = 'isCompleted'
}

/**
 * 
 * @export
 * @interface QuestionAnswerSetSaveQuestionAnswer
 */
export interface QuestionAnswerSetSaveQuestionAnswer {
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetSaveQuestionAnswer
     */
    accountId: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetSaveQuestionAnswer
     */
    baseObjectID?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetSaveQuestionAnswer
     */
    baseObjectType?: QuestionAnswerSetSaveQuestionAnswerBaseObjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetSaveQuestionAnswer
     */
    inquiryHash?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetSaveQuestionAnswer
     */
    inquiryId?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetSaveQuestionAnswer
     */
    origin: QuestionAnswerSetSaveQuestionAnswerOriginEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetSaveQuestionAnswer
     */
    productId?: string;
    /**
     * ProfessionAliases represents slice of ProfessionAlias
     * @type {Array<QuestionAnswerSetProfessionAlias>}
     * @memberof QuestionAnswerSetSaveQuestionAnswer
     */
    professionAliases?: Array<QuestionAnswerSetProfessionAlias>;
    /**
     * 
     * @type {QuestionParameters}
     * @memberof QuestionAnswerSetSaveQuestionAnswer
     */
    questionParameters?: QuestionParameters;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetSaveQuestionAnswer
     */
    title?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum QuestionAnswerSetSaveQuestionAnswerBaseObjectTypeEnum {
    Inquiry = 'inquiry',
    Consultation = 'consultation',
    DinAnalysis = 'din_analysis'
}
/**
    * @export
    * @enum {string}
    */
export enum QuestionAnswerSetSaveQuestionAnswerOriginEnum {
    RiskAssessment = 'riskAssessment',
    RiskAnalysis = 'riskAnalysis',
    ContentAnalysis = 'contentAnalysis',
    BuildingAnalysis = 'buildingAnalysis',
    Tendering = 'tendering',
    Booking = 'booking',
    NormedRiskAnalysis = 'normedRiskAnalysis'
}

/**
 * 
 * @export
 * @interface QuestionParameters
 */
export interface QuestionParameters {
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestionParameters
     */
    questionGroupsIDs?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestionParameters
     */
    riskGroupsIDs?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestionParameters
     */
    selectedCalculators?: Array<string>;
}
/**
 * 
 * @export
 * @interface QuestionnaireDocumentRequest
 */
export interface QuestionnaireDocumentRequest {
    /**
     * QuestionAnswerIDs represents the list of QASet IDs
     * @type {Array<string>}
     * @memberof QuestionnaireDocumentRequest
     */
    questionAnswerIDs: Array<string>;
}
/**
 * 
 * @export
 * @interface Rule
 */
export interface Rule {
    /**
     * 
     * @type {string}
     * @memberof Rule
     */
    conditionOperator: string;
    /**
     * 
     * @type {string}
     * @memberof Rule
     */
    expression: string;
    /**
     * 
     * @type {number}
     * @memberof Rule
     */
    group?: number;
    /**
     * 
     * @type {string}
     * @memberof Rule
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Rule
     */
    linkOperator: string;
    /**
     * 
     * @type {number}
     * @memberof Rule
     */
    rank?: number;
    /**
     * 
     * @type {string}
     * @memberof Rule
     */
    type: string;
}
/**
 * 
 * @export
 * @interface UpdateStatusQuestionAnswer
 */
export interface UpdateStatusQuestionAnswer {
    /**
     * 
     * @type {string}
     * @memberof UpdateStatusQuestionAnswer
     */
    status: UpdateStatusQuestionAnswerStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateStatusQuestionAnswerStatusEnum {
    IsLocked = 'isLocked',
    IsEditable = ' isEditable',
    IsCompleted = ' isCompleted'
}

/**
 * ViewSettings is dto for ViewSettings
 * @export
 * @interface ViewSettings
 */
export interface ViewSettings {
    /**
     * 
     * @type {string}
     * @memberof ViewSettings
     */
    calculation?: string;
    /**
     * 
     * @type {string}
     * @memberof ViewSettings
     */
    date?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ViewSettings
     */
    decimal?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ViewSettings
     */
    step?: number;
}

/**
 * CategoryApi - axios parameter creator
 * @export
 */
export const CategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byCategoryIDContentRequest: async (categoryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('byCategoryIDContentRequest', 'categoryId', categoryId)
            const localVarPath = `/category/{categoryId}/contentArea`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byCategoryIDQuestionsRequest: async (categoryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('byCategoryIDQuestionsRequest', 'categoryId', categoryId)
            const localVarPath = `/categories/{categoryId}/questions`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryDeleteRequest: async (categoryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('categoryDeleteRequest', 'categoryId', categoryId)
            const localVarPath = `/categories/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {CategoryUpdateQuestionsAndAnswersRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryUpdateQuestionsAndAnswersRequest: async (categoryId: string, body: CategoryUpdateQuestionsAndAnswersRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('categoryUpdateQuestionsAndAnswersRequest', 'categoryId', categoryId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('categoryUpdateQuestionsAndAnswersRequest', 'body', body)
            const localVarPath = `/categories/{categoryId}/questionAndAnswers`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {CategoryUpdateQuestionsAndAnswersRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryValidateAnswersRequest: async (categoryId: string, body: CategoryUpdateQuestionsAndAnswersRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('categoryValidateAnswersRequest', 'categoryId', categoryId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('categoryValidateAnswersRequest', 'body', body)
            const localVarPath = `/categories/{categoryId}/validateAnswers`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoryApi - functional programming interface
 * @export
 */
export const CategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async byCategoryIDContentRequest(categoryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuestionAnswerSetContentBlock>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.byCategoryIDContentRequest(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async byCategoryIDQuestionsRequest(categoryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuestionAnswerSetQuestion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.byCategoryIDQuestionsRequest(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryDeleteRequest(categoryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionAnswerSetQuestionAnswer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoryDeleteRequest(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {CategoryUpdateQuestionsAndAnswersRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryUpdateQuestionsAndAnswersRequest(categoryId: string, body: CategoryUpdateQuestionsAndAnswersRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionAnswerSetQuestionAnswer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoryUpdateQuestionsAndAnswersRequest(categoryId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {CategoryUpdateQuestionsAndAnswersRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryValidateAnswersRequest(categoryId: string, body: CategoryUpdateQuestionsAndAnswersRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryError>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoryValidateAnswersRequest(categoryId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoryApi - factory interface
 * @export
 */
export const CategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoryApiFp(configuration)
    return {
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byCategoryIDContentRequest(categoryId: string, options?: any): AxiosPromise<Array<QuestionAnswerSetContentBlock>> {
            return localVarFp.byCategoryIDContentRequest(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byCategoryIDQuestionsRequest(categoryId: string, options?: any): AxiosPromise<Array<QuestionAnswerSetQuestion>> {
            return localVarFp.byCategoryIDQuestionsRequest(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryDeleteRequest(categoryId: string, options?: any): AxiosPromise<QuestionAnswerSetQuestionAnswer> {
            return localVarFp.categoryDeleteRequest(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {CategoryUpdateQuestionsAndAnswersRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryUpdateQuestionsAndAnswersRequest(categoryId: string, body: CategoryUpdateQuestionsAndAnswersRequest, options?: any): AxiosPromise<QuestionAnswerSetQuestionAnswer> {
            return localVarFp.categoryUpdateQuestionsAndAnswersRequest(categoryId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {CategoryUpdateQuestionsAndAnswersRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryValidateAnswersRequest(categoryId: string, body: CategoryUpdateQuestionsAndAnswersRequest, options?: any): AxiosPromise<CategoryError> {
            return localVarFp.categoryValidateAnswersRequest(categoryId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoryApi - object-oriented interface
 * @export
 * @class CategoryApi
 * @extends {BaseAPI}
 */
export class CategoryApi extends BaseAPI {
    /**
     * 
     * @param {string} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public byCategoryIDContentRequest(categoryId: string, options?: any) {
        return CategoryApiFp(this.configuration).byCategoryIDContentRequest(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public byCategoryIDQuestionsRequest(categoryId: string, options?: any) {
        return CategoryApiFp(this.configuration).byCategoryIDQuestionsRequest(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoryDeleteRequest(categoryId: string, options?: any) {
        return CategoryApiFp(this.configuration).categoryDeleteRequest(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} categoryId 
     * @param {CategoryUpdateQuestionsAndAnswersRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoryUpdateQuestionsAndAnswersRequest(categoryId: string, body: CategoryUpdateQuestionsAndAnswersRequest, options?: any) {
        return CategoryApiFp(this.configuration).categoryUpdateQuestionsAndAnswersRequest(categoryId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} categoryId 
     * @param {CategoryUpdateQuestionsAndAnswersRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoryValidateAnswersRequest(categoryId: string, body: CategoryUpdateQuestionsAndAnswersRequest, options?: any) {
        return CategoryApiFp(this.configuration).categoryValidateAnswersRequest(categoryId, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentsApi - axios parameter creator
 * @export
 */
export const DocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {QuestionnaireDocumentRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postQuestionnaireDocumentRequest: async (body?: QuestionnaireDocumentRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents/questionnaire`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentsApi - functional programming interface
 * @export
 */
export const DocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {QuestionnaireDocumentRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postQuestionnaireDocumentRequest(body?: QuestionnaireDocumentRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postQuestionnaireDocumentRequest(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentsApi - factory interface
 * @export
 */
export const DocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentsApiFp(configuration)
    return {
        /**
         * 
         * @param {QuestionnaireDocumentRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postQuestionnaireDocumentRequest(body?: QuestionnaireDocumentRequest, options?: any): AxiosPromise<any> {
            return localVarFp.postQuestionnaireDocumentRequest(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentsApi - object-oriented interface
 * @export
 * @class DocumentsApi
 * @extends {BaseAPI}
 */
export class DocumentsApi extends BaseAPI {
    /**
     * 
     * @param {QuestionnaireDocumentRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public postQuestionnaireDocumentRequest(body?: QuestionnaireDocumentRequest, options?: any) {
        return DocumentsApiFp(this.configuration).postQuestionnaireDocumentRequest(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GroupApi - axios parameter creator
 * @export
 */
export const GroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byGroupIDContentRequest: async (groupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('byGroupIDContentRequest', 'groupId', groupId)
            const localVarPath = `/groups/{groupId}/contentArea`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byGroupIDQuestionsRequest: async (groupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('byGroupIDQuestionsRequest', 'groupId', groupId)
            const localVarPath = `/groups/{groupId}/categoriesAndQuestions`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} groupId 
         * @param {Array<GroupInsertCategory>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupInsertCategoriesRequest: async (groupId: string, body: Array<GroupInsertCategory>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('groupInsertCategoriesRequest', 'groupId', groupId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('groupInsertCategoriesRequest', 'body', body)
            const localVarPath = `/groups/{groupId}/categories`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} groupId 
         * @param {GroupUpdateQuestionsAndAnswersRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupUpdateQuestionsAndAnswersRequest: async (groupId: string, body: GroupUpdateQuestionsAndAnswersRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('groupUpdateQuestionsAndAnswersRequest', 'groupId', groupId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('groupUpdateQuestionsAndAnswersRequest', 'body', body)
            const localVarPath = `/groups/{groupId}/questionAndAnswers`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} groupId 
         * @param {GroupUpdateQuestionsAndAnswersRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupValidateAnswersRequest: async (groupId: string, body: GroupUpdateQuestionsAndAnswersRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('groupValidateAnswersRequest', 'groupId', groupId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('groupValidateAnswersRequest', 'body', body)
            const localVarPath = `/groups/{groupId}/validateAnswers`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupApi - functional programming interface
 * @export
 */
export const GroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async byGroupIDContentRequest(groupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuestionAnswerSetContentBlock>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.byGroupIDContentRequest(groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async byGroupIDQuestionsRequest(groupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuestionAnswerSetCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.byGroupIDQuestionsRequest(groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} groupId 
         * @param {Array<GroupInsertCategory>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupInsertCategoriesRequest(groupId: string, body: Array<GroupInsertCategory>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionAnswerSetQuestionAnswer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupInsertCategoriesRequest(groupId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} groupId 
         * @param {GroupUpdateQuestionsAndAnswersRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupUpdateQuestionsAndAnswersRequest(groupId: string, body: GroupUpdateQuestionsAndAnswersRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionAnswerSetQuestionAnswer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupUpdateQuestionsAndAnswersRequest(groupId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} groupId 
         * @param {GroupUpdateQuestionsAndAnswersRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupValidateAnswersRequest(groupId: string, body: GroupUpdateQuestionsAndAnswersRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupError>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupValidateAnswersRequest(groupId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupApi - factory interface
 * @export
 */
export const GroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupApiFp(configuration)
    return {
        /**
         * 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byGroupIDContentRequest(groupId: string, options?: any): AxiosPromise<Array<QuestionAnswerSetContentBlock>> {
            return localVarFp.byGroupIDContentRequest(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byGroupIDQuestionsRequest(groupId: string, options?: any): AxiosPromise<Array<QuestionAnswerSetCategory>> {
            return localVarFp.byGroupIDQuestionsRequest(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} groupId 
         * @param {Array<GroupInsertCategory>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupInsertCategoriesRequest(groupId: string, body: Array<GroupInsertCategory>, options?: any): AxiosPromise<QuestionAnswerSetQuestionAnswer> {
            return localVarFp.groupInsertCategoriesRequest(groupId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} groupId 
         * @param {GroupUpdateQuestionsAndAnswersRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupUpdateQuestionsAndAnswersRequest(groupId: string, body: GroupUpdateQuestionsAndAnswersRequest, options?: any): AxiosPromise<QuestionAnswerSetQuestionAnswer> {
            return localVarFp.groupUpdateQuestionsAndAnswersRequest(groupId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} groupId 
         * @param {GroupUpdateQuestionsAndAnswersRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupValidateAnswersRequest(groupId: string, body: GroupUpdateQuestionsAndAnswersRequest, options?: any): AxiosPromise<GroupError> {
            return localVarFp.groupValidateAnswersRequest(groupId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupApi - object-oriented interface
 * @export
 * @class GroupApi
 * @extends {BaseAPI}
 */
export class GroupApi extends BaseAPI {
    /**
     * 
     * @param {string} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public byGroupIDContentRequest(groupId: string, options?: any) {
        return GroupApiFp(this.configuration).byGroupIDContentRequest(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public byGroupIDQuestionsRequest(groupId: string, options?: any) {
        return GroupApiFp(this.configuration).byGroupIDQuestionsRequest(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} groupId 
     * @param {Array<GroupInsertCategory>} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public groupInsertCategoriesRequest(groupId: string, body: Array<GroupInsertCategory>, options?: any) {
        return GroupApiFp(this.configuration).groupInsertCategoriesRequest(groupId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} groupId 
     * @param {GroupUpdateQuestionsAndAnswersRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public groupUpdateQuestionsAndAnswersRequest(groupId: string, body: GroupUpdateQuestionsAndAnswersRequest, options?: any) {
        return GroupApiFp(this.configuration).groupUpdateQuestionsAndAnswersRequest(groupId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} groupId 
     * @param {GroupUpdateQuestionsAndAnswersRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public groupValidateAnswersRequest(groupId: string, body: GroupUpdateQuestionsAndAnswersRequest, options?: any) {
        return GroupApiFp(this.configuration).groupValidateAnswersRequest(groupId, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QuestionAnswerApi - axios parameter creator
 * @export
 */
export const QuestionAnswerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byOriginQuestionAnswerRequest: async (inquiryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryId' is not null or undefined
            assertParamExists('byOriginQuestionAnswerRequest', 'inquiryId', inquiryId)
            const localVarPath = `/sets/latest/{inquiryId}`
                .replace(`{${"inquiryId"}}`, encodeURIComponent(String(inquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} setId 
         * @param {boolean} [showFull] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bySetIDQuestionAnswerRequest: async (setId: string, showFull?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'setId' is not null or undefined
            assertParamExists('bySetIDQuestionAnswerRequest', 'setId', setId)
            const localVarPath = `/sets/{setId}`
                .replace(`{${"setId"}}`, encodeURIComponent(String(setId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (showFull !== undefined) {
                localVarQueryParameter['showFull'] = showFull;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} setId 
         * @param {QuestionParameters} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneQuestionAnswerRequest: async (setId: string, body?: QuestionParameters, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'setId' is not null or undefined
            assertParamExists('cloneQuestionAnswerRequest', 'setId', setId)
            const localVarPath = `/sets/{setId}/clone`
                .replace(`{${"setId"}}`, encodeURIComponent(String(setId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} baseObjectIds 
         * @param {string} baseObjectType 
         * @param {boolean} eagerLoad 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latestQuestionAnswerRequest: async (baseObjectIds: Array<string>, baseObjectType: string, eagerLoad: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseObjectIds' is not null or undefined
            assertParamExists('latestQuestionAnswerRequest', 'baseObjectIds', baseObjectIds)
            // verify required parameter 'baseObjectType' is not null or undefined
            assertParamExists('latestQuestionAnswerRequest', 'baseObjectType', baseObjectType)
            // verify required parameter 'eagerLoad' is not null or undefined
            assertParamExists('latestQuestionAnswerRequest', 'eagerLoad', eagerLoad)
            const localVarPath = `/sets/latest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (baseObjectIds) {
                localVarQueryParameter['baseObjectIds'] = baseObjectIds.join(COLLECTION_FORMATS.csv);
            }

            if (baseObjectType !== undefined) {
                localVarQueryParameter['baseObjectType'] = baseObjectType;
            }

            if (eagerLoad !== undefined) {
                localVarQueryParameter['eagerLoad'] = eagerLoad;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {QuestionAnswerSetSaveQuestionAnswer} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionAnswerRequest: async (body?: QuestionAnswerSetSaveQuestionAnswer, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} setId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionAnswerSubmitRequest: async (setId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'setId' is not null or undefined
            assertParamExists('questionAnswerSubmitRequest', 'setId', setId)
            const localVarPath = `/sets/{setId}/submit`
                .replace(`{${"setId"}}`, encodeURIComponent(String(setId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} setId 
         * @param {UpdateStatusQuestionAnswer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionAnswerUpdateStatusRequest: async (setId: string, body: UpdateStatusQuestionAnswer, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'setId' is not null or undefined
            assertParamExists('questionAnswerUpdateStatusRequest', 'setId', setId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('questionAnswerUpdateStatusRequest', 'body', body)
            const localVarPath = `/sets/{setId}/statuses`
                .replace(`{${"setId"}}`, encodeURIComponent(String(setId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [filterIDs] 
         * @param {string} [filterInquiryId] 
         * @param {string} [filterInquiryHash] 
         * @param {string} [filterBaseObjectId] 
         * @param {'inquiry' | 'consultation' | 'din_analysis'} [filterBaseObjectType] 
         * @param {'riskAnalysis' | 'contentAnalysis' | 'buildingAnalysis' | 'riskAssessment' | 'tendering' | 'booking' | 'normedRiskAnalysis'} [filterOrigin] 
         * @param {'isLocked' | 'isEditable' | 'isCompleted'} [filterStatus] 
         * @param {string} [filterRelatedId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {'asc' | 'desc'} [orderCreateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchQuestionAnswerRequest: async (filterIDs?: Array<string>, filterInquiryId?: string, filterInquiryHash?: string, filterBaseObjectId?: string, filterBaseObjectType?: 'inquiry' | 'consultation' | 'din_analysis', filterOrigin?: 'riskAnalysis' | 'contentAnalysis' | 'buildingAnalysis' | 'riskAssessment' | 'tendering' | 'booking' | 'normedRiskAnalysis', filterStatus?: 'isLocked' | 'isEditable' | 'isCompleted', filterRelatedId?: string, limit?: number, offset?: number, noPagination?: boolean, orderCreateTime?: 'asc' | 'desc', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterIDs) {
                localVarQueryParameter['filter[IDs]'] = filterIDs.join(COLLECTION_FORMATS.csv);
            }

            if (filterInquiryId !== undefined) {
                localVarQueryParameter['filter[inquiryId]'] = filterInquiryId;
            }

            if (filterInquiryHash !== undefined) {
                localVarQueryParameter['filter[inquiryHash]'] = filterInquiryHash;
            }

            if (filterBaseObjectId !== undefined) {
                localVarQueryParameter['filter[baseObjectId]'] = filterBaseObjectId;
            }

            if (filterBaseObjectType !== undefined) {
                localVarQueryParameter['filter[baseObjectType]'] = filterBaseObjectType;
            }

            if (filterOrigin !== undefined) {
                localVarQueryParameter['filter[origin]'] = filterOrigin;
            }

            if (filterStatus !== undefined) {
                localVarQueryParameter['filter[status]'] = filterStatus;
            }

            if (filterRelatedId !== undefined) {
                localVarQueryParameter['filter[relatedId]'] = filterRelatedId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (noPagination !== undefined) {
                localVarQueryParameter['noPagination'] = noPagination;
            }

            if (orderCreateTime !== undefined) {
                localVarQueryParameter['order[createTime]'] = orderCreateTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionAnswerApi - functional programming interface
 * @export
 */
export const QuestionAnswerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionAnswerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async byOriginQuestionAnswerRequest(inquiryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuestionAnswerSetQuestionAnswer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.byOriginQuestionAnswerRequest(inquiryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} setId 
         * @param {boolean} [showFull] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bySetIDQuestionAnswerRequest(setId: string, showFull?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionAnswerSetQuestionAnswer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bySetIDQuestionAnswerRequest(setId, showFull, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} setId 
         * @param {QuestionParameters} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cloneQuestionAnswerRequest(setId: string, body?: QuestionParameters, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionAnswerSetQuestionAnswer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cloneQuestionAnswerRequest(setId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} baseObjectIds 
         * @param {string} baseObjectType 
         * @param {boolean} eagerLoad 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latestQuestionAnswerRequest(baseObjectIds: Array<string>, baseObjectType: string, eagerLoad: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuestionAnswerSetQuestionAnswer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latestQuestionAnswerRequest(baseObjectIds, baseObjectType, eagerLoad, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {QuestionAnswerSetSaveQuestionAnswer} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionAnswerRequest(body?: QuestionAnswerSetSaveQuestionAnswer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionAnswerSetQuestionAnswer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionAnswerRequest(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} setId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionAnswerSubmitRequest(setId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionAnswerSubmitRequest(setId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} setId 
         * @param {UpdateStatusQuestionAnswer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionAnswerUpdateStatusRequest(setId: string, body: UpdateStatusQuestionAnswer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionAnswerUpdateStatusRequest(setId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [filterIDs] 
         * @param {string} [filterInquiryId] 
         * @param {string} [filterInquiryHash] 
         * @param {string} [filterBaseObjectId] 
         * @param {'inquiry' | 'consultation' | 'din_analysis'} [filterBaseObjectType] 
         * @param {'riskAnalysis' | 'contentAnalysis' | 'buildingAnalysis' | 'riskAssessment' | 'tendering' | 'booking' | 'normedRiskAnalysis'} [filterOrigin] 
         * @param {'isLocked' | 'isEditable' | 'isCompleted'} [filterStatus] 
         * @param {string} [filterRelatedId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {'asc' | 'desc'} [orderCreateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchQuestionAnswerRequest(filterIDs?: Array<string>, filterInquiryId?: string, filterInquiryHash?: string, filterBaseObjectId?: string, filterBaseObjectType?: 'inquiry' | 'consultation' | 'din_analysis', filterOrigin?: 'riskAnalysis' | 'contentAnalysis' | 'buildingAnalysis' | 'riskAssessment' | 'tendering' | 'booking' | 'normedRiskAnalysis', filterStatus?: 'isLocked' | 'isEditable' | 'isCompleted', filterRelatedId?: string, limit?: number, offset?: number, noPagination?: boolean, orderCreateTime?: 'asc' | 'desc', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionAnswerList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchQuestionAnswerRequest(filterIDs, filterInquiryId, filterInquiryHash, filterBaseObjectId, filterBaseObjectType, filterOrigin, filterStatus, filterRelatedId, limit, offset, noPagination, orderCreateTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuestionAnswerApi - factory interface
 * @export
 */
export const QuestionAnswerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionAnswerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byOriginQuestionAnswerRequest(inquiryId: string, options?: any): AxiosPromise<Array<QuestionAnswerSetQuestionAnswer>> {
            return localVarFp.byOriginQuestionAnswerRequest(inquiryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} setId 
         * @param {boolean} [showFull] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bySetIDQuestionAnswerRequest(setId: string, showFull?: boolean, options?: any): AxiosPromise<QuestionAnswerSetQuestionAnswer> {
            return localVarFp.bySetIDQuestionAnswerRequest(setId, showFull, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} setId 
         * @param {QuestionParameters} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneQuestionAnswerRequest(setId: string, body?: QuestionParameters, options?: any): AxiosPromise<QuestionAnswerSetQuestionAnswer> {
            return localVarFp.cloneQuestionAnswerRequest(setId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} baseObjectIds 
         * @param {string} baseObjectType 
         * @param {boolean} eagerLoad 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latestQuestionAnswerRequest(baseObjectIds: Array<string>, baseObjectType: string, eagerLoad: boolean, options?: any): AxiosPromise<Array<QuestionAnswerSetQuestionAnswer>> {
            return localVarFp.latestQuestionAnswerRequest(baseObjectIds, baseObjectType, eagerLoad, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {QuestionAnswerSetSaveQuestionAnswer} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionAnswerRequest(body?: QuestionAnswerSetSaveQuestionAnswer, options?: any): AxiosPromise<QuestionAnswerSetQuestionAnswer> {
            return localVarFp.questionAnswerRequest(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} setId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionAnswerSubmitRequest(setId: string, options?: any): AxiosPromise<void> {
            return localVarFp.questionAnswerSubmitRequest(setId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} setId 
         * @param {UpdateStatusQuestionAnswer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionAnswerUpdateStatusRequest(setId: string, body: UpdateStatusQuestionAnswer, options?: any): AxiosPromise<void> {
            return localVarFp.questionAnswerUpdateStatusRequest(setId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [filterIDs] 
         * @param {string} [filterInquiryId] 
         * @param {string} [filterInquiryHash] 
         * @param {string} [filterBaseObjectId] 
         * @param {'inquiry' | 'consultation' | 'din_analysis'} [filterBaseObjectType] 
         * @param {'riskAnalysis' | 'contentAnalysis' | 'buildingAnalysis' | 'riskAssessment' | 'tendering' | 'booking' | 'normedRiskAnalysis'} [filterOrigin] 
         * @param {'isLocked' | 'isEditable' | 'isCompleted'} [filterStatus] 
         * @param {string} [filterRelatedId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {'asc' | 'desc'} [orderCreateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchQuestionAnswerRequest(filterIDs?: Array<string>, filterInquiryId?: string, filterInquiryHash?: string, filterBaseObjectId?: string, filterBaseObjectType?: 'inquiry' | 'consultation' | 'din_analysis', filterOrigin?: 'riskAnalysis' | 'contentAnalysis' | 'buildingAnalysis' | 'riskAssessment' | 'tendering' | 'booking' | 'normedRiskAnalysis', filterStatus?: 'isLocked' | 'isEditable' | 'isCompleted', filterRelatedId?: string, limit?: number, offset?: number, noPagination?: boolean, orderCreateTime?: 'asc' | 'desc', options?: any): AxiosPromise<QuestionAnswerList> {
            return localVarFp.searchQuestionAnswerRequest(filterIDs, filterInquiryId, filterInquiryHash, filterBaseObjectId, filterBaseObjectType, filterOrigin, filterStatus, filterRelatedId, limit, offset, noPagination, orderCreateTime, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestionAnswerApi - object-oriented interface
 * @export
 * @class QuestionAnswerApi
 * @extends {BaseAPI}
 */
export class QuestionAnswerApi extends BaseAPI {
    /**
     * 
     * @param {string} inquiryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionAnswerApi
     */
    public byOriginQuestionAnswerRequest(inquiryId: string, options?: any) {
        return QuestionAnswerApiFp(this.configuration).byOriginQuestionAnswerRequest(inquiryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} setId 
     * @param {boolean} [showFull] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionAnswerApi
     */
    public bySetIDQuestionAnswerRequest(setId: string, showFull?: boolean, options?: any) {
        return QuestionAnswerApiFp(this.configuration).bySetIDQuestionAnswerRequest(setId, showFull, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} setId 
     * @param {QuestionParameters} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionAnswerApi
     */
    public cloneQuestionAnswerRequest(setId: string, body?: QuestionParameters, options?: any) {
        return QuestionAnswerApiFp(this.configuration).cloneQuestionAnswerRequest(setId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} baseObjectIds 
     * @param {string} baseObjectType 
     * @param {boolean} eagerLoad 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionAnswerApi
     */
    public latestQuestionAnswerRequest(baseObjectIds: Array<string>, baseObjectType: string, eagerLoad: boolean, options?: any) {
        return QuestionAnswerApiFp(this.configuration).latestQuestionAnswerRequest(baseObjectIds, baseObjectType, eagerLoad, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {QuestionAnswerSetSaveQuestionAnswer} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionAnswerApi
     */
    public questionAnswerRequest(body?: QuestionAnswerSetSaveQuestionAnswer, options?: any) {
        return QuestionAnswerApiFp(this.configuration).questionAnswerRequest(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} setId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionAnswerApi
     */
    public questionAnswerSubmitRequest(setId: string, options?: any) {
        return QuestionAnswerApiFp(this.configuration).questionAnswerSubmitRequest(setId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} setId 
     * @param {UpdateStatusQuestionAnswer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionAnswerApi
     */
    public questionAnswerUpdateStatusRequest(setId: string, body: UpdateStatusQuestionAnswer, options?: any) {
        return QuestionAnswerApiFp(this.configuration).questionAnswerUpdateStatusRequest(setId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [filterIDs] 
     * @param {string} [filterInquiryId] 
     * @param {string} [filterInquiryHash] 
     * @param {string} [filterBaseObjectId] 
     * @param {'inquiry' | 'consultation' | 'din_analysis'} [filterBaseObjectType] 
     * @param {'riskAnalysis' | 'contentAnalysis' | 'buildingAnalysis' | 'riskAssessment' | 'tendering' | 'booking' | 'normedRiskAnalysis'} [filterOrigin] 
     * @param {'isLocked' | 'isEditable' | 'isCompleted'} [filterStatus] 
     * @param {string} [filterRelatedId] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {boolean} [noPagination] 
     * @param {'asc' | 'desc'} [orderCreateTime] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionAnswerApi
     */
    public searchQuestionAnswerRequest(filterIDs?: Array<string>, filterInquiryId?: string, filterInquiryHash?: string, filterBaseObjectId?: string, filterBaseObjectType?: 'inquiry' | 'consultation' | 'din_analysis', filterOrigin?: 'riskAnalysis' | 'contentAnalysis' | 'buildingAnalysis' | 'riskAssessment' | 'tendering' | 'booking' | 'normedRiskAnalysis', filterStatus?: 'isLocked' | 'isEditable' | 'isCompleted', filterRelatedId?: string, limit?: number, offset?: number, noPagination?: boolean, orderCreateTime?: 'asc' | 'desc', options?: any) {
        return QuestionAnswerApiFp(this.configuration).searchQuestionAnswerRequest(filterIDs, filterInquiryId, filterInquiryHash, filterBaseObjectId, filterBaseObjectType, filterOrigin, filterStatus, filterRelatedId, limit, offset, noPagination, orderCreateTime, options).then((request) => request(this.axios, this.basePath));
    }
}


