import React, { useState } from 'react';

import { FormLabel, InlineLoading } from '@developers/graphite/carbon-components';
import { Editor } from '@tinymce/tinymce-react';
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
  UseControllerOptions,
} from 'react-hook-form';
import classnames from 'classnames';

import { ToolbarOption } from '@enums';
import { helpers } from '@helpers';

import { getPlugins } from './toolbarConfig';

// TODO: import { settings } from 'carbon-components'; and get prefix from settings once moved to GDS
const { prefix } = { prefix: 'bx' }; // settings;

interface Props {
  labelText?: string;
  placeholder?: string;
  name: string;
  rows?: number;
  control: Control;
  onBlur?: () => void;
  toolbar?: string | string[];
  defaultValue?: string;
  rules?: UseControllerOptions['rules'];
  errors?: DeepMap<FieldValues, FieldError>;
}

const metaPlugins = ['paste', 'autoresize', 'noneditable'];

const defaultToolbar = `${ToolbarOption.bold} ${ToolbarOption.italic} ${ToolbarOption.underline} | ${ToolbarOption.bullist} ${ToolbarOption.numlist}`;

const supportedLanguages = ['en', 'de', 'fr'];
const fallbackLanguage = 'en';

const EditableTextareaField: React.FC<Props> = ({
  labelText,
  placeholder = '',
  name,
  control,
  onBlur,
  rows = 1,
  toolbar = defaultToolbar,
  defaultValue = '',
  rules,
  errors,
}) => {
  const [showLoading, setShowLoading] = useState(true);

  const editorClass = classnames(`rows-${rows}`, {
    'hide-editor': showLoading,
  });

  const plugins = getPlugins(toolbar);

  const language = helpers.getLocale();

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ onChange, value }) => (
          <div className={`editable-area ${prefix}--form-item`} data-qa="editable-textarea-field">
            <FormLabel>{labelText || ''}</FormLabel>
            {showLoading && <InlineLoading />}
            <div className={editorClass}>
              <Editor
                init={{
                  toolbar,
                  plugins: [...metaPlugins, ...plugins],
                  menubar: false,
                  table_use_colgroups: true,
                  placeholder,
                  autoresize_bottom_margin: 0,
                  autoresize_overflow_padding: 10,
                  language: supportedLanguages.includes(language) ? language : fallbackLanguage,
                  content_css: '/css/editable-textarea.css',
                  statusbar: false,
                  entity_encoding: 'raw',
                  invalid_elements: 'h1,h2,h3',
                }}
                onEditorChange={(content: string) => onChange(content)}
                value={value}
                onBlur={() => onBlur && onBlur()}
                onInit={() => setShowLoading(false)}
              />
            </div>
          </div>
        )}
      />
      {errors && errors[name] && (
        <div className="pt-2 editable-area__error-message">{errors[name].message}</div>
      )}
    </>
  );
};

export default EditableTextareaField;
