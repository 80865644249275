export enum Mode {
  Production = 'production',
  Development = 'development',
}

export enum HttpStatusCode {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  NotAcceptable = 406,
  Gone = 410,
  Locked = 423,
  ServiceUnavailable = 503,
}

export enum HttpHeader {
  ContentType = 'content-type',
  Accept = 'accept',
}

export enum AxiosResponseType {
  ArrayBuffer = 'arraybuffer',
  Document = 'document',
  Json = 'json',
  Text = 'text',
  Stream = 'stream',
  Blob = 'blob',
}

export enum ContentType {
  Pdf = 'application/pdf',
  Json = 'application/json',
  Png = 'image/png',
  Jpeg = 'image/jpeg',
  Docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  Xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export enum FileExtension {
  Pdf = '.pdf',
  Json = '.json',
  Png = '.png',
  Jpeg = '.jpeg',
  Jpg = '.jpg',
  Docx = '.docx',
  Xlsx = '.xlsx',
}

export enum Protocol {
  http = 'http',
  https = 'https',
}
