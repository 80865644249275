import { setupDateHelpers } from '@helpers/dateHelpers';

import { setup as setupNumeral } from './numeral.setup';

const setup = (locale: string): void => {
  setupNumeral(locale);
  setupDateHelpers(locale);
};

export default setup;
