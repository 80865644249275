import React from 'react';

import { GdsPortal } from '@developers/graphite';

import { InfoHeaderButtonsContainerId } from './InfoHeader';

const InfoHeaderButtonsPortal: React.FC = ({ children }) => {
  return <GdsPortal id={InfoHeaderButtonsContainerId}>{children}</GdsPortal>;
};

export default InfoHeaderButtonsPortal;
