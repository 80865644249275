import React, { useState } from 'react';

import {
  Button,
  ComposedModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Tag,
  Row,
  Column,
  Grid,
  Toggle,
} from '@developers/graphite/carbon-components';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { HttpStatusCode } from '@developers/toolbox';

import { dissmisalModalCalculatorsService } from '@services/offer';
import { inquiriesService } from '@services/consultation';
import { Loader } from '@shared';
import { useMainState } from '@hooks';

interface Props {
  open: boolean;
  toggleComposeModal: (arg: boolean) => void;
}

const DismissalReasonsModal: React.FC<Props> = ({ open, toggleComposeModal }) => {
  const { t } = useTranslation();
  const [filterCalculator, setFilterCalculator] = useState(false);
  const { inquiryData } = useMainState();

  const { data: offerGroupDetails } = inquiriesService.useGetOfferGroup(inquiryData?.id.toString());

  const { data: contentCalculators } =
    dissmisalModalCalculatorsService.useDissmisalModalCalculators(offerGroupDetails?.id as string, {
      onError: (error) => {
        if (
          !error.response?.status ||
          ![HttpStatusCode.NotAcceptable, HttpStatusCode.NotFound].includes(error.response?.status)
        ) {
          toast.info(`${t('apiErrorMessage')}`);
        }
      },
    });

  return (
    <ComposedModal
      open={open}
      preventCloseOnClickOutside={true}
      onClose={() => toggleComposeModal(false)}
      size="md"
      className="dismissal-modal"
    >
      <ModalHeader title={t('result.dismissalReasons')} />
      <ModalBody id="modal-body">
        {contentCalculators ? (
          <Grid className="grid">
            <div className="calculators-grid">
              <div className="toggle-small">
                <Toggle
                  size="sm"
                  id="toggle-1"
                  className="toggle-button"
                  labelA=""
                  labelB=""
                  onClick={() => setFilterCalculator(!filterCalculator)}
                />
                <div className="toggle-label">{t('result.dismissalToggleLabel')} </div>
              </div>
              {contentCalculators._embedded?.insurers?.map((item) => {
                return (
                  <div
                    className={classNames('calculators-cell', {
                      'filter-calculations': filterCalculator && item?.hasOffer,
                    })}
                    key={item.id}
                  >
                    <Column>
                      <Row>
                        <img className="dismissal-modal__logo" src={item?.logo} alt={item?.name} />
                        <div className="calculator-tag-section">
                          <div className="calculator-tag">
                            {item?.hasOffer === false && (
                              <Tag type="red" title={t('result.dismissalRedTag')}>
                                {t('result.dismissalRedTag')}
                              </Tag>
                            )}
                            {item?.hasOffer && (
                              <Tag type="green" title={t('result.dismissalGreenTag')}>
                                {t('result.dismissalGreenTag')}
                              </Tag>
                            )}
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <div className="item-name"> {item?.name}</div>
                      </Row>
                      <Row>
                        <div className="item-reason"> {item?.reasons[0]?.text}</div>
                      </Row>
                    </Column>
                  </div>
                );
              })}
            </div>
          </Grid>
        ) : (
          <Loader />
        )}
      </ModalBody>

      <ModalFooter>
        <Button onClick={() => toggleComposeModal(false)} kind="primary">
          {t('general.close')}
        </Button>
      </ModalFooter>
    </ComposedModal>
  );
};

export default DismissalReasonsModal;
