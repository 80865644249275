import React from 'react';

import { Close16, Checkmark16, CarbonIconType } from '@developers/graphite/carbon-icons';

import { defineIconColor } from '@helpers';
import { IconType } from '@enums';

interface Components {
  [key: string]: CarbonIconType;
}
const components: Components = {
  close: Close16,
  check: Checkmark16,
};

interface Props {
  iconType: string | null;
  className?: string;
  size?: number;
}

const DefinedIcon: React.FC<Props> = ({ iconType, className, size = 16 }) => {
  if (!iconType) return null;
  const color = defineIconColor(iconType);
  const SpecificIcon = components[iconType.replace(IconType.CheckAveragePart, '')];
  return <SpecificIcon className={className} fill={color} width={size} height={size} />;
};

export default DefinedIcon;
