import React, { useState } from 'react';

import { Checkbox, TooltipIcon } from '@developers/graphite/carbon-components';
import { Information16 } from '@developers/graphite/carbon-icons';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import useStore from '@pages/Result/zustand';
import { Insurer, SalesCampaign, Tariff } from '@typings/resultPage';
import { comparisonDataService } from '@services/offer';
import { inquiriesService } from '@services/consultation';
import { TenderingOfferTag } from '@shared';
import { useMainState } from '@hooks';
import { helpers } from '@helpers';

type ComparisonOffers = {
  offer: {
    id: string;
  };
};

type Props = {
  id: string;
  insurer: Insurer;
  tariff: Tariff;
  salesCampaign: SalesCampaign | null;
  changeCheckedItemsFunc: (arg1: string) => void;
  checkedItems: string[];
  comparisonOffers?: ComparisonOffers[];
  offerType: string;
  offerExpiration: string;
};

const ResultOfferName: React.FC<Props> = ({
  id,
  salesCampaign,
  insurer,
  tariff,
  changeCheckedItemsFunc,
  checkedItems,
  offerType,
  offerExpiration,
}) => {
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();
  const { inquiryData } = useMainState();
  const store = useStore();

  const { data: offerGroupDetails } = inquiriesService.useGetOfferGroup(inquiryData?.id.toString());

  const addOfferForComparison = inquiriesService.useAddOfferForComparison();
  const removeOfferForComparison = comparisonDataService.useRemoveOfferForComparison();
  const isExpiredOffer = helpers.checkIsOfferExpired(offerExpiration);
  const isDisabled = (checkedItems.length > 2 && !checked) || isExpiredOffer;

  const addOffer = () => {
    inquiryData &&
      addOfferForComparison.mutate(
        { offerId: id, inquiryId: inquiryData?.id.toString() },
        {
          onSuccess: () => {
            store.setIsSuccessAddRemoveComparison(true);
          },
        }
      );
  };

  const removeOffer = () => {
    removeOfferForComparison.mutate(
      { id: id as string, offerId: offerGroupDetails?.id as string },
      {
        onSuccess: () => {
          store.setIsSuccessAddRemoveComparison(true);
        },
      }
    );
  };

  const selectOfferCheckboxChangeHandler = (value: boolean) => {
    changeCheckedItemsFunc(id);
    setChecked(value);
    store.setIsSuccessAddRemoveComparison(false);
    value ? addOffer() : removeOffer();
  };

  const showSalesCampaign = salesCampaign?.text && salesCampaign?.text !== '';

  return (
    <div className="offer-name">
      {showSalesCampaign && (
        <div className="offer-name__sales">
          {salesCampaign?.text}
          {salesCampaign?.tooltip && (
            <TooltipIcon tooltipText={salesCampaign.tooltip} direction="right">
              <Information16 />
            </TooltipIcon>
          )}
        </div>
      )}
      <div
        className={classNames({
          'mt-3': salesCampaign,
        })}
      >
        {offerType === 'tenderingOffer' && <TenderingOfferTag />}
        <img className="offer-name__logo" src={insurer.logo} alt={insurer.name} />
        <div className="offer-name__company mt-2">{insurer.name}</div>
        <div className="offer-name__tariff">{tariff.name}</div>
      </div>
      <div className="mt-3">
        <Checkbox
          data-qa="select-offer"
          labelText={`${t('result.chooseLabel')}`}
          id={`result-offer-${id}`}
          className="d-print-none"
          disabled={isDisabled}
          onChange={(value: boolean) => {
            selectOfferCheckboxChangeHandler(value);
          }}
        />
      </div>
    </div>
  );
};

export default ResultOfferName;
