import { flatten, uniq } from 'lodash';

import { ToolbarOption } from '@enums';

const toolbarConfig: {
  [key in ToolbarOption]?: string[];
} = {
  [ToolbarOption.bullist]: ['advlist', 'lists'],
  [ToolbarOption.numlist]: ['advlist', 'lists'],
  [ToolbarOption.table]: ['table'],
};

export const getPlugins = (toolbar: string | string[]): string[] => {
  const toolbarArray = typeof toolbar === 'string' ? [toolbar] : toolbar;

  const splitedToolbar = flatten(toolbarArray).map((toolbarStr) => toolbarStr.trim().split(' '));

  const pluginsArray = flatten(splitedToolbar).reduce(
    (array: (string | string[])[], toolbarOption: string) => {
      if (Object.keys(toolbarConfig).includes(toolbarOption)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        array.push(toolbarConfig[toolbarOption as ToolbarOption]!);
      }

      return array;
    },
    []
  );

  return uniq(flatten(pluginsArray));
};
