import React, { useMemo } from 'react';

import { Container } from 'react-bootstrap';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFeature } from 'flagged';

import { Header, Footer, BootstrapWrapper, InfoHeader } from '@shared';
import { useMainState } from '@hooks';
import { FeatureFlag } from '@enums';

import { Spacer, Steps } from '..';
import useGlobalState from '../../../../useGlobalStore';

type Props = {
  children: React.ReactNode;
  pageClassName?: string;
  headerClassName?: string;
  hideSteps?: boolean;
  id?: string;
};

const MainContent: React.FC<Props> = (props) => {
  const { children, pageClassName, headerClassName, hideSteps, id } = props;
  const portalConfig = useGlobalState((state) => state.portalConfig);
  const { t } = useTranslation();

  const { inquiryInfo, inquiryGroupsData } = useMainState();

  const professionTitle = useMemo(() => {
    if (inquiryGroupsData?.professionAliases.length === 1) {
      if (inquiryInfo) {
        return inquiryInfo.professionAlias;
      }

      return inquiryGroupsData.professionAliases[0].name;
    }

    return t('general.mixedProfession');
  }, [inquiryInfo, inquiryGroupsData, t]);

  const widgetLandingPageFeatureFlag = useFeature(FeatureFlag.useWidgetLandingPage);
  const widgetShowsLandingPage = widgetLandingPageFeatureFlag && !portalConfig?.minimalLayout;
  // INFO  when feature flag is removed we will only check for minimalLayout, no longer for portalConfig header and footer

  return (
    <>
      <BootstrapWrapper className={headerClassName} id={id}>
        <Container className={classNames('bg-white', pageClassName)}>
          {(portalConfig?.header || widgetShowsLandingPage) && <Header />}
          <Spacer height="10px" />
          {!hideSteps && <Steps />}
          <Spacer height="4px" />
        </Container>
        {inquiryInfo && inquiryGroupsData && (
          <InfoHeader productName={inquiryInfo.productName} professionAlias={professionTitle} />
        )}
        {!inquiryInfo && inquiryGroupsData && <InfoHeader professionAlias={professionTitle} />}
        <Container>
          <Spacer height="4px" />
        </Container>
      </BootstrapWrapper>
      {children}
      {(portalConfig?.footer || widgetShowsLandingPage) && (
        <BootstrapWrapper>
          <Footer />
        </BootstrapWrapper>
      )}
    </>
  );
};

export default MainContent;
