import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { AxiosResponseType, HttpHeader, ContentType } from '@developers/toolbox';

import { ProductPDFRequest, RiskAnalysisApi } from '@api/consultation';
import { helpers } from '@helpers';

const consultationInstance = new RiskAnalysisApi(
  undefined,
  helpers.isWidget
    ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/consultation/v1`
    : '/api/consultation/v1'
);

const useGenerateRiskAnalysisDocument = (
  consultationId: string
): UseMutationResult<AxiosResponse<BlobPart>, AxiosError, ProductPDFRequest> =>
  useMutation((riskAnalysis: ProductPDFRequest) =>
    consultationInstance.postRiskAnalysisDocumentRequest(consultationId, riskAnalysis, {
      responseType: AxiosResponseType.ArrayBuffer,
      headers: { [HttpHeader.Accept]: ContentType.Pdf },
    })
  );

const document = {
  useGenerateRiskAnalysisDocument,
};

export default document;
