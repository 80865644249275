import { useTranslation } from 'react-i18next';
import { currencyHelpers } from '@developers/toolbox';

import { FilterType } from '@enums';

const useFilterTranslation = (
  initialKey: string,
  value: string,
  showCurrency?: boolean
): string => {
  const { t } = useTranslation();
  const million = 1000000;

  if (value === 'all') {
    return t('filterType.default.all');
  }

  if (+value === 0) {
    return t('filterType.default.value_0');
  }

  const moneyFilterTranslation = (): string => {
    const valueInCents = currencyHelpers.convertFromCents(value);

    const finalValue = currencyHelpers.formatCurrency(+value, {
      decimalScale: 0,
      showCurrencySymbol: showCurrency,
      compact: valueInCents >= million,
    });

    return t(`filterType.radioButton.value`, {
      value: finalValue,
    });
  };

  const checkboxYearTranslation = (): string => {
    return +value < 12
      ? t('filterType.checkboxPeriodYearly.month', { count: +value })
      : t('filterType.checkboxPeriodYearly.year', { count: +value / 12 });
  };

  const defaultTranslation = (): string => {
    // TODO need's to be changed, will be discussed with backend team
    const convertedValue = Number(value);
    if (convertedValue) {
      return currencyHelpers.formatCurrency(+value, {
        numberIsInCents: false,
        compact: +value >= million,
      });
    }
    return value;
  };

  switch (initialKey) {
    case FilterType.CheckboxPeriodYearly:
      return checkboxYearTranslation();
    case FilterType.RadioButtonMoneyMax:
    case FilterType.RadioButtonMoneyMin:
      return moneyFilterTranslation();
    default:
      return defaultTranslation();
  }
};

export default useFilterTranslation;
