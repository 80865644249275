import { patterns } from './regexHelpers';

export const isAbsoluteUrl = (url: string): boolean => {
  return patterns.absoluteUrl.test(url);
};

export const isValidUrl = (url?: string | null): boolean => {
  if (!url) {
    return false;
  }

  const invalidValuesPattern = /undefined|null|\/\//gm;

  const relativeUrl = url.replace(/^((https|http):\/\/.)/, '');

  const hasInvalidValues = invalidValuesPattern.test(relativeUrl);

  if (hasInvalidValues) {
    return false;
  }

  return true;
};
