import React from 'react';

import { useTranslation } from 'react-i18next';
import {
  FormGroup,
  RadioButton,
  RadioButtonGroup,
  Checkbox,
  Button,
} from '@developers/graphite/carbon-components';
import { Reset16 } from '@developers/graphite/carbon-icons';

import { OfferListConfigurationResponseDto } from '@api/offerOrchestrator';

import { ResultFiltersCheckboxes } from '..';
import { useFilterTranslation } from '../../hooks';

type Props = {
  resultConfiguration: OfferListConfigurationResponseDto;
  addActiveFilter: (value: string[], featureId: string, comparisonOperator?: string | null) => void;
  showBestOfferPerTariff: (value: boolean) => void;
  resetFilters: () => void;
};

const ResultFilters: React.FC<Props> = ({
  resultConfiguration,
  addActiveFilter,
  showBestOfferPerTariff,
  resetFilters,
}) => {
  const { t } = useTranslation();
  const { filters, showBestOfferPerTariffOnly } = resultConfiguration;
  const filterOptionsTranslation = useFilterTranslation;

  return (
    <div className="result-filters">
      <Button
        kind="ghost"
        size="small"
        className="reset-btn"
        renderIcon={Reset16}
        onClick={resetFilters}
      >
        {t('resultFilters.filtersReset')}
      </Button>
      {filters.map((filter) => {
        const selectedValue = filter?.options?.find((i) => i.selected === true);
        return (
          <div key={filter.featureId} data-qa={filter.type}>
            {filter.type.includes('checkbox') && filter.options && (
              <ResultFiltersCheckboxes filter={filter} addActiveFilter={addActiveFilter} />
            )}
            {filter.type.includes('radio-button') && (
              <FormGroup legendText={filter.label} name={filter.featureId}>
                <RadioButtonGroup
                  defaultSelected={selectedValue?.value}
                  name={filter.name}
                  orientation="vertical"
                  onChange={(value) =>
                    addActiveFilter([value as string], filter.featureId, filter.comparisonOperator)
                  }
                >
                  {filter?.options?.map((option) => {
                    return (
                      <RadioButton
                        key={option.value}
                        labelText={filterOptionsTranslation(filter.type, option.value, true)}
                        value={option.value}
                        name={filter.featureId}
                        data-qa={`${filter.type}_option`}
                      />
                    );
                  })}
                </RadioButtonGroup>
              </FormGroup>
            )}
          </div>
        );
      })}
      <div className="result-filters__presentation">
        <fieldset className="bx--fieldset">
          <legend className="bx--label">{t('resultFilters.presentation')}</legend>
          <Checkbox
            data-qa="show-best-offer-per-tariff-only"
            labelText={`${t('resultFilters.presentationLabel')}`}
            defaultChecked={showBestOfferPerTariffOnly}
            id="darstellung-id"
            onChange={(value: boolean) => showBestOfferPerTariff(value)}
          />
        </fieldset>
      </div>
    </div>
  );
};

export default ResultFilters;
