import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { API, helpers } from '@helpers';
import { ApiQuery } from '@enums';

export interface InquiryData {
  comment: string | null;
  created: Date;
  hash: string;
  id: number;
  inquiryGroup: string;
  product: string;
  status: number;
}

const useInquiryData = (
  inquiryId?: string,
  options: UseQueryOptions<InquiryData, Error> = {}
): QueryObserverResult<InquiryData, Error> => {
  return useQuery<InquiryData, Error>(
    [ApiQuery.InquiryData, inquiryId],
    async () => {
      const response = await API.get<InquiryData>(
        helpers.isWidget
          ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1/inquiries/${inquiryId}.json`
          : `/api/e2e/v1/inquiries/${inquiryId}.json`
      );

      return response;
    },
    {
      ...options,
      enabled: (options.enabled ?? true) && !!inquiryId,
    }
  );
};

export default useInquiryData;
