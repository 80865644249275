import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { masterDataApi } from '@developers/api-client-generator';

import { ProfessionAliasApi } from '@api/masterData';
import { ApiQuery } from '@enums';
import { helpers } from '@helpers';

const professionAliasInstance = new ProfessionAliasApi(
  undefined,
  helpers.isWidget
    ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/masterdata/v1`
    : '/api/masterdata/v1'
);

const useProfessionAliases = (
  professionAliasIds: string[],
  options: UseQueryOptions<masterDataApi.ProfessionAliasList, AxiosError> = {}
): UseQueryResult<masterDataApi.ProfessionAliasList, AxiosError> =>
  useQuery(
    ApiQuery.SearchProfessionAlias,
    () =>
      professionAliasInstance
        .searchProfessionAliasRequest(undefined, undefined, professionAliasIds)
        .then((res) => res.data),
    { ...options }
  );

const professionAlias = {
  useProfessionAliases,
};

export default professionAlias;
