import {
  useMutation,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from 'react-query';
import { AxiosError } from 'axios';

import { ApiQuery } from '@enums';
import {
  ConsultationApi,
  RiskAssessment,
  SaveRiskAssessment,
  CustomerMessage,
  RiskAssessmentEmail,
} from '@api/consultation';
import { helpers } from '@helpers';

const consultationInstance = new ConsultationApi(
  undefined,
  helpers.isWidget
    ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/consultation/v1`
    : '/api/consultation/v1'
);

const useCreateRiskAssessment = (
  consultationId: string
): UseMutationResult<RiskAssessment, AxiosError, SaveRiskAssessment> =>
  useMutation((body: SaveRiskAssessment) =>
    consultationInstance.createRiskAssessmentRequest(consultationId, body).then((res) => res.data)
  );

const useSendCustomerMessage = (
  consultationId: string
): UseMutationResult<RiskAssessment, AxiosError, CustomerMessage> =>
  useMutation((body: CustomerMessage) =>
    consultationInstance.sendCustomerMessageRequest(consultationId, body).then((res) => res.data)
  );

const useGetEmailTemplate = (
  consultationId: string,
  riskAssessmentId: string,
  options: UseQueryOptions<RiskAssessmentEmail, AxiosError> = {}
): UseQueryResult<RiskAssessmentEmail, AxiosError> =>
  useQuery(
    [ApiQuery.EmailTemplate, consultationId, riskAssessmentId],
    () =>
      consultationInstance
        .getRiskAssessmentEmailRequest(consultationId, riskAssessmentId)
        .then((res) => res.data),
    { keepPreviousData: true, refetchOnMount: false, ...options }
  );

const riskAssessment = {
  useCreateRiskAssessment,
  useSendCustomerMessage,
  useGetEmailTemplate,
};

export default riskAssessment;
