import React from 'react';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { UserRoles } from '@enums';
import { useGlobalState } from '@hooks';
import { inquiriesService } from '@services/consultation';
import { InquiryList } from '@api/consultation';
import { helpers } from '@helpers';

import SuccessPageB2C from './SuccessPageB2C';
import SuccessPageB2B from './SuccessPageB2B';

type Props = {
  inquiryId: string | undefined;
};

const SuccessPage: React.FC<Props> = ({ inquiryId }) => {
  const { t } = useTranslation();
  const { sessions } = useGlobalState();
  const { inquiryHash = '' } = useParams<{ inquiryHash: string }>();

  const { data: dataInquiries } = inquiriesService.useGetInquiries(inquiryHash, {
    onError: () => {
      toast.info(`${t('apiErrorMessage')}`);
    },
  });

  const getInquiry = (inquiries: InquiryList | undefined) => {
    const inquiry = inquiries?._embedded.inquiries.find((el) => el.hash === inquiryHash);
    return inquiry;
  };

  return !helpers.envVariables.REACT_APP_IS_WIDGET || sessions?.roles[0] === UserRoles.B2B ? (
    <SuccessPageB2B inquiryId={getInquiry(dataInquiries)?.id} />
  ) : (
    <SuccessPageB2C inquiryId={getInquiry(dataInquiries)?.id} />
  );
};

export default SuccessPage;
