import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { API, helpers } from '@helpers';
import { ApiQuery } from '@enums';

export interface Settings {
  brokerCode: string;
  brokerName: string | null;
  poolId: number;
  poolTag: string;
  poolName: string;
  brokerPortalLoginUrl: string | null;
  hasComparisonSubscription: boolean;
  hasTopBar: boolean;
  hasLandingPageUrlOnTopBar: boolean;
  externalWebsiteUrl: string | null;
  externalWebsiteLabel: string | null;
  bannerHtml: string | null;
  logoUrl: string | null;
  generalConditionsUrl: string | null;
  privacyPolicyUrl: string | null;
  hasPreviousContract: boolean;
  hasBrokerTutorial: boolean;
  skipLandingPage: boolean;
}

const useSettings = (
  options: UseQueryOptions<Settings, Error> = {}
): QueryObserverResult<Settings, Error> => {
  return useQuery<Settings, Error>(
    [ApiQuery.Settings],
    async () => {
      const response = await API.get<Settings>(
        helpers.isWidget
          ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1/settings`
          : '/api/e2e/v1/settings'
      );

      return response;
    },
    options
  );
};

export default useSettings;
