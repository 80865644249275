import { ProductApi } from '@api/masterData';
import { helpers } from '@helpers';

const productInstance = new ProductApi(
  undefined,
  helpers.isWidget
    ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/masterdata/v1`
    : '/api/masterdata/v1'
);

const product = {
  productInstance,
};

export default product;
