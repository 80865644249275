/* tslint:disable */
/* eslint-disable */
/**
 * Broker API
 * Broker API provides data relevant for brokers.
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Account holds most basic and crucial Account data used for REST entity
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    PDFLogoDocumentID?: string;
    /**
     * 
     * @type {AccountAddress}
     * @memberof Account
     */
    address?: AccountAddress;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    advisorySuiteTitle: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    agentNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    branch?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    companyRegistrationNumber?: string;
    /**
     * 
     * @type {AccountContact}
     * @memberof Account
     */
    contact?: AccountContact;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    director?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    email?: string;
    /**
     * 
     * @type {Array<AccountEmail>}
     * @memberof Account
     */
    emails?: Array<AccountEmail>;
    /**
     * 
     * @type {Entity}
     * @memberof Account
     */
    entity: Entity;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    fax?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    imprint?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    isTest: boolean;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    notificationUserHash?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    supervisoryBoard?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    tag: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    taxID?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    website?: string;
}
/**
 * AccountAddress holds most basic and crucial AccountAddress data used for REST entity
 * @export
 * @interface AccountAddress
 */
export interface AccountAddress {
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    postcode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    streetNumber?: string;
}
/**
 * AccountContact holds most basic and crucial AccountContact data used for REST entity
 * @export
 * @interface AccountContact
 */
export interface AccountContact {
    /**
     * 
     * @type {string}
     * @memberof AccountContact
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountContact
     */
    name?: string;
    /**
     * 
     * @type {Array<AccountPhone>}
     * @memberof AccountContact
     */
    phones?: Array<AccountPhone>;
    /**
     * 
     * @type {string}
     * @memberof AccountContact
     */
    workingTime?: string;
}
/**
 * AccountEmail holds most basic and crucial AccountEmail data used for REST entity
 * @export
 * @interface AccountEmail
 */
export interface AccountEmail {
    /**
     * 
     * @type {string}
     * @memberof AccountEmail
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AccountEmail
     */
    type: AccountEmailTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AccountEmailTypeEnum {
    Deal = 'deal',
    DealCc = 'dealCC',
    DealNoAgencyNumber = 'dealNoAgencyNumber',
    DealPartnerAgencyNumber = 'dealPartnerAgencyNumber',
    Tendering = 'tendering',
    TenderingCc = 'tenderingCC',
    Test = 'test',
    Registration = 'registration',
    RegistrationCc = 'registrationCC',
    ServiceTeam = 'serviceTeam',
    InsurerAutoCc = 'insurerAutoCC',
    TenderingBackoffice = 'tenderingBackoffice',
    TenderingRequestInsurerCc = 'tenderingRequestInsurerCC',
    TenderingOffer = 'tenderingOffer',
    Unknown = 'unknown'
}

/**
 * AccountPhone holds most basic and crucial AccountPhone data used for REST entity
 * @export
 * @interface AccountPhone
 */
export interface AccountPhone {
    /**
     * 
     * @type {string}
     * @memberof AccountPhone
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountPhone
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface AgencyNumber
 */
export interface AgencyNumber {
    /**
     * 
     * @type {string}
     * @memberof AgencyNumber
     */
    id: string;
    /**
     * 
     * @type {InsurerContact}
     * @memberof AgencyNumber
     */
    insurerContact: InsurerContact;
    /**
     * 
     * @type {string}
     * @memberof AgencyNumber
     */
    insurerId: string;
    /**
     * 
     * @type {Array<MultipleAgencyNumber>}
     * @memberof AgencyNumber
     */
    numbers: Array<MultipleAgencyNumber>;
}
/**
 * 
 * @export
 * @interface AgencyNumberError
 */
export interface AgencyNumberError {
    /**
     * 
     * @type {string}
     * @memberof AgencyNumberError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof AgencyNumberError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface AgencyNumbersList
 */
export interface AgencyNumbersList {
    /**
     * 
     * @type {EmbeddedAgencyNumbers}
     * @memberof AgencyNumbersList
     */
    _embedded: EmbeddedAgencyNumbers;
    /**
     * 
     * @type {number}
     * @memberof AgencyNumbersList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof AgencyNumbersList
     */
    total: number;
}
/**
 * Broker holds most basic and Broker data used for RESTful response
 * @export
 * @interface Broker
 */
export interface Broker {
    /**
     * 
     * @type {Account}
     * @memberof Broker
     */
    account: Account;
    /**
     * 
     * @type {BrokerAddress}
     * @memberof Broker
     */
    address?: BrokerAddress;
    /**
     * 
     * @type {string}
     * @memberof Broker
     */
    brokerCode: string;
    /**
     * 
     * @type {string}
     * @memberof Broker
     */
    company?: string;
    /**
     * 
     * @type {BrokerContact}
     * @memberof Broker
     */
    contact: BrokerContact;
    /**
     * 
     * @type {Entity}
     * @memberof Broker
     */
    entity: Entity;
    /**
     * 
     * @type {string}
     * @memberof Broker
     */
    guildNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Broker
     */
    internalId: string;
    /**
     * 
     * @type {string}
     * @memberof Broker
     */
    notificationUserHash: string;
    /**
     * 
     * @type {BrokerPerson}
     * @memberof Broker
     */
    person?: BrokerPerson;
    /**
     * 
     * @type {string}
     * @memberof Broker
     */
    publicAgencyRegistrationNo?: string;
    /**
     * 
     * @type {string}
     * @memberof Broker
     */
    registrationNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof Broker
     */
    type: number;
    /**
     * 
     * @type {BrokerWert14}
     * @memberof Broker
     */
    wert14?: BrokerWert14;
}
/**
 * BrokerAddress holds the address data of a Broker used for RESTFUL response
 * @export
 * @interface BrokerAddress
 */
export interface BrokerAddress {
    /**
     * 
     * @type {string}
     * @memberof BrokerAddress
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerAddress
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerAddress
     */
    street_number?: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerAddress
     */
    zip?: string;
}
/**
 * BrokerContact holds most basic and Broker data used for RESTful response
 * @export
 * @interface BrokerContact
 */
export interface BrokerContact {
    /**
     * 
     * @type {string}
     * @memberof BrokerContact
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerContact
     */
    phone?: string;
}
/**
 * BrokerError is an Error Representation object
 * @export
 * @interface BrokerError
 */
export interface BrokerError {
    /**
     * 
     * @type {string}
     * @memberof BrokerError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof BrokerError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * BrokerList is a Representation object
 * @export
 * @interface BrokerList
 */
export interface BrokerList {
    /**
     * 
     * @type {EmbeddedBrokerTeasers}
     * @memberof BrokerList
     */
    _embedded: EmbeddedBrokerTeasers;
    /**
     * 
     * @type {number}
     * @memberof BrokerList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof BrokerList
     */
    total: number;
}
/**
 * 
 * @export
 * @interface BrokerLogo
 */
export interface BrokerLogo {
    /**
     * 
     * @type {string}
     * @memberof BrokerLogo
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerLogo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerLogo
     */
    url?: string;
}
/**
 * BrokerPerson holds most basic and Broker data used for RESTful response
 * @export
 * @interface BrokerPerson
 */
export interface BrokerPerson {
    /**
     * 
     * @type {string}
     * @memberof BrokerPerson
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerPerson
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerPerson
     */
    lastName?: string;
}
/**
 * 
 * @export
 * @interface BrokerSetting
 */
export interface BrokerSetting {
    /**
     * 
     * @type {string}
     * @memberof BrokerSetting
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof BrokerSetting
     */
    isRequired: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof BrokerSetting
     */
    jsonValue?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof BrokerSetting
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerSetting
     */
    stringValue?: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerSetting
     */
    type: string;
}
/**
 * 
 * @export
 * @interface BrokerSettingError
 */
export interface BrokerSettingError {
    /**
     * 
     * @type {string}
     * @memberof BrokerSettingError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof BrokerSettingError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface BrokerSettingList
 */
export interface BrokerSettingList {
    /**
     * 
     * @type {EmbeddedBrokerSettings}
     * @memberof BrokerSettingList
     */
    _embedded: EmbeddedBrokerSettings;
    /**
     * 
     * @type {number}
     * @memberof BrokerSettingList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof BrokerSettingList
     */
    total: number;
}
/**
 * BrokerTeaser holds most basic and crutial Broker data used for RESTful HAL
 * @export
 * @interface BrokerTeaser
 */
export interface BrokerTeaser {
    /**
     * 
     * @type {string}
     * @memberof BrokerTeaser
     */
    brokerCode: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerTeaser
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerTeaser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerTeaser
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerTeaser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerTeaser
     */
    internalId: string;
}
/**
 * BrokerWert14 holds the Wert14 data of a Broker used for RESTFUL response
 * @export
 * @interface BrokerWert14
 */
export interface BrokerWert14 {
    /**
     * 
     * @type {string}
     * @memberof BrokerWert14
     */
    apiKey: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerWert14
     */
    signatureHash: string;
}
/**
 * ChangeBrokerPasswordRequestBody represents initiate password reset API request body
 * @export
 * @interface ChangeBrokerPasswordRequestBody
 */
export interface ChangeBrokerPasswordRequestBody {
    /**
     * 
     * @type {string}
     * @memberof ChangeBrokerPasswordRequestBody
     */
    confirmNewPassword: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeBrokerPasswordRequestBody
     */
    newPassword: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeBrokerPasswordRequestBody
     */
    token: string;
}
/**
 * 
 * @export
 * @interface EmbeddedAgencyNumbers
 */
export interface EmbeddedAgencyNumbers {
    /**
     * 
     * @type {Array<AgencyNumber>}
     * @memberof EmbeddedAgencyNumbers
     */
    agencyNumbers: Array<AgencyNumber>;
}
/**
 * EmbeddedBrokerSettings is a Representation object of Settings
 * @export
 * @interface EmbeddedBrokerSettings
 */
export interface EmbeddedBrokerSettings {
    /**
     * 
     * @type {Array<BrokerSetting>}
     * @memberof EmbeddedBrokerSettings
     */
    brokerSettings: Array<BrokerSetting>;
}
/**
 * EmbeddedBrokerTeasers is a Representation object of BrokerTeasers
 * @export
 * @interface EmbeddedBrokerTeasers
 */
export interface EmbeddedBrokerTeasers {
    /**
     * BrokerTeasers type is a \"generic\" which holds a slice of BrokerTeaser
     * @type {Array<BrokerTeaser>}
     * @memberof EmbeddedBrokerTeasers
     */
    brokers: Array<BrokerTeaser>;
}
/**
 * EmbeddedPortalSettings is a Representation object of Settings
 * @export
 * @interface EmbeddedPortalSettings
 */
export interface EmbeddedPortalSettings {
    /**
     * 
     * @type {Array<PortalSetting>}
     * @memberof EmbeddedPortalSettings
     */
    portalSettings: Array<PortalSetting>;
}
/**
 * Entity holds most basic and crucial Entity data used for REST entity
 * @export
 * @interface Entity
 */
export interface Entity {
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof Entity
     */
    isActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Entity
     */
    isDeleted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Entity
     */
    isLocked: boolean;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    updatedAt: string;
}
/**
 * InitiatePasswordResetRequestBody represents initiate password reset API request body
 * @export
 * @interface InitiatePasswordResetRequestBody
 */
export interface InitiatePasswordResetRequestBody {
    /**
     * 
     * @type {string}
     * @memberof InitiatePasswordResetRequestBody
     */
    accountTag: string;
    /**
     * 
     * @type {string}
     * @memberof InitiatePasswordResetRequestBody
     */
    identifier: string;
    /**
     * 
     * @type {string}
     * @memberof InitiatePasswordResetRequestBody
     */
    resetMethod: InitiatePasswordResetRequestBodyResetMethodEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum InitiatePasswordResetRequestBodyResetMethodEnum {
    BrokerCode = 'brokerCode',
    BrokerNumber = 'brokerNumber',
    Email = 'email'
}

/**
 * InsurerContact represents insurer contact for the agency used for REST entity
 * @export
 * @interface InsurerContact
 */
export interface InsurerContact {
    /**
     * 
     * @type {string}
     * @memberof InsurerContact
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof InsurerContact
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof InsurerContact
     */
    overrideDealEmail: boolean;
    /**
     * 
     * @type {number}
     * @memberof InsurerContact
     */
    processType: number;
    /**
     * 
     * @type {string}
     * @memberof InsurerContact
     */
    productId: string;
    /**
     * 
     * @type {boolean}
     * @memberof InsurerContact
     */
    sendEmail: boolean;
}
/**
 * 
 * @export
 * @interface MultipleAgencyNumber
 */
export interface MultipleAgencyNumber {
    /**
     * 
     * @type {string}
     * @memberof MultipleAgencyNumber
     */
    agencyId?: string;
    /**
     * 
     * @type {string}
     * @memberof MultipleAgencyNumber
     */
    agencyNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof MultipleAgencyNumber
     */
    comment?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MultipleAgencyNumber
     */
    defaultForCalculatorOffers?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MultipleAgencyNumber
     */
    defaultForTenderingOffers?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MultipleAgencyNumber
     */
    externalId?: string;
    /**
     * 
     * @type {string}
     * @memberof MultipleAgencyNumber
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof MultipleAgencyNumber
     */
    type?: number;
}
/**
 * 
 * @export
 * @interface PortalSetting
 */
export interface PortalSetting {
    /**
     * 
     * @type {string}
     * @memberof PortalSetting
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof PortalSetting
     */
    isDefault: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof PortalSetting
     */
    jsonValue?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof PortalSetting
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof PortalSetting
     */
    stringValue?: string;
}
/**
 * 
 * @export
 * @interface PortalSettingError
 */
export interface PortalSettingError {
    /**
     * 
     * @type {string}
     * @memberof PortalSettingError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof PortalSettingError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface PortalSettingList
 */
export interface PortalSettingList {
    /**
     * 
     * @type {EmbeddedPortalSettings}
     * @memberof PortalSettingList
     */
    _embedded: EmbeddedPortalSettings;
    /**
     * 
     * @type {number}
     * @memberof PortalSettingList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof PortalSettingList
     */
    total: number;
}
/**
 * ValidatePasswordResetTokenResponseBody represents token validation response
 * @export
 * @interface ValidatePasswordResetTokenResponseBody
 */
export interface ValidatePasswordResetTokenResponseBody {
    /**
     * 
     * @type {boolean}
     * @memberof ValidatePasswordResetTokenResponseBody
     */
    isValid?: boolean;
}

/**
 * BrokerApi - axios parameter creator
 * @export
 */
export const BrokerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} brokerId 
         * @param {'accountOnly' | 'brokerOnly' | 'brokerFirst'} prioritisation 
         * @param {'tendering' | 'calculator'} offerType 
         * @param {Array<string>} [insurerIds] 
         * @param {string} [productId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agencyNumberRequest: async (brokerId: string, prioritisation: 'accountOnly' | 'brokerOnly' | 'brokerFirst', offerType: 'tendering' | 'calculator', insurerIds?: Array<string>, productId?: string, limit?: number, offset?: number, noPagination?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'brokerId' is not null or undefined
            assertParamExists('agencyNumberRequest', 'brokerId', brokerId)
            // verify required parameter 'prioritisation' is not null or undefined
            assertParamExists('agencyNumberRequest', 'prioritisation', prioritisation)
            // verify required parameter 'offerType' is not null or undefined
            assertParamExists('agencyNumberRequest', 'offerType', offerType)
            const localVarPath = `/brokers/{brokerId}/agencyNumbers`
                .replace(`{${"brokerId"}}`, encodeURIComponent(String(brokerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (insurerIds) {
                localVarQueryParameter['insurerIds'] = insurerIds.join(COLLECTION_FORMATS.csv);
            }

            if (prioritisation !== undefined) {
                localVarQueryParameter['prioritisation'] = prioritisation;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (offerType !== undefined) {
                localVarQueryParameter['offerType'] = offerType;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (noPagination !== undefined) {
                localVarQueryParameter['noPagination'] = noPagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} brokerId 
         * @param {Array<string>} [settingIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerSettingRequest: async (brokerId: number, settingIds?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'brokerId' is not null or undefined
            assertParamExists('brokerSettingRequest', 'brokerId', brokerId)
            const localVarPath = `/brokers/{brokerId}/settings`
                .replace(`{${"brokerId"}}`, encodeURIComponent(String(brokerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (settingIds) {
                localVarQueryParameter['settingIds'] = settingIds.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} brokerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDBrokerContactRequest: async (brokerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'brokerId' is not null or undefined
            assertParamExists('byIDBrokerContactRequest', 'brokerId', brokerId)
            const localVarPath = `/brokers/{brokerId}/contact`
                .replace(`{${"brokerId"}}`, encodeURIComponent(String(brokerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} brokerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDBrokerLogoRequest: async (brokerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'brokerId' is not null or undefined
            assertParamExists('byIDBrokerLogoRequest', 'brokerId', brokerId)
            const localVarPath = `/brokers/{brokerId}/logo`
                .replace(`{${"brokerId"}}`, encodeURIComponent(String(brokerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} brokerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDBrokerRequest: async (brokerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'brokerId' is not null or undefined
            assertParamExists('byIDBrokerRequest', 'brokerId', brokerId)
            const localVarPath = `/brokers/{brokerId}`
                .replace(`{${"brokerId"}}`, encodeURIComponent(String(brokerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChangeBrokerPasswordRequestBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeBrokerPasswordRequest: async (body?: ChangeBrokerPasswordRequestBody, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/brokers/changePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InitiatePasswordResetRequestBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiatePasswordResetRequest: async (body?: InitiatePasswordResetRequestBody, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/brokers/initiatePasswordReset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} brokerId 
         * @param {Array<string>} [settingIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalSettingRequest: async (brokerId: number, settingIds?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'brokerId' is not null or undefined
            assertParamExists('portalSettingRequest', 'brokerId', brokerId)
            const localVarPath = `/brokers/{brokerId}/portalSettings`
                .replace(`{${"brokerId"}}`, encodeURIComponent(String(brokerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (settingIds) {
                localVarQueryParameter['settingIds'] = settingIds.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {Array<string>} [brokerIds] 
         * @param {string} [brokerCode] 
         * @param {string} [poolInternalId] 
         * @param {string} [email] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBrokerRequest: async (accountId?: string, brokerIds?: Array<string>, brokerCode?: string, poolInternalId?: string, email?: string, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/brokers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (brokerIds) {
                localVarQueryParameter['brokerIds'] = brokerIds.join(COLLECTION_FORMATS.csv);
            }

            if (brokerCode !== undefined) {
                localVarQueryParameter['brokerCode'] = brokerCode;
            }

            if (poolInternalId !== undefined) {
                localVarQueryParameter['poolInternalId'] = poolInternalId;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (noPagination !== undefined) {
                localVarQueryParameter['noPagination'] = noPagination;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (includeLocked !== undefined) {
                localVarQueryParameter['includeLocked'] = includeLocked;
            }

            if (includeInactive !== undefined) {
                localVarQueryParameter['includeInactive'] = includeInactive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} brokerId 
         * @param {BrokerContact} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBrokerContactRequest: async (brokerId: string, body?: BrokerContact, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'brokerId' is not null or undefined
            assertParamExists('updateBrokerContactRequest', 'brokerId', brokerId)
            const localVarPath = `/brokers/{brokerId}/contact`
                .replace(`{${"brokerId"}}`, encodeURIComponent(String(brokerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePasswordResetTokenRequest: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('validatePasswordResetTokenRequest', 'token', token)
            const localVarPath = `/brokers/passwordResetToken/{token}/validate`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrokerApi - functional programming interface
 * @export
 */
export const BrokerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrokerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} brokerId 
         * @param {'accountOnly' | 'brokerOnly' | 'brokerFirst'} prioritisation 
         * @param {'tendering' | 'calculator'} offerType 
         * @param {Array<string>} [insurerIds] 
         * @param {string} [productId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agencyNumberRequest(brokerId: string, prioritisation: 'accountOnly' | 'brokerOnly' | 'brokerFirst', offerType: 'tendering' | 'calculator', insurerIds?: Array<string>, productId?: string, limit?: number, offset?: number, noPagination?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgencyNumbersList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.agencyNumberRequest(brokerId, prioritisation, offerType, insurerIds, productId, limit, offset, noPagination, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} brokerId 
         * @param {Array<string>} [settingIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokerSettingRequest(brokerId: number, settingIds?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrokerSettingList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokerSettingRequest(brokerId, settingIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} brokerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async byIDBrokerContactRequest(brokerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrokerContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.byIDBrokerContactRequest(brokerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} brokerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async byIDBrokerLogoRequest(brokerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrokerLogo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.byIDBrokerLogoRequest(brokerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} brokerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async byIDBrokerRequest(brokerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Broker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.byIDBrokerRequest(brokerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ChangeBrokerPasswordRequestBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeBrokerPasswordRequest(body?: ChangeBrokerPasswordRequestBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeBrokerPasswordRequest(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InitiatePasswordResetRequestBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiatePasswordResetRequest(body?: InitiatePasswordResetRequestBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiatePasswordResetRequest(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} brokerId 
         * @param {Array<string>} [settingIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalSettingRequest(brokerId: number, settingIds?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortalSettingList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portalSettingRequest(brokerId, settingIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {Array<string>} [brokerIds] 
         * @param {string} [brokerCode] 
         * @param {string} [poolInternalId] 
         * @param {string} [email] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchBrokerRequest(accountId?: string, brokerIds?: Array<string>, brokerCode?: string, poolInternalId?: string, email?: string, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrokerList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchBrokerRequest(accountId, brokerIds, brokerCode, poolInternalId, email, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} brokerId 
         * @param {BrokerContact} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBrokerContactRequest(brokerId: string, body?: BrokerContact, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrokerContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBrokerContactRequest(brokerId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validatePasswordResetTokenRequest(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidatePasswordResetTokenResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validatePasswordResetTokenRequest(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BrokerApi - factory interface
 * @export
 */
export const BrokerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrokerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} brokerId 
         * @param {'accountOnly' | 'brokerOnly' | 'brokerFirst'} prioritisation 
         * @param {'tendering' | 'calculator'} offerType 
         * @param {Array<string>} [insurerIds] 
         * @param {string} [productId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agencyNumberRequest(brokerId: string, prioritisation: 'accountOnly' | 'brokerOnly' | 'brokerFirst', offerType: 'tendering' | 'calculator', insurerIds?: Array<string>, productId?: string, limit?: number, offset?: number, noPagination?: boolean, options?: any): AxiosPromise<AgencyNumbersList> {
            return localVarFp.agencyNumberRequest(brokerId, prioritisation, offerType, insurerIds, productId, limit, offset, noPagination, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} brokerId 
         * @param {Array<string>} [settingIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerSettingRequest(brokerId: number, settingIds?: Array<string>, options?: any): AxiosPromise<BrokerSettingList> {
            return localVarFp.brokerSettingRequest(brokerId, settingIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} brokerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDBrokerContactRequest(brokerId: string, options?: any): AxiosPromise<BrokerContact> {
            return localVarFp.byIDBrokerContactRequest(brokerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} brokerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDBrokerLogoRequest(brokerId: string, options?: any): AxiosPromise<BrokerLogo> {
            return localVarFp.byIDBrokerLogoRequest(brokerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} brokerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDBrokerRequest(brokerId: string, options?: any): AxiosPromise<Broker> {
            return localVarFp.byIDBrokerRequest(brokerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChangeBrokerPasswordRequestBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeBrokerPasswordRequest(body?: ChangeBrokerPasswordRequestBody, options?: any): AxiosPromise<void> {
            return localVarFp.changeBrokerPasswordRequest(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InitiatePasswordResetRequestBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiatePasswordResetRequest(body?: InitiatePasswordResetRequestBody, options?: any): AxiosPromise<void> {
            return localVarFp.initiatePasswordResetRequest(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} brokerId 
         * @param {Array<string>} [settingIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalSettingRequest(brokerId: number, settingIds?: Array<string>, options?: any): AxiosPromise<PortalSettingList> {
            return localVarFp.portalSettingRequest(brokerId, settingIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {Array<string>} [brokerIds] 
         * @param {string} [brokerCode] 
         * @param {string} [poolInternalId] 
         * @param {string} [email] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBrokerRequest(accountId?: string, brokerIds?: Array<string>, brokerCode?: string, poolInternalId?: string, email?: string, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): AxiosPromise<BrokerList> {
            return localVarFp.searchBrokerRequest(accountId, brokerIds, brokerCode, poolInternalId, email, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} brokerId 
         * @param {BrokerContact} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBrokerContactRequest(brokerId: string, body?: BrokerContact, options?: any): AxiosPromise<BrokerContact> {
            return localVarFp.updateBrokerContactRequest(brokerId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePasswordResetTokenRequest(token: string, options?: any): AxiosPromise<ValidatePasswordResetTokenResponseBody> {
            return localVarFp.validatePasswordResetTokenRequest(token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BrokerApi - object-oriented interface
 * @export
 * @class BrokerApi
 * @extends {BaseAPI}
 */
export class BrokerApi extends BaseAPI {
    /**
     * 
     * @param {string} brokerId 
     * @param {'accountOnly' | 'brokerOnly' | 'brokerFirst'} prioritisation 
     * @param {'tendering' | 'calculator'} offerType 
     * @param {Array<string>} [insurerIds] 
     * @param {string} [productId] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {boolean} [noPagination] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokerApi
     */
    public agencyNumberRequest(brokerId: string, prioritisation: 'accountOnly' | 'brokerOnly' | 'brokerFirst', offerType: 'tendering' | 'calculator', insurerIds?: Array<string>, productId?: string, limit?: number, offset?: number, noPagination?: boolean, options?: any) {
        return BrokerApiFp(this.configuration).agencyNumberRequest(brokerId, prioritisation, offerType, insurerIds, productId, limit, offset, noPagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} brokerId 
     * @param {Array<string>} [settingIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokerApi
     */
    public brokerSettingRequest(brokerId: number, settingIds?: Array<string>, options?: any) {
        return BrokerApiFp(this.configuration).brokerSettingRequest(brokerId, settingIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} brokerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokerApi
     */
    public byIDBrokerContactRequest(brokerId: string, options?: any) {
        return BrokerApiFp(this.configuration).byIDBrokerContactRequest(brokerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} brokerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokerApi
     */
    public byIDBrokerLogoRequest(brokerId: string, options?: any) {
        return BrokerApiFp(this.configuration).byIDBrokerLogoRequest(brokerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} brokerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokerApi
     */
    public byIDBrokerRequest(brokerId: string, options?: any) {
        return BrokerApiFp(this.configuration).byIDBrokerRequest(brokerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChangeBrokerPasswordRequestBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokerApi
     */
    public changeBrokerPasswordRequest(body?: ChangeBrokerPasswordRequestBody, options?: any) {
        return BrokerApiFp(this.configuration).changeBrokerPasswordRequest(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InitiatePasswordResetRequestBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokerApi
     */
    public initiatePasswordResetRequest(body?: InitiatePasswordResetRequestBody, options?: any) {
        return BrokerApiFp(this.configuration).initiatePasswordResetRequest(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} brokerId 
     * @param {Array<string>} [settingIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokerApi
     */
    public portalSettingRequest(brokerId: number, settingIds?: Array<string>, options?: any) {
        return BrokerApiFp(this.configuration).portalSettingRequest(brokerId, settingIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [accountId] 
     * @param {Array<string>} [brokerIds] 
     * @param {string} [brokerCode] 
     * @param {string} [poolInternalId] 
     * @param {string} [email] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {boolean} [noPagination] 
     * @param {boolean} [includeDeleted] 
     * @param {boolean} [includeLocked] 
     * @param {boolean} [includeInactive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokerApi
     */
    public searchBrokerRequest(accountId?: string, brokerIds?: Array<string>, brokerCode?: string, poolInternalId?: string, email?: string, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any) {
        return BrokerApiFp(this.configuration).searchBrokerRequest(accountId, brokerIds, brokerCode, poolInternalId, email, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} brokerId 
     * @param {BrokerContact} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokerApi
     */
    public updateBrokerContactRequest(brokerId: string, body?: BrokerContact, options?: any) {
        return BrokerApiFp(this.configuration).updateBrokerContactRequest(brokerId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokerApi
     */
    public validatePasswordResetTokenRequest(token: string, options?: any) {
        return BrokerApiFp(this.configuration).validatePasswordResetTokenRequest(token, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TokenDetailApi - axios parameter creator
 * @export
 */
export const TokenDetailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenDetail: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/tokenDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TokenDetailApi - functional programming interface
 * @export
 */
export const TokenDetailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TokenDetailApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenDetail(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Broker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenDetail(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TokenDetailApi - factory interface
 * @export
 */
export const TokenDetailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TokenDetailApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenDetail(options?: any): AxiosPromise<Broker> {
            return localVarFp.tokenDetail(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TokenDetailApi - object-oriented interface
 * @export
 * @class TokenDetailApi
 * @extends {BaseAPI}
 */
export class TokenDetailApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenDetailApi
     */
    public tokenDetail(options?: any) {
        return TokenDetailApiFp(this.configuration).tokenDetail(options).then((request) => request(this.axios, this.basePath));
    }
}


