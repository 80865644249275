import { HttpStatusCode } from '@developers/toolbox';
import { useTranslation } from 'react-i18next';

interface TextsForErrorPage {
  title: string;
  desc: string;
  code: string;
}

export const useTextsForErrorPage = (errorCode = HttpStatusCode.NotFound): TextsForErrorPage => {
  const { t } = useTranslation();
  const textsForErrorPage: TextsForErrorPage = {
    title: '',
    desc: '',
    code: '',
  };

  textsForErrorPage.title = `${errorCode} ${t(`errorPages.${errorCode}.title`)}`;
  textsForErrorPage.desc = t(`errorPages.${errorCode}.desc`);
  textsForErrorPage.code = `${t('errorPages.errorCode')}: ${errorCode} ${
    HttpStatusCode[errorCode]
  }`;

  return textsForErrorPage;
};
export default useTextsForErrorPage;
