import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { API } from '@helpers';
import { ApiQuery } from '@enums';

export interface AdditionalFields {
  brokerCode: string;
}

export interface Sessions {
  id: string;
  type: string;
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  roles: string[];
  additionalFields: AdditionalFields;
}

const useCheckSession = (
  options: UseQueryOptions<Sessions, Error> = {}
): QueryObserverResult<Sessions, Error> => {
  return useQuery<Sessions, Error>(
    [ApiQuery.CheckSession],
    async () => {
      const response = await API.get<Sessions>(`/api/sessions`);
      return response;
    },
    options
  );
};

export default useCheckSession;
