import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { urlHelpers } from '@developers/toolbox';

import { helpers } from '@helpers';

const FeatureFlagRedirection: React.FC = () => {
  const location = useLocation();
  const baseUrl = helpers.envVariables.REACT_APP_BASE_URL;

  const redirectionLink = `${baseUrl}${location.pathname}${location.search || ''}`;

  const redirectionLinkIsValid = urlHelpers.isValidUrl(redirectionLink);

  useEffect(() => {
    if (redirectionLinkIsValid) {
      window.location.href = redirectionLink;
    }
  }, [redirectionLink, redirectionLinkIsValid]);

  return null;
};

export default FeatureFlagRedirection;
