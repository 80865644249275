import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { AxiosError } from 'axios';

import { ConsultationApi, Analysis, SaveAnalysis, QuestionPageList } from '@api/consultation';
import { ApiQuery } from '@enums';
import { helpers } from '@helpers';

const consultationInstance = new ConsultationApi(
  undefined,
  helpers.isWidget
    ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/consultation/v1`
    : '/api/consultation/v1'
);

const useContentAnalyses = (
  consultationId: string,
  options: UseQueryOptions<Analysis, AxiosError> = {}
): UseQueryResult<Analysis, AxiosError> =>
  useQuery(
    [ApiQuery.ContentAnalyses, consultationId],
    () => consultationInstance.getContentAnalysisRequest(consultationId).then((res) => res.data),
    { keepPreviousData: true, refetchOnMount: false, retry: false, ...options }
  );

const useUpdateContentAnalyses = (
  consultationId: string
): UseMutationResult<void, AxiosError, SaveAnalysis> =>
  useMutation((data: SaveAnalysis) =>
    consultationInstance.updateContentAnalysisRequest(consultationId, data).then((res) => res.data)
  );

const useContentAnalysesQuestionPages = (
  consultationId: string,
  options: UseQueryOptions<QuestionPageList, AxiosError> = {}
): UseQueryResult<QuestionPageList, AxiosError> =>
  useQuery(
    [ApiQuery.ContentAnalysesQuestionPages, consultationId],
    () =>
      consultationInstance
        .questionPagesByConsultationIDRequest(consultationId)
        .then((res) => res.data),
    { keepPreviousData: true, refetchOnMount: false, ...options }
  );

const contentAnalyses = {
  useContentAnalyses,
  useUpdateContentAnalyses,
  useContentAnalysesQuestionPages,
};

export default contentAnalyses;
