import { sortBy } from 'lodash';

interface Field {
  ranking: string | null;
}
export interface UpdatedData {
  rows: Field[];
  key: number;
}

const getDigitsFromString = (value: string): number => {
  return parseInt(value.toString().replace(/[^0-9]/g, ''), 10);
};
const getLettersFromString = (value: string): string => {
  return value.toString().replace(/[0-9]/g, '');
};

const getSortedNumbersArray = (fields: Field[]): Set<number> => {
  const numbers: number[] = [];

  fields.forEach((field: Field, key) => {
    const number = field.ranking ? getDigitsFromString(field.ranking) : key;
    numbers.push(number);
  });
  const sortedArray = sortBy(numbers);
  return new Set(sortedArray);
};

const sortArrayByRanking = (fields: Field[], numbersArray: Set<number>): UpdatedData[] => {
  const updatedData: { rows: Field[]; key: number }[] = [];
  numbersArray.forEach((number) => {
    const fieldsWithParticularNumber = fields.filter((field, key) => {
      const fieldNumber = field.ranking ? getDigitsFromString(field.ranking) : key;
      return fieldNumber === number;
    });
    const sortedByLetter = sortBy(fieldsWithParticularNumber, (item) => {
      const letter = item.ranking ? getLettersFromString(item.ranking) : '';
      return letter;
    });
    updatedData.push({ rows: sortedByLetter, key: number });
  });
  return updatedData;
};

const sortItemsByRankingHelper = {
  getSortedNumbersArray,
  sortArrayByRanking,
  getDigitsFromString,
  getLettersFromString,
};

export default sortItemsByRankingHelper;
