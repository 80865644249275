/* eslint-disable react/jsx-pascal-case */
import React from 'react';

import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import {
  CheckmarkOutline32,
  Number_132,
  Number_232,
  Number_332,
  Number_432,
} from '@developers/graphite/carbon-icons';
import { Button, ListItem, UnorderedList } from '@developers/graphite/carbon-components';
import { ValidatedLink } from '@developers/graphite';

import { useGlobalState, useMainState } from '@hooks';
import { URLPart } from '@enums';
import { ReactRouterValidatedLink } from '@shared';

type Props = {
  inquiryId: string | undefined;
};

const SuccessPageB2B: React.FC<Props> = ({ inquiryId }) => {
  const { t } = useTranslation();
  const { inquiryInfo } = useMainState();
  const { frontendSettings, brokerSettings } = useGlobalState();

  const routeManualDealBreak = `${frontendSettings.baseUrl}${URLPart.Broker}${URLPart.DealBreaks}?${brokerSettings?.brokerCode}&${URLPart.DealBreaksQuery}=${inquiryId}&${URLPart.BrokerHash}=${brokerSettings.brokerCode}`;

  const listItems: string[] = t('tender.successPage.listItemsb2b', { returnObjects: true });

  const orderedListCustomNumbers = [
    { icon: <Number_132 className="b2c-success__list-number" /> },
    { icon: <Number_232 className="b2c-success__list-number" /> },
    { icon: <Number_332 className="b2c-success__list-number" /> },
    { icon: <Number_432 className="b2c-success__list-number" /> },
  ];

  return (
    <div className="bx--grid container--custom">
      <div className="b2b-success__content">
        <div className="b2b-success__wrapper">
          <div className="b2b-success__icon">
            <CheckmarkOutline32 className="b2b-success__success-icon" />
          </div>
          <div className="b2b-success__title">{t('tender.successPage.title')}</div>
          <div className="b2c-success__sub-title">
            <Trans
              i18nKey="tender.successPage.subtitle"
              values={{ inquiryId }}
              components={[<ReactRouterValidatedLink key={0} url={routeManualDealBreak} />]}
            />
          </div>
          <div className="b2b-success__list-title">{t('tender.successPage.listTitle')}</div>

          <UnorderedList className="b2b-success__list">
            <ListItem className="b2b-success__list-item" data-qa="b2c-success__list-item">
              {orderedListCustomNumbers[0].icon}
              <span>
                <Trans
                  i18nKey="tender.successPage.listItem1"
                  components={[<ReactRouterValidatedLink key={0} url={routeManualDealBreak} />]}
                />
              </span>
            </ListItem>
            {listItems.map((item, index) => {
              return (
                <ListItem
                  className="b2b-success__list-item"
                  key={item}
                  data-qa="b2b-success__list-item"
                >
                  {orderedListCustomNumbers[index + 1].icon}
                  <span>{item}</span>
                </ListItem>
              );
            })}
          </UnorderedList>

          <ValidatedLink
            url={`${URLPart.ProductPage}/${inquiryInfo?.groupHash}`}
            render={(url) => (
              <Button as={Link} to={url} className="b2b-success__back-btn">
                {t('tender.successPage.buttonText')}
              </Button>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default SuccessPageB2B;
