import React from 'react';

import {
  ComposedModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from '@developers/graphite/carbon-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { URLPart } from '@enums';

type Props = {
  open: boolean;
  close: () => void;
  inquiryHash: string;
  offerAvailable: boolean;
};

const CancelTenderModal: React.FC<Props> = ({ open, close, inquiryHash, offerAvailable }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const redirectCancelRequest = (pageUrl: string) => {
    history.push(`${pageUrl}/${inquiryHash}`);
  };
  return (
    <ComposedModal
      open={open}
      preventCloseOnClickOutside={true}
      onClose={close}
      size="sm"
      data-qa="tender-cancel-modal"
    >
      <ModalHeader title={t('tender.cancelTenderRequestTitle')} />
      <ModalBody>
        <span>
          {offerAvailable
            ? `${t('tender.cancelTenderRequestTextResult')} ${t('general.changesWillNotBeSaved')}`
            : `${t('tender.cancelTenderRequestTextQuestionnaire')} ${t(
                'general.changesWillNotBeSaved'
              )}`}
        </span>
      </ModalBody>
      <ModalFooter>
        <Button onClick={close} kind="secondary">
          {t('general.doNotCancel')}
        </Button>
        <Button
          onClick={() =>
            redirectCancelRequest(offerAvailable ? URLPart.ResultList : URLPart.InquiryPage)
          }
          kind="primary"
          data-qa="tender-cancel-modal__button-redirect"
        >
          {t('general.cancelRequest')}
        </Button>
      </ModalFooter>
    </ComposedModal>
  );
};

export default CancelTenderModal;
