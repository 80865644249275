import React, { useEffect } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import {
  Button,
  ComboBox,
  TextInput,
  TextInputSkeleton,
  ButtonSkeleton,
} from '@developers/graphite/carbon-components';
import { ChevronRight16 } from '@developers/graphite/carbon-icons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { regexHelpers } from '@developers/toolbox';
import { ButtonWithLoading } from '@developers/graphite';

import { Customer, LegalForm, SaveCustomerAccountTypeEnum } from '@api/consultation/api';
import { consultationService } from '@services/consultation';
import { UserRoles } from '@enums';
import { useGlobalState } from '@hooks';
import { helpers } from '@helpers';

type Props = {
  customerInfo: Customer;
  legalForm: LegalForm[];
  consultationId: string;
  setShowQuestionnaire: (arg: boolean) => void;
  setShowCancelTenderModal: (arg: boolean) => void;
  isLoadingData: boolean;
};

const CustomerInfoForm: React.FC<Props> = ({
  customerInfo,
  legalForm,
  consultationId,
  setShowQuestionnaire,
  setShowCancelTenderModal,
  isLoadingData,
}) => {
  const { t } = useTranslation();
  const { sessions } = useGlobalState();
  const { control, errors, handleSubmit, getValues, reset } = useFormContext();

  const createCustomer = consultationService.useCreateCustomer();

  useEffect(() => {
    if (customerInfo) {
      reset(customerInfo);
    }
  }, [customerInfo, reset]);

  const submitHandler = () => {
    const values = getValues();

    const legalFormValue = legalForm.find((el) => el.id === values.company.legalForm.id);

    values.company.legalForm = legalFormValue?.id;

    createCustomer.mutate(
      {
        consultationId,
        body: {
          accountType: customerInfo.accountType as unknown as SaveCustomerAccountTypeEnum,
          company: values.company,
          contactPerson: values.contactPerson,
          billingAddress: values.billingAddress,
        },
      },
      {
        onSuccess: () => {
          setShowQuestionnaire(true);
        },
        onError: () => {
          toast.info(`${t('apiErrorMessage')}`);
        },
      }
    );
  };

  const customerInfoFormSkeleton = (
    <>
      <div className="customer-info-form__questions">
        <div className="customer-info-form__questions-row">
          <TextInputSkeleton />
          <TextInputSkeleton />
        </div>
        <div className="customer-info-form__questions-row">
          <TextInputSkeleton />
          <TextInputSkeleton />
        </div>
        <div className="customer-info-form__questions-row">
          <TextInputSkeleton />
          <TextInputSkeleton />
        </div>
      </div>
      <div className="customer-info-form__buttons">
        <ButtonSkeleton />
        <div className="customer-info-form__buttons-actions">
          <ButtonSkeleton />
        </div>
      </div>
    </>
  );

  return (
    <div className="customer-info-form">
      {isLoadingData ? (
        customerInfoFormSkeleton
      ) : (
        <>
          <div className="customer-info-form__questions">
            <div className="customer-info-form__questions-row">
              <Controller
                name="contactPerson.firstName"
                defaultValue={customerInfo?.contactPerson?.firstName ?? ''}
                control={control}
                render={({ onChange, value }) => (
                  <TextInput
                    id="someId"
                    labelText={`${t('general.firstName')}`}
                    placeholder={`${t('general.firstName')}`}
                    value={value}
                    invalid={!!(errors && errors.contactPerson && errors.contactPerson.firstName)}
                    invalidText={`${t('validationMessage.notEmpty')}`}
                    onChange={onChange}
                    light={true}
                  />
                )}
              />
              <Controller
                name="contactPerson.lastName"
                defaultValue={customerInfo?.contactPerson?.lastName ?? ''}
                control={control}
                render={({ onChange, value }) => (
                  <TextInput
                    id="someId"
                    labelText={`${t('general.lastName')}`}
                    placeholder={`${t('general.lastName')}`}
                    value={value}
                    invalid={!!(errors && errors.contactPerson && errors.contactPerson.lastname)}
                    invalidText={`${t('validationMessage.notEmpty')}`}
                    onChange={onChange}
                    light={true}
                  />
                )}
              />
            </div>
            <div className="customer-info-form__questions-row">
              <Controller
                name="contactPerson.phone"
                defaultValue={customerInfo?.contactPerson?.phone ?? ''}
                control={control}
                render={({ onChange, value }) => (
                  <TextInput
                    id="someId"
                    labelText={`${t('general.phoneLabel')}`}
                    placeholder={`${t('general.phoneLabel')}`}
                    value={value}
                    invalid={!!(errors && errors.contactPerson && errors.contactPerson.phone)}
                    invalidText={`${t('validationMessage.notEmpty')}`}
                    onChange={onChange}
                    light={true}
                  />
                )}
              />
              <Controller
                name="contactPerson.email"
                defaultValue={customerInfo?.contactPerson?.email ?? ''}
                control={control}
                rules={{
                  required:
                    // TODO: Remove this condition after migrating to Advisory
                    (helpers.envVariables.REACT_APP_IS_WIDGET ||
                      sessions.roles[0] === UserRoles.B2C) &&
                    `${t('validationMessage.notEmpty')}`,
                  pattern: {
                    value: regexHelpers.patterns.email,
                    message: `${t('validationMessage.wrongEmail')}`,
                  },
                }}
                render={({ onChange, value }) => (
                  <TextInput
                    id="someId"
                    labelText={`${t('general.emailLabel')}`}
                    placeholder={`${t('general.emailLabel')}`}
                    value={value}
                    invalid={!!(errors?.contactPerson && errors?.contactPerson)}
                    invalidText={errors?.contactPerson?.email.message}
                    onChange={onChange}
                    light={true}
                  />
                )}
              />
            </div>

            <div className="customer-info-form__questions-row">
              <Controller
                name="company.name"
                defaultValue={customerInfo?.company?.name ?? ''}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ onChange, value }) => (
                  <TextInput
                    id="someId"
                    labelText={`${t('general.companyName')}`}
                    placeholder={`${t('general.name')}`}
                    value={value}
                    invalid={!!(errors && errors.company && errors.company.name)}
                    invalidText={`${t('validationMessage.notEmpty')}`}
                    onChange={onChange}
                    light={true}
                  />
                )}
              />
              <Controller
                name="company.legalForm.id"
                defaultValue={customerInfo?.company?.legalForm?.name ?? ''}
                control={control}
                rules={{ required: true }}
                render={({ onChange, value }) => (
                  <ComboBox
                    id="legalFormId"
                    titleText={t('bookingFormLabels.legalForm')}
                    placeholder={t('general.pleaseChoose')}
                    onChange={({ selectedItem }) => {
                      if (selectedItem) {
                        onChange(selectedItem);
                      } else {
                        onChange(null);
                      }
                    }}
                    items={legalForm ? legalForm.map((item) => item.id) : []}
                    itemToString={(itemName) =>
                      legalForm?.find((item) => item.id === itemName)?.name || ''
                    }
                    selectedItem={value}
                    invalid={!!errors?.company?.legalForm}
                    invalidText={`${t('validationMessage.notEmpty')}`}
                    light={true}
                  />
                )}
              />
            </div>
            <div className="customer-info-form__questions-row">
              <Controller
                name="billingAddress.street"
                defaultValue={customerInfo?.billingAddress?.street ?? ''}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ onChange, value }) => (
                  <TextInput
                    id="someId"
                    labelText={`${t('general.streetName')}`}
                    placeholder={`${t('general.street')}`}
                    value={value}
                    invalid={!!errors?.billingAddress?.street}
                    invalidText={`${t('validationMessage.notEmpty')}`}
                    onChange={onChange}
                    light={true}
                  />
                )}
              />
              <Controller
                name="billingAddress.streetNumber"
                defaultValue={customerInfo?.billingAddress?.streetNumber ?? ''}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ onChange, value }) => (
                  <TextInput
                    id="someId"
                    labelText={`${t('general.streetNumber')}`}
                    placeholder={`${t('general.number')}`}
                    value={value}
                    invalid={!!errors?.billingAddress?.streetNumber}
                    invalidText={`${t('validationMessage.notEmpty')}`}
                    onChange={onChange}
                    light={true}
                  />
                )}
              />
            </div>
            <div className="customer-info-form__questions-row">
              <Controller
                name="billingAddress.postcode"
                defaultValue={customerInfo?.billingAddress?.postcode ?? ''}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ onChange, value }) => (
                  <TextInput
                    id="zipID"
                    labelText={`${t('general.postalCode')}`}
                    placeholder={`${t('general.zip')}`}
                    value={value}
                    invalid={!!(errors && errors.billingAddress && errors.billingAddress.postcode)}
                    invalidText={`${t('validationMessage.notEmpty')}`}
                    onChange={onChange}
                    light={true}
                    data-qa="customer-info-form__zipID"
                  />
                )}
              />
              <Controller
                name="billingAddress.city"
                defaultValue={customerInfo?.billingAddress?.city ?? ''}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ onChange, value }) => (
                  <TextInput
                    id="someId"
                    labelText={`${t('general.city')}`}
                    placeholder={`${t('general.city')}`}
                    value={value}
                    invalid={!!(errors && errors.billingAddress && errors.billingAddress.city)}
                    invalidText={`${t('validationMessage.notEmpty')}`}
                    onChange={onChange}
                    light={true}
                  />
                )}
              />
            </div>
          </div>
          <div className="customer-info-form__buttons">
            <Button
              kind="ghost"
              onClick={() => setShowCancelTenderModal(true)}
              data-qa="customer-info-form__buttons__cancel"
            >
              {t('general.cancel')}
            </Button>
            <div className="customer-info-form__buttons-actions">
              <ButtonWithLoading
                loading={createCustomer?.isLoading}
                loadingDescription={t('general.saving')}
                className="questionnaire-area__continue"
                renderIcon={ChevronRight16}
                onClick={handleSubmit(submitHandler)}
              >
                {t('general.next')}
              </ButtonWithLoading>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CustomerInfoForm;
