import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';

import { ConsultationApi, RangeList } from '@api/consultation';
import { ApiQuery } from '@enums';
import { helpers } from '@helpers';

const consultationInstance = new ConsultationApi(
  undefined,
  helpers.isWidget
    ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/consultation/v1`
    : '/api/consultation/v1'
);

const useRanges = (
  consultationId: string,
  options: UseQueryOptions<RangeList, AxiosError> = {}
): UseQueryResult<RangeList, AxiosError> =>
  useQuery(
    [ApiQuery.Ranges, consultationId],
    () => consultationInstance.getRangesRequest(consultationId).then((res) => res.data),
    { keepPreviousData: true, refetchOnMount: false, retry: false, ...options }
  );

const ranges = {
  useRanges,
};

export default ranges;
