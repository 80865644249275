import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';

import { Subscription, E2EB2CCreateSubscription, SubscriptionApi } from '@api/consultation';
import { helpers } from '@helpers';

const subscriptionInstance = new SubscriptionApi(
  undefined,
  helpers.isWidget
    ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/consultation/v1`
    : '/api/consultation/v1'
);

const useSubscription = (): UseMutationResult<
  Subscription | void,
  AxiosError,
  { inquiryId: string; body: E2EB2CCreateSubscription }
> =>
  useMutation((data: { inquiryId: string; body: E2EB2CCreateSubscription }) =>
    subscriptionInstance
      .e2EB2CSubscriptionRequest(data.inquiryId, data.body)
      .then((res) => res.data)
  );

const subscription = {
  useSubscription,
};

export default subscription;
