import React from 'react';

import { Pagination } from 'react-bootstrap';
import { useFeature } from 'flagged';
import { useTranslation } from 'react-i18next';

import { FeatureFlag, URLPart } from '@enums';

interface Props {
  currentPage: URLPart;
  pageOrder: URLPart[];
}

const BookingStep: React.FC<Props> = ({ currentPage, pageOrder }) => {
  const { t } = useTranslation();

  const showQuestionnairePage = useFeature(FeatureFlag.useQuestionnairePage);
  const showTenderPage = useFeature(FeatureFlag.useTenderPage);

  const currentPageIsActive =
    currentPage === URLPart.BookingPage || currentPage === URLPart.TenderPage;
  const currentPageIsCompleted =
    pageOrder.indexOf(currentPage) > pageOrder.indexOf(URLPart.BookingPage);

  return (
    <>
      <div className="step-title">
        {t('steps.tender')}/{t('steps.offer')}
      </div>
      <div>
        <Pagination className="justify-content-center mb-0">
          <Pagination.Item
            disabled={!currentPageIsCompleted && !currentPageIsActive}
            active={currentPageIsActive}
          >
            {showQuestionnairePage || showTenderPage ? 4 : 6}
          </Pagination.Item>
        </Pagination>
      </div>
    </>
  );
};

export default BookingStep;
