import React from 'react';

import { Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import { useFeature } from 'flagged';
import { Link } from 'react-router-dom';

import { HeaderTopBar } from '@shared';
import { FeatureFlag, URLPart } from '@enums';
import { useGlobalState, useMainState } from '@hooks';
import { Paths } from '@routes';

const Header: React.FC = () => {
  const showProductPage = useFeature(FeatureFlag.useProductPage);

  const { frontendSettings, contactInfo, brokerSettings, tokenDetails, settings } =
    useGlobalState();

  const { inquiryInfo } = useMainState();

  if (showProductPage && !tokenDetails) return null;

  if (!contactInfo) return null;

  const { name, phones, email, workingTime } =
    showProductPage && tokenDetails ? tokenDetails.account.contact : contactInfo;

  const productPageLink = `${URLPart.ProductPage}/${inquiryInfo?.groupHash}`;
  const landingPageLink = Paths.Root;

  return (
    <div data-qa="header-component">
      {settings?.hasTopBar && (
        <HeaderTopBar settings={settings} frontendSettings={frontendSettings} />
      )}
      <Row as="header">
        <Col xs={12} md={4}>
          {settings?.logoUrl && brokerSettings?.brokerCode && (
            <Link
              to={settings?.skipLandingPage ? productPageLink : landingPageLink}
              data-qa="header-logo"
            >
              <img className="logo" alt="Logo" src={`${settings.logoUrl}`} />
            </Link>
          )}
        </Col>
        <Col md={4} xs={12} className="no-image">
          {settings?.bannerHtml && (
            <div
              data-qa="header-text"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: settings.bannerHtml,
              }}
            />
          )}
        </Col>
        <Col xs={12} md={4} className="text-right p-0 d-print-none">
          {name && (
            <h5 data-qa="pool-name" className="h5 mb-1">
              {name}
            </h5>
          )}
          {phones?.map((item) => {
            return (
              <h4
                className={classNames('mb-0 font-weight-bold', {
                  'h6 mb-1': phones.length > 1 || item?.label,
                })}
                key={item.phone}
              >
                {item?.label && <span>{item.label}</span>}{' '}
                <a data-qa="pool-phone" href={`tel:${item.phone}`}>
                  {item.phone}
                </a>
              </h4>
            );
          })}
          {email && (
            <h6 data-qa="pool-email" className="h6 mb-1 text-secondary">
              {email}
            </h6>
          )}
          {workingTime && (
            <h6
              data-qa="pool-working-hours"
              className="h6 mb-0"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: workingTime,
              }}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Header;
