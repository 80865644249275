/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState, useCallback } from 'react';

import axios from 'axios';
import axiosQueries from '@developers/queries/axiosInstance';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';

import { FrontendSettings } from '@hooks/queries/useFrontendSettings';
import { Sessions } from '@hooks/queries/useCheckSession';
import { Settings } from '@hooks/queries/useSettings';
import { PoolDesign } from '@hooks/queries/usePoolDesign';
import { ContactInfo } from '@hooks/queries/useContactInfo';
import { BrokerSettings } from '@hooks/queries/useBrokerSettings';
import { helpers } from '@helpers';
import { ParamTypes } from '@typings/params';
import {
  useFrontendSettings,
  useBrokerSettings,
  useSettings,
  usePoolDesign,
  useTokenDetails,
  useContactInfo,
} from '@hooks';
import { SessionStatus, ProfessionsPart, URLPart } from '@enums';
import { authorizationApi } from '@services';
import { tokenDetail } from '@services/broker';
import { professionAliasService } from '@services/masterData';
import { inquiryGroupService } from '@services/e2e';
import { consultationService } from '@services/consultation';

import useGlobalStore from '../useGlobalStore';
import { Paths } from '../Routes';

import useSession from './useSession';

export interface BootingServices {
  data: {
    frontendSettings: FrontendSettings | undefined;
    session: Sessions | undefined | null;
    settings: Settings | undefined;
    poolDesign: PoolDesign[] | undefined;
    tokenDetails: any | undefined;
    contactInfo: ContactInfo | undefined;
    brokerSettings: BrokerSettings | undefined;
  };
  loading: boolean;
  success: {
    useFrontendSettingsSuccess: boolean;
    useCheckSessionSucceeded: boolean;
    useSettingsSucceeded: boolean;
    usePoolDesignSuccess: boolean;
    useTokenDetailsSuccess: boolean;
    useContactInfoSuccess: boolean;
    useBrokerSettingsSuccess: boolean;
  };
  errors: (Error | null)[];
}

const { isWidget } = helpers;

const useBootingServices = (): BootingServices => {
  const history = useHistory();
  const { inquiryHash: inquiryHashParam, inquiryGroupHash } = useParams<ParamTypes>();
  const matchHomeAndRoot = useRouteMatch([Paths.Home, Paths.Root]);
  const matchRoot = useRouteMatch([Paths.Root]);
  const portalConfig = useGlobalStore((state) => state.portalConfig);
  const [authFetched, setAuthFetched] = useState({
    brokerCode: false,
    brokerCodeAndConsultationHash: false,
    brokerCodeAndInquiryHash: false,
  });

  const isAuthReady = useMemo(
    () =>
      authFetched.brokerCode ||
      authFetched.brokerCodeAndConsultationHash ||
      authFetched.brokerCodeAndInquiryHash,
    [authFetched]
  );

  const brokerCodeAuthorizationRequest = authorizationApi.broker.useBrokerCodeAuthorizationRequest({
    enabled: isWidget && !!portalConfig?.brokerCode,
  });

  useEffect(() => {
    if (
      isWidget &&
      !portalConfig?.productId &&
      (!portalConfig?.professionAliasIds || !portalConfig.professionAliasIds.length) &&
      portalConfig?.fallbackUrl &&
      matchRoot?.isExact
    ) {
      window.location.replace(portalConfig.fallbackUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portalConfig]);

  const createInquiryGroup = inquiryGroupService.useCreateInquiryGroup();
  const createInquiry = consultationService.useCreateInquiry();

  const setAuthToken = useCallback(
    ({
      code,
      consultationHash,
      inquiryHash,
      onSuccess,
    }: {
      code: string;
      consultationHash?: string;
      inquiryHash?: string;
      onSuccess?: () => void;
    }) => {
      brokerCodeAuthorizationRequest.mutate(
        {
          code,
          consultationHash,
          inquiryHash,
        },
        {
          onSuccess: (data) => {
            axios.defaults.headers.common.Authorization = `Bearer ${data.id}`;
            axiosQueries.defaults.headers.common.Authorization = `Bearer ${data.id}`;
            onSuccess && onSuccess();
          },
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [brokerCodeAuthorizationRequest.mutate]
  );

  useEffect(() => {
    if (portalConfig?.brokerCode && isWidget) {
      setAuthToken({
        code: portalConfig?.brokerCode,
        inquiryHash: inquiryHashParam,
        consultationHash: inquiryGroupHash,
        onSuccess: () => {
          if (portalConfig?.brokerCode && !inquiryHashParam && !inquiryGroupHash) {
            setAuthFetched({ ...authFetched, brokerCode: true });
          }
          if (portalConfig?.brokerCode && inquiryHashParam && !inquiryGroupHash) {
            setAuthFetched({ ...authFetched, brokerCodeAndConsultationHash: true });
          }
          if (portalConfig?.brokerCode && !inquiryHashParam && inquiryGroupHash) {
            setAuthFetched({ ...authFetched, brokerCodeAndInquiryHash: true });
          }
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portalConfig?.brokerCode, inquiryHashParam, inquiryGroupHash]);

  /** Handle logic if portalConfig has productAliasIds or productId */
  const { isLoading: useProfessionAliasesLoading } = professionAliasService.useProfessionAliases(
    portalConfig?.professionAliasIds ? portalConfig?.professionAliasIds.map(String) : [],
    {
      enabled:
        matchHomeAndRoot?.isExact && !!portalConfig?.professionAliasIds && authFetched.brokerCode,

      onSuccess: (data) => {
        const professionAliasesInquiryGroup = data._embedded.professionAliases.map(
          (professionAlias) => ({
            name: '',
            professionAlias: `${ProfessionsPart.Aliases}${professionAlias.hash}`,
          })
        );

        createInquiryGroup.mutate(
          {
            professionAliases: professionAliasesInquiryGroup,
          },
          {
            onSuccess: (inquiryGroupData) => {
              if (portalConfig?.brokerCode) {
                setAuthToken({
                  code: portalConfig?.brokerCode,
                  consultationHash: inquiryGroupData.hash,
                  onSuccess: () =>
                    setAuthFetched({ ...authFetched, brokerCodeAndConsultationHash: true }),
                });
              }

              if (!portalConfig?.productId) {
                history.replace(`${URLPart.ProductPage}/${inquiryGroupData.hash}`);
              }
            },
          }
        );
      },
    }
  );

  /** Handle logic if portalConfig has productAliasIds and productId */
  const { isLoading: useConsultationListLoading } = consultationService.useConsultationList(
    { hash: createInquiryGroup.data?.hash },
    {
      enabled:
        !!portalConfig?.productId &&
        !!createInquiryGroup.data?.hash &&
        authFetched.brokerCodeAndConsultationHash,
      onSuccess: (consultationsData) => {
        createInquiry.mutate(
          {
            consultationId: consultationsData._embedded.consultations[0].id,
            body: { productIds: [(portalConfig?.productId || '').toString()] },
          },
          {
            onSuccess: (data) => {
              history.replace(`${URLPart.InquiryPage}/${data[0].hash}`);
            },
          }
        );
      },
    }
  );

  /** B2C Token details call */
  const {
    data: b2cTokenDetails,
    isSuccess: useB2CTokenDetailsSuccess,
    isLoading: useB2CTokenDetailsLoading,
    error: useB2CTokenDetailsError,
  } = tokenDetail.useTokenDetail({
    enabled: isAuthReady,
  });

  const { data: session, status, error: useCheckSessionError } = useSession();

  const {
    data: frontendSettings,
    isSuccess: useFrontendSettingsSuccess,
    isLoading: useFrontendSettingsLoading,
    error: useFrontendSettingsError,
  } = useFrontendSettings();

  /** B2B Token details call */
  const {
    data: b2bTokenDetails,
    isSuccess: useB2BTokenDetailsSuccess,
    isLoading: useB2BTokenDetailsLoading,
    error: useB2BTokenDetailsError,
  } = useTokenDetails({
    enabled: !isWidget && !!frontendSettings?.baseUrl && status === SessionStatus.Authenticated,
  });

  const {
    data: contactInfo,
    isSuccess: useContactInfoSuccess,
    isLoading: useContactInfoLoading,
    error: useContactInfoError,
  } = useContactInfo({
    enabled: isWidget
      ? isAuthReady
      : !!frontendSettings?.baseUrl && status === SessionStatus.Authenticated,
  });

  const {
    data: brokerSettings,
    isSuccess: useBrokerSettingsSuccess,
    isLoading: useBrokerSettingsLoading,
    error: useBrokerSettingsError,
  } = useBrokerSettings({
    enabled: isWidget
      ? isAuthReady
      : !!frontendSettings?.baseUrl && status === SessionStatus.Authenticated,
  });

  const {
    data: settings,
    isSuccess: useSettingsSucceeded,
    isLoading: useSettingsLoading,
    error: useSettingsError,
  } = useSettings({
    enabled: isWidget
      ? isAuthReady
      : !!frontendSettings?.baseUrl && status === SessionStatus.Authenticated,
  });

  const {
    data: poolDesign,
    isSuccess: usePoolDesignSuccess,
    isLoading: usePoolDesignLoading,
    error: usePoolDesignError,
  } = usePoolDesign(settings?.poolId, {
    enabled: useSettingsSucceeded,
  });

  const responseData = () => {
    return {
      data: {
        frontendSettings,
        session,
        settings,
        poolDesign,
        tokenDetails: b2cTokenDetails || b2bTokenDetails,
        contactInfo,
        brokerSettings,
      },
      loading:
        useFrontendSettingsLoading ||
        (helpers.isWidget ? false : status === SessionStatus.Loading) ||
        useSettingsLoading ||
        usePoolDesignLoading ||
        useB2CTokenDetailsLoading ||
        useB2BTokenDetailsLoading ||
        useContactInfoLoading ||
        useBrokerSettingsLoading ||
        (helpers.isWidget ? brokerCodeAuthorizationRequest.isLoading : false) ||
        createInquiryGroup.isLoading ||
        createInquiry.isLoading ||
        useProfessionAliasesLoading ||
        useConsultationListLoading,
      success: {
        useFrontendSettingsSuccess,
        useSettingsSucceeded,
        useCheckSessionSucceeded: status === SessionStatus.Authenticated,
        usePoolDesignSuccess,
        useTokenDetailsSuccess: useB2CTokenDetailsSuccess || useB2BTokenDetailsSuccess,
        useContactInfoSuccess,
        useBrokerSettingsSuccess,
      },
      errors: [
        useFrontendSettingsError,
        useCheckSessionError,
        useBrokerSettingsError,
        useSettingsError,
        usePoolDesignError,
        useB2CTokenDetailsError || useB2BTokenDetailsError,
        useContactInfoError,
        brokerCodeAuthorizationRequest.error,
      ].filter((err) => err),
    };
  };

  return responseData();
};

export default useBootingServices;
