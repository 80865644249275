import React, { useState } from 'react';

import {
  Tabs,
  Tab,
  NumberInput,
  Button,
  ComposedModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  InlineLoading,
} from '@developers/graphite/carbon-components';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { AnyObject } from 'immer/dist/internal';

import { discountsService, offerServices, configuration } from '@services/offer';
import { inquiriesService } from '@services/consultation';
import { useGlobalState, useMainState } from '@hooks';
import { AppliedDiscountDto, DiscountDto } from '@api/offerOrchestrator';
import useStore from '@pages/Result/zustand';
import { Loader } from '@shared';
import { FilterValues, DiscountType } from '@enums';

// TODO: refactor to use @pages alias here and in all similar situations import hooks from '../../hooks';
import { OwnDiscountContent } from '..';
import { useSetActiveParams } from '../../hooks';

interface Props {
  discountValue: number;
  gross: string;
  finalPrice: string;
  currency: string;
  id: string;
  open: boolean;
  toggleComposeModal: (arg: boolean) => void;
  isOwnDiscount: boolean;
  offerGroupId: string;
}

const DiscountModal: React.FC<Props> = ({
  discountValue,
  finalPrice,
  currency,
  id,
  open,
  isOwnDiscount,
  gross,
  toggleComposeModal,
  offerGroupId,
}) => {
  const { t } = useTranslation();
  const [numberValue, changeNumberValue] = useState(discountValue.toString());
  const [loadData, setLoadData] = useState(false);
  const [isOwnDiscountTab, changeisOwnDiscountTab] = useState(false);
  const { settings } = useGlobalState();
  const addDiscount = discountsService.useAddDicount();
  const updateDiscount = discountsService.useUpdateDicount();
  const deleteDiscount = discountsService.useDeleteDicount();
  const [fetchedDiscountType, setFetchedDiscountType] = useState('');
  const [calculatedDiscountValue, changecalculatedDiscountValue] = useState(0);
  const [isSaveInlineLoader, setIsSaveInlineLoader] = useState(false);
  const [isClearInlineLoader, setIsClearInlineLoader] = useState(false);

  // TODO Remove as much as possible from the zustand
  const state = useStore();
  const { inquiryData } = useMainState();

  const { data: offerGroupDetails } = inquiriesService.useGetOfferGroup(inquiryData?.id.toString());
  const { data: configurationData } = configuration.useGetConfiguration(
    offerGroupDetails?.id as string
  );
  const configurationSettings = configurationData?.settings;

  const displayOwnDiscountsTab =
    !configurationSettings?.hideBookingDiscount || !configurationSettings?.hideTargetPremium;

  const {
    data: discountData,
    isFetching,
    status,
  } = discountsService.useDicountData(id, offerGroupId, {
    onSuccess: (data: AnyObject) => {
      const isTargetDiscountApplied = data.discounts.some(
        (discount: { type: string }) => discount.type === DiscountType.TargetDiscount
      );
      state.setDiscountType(
        // TMP If discount type is 'account-discount', for now we have to ignore it and set default one as 'booking'
        isTargetDiscountApplied || configurationSettings?.hideBookingDiscount
          ? DiscountType.TargetDiscount
          : DiscountType.BookingDiscount
      );
      setFetchedDiscountType(data?.discounts[0].type);
      state.setAgreementDate(data?.discounts[0].agreementDate);
      state.setCoordinatedWith(data?.discounts[0].coordinatedWith);
      state.setPrice(data?.discountedGrossPremium / 100);
      state.setComment(data?.discounts[0].comment);
      state.setDiscount(data?.appliedDiscount);
    },
    onError: () => {
      state.setDiscountType(
        configurationSettings?.hideBookingDiscount
          ? DiscountType.TargetDiscount
          : DiscountType.BookingDiscount
      );
      state.setAgreementDate('');
      state.setCoordinatedWith('');
      state.setPrice(0);
      state.setComment('');
      state.setDiscount(0);
    },
  });

  // TODO Move some function to helpers or create custom hooks if needed
  const calculateDiscountValue = (value: number) => {
    const numVal1 = parseInt(gross, 10) / 100;
    const numVal2 = value / 100;
    const totalValue = numVal1 - numVal1 * numVal2;
    return totalValue.toFixed(2);
  };

  const enableDepositeDiscount = () => {
    if (parseInt(numberValue, 10) <= discountValue) {
      return true;
    }
    return false;
  };

  const enableOwnDiscount = () => {
    if (
      (state.discount >= 0 || state.price >= 0) &&
      state.coordinatedWith?.length > 5 &&
      state.agreementDate
    ) {
      return true;
    }
    return false;
  };

  const isOwnDiscountTabOrModal = () => {
    if (isOwnDiscountTab || isOwnDiscount || isTargetOrBookingDiscountApplied) {
      return true;
    }
    return false;
  };

  const closeModalAndUpdateOffers = () => {
    setLoadData(true);
    setLoadData(false);
    toggleComposeModal(false);
  };

  const onDeleteDiscount = () => {
    const mutateVariables = {
      id: offerGroupId as string,
      offerId: id as string,
      type: state.discountType,
    };
    deleteDiscount.mutate(mutateVariables, {
      onSuccess: () => {
        closeModalAndUpdateOffers();
        setIsClearInlineLoader(false);
        state.setAgreementDate('');
        state.setComment('');
        state.setPrice(0);
        state.setDiscount(0);
        state.setCoordinatedWith('');
      },
      onError: () => {
        setIsClearInlineLoader(false);
        toast.info(`${t('apiErrorMessage')}`);
      },
    });
  };
  const onAddDiscount = () => {
    const mutateVariables = {
      id: offerGroupId as string,
      offerId: id as string,
      discountDto:
        state.discountType === `${DiscountType.TargetDiscount}`
          ? ({
              type: state.discountType,
              coordinatedWith: state.coordinatedWith,
              agreementDate: state.agreementDate,
              comment: state.comment,
              price: state.price * 100,
            } as DiscountDto)
          : ({
              type: state.discountType,
              coordinatedWith: state.coordinatedWith,
              discountPercentage: state.discount,
              agreementDate: state.agreementDate,
              comment: state.comment,
            } as DiscountDto),
    };

    if (status === 'success') {
      const deleteMutateVariables = {
        id: offerGroupId as string,
        offerId: id as string,
        type: fetchedDiscountType,
      };
      deleteDiscount.mutate(deleteMutateVariables, {
        onSuccess: () => {
          addDiscount.mutate(mutateVariables, {
            onSuccess: () => {
              setIsSaveInlineLoader(false);
              closeModalAndUpdateOffers();
            },
            onError: () => {
              setIsSaveInlineLoader(false);
              toast.info(`${t('apiErrorMessage')}`);
            },
          });
        },
        onError: () => {
          setIsClearInlineLoader(false);
          toast.info(`${t('apiErrorMessage')}`);
        },
      });
    } else {
      addDiscount.mutate(mutateVariables, {
        onSuccess: () => {
          setIsSaveInlineLoader(false);
          closeModalAndUpdateOffers();
        },
        onError: () => {
          setIsSaveInlineLoader(false);
          toast.info(`${t('apiErrorMessage')}`);
        },
      });
    }
  };

  const onUpdateDiscount = () => {
    const mutateVariables = {
      id: offerGroupId as string,
      offerId: id as string,
      appliedDiscount: {
        appliedDiscount: parseInt(numberValue, 10),
      } as AppliedDiscountDto,
    };
    updateDiscount.mutate(mutateVariables, {
      onSuccess: () => {
        closeModalAndUpdateOffers();
        setIsSaveInlineLoader(false);
      },
      onError: () => {
        setIsSaveInlineLoader(false);
        toast.info(`${t('apiErrorMessage')}`);
      },
    });
  };

  const params = {
    offset: 0,
    limit: 10,
    pagination: 1,
    origin: FilterValues.ResultPage,
  };
  const { params: filterParams, paramsCreated: loadOffers } = useSetActiveParams(params, loadData);
  offerServices.useGetOffers(offerGroupId, filterParams, loadOffers);

  // eslint-disable-next-line react/no-danger
  const formatedCurrency = <span dangerouslySetInnerHTML={{ __html: `&#${currency};` }} />; // TODO remove dangerouslySetInnerHTML
  const methods = useForm();

  const isTargetOrBookingDiscountApplied = discountData?.discounts.some(
    (discount: { type: string }) =>
      discount.type === DiscountType.TargetDiscount ||
      discount.type === DiscountType.BookingDiscount
  );

  const selectedTab = isTargetOrBookingDiscountApplied || !discountData ? 1 : 0;
  return (
    <FormProvider {...methods}>
      <ComposedModal
        open={open}
        preventCloseOnClickOutside={true}
        onClose={() => toggleComposeModal(false)}
      >
        <ModalHeader
          title={isOwnDiscount ? t('discountModal.ownDiscountTitle') : t('result.discount')}
        />
        {!isFetching ? (
          <ModalBody>
            <div className="discount-modal">
              <Tabs selected={selectedTab}>
                <Tab
                  data-qa="deposited-discount"
                  id="result-discount-modul-1"
                  label={t('discountModal.depositedDiscount')}
                  onClick={() => changeisOwnDiscountTab(false)}
                  disabled={isTargetOrBookingDiscountApplied || !discountData}
                >
                  {/* TODO create component from this tab data */}
                  <p className="mb-5">
                    {`${t('discountModal.discountDescPart1')} ${settings?.poolName} ${t(
                      'discountModal.discountDescPart2'
                    )} ${settings?.poolName} ${t('discountModal.discountDescPart3')}`}
                  </p>
                  <div className="bx--row">
                    <div className="bx--col-md-4">
                      <NumberInput
                        id="result-discount-modul-number-input"
                        invalidText={`${t(
                          'discountModal.invalidDiscountStart'
                        )} ${discountValue}% ${t('discountModal.invalidDiscountEnd')}`}
                        label={`${t('result.discount')} (${t('general.in')} %)`}
                        max={100}
                        min={0}
                        step={5}
                        value={isNaN(parseInt(numberValue, 10)) ? 0 : parseInt(numberValue, 10)}
                        onChange={(
                          e:
                            | React.ChangeEvent<HTMLInputElement>
                            | React.MouseEvent<HTMLButtonElement>
                        ) => {
                          // TODO: FIX after type is is added into library https://github.com/carbon-design-system/carbon/issues/8667
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          changeNumberValue(
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            (e as any).imaginaryTarget.value === ''
                              ? '0'
                              : // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                (e as any).imaginaryTarget.value
                          );
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          changecalculatedDiscountValue((e as any).imaginaryTarget.value);
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        }}
                        invalid={parseInt(numberValue, 10) > discountValue}
                      />
                    </div>
                    <div className="bx--col-md-4 pt-7">
                      <div className="discount-modal__price">
                        {/* TODO: Add price changes when NumberInpit is changed  */}
                        {t('discountModal.premium')}:
                        {calculatedDiscountValue === 0
                          ? parseInt(finalPrice, 10) / 100
                          : calculateDiscountValue(calculatedDiscountValue)}
                        {formatedCurrency}
                      </div>
                    </div>
                  </div>
                </Tab>
                {displayOwnDiscountsTab && (
                  <Tab
                    data-qa="own-discount"
                    id="result-discount-modul-2"
                    label={t('discountModal.ownDiscountTitle')}
                    onClick={() => changeisOwnDiscountTab(true)}
                  >
                    <OwnDiscountContent currency={currency} id={id} gross={gross} />
                  </Tab>
                )}
              </Tabs>
            </div>
          </ModalBody>
        ) : (
          <Loader />
        )}
        <ModalFooter>
          <Button kind="ghost" onClick={() => toggleComposeModal(false)}>
            {t('general.cancel')}
          </Button>
          {isOwnDiscountTabOrModal() && fetchedDiscountType !== '' && (
            <Button
              kind="secondary"
              onClick={() => {
                onDeleteDiscount();
                setIsClearInlineLoader(true);
              }}
            >
              {t('general.clear')}
              {isClearInlineLoader && <InlineLoading className="inline-loader" />}
            </Button>
          )}
          {fetchedDiscountType === '' && (
            <Button kind="secondary" disabled>
              {t('general.clear')}
            </Button>
          )}
          {enableOwnDiscount() && isOwnDiscountTabOrModal() && (
            <Button
              onClick={() => {
                onAddDiscount();
                setIsSaveInlineLoader(true);
              }}
              kind="primary"
            >
              {t('general.save')}
              {isSaveInlineLoader && <InlineLoading className="inline-loader" />}
            </Button>
          )}
          {!enableOwnDiscount() && isOwnDiscountTabOrModal() && (
            <Button kind="primary" disabled>
              {t('general.save')}
            </Button>
          )}
          {enableDepositeDiscount() && !isOwnDiscountTabOrModal() && (
            <Button
              onClick={() => {
                onUpdateDiscount();
                setIsSaveInlineLoader(true);
              }}
              kind="primary"
            >
              {t('general.save')}
              {isSaveInlineLoader && <InlineLoading className="inline-loader" />}
            </Button>
          )}
          {!enableDepositeDiscount() && !isOwnDiscountTabOrModal() && (
            <Button kind="primary" disabled>
              {t('general.save')}
            </Button>
          )}
        </ModalFooter>
      </ComposedModal>
    </FormProvider>
  );
};

export default DiscountModal;
