import React from 'react';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  Modal,
  ModalBody,
  SkeletonText,
  StructuredListBody,
  StructuredListCell,
  StructuredListRow,
  StructuredListWrapper,
} from '@developers/graphite/carbon-components';

import { useMainState } from '@hooks';
import { inquiriesService } from '@services/e2e';

type Props = {
  show: boolean;
  handleClose: () => void;
};

const QuestionModal: React.FC<Props> = ({ show, handleClose }) => {
  const { t } = useTranslation();
  const { inquiryInfo } = useMainState();

  const {
    data: questionInfo,
    isLoading,
    isFetching,
  } = inquiriesService.useQuestionInfo(inquiryInfo?.hash || '', {
    enabled: show && !!inquiryInfo?.hash,
    onError: () => {
      toast.info(`${t('apiErrorMessage')}`);
    },
  });

  return (
    <Modal
      open={show}
      onRequestClose={handleClose}
      passiveModal
      preventCloseOnClickOutside
      size="sm"
      modalHeading={t('questionModal.information')}
    >
      <ModalBody>
        <StructuredListWrapper isCondensed>
          <StructuredListBody>
            <StructuredListRow>
              <StructuredListCell>{t('questionModal.insurance')}</StructuredListCell>
              <StructuredListCell>{inquiryInfo?.productName}</StructuredListCell>
            </StructuredListRow>
            <StructuredListRow>
              <StructuredListCell>{t('questionModal.business')}</StructuredListCell>
              <StructuredListCell>{inquiryInfo?.professionAlias}</StructuredListCell>
            </StructuredListRow>
            {isLoading || isFetching ? (
              <StructuredListRow>
                <StructuredListCell>
                  <SkeletonText />
                </StructuredListCell>
              </StructuredListRow>
            ) : (
              questionInfo?.map(({ questionId, question, values }) => {
                return (
                  <StructuredListRow key={questionId}>
                    <StructuredListCell>{question}</StructuredListCell>
                    <StructuredListCell>
                      {values?.map((value: string) => {
                        return <div key={value}>{value}</div>;
                      })}
                    </StructuredListCell>
                  </StructuredListRow>
                );
              })
            )}
          </StructuredListBody>
        </StructuredListWrapper>
      </ModalBody>
    </Modal>
  );
};

export default QuestionModal;
