/* tslint:disable */
/* eslint-disable */
/**
 * Hello API Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BrokerBrokerRead
 */
export interface BrokerBrokerRead {
    /**
     * 
     * @type {number}
     * @memberof BrokerBrokerRead
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BrokerBrokerRead
     */
    brokerCode?: string;
    /**
     * 
     * @type {PoolBrokerRead}
     * @memberof BrokerBrokerRead
     */
    pool?: PoolBrokerRead;
    /**
     * 
     * @type {string}
     * @memberof BrokerBrokerRead
     */
    fullName?: string;
}
/**
 * compares Elements(e.g. insurances, phones) and its features.
 * @export
 * @interface ComparisonComparisonGetFeaturesStructureGetOfferGetFeatureGet
 */
export interface ComparisonComparisonGetFeaturesStructureGetOfferGetFeatureGet {
    /**
     * identifier, e.g. inquiry hash.
     * @type {string}
     * @memberof ComparisonComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    inquiryHash?: string;
    /**
     * 
     * @type {FeaturesStructureComparisonGetFeaturesStructureGetOfferGetFeatureGet}
     * @memberof ComparisonComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    featuresStructure?: FeaturesStructureComparisonGetFeaturesStructureGetOfferGetFeatureGet;
    /**
     * comparison elements and its features.
     * @type {Array<OfferComparisonGetFeaturesStructureGetOfferGetFeatureGet>}
     * @memberof ComparisonComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    elements?: Array<OfferComparisonGetFeaturesStructureGetOfferGetFeatureGet>;
    /**
     * associative  array with extra information; possible keys: isProductHaft.
     * @type {Array<string>}
     * @memberof ComparisonComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    extraInfo?: Array<string>;
}
/**
 * 
 * @export
 * @interface ComparisonSubscription
 */
export interface ComparisonSubscription {
    /**
     * identifier, e.g. inquiry hash.
     * @type {string}
     * @memberof ComparisonSubscription
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ComparisonSubscription
     */
    subscribed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSubscription
     */
    inquiryHash?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ComparisonSubscription
     */
    calculationHashes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSubscription
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSubscription
     */
    phone?: string;
}
/**
 * 
 * @export
 * @interface ComparisonSubscriptionPost
 */
export interface ComparisonSubscriptionPost {
    /**
     * 
     * @type {string}
     * @memberof ComparisonSubscriptionPost
     */
    inquiryHash: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ComparisonSubscriptionPost
     */
    calculationHashes: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSubscriptionPost
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSubscriptionPost
     */
    phone?: string;
}
/**
 * 
 * @export
 * @interface ContractOfferOfferGetFeatureGet
 */
export interface ContractOfferOfferGetFeatureGet {
    /**
     * contractId OR calculationHash.
     * @type {string}
     * @memberof ContractOfferOfferGetFeatureGet
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ContractOfferOfferGetFeatureGet
     */
    type: number;
    /**
     * 
     * @type {string}
     * @memberof ContractOfferOfferGetFeatureGet
     */
    inquiry: string;
    /**
     * 
     * @type {InsurerOfferGetFeatureGet}
     * @memberof ContractOfferOfferGetFeatureGet
     */
    insurer: InsurerOfferGetFeatureGet;
    /**
     * 
     * @type {OfferDetailsOfferGetFeatureGet}
     * @memberof ContractOfferOfferGetFeatureGet
     */
    details: OfferDetailsOfferGetFeatureGet;
}
/**
 * 
 * @export
 * @interface ContractOfferOfferPostFeaturePost
 */
export interface ContractOfferOfferPostFeaturePost {
    /**
     * contractId OR calculationHash.
     * @type {string}
     * @memberof ContractOfferOfferPostFeaturePost
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ContractOfferOfferPostFeaturePost
     */
    type: number;
    /**
     * 
     * @type {string}
     * @memberof ContractOfferOfferPostFeaturePost
     */
    inquiry: string;
    /**
     * 
     * @type {string}
     * @memberof ContractOfferOfferPostFeaturePost
     */
    insurer: string;
    /**
     * 
     * @type {OfferDetailsOfferPostFeaturePost}
     * @memberof ContractOfferOfferPostFeaturePost
     */
    details: OfferDetailsOfferPostFeaturePost;
}
/**
 * 
 * @export
 * @interface CustomerCustomerRead
 */
export interface CustomerCustomerRead {
    /**
     * 
     * @type {number}
     * @memberof CustomerCustomerRead
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerCustomerRead
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCustomerRead
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCustomerRead
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCustomerRead
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCustomerRead
     */
    streetNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCustomerRead
     */
    zip?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCustomerRead
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCustomerRead
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCustomerRead
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCustomerRead
     */
    salutation?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCustomerRead
     */
    title?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerCustomerRead
     */
    legalForm?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerCustomerRead
     */
    fullName?: string;
}
/**
 * 
 * @export
 * @interface CustomerCustomerWrite
 */
export interface CustomerCustomerWrite {
    /**
     * 
     * @type {string}
     * @memberof CustomerCustomerWrite
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCustomerWrite
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCustomerWrite
     */
    inquiryHash?: string;
}
/**
 * 
 * @export
 * @interface DocumentCollectionDocumentRead
 */
export interface DocumentCollectionDocumentRead {
    /**
     * 
     * @type {Array<DocumentDocumentRead>}
     * @memberof DocumentCollectionDocumentRead
     */
    documents?: Array<DocumentDocumentRead>;
}
/**
 * 
 * @export
 * @interface DocumentDocumentRead
 */
export interface DocumentDocumentRead {
    /**
     * 
     * @type {string}
     * @memberof DocumentDocumentRead
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDocumentRead
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDocumentRead
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDocumentRead
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDocumentRead
     */
    mimeType?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDocumentRead
     */
    extension?: string;
}
/**
 * 
 * @export
 * @interface DocumentLandingpagesettingsReadDocumentRead
 */
export interface DocumentLandingpagesettingsReadDocumentRead {
    /**
     * 
     * @type {string}
     * @memberof DocumentLandingpagesettingsReadDocumentRead
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentLandingpagesettingsReadDocumentRead
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentLandingpagesettingsReadDocumentRead
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentLandingpagesettingsReadDocumentRead
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentLandingpagesettingsReadDocumentRead
     */
    mimeType?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentLandingpagesettingsReadDocumentRead
     */
    extension?: string;
}
/**
 * 
 * @export
 * @interface E2EContactInfo
 */
export interface E2EContactInfo {
    /**
     * 
     * @type {string}
     * @memberof E2EContactInfo
     */
    brokerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof E2EContactInfo
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof E2EContactInfo
     */
    phones?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof E2EContactInfo
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof E2EContactInfo
     */
    workingTime?: string;
    /**
     * 
     * @type {string}
     * @memberof E2EContactInfo
     */
    phone?: string;
}
/**
 * 
 * @export
 * @interface E2ESettings
 */
export interface E2ESettings {
    /**
     * 
     * @type {string}
     * @memberof E2ESettings
     */
    brokerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof E2ESettings
     */
    brokerName?: string;
    /**
     * 
     * @type {number}
     * @memberof E2ESettings
     */
    poolId?: number;
    /**
     * 
     * @type {string}
     * @memberof E2ESettings
     */
    poolTag?: string;
    /**
     * 
     * @type {string}
     * @memberof E2ESettings
     */
    poolName?: string;
    /**
     * 
     * @type {string}
     * @memberof E2ESettings
     */
    brokerPortalLoginUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof E2ESettings
     */
    hasComparisonSubscription?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof E2ESettings
     */
    hasTopBar?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof E2ESettings
     */
    hasLandingPageUrlOnTopBar?: boolean;
    /**
     * 
     * @type {string}
     * @memberof E2ESettings
     */
    externalWebsiteUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof E2ESettings
     */
    externalWebsiteLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof E2ESettings
     */
    bannerHtml?: string;
    /**
     * 
     * @type {string}
     * @memberof E2ESettings
     */
    logoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof E2ESettings
     */
    generalConditionsUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof E2ESettings
     */
    privacyPolicyUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof E2ESettings
     */
    hasPreviousContract?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof E2ESettings
     */
    hasBrokerTutorial?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof E2ESettings
     */
    skipLandingPage?: boolean;
}
/**
 * 
 * @export
 * @interface FeatureComparisonGetFeaturesStructureGetOfferGetFeatureGet
 */
export interface FeatureComparisonGetFeaturesStructureGetOfferGetFeatureGet {
    /**
     * 
     * @type {number}
     * @memberof FeatureComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FeatureComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    formattedValue?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    iconType?: string;
}
/**
 * 
 * @export
 * @interface FeatureFeatureFeatureGet
 */
export interface FeatureFeatureFeatureGet {
    /**
     * 
     * @type {number}
     * @memberof FeatureFeatureFeatureGet
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FeatureFeatureFeatureGet
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureFeatureFeatureGet
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureFeatureFeatureGet
     */
    formattedValue?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureFeatureFeatureGet
     */
    iconType?: string;
}
/**
 * 
 * @export
 * @interface FeatureOfferGetFeatureGet
 */
export interface FeatureOfferGetFeatureGet {
    /**
     * 
     * @type {number}
     * @memberof FeatureOfferGetFeatureGet
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FeatureOfferGetFeatureGet
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureOfferGetFeatureGet
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureOfferGetFeatureGet
     */
    formattedValue?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureOfferGetFeatureGet
     */
    iconType?: string;
}
/**
 * 
 * @export
 * @interface FeatureOfferPostFeaturePost
 */
export interface FeatureOfferPostFeaturePost {
    /**
     * 
     * @type {number}
     * @memberof FeatureOfferPostFeaturePost
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FeatureOfferPostFeaturePost
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureOfferPostFeaturePost
     */
    iconType?: string;
}
/**
 * 
 * @export
 * @interface FeaturesStructureComparisonGetFeaturesStructureGetOfferGetFeatureGet
 */
export interface FeaturesStructureComparisonGetFeaturesStructureGetOfferGetFeatureGet {
    /**
     * 
     * @type {Array<TariffFeatureCategoryComparisonGetFeaturesStructureGetOfferGetFeatureGet>}
     * @memberof FeaturesStructureComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    categories?: Array<TariffFeatureCategoryComparisonGetFeaturesStructureGetOfferGetFeatureGet>;
    /**
     * 
     * @type {Array<FootnoteComparisonGetFeaturesStructureGetOfferGetFeatureGet>}
     * @memberof FeaturesStructureComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    footnotes?: Array<FootnoteComparisonGetFeaturesStructureGetOfferGetFeatureGet>;
}
/**
 * 
 * @export
 * @interface FootnoteComparisonGetFeaturesStructureGetOfferGetFeatureGet
 */
export interface FootnoteComparisonGetFeaturesStructureGetOfferGetFeatureGet {
    /**
     * 
     * @type {number}
     * @memberof FootnoteComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    index?: number;
    /**
     * 
     * @type {string}
     * @memberof FootnoteComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface IndustryGroupInsdustrygroupRead
 */
export interface IndustryGroupInsdustrygroupRead {
    /**
     * 
     * @type {string}
     * @memberof IndustryGroupInsdustrygroupRead
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof IndustryGroupInsdustrygroupRead
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof IndustryGroupInsdustrygroupRead
     */
    info?: string;
    /**
     * 
     * @type {string}
     * @memberof IndustryGroupInsdustrygroupRead
     */
    text?: string;
}
/**
 * 
 * @export
 * @interface IndustryIndustryRead
 */
export interface IndustryIndustryRead {
    /**
     * 
     * @type {string}
     * @memberof IndustryIndustryRead
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof IndustryIndustryRead
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof IndustryIndustryRead
     */
    info?: string;
    /**
     * 
     * @type {string}
     * @memberof IndustryIndustryRead
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof IndustryIndustryRead
     */
    industryGroup?: string;
}
/**
 * 
 * @export
 * @interface InquiryAnswer
 */
export interface InquiryAnswer {
    /**
     * 
     * @type {number}
     * @memberof InquiryAnswer
     */
    questionId?: number;
    /**
     * 
     * @type {string}
     * @memberof InquiryAnswer
     */
    question?: string;
    /**
     * answered values, could be more than one.
     * @type {Array<string>}
     * @memberof InquiryAnswer
     */
    values?: Array<string>;
}
/**
 * 
 * @export
 * @interface InquiryGroupInquirygroupRead
 */
export interface InquiryGroupInquirygroupRead {
    /**
     * 
     * @type {string}
     * @memberof InquiryGroupInquirygroupRead
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof InquiryGroupInquirygroupRead
     */
    hash?: string;
    /**
     * 
     * @type {Array<InquiryGroupProfessionAliasInquirygroupRead>}
     * @memberof InquiryGroupInquirygroupRead
     */
    professionAliases: Array<InquiryGroupProfessionAliasInquirygroupRead>;
    /**
     * 
     * @type {string}
     * @memberof InquiryGroupInquirygroupRead
     */
    customer?: string;
    /**
     * 
     * @type {number}
     * @memberof InquiryGroupInquirygroupRead
     */
    riskAnalysisId?: number;
}
/**
 * 
 * @export
 * @interface InquiryGroupInquirygroupWrite
 */
export interface InquiryGroupInquirygroupWrite {
    /**
     * 
     * @type {Array<InquiryGroupProfessionAliasInquirygroupWrite>}
     * @memberof InquiryGroupInquirygroupWrite
     */
    professionAliases: Array<InquiryGroupProfessionAliasInquirygroupWrite>;
    /**
     * 
     * @type {string}
     * @memberof InquiryGroupInquirygroupWrite
     */
    customer?: string;
    /**
     * 
     * @type {number}
     * @memberof InquiryGroupInquirygroupWrite
     */
    riskAnalysisId?: number;
}
/**
 * 
 * @export
 * @interface InquiryGroupProfessionAliasInquirygroupRead
 */
export interface InquiryGroupProfessionAliasInquirygroupRead {
    /**
     * 
     * @type {string}
     * @memberof InquiryGroupProfessionAliasInquirygroupRead
     */
    professionAlias: string;
    /**
     * 
     * @type {boolean}
     * @memberof InquiryGroupProfessionAliasInquirygroupRead
     */
    isSuggested?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InquiryGroupProfessionAliasInquirygroupRead
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof InquiryGroupProfessionAliasInquirygroupRead
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof InquiryGroupProfessionAliasInquirygroupRead
     */
    professionAliasId?: number;
}
/**
 * 
 * @export
 * @interface InquiryGroupProfessionAliasInquirygroupWrite
 */
export interface InquiryGroupProfessionAliasInquirygroupWrite {
    /**
     * 
     * @type {string}
     * @memberof InquiryGroupProfessionAliasInquirygroupWrite
     */
    professionAlias: string;
    /**
     * 
     * @type {boolean}
     * @memberof InquiryGroupProfessionAliasInquirygroupWrite
     */
    isSuggested?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InquiryGroupProfessionAliasInquirygroupWrite
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof InquiryGroupProfessionAliasInquirygroupWrite
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface InquiryInfo
 */
export interface InquiryInfo {
    /**
     * 
     * @type {string}
     * @memberof InquiryInfo
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof InquiryInfo
     */
    groupHash?: string;
    /**
     * 
     * @type {string}
     * @memberof InquiryInfo
     */
    brokerCode?: string;
    /**
     * 
     * @type {number}
     * @memberof InquiryInfo
     */
    professionId?: number;
    /**
     * 
     * @type {number}
     * @memberof InquiryInfo
     */
    professionAliasId?: number;
    /**
     * 
     * @type {string}
     * @memberof InquiryInfo
     */
    professionAlias?: string;
    /**
     * 
     * @type {number}
     * @memberof InquiryInfo
     */
    productId?: number;
    /**
     * 
     * @type {string}
     * @memberof InquiryInfo
     */
    productTag?: string;
    /**
     * 
     * @type {string}
     * @memberof InquiryInfo
     */
    productName?: string;
}
/**
 * 
 * @export
 * @interface InquiryInquiryRead
 */
export interface InquiryInquiryRead {
    /**
     * 
     * @type {number}
     * @memberof InquiryInquiryRead
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InquiryInquiryRead
     */
    hash?: string;
    /**
     * 
     * @type {number}
     * @memberof InquiryInquiryRead
     */
    status?: number;
    /**
     * 
     * @type {string}
     * @memberof InquiryInquiryRead
     */
    comment?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InquiryInquiryRead
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InquiryInquiryRead
     */
    isTest?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InquiryInquiryRead
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof InquiryInquiryRead
     */
    product: string;
    /**
     * 
     * @type {string}
     * @memberof InquiryInquiryRead
     */
    inquiryGroup: string;
}
/**
 * 
 * @export
 * @interface InquiryInquiryWrite
 */
export interface InquiryInquiryWrite {
    /**
     * 
     * @type {string}
     * @memberof InquiryInquiryWrite
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof InquiryInquiryWrite
     */
    product: string;
    /**
     * 
     * @type {string}
     * @memberof InquiryInquiryWrite
     */
    inquiryGroup: string;
}
/**
 * 
 * @export
 * @interface InsurerComparisonGetFeaturesStructureGetOfferGetFeatureGet
 */
export interface InsurerComparisonGetFeaturesStructureGetOfferGetFeatureGet {
    /**
     * 
     * @type {number}
     * @memberof InsurerComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InsurerComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurerComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    nameTag?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurerComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurerComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    info?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurerComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    image?: string;
}
/**
 * 
 * @export
 * @interface InsurerImgLandingpagesettingsReadDocumentRead
 */
export interface InsurerImgLandingpagesettingsReadDocumentRead {
    /**
     * 
     * @type {string}
     * @memberof InsurerImgLandingpagesettingsReadDocumentRead
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurerImgLandingpagesettingsReadDocumentRead
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface InsurerOfferGetFeatureGet
 */
export interface InsurerOfferGetFeatureGet {
    /**
     * 
     * @type {number}
     * @memberof InsurerOfferGetFeatureGet
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InsurerOfferGetFeatureGet
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurerOfferGetFeatureGet
     */
    nameTag?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurerOfferGetFeatureGet
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurerOfferGetFeatureGet
     */
    info?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurerOfferGetFeatureGet
     */
    image?: string;
}
/**
 * 
 * @export
 * @interface InsurerRead
 */
export interface InsurerRead {
    /**
     * 
     * @type {number}
     * @memberof InsurerRead
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InsurerRead
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurerRead
     */
    nameTag?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurerRead
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurerRead
     */
    info?: string;
}
/**
 * 
 * @export
 * @interface LandingPageSettingsLandingpagesettingsReadDocumentRead
 */
export interface LandingPageSettingsLandingpagesettingsReadDocumentRead {
    /**
     * 
     * @type {number}
     * @memberof LandingPageSettingsLandingpagesettingsReadDocumentRead
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LandingPageSettingsLandingpagesettingsReadDocumentRead
     */
    searchButtonTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPageSettingsLandingpagesettingsReadDocumentRead
     */
    headerTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPageSettingsLandingpagesettingsReadDocumentRead
     */
    headerContent?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPageSettingsLandingpagesettingsReadDocumentRead
     */
    headerImageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPageSettingsLandingpagesettingsReadDocumentRead
     */
    headerBackgroundImageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPageSettingsLandingpagesettingsReadDocumentRead
     */
    headerBackgroundYoutubeHash?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPageSettingsLandingpagesettingsReadDocumentRead
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPageSettingsLandingpagesettingsReadDocumentRead
     */
    metaTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPageSettingsLandingpagesettingsReadDocumentRead
     */
    metaKeywords?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPageSettingsLandingpagesettingsReadDocumentRead
     */
    metaDescription?: string;
    /**
     * 
     * @type {Array<InsurerImgLandingpagesettingsReadDocumentRead>}
     * @memberof LandingPageSettingsLandingpagesettingsReadDocumentRead
     */
    insurerSliderImages?: Array<InsurerImgLandingpagesettingsReadDocumentRead>;
    /**
     * 
     * @type {PoolInitialInformationLandingpagesettingsReadDocumentRead}
     * @memberof LandingPageSettingsLandingpagesettingsReadDocumentRead
     */
    initialInformation?: PoolInitialInformationLandingpagesettingsReadDocumentRead;
}
/**
 * 
 * @export
 * @interface OfferComparisonGetFeaturesStructureGetOfferGetFeatureGet
 */
export interface OfferComparisonGetFeaturesStructureGetOfferGetFeatureGet {
    /**
     * contractId OR calculationHash.
     * @type {string}
     * @memberof OfferComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof OfferComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    type: number;
    /**
     * 
     * @type {string}
     * @memberof OfferComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    inquiry: string;
    /**
     * 
     * @type {InsurerComparisonGetFeaturesStructureGetOfferGetFeatureGet}
     * @memberof OfferComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    insurer: InsurerComparisonGetFeaturesStructureGetOfferGetFeatureGet;
    /**
     * 
     * @type {OfferDetailsComparisonGetFeaturesStructureGetOfferGetFeatureGet}
     * @memberof OfferComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    details: OfferDetailsComparisonGetFeaturesStructureGetOfferGetFeatureGet;
}
/**
 * 
 * @export
 * @interface OfferDetailsComparisonGetFeaturesStructureGetOfferGetFeatureGet
 */
export interface OfferDetailsComparisonGetFeaturesStructureGetOfferGetFeatureGet {
    /**
     * 
     * @type {PremiumFeatureComparisonGetFeaturesStructureGetOfferGetFeatureGet}
     * @memberof OfferDetailsComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    premium: PremiumFeatureComparisonGetFeaturesStructureGetOfferGetFeatureGet;
    /**
     * 
     * @type {TariffComparisonGetFeaturesStructureGetOfferGetFeatureGet}
     * @memberof OfferDetailsComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    tariff?: TariffComparisonGetFeaturesStructureGetOfferGetFeatureGet;
    /**
     * 
     * @type {PaymentIntervalFeatureComparisonGetFeaturesStructureGetOfferGetFeatureGet}
     * @memberof OfferDetailsComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    paymentInterval?: PaymentIntervalFeatureComparisonGetFeaturesStructureGetOfferGetFeatureGet;
    /**
     * example: 10.2020
     * @type {string}
     * @memberof OfferDetailsComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    tariffVersion?: string;
    /**
     * 
     * @type {Array<FeatureComparisonGetFeaturesStructureGetOfferGetFeatureGet>}
     * @memberof OfferDetailsComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    features?: Array<FeatureComparisonGetFeaturesStructureGetOfferGetFeatureGet>;
}
/**
 * 
 * @export
 * @interface OfferDetailsOfferGetFeatureGet
 */
export interface OfferDetailsOfferGetFeatureGet {
    /**
     * 
     * @type {PremiumFeatureOfferGetFeatureGet}
     * @memberof OfferDetailsOfferGetFeatureGet
     */
    premium: PremiumFeatureOfferGetFeatureGet;
    /**
     * 
     * @type {TariffOfferGetFeatureGet}
     * @memberof OfferDetailsOfferGetFeatureGet
     */
    tariff?: TariffOfferGetFeatureGet;
    /**
     * 
     * @type {PaymentIntervalFeatureOfferGetFeatureGet}
     * @memberof OfferDetailsOfferGetFeatureGet
     */
    paymentInterval?: PaymentIntervalFeatureOfferGetFeatureGet;
    /**
     * example: 10.2020
     * @type {string}
     * @memberof OfferDetailsOfferGetFeatureGet
     */
    tariffVersion?: string;
    /**
     * 
     * @type {Array<FeatureOfferGetFeatureGet>}
     * @memberof OfferDetailsOfferGetFeatureGet
     */
    features?: Array<FeatureOfferGetFeatureGet>;
}
/**
 * 
 * @export
 * @interface OfferDetailsOfferPostFeaturePost
 */
export interface OfferDetailsOfferPostFeaturePost {
    /**
     * 
     * @type {PremiumFeatureOfferPostFeaturePost}
     * @memberof OfferDetailsOfferPostFeaturePost
     */
    premium: PremiumFeatureOfferPostFeaturePost;
    /**
     * 
     * @type {TariffOfferPostFeaturePost}
     * @memberof OfferDetailsOfferPostFeaturePost
     */
    tariff?: TariffOfferPostFeaturePost;
    /**
     * 
     * @type {PaymentIntervalFeatureOfferPostFeaturePost}
     * @memberof OfferDetailsOfferPostFeaturePost
     */
    paymentInterval?: PaymentIntervalFeatureOfferPostFeaturePost;
    /**
     * example: 10.2020
     * @type {string}
     * @memberof OfferDetailsOfferPostFeaturePost
     */
    tariffVersion?: string;
    /**
     * 
     * @type {Array<FeatureOfferPostFeaturePost>}
     * @memberof OfferDetailsOfferPostFeaturePost
     */
    features?: Array<FeatureOfferPostFeaturePost>;
}
/**
 * 
 * @export
 * @interface PaymentIntervalFeatureComparisonGetFeaturesStructureGetOfferGetFeatureGet
 */
export interface PaymentIntervalFeatureComparisonGetFeaturesStructureGetOfferGetFeatureGet {
    /**
     * 
     * @type {number}
     * @memberof PaymentIntervalFeatureComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    value: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentIntervalFeatureComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    formattedValue?: string;
}
/**
 * 
 * @export
 * @interface PaymentIntervalFeatureOfferGetFeatureGet
 */
export interface PaymentIntervalFeatureOfferGetFeatureGet {
    /**
     * 
     * @type {number}
     * @memberof PaymentIntervalFeatureOfferGetFeatureGet
     */
    value: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentIntervalFeatureOfferGetFeatureGet
     */
    formattedValue?: string;
}
/**
 * 
 * @export
 * @interface PaymentIntervalFeatureOfferPostFeaturePost
 */
export interface PaymentIntervalFeatureOfferPostFeaturePost {
    /**
     * 
     * @type {number}
     * @memberof PaymentIntervalFeatureOfferPostFeaturePost
     */
    value: number;
}
/**
 * 
 * @export
 * @interface PoolBrokerRead
 */
export interface PoolBrokerRead {
    /**
     * 
     * @type {number}
     * @memberof PoolBrokerRead
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PoolBrokerRead
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolBrokerRead
     */
    tag?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolBrokerRead
     */
    company?: string;
}
/**
 * 
 * @export
 * @interface PoolDesignPooldesignRead
 */
export interface PoolDesignPooldesignRead {
    /**
     * 
     * @type {number}
     * @memberof PoolDesignPooldesignRead
     */
    poolId?: number;
    /**
     * 
     * @type {string}
     * @memberof PoolDesignPooldesignRead
     */
    poolTag?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolDesignPooldesignRead
     */
    primaryColor?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolDesignPooldesignRead
     */
    secondaryColor?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolDesignPooldesignRead
     */
    thirdColor?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolDesignPooldesignRead
     */
    fourthColor?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolDesignPooldesignRead
     */
    fifthColor?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolDesignPooldesignRead
     */
    bodyBgColor?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolDesignPooldesignRead
     */
    mainFontFamily?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolDesignPooldesignRead
     */
    landingPageBodyBackgroundColor?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolDesignPooldesignRead
     */
    landingPageHeaderBoxBackgroundColor?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolDesignPooldesignRead
     */
    landingPageHeaderBoxTextColor?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolDesignPooldesignRead
     */
    headerContactHoverColor?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolDesignPooldesignRead
     */
    faviconUrl?: string;
}
/**
 * 
 * @export
 * @interface PoolInitialInformationLandingpagesettingsReadDocumentRead
 */
export interface PoolInitialInformationLandingpagesettingsReadDocumentRead {
    /**
     * 
     * @type {string}
     * @memberof PoolInitialInformationLandingpagesettingsReadDocumentRead
     */
    confirmationText?: string;
    /**
     * 
     * @type {DocumentLandingpagesettingsReadDocumentRead}
     * @memberof PoolInitialInformationLandingpagesettingsReadDocumentRead
     */
    document?: DocumentLandingpagesettingsReadDocumentRead;
}
/**
 * 
 * @export
 * @interface PremiumFeatureComparisonGetFeaturesStructureGetOfferGetFeatureGet
 */
export interface PremiumFeatureComparisonGetFeaturesStructureGetOfferGetFeatureGet {
    /**
     * premium including discount.
     * @type {object}
     * @memberof PremiumFeatureComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    finalGross: object;
    /**
     * 
     * @type {string}
     * @memberof PremiumFeatureComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    formattedFinalGross?: string;
    /**
     * 
     * @type {number}
     * @memberof PremiumFeatureComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    discount?: number;
    /**
     * discount can be applied but not shown.
     * @type {boolean}
     * @memberof PremiumFeatureComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    showDiscount?: boolean;
    /**
     * premium without discount.
     * @type {number}
     * @memberof PremiumFeatureComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    gross?: number;
    /**
     * 
     * @type {string}
     * @memberof PremiumFeatureComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    formattedGross?: string;
}
/**
 * 
 * @export
 * @interface PremiumFeatureOfferGetFeatureGet
 */
export interface PremiumFeatureOfferGetFeatureGet {
    /**
     * premium including discount.
     * @type {object}
     * @memberof PremiumFeatureOfferGetFeatureGet
     */
    finalGross: object;
    /**
     * 
     * @type {string}
     * @memberof PremiumFeatureOfferGetFeatureGet
     */
    formattedFinalGross?: string;
    /**
     * 
     * @type {number}
     * @memberof PremiumFeatureOfferGetFeatureGet
     */
    discount?: number;
    /**
     * discount can be applied but not shown.
     * @type {boolean}
     * @memberof PremiumFeatureOfferGetFeatureGet
     */
    showDiscount?: boolean;
    /**
     * premium without discount.
     * @type {number}
     * @memberof PremiumFeatureOfferGetFeatureGet
     */
    gross?: number;
    /**
     * 
     * @type {string}
     * @memberof PremiumFeatureOfferGetFeatureGet
     */
    formattedGross?: string;
}
/**
 * 
 * @export
 * @interface PremiumFeatureOfferPostFeaturePost
 */
export interface PremiumFeatureOfferPostFeaturePost {
    /**
     * premium including discount.
     * @type {object}
     * @memberof PremiumFeatureOfferPostFeaturePost
     */
    finalGross: object;
}
/**
 * 
 * @export
 * @interface ProductProductRead
 */
export interface ProductProductRead {
    /**
     * 
     * @type {string}
     * @memberof ProductProductRead
     */
    tag?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductProductRead
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductProductRead
     */
    nameLong?: string;
}
/**
 * 
 * @export
 * @interface ProfessionAliasProfessionaliasRead
 */
export interface ProfessionAliasProfessionaliasRead {
    /**
     * 
     * @type {number}
     * @memberof ProfessionAliasProfessionaliasRead
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfessionAliasProfessionaliasRead
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfessionAliasProfessionaliasRead
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfessionAliasProfessionaliasRead
     */
    profession?: string;
}
/**
 * 
 * @export
 * @interface TariffComparisonGetFeaturesStructureGetOfferGetFeatureGet
 */
export interface TariffComparisonGetFeaturesStructureGetOfferGetFeatureGet {
    /**
     * 
     * @type {number}
     * @memberof TariffComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TariffComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface TariffFeatureCategoryComparisonGetFeaturesStructureGetOfferGetFeatureGet
 */
export interface TariffFeatureCategoryComparisonGetFeaturesStructureGetOfferGetFeatureGet {
    /**
     * 
     * @type {number}
     * @memberof TariffFeatureCategoryComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TariffFeatureCategoryComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    name?: string;
    /**
     * 
     * @type {Array<TariffFeatureFieldComparisonGetFeaturesStructureGetOfferGetFeatureGet>}
     * @memberof TariffFeatureCategoryComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    featureElements?: Array<TariffFeatureFieldComparisonGetFeaturesStructureGetOfferGetFeatureGet>;
}
/**
 * 
 * @export
 * @interface TariffFeatureFieldComparisonGetFeaturesStructureGetOfferGetFeatureGet
 */
export interface TariffFeatureFieldComparisonGetFeaturesStructureGetOfferGetFeatureGet {
    /**
     * 
     * @type {number}
     * @memberof TariffFeatureFieldComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TariffFeatureFieldComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TariffFeatureFieldComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    example?: string;
    /**
     * 
     * @type {string}
     * @memberof TariffFeatureFieldComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    info?: string;
    /**
     * 
     * @type {number}
     * @memberof TariffFeatureFieldComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    footnoteIndex?: number;
    /**
     * 
     * @type {string}
     * @memberof TariffFeatureFieldComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    type?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TariffFeatureFieldComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    required?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TariffFeatureFieldComparisonGetFeaturesStructureGetOfferGetFeatureGet
     */
    hasIcon?: boolean;
}
/**
 * 
 * @export
 * @interface TariffOfferGetFeatureGet
 */
export interface TariffOfferGetFeatureGet {
    /**
     * 
     * @type {number}
     * @memberof TariffOfferGetFeatureGet
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TariffOfferGetFeatureGet
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface TariffOfferPostFeaturePost
 */
export interface TariffOfferPostFeaturePost {
    /**
     * 
     * @type {string}
     * @memberof TariffOfferPostFeaturePost
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface TariffTariffGet
 */
export interface TariffTariffGet {
    /**
     * 
     * @type {number}
     * @memberof TariffTariffGet
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TariffTariffGet
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TariffTariffGet
     */
    tag?: string;
}

/**
 * BrokerApi - axios parameter creator
 * @export
 */
export const BrokerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves a Broker resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerMeBrokerItem: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/brokers/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrokerApi - functional programming interface
 * @export
 */
export const BrokerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrokerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves a Broker resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokerMeBrokerItem(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrokerBrokerRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokerMeBrokerItem(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BrokerApi - factory interface
 * @export
 */
export const BrokerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrokerApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves a Broker resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerMeBrokerItem(options?: any): AxiosPromise<BrokerBrokerRead> {
            return localVarFp.brokerMeBrokerItem(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BrokerApi - object-oriented interface
 * @export
 * @class BrokerApi
 * @extends {BaseAPI}
 */
export class BrokerApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves a Broker resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokerApi
     */
    public brokerMeBrokerItem(options?: any) {
        return BrokerApiFp(this.configuration).brokerMeBrokerItem(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ComparisonApi - axios parameter creator
 * @export
 */
export const ComparisonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves a Comparison resource.
         * @param {string} inquiryHash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComparisonItem: async (inquiryHash: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryHash' is not null or undefined
            assertParamExists('getComparisonItem', 'inquiryHash', inquiryHash)
            const localVarPath = `/comparison/{inquiryHash}`
                .replace(`{${"inquiryHash"}}`, encodeURIComponent(String(inquiryHash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ComparisonApi - functional programming interface
 * @export
 */
export const ComparisonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ComparisonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves a Comparison resource.
         * @param {string} inquiryHash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComparisonItem(inquiryHash: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparisonComparisonGetFeaturesStructureGetOfferGetFeatureGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComparisonItem(inquiryHash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ComparisonApi - factory interface
 * @export
 */
export const ComparisonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ComparisonApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves a Comparison resource.
         * @param {string} inquiryHash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComparisonItem(inquiryHash: string, options?: any): AxiosPromise<ComparisonComparisonGetFeaturesStructureGetOfferGetFeatureGet> {
            return localVarFp.getComparisonItem(inquiryHash, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ComparisonApi - object-oriented interface
 * @export
 * @class ComparisonApi
 * @extends {BaseAPI}
 */
export class ComparisonApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves a Comparison resource.
     * @param {string} inquiryHash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparisonApi
     */
    public getComparisonItem(inquiryHash: string, options?: any) {
        return ComparisonApiFp(this.configuration).getComparisonItem(inquiryHash, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ComparisonSubscriptionApi - axios parameter creator
 * @export
 */
export const ComparisonSubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves a ComparisonSubscription resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComparisonSubscriptionItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getComparisonSubscriptionItem', 'id', id)
            const localVarPath = `/comparison/{id}/subscription`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a ComparisonSubscription resource.
         * @param {ComparisonSubscriptionPost} [comparisonSubscription] The new ComparisonSubscription resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postComparisonSubscriptionCollection: async (comparisonSubscription?: ComparisonSubscriptionPost, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/comparison/subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comparisonSubscription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ComparisonSubscriptionApi - functional programming interface
 * @export
 */
export const ComparisonSubscriptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ComparisonSubscriptionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves a ComparisonSubscription resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComparisonSubscriptionItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparisonSubscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComparisonSubscriptionItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a ComparisonSubscription resource.
         * @param {ComparisonSubscriptionPost} [comparisonSubscription] The new ComparisonSubscription resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postComparisonSubscriptionCollection(comparisonSubscription?: ComparisonSubscriptionPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparisonSubscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postComparisonSubscriptionCollection(comparisonSubscription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ComparisonSubscriptionApi - factory interface
 * @export
 */
export const ComparisonSubscriptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ComparisonSubscriptionApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves a ComparisonSubscription resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComparisonSubscriptionItem(id: string, options?: any): AxiosPromise<ComparisonSubscription> {
            return localVarFp.getComparisonSubscriptionItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a ComparisonSubscription resource.
         * @param {ComparisonSubscriptionPost} [comparisonSubscription] The new ComparisonSubscription resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postComparisonSubscriptionCollection(comparisonSubscription?: ComparisonSubscriptionPost, options?: any): AxiosPromise<ComparisonSubscription> {
            return localVarFp.postComparisonSubscriptionCollection(comparisonSubscription, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ComparisonSubscriptionApi - object-oriented interface
 * @export
 * @class ComparisonSubscriptionApi
 * @extends {BaseAPI}
 */
export class ComparisonSubscriptionApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves a ComparisonSubscription resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparisonSubscriptionApi
     */
    public getComparisonSubscriptionItem(id: string, options?: any) {
        return ComparisonSubscriptionApiFp(this.configuration).getComparisonSubscriptionItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a ComparisonSubscription resource.
     * @param {ComparisonSubscriptionPost} [comparisonSubscription] The new ComparisonSubscription resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparisonSubscriptionApi
     */
    public postComparisonSubscriptionCollection(comparisonSubscription?: ComparisonSubscriptionPost, options?: any) {
        return ComparisonSubscriptionApiFp(this.configuration).postComparisonSubscriptionCollection(comparisonSubscription, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContractApi - axios parameter creator
 * @export
 */
export const ContractApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Removes the Contract resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContractItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteContractItem', 'id', id)
            const localVarPath = `/contracts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a Offer resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getContractItem', 'id', id)
            const localVarPath = `/contracts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a Contract resource.
         * @param {ContractOfferOfferPostFeaturePost} [contract] The new Contract resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContractCollection: async (contract?: ContractOfferOfferPostFeaturePost, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/contracts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Replaces the Contract resource.
         * @param {string} id 
         * @param {ContractOfferOfferPostFeaturePost} [contract] The updated Contract resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContractItem: async (id: string, contract?: ContractOfferOfferPostFeaturePost, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putContractItem', 'id', id)
            const localVarPath = `/contracts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractApi - functional programming interface
 * @export
 */
export const ContractApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Removes the Contract resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContractItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContractItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a Offer resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContractItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractOfferOfferGetFeatureGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContractItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a Contract resource.
         * @param {ContractOfferOfferPostFeaturePost} [contract] The new Contract resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postContractCollection(contract?: ContractOfferOfferPostFeaturePost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractOfferOfferGetFeatureGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postContractCollection(contract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Replaces the Contract resource.
         * @param {string} id 
         * @param {ContractOfferOfferPostFeaturePost} [contract] The updated Contract resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putContractItem(id: string, contract?: ContractOfferOfferPostFeaturePost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractOfferOfferGetFeatureGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putContractItem(id, contract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractApi - factory interface
 * @export
 */
export const ContractApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractApiFp(configuration)
    return {
        /**
         * 
         * @summary Removes the Contract resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContractItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteContractItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a Offer resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractItem(id: string, options?: any): AxiosPromise<ContractOfferOfferGetFeatureGet> {
            return localVarFp.getContractItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a Contract resource.
         * @param {ContractOfferOfferPostFeaturePost} [contract] The new Contract resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContractCollection(contract?: ContractOfferOfferPostFeaturePost, options?: any): AxiosPromise<ContractOfferOfferGetFeatureGet> {
            return localVarFp.postContractCollection(contract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Replaces the Contract resource.
         * @param {string} id 
         * @param {ContractOfferOfferPostFeaturePost} [contract] The updated Contract resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContractItem(id: string, contract?: ContractOfferOfferPostFeaturePost, options?: any): AxiosPromise<ContractOfferOfferGetFeatureGet> {
            return localVarFp.putContractItem(id, contract, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractApi - object-oriented interface
 * @export
 * @class ContractApi
 * @extends {BaseAPI}
 */
export class ContractApi extends BaseAPI {
    /**
     * 
     * @summary Removes the Contract resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public deleteContractItem(id: string, options?: any) {
        return ContractApiFp(this.configuration).deleteContractItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a Offer resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public getContractItem(id: string, options?: any) {
        return ContractApiFp(this.configuration).getContractItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a Contract resource.
     * @param {ContractOfferOfferPostFeaturePost} [contract] The new Contract resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public postContractCollection(contract?: ContractOfferOfferPostFeaturePost, options?: any) {
        return ContractApiFp(this.configuration).postContractCollection(contract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Replaces the Contract resource.
     * @param {string} id 
     * @param {ContractOfferOfferPostFeaturePost} [contract] The updated Contract resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public putContractItem(id: string, contract?: ContractOfferOfferPostFeaturePost, options?: any) {
        return ContractApiFp(this.configuration).putContractItem(id, contract, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves a Customer resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCustomerItem', 'id', id)
            const localVarPath = `/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the Customer resource.
         * @param {string} id 
         * @param {CustomerCustomerWrite} [customer] The updated Customer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCustomerItem: async (id: string, customer?: CustomerCustomerWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchCustomerItem', 'id', id)
            const localVarPath = `/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a Customer resource.
         * @param {CustomerCustomerWrite} [customer] The new Customer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCustomerCollection: async (customer?: CustomerCustomerWrite, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves a Customer resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerCustomerRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the Customer resource.
         * @param {string} id 
         * @param {CustomerCustomerWrite} [customer] The updated Customer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCustomerItem(id: string, customer?: CustomerCustomerWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerCustomerRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCustomerItem(id, customer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a Customer resource.
         * @param {CustomerCustomerWrite} [customer] The new Customer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCustomerCollection(customer?: CustomerCustomerWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerCustomerRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCustomerCollection(customer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves a Customer resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerItem(id: string, options?: any): AxiosPromise<CustomerCustomerRead> {
            return localVarFp.getCustomerItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the Customer resource.
         * @param {string} id 
         * @param {CustomerCustomerWrite} [customer] The updated Customer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCustomerItem(id: string, customer?: CustomerCustomerWrite, options?: any): AxiosPromise<CustomerCustomerRead> {
            return localVarFp.patchCustomerItem(id, customer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a Customer resource.
         * @param {CustomerCustomerWrite} [customer] The new Customer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCustomerCollection(customer?: CustomerCustomerWrite, options?: any): AxiosPromise<CustomerCustomerRead> {
            return localVarFp.postCustomerCollection(customer, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves a Customer resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomerItem(id: string, options?: any) {
        return CustomerApiFp(this.configuration).getCustomerItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the Customer resource.
     * @param {string} id 
     * @param {CustomerCustomerWrite} [customer] The updated Customer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public patchCustomerItem(id: string, customer?: CustomerCustomerWrite, options?: any) {
        return CustomerApiFp(this.configuration).patchCustomerItem(id, customer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a Customer resource.
     * @param {CustomerCustomerWrite} [customer] The new Customer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public postCustomerCollection(customer?: CustomerCustomerWrite, options?: any) {
        return CustomerApiFp(this.configuration).postCustomerCollection(customer, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentCollectionApi - axios parameter creator
 * @export
 */
export const DocumentCollectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves a DocumentCollection resource.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentCollectionItem: async (hash: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('getDocumentCollectionItem', 'hash', hash)
            const localVarPath = `/inquiries/{hash}/documents`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentCollectionApi - functional programming interface
 * @export
 */
export const DocumentCollectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentCollectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves a DocumentCollection resource.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentCollectionItem(hash: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentCollectionDocumentRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentCollectionItem(hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentCollectionApi - factory interface
 * @export
 */
export const DocumentCollectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentCollectionApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves a DocumentCollection resource.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentCollectionItem(hash: string, options?: any): AxiosPromise<DocumentCollectionDocumentRead> {
            return localVarFp.getDocumentCollectionItem(hash, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentCollectionApi - object-oriented interface
 * @export
 * @class DocumentCollectionApi
 * @extends {BaseAPI}
 */
export class DocumentCollectionApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves a DocumentCollection resource.
     * @param {string} hash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentCollectionApi
     */
    public getDocumentCollectionItem(hash: string, options?: any) {
        return DocumentCollectionApiFp(this.configuration).getDocumentCollectionItem(hash, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * E2EContactInfoApi - axios parameter creator
 * @export
 */
export const E2EContactInfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves a E2EContactInfo resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactInfoGetE2EContactInfoItem: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/contact-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * E2EContactInfoApi - functional programming interface
 * @export
 */
export const E2EContactInfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = E2EContactInfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves a E2EContactInfo resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactInfoGetE2EContactInfoItem(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<E2EContactInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactInfoGetE2EContactInfoItem(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * E2EContactInfoApi - factory interface
 * @export
 */
export const E2EContactInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = E2EContactInfoApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves a E2EContactInfo resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactInfoGetE2EContactInfoItem(options?: any): AxiosPromise<E2EContactInfo> {
            return localVarFp.contactInfoGetE2EContactInfoItem(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * E2EContactInfoApi - object-oriented interface
 * @export
 * @class E2EContactInfoApi
 * @extends {BaseAPI}
 */
export class E2EContactInfoApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves a E2EContactInfo resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof E2EContactInfoApi
     */
    public contactInfoGetE2EContactInfoItem(options?: any) {
        return E2EContactInfoApiFp(this.configuration).contactInfoGetE2EContactInfoItem(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * E2ESettingsApi - axios parameter creator
 * @export
 */
export const E2ESettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves a E2ESettings resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsGetE2ESettingsItem: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * E2ESettingsApi - functional programming interface
 * @export
 */
export const E2ESettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = E2ESettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves a E2ESettings resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingsGetE2ESettingsItem(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<E2ESettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingsGetE2ESettingsItem(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * E2ESettingsApi - factory interface
 * @export
 */
export const E2ESettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = E2ESettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves a E2ESettings resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsGetE2ESettingsItem(options?: any): AxiosPromise<E2ESettings> {
            return localVarFp.settingsGetE2ESettingsItem(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * E2ESettingsApi - object-oriented interface
 * @export
 * @class E2ESettingsApi
 * @extends {BaseAPI}
 */
export class E2ESettingsApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves a E2ESettings resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof E2ESettingsApi
     */
    public settingsGetE2ESettingsItem(options?: any) {
        return E2ESettingsApiFp(this.configuration).settingsGetE2ESettingsItem(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FeatureApi - axios parameter creator
 * @export
 */
export const FeatureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves the collection of Feature resources.
         * @param {string} id 
         * @param {number} [profession] 
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTariffsFeaturesGetSubresource: async (id: string, profession?: number, page?: number, pagination?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTariffsFeaturesGetSubresource', 'id', id)
            const localVarPath = `/tariffs/{id}/features`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (profession !== undefined) {
                localVarQueryParameter['profession'] = profession;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a Feature resource.
         * @param {string} featureFieldId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureItem: async (featureFieldId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureFieldId' is not null or undefined
            assertParamExists('getFeatureItem', 'featureFieldId', featureFieldId)
            const localVarPath = `/features/{featureFieldId}`
                .replace(`{${"featureFieldId"}}`, encodeURIComponent(String(featureFieldId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureApi - functional programming interface
 * @export
 */
export const FeatureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeatureApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of Feature resources.
         * @param {string} id 
         * @param {number} [profession] 
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTariffsFeaturesGetSubresource(id: string, profession?: number, page?: number, pagination?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureFeatureFeatureGet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTariffsFeaturesGetSubresource(id, profession, page, pagination, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a Feature resource.
         * @param {string} featureFieldId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureItem(featureFieldId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureFeatureFeatureGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureItem(featureFieldId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeatureApi - factory interface
 * @export
 */
export const FeatureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeatureApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of Feature resources.
         * @param {string} id 
         * @param {number} [profession] 
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTariffsFeaturesGetSubresource(id: string, profession?: number, page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<FeatureFeatureFeatureGet>> {
            return localVarFp.apiTariffsFeaturesGetSubresource(id, profession, page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a Feature resource.
         * @param {string} featureFieldId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureItem(featureFieldId: string, options?: any): AxiosPromise<FeatureFeatureFeatureGet> {
            return localVarFp.getFeatureItem(featureFieldId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeatureApi - object-oriented interface
 * @export
 * @class FeatureApi
 * @extends {BaseAPI}
 */
export class FeatureApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves the collection of Feature resources.
     * @param {string} id 
     * @param {number} [profession] 
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public apiTariffsFeaturesGetSubresource(id: string, profession?: number, page?: number, pagination?: boolean, options?: any) {
        return FeatureApiFp(this.configuration).apiTariffsFeaturesGetSubresource(id, profession, page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a Feature resource.
     * @param {string} featureFieldId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public getFeatureItem(featureFieldId: string, options?: any) {
        return FeatureApiFp(this.configuration).getFeatureItem(featureFieldId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IndustryApi - axios parameter creator
 * @export
 */
export const IndustryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves a Industry resource.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndustryItem: async (hash: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('getIndustryItem', 'hash', hash)
            const localVarPath = `/industries/{hash}`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IndustryApi - functional programming interface
 * @export
 */
export const IndustryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IndustryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves a Industry resource.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIndustryItem(hash: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndustryIndustryRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIndustryItem(hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IndustryApi - factory interface
 * @export
 */
export const IndustryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IndustryApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves a Industry resource.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndustryItem(hash: string, options?: any): AxiosPromise<IndustryIndustryRead> {
            return localVarFp.getIndustryItem(hash, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IndustryApi - object-oriented interface
 * @export
 * @class IndustryApi
 * @extends {BaseAPI}
 */
export class IndustryApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves a Industry resource.
     * @param {string} hash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndustryApi
     */
    public getIndustryItem(hash: string, options?: any) {
        return IndustryApiFp(this.configuration).getIndustryItem(hash, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IndustryGroupApi - axios parameter creator
 * @export
 */
export const IndustryGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves the collection of IndustryGroup resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndustryGroupCollection: async (page?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/industry-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a IndustryGroup resource.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndustryGroupItem: async (hash: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('getIndustryGroupItem', 'hash', hash)
            const localVarPath = `/industry-groups/{hash}`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IndustryGroupApi - functional programming interface
 * @export
 */
export const IndustryGroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IndustryGroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of IndustryGroup resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIndustryGroupCollection(page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IndustryGroupInsdustrygroupRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIndustryGroupCollection(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a IndustryGroup resource.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIndustryGroupItem(hash: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndustryGroupInsdustrygroupRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIndustryGroupItem(hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IndustryGroupApi - factory interface
 * @export
 */
export const IndustryGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IndustryGroupApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of IndustryGroup resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndustryGroupCollection(page?: number, options?: any): AxiosPromise<Array<IndustryGroupInsdustrygroupRead>> {
            return localVarFp.getIndustryGroupCollection(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a IndustryGroup resource.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndustryGroupItem(hash: string, options?: any): AxiosPromise<IndustryGroupInsdustrygroupRead> {
            return localVarFp.getIndustryGroupItem(hash, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IndustryGroupApi - object-oriented interface
 * @export
 * @class IndustryGroupApi
 * @extends {BaseAPI}
 */
export class IndustryGroupApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves the collection of IndustryGroup resources.
     * @param {number} [page] The collection page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndustryGroupApi
     */
    public getIndustryGroupCollection(page?: number, options?: any) {
        return IndustryGroupApiFp(this.configuration).getIndustryGroupCollection(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a IndustryGroup resource.
     * @param {string} hash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndustryGroupApi
     */
    public getIndustryGroupItem(hash: string, options?: any) {
        return IndustryGroupApiFp(this.configuration).getIndustryGroupItem(hash, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InquiryApi - axios parameter creator
 * @export
 */
export const InquiryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves the collection of InquiryAnswer resources.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInquiriesAnswersGetSubresource: async (hash: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('apiInquiriesAnswersGetSubresource', 'hash', hash)
            const localVarPath = `/inquiries/{hash}/answers`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a Inquiry resource.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInquiryItem: async (hash: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('getInquiryItem', 'hash', hash)
            const localVarPath = `/inquiries/{hash}`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a Inquiry resource.
         * @param {InquiryInquiryWrite} [inquiry] The new Inquiry resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInquiryCollection: async (inquiry?: InquiryInquiryWrite, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/inquiries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inquiry, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InquiryApi - functional programming interface
 * @export
 */
export const InquiryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InquiryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of InquiryAnswer resources.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInquiriesAnswersGetSubresource(hash: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InquiryAnswer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInquiriesAnswersGetSubresource(hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a Inquiry resource.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInquiryItem(hash: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InquiryInquiryRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInquiryItem(hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a Inquiry resource.
         * @param {InquiryInquiryWrite} [inquiry] The new Inquiry resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postInquiryCollection(inquiry?: InquiryInquiryWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InquiryInquiryRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postInquiryCollection(inquiry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InquiryApi - factory interface
 * @export
 */
export const InquiryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InquiryApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of InquiryAnswer resources.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInquiriesAnswersGetSubresource(hash: string, options?: any): AxiosPromise<Array<InquiryAnswer>> {
            return localVarFp.apiInquiriesAnswersGetSubresource(hash, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a Inquiry resource.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInquiryItem(hash: string, options?: any): AxiosPromise<InquiryInquiryRead> {
            return localVarFp.getInquiryItem(hash, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a Inquiry resource.
         * @param {InquiryInquiryWrite} [inquiry] The new Inquiry resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInquiryCollection(inquiry?: InquiryInquiryWrite, options?: any): AxiosPromise<InquiryInquiryRead> {
            return localVarFp.postInquiryCollection(inquiry, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InquiryApi - object-oriented interface
 * @export
 * @class InquiryApi
 * @extends {BaseAPI}
 */
export class InquiryApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves the collection of InquiryAnswer resources.
     * @param {string} hash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryApi
     */
    public apiInquiriesAnswersGetSubresource(hash: string, options?: any) {
        return InquiryApiFp(this.configuration).apiInquiriesAnswersGetSubresource(hash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a Inquiry resource.
     * @param {string} hash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryApi
     */
    public getInquiryItem(hash: string, options?: any) {
        return InquiryApiFp(this.configuration).getInquiryItem(hash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a Inquiry resource.
     * @param {InquiryInquiryWrite} [inquiry] The new Inquiry resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryApi
     */
    public postInquiryCollection(inquiry?: InquiryInquiryWrite, options?: any) {
        return InquiryApiFp(this.configuration).postInquiryCollection(inquiry, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InquiryAnswerApi - axios parameter creator
 * @export
 */
export const InquiryAnswerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves the collection of InquiryAnswer resources.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInquiriesAnswersGetSubresource: async (hash: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('apiInquiriesAnswersGetSubresource', 'hash', hash)
            const localVarPath = `/inquiries/{hash}/answers`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a InquiryAnswer resource.
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInquiryAnswerItem: async (questionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionId' is not null or undefined
            assertParamExists('getInquiryAnswerItem', 'questionId', questionId)
            const localVarPath = `/inquiry-answers/{questionId}`
                .replace(`{${"questionId"}}`, encodeURIComponent(String(questionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InquiryAnswerApi - functional programming interface
 * @export
 */
export const InquiryAnswerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InquiryAnswerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of InquiryAnswer resources.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInquiriesAnswersGetSubresource(hash: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InquiryAnswer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInquiriesAnswersGetSubresource(hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a InquiryAnswer resource.
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInquiryAnswerItem(questionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InquiryAnswer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInquiryAnswerItem(questionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InquiryAnswerApi - factory interface
 * @export
 */
export const InquiryAnswerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InquiryAnswerApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of InquiryAnswer resources.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInquiriesAnswersGetSubresource(hash: string, options?: any): AxiosPromise<Array<InquiryAnswer>> {
            return localVarFp.apiInquiriesAnswersGetSubresource(hash, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a InquiryAnswer resource.
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInquiryAnswerItem(questionId: string, options?: any): AxiosPromise<InquiryAnswer> {
            return localVarFp.getInquiryAnswerItem(questionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InquiryAnswerApi - object-oriented interface
 * @export
 * @class InquiryAnswerApi
 * @extends {BaseAPI}
 */
export class InquiryAnswerApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves the collection of InquiryAnswer resources.
     * @param {string} hash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryAnswerApi
     */
    public apiInquiriesAnswersGetSubresource(hash: string, options?: any) {
        return InquiryAnswerApiFp(this.configuration).apiInquiriesAnswersGetSubresource(hash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a InquiryAnswer resource.
     * @param {string} questionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryAnswerApi
     */
    public getInquiryAnswerItem(questionId: string, options?: any) {
        return InquiryAnswerApiFp(this.configuration).getInquiryAnswerItem(questionId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InquiryGroupApi - axios parameter creator
 * @export
 */
export const InquiryGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves a InquiryGroup resource.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInquiryGroupItem: async (hash: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('getInquiryGroupItem', 'hash', hash)
            const localVarPath = `/inquiry-groups/{hash}`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the InquiryGroup resource.
         * @param {string} hash 
         * @param {InquiryGroupInquirygroupWrite} [inquiryGroup] The updated InquiryGroup resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchInquiryGroupItem: async (hash: string, inquiryGroup?: InquiryGroupInquirygroupWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('patchInquiryGroupItem', 'hash', hash)
            const localVarPath = `/inquiry-groups/{hash}`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inquiryGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a InquiryGroup resource.
         * @param {InquiryGroupInquirygroupWrite} [inquiryGroup] The new InquiryGroup resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInquiryGroupCollection: async (inquiryGroup?: InquiryGroupInquirygroupWrite, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/inquiry-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inquiryGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Replaces the InquiryGroup resource.
         * @param {string} hash 
         * @param {InquiryGroupInquirygroupWrite} [inquiryGroup] The updated InquiryGroup resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putInquiryGroupItem: async (hash: string, inquiryGroup?: InquiryGroupInquirygroupWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('putInquiryGroupItem', 'hash', hash)
            const localVarPath = `/inquiry-groups/{hash}`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inquiryGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InquiryGroupApi - functional programming interface
 * @export
 */
export const InquiryGroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InquiryGroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves a InquiryGroup resource.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInquiryGroupItem(hash: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InquiryGroupInquirygroupRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInquiryGroupItem(hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the InquiryGroup resource.
         * @param {string} hash 
         * @param {InquiryGroupInquirygroupWrite} [inquiryGroup] The updated InquiryGroup resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchInquiryGroupItem(hash: string, inquiryGroup?: InquiryGroupInquirygroupWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InquiryGroupInquirygroupRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchInquiryGroupItem(hash, inquiryGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a InquiryGroup resource.
         * @param {InquiryGroupInquirygroupWrite} [inquiryGroup] The new InquiryGroup resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postInquiryGroupCollection(inquiryGroup?: InquiryGroupInquirygroupWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InquiryGroupInquirygroupRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postInquiryGroupCollection(inquiryGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Replaces the InquiryGroup resource.
         * @param {string} hash 
         * @param {InquiryGroupInquirygroupWrite} [inquiryGroup] The updated InquiryGroup resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putInquiryGroupItem(hash: string, inquiryGroup?: InquiryGroupInquirygroupWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InquiryGroupInquirygroupRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putInquiryGroupItem(hash, inquiryGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InquiryGroupApi - factory interface
 * @export
 */
export const InquiryGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InquiryGroupApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves a InquiryGroup resource.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInquiryGroupItem(hash: string, options?: any): AxiosPromise<InquiryGroupInquirygroupRead> {
            return localVarFp.getInquiryGroupItem(hash, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the InquiryGroup resource.
         * @param {string} hash 
         * @param {InquiryGroupInquirygroupWrite} [inquiryGroup] The updated InquiryGroup resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchInquiryGroupItem(hash: string, inquiryGroup?: InquiryGroupInquirygroupWrite, options?: any): AxiosPromise<InquiryGroupInquirygroupRead> {
            return localVarFp.patchInquiryGroupItem(hash, inquiryGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a InquiryGroup resource.
         * @param {InquiryGroupInquirygroupWrite} [inquiryGroup] The new InquiryGroup resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInquiryGroupCollection(inquiryGroup?: InquiryGroupInquirygroupWrite, options?: any): AxiosPromise<InquiryGroupInquirygroupRead> {
            return localVarFp.postInquiryGroupCollection(inquiryGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Replaces the InquiryGroup resource.
         * @param {string} hash 
         * @param {InquiryGroupInquirygroupWrite} [inquiryGroup] The updated InquiryGroup resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putInquiryGroupItem(hash: string, inquiryGroup?: InquiryGroupInquirygroupWrite, options?: any): AxiosPromise<InquiryGroupInquirygroupRead> {
            return localVarFp.putInquiryGroupItem(hash, inquiryGroup, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InquiryGroupApi - object-oriented interface
 * @export
 * @class InquiryGroupApi
 * @extends {BaseAPI}
 */
export class InquiryGroupApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves a InquiryGroup resource.
     * @param {string} hash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryGroupApi
     */
    public getInquiryGroupItem(hash: string, options?: any) {
        return InquiryGroupApiFp(this.configuration).getInquiryGroupItem(hash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the InquiryGroup resource.
     * @param {string} hash 
     * @param {InquiryGroupInquirygroupWrite} [inquiryGroup] The updated InquiryGroup resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryGroupApi
     */
    public patchInquiryGroupItem(hash: string, inquiryGroup?: InquiryGroupInquirygroupWrite, options?: any) {
        return InquiryGroupApiFp(this.configuration).patchInquiryGroupItem(hash, inquiryGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a InquiryGroup resource.
     * @param {InquiryGroupInquirygroupWrite} [inquiryGroup] The new InquiryGroup resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryGroupApi
     */
    public postInquiryGroupCollection(inquiryGroup?: InquiryGroupInquirygroupWrite, options?: any) {
        return InquiryGroupApiFp(this.configuration).postInquiryGroupCollection(inquiryGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Replaces the InquiryGroup resource.
     * @param {string} hash 
     * @param {InquiryGroupInquirygroupWrite} [inquiryGroup] The updated InquiryGroup resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryGroupApi
     */
    public putInquiryGroupItem(hash: string, inquiryGroup?: InquiryGroupInquirygroupWrite, options?: any) {
        return InquiryGroupApiFp(this.configuration).putInquiryGroupItem(hash, inquiryGroup, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InquiryInfoApi - axios parameter creator
 * @export
 */
export const InquiryInfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves a InquiryInfo resource.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInquiryInfoItem: async (hash: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('getInquiryInfoItem', 'hash', hash)
            const localVarPath = `/inquiries/{hash}/info`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InquiryInfoApi - functional programming interface
 * @export
 */
export const InquiryInfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InquiryInfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves a InquiryInfo resource.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInquiryInfoItem(hash: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InquiryInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInquiryInfoItem(hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InquiryInfoApi - factory interface
 * @export
 */
export const InquiryInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InquiryInfoApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves a InquiryInfo resource.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInquiryInfoItem(hash: string, options?: any): AxiosPromise<InquiryInfo> {
            return localVarFp.getInquiryInfoItem(hash, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InquiryInfoApi - object-oriented interface
 * @export
 * @class InquiryInfoApi
 * @extends {BaseAPI}
 */
export class InquiryInfoApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves a InquiryInfo resource.
     * @param {string} hash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryInfoApi
     */
    public getInquiryInfoItem(hash: string, options?: any) {
        return InquiryInfoApiFp(this.configuration).getInquiryInfoItem(hash, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InsurerApi - axios parameter creator
 * @export
 */
export const InsurerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves the collection of Insurer resources.
         * @param {Array<string>} [groups] 
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsurerCollection: async (groups?: Array<string>, page?: number, pagination?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/insurers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (groups) {
                localVarQueryParameter['groups[]'] = groups;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a Insurer resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsurerItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInsurerItem', 'id', id)
            const localVarPath = `/insurers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InsurerApi - functional programming interface
 * @export
 */
export const InsurerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InsurerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of Insurer resources.
         * @param {Array<string>} [groups] 
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInsurerCollection(groups?: Array<string>, page?: number, pagination?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InsurerRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInsurerCollection(groups, page, pagination, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a Insurer resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInsurerItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsurerRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInsurerItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InsurerApi - factory interface
 * @export
 */
export const InsurerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InsurerApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of Insurer resources.
         * @param {Array<string>} [groups] 
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsurerCollection(groups?: Array<string>, page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<InsurerRead>> {
            return localVarFp.getInsurerCollection(groups, page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a Insurer resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsurerItem(id: string, options?: any): AxiosPromise<InsurerRead> {
            return localVarFp.getInsurerItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InsurerApi - object-oriented interface
 * @export
 * @class InsurerApi
 * @extends {BaseAPI}
 */
export class InsurerApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves the collection of Insurer resources.
     * @param {Array<string>} [groups] 
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurerApi
     */
    public getInsurerCollection(groups?: Array<string>, page?: number, pagination?: boolean, options?: any) {
        return InsurerApiFp(this.configuration).getInsurerCollection(groups, page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a Insurer resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurerApi
     */
    public getInsurerItem(id: string, options?: any) {
        return InsurerApiFp(this.configuration).getInsurerItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LandingPageSettingsApi - axios parameter creator
 * @export
 */
export const LandingPageSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves a LandingPageSettings resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLandingPageSettingsItem: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/landing-page/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LandingPageSettingsApi - functional programming interface
 * @export
 */
export const LandingPageSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LandingPageSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves a LandingPageSettings resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLandingPageSettingsItem(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LandingPageSettingsLandingpagesettingsReadDocumentRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLandingPageSettingsItem(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LandingPageSettingsApi - factory interface
 * @export
 */
export const LandingPageSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LandingPageSettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves a LandingPageSettings resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLandingPageSettingsItem(options?: any): AxiosPromise<LandingPageSettingsLandingpagesettingsReadDocumentRead> {
            return localVarFp.getLandingPageSettingsItem(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LandingPageSettingsApi - object-oriented interface
 * @export
 * @class LandingPageSettingsApi
 * @extends {BaseAPI}
 */
export class LandingPageSettingsApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves a LandingPageSettings resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LandingPageSettingsApi
     */
    public getLandingPageSettingsItem(options?: any) {
        return LandingPageSettingsApiFp(this.configuration).getLandingPageSettingsItem(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PoolDesignApi - axios parameter creator
 * @export
 */
export const PoolDesignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves the collection of PoolDesign resources.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoolDesignCollection: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/pools/designs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a PoolDesign resource.
         * @param {string} poolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoolDesignItem: async (poolId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'poolId' is not null or undefined
            assertParamExists('getPoolDesignItem', 'poolId', poolId)
            const localVarPath = `/pools/designs/{poolId}`
                .replace(`{${"poolId"}}`, encodeURIComponent(String(poolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PoolDesignApi - functional programming interface
 * @export
 */
export const PoolDesignApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PoolDesignApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of PoolDesign resources.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPoolDesignCollection(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PoolDesignPooldesignRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPoolDesignCollection(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a PoolDesign resource.
         * @param {string} poolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPoolDesignItem(poolId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoolDesignPooldesignRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPoolDesignItem(poolId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PoolDesignApi - factory interface
 * @export
 */
export const PoolDesignApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PoolDesignApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of PoolDesign resources.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoolDesignCollection(options?: any): AxiosPromise<Array<PoolDesignPooldesignRead>> {
            return localVarFp.getPoolDesignCollection(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a PoolDesign resource.
         * @param {string} poolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoolDesignItem(poolId: string, options?: any): AxiosPromise<PoolDesignPooldesignRead> {
            return localVarFp.getPoolDesignItem(poolId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PoolDesignApi - object-oriented interface
 * @export
 * @class PoolDesignApi
 * @extends {BaseAPI}
 */
export class PoolDesignApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves the collection of PoolDesign resources.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolDesignApi
     */
    public getPoolDesignCollection(options?: any) {
        return PoolDesignApiFp(this.configuration).getPoolDesignCollection(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a PoolDesign resource.
     * @param {string} poolId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolDesignApi
     */
    public getPoolDesignItem(poolId: string, options?: any) {
        return PoolDesignApiFp(this.configuration).getPoolDesignItem(poolId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves a Product resource.
         * @param {string} tag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductItem: async (tag: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tag' is not null or undefined
            assertParamExists('getProductItem', 'tag', tag)
            const localVarPath = `/products/{tag}`
                .replace(`{${"tag"}}`, encodeURIComponent(String(tag)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves a Product resource.
         * @param {string} tag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductItem(tag: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductProductRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductItem(tag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves a Product resource.
         * @param {string} tag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductItem(tag: string, options?: any): AxiosPromise<ProductProductRead> {
            return localVarFp.getProductItem(tag, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves a Product resource.
     * @param {string} tag 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getProductItem(tag: string, options?: any) {
        return ProductApiFp(this.configuration).getProductItem(tag, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProfessionApi - axios parameter creator
 * @export
 */
export const ProfessionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves a Profession resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfessionItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProfessionItem', 'id', id)
            const localVarPath = `/professions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfessionApi - functional programming interface
 * @export
 */
export const ProfessionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfessionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves a Profession resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfessionItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfessionItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfessionApi - factory interface
 * @export
 */
export const ProfessionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfessionApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves a Profession resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfessionItem(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.getProfessionItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfessionApi - object-oriented interface
 * @export
 * @class ProfessionApi
 * @extends {BaseAPI}
 */
export class ProfessionApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves a Profession resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessionApi
     */
    public getProfessionItem(id: string, options?: any) {
        return ProfessionApiFp(this.configuration).getProfessionItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProfessionAliasApi - axios parameter creator
 * @export
 */
export const ProfessionAliasApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves the collection of ProfessionAlias resources.
         * @param {string} [fullTextSearch] 
         * @param {string} [industryGroupHash] 
         * @param {Array<string>} [groups] 
         * @param {string} [orderAlias] 
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfessionAliasCollection: async (fullTextSearch?: string, industryGroupHash?: string, groups?: Array<string>, orderAlias?: string, page?: number, itemsPerPage?: number, pagination?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/profession-aliases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['full_text_search'] = fullTextSearch;
            }

            if (industryGroupHash !== undefined) {
                localVarQueryParameter['industry_group_hash'] = industryGroupHash;
            }

            if (groups) {
                localVarQueryParameter['groups[]'] = groups;
            }

            if (orderAlias !== undefined) {
                localVarQueryParameter['order[alias]'] = orderAlias;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a ProfessionAlias resource.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfessionAliasItem: async (hash: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('getProfessionAliasItem', 'hash', hash)
            const localVarPath = `/profession-aliases/{hash}`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfessionAliasApi - functional programming interface
 * @export
 */
export const ProfessionAliasApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfessionAliasApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of ProfessionAlias resources.
         * @param {string} [fullTextSearch] 
         * @param {string} [industryGroupHash] 
         * @param {Array<string>} [groups] 
         * @param {string} [orderAlias] 
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfessionAliasCollection(fullTextSearch?: string, industryGroupHash?: string, groups?: Array<string>, orderAlias?: string, page?: number, itemsPerPage?: number, pagination?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProfessionAliasProfessionaliasRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfessionAliasCollection(fullTextSearch, industryGroupHash, groups, orderAlias, page, itemsPerPage, pagination, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a ProfessionAlias resource.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfessionAliasItem(hash: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfessionAliasProfessionaliasRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfessionAliasItem(hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfessionAliasApi - factory interface
 * @export
 */
export const ProfessionAliasApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfessionAliasApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of ProfessionAlias resources.
         * @param {string} [fullTextSearch] 
         * @param {string} [industryGroupHash] 
         * @param {Array<string>} [groups] 
         * @param {string} [orderAlias] 
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfessionAliasCollection(fullTextSearch?: string, industryGroupHash?: string, groups?: Array<string>, orderAlias?: string, page?: number, itemsPerPage?: number, pagination?: boolean, options?: any): AxiosPromise<Array<ProfessionAliasProfessionaliasRead>> {
            return localVarFp.getProfessionAliasCollection(fullTextSearch, industryGroupHash, groups, orderAlias, page, itemsPerPage, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a ProfessionAlias resource.
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfessionAliasItem(hash: string, options?: any): AxiosPromise<ProfessionAliasProfessionaliasRead> {
            return localVarFp.getProfessionAliasItem(hash, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfessionAliasApi - object-oriented interface
 * @export
 * @class ProfessionAliasApi
 * @extends {BaseAPI}
 */
export class ProfessionAliasApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves the collection of ProfessionAlias resources.
     * @param {string} [fullTextSearch] 
     * @param {string} [industryGroupHash] 
     * @param {Array<string>} [groups] 
     * @param {string} [orderAlias] 
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessionAliasApi
     */
    public getProfessionAliasCollection(fullTextSearch?: string, industryGroupHash?: string, groups?: Array<string>, orderAlias?: string, page?: number, itemsPerPage?: number, pagination?: boolean, options?: any) {
        return ProfessionAliasApiFp(this.configuration).getProfessionAliasCollection(fullTextSearch, industryGroupHash, groups, orderAlias, page, itemsPerPage, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a ProfessionAlias resource.
     * @param {string} hash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessionAliasApi
     */
    public getProfessionAliasItem(hash: string, options?: any) {
        return ProfessionAliasApiFp(this.configuration).getProfessionAliasItem(hash, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TariffApi - axios parameter creator
 * @export
 */
export const TariffApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves the collection of Feature resources.
         * @param {string} id 
         * @param {number} [profession] 
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTariffsFeaturesGetSubresource: async (id: string, profession?: number, page?: number, pagination?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTariffsFeaturesGetSubresource', 'id', id)
            const localVarPath = `/tariffs/{id}/features`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (profession !== undefined) {
                localVarQueryParameter['profession'] = profession;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the collection of Tariff resources.
         * @param {string} [calculatorProduct] 
         * @param {Array<string>} [calculatorProduct2] 
         * @param {string} [calculatorInsurer] 
         * @param {Array<string>} [calculatorInsurer2] 
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffCollection: async (calculatorProduct?: string, calculatorProduct2?: Array<string>, calculatorInsurer?: string, calculatorInsurer2?: Array<string>, page?: number, pagination?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/tariffs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (calculatorProduct !== undefined) {
                localVarQueryParameter['calculator.product'] = calculatorProduct;
            }

            if (calculatorProduct2) {
                localVarQueryParameter['calculator.product[]'] = calculatorProduct2;
            }

            if (calculatorInsurer !== undefined) {
                localVarQueryParameter['calculator.insurer'] = calculatorInsurer;
            }

            if (calculatorInsurer2) {
                localVarQueryParameter['calculator.insurer[]'] = calculatorInsurer2;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a Tariff resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTariffItem', 'id', id)
            const localVarPath = `/tariffs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TariffApi - functional programming interface
 * @export
 */
export const TariffApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TariffApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of Feature resources.
         * @param {string} id 
         * @param {number} [profession] 
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTariffsFeaturesGetSubresource(id: string, profession?: number, page?: number, pagination?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureFeatureFeatureGet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTariffsFeaturesGetSubresource(id, profession, page, pagination, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves the collection of Tariff resources.
         * @param {string} [calculatorProduct] 
         * @param {Array<string>} [calculatorProduct2] 
         * @param {string} [calculatorInsurer] 
         * @param {Array<string>} [calculatorInsurer2] 
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTariffCollection(calculatorProduct?: string, calculatorProduct2?: Array<string>, calculatorInsurer?: string, calculatorInsurer2?: Array<string>, page?: number, pagination?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TariffTariffGet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTariffCollection(calculatorProduct, calculatorProduct2, calculatorInsurer, calculatorInsurer2, page, pagination, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a Tariff resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTariffItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TariffTariffGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTariffItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TariffApi - factory interface
 * @export
 */
export const TariffApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TariffApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of Feature resources.
         * @param {string} id 
         * @param {number} [profession] 
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTariffsFeaturesGetSubresource(id: string, profession?: number, page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<FeatureFeatureFeatureGet>> {
            return localVarFp.apiTariffsFeaturesGetSubresource(id, profession, page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the collection of Tariff resources.
         * @param {string} [calculatorProduct] 
         * @param {Array<string>} [calculatorProduct2] 
         * @param {string} [calculatorInsurer] 
         * @param {Array<string>} [calculatorInsurer2] 
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffCollection(calculatorProduct?: string, calculatorProduct2?: Array<string>, calculatorInsurer?: string, calculatorInsurer2?: Array<string>, page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<TariffTariffGet>> {
            return localVarFp.getTariffCollection(calculatorProduct, calculatorProduct2, calculatorInsurer, calculatorInsurer2, page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a Tariff resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffItem(id: string, options?: any): AxiosPromise<TariffTariffGet> {
            return localVarFp.getTariffItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TariffApi - object-oriented interface
 * @export
 * @class TariffApi
 * @extends {BaseAPI}
 */
export class TariffApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves the collection of Feature resources.
     * @param {string} id 
     * @param {number} [profession] 
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TariffApi
     */
    public apiTariffsFeaturesGetSubresource(id: string, profession?: number, page?: number, pagination?: boolean, options?: any) {
        return TariffApiFp(this.configuration).apiTariffsFeaturesGetSubresource(id, profession, page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the collection of Tariff resources.
     * @param {string} [calculatorProduct] 
     * @param {Array<string>} [calculatorProduct2] 
     * @param {string} [calculatorInsurer] 
     * @param {Array<string>} [calculatorInsurer2] 
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TariffApi
     */
    public getTariffCollection(calculatorProduct?: string, calculatorProduct2?: Array<string>, calculatorInsurer?: string, calculatorInsurer2?: Array<string>, page?: number, pagination?: boolean, options?: any) {
        return TariffApiFp(this.configuration).getTariffCollection(calculatorProduct, calculatorProduct2, calculatorInsurer, calculatorInsurer2, page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a Tariff resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TariffApi
     */
    public getTariffItem(id: string, options?: any) {
        return TariffApiFp(this.configuration).getTariffItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}


