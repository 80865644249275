import { v4 as uuidv4 } from 'uuid';

import { Values } from '@typings/bookingSet';
import { Document } from '@api/fileService';
import { FileStatus, UploaderValue } from '@typings/fileUploader';
import { FormFileValueDto } from '@api/booking';

// INFO: bookingFilesUploaderHandling function changes the current value of file inputs to the value which is necessary for Booking API

const bookingFilesUploaderHandling = (values: Values): Values => {
  for (const key in values) {
    if (values[key]?.files) {
      const { files } = values[key];
      for (const fileKey in files) {
        if (files[fileKey]) {
          const isMultipleFilesUploader = Array.isArray(files[fileKey]);
          let newUploaderField;
          if (isMultipleFilesUploader) {
            newUploaderField = (files[fileKey] as UploaderValue[]).map((field) => {
              const { file } = field as UploaderValue;
              return getFileDataObject(file as Document);
            });
          } else {
            const { file } = files[fileKey] as UploaderValue;
            newUploaderField = getFileDataObject(file as Document);
          }
          files[fileKey] = newUploaderField;
        }
      }
    }
  }
  return values;
};

const getFileDataObject = (file: Document) => {
  return {
    name: `${file.name}.${(file as Document).extension}`,
    // eslint-disable-next-line no-underscore-dangle
    id: (file as Document)._id,
  };
};
// INFO: bookingFilesDefaultValueHandling function changes the default values from Booking API File input to the acceptable structure for the Multiple Uploader
const bookingMultipleFilesDefaultValueHandling = (values: FormFileValueDto[]): UploaderValue[] => {
  const newFilesArr: UploaderValue[] = [];
  values.forEach((value) => {
    newFilesArr.push(buildTheNewValueStructure(value));
  });
  return newFilesArr;
};

const buildTheNewValueStructure = (value: FormFileValueDto): UploaderValue => {
  const nameArr = value.name.split('.');
  const extension = nameArr[nameArr.length - 1];
  nameArr.splice(nameArr.length - 1);
  return {
    innerId: uuidv4(),
    file: { name: nameArr.join('.'), _id: value.id, extension } as Document,
    status: FileStatus.Edit,
    error: false,
  };
};

export {
  bookingFilesUploaderHandling,
  bookingMultipleFilesDefaultValueHandling,
  buildTheNewValueStructure,
};
