import React, { useMemo } from 'react';

import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { helpers } from '@helpers';
import { AppState } from '@store';
import { HomeResultState } from '@typings/home';
import { UserRoles } from '@enums';
import { Settings } from '@hooks/queries/useSettings';
import { Sessions } from '@hooks/queries/useCheckSession';

type Props = {
  settings: Settings | undefined;
  session: Sessions | undefined | null;
};

const HelmetWrapper: React.FC<Props> = ({ settings, session }) => {
  const { homeData } = useSelector<AppState, HomeResultState>((state: AppState) => state.home);

  const mouseflowTrackingDisabled = useMemo(() => {
    const poolId = String(settings?.poolId);
    const restrictedPools = helpers.envVariables.REACT_APP_MOUSEFLOW_DISABLED_POOLS.split(' ');
    return restrictedPools.includes(poolId);
  }, [settings]);

  return (
    <Helmet>
      {settings?.poolName && <title>Tarifvergleich | {settings.poolName}</title>}
      {homeData?.metaDescription && <meta name="description" content={homeData.metaDescription} />}
      {homeData?.metaKeywords && <meta name="keywords" content={homeData.metaKeywords} />}
      {helpers.envVariables.REACT_APP_HEAP_ANALYTICS && session && (
        <script
          type="text/javascript"
          src="/js/heap.js"
          data-heap-key={helpers.envVariables.REACT_APP_HEAP_ANALYTICS_KEY}
        />
      )}
      {helpers.envVariables.REACT_APP_STONLY_KEY && session && (
        <script
          type="text/javascript"
          src="/js/stonly.js"
          data-stonly-key={helpers.envVariables.REACT_APP_STONLY_KEY}
        />
      )}
      {(helpers.envVariables.REACT_APP_GOOGLE_RECAPTCHA_V2_KEY &&
        session &&
        session.roles.includes(UserRoles.B2C)) ||
        (helpers.envVariables.REACT_APP_IS_WIDGET && (
          <script src="https://www.google.com/recaptcha/api.js?render=explicit" />
        ))}
      {helpers.envVariables.REACT_APP_MOUSE_FLOW && session && !mouseflowTrackingDisabled && (
        <script
          type="text/javascript"
          src="/js/mouseFlow.js"
          data-mouse-flow-key={
            session.roles.includes(UserRoles.B2B)
              ? helpers.envVariables.REACT_APP_MOUSE_FLOW_B2B_KEY
              : helpers.envVariables.REACT_APP_MOUSE_FLOW_B2C_KEY
          }
        />
      )}
    </Helmet>
  );
};

export default HelmetWrapper;
