import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { API, helpers } from '@helpers';
import { ApiQuery } from '@enums';

export interface InquiryInfo {
  hash: string;
  groupHash: string;
  professionAlias: string;
  productTag: string;
  productName: string;
  productId: number;
  professionId: number;
}

const useInquiryInfo = (
  inquiryId?: string,
  options: UseQueryOptions<InquiryInfo, Error> = {}
): QueryObserverResult<InquiryInfo, Error> => {
  return useQuery<InquiryInfo, Error>(
    [ApiQuery.InquiryInfo, inquiryId],
    async () => {
      const response = await API.get<InquiryInfo>(
        helpers.isWidget
          ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1/inquiries/${inquiryId}/info`
          : `/api/e2e/v1/inquiries/${inquiryId}/info`
      );

      return response;
    },
    {
      ...options,
      enabled: (options.enabled ?? true) && !!inquiryId,
    }
  );
};

export default useInquiryInfo;
