import React, { useContext } from 'react';

import { once } from 'lodash';

import { UrlParams } from '@typings/global';
import { StateDisplayName } from '@enums';

import { InquiryInfo } from './queries/useInquiryInfo';
import { InquiryData } from './queries/useInquiryData';
import { InquiryGroupsData } from './queries/useInquiryGroupsData';

export interface MainState {
  readonly urlParams: UrlParams;
  readonly inquiryInfo?: InquiryInfo;
  readonly inquiryData?: InquiryData;
  readonly inquiryGroupsData?: InquiryGroupsData;
}

const createMainStateContext = once(() => React.createContext({} as MainState));

export const useMainState = (): MainState => useContext(createMainStateContext());

export const MainStateContext = createMainStateContext();

export const MainStateContextProvider: React.FC<MainState> = ({ children, ...mainState }) => {
  MainStateContext.displayName = StateDisplayName.main;
  return <MainStateContext.Provider value={mainState}>{children}</MainStateContext.Provider>;
};
