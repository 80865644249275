import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { ContentType, HttpHeader } from '@developers/toolbox';

import { InquiryAnswer, InquiryApi } from '@api/e2e';
import { ApiQuery } from '@enums';
import { helpers } from '@helpers';

const inquiryInstance = new InquiryApi(
  undefined,
  helpers.isWidget
    ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1`
    : '/api/e2e/v1'
);

const useQuestionInfo = (
  inquiryId: string,
  options: UseQueryOptions<InquiryAnswer[], AxiosError> = {}
): UseQueryResult<InquiryAnswer[], AxiosError> =>
  useQuery(
    [ApiQuery.E2EInquiriesAnswers, inquiryId],
    () =>
      inquiryInstance
        .apiInquiriesAnswersGetSubresource(inquiryId, {
          headers: { [HttpHeader.Accept]: ContentType.Json },
        })
        .then((res) => res.data),
    {
      enabled: !!inquiryId,
      ...options,
    }
  );

const inquiries = {
  useQuestionInfo,
};

export default inquiries;
