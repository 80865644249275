import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { API, helpers } from '@helpers';
import { ApiQuery } from '@enums';
import { TokenDetails } from '@typings/tokenDetails';

const useTokenDetails = (
  options: UseQueryOptions<TokenDetails, Error> = {}
): QueryObserverResult<TokenDetails, Error> => {
  return useQuery<TokenDetails, Error>(
    [ApiQuery.TokenDetails],
    async () => {
      const response = await API.get<TokenDetails>(
        helpers.isWidget
          ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/broker/v1/tokenDetails`
          : '/api/broker/v1/tokenDetails'
      );

      return response;
    },
    options
  );
};

export default useTokenDetails;
