/* tslint:disable */
/* eslint-disable */
/**
 * Master Data API
 * Master Data API provides data relevant for brokers.
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Account holds most basic and crucial Account data used for REST entity
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    PDFLogoDocumentID?: string;
    /**
     * 
     * @type {AccountAddress}
     * @memberof Account
     */
    address?: AccountAddress;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    agentNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    branch?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    companyRegistrationNumber?: string;
    /**
     * 
     * @type {AccountContact}
     * @memberof Account
     */
    contact?: AccountContact;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    director?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    email?: string;
    /**
     * 
     * @type {Array<AccountEmail>}
     * @memberof Account
     */
    emails?: Array<AccountEmail>;
    /**
     * 
     * @type {Entity}
     * @memberof Account
     */
    entity: Entity;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    fax?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    imprint?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    isTest: boolean;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    supervisoryBoard?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    tag: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    taxID?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    website?: string;
}
/**
 * AccountAddress holds most basic and crucial AccountAddress data used for REST entity
 * @export
 * @interface AccountAddress
 */
export interface AccountAddress {
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    postcode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    streetNumber?: string;
}
/**
 * AccountContact holds most basic and crucial AccountContact data used for REST entity
 * @export
 * @interface AccountContact
 */
export interface AccountContact {
    /**
     * 
     * @type {string}
     * @memberof AccountContact
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountContact
     */
    name?: string;
    /**
     * 
     * @type {Array<AccountPhone>}
     * @memberof AccountContact
     */
    phones?: Array<AccountPhone>;
    /**
     * 
     * @type {string}
     * @memberof AccountContact
     */
    workingTime?: string;
}
/**
 * 
 * @export
 * @interface AccountDesignSetting
 */
export interface AccountDesignSetting {
    /**
     * 
     * @type {string}
     * @memberof AccountDesignSetting
     */
    accountId?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDesignSetting
     */
    accountTag?: string;
    /**
     * 
     * @type {Image}
     * @memberof AccountDesignSetting
     */
    appleTouch?: Image;
    /**
     * 
     * @type {Image}
     * @memberof AccountDesignSetting
     */
    favicon?: Image;
    /**
     * 
     * @type {Image}
     * @memberof AccountDesignSetting
     */
    landingPageBackground?: Image;
    /**
     * 
     * @type {Image}
     * @memberof AccountDesignSetting
     */
    logo?: Image;
    /**
     * 
     * @type {string}
     * @memberof AccountDesignSetting
     */
    primaryColor?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDesignSetting
     */
    secondaryColor?: string;
}
/**
 * 
 * @export
 * @interface AccountDesignSettingError
 */
export interface AccountDesignSettingError {
    /**
     * 
     * @type {string}
     * @memberof AccountDesignSettingError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof AccountDesignSettingError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * AccountEmail holds most basic and crucial AccountEmail data used for REST entity
 * @export
 * @interface AccountEmail
 */
export interface AccountEmail {
    /**
     * 
     * @type {string}
     * @memberof AccountEmail
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AccountEmail
     */
    type: AccountEmailTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AccountEmailTypeEnum {
    Deal = 'deal',
    DealCc = 'dealCC',
    DealNoAgencyNumber = 'dealNoAgencyNumber',
    DealPartnerAgencyNumber = 'dealPartnerAgencyNumber',
    Tendering = 'tendering',
    TenderingCc = 'tenderingCC',
    Test = 'test',
    Registration = 'registration',
    RegistrationCc = 'registrationCC',
    ServiceTeam = 'serviceTeam',
    InsurerAutoCc = 'insurerAutoCC',
    TenderingBackoffice = 'tenderingBackoffice',
    TenderingRequestInsurerCc = 'tenderingRequestInsurerCC',
    TenderingOffer = 'tenderingOffer',
    Unknown = 'unknown'
}

/**
 * AccountError is an Error Representation object
 * @export
 * @interface AccountError
 */
export interface AccountError {
    /**
     * 
     * @type {string}
     * @memberof AccountError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof AccountError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * AccountList is a Representation object
 * @export
 * @interface AccountList
 */
export interface AccountList {
    /**
     * 
     * @type {EmbeddedAccountTeasers}
     * @memberof AccountList
     */
    _embedded: EmbeddedAccountTeasers;
    /**
     * 
     * @type {number}
     * @memberof AccountList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof AccountList
     */
    total: number;
}
/**
 * AccountPhone holds most basic and crucial AccountPhone data used for REST entity
 * @export
 * @interface AccountPhone
 */
export interface AccountPhone {
    /**
     * 
     * @type {string}
     * @memberof AccountPhone
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountPhone
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface AccountSetting
 */
export interface AccountSetting {
    /**
     * 
     * @type {string}
     * @memberof AccountSetting
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSetting
     */
    isRequired: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof AccountSetting
     */
    jsonValue?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof AccountSetting
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSetting
     */
    stringValue?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSetting
     */
    type: string;
}
/**
 * 
 * @export
 * @interface AccountSettingError
 */
export interface AccountSettingError {
    /**
     * 
     * @type {string}
     * @memberof AccountSettingError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof AccountSettingError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface AccountSettingList
 */
export interface AccountSettingList {
    /**
     * 
     * @type {EmbeddedAccountSettings}
     * @memberof AccountSettingList
     */
    _embedded: EmbeddedAccountSettings;
    /**
     * 
     * @type {number}
     * @memberof AccountSettingList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof AccountSettingList
     */
    total: number;
}
/**
 * AccountTeaser holds most basic and crucial Account data used for RESTful HAL
 * @export
 * @interface AccountTeaser
 */
export interface AccountTeaser {
    /**
     * 
     * @type {AccountAddress}
     * @memberof AccountTeaser
     */
    address?: AccountAddress;
    /**
     * 
     * @type {string}
     * @memberof AccountTeaser
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountTeaser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AccountTeaser
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AccountTeaser
     */
    tag: string;
}
/**
 * 
 * @export
 * @interface AdminSetting
 */
export interface AdminSetting {
    /**
     * 
     * @type {string}
     * @memberof AdminSetting
     */
    fieldType: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSetting
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminSetting
     */
    isRequired: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminSetting
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof AdminSetting
     */
    type: number;
}
/**
 * 
 * @export
 * @interface AdminSettingError
 */
export interface AdminSettingError {
    /**
     * 
     * @type {string}
     * @memberof AdminSettingError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof AdminSettingError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface AdminSettingList
 */
export interface AdminSettingList {
    /**
     * 
     * @type {EmbeddedAdminSettings}
     * @memberof AdminSettingList
     */
    _embedded: EmbeddedAdminSettings;
    /**
     * 
     * @type {number}
     * @memberof AdminSettingList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof AdminSettingList
     */
    total: number;
}
/**
 * 
 * @export
 * @interface AgencyNumber
 */
export interface AgencyNumber {
    /**
     * 
     * @type {string}
     * @memberof AgencyNumber
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyNumber
     */
    insurerId: string;
    /**
     * 
     * @type {Array<MultipleAgencyNumber>}
     * @memberof AgencyNumber
     */
    numbers: Array<MultipleAgencyNumber>;
}
/**
 * 
 * @export
 * @interface AgencyNumberError
 */
export interface AgencyNumberError {
    /**
     * 
     * @type {string}
     * @memberof AgencyNumberError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof AgencyNumberError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface AgencyNumbersList
 */
export interface AgencyNumbersList {
    /**
     * 
     * @type {EmbeddedAgencyNumbers}
     * @memberof AgencyNumbersList
     */
    _embedded: EmbeddedAgencyNumbers;
    /**
     * 
     * @type {number}
     * @memberof AgencyNumbersList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof AgencyNumbersList
     */
    total: number;
}
/**
 * EmbeddedAccountSettings is a Representation object of Settings
 * @export
 * @interface EmbeddedAccountSettings
 */
export interface EmbeddedAccountSettings {
    /**
     * 
     * @type {Array<AccountSetting>}
     * @memberof EmbeddedAccountSettings
     */
    accountSettings: Array<AccountSetting>;
}
/**
 * EmbeddedAccountTeasers is a Representation object of AccountTeasers
 * @export
 * @interface EmbeddedAccountTeasers
 */
export interface EmbeddedAccountTeasers {
    /**
     * AccountTeasers type is a \"generic\" which holds a slice of AccountTeaser
     * @type {Array<AccountTeaser>}
     * @memberof EmbeddedAccountTeasers
     */
    accounts: Array<AccountTeaser>;
}
/**
 * 
 * @export
 * @interface EmbeddedAdminSettings
 */
export interface EmbeddedAdminSettings {
    /**
     * 
     * @type {Array<AdminSetting>}
     * @memberof EmbeddedAdminSettings
     */
    adminSettings: Array<AdminSetting>;
}
/**
 * 
 * @export
 * @interface EmbeddedAgencyNumbers
 */
export interface EmbeddedAgencyNumbers {
    /**
     * 
     * @type {Array<AgencyNumber>}
     * @memberof EmbeddedAgencyNumbers
     */
    agencyNumbers: Array<AgencyNumber>;
}
/**
 * EmbeddedIndustryTeasers is a Representation object of IndustryTeasers
 * @export
 * @interface EmbeddedIndustryTeasers
 */
export interface EmbeddedIndustryTeasers {
    /**
     * IndustryTeasers type is a \"generic\" which holds a slice of IndustryTeaser
     * @type {Array<IndustryTeaser>}
     * @memberof EmbeddedIndustryTeasers
     */
    industries: Array<IndustryTeaser>;
}
/**
 * EmbeddedInsurers is a Representation object of Insurers
 * @export
 * @interface EmbeddedInsurers
 */
export interface EmbeddedInsurers {
    /**
     * 
     * @type {Array<InsurerTeaser>}
     * @memberof EmbeddedInsurers
     */
    insurers: Array<InsurerTeaser>;
}
/**
 * 
 * @export
 * @interface EmbeddedMultipleAgencyNumbers
 */
export interface EmbeddedMultipleAgencyNumbers {
    /**
     * 
     * @type {Array<MultipleAgencyNumber>}
     * @memberof EmbeddedMultipleAgencyNumbers
     */
    multipleAgencyNumbers: Array<MultipleAgencyNumber>;
}
/**
 * EmbeddedPortalSettings is a Representation object of Settings
 * @export
 * @interface EmbeddedPortalSettings
 */
export interface EmbeddedPortalSettings {
    /**
     * 
     * @type {Array<PortalSetting>}
     * @memberof EmbeddedPortalSettings
     */
    portalSettings: Array<PortalSetting>;
}
/**
 * EmbeddedProductTeasers is a Representation object of ProductTeasers
 * @export
 * @interface EmbeddedProductTeasers
 */
export interface EmbeddedProductTeasers {
    /**
     * Products type is a \"generic\" which holds a slice of ProductTeaser
     * @type {Array<Product>}
     * @memberof EmbeddedProductTeasers
     */
    products: Array<Product>;
}
/**
 * EmbeddedProfessionAliases is a Representation object of ProfessionAliases
 * @export
 * @interface EmbeddedProfessionAliases
 */
export interface EmbeddedProfessionAliases {
    /**
     * ProfessionAliases type is a \"generic\" which holds a slice of ProfessionAlias
     * @type {Array<ProfessionAlias>}
     * @memberof EmbeddedProfessionAliases
     */
    professionAliases: Array<ProfessionAlias>;
}
/**
 * EmbeddedProfessions is a Representation object of Professions
 * @export
 * @interface EmbeddedProfessions
 */
export interface EmbeddedProfessions {
    /**
     * Professions type is a \"generic\" which holds a slice of Profession
     * @type {Array<Profession>}
     * @memberof EmbeddedProfessions
     */
    professions: Array<Profession>;
}
/**
 * Entity holds most basic and crucial Entity data used for REST entity
 * @export
 * @interface Entity
 */
export interface Entity {
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof Entity
     */
    isActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Entity
     */
    isDeleted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Entity
     */
    isLocked: boolean;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @interface Image
 */
export interface Image {
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    url?: string;
}
/**
 * Industry holds most basic and crucial Industry data used for REST entity
 * @export
 * @interface Industry
 */
export interface Industry {
    /**
     * 
     * @type {Entity}
     * @memberof Industry
     */
    entity: Entity;
    /**
     * 
     * @type {string}
     * @memberof Industry
     */
    hash: string;
    /**
     * 
     * @type {string}
     * @memberof Industry
     */
    info?: string;
    /**
     * 
     * @type {string}
     * @memberof Industry
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Industry
     */
    text?: string;
}
/**
 * IndustryError is an Error Representation object
 * @export
 * @interface IndustryError
 */
export interface IndustryError {
    /**
     * 
     * @type {string}
     * @memberof IndustryError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IndustryError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * IndustryList is a Representation object
 * @export
 * @interface IndustryList
 */
export interface IndustryList {
    /**
     * 
     * @type {EmbeddedIndustryTeasers}
     * @memberof IndustryList
     */
    _embedded: EmbeddedIndustryTeasers;
    /**
     * 
     * @type {number}
     * @memberof IndustryList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof IndustryList
     */
    total: number;
}
/**
 * IndustryTeaser holds most basic and crutial Industry data used for RESTful HAL
 * @export
 * @interface IndustryTeaser
 */
export interface IndustryTeaser {
    /**
     * 
     * @type {string}
     * @memberof IndustryTeaser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IndustryTeaser
     */
    name: string;
}
/**
 * 
 * @export
 * @interface Insurer
 */
export interface Insurer {
    /**
     * 
     * @type {Entity}
     * @memberof Insurer
     */
    entity: Entity;
    /**
     * 
     * @type {string}
     * @memberof Insurer
     */
    externalId?: string;
    /**
     * 
     * @type {string}
     * @memberof Insurer
     */
    icon?: string;
    /**
     * 
     * @type {string}
     * @memberof Insurer
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Insurer
     */
    tag: string;
}
/**
 * 
 * @export
 * @interface InsurerError
 */
export interface InsurerError {
    /**
     * 
     * @type {string}
     * @memberof InsurerError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof InsurerError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface InsurerList
 */
export interface InsurerList {
    /**
     * 
     * @type {EmbeddedInsurers}
     * @memberof InsurerList
     */
    _embedded: EmbeddedInsurers;
    /**
     * 
     * @type {number}
     * @memberof InsurerList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof InsurerList
     */
    total: number;
}
/**
 * 
 * @export
 * @interface InsurerTeaser
 */
export interface InsurerTeaser {
    /**
     * 
     * @type {Entity}
     * @memberof InsurerTeaser
     */
    entity: Entity;
    /**
     * 
     * @type {string}
     * @memberof InsurerTeaser
     */
    externalId?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurerTeaser
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof InsurerTeaser
     */
    tag: string;
}
/**
 * 
 * @export
 * @interface MultipleAgencyNumber
 */
export interface MultipleAgencyNumber {
    /**
     * 
     * @type {string}
     * @memberof MultipleAgencyNumber
     */
    agencyId?: string;
    /**
     * 
     * @type {string}
     * @memberof MultipleAgencyNumber
     */
    agencyNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof MultipleAgencyNumber
     */
    comment?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MultipleAgencyNumber
     */
    defaultForCalculatorOffers?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MultipleAgencyNumber
     */
    defaultForTenderingOffers?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MultipleAgencyNumber
     */
    externalId?: string;
    /**
     * 
     * @type {string}
     * @memberof MultipleAgencyNumber
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof MultipleAgencyNumber
     */
    type?: number;
}
/**
 * 
 * @export
 * @interface MultipleAgencyNumberError
 */
export interface MultipleAgencyNumberError {
    /**
     * 
     * @type {string}
     * @memberof MultipleAgencyNumberError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof MultipleAgencyNumberError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface MultipleAgencyNumbersList
 */
export interface MultipleAgencyNumbersList {
    /**
     * 
     * @type {EmbeddedMultipleAgencyNumbers}
     * @memberof MultipleAgencyNumbersList
     */
    _embedded: EmbeddedMultipleAgencyNumbers;
    /**
     * 
     * @type {number}
     * @memberof MultipleAgencyNumbersList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof MultipleAgencyNumbersList
     */
    total: number;
}
/**
 * 
 * @export
 * @interface PortalLinksSetting
 */
export interface PortalLinksSetting {
    /**
     * 
     * @type {string}
     * @memberof PortalLinksSetting
     */
    generalConditionsURL: string;
    /**
     * 
     * @type {string}
     * @memberof PortalLinksSetting
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PortalLinksSetting
     */
    insuranceConditionsURL: string;
    /**
     * 
     * @type {string}
     * @memberof PortalLinksSetting
     */
    privacyPolicyURL: string;
}
/**
 * 
 * @export
 * @interface PortalLinksSettingError
 */
export interface PortalLinksSettingError {
    /**
     * 
     * @type {string}
     * @memberof PortalLinksSettingError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof PortalLinksSettingError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface PortalSetting
 */
export interface PortalSetting {
    /**
     * 
     * @type {string}
     * @memberof PortalSetting
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof PortalSetting
     */
    isDefault: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof PortalSetting
     */
    jsonValue?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof PortalSetting
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof PortalSetting
     */
    stringValue?: string;
}
/**
 * 
 * @export
 * @interface PortalSettingError
 */
export interface PortalSettingError {
    /**
     * 
     * @type {string}
     * @memberof PortalSettingError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof PortalSettingError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface PortalSettingList
 */
export interface PortalSettingList {
    /**
     * 
     * @type {EmbeddedPortalSettings}
     * @memberof PortalSettingList
     */
    _embedded: EmbeddedPortalSettings;
    /**
     * 
     * @type {number}
     * @memberof PortalSettingList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof PortalSettingList
     */
    total: number;
}
/**
 * Postcode is REST Data Transfer Object
 * @export
 * @interface Postcode
 */
export interface Postcode {
    /**
     * 
     * @type {string}
     * @memberof Postcode
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof Postcode
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Postcode
     */
    postcode: string;
}
/**
 * PostcodeError is an Error Representation object
 * @export
 * @interface PostcodeError
 */
export interface PostcodeError {
    /**
     * 
     * @type {string}
     * @memberof PostcodeError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof PostcodeError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * Product holds most basic and crucial Product data used for REST entity
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {Entity}
     * @memberof Product
     */
    entity: Entity;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    example?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    icon: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    info?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    isCommercial: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    isCorporate: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    isLead: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    isNameObligatory: boolean;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    nameLong?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    nameShort?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    rank: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    tag: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    text?: string;
}
/**
 * ProductError is an Error Representation object
 * @export
 * @interface ProductError
 */
export interface ProductError {
    /**
     * 
     * @type {string}
     * @memberof ProductError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ProductError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * ProductList is a Representation object
 * @export
 * @interface ProductList
 */
export interface ProductList {
    /**
     * 
     * @type {EmbeddedProductTeasers}
     * @memberof ProductList
     */
    _embedded: EmbeddedProductTeasers;
    /**
     * 
     * @type {number}
     * @memberof ProductList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof ProductList
     */
    total: number;
}
/**
 * Profession holds most basic and crucial Profession data used for REST entity
 * @export
 * @interface Profession
 */
export interface Profession {
    /**
     * 
     * @type {Entity}
     * @memberof Profession
     */
    entity: Entity;
    /**
     * 
     * @type {string}
     * @memberof Profession
     */
    hash: string;
    /**
     * 
     * @type {Industry}
     * @memberof Profession
     */
    industry: Industry;
    /**
     * 
     * @type {string}
     * @memberof Profession
     */
    info?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Profession
     */
    isOffice: boolean;
    /**
     * 
     * @type {string}
     * @memberof Profession
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Profession
     */
    text?: string;
}
/**
 * ProfessionAlias holds most basic and crucial ProfessionAlias data used for REST entity
 * @export
 * @interface ProfessionAlias
 */
export interface ProfessionAlias {
    /**
     * 
     * @type {string}
     * @memberof ProfessionAlias
     */
    alias: string;
    /**
     * 
     * @type {Entity}
     * @memberof ProfessionAlias
     */
    entity: Entity;
    /**
     * 
     * @type {string}
     * @memberof ProfessionAlias
     */
    hash: string;
    /**
     * 
     * @type {Profession}
     * @memberof ProfessionAlias
     */
    profession: Profession;
}
/**
 * ProfessionAliasError is an Error Representation object
 * @export
 * @interface ProfessionAliasError
 */
export interface ProfessionAliasError {
    /**
     * 
     * @type {string}
     * @memberof ProfessionAliasError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ProfessionAliasError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * ProfessionAliasList is a Representation object
 * @export
 * @interface ProfessionAliasList
 */
export interface ProfessionAliasList {
    /**
     * 
     * @type {EmbeddedProfessionAliases}
     * @memberof ProfessionAliasList
     */
    _embedded: EmbeddedProfessionAliases;
    /**
     * 
     * @type {number}
     * @memberof ProfessionAliasList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof ProfessionAliasList
     */
    total: number;
}
/**
 * ProfessionError is an Error Representation object
 * @export
 * @interface ProfessionError
 */
export interface ProfessionError {
    /**
     * 
     * @type {string}
     * @memberof ProfessionError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ProfessionError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * ProfessionList is a Representation object
 * @export
 * @interface ProfessionList
 */
export interface ProfessionList {
    /**
     * 
     * @type {EmbeddedProfessions}
     * @memberof ProfessionList
     */
    _embedded: EmbeddedProfessions;
    /**
     * 
     * @type {number}
     * @memberof ProfessionList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof ProfessionList
     */
    total: number;
}
/**
 * VerifyPostcode is REST Data Transfer Object
 * @export
 * @interface VerifyPostcode
 */
export interface VerifyPostcode {
    /**
     * 
     * @type {string}
     * @memberof VerifyPostcode
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyPostcode
     */
    postcode: string;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {Array<string>} [settingIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountSettingRequest: async (accountId: number, settingIds?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountSettingRequest', 'accountId', accountId)
            const localVarPath = `/accounts/{accountId}/settings`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (settingIds) {
                localVarQueryParameter['settingIds'] = settingIds.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {Array<string>} [insurerIds] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agencyNumberRequest: async (accountId: string, insurerIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('agencyNumberRequest', 'accountId', accountId)
            const localVarPath = `/accounts/{accountId}/agencyNumbers`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (insurerIds) {
                localVarQueryParameter['insurerIds'] = insurerIds.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (noPagination !== undefined) {
                localVarQueryParameter['noPagination'] = noPagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDAccountDesignRequest: async (accountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('byIDAccountDesignRequest', 'accountId', accountId)
            const localVarPath = `/accounts/{accountId}/design`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDAccountRequest: async (accountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('byIDAccountRequest', 'accountId', accountId)
            const localVarPath = `/accounts/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} industryId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDProductAndAccountAndIndustryRequest: async (productId: string, industryId: string, accountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('byIDProductAndAccountAndIndustryRequest', 'productId', productId)
            // verify required parameter 'industryId' is not null or undefined
            assertParamExists('byIDProductAndAccountAndIndustryRequest', 'industryId', industryId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('byIDProductAndAccountAndIndustryRequest', 'accountId', accountId)
            const localVarPath = `/accounts/{accountId}/industries/{industryId}/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"industryId"}}`, encodeURIComponent(String(industryId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} professionId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDProductAndAccountAndProfessionRequest: async (productId: string, professionId: string, accountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('byIDProductAndAccountAndProfessionRequest', 'productId', productId)
            // verify required parameter 'professionId' is not null or undefined
            assertParamExists('byIDProductAndAccountAndProfessionRequest', 'professionId', professionId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('byIDProductAndAccountAndProfessionRequest', 'accountId', accountId)
            const localVarPath = `/accounts/{accountId}/professions/{professionId}/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"professionId"}}`, encodeURIComponent(String(professionId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDProductAndAccountRequest: async (productId: string, accountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('byIDProductAndAccountRequest', 'productId', productId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('byIDProductAndAccountRequest', 'accountId', accountId)
            const localVarPath = `/accounts/{accountId}/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalLinksSettingRequest: async (accountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('portalLinksSettingRequest', 'accountId', accountId)
            const localVarPath = `/accounts/{accountId}/portalLinksSetting`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {Array<string>} [settingIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalSettingRequest: async (accountId: number, settingIds?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('portalSettingRequest', 'accountId', accountId)
            const localVarPath = `/accounts/{accountId}/portalSettings`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (settingIds) {
                localVarQueryParameter['settingIds'] = settingIds.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [tag] 
         * @param {string} [insurerId] 
         * @param {boolean} [isTest] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {'asc' | 'desc'} [orderId] 
         * @param {'asc' | 'desc'} [orderActive] 
         * @param {'asc' | 'desc'} [orderCreatedAt] 
         * @param {'asc' | 'desc'} [orderName] 
         * @param {'asc' | 'desc'} [orderType] 
         * @param {'asc' | 'desc'} [orderTag] 
         * @param {'asc' | 'desc'} [orderIsTest] 
         * @param {'asc' | 'desc'} [orderContactName] 
         * @param {'asc' | 'desc'} [orderContactPhoneFirst] 
         * @param {'asc' | 'desc'} [orderContactLabelFirst] 
         * @param {'asc' | 'desc'} [orderContactPhoneSecond] 
         * @param {'asc' | 'desc'} [orderContactLabelSecond] 
         * @param {'asc' | 'desc'} [orderContactEmail] 
         * @param {'asc' | 'desc'} [orderContactWorkTime] 
         * @param {'asc' | 'desc'} [orderStreet] 
         * @param {'asc' | 'desc'} [orderStreetNumber] 
         * @param {'asc' | 'desc'} [orderZip] 
         * @param {'asc' | 'desc'} [orderCity] 
         * @param {string} [filterId] 
         * @param {string} [filterName] 
         * @param {string} [filterTag] 
         * @param {string} [filterContactName] 
         * @param {string} [filterContactPhoneFirst] 
         * @param {string} [filterContactLabelFirst] 
         * @param {string} [filterContactPhoneSecond] 
         * @param {string} [filterContactLabelSecond] 
         * @param {string} [filterContactEmail] 
         * @param {string} [filterStreet] 
         * @param {string} [filterStreetNumber] 
         * @param {string} [filterZip] 
         * @param {string} [filterCity] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAccountRequest: async (name?: string, tag?: string, insurerId?: string, isTest?: boolean, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, orderId?: 'asc' | 'desc', orderActive?: 'asc' | 'desc', orderCreatedAt?: 'asc' | 'desc', orderName?: 'asc' | 'desc', orderType?: 'asc' | 'desc', orderTag?: 'asc' | 'desc', orderIsTest?: 'asc' | 'desc', orderContactName?: 'asc' | 'desc', orderContactPhoneFirst?: 'asc' | 'desc', orderContactLabelFirst?: 'asc' | 'desc', orderContactPhoneSecond?: 'asc' | 'desc', orderContactLabelSecond?: 'asc' | 'desc', orderContactEmail?: 'asc' | 'desc', orderContactWorkTime?: 'asc' | 'desc', orderStreet?: 'asc' | 'desc', orderStreetNumber?: 'asc' | 'desc', orderZip?: 'asc' | 'desc', orderCity?: 'asc' | 'desc', filterId?: string, filterName?: string, filterTag?: string, filterContactName?: string, filterContactPhoneFirst?: string, filterContactLabelFirst?: string, filterContactPhoneSecond?: string, filterContactLabelSecond?: string, filterContactEmail?: string, filterStreet?: string, filterStreetNumber?: string, filterZip?: string, filterCity?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (insurerId !== undefined) {
                localVarQueryParameter['insurerId'] = insurerId;
            }

            if (isTest !== undefined) {
                localVarQueryParameter['isTest'] = isTest;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (noPagination !== undefined) {
                localVarQueryParameter['noPagination'] = noPagination;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (includeLocked !== undefined) {
                localVarQueryParameter['includeLocked'] = includeLocked;
            }

            if (includeInactive !== undefined) {
                localVarQueryParameter['includeInactive'] = includeInactive;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['order[id]'] = orderId;
            }

            if (orderActive !== undefined) {
                localVarQueryParameter['order[active]'] = orderActive;
            }

            if (orderCreatedAt !== undefined) {
                localVarQueryParameter['order[created_at]'] = orderCreatedAt;
            }

            if (orderName !== undefined) {
                localVarQueryParameter['order[name]'] = orderName;
            }

            if (orderType !== undefined) {
                localVarQueryParameter['order[type]'] = orderType;
            }

            if (orderTag !== undefined) {
                localVarQueryParameter['order[tag]'] = orderTag;
            }

            if (orderIsTest !== undefined) {
                localVarQueryParameter['order[is_test]'] = orderIsTest;
            }

            if (orderContactName !== undefined) {
                localVarQueryParameter['order[contact_name]'] = orderContactName;
            }

            if (orderContactPhoneFirst !== undefined) {
                localVarQueryParameter['order[contact_phone_first]'] = orderContactPhoneFirst;
            }

            if (orderContactLabelFirst !== undefined) {
                localVarQueryParameter['order[contact_label_first]'] = orderContactLabelFirst;
            }

            if (orderContactPhoneSecond !== undefined) {
                localVarQueryParameter['order[contact_phone_second]'] = orderContactPhoneSecond;
            }

            if (orderContactLabelSecond !== undefined) {
                localVarQueryParameter['order[contact_label_second]'] = orderContactLabelSecond;
            }

            if (orderContactEmail !== undefined) {
                localVarQueryParameter['order[contact_email]'] = orderContactEmail;
            }

            if (orderContactWorkTime !== undefined) {
                localVarQueryParameter['order[contact_work_time]'] = orderContactWorkTime;
            }

            if (orderStreet !== undefined) {
                localVarQueryParameter['order[street]'] = orderStreet;
            }

            if (orderStreetNumber !== undefined) {
                localVarQueryParameter['order[street_number]'] = orderStreetNumber;
            }

            if (orderZip !== undefined) {
                localVarQueryParameter['order[zip]'] = orderZip;
            }

            if (orderCity !== undefined) {
                localVarQueryParameter['order[city]'] = orderCity;
            }

            if (filterId !== undefined) {
                localVarQueryParameter['filter[id]'] = filterId;
            }

            if (filterName !== undefined) {
                localVarQueryParameter['filter[name]'] = filterName;
            }

            if (filterTag !== undefined) {
                localVarQueryParameter['filter[tag]'] = filterTag;
            }

            if (filterContactName !== undefined) {
                localVarQueryParameter['filter[contact_name]'] = filterContactName;
            }

            if (filterContactPhoneFirst !== undefined) {
                localVarQueryParameter['filter[contact_phone_first]'] = filterContactPhoneFirst;
            }

            if (filterContactLabelFirst !== undefined) {
                localVarQueryParameter['filter[contact_label_first]'] = filterContactLabelFirst;
            }

            if (filterContactPhoneSecond !== undefined) {
                localVarQueryParameter['filter[contact_phone_second]'] = filterContactPhoneSecond;
            }

            if (filterContactLabelSecond !== undefined) {
                localVarQueryParameter['filter[contact_label_second]'] = filterContactLabelSecond;
            }

            if (filterContactEmail !== undefined) {
                localVarQueryParameter['filter[contact_email]'] = filterContactEmail;
            }

            if (filterStreet !== undefined) {
                localVarQueryParameter['filter[street]'] = filterStreet;
            }

            if (filterStreetNumber !== undefined) {
                localVarQueryParameter['filter[street_number]'] = filterStreetNumber;
            }

            if (filterZip !== undefined) {
                localVarQueryParameter['filter[zip]'] = filterZip;
            }

            if (filterCity !== undefined) {
                localVarQueryParameter['filter[city]'] = filterCity;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {boolean} [forInsurerList] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInsurerForAccountRequest: async (accountId: string, forInsurerList?: boolean, limit?: number, offset?: number, noPagination?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('searchInsurerForAccountRequest', 'accountId', accountId)
            const localVarPath = `/accounts/{accountId}/insurers`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (forInsurerList !== undefined) {
                localVarQueryParameter['forInsurerList'] = forInsurerList;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (noPagination !== undefined) {
                localVarQueryParameter['noPagination'] = noPagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} industryId 
         * @param {string} accountId 
         * @param {Array<string>} [productIds] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProductForAccountAndIndustryRequest: async (industryId: string, accountId: string, productIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'industryId' is not null or undefined
            assertParamExists('searchProductForAccountAndIndustryRequest', 'industryId', industryId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('searchProductForAccountAndIndustryRequest', 'accountId', accountId)
            const localVarPath = `/accounts/{accountId}/industries/{industryId}/products`
                .replace(`{${"industryId"}}`, encodeURIComponent(String(industryId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (productIds) {
                localVarQueryParameter['productIds'] = productIds.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (noPagination !== undefined) {
                localVarQueryParameter['noPagination'] = noPagination;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (includeLocked !== undefined) {
                localVarQueryParameter['includeLocked'] = includeLocked;
            }

            if (includeInactive !== undefined) {
                localVarQueryParameter['includeInactive'] = includeInactive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} professionId 
         * @param {string} accountId 
         * @param {Array<string>} [productIds] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProductForAccountAndProfessionRequest: async (professionId: string, accountId: string, productIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'professionId' is not null or undefined
            assertParamExists('searchProductForAccountAndProfessionRequest', 'professionId', professionId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('searchProductForAccountAndProfessionRequest', 'accountId', accountId)
            const localVarPath = `/accounts/{accountId}/professions/{professionId}/products`
                .replace(`{${"professionId"}}`, encodeURIComponent(String(professionId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (productIds) {
                localVarQueryParameter['productIds'] = productIds.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (noPagination !== undefined) {
                localVarQueryParameter['noPagination'] = noPagination;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (includeLocked !== undefined) {
                localVarQueryParameter['includeLocked'] = includeLocked;
            }

            if (includeInactive !== undefined) {
                localVarQueryParameter['includeInactive'] = includeInactive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {Array<string>} [productIds] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProductForAccountRequest: async (accountId: string, productIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('searchProductForAccountRequest', 'accountId', accountId)
            const localVarPath = `/accounts/{accountId}/products`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (productIds) {
                localVarQueryParameter['productIds'] = productIds.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (noPagination !== undefined) {
                localVarQueryParameter['noPagination'] = noPagination;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (includeLocked !== undefined) {
                localVarQueryParameter['includeLocked'] = includeLocked;
            }

            if (includeInactive !== undefined) {
                localVarQueryParameter['includeInactive'] = includeInactive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {Array<string>} [settingIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountSettingRequest(accountId: number, settingIds?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountSettingList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountSettingRequest(accountId, settingIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accountId 
         * @param {Array<string>} [insurerIds] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agencyNumberRequest(accountId: string, insurerIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgencyNumbersList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.agencyNumberRequest(accountId, insurerIds, limit, offset, noPagination, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async byIDAccountDesignRequest(accountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountDesignSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.byIDAccountDesignRequest(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async byIDAccountRequest(accountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.byIDAccountRequest(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} industryId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async byIDProductAndAccountAndIndustryRequest(productId: string, industryId: string, accountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.byIDProductAndAccountAndIndustryRequest(productId, industryId, accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} professionId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async byIDProductAndAccountAndProfessionRequest(productId: string, professionId: string, accountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.byIDProductAndAccountAndProfessionRequest(productId, professionId, accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async byIDProductAndAccountRequest(productId: string, accountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.byIDProductAndAccountRequest(productId, accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalLinksSettingRequest(accountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortalLinksSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portalLinksSettingRequest(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {Array<string>} [settingIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalSettingRequest(accountId: number, settingIds?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortalSettingList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portalSettingRequest(accountId, settingIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [tag] 
         * @param {string} [insurerId] 
         * @param {boolean} [isTest] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {'asc' | 'desc'} [orderId] 
         * @param {'asc' | 'desc'} [orderActive] 
         * @param {'asc' | 'desc'} [orderCreatedAt] 
         * @param {'asc' | 'desc'} [orderName] 
         * @param {'asc' | 'desc'} [orderType] 
         * @param {'asc' | 'desc'} [orderTag] 
         * @param {'asc' | 'desc'} [orderIsTest] 
         * @param {'asc' | 'desc'} [orderContactName] 
         * @param {'asc' | 'desc'} [orderContactPhoneFirst] 
         * @param {'asc' | 'desc'} [orderContactLabelFirst] 
         * @param {'asc' | 'desc'} [orderContactPhoneSecond] 
         * @param {'asc' | 'desc'} [orderContactLabelSecond] 
         * @param {'asc' | 'desc'} [orderContactEmail] 
         * @param {'asc' | 'desc'} [orderContactWorkTime] 
         * @param {'asc' | 'desc'} [orderStreet] 
         * @param {'asc' | 'desc'} [orderStreetNumber] 
         * @param {'asc' | 'desc'} [orderZip] 
         * @param {'asc' | 'desc'} [orderCity] 
         * @param {string} [filterId] 
         * @param {string} [filterName] 
         * @param {string} [filterTag] 
         * @param {string} [filterContactName] 
         * @param {string} [filterContactPhoneFirst] 
         * @param {string} [filterContactLabelFirst] 
         * @param {string} [filterContactPhoneSecond] 
         * @param {string} [filterContactLabelSecond] 
         * @param {string} [filterContactEmail] 
         * @param {string} [filterStreet] 
         * @param {string} [filterStreetNumber] 
         * @param {string} [filterZip] 
         * @param {string} [filterCity] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAccountRequest(name?: string, tag?: string, insurerId?: string, isTest?: boolean, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, orderId?: 'asc' | 'desc', orderActive?: 'asc' | 'desc', orderCreatedAt?: 'asc' | 'desc', orderName?: 'asc' | 'desc', orderType?: 'asc' | 'desc', orderTag?: 'asc' | 'desc', orderIsTest?: 'asc' | 'desc', orderContactName?: 'asc' | 'desc', orderContactPhoneFirst?: 'asc' | 'desc', orderContactLabelFirst?: 'asc' | 'desc', orderContactPhoneSecond?: 'asc' | 'desc', orderContactLabelSecond?: 'asc' | 'desc', orderContactEmail?: 'asc' | 'desc', orderContactWorkTime?: 'asc' | 'desc', orderStreet?: 'asc' | 'desc', orderStreetNumber?: 'asc' | 'desc', orderZip?: 'asc' | 'desc', orderCity?: 'asc' | 'desc', filterId?: string, filterName?: string, filterTag?: string, filterContactName?: string, filterContactPhoneFirst?: string, filterContactLabelFirst?: string, filterContactPhoneSecond?: string, filterContactLabelSecond?: string, filterContactEmail?: string, filterStreet?: string, filterStreetNumber?: string, filterZip?: string, filterCity?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAccountRequest(name, tag, insurerId, isTest, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, orderId, orderActive, orderCreatedAt, orderName, orderType, orderTag, orderIsTest, orderContactName, orderContactPhoneFirst, orderContactLabelFirst, orderContactPhoneSecond, orderContactLabelSecond, orderContactEmail, orderContactWorkTime, orderStreet, orderStreetNumber, orderZip, orderCity, filterId, filterName, filterTag, filterContactName, filterContactPhoneFirst, filterContactLabelFirst, filterContactPhoneSecond, filterContactLabelSecond, filterContactEmail, filterStreet, filterStreetNumber, filterZip, filterCity, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accountId 
         * @param {boolean} [forInsurerList] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchInsurerForAccountRequest(accountId: string, forInsurerList?: boolean, limit?: number, offset?: number, noPagination?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsurerList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchInsurerForAccountRequest(accountId, forInsurerList, limit, offset, noPagination, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} industryId 
         * @param {string} accountId 
         * @param {Array<string>} [productIds] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchProductForAccountAndIndustryRequest(industryId: string, accountId: string, productIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchProductForAccountAndIndustryRequest(industryId, accountId, productIds, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} professionId 
         * @param {string} accountId 
         * @param {Array<string>} [productIds] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchProductForAccountAndProfessionRequest(professionId: string, accountId: string, productIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchProductForAccountAndProfessionRequest(professionId, accountId, productIds, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accountId 
         * @param {Array<string>} [productIds] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchProductForAccountRequest(accountId: string, productIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchProductForAccountRequest(accountId, productIds, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {Array<string>} [settingIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountSettingRequest(accountId: number, settingIds?: Array<string>, options?: any): AxiosPromise<AccountSettingList> {
            return localVarFp.accountSettingRequest(accountId, settingIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountId 
         * @param {Array<string>} [insurerIds] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agencyNumberRequest(accountId: string, insurerIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, options?: any): AxiosPromise<AgencyNumbersList> {
            return localVarFp.agencyNumberRequest(accountId, insurerIds, limit, offset, noPagination, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDAccountDesignRequest(accountId: string, options?: any): AxiosPromise<AccountDesignSetting> {
            return localVarFp.byIDAccountDesignRequest(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDAccountRequest(accountId: string, options?: any): AxiosPromise<Account> {
            return localVarFp.byIDAccountRequest(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} industryId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDProductAndAccountAndIndustryRequest(productId: string, industryId: string, accountId: string, options?: any): AxiosPromise<Product> {
            return localVarFp.byIDProductAndAccountAndIndustryRequest(productId, industryId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} professionId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDProductAndAccountAndProfessionRequest(productId: string, professionId: string, accountId: string, options?: any): AxiosPromise<Product> {
            return localVarFp.byIDProductAndAccountAndProfessionRequest(productId, professionId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDProductAndAccountRequest(productId: string, accountId: string, options?: any): AxiosPromise<Product> {
            return localVarFp.byIDProductAndAccountRequest(productId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalLinksSettingRequest(accountId: string, options?: any): AxiosPromise<PortalLinksSetting> {
            return localVarFp.portalLinksSettingRequest(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountId 
         * @param {Array<string>} [settingIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalSettingRequest(accountId: number, settingIds?: Array<string>, options?: any): AxiosPromise<PortalSettingList> {
            return localVarFp.portalSettingRequest(accountId, settingIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [tag] 
         * @param {string} [insurerId] 
         * @param {boolean} [isTest] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {'asc' | 'desc'} [orderId] 
         * @param {'asc' | 'desc'} [orderActive] 
         * @param {'asc' | 'desc'} [orderCreatedAt] 
         * @param {'asc' | 'desc'} [orderName] 
         * @param {'asc' | 'desc'} [orderType] 
         * @param {'asc' | 'desc'} [orderTag] 
         * @param {'asc' | 'desc'} [orderIsTest] 
         * @param {'asc' | 'desc'} [orderContactName] 
         * @param {'asc' | 'desc'} [orderContactPhoneFirst] 
         * @param {'asc' | 'desc'} [orderContactLabelFirst] 
         * @param {'asc' | 'desc'} [orderContactPhoneSecond] 
         * @param {'asc' | 'desc'} [orderContactLabelSecond] 
         * @param {'asc' | 'desc'} [orderContactEmail] 
         * @param {'asc' | 'desc'} [orderContactWorkTime] 
         * @param {'asc' | 'desc'} [orderStreet] 
         * @param {'asc' | 'desc'} [orderStreetNumber] 
         * @param {'asc' | 'desc'} [orderZip] 
         * @param {'asc' | 'desc'} [orderCity] 
         * @param {string} [filterId] 
         * @param {string} [filterName] 
         * @param {string} [filterTag] 
         * @param {string} [filterContactName] 
         * @param {string} [filterContactPhoneFirst] 
         * @param {string} [filterContactLabelFirst] 
         * @param {string} [filterContactPhoneSecond] 
         * @param {string} [filterContactLabelSecond] 
         * @param {string} [filterContactEmail] 
         * @param {string} [filterStreet] 
         * @param {string} [filterStreetNumber] 
         * @param {string} [filterZip] 
         * @param {string} [filterCity] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAccountRequest(name?: string, tag?: string, insurerId?: string, isTest?: boolean, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, orderId?: 'asc' | 'desc', orderActive?: 'asc' | 'desc', orderCreatedAt?: 'asc' | 'desc', orderName?: 'asc' | 'desc', orderType?: 'asc' | 'desc', orderTag?: 'asc' | 'desc', orderIsTest?: 'asc' | 'desc', orderContactName?: 'asc' | 'desc', orderContactPhoneFirst?: 'asc' | 'desc', orderContactLabelFirst?: 'asc' | 'desc', orderContactPhoneSecond?: 'asc' | 'desc', orderContactLabelSecond?: 'asc' | 'desc', orderContactEmail?: 'asc' | 'desc', orderContactWorkTime?: 'asc' | 'desc', orderStreet?: 'asc' | 'desc', orderStreetNumber?: 'asc' | 'desc', orderZip?: 'asc' | 'desc', orderCity?: 'asc' | 'desc', filterId?: string, filterName?: string, filterTag?: string, filterContactName?: string, filterContactPhoneFirst?: string, filterContactLabelFirst?: string, filterContactPhoneSecond?: string, filterContactLabelSecond?: string, filterContactEmail?: string, filterStreet?: string, filterStreetNumber?: string, filterZip?: string, filterCity?: string, search?: string, options?: any): AxiosPromise<AccountList> {
            return localVarFp.searchAccountRequest(name, tag, insurerId, isTest, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, orderId, orderActive, orderCreatedAt, orderName, orderType, orderTag, orderIsTest, orderContactName, orderContactPhoneFirst, orderContactLabelFirst, orderContactPhoneSecond, orderContactLabelSecond, orderContactEmail, orderContactWorkTime, orderStreet, orderStreetNumber, orderZip, orderCity, filterId, filterName, filterTag, filterContactName, filterContactPhoneFirst, filterContactLabelFirst, filterContactPhoneSecond, filterContactLabelSecond, filterContactEmail, filterStreet, filterStreetNumber, filterZip, filterCity, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountId 
         * @param {boolean} [forInsurerList] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInsurerForAccountRequest(accountId: string, forInsurerList?: boolean, limit?: number, offset?: number, noPagination?: boolean, options?: any): AxiosPromise<InsurerList> {
            return localVarFp.searchInsurerForAccountRequest(accountId, forInsurerList, limit, offset, noPagination, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} industryId 
         * @param {string} accountId 
         * @param {Array<string>} [productIds] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProductForAccountAndIndustryRequest(industryId: string, accountId: string, productIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): AxiosPromise<ProductList> {
            return localVarFp.searchProductForAccountAndIndustryRequest(industryId, accountId, productIds, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} professionId 
         * @param {string} accountId 
         * @param {Array<string>} [productIds] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProductForAccountAndProfessionRequest(professionId: string, accountId: string, productIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): AxiosPromise<ProductList> {
            return localVarFp.searchProductForAccountAndProfessionRequest(professionId, accountId, productIds, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountId 
         * @param {Array<string>} [productIds] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProductForAccountRequest(accountId: string, productIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): AxiosPromise<ProductList> {
            return localVarFp.searchProductForAccountRequest(accountId, productIds, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @param {number} accountId 
     * @param {Array<string>} [settingIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountSettingRequest(accountId: number, settingIds?: Array<string>, options?: any) {
        return AccountApiFp(this.configuration).accountSettingRequest(accountId, settingIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountId 
     * @param {Array<string>} [insurerIds] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {boolean} [noPagination] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public agencyNumberRequest(accountId: string, insurerIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, options?: any) {
        return AccountApiFp(this.configuration).agencyNumberRequest(accountId, insurerIds, limit, offset, noPagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public byIDAccountDesignRequest(accountId: string, options?: any) {
        return AccountApiFp(this.configuration).byIDAccountDesignRequest(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public byIDAccountRequest(accountId: string, options?: any) {
        return AccountApiFp(this.configuration).byIDAccountRequest(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {string} industryId 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public byIDProductAndAccountAndIndustryRequest(productId: string, industryId: string, accountId: string, options?: any) {
        return AccountApiFp(this.configuration).byIDProductAndAccountAndIndustryRequest(productId, industryId, accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {string} professionId 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public byIDProductAndAccountAndProfessionRequest(productId: string, professionId: string, accountId: string, options?: any) {
        return AccountApiFp(this.configuration).byIDProductAndAccountAndProfessionRequest(productId, professionId, accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public byIDProductAndAccountRequest(productId: string, accountId: string, options?: any) {
        return AccountApiFp(this.configuration).byIDProductAndAccountRequest(productId, accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public portalLinksSettingRequest(accountId: string, options?: any) {
        return AccountApiFp(this.configuration).portalLinksSettingRequest(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountId 
     * @param {Array<string>} [settingIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public portalSettingRequest(accountId: number, settingIds?: Array<string>, options?: any) {
        return AccountApiFp(this.configuration).portalSettingRequest(accountId, settingIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {string} [tag] 
     * @param {string} [insurerId] 
     * @param {boolean} [isTest] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {boolean} [noPagination] 
     * @param {boolean} [includeDeleted] 
     * @param {boolean} [includeLocked] 
     * @param {boolean} [includeInactive] 
     * @param {'asc' | 'desc'} [orderId] 
     * @param {'asc' | 'desc'} [orderActive] 
     * @param {'asc' | 'desc'} [orderCreatedAt] 
     * @param {'asc' | 'desc'} [orderName] 
     * @param {'asc' | 'desc'} [orderType] 
     * @param {'asc' | 'desc'} [orderTag] 
     * @param {'asc' | 'desc'} [orderIsTest] 
     * @param {'asc' | 'desc'} [orderContactName] 
     * @param {'asc' | 'desc'} [orderContactPhoneFirst] 
     * @param {'asc' | 'desc'} [orderContactLabelFirst] 
     * @param {'asc' | 'desc'} [orderContactPhoneSecond] 
     * @param {'asc' | 'desc'} [orderContactLabelSecond] 
     * @param {'asc' | 'desc'} [orderContactEmail] 
     * @param {'asc' | 'desc'} [orderContactWorkTime] 
     * @param {'asc' | 'desc'} [orderStreet] 
     * @param {'asc' | 'desc'} [orderStreetNumber] 
     * @param {'asc' | 'desc'} [orderZip] 
     * @param {'asc' | 'desc'} [orderCity] 
     * @param {string} [filterId] 
     * @param {string} [filterName] 
     * @param {string} [filterTag] 
     * @param {string} [filterContactName] 
     * @param {string} [filterContactPhoneFirst] 
     * @param {string} [filterContactLabelFirst] 
     * @param {string} [filterContactPhoneSecond] 
     * @param {string} [filterContactLabelSecond] 
     * @param {string} [filterContactEmail] 
     * @param {string} [filterStreet] 
     * @param {string} [filterStreetNumber] 
     * @param {string} [filterZip] 
     * @param {string} [filterCity] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public searchAccountRequest(name?: string, tag?: string, insurerId?: string, isTest?: boolean, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, orderId?: 'asc' | 'desc', orderActive?: 'asc' | 'desc', orderCreatedAt?: 'asc' | 'desc', orderName?: 'asc' | 'desc', orderType?: 'asc' | 'desc', orderTag?: 'asc' | 'desc', orderIsTest?: 'asc' | 'desc', orderContactName?: 'asc' | 'desc', orderContactPhoneFirst?: 'asc' | 'desc', orderContactLabelFirst?: 'asc' | 'desc', orderContactPhoneSecond?: 'asc' | 'desc', orderContactLabelSecond?: 'asc' | 'desc', orderContactEmail?: 'asc' | 'desc', orderContactWorkTime?: 'asc' | 'desc', orderStreet?: 'asc' | 'desc', orderStreetNumber?: 'asc' | 'desc', orderZip?: 'asc' | 'desc', orderCity?: 'asc' | 'desc', filterId?: string, filterName?: string, filterTag?: string, filterContactName?: string, filterContactPhoneFirst?: string, filterContactLabelFirst?: string, filterContactPhoneSecond?: string, filterContactLabelSecond?: string, filterContactEmail?: string, filterStreet?: string, filterStreetNumber?: string, filterZip?: string, filterCity?: string, search?: string, options?: any) {
        return AccountApiFp(this.configuration).searchAccountRequest(name, tag, insurerId, isTest, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, orderId, orderActive, orderCreatedAt, orderName, orderType, orderTag, orderIsTest, orderContactName, orderContactPhoneFirst, orderContactLabelFirst, orderContactPhoneSecond, orderContactLabelSecond, orderContactEmail, orderContactWorkTime, orderStreet, orderStreetNumber, orderZip, orderCity, filterId, filterName, filterTag, filterContactName, filterContactPhoneFirst, filterContactLabelFirst, filterContactPhoneSecond, filterContactLabelSecond, filterContactEmail, filterStreet, filterStreetNumber, filterZip, filterCity, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountId 
     * @param {boolean} [forInsurerList] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {boolean} [noPagination] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public searchInsurerForAccountRequest(accountId: string, forInsurerList?: boolean, limit?: number, offset?: number, noPagination?: boolean, options?: any) {
        return AccountApiFp(this.configuration).searchInsurerForAccountRequest(accountId, forInsurerList, limit, offset, noPagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} industryId 
     * @param {string} accountId 
     * @param {Array<string>} [productIds] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {boolean} [noPagination] 
     * @param {boolean} [includeDeleted] 
     * @param {boolean} [includeLocked] 
     * @param {boolean} [includeInactive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public searchProductForAccountAndIndustryRequest(industryId: string, accountId: string, productIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any) {
        return AccountApiFp(this.configuration).searchProductForAccountAndIndustryRequest(industryId, accountId, productIds, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} professionId 
     * @param {string} accountId 
     * @param {Array<string>} [productIds] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {boolean} [noPagination] 
     * @param {boolean} [includeDeleted] 
     * @param {boolean} [includeLocked] 
     * @param {boolean} [includeInactive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public searchProductForAccountAndProfessionRequest(professionId: string, accountId: string, productIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any) {
        return AccountApiFp(this.configuration).searchProductForAccountAndProfessionRequest(professionId, accountId, productIds, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountId 
     * @param {Array<string>} [productIds] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {boolean} [noPagination] 
     * @param {boolean} [includeDeleted] 
     * @param {boolean} [includeLocked] 
     * @param {boolean} [includeInactive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public searchProductForAccountRequest(accountId: string, productIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any) {
        return AccountApiFp(this.configuration).searchProductForAccountRequest(accountId, productIds, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CityApi - axios parameter creator
 * @export
 */
export const CityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} city 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byCityRequest: async (city: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'city' is not null or undefined
            assertParamExists('byCityRequest', 'city', city)
            const localVarPath = `/cities/{city}`
                .replace(`{${"city"}}`, encodeURIComponent(String(city)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CityApi - functional programming interface
 * @export
 */
export const CityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} city 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async byCityRequest(city: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Postcode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.byCityRequest(city, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CityApi - factory interface
 * @export
 */
export const CityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CityApiFp(configuration)
    return {
        /**
         * 
         * @param {string} city 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byCityRequest(city: string, options?: any): AxiosPromise<Array<Postcode>> {
            return localVarFp.byCityRequest(city, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CityApi - object-oriented interface
 * @export
 * @class CityApi
 * @extends {BaseAPI}
 */
export class CityApi extends BaseAPI {
    /**
     * 
     * @param {string} city 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApi
     */
    public byCityRequest(city: string, options?: any) {
        return CityApiFp(this.configuration).byCityRequest(city, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IndustryApi - axios parameter creator
 * @export
 */
export const IndustryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} industryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDIndustryRequest: async (industryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'industryId' is not null or undefined
            assertParamExists('byIDIndustryRequest', 'industryId', industryId)
            const localVarPath = `/industries/{industryId}`
                .replace(`{${"industryId"}}`, encodeURIComponent(String(industryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIndustryRequest: async (limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/industries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (noPagination !== undefined) {
                localVarQueryParameter['noPagination'] = noPagination;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (includeLocked !== undefined) {
                localVarQueryParameter['includeLocked'] = includeLocked;
            }

            if (includeInactive !== undefined) {
                localVarQueryParameter['includeInactive'] = includeInactive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} industryId 
         * @param {Array<string>} [productIds] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProductForIndustryRequest: async (industryId: string, productIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'industryId' is not null or undefined
            assertParamExists('searchProductForIndustryRequest', 'industryId', industryId)
            const localVarPath = `/industries/{industryId}/products`
                .replace(`{${"industryId"}}`, encodeURIComponent(String(industryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (productIds) {
                localVarQueryParameter['productIds'] = productIds.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (noPagination !== undefined) {
                localVarQueryParameter['noPagination'] = noPagination;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (includeLocked !== undefined) {
                localVarQueryParameter['includeLocked'] = includeLocked;
            }

            if (includeInactive !== undefined) {
                localVarQueryParameter['includeInactive'] = includeInactive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IndustryApi - functional programming interface
 * @export
 */
export const IndustryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IndustryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} industryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async byIDIndustryRequest(industryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Industry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.byIDIndustryRequest(industryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchIndustryRequest(limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndustryList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchIndustryRequest(limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} industryId 
         * @param {Array<string>} [productIds] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchProductForIndustryRequest(industryId: string, productIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchProductForIndustryRequest(industryId, productIds, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IndustryApi - factory interface
 * @export
 */
export const IndustryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IndustryApiFp(configuration)
    return {
        /**
         * 
         * @param {string} industryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDIndustryRequest(industryId: string, options?: any): AxiosPromise<Industry> {
            return localVarFp.byIDIndustryRequest(industryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIndustryRequest(limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): AxiosPromise<IndustryList> {
            return localVarFp.searchIndustryRequest(limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} industryId 
         * @param {Array<string>} [productIds] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProductForIndustryRequest(industryId: string, productIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): AxiosPromise<ProductList> {
            return localVarFp.searchProductForIndustryRequest(industryId, productIds, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IndustryApi - object-oriented interface
 * @export
 * @class IndustryApi
 * @extends {BaseAPI}
 */
export class IndustryApi extends BaseAPI {
    /**
     * 
     * @param {string} industryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndustryApi
     */
    public byIDIndustryRequest(industryId: string, options?: any) {
        return IndustryApiFp(this.configuration).byIDIndustryRequest(industryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {boolean} [noPagination] 
     * @param {boolean} [includeDeleted] 
     * @param {boolean} [includeLocked] 
     * @param {boolean} [includeInactive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndustryApi
     */
    public searchIndustryRequest(limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any) {
        return IndustryApiFp(this.configuration).searchIndustryRequest(limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} industryId 
     * @param {Array<string>} [productIds] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {boolean} [noPagination] 
     * @param {boolean} [includeDeleted] 
     * @param {boolean} [includeLocked] 
     * @param {boolean} [includeInactive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndustryApi
     */
    public searchProductForIndustryRequest(industryId: string, productIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any) {
        return IndustryApiFp(this.configuration).searchProductForIndustryRequest(industryId, productIds, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InsurerApi - axios parameter creator
 * @export
 */
export const InsurerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} insurerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDInsurerRequest: async (insurerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'insurerId' is not null or undefined
            assertParamExists('byIDInsurerRequest', 'insurerId', insurerId)
            const localVarPath = `/insurers/{insurerId}`
                .replace(`{${"insurerId"}}`, encodeURIComponent(String(insurerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [insurerIds] 
         * @param {string} [name] 
         * @param {string} [tag] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInsurerRequest: async (insurerIds?: Array<string>, name?: string, tag?: string, limit?: number, offset?: number, noPagination?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/insurers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (insurerIds) {
                localVarQueryParameter['insurerIds'] = insurerIds.join(COLLECTION_FORMATS.csv);
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (noPagination !== undefined) {
                localVarQueryParameter['noPagination'] = noPagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InsurerApi - functional programming interface
 * @export
 */
export const InsurerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InsurerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} insurerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async byIDInsurerRequest(insurerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Insurer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.byIDInsurerRequest(insurerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [insurerIds] 
         * @param {string} [name] 
         * @param {string} [tag] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchInsurerRequest(insurerIds?: Array<string>, name?: string, tag?: string, limit?: number, offset?: number, noPagination?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsurerList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchInsurerRequest(insurerIds, name, tag, limit, offset, noPagination, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InsurerApi - factory interface
 * @export
 */
export const InsurerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InsurerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} insurerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDInsurerRequest(insurerId: string, options?: any): AxiosPromise<Insurer> {
            return localVarFp.byIDInsurerRequest(insurerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [insurerIds] 
         * @param {string} [name] 
         * @param {string} [tag] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInsurerRequest(insurerIds?: Array<string>, name?: string, tag?: string, limit?: number, offset?: number, noPagination?: boolean, options?: any): AxiosPromise<InsurerList> {
            return localVarFp.searchInsurerRequest(insurerIds, name, tag, limit, offset, noPagination, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InsurerApi - object-oriented interface
 * @export
 * @class InsurerApi
 * @extends {BaseAPI}
 */
export class InsurerApi extends BaseAPI {
    /**
     * 
     * @param {string} insurerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurerApi
     */
    public byIDInsurerRequest(insurerId: string, options?: any) {
        return InsurerApiFp(this.configuration).byIDInsurerRequest(insurerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [insurerIds] 
     * @param {string} [name] 
     * @param {string} [tag] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {boolean} [noPagination] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurerApi
     */
    public searchInsurerRequest(insurerIds?: Array<string>, name?: string, tag?: string, limit?: number, offset?: number, noPagination?: boolean, options?: any) {
        return InsurerApiFp(this.configuration).searchInsurerRequest(insurerIds, name, tag, limit, offset, noPagination, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MultipleAgencyNumberApi - axios parameter creator
 * @export
 */
export const MultipleAgencyNumberApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} agencyIds 
         * @param {number} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        multipleAgencyNumberRequest: async (agencyIds: Array<string>, type?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agencyIds' is not null or undefined
            assertParamExists('multipleAgencyNumberRequest', 'agencyIds', agencyIds)
            const localVarPath = `/multipleAgencyNumbers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (agencyIds) {
                localVarQueryParameter['agencyIds'] = agencyIds.join(COLLECTION_FORMATS.csv);
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MultipleAgencyNumberApi - functional programming interface
 * @export
 */
export const MultipleAgencyNumberApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MultipleAgencyNumberApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<string>} agencyIds 
         * @param {number} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async multipleAgencyNumberRequest(agencyIds: Array<string>, type?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultipleAgencyNumbersList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.multipleAgencyNumberRequest(agencyIds, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MultipleAgencyNumberApi - factory interface
 * @export
 */
export const MultipleAgencyNumberApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MultipleAgencyNumberApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<string>} agencyIds 
         * @param {number} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        multipleAgencyNumberRequest(agencyIds: Array<string>, type?: number, options?: any): AxiosPromise<MultipleAgencyNumbersList> {
            return localVarFp.multipleAgencyNumberRequest(agencyIds, type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MultipleAgencyNumberApi - object-oriented interface
 * @export
 * @class MultipleAgencyNumberApi
 * @extends {BaseAPI}
 */
export class MultipleAgencyNumberApi extends BaseAPI {
    /**
     * 
     * @param {Array<string>} agencyIds 
     * @param {number} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultipleAgencyNumberApi
     */
    public multipleAgencyNumberRequest(agencyIds: Array<string>, type?: number, options?: any) {
        return MultipleAgencyNumberApiFp(this.configuration).multipleAgencyNumberRequest(agencyIds, type, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PostcodeApi - axios parameter creator
 * @export
 */
export const PostcodeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} postcode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byPostcodeRequest: async (postcode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postcode' is not null or undefined
            assertParamExists('byPostcodeRequest', 'postcode', postcode)
            const localVarPath = `/postcodes/{postcode}`
                .replace(`{${"postcode"}}`, encodeURIComponent(String(postcode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyPostcode} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyPostcodeAndCityRequest: async (body?: VerifyPostcode, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/postcodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PostcodeApi - functional programming interface
 * @export
 */
export const PostcodeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PostcodeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} postcode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async byPostcodeRequest(postcode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Postcode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.byPostcodeRequest(postcode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyPostcode} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyPostcodeAndCityRequest(body?: VerifyPostcode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyPostcodeAndCityRequest(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PostcodeApi - factory interface
 * @export
 */
export const PostcodeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PostcodeApiFp(configuration)
    return {
        /**
         * 
         * @param {string} postcode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byPostcodeRequest(postcode: string, options?: any): AxiosPromise<Array<Postcode>> {
            return localVarFp.byPostcodeRequest(postcode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyPostcode} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyPostcodeAndCityRequest(body?: VerifyPostcode, options?: any): AxiosPromise<void> {
            return localVarFp.verifyPostcodeAndCityRequest(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PostcodeApi - object-oriented interface
 * @export
 * @class PostcodeApi
 * @extends {BaseAPI}
 */
export class PostcodeApi extends BaseAPI {
    /**
     * 
     * @param {string} postcode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostcodeApi
     */
    public byPostcodeRequest(postcode: string, options?: any) {
        return PostcodeApiFp(this.configuration).byPostcodeRequest(postcode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyPostcode} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostcodeApi
     */
    public verifyPostcodeAndCityRequest(body?: VerifyPostcode, options?: any) {
        return PostcodeApiFp(this.configuration).verifyPostcodeAndCityRequest(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDProductRequest: async (productId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('byIDProductRequest', 'productId', productId)
            const localVarPath = `/products/id/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byTagProductRequest: async (productTag: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productTag' is not null or undefined
            assertParamExists('byTagProductRequest', 'productTag', productTag)
            const localVarPath = `/products/tag/{productTag}`
                .replace(`{${"productTag"}}`, encodeURIComponent(String(productTag)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [productIds] 
         * @param {Array<string>} [productIdsToExclude] 
         * @param {string} [insurerId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProductRequest: async (productIds?: Array<string>, productIdsToExclude?: Array<string>, insurerId?: string, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (productIds) {
                localVarQueryParameter['productIds'] = productIds.join(COLLECTION_FORMATS.csv);
            }

            if (productIdsToExclude) {
                localVarQueryParameter['productIdsToExclude'] = productIdsToExclude.join(COLLECTION_FORMATS.csv);
            }

            if (insurerId !== undefined) {
                localVarQueryParameter['insurerId'] = insurerId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (noPagination !== undefined) {
                localVarQueryParameter['noPagination'] = noPagination;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (includeLocked !== undefined) {
                localVarQueryParameter['includeLocked'] = includeLocked;
            }

            if (includeInactive !== undefined) {
                localVarQueryParameter['includeInactive'] = includeInactive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async byIDProductRequest(productId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.byIDProductRequest(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async byTagProductRequest(productTag: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.byTagProductRequest(productTag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [productIds] 
         * @param {Array<string>} [productIdsToExclude] 
         * @param {string} [insurerId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchProductRequest(productIds?: Array<string>, productIdsToExclude?: Array<string>, insurerId?: string, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchProductRequest(productIds, productIdsToExclude, insurerId, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDProductRequest(productId: string, options?: any): AxiosPromise<Product> {
            return localVarFp.byIDProductRequest(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byTagProductRequest(productTag: string, options?: any): AxiosPromise<Product> {
            return localVarFp.byTagProductRequest(productTag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [productIds] 
         * @param {Array<string>} [productIdsToExclude] 
         * @param {string} [insurerId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProductRequest(productIds?: Array<string>, productIdsToExclude?: Array<string>, insurerId?: string, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): AxiosPromise<ProductList> {
            return localVarFp.searchProductRequest(productIds, productIdsToExclude, insurerId, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public byIDProductRequest(productId: string, options?: any) {
        return ProductApiFp(this.configuration).byIDProductRequest(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productTag 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public byTagProductRequest(productTag: string, options?: any) {
        return ProductApiFp(this.configuration).byTagProductRequest(productTag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [productIds] 
     * @param {Array<string>} [productIdsToExclude] 
     * @param {string} [insurerId] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {boolean} [noPagination] 
     * @param {boolean} [includeDeleted] 
     * @param {boolean} [includeLocked] 
     * @param {boolean} [includeInactive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public searchProductRequest(productIds?: Array<string>, productIdsToExclude?: Array<string>, insurerId?: string, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any) {
        return ProductApiFp(this.configuration).searchProductRequest(productIds, productIdsToExclude, insurerId, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProfessionApi - axios parameter creator
 * @export
 */
export const ProfessionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} professionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDProfessionRequest: async (professionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'professionId' is not null or undefined
            assertParamExists('byIDProfessionRequest', 'professionId', professionId)
            const localVarPath = `/professions/{professionId}`
                .replace(`{${"professionId"}}`, encodeURIComponent(String(professionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} professionId 
         * @param {Array<string>} [productIds] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProductForProfessionRequest: async (professionId: string, productIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'professionId' is not null or undefined
            assertParamExists('searchProductForProfessionRequest', 'professionId', professionId)
            const localVarPath = `/professions/{professionId}/products`
                .replace(`{${"professionId"}}`, encodeURIComponent(String(professionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (productIds) {
                localVarQueryParameter['productIds'] = productIds.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (noPagination !== undefined) {
                localVarQueryParameter['noPagination'] = noPagination;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (includeLocked !== undefined) {
                localVarQueryParameter['includeLocked'] = includeLocked;
            }

            if (includeInactive !== undefined) {
                localVarQueryParameter['includeInactive'] = includeInactive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} professionId 
         * @param {string} [alias] 
         * @param {string} [hash] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProfessionAliasForProfessionRequest: async (professionId: string, alias?: string, hash?: string, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'professionId' is not null or undefined
            assertParamExists('searchProfessionAliasForProfessionRequest', 'professionId', professionId)
            const localVarPath = `/professions/{professionId}/professionAliases`
                .replace(`{${"professionId"}}`, encodeURIComponent(String(professionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (alias !== undefined) {
                localVarQueryParameter['alias'] = alias;
            }

            if (hash !== undefined) {
                localVarQueryParameter['hash'] = hash;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (noPagination !== undefined) {
                localVarQueryParameter['noPagination'] = noPagination;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (includeLocked !== undefined) {
                localVarQueryParameter['includeLocked'] = includeLocked;
            }

            if (includeInactive !== undefined) {
                localVarQueryParameter['includeInactive'] = includeInactive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {Array<string>} [professionIds] 
         * @param {Array<string>} [professionAliasIds] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProfessionRequest: async (name?: string, professionIds?: Array<string>, professionAliasIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/professions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (professionIds) {
                localVarQueryParameter['professionIds'] = professionIds.join(COLLECTION_FORMATS.csv);
            }

            if (professionAliasIds) {
                localVarQueryParameter['professionAliasIds'] = professionAliasIds.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (noPagination !== undefined) {
                localVarQueryParameter['noPagination'] = noPagination;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (includeLocked !== undefined) {
                localVarQueryParameter['includeLocked'] = includeLocked;
            }

            if (includeInactive !== undefined) {
                localVarQueryParameter['includeInactive'] = includeInactive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfessionApi - functional programming interface
 * @export
 */
export const ProfessionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfessionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} professionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async byIDProfessionRequest(professionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profession>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.byIDProfessionRequest(professionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} professionId 
         * @param {Array<string>} [productIds] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchProductForProfessionRequest(professionId: string, productIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchProductForProfessionRequest(professionId, productIds, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} professionId 
         * @param {string} [alias] 
         * @param {string} [hash] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchProfessionAliasForProfessionRequest(professionId: string, alias?: string, hash?: string, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfessionAliasList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchProfessionAliasForProfessionRequest(professionId, alias, hash, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {Array<string>} [professionIds] 
         * @param {Array<string>} [professionAliasIds] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchProfessionRequest(name?: string, professionIds?: Array<string>, professionAliasIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfessionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchProfessionRequest(name, professionIds, professionAliasIds, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfessionApi - factory interface
 * @export
 */
export const ProfessionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfessionApiFp(configuration)
    return {
        /**
         * 
         * @param {string} professionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDProfessionRequest(professionId: string, options?: any): AxiosPromise<Profession> {
            return localVarFp.byIDProfessionRequest(professionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} professionId 
         * @param {Array<string>} [productIds] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProductForProfessionRequest(professionId: string, productIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): AxiosPromise<ProductList> {
            return localVarFp.searchProductForProfessionRequest(professionId, productIds, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} professionId 
         * @param {string} [alias] 
         * @param {string} [hash] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProfessionAliasForProfessionRequest(professionId: string, alias?: string, hash?: string, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): AxiosPromise<ProfessionAliasList> {
            return localVarFp.searchProfessionAliasForProfessionRequest(professionId, alias, hash, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {Array<string>} [professionIds] 
         * @param {Array<string>} [professionAliasIds] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProfessionRequest(name?: string, professionIds?: Array<string>, professionAliasIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): AxiosPromise<ProfessionList> {
            return localVarFp.searchProfessionRequest(name, professionIds, professionAliasIds, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfessionApi - object-oriented interface
 * @export
 * @class ProfessionApi
 * @extends {BaseAPI}
 */
export class ProfessionApi extends BaseAPI {
    /**
     * 
     * @param {string} professionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessionApi
     */
    public byIDProfessionRequest(professionId: string, options?: any) {
        return ProfessionApiFp(this.configuration).byIDProfessionRequest(professionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} professionId 
     * @param {Array<string>} [productIds] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {boolean} [noPagination] 
     * @param {boolean} [includeDeleted] 
     * @param {boolean} [includeLocked] 
     * @param {boolean} [includeInactive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessionApi
     */
    public searchProductForProfessionRequest(professionId: string, productIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any) {
        return ProfessionApiFp(this.configuration).searchProductForProfessionRequest(professionId, productIds, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} professionId 
     * @param {string} [alias] 
     * @param {string} [hash] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {boolean} [noPagination] 
     * @param {boolean} [includeDeleted] 
     * @param {boolean} [includeLocked] 
     * @param {boolean} [includeInactive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessionApi
     */
    public searchProfessionAliasForProfessionRequest(professionId: string, alias?: string, hash?: string, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any) {
        return ProfessionApiFp(this.configuration).searchProfessionAliasForProfessionRequest(professionId, alias, hash, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {Array<string>} [professionIds] 
     * @param {Array<string>} [professionAliasIds] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {boolean} [noPagination] 
     * @param {boolean} [includeDeleted] 
     * @param {boolean} [includeLocked] 
     * @param {boolean} [includeInactive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessionApi
     */
    public searchProfessionRequest(name?: string, professionIds?: Array<string>, professionAliasIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any) {
        return ProfessionApiFp(this.configuration).searchProfessionRequest(name, professionIds, professionAliasIds, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProfessionAliasApi - axios parameter creator
 * @export
 */
export const ProfessionAliasApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} professionAliasId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDProfessionAliasRequest: async (professionAliasId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'professionAliasId' is not null or undefined
            assertParamExists('byIDProfessionAliasRequest', 'professionAliasId', professionAliasId)
            const localVarPath = `/professionAliases/{professionAliasId}`
                .replace(`{${"professionAliasId"}}`, encodeURIComponent(String(professionAliasId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [alias] 
         * @param {string} [hash] 
         * @param {Array<string>} [professionAliasIds] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProfessionAliasRequest: async (alias?: string, hash?: string, professionAliasIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/professionAliases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (alias !== undefined) {
                localVarQueryParameter['alias'] = alias;
            }

            if (hash !== undefined) {
                localVarQueryParameter['hash'] = hash;
            }

            if (professionAliasIds) {
                localVarQueryParameter['professionAliasIds'] = professionAliasIds.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (noPagination !== undefined) {
                localVarQueryParameter['noPagination'] = noPagination;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (includeLocked !== undefined) {
                localVarQueryParameter['includeLocked'] = includeLocked;
            }

            if (includeInactive !== undefined) {
                localVarQueryParameter['includeInactive'] = includeInactive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfessionAliasApi - functional programming interface
 * @export
 */
export const ProfessionAliasApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfessionAliasApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} professionAliasId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async byIDProfessionAliasRequest(professionAliasId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfessionAlias>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.byIDProfessionAliasRequest(professionAliasId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [alias] 
         * @param {string} [hash] 
         * @param {Array<string>} [professionAliasIds] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchProfessionAliasRequest(alias?: string, hash?: string, professionAliasIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfessionAliasList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchProfessionAliasRequest(alias, hash, professionAliasIds, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfessionAliasApi - factory interface
 * @export
 */
export const ProfessionAliasApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfessionAliasApiFp(configuration)
    return {
        /**
         * 
         * @param {string} professionAliasId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDProfessionAliasRequest(professionAliasId: string, options?: any): AxiosPromise<ProfessionAlias> {
            return localVarFp.byIDProfessionAliasRequest(professionAliasId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [alias] 
         * @param {string} [hash] 
         * @param {Array<string>} [professionAliasIds] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProfessionAliasRequest(alias?: string, hash?: string, professionAliasIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): AxiosPromise<ProfessionAliasList> {
            return localVarFp.searchProfessionAliasRequest(alias, hash, professionAliasIds, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfessionAliasApi - object-oriented interface
 * @export
 * @class ProfessionAliasApi
 * @extends {BaseAPI}
 */
export class ProfessionAliasApi extends BaseAPI {
    /**
     * 
     * @param {string} professionAliasId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessionAliasApi
     */
    public byIDProfessionAliasRequest(professionAliasId: string, options?: any) {
        return ProfessionAliasApiFp(this.configuration).byIDProfessionAliasRequest(professionAliasId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [alias] 
     * @param {string} [hash] 
     * @param {Array<string>} [professionAliasIds] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {boolean} [noPagination] 
     * @param {boolean} [includeDeleted] 
     * @param {boolean} [includeLocked] 
     * @param {boolean} [includeInactive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessionAliasApi
     */
    public searchProfessionAliasRequest(alias?: string, hash?: string, professionAliasIds?: Array<string>, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any) {
        return ProfessionAliasApiFp(this.configuration).searchProfessionAliasRequest(alias, hash, professionAliasIds, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingApi - axios parameter creator
 * @export
 */
export const SettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} [settingIds] 
         * @param {Array<number>} [types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSettingRequest: async (settingIds?: Array<string>, types?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (settingIds) {
                localVarQueryParameter['settingIds'] = settingIds.join(COLLECTION_FORMATS.csv);
            }

            if (types) {
                localVarQueryParameter['types'] = types.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingApi - functional programming interface
 * @export
 */
export const SettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<string>} [settingIds] 
         * @param {Array<number>} [types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSettingRequest(settingIds?: Array<string>, types?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSettingList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSettingRequest(settingIds, types, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingApi - factory interface
 * @export
 */
export const SettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<string>} [settingIds] 
         * @param {Array<number>} [types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSettingRequest(settingIds?: Array<string>, types?: Array<number>, options?: any): AxiosPromise<AdminSettingList> {
            return localVarFp.adminSettingRequest(settingIds, types, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingApi - object-oriented interface
 * @export
 * @class SettingApi
 * @extends {BaseAPI}
 */
export class SettingApi extends BaseAPI {
    /**
     * 
     * @param {Array<string>} [settingIds] 
     * @param {Array<number>} [types] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public adminSettingRequest(settingIds?: Array<string>, types?: Array<number>, options?: any) {
        return SettingApiFp(this.configuration).adminSettingRequest(settingIds, types, options).then((request) => request(this.axios, this.basePath));
    }
}


