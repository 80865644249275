import React from 'react';

import { Route } from 'react-router-dom';

type PropsLayout = {
  // TODO: Check can this "any" be solved on a better way
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FunctionComponent<any> | React.ComponentClass<any>;
  exact?: boolean;
  path?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  layout: React.FunctionComponent<any>;
  className?: string;
  headerClassName?: string;
  id?: string;
};

const RouteWithLayout: React.FC<PropsLayout> = (props) => {
  const { layout: Layout, component: Component, className, headerClassName, id, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout pageClassName={className} headerClassName={headerClassName} id={id}>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

export default RouteWithLayout;
