import React from 'react';

import { Container } from 'react-bootstrap';

import { BootstrapWrapper } from '@shared';

type Props = {
  children: React.ReactNode;
};

const ErrorPage: React.FC<Props> = (props) => {
  const { children } = props;

  return (
    <BootstrapWrapper>
      <Container className="bg-white">
        <main>{children}</main>
      </Container>
    </BootstrapWrapper>
  );
};

export default ErrorPage;
