import React from 'react';

import {
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  Tabs,
  Tab,
} from '@developers/graphite/carbon-components';

import { Loader, TariffDetailsDocuments } from '@shared';
import { OfferDetailDto } from '@api/offerOrchestrator';
import { DefinedIcon } from '@pages/Result/Components';

type Props = {
  data?: OfferDetailDto;
  isLoading: boolean;
};
// TODO: when Result is rewritten to react-query, call offerServices.useGetOfferDetails here and not in parent wrapper

const TariffDetails: React.FC<Props> = ({ data, isLoading }) => {
  if (isLoading) {
    return <Loader />;
  }

  if (!data) return null;
  return (
    <div className="tariff-details">
      <Tabs type="container">
        {data.featureCategoryGroups.map((group, index) => {
          // TODO It will be changed, waiting for BE to add new property
          const featureCategoryGroupsLength = data.featureCategoryGroups.length;
          return (
            <Tab id={group.id} key={group.id} label={group.name}>
              {group.featureCategories.map((category) => {
                if (category.isExcludedFromList) return <></>;
                return (
                  <StructuredListWrapper key={category.name}>
                    <StructuredListHead>
                      <StructuredListRow head tabIndex={0}>
                        <StructuredListCell head>{category.name}</StructuredListCell>
                        <StructuredListCell head />
                      </StructuredListRow>
                    </StructuredListHead>

                    {category.features.map((categoryItem) => {
                      if (categoryItem.isExcludedFromList) return <></>;
                      return (
                        <StructuredListRow key={categoryItem.id}>
                          <StructuredListCell>{categoryItem.name}</StructuredListCell>
                          <StructuredListCell>
                            <div className="tariff-details__value">
                              <DefinedIcon className="me-2" iconType={categoryItem.iconType} />
                              {/* eslint-disable-next-line react/no-danger */}
                              <div dangerouslySetInnerHTML={{ __html: categoryItem.value }} />
                            </div>
                          </StructuredListCell>
                        </StructuredListRow>
                      );
                    })}
                  </StructuredListWrapper>
                );
              })}
              {data.documents && index + 1 === featureCategoryGroupsLength && (
                <StructuredListWrapper>
                  <TariffDetailsDocuments documentsCollection={data.documents} />
                </StructuredListWrapper>
              )}
            </Tab>
          );
        })}
      </Tabs>
    </div>
  );
};

export default TariffDetails;
