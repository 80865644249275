import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';

import { ConsultationApi, ProfessionAliasList } from '@api/consultation';
import { ApiQuery } from '@enums';
import { helpers } from '@helpers';

const consultationInstance = new ConsultationApi(
  undefined,
  helpers.isWidget
    ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/consultation/v1`
    : '/api/consultation/v1'
);

const useProfessionAliases = (
  consultationId: string,
  options: UseQueryOptions<ProfessionAliasList, AxiosError> = {}
): UseQueryResult<ProfessionAliasList, AxiosError> =>
  useQuery(
    [ApiQuery.ProfessionAliases, consultationId],
    () =>
      consultationInstance
        .professionAliasesByConsultationIDRequest(consultationId)
        .then((res) => res.data),
    { keepPreviousData: true, refetchOnMount: false, ...options }
  );

const professionAliases = {
  useProfessionAliases,
};

export default professionAliases;
