/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  useQuery,
  UseQueryResult,
  UseQueryOptions,
  useMutation,
  UseMutationResult,
} from 'react-query';
import { AxiosError } from 'axios';

import { helpers } from '@helpers';
import {
  ConsultationApi,
  ConsultationList,
  QuestionAnswerSet,
  Inquiry,
  SaveInquiry,
  Consultation,
  Customer,
  SaveCustomer,
  SelectedOffer,
} from '@api/consultation';
import { ApiQuery } from '@enums';
import { ConsultationsPagination } from '@typings/consultations';

const consultationInstance = new ConsultationApi(
  undefined,
  helpers.isWidget
    ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/consultation/v1`
    : '/api/consultation/v1'
);

const useConsultationList = (
  {
    hash,
    limit,
    offset,
    noPagination,
    includeDeleted,
    includeLocked,
    includeInactive,
  }: ConsultationsPagination,
  options: UseQueryOptions<ConsultationList, AxiosError> = {}
): UseQueryResult<ConsultationList, AxiosError> =>
  useQuery(
    [
      ApiQuery.Consultations,
      hash,
      limit,
      offset,
      noPagination,
      includeDeleted,
      includeLocked,
      includeInactive,
    ],
    () =>
      consultationInstance
        .searchConsultationRequest(
          hash,
          limit,
          offset,
          noPagination,
          includeDeleted,
          includeLocked,
          includeInactive
        )
        .then((res) => res.data),
    { keepPreviousData: true, refetchOnMount: false, ...options }
  );

const useConsultationById = (
  consultationId: string,
  options: UseQueryOptions<Consultation, AxiosError> = {}
): UseQueryResult<Consultation, AxiosError> =>
  useQuery(
    [ApiQuery.ConsultationById, consultationId],
    () => consultationInstance.byIDConsultationRequest(consultationId).then((res) => res.data),
    { keepPreviousData: true, refetchOnMount: false, ...options }
  );

const useQuestionAnswerSets = (
  inquiryGroupHash: string,
  options: UseQueryOptions<QuestionAnswerSet[], AxiosError> = {}
): UseQueryResult<QuestionAnswerSet[], AxiosError> =>
  useQuery(
    [ApiQuery.QuestionAnswerSets, inquiryGroupHash],
    () =>
      consultationInstance
        .searchConsultationRequest(inquiryGroupHash)
        .then((res) =>
          consultationInstance
            .getAnswerSetRequest(res.data._embedded.consultations[0].id)
            .then((resp) => resp.data)
        ),
    { enabled: !!inquiryGroupHash, refetchOnMount: false, ...options }
  );

const useCreateInquiry = (): UseMutationResult<
  Inquiry[],
  AxiosError,
  { consultationId: string; body: SaveInquiry; options?: any }
> =>
  useMutation(
    ({ consultationId, body, options }) =>
      consultationInstance
        .createInquiryByConsultationIDRequest(consultationId, body, options)
        .then((res) => res.data),
    { mutationKey: ApiQuery.CreateInquiry }
  );

const useCreateCustomer = (): UseMutationResult<
  Customer,
  AxiosError,
  { consultationId: string; body: SaveCustomer }
> =>
  useMutation(({ consultationId, body }) =>
    consultationInstance
      .updateCustomerByConsultationIDRequest(consultationId, body)
      .then((res) => res.data)
  );

const useConsultationGetSelectedOffers = (
  consultationId: string,
  options: UseQueryOptions<Array<SelectedOffer>, AxiosError> = {}
): UseQueryResult<Array<SelectedOffer>, AxiosError> =>
  useQuery(
    [ApiQuery.SelectedOffers, 'consultation', consultationId],
    () =>
      consultationInstance
        .getSelectedOffersByConsultationIDRequest(consultationId)
        .then((res) => res.data),
    { keepPreviousData: false, refetchOnMount: true, retry: false, ...options }
  );

const consultations = {
  useConsultationList,
  useConsultationById,
  useQuestionAnswerSets,
  useCreateInquiry,
  useCreateCustomer,
  useConsultationGetSelectedOffers,
};

export default consultations;
