import {
  ManageProtection16,
  ThumbsUp16,
  WarningAlt16,
  Warning16,
  DocumentAdd16,
  CarbonIconType,
} from '@developers/graphite/carbon-icons';

import { CategoryRank } from '@enums';

type CategoryRankStyle = {
  [key: number]: { textColor: string; bcgColor: string; icon: CarbonIconType };
};

export const productCategoryStyleMapping: CategoryRankStyle = {
  [CategoryRank.One]: {
    textColor: '#525252',
    bcgColor: '#F4F4F4',
    icon: ManageProtection16,
  },
  [CategoryRank.Two]: {
    textColor: '#DA1E28',
    bcgColor: '#FFF1F1',
    icon: WarningAlt16,
  },
  [CategoryRank.Three]: { textColor: '#B98806', bcgColor: '#FFF8E1', icon: Warning16 },
  [CategoryRank.Four]: { textColor: '#24A148', bcgColor: '#DEFBE6', icon: ThumbsUp16 },
  [CategoryRank.Five]: { textColor: '#0043CE', bcgColor: '#EDF5FF', icon: DocumentAdd16 },
  [CategoryRank.Six]: { textColor: '#0043CE', bcgColor: '#EDF5FF', icon: DocumentAdd16 },
};

export default productCategoryStyleMapping;
