/* tslint:disable */
/* eslint-disable */
/**
 * Booking API
 * API to handle everything related to bookings
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AgencyNumberDto
 */
export interface AgencyNumberDto {
    /**
     * 
     * @type {boolean}
     * @memberof AgencyNumberDto
     */
    enableEditing: boolean;
    /**
     * 
     * @type {Array<AgencyNumbersDto>}
     * @memberof AgencyNumberDto
     */
    agencyNumbers: Array<AgencyNumbersDto>;
}
/**
 * 
 * @export
 * @interface AgencyNumberItemDto
 */
export interface AgencyNumberItemDto {
    /**
     * 
     * @type {string}
     * @memberof AgencyNumberItemDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyNumberItemDto
     */
    value: string;
}
/**
 * 
 * @export
 * @interface AgencyNumbersDto
 */
export interface AgencyNumbersDto {
    /**
     * 
     * @type {string}
     * @memberof AgencyNumbersDto
     */
    productName: string;
    /**
     * 
     * @type {Array<AgencyNumberItemDto>}
     * @memberof AgencyNumbersDto
     */
    data: Array<AgencyNumberItemDto>;
}
/**
 * 
 * @export
 * @interface BankDetailsInfoDto
 */
export interface BankDetailsInfoDto {
    /**
     * 
     * @type {string}
     * @memberof BankDetailsInfoDto
     */
    title: string;
    /**
     * 
     * @type {Array<PaymentItemDto>}
     * @memberof BankDetailsInfoDto
     */
    data: Array<PaymentItemDto>;
}
/**
 * 
 * @export
 * @interface BookingSetResponseDto
 */
export interface BookingSetResponseDto {
    /**
     * 
     * @type {string}
     * @memberof BookingSetResponseDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BookingSetResponseDto
     */
    activeStepSlug: string;
    /**
     * 
     * @type {string}
     * @memberof BookingSetResponseDto
     */
    assignedBrokerId: string;
    /**
     * 
     * @type {string}
     * @memberof BookingSetResponseDto
     */
    updatedAt: string;
    /**
     * 
     * @type {BookingSetResponseDtoData}
     * @memberof BookingSetResponseDto
     */
    data: BookingSetResponseDtoData;
    /**
     * 
     * @type {Array<BookingSetResponseDtoSettings>}
     * @memberof BookingSetResponseDto
     */
    settings: Array<BookingSetResponseDtoSettings>;
    /**
     * 
     * @type {StepMetadataDto}
     * @memberof BookingSetResponseDto
     */
    metadata: StepMetadataDto;
}
/**
 * 
 * @export
 * @interface BookingSetResponseDtoData
 */
export interface BookingSetResponseDtoData {
    /**
     * 
     * @type {FormDto}
     * @memberof BookingSetResponseDtoData
     */
    form?: FormDto;
    /**
     * 
     * @type {Array<object>}
     * @memberof BookingSetResponseDtoData
     */
    additional?: Array<object>;
}
/**
 * 
 * @export
 * @interface BookingSetResponseDtoSettings
 */
export interface BookingSetResponseDtoSettings {
    /**
     * 
     * @type {string}
     * @memberof BookingSetResponseDtoSettings
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BookingSetResponseDtoSettings
     */
    value?: boolean;
}
/**
 * 
 * @export
 * @interface BrokerMismatchResponseDto
 */
export interface BrokerMismatchResponseDto {
    /**
     * HTTP status code
     * @type {number}
     * @memberof BrokerMismatchResponseDto
     */
    status: number;
    /**
     * Human readable error message
     * @type {string}
     * @memberof BrokerMismatchResponseDto
     */
    message: string;
    /**
     * Existing Booking Set ID
     * @type {string}
     * @memberof BrokerMismatchResponseDto
     */
    existingBookingSetId: string;
    /**
     * Assigned Broker ID
     * @type {string}
     * @memberof BrokerMismatchResponseDto
     */
    assignedBrokerId: string;
    /**
     * Assigned Broker Name
     * @type {string}
     * @memberof BrokerMismatchResponseDto
     */
    assignedBrokerName: string;
}
/**
 * 
 * @export
 * @interface CalculatorDto
 */
export interface CalculatorDto {
    /**
     * 
     * @type {string}
     * @memberof CalculatorDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CalculatorDto
     */
    name: string;
}
/**
 * 
 * @export
 * @interface CloneBookingSetDto
 */
export interface CloneBookingSetDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof CloneBookingSetDto
     */
    inquiryHashes: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CloneBookingSetDto
     */
    type: CloneBookingSetDtoTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CloneBookingSetDtoTypeEnum {
    Proposal = 'proposal',
    CoverNote = 'coverNote'
}

/**
 * 
 * @export
 * @interface CreateBookingSetDto
 */
export interface CreateBookingSetDto {
    /**
     * 
     * @type {string}
     * @memberof CreateBookingSetDto
     */
    consultationId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateBookingSetDto
     */
    inquiryHashes: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateBookingSetDto
     */
    flow: CreateBookingSetDtoFlowEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateBookingSetDto
     */
    type: CreateBookingSetDtoTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateBookingSetDtoFlowEnum {
    B2b = 'b2b',
    B2c = 'b2c'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateBookingSetDtoTypeEnum {
    Proposal = 'proposal',
    CoverNote = 'coverNote'
}

/**
 * 
 * @export
 * @interface CreateBookingSetResponseDto
 */
export interface CreateBookingSetResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CreateBookingSetResponseDto
     */
    id: string;
}
/**
 * 
 * @export
 * @interface CustomerEmailDto
 */
export interface CustomerEmailDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerEmailDto
     */
    recipient: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerEmailDto
     */
    cc: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerEmailDto
     */
    body: string;
}
/**
 * 
 * @export
 * @interface DetailsResponseDto
 */
export interface DetailsResponseDto {
    /**
     * 
     * @type {string}
     * @memberof DetailsResponseDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DetailsResponseDto
     */
    consultationId: string;
    /**
     * 
     * @type {string}
     * @memberof DetailsResponseDto
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof DetailsResponseDto
     */
    assignedBrokerId: string;
    /**
     * 
     * @type {string}
     * @memberof DetailsResponseDto
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof DetailsResponseDto
     */
    activeStep: DetailsResponseDtoActiveStepEnum;
    /**
     * 
     * @type {string}
     * @memberof DetailsResponseDto
     */
    flow: DetailsResponseDtoFlowEnum;
    /**
     * 
     * @type {string}
     * @memberof DetailsResponseDto
     */
    type: DetailsResponseDtoTypeEnum;
    /**
     * 
     * @type {Array<InquiryDto>}
     * @memberof DetailsResponseDto
     */
    inquiries: Array<InquiryDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DetailsResponseDto
     */
    documentTypesToAttachToCustomerEmail: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DetailsResponseDto
     */
    documentTypesToAttachToConfirmationEmail: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DetailsResponseDto
     */
    documentTypesToGenerate: Array<string>;
    /**
     * 
     * @type {Array<DocumentDto>}
     * @memberof DetailsResponseDto
     */
    documents: Array<DocumentDto>;
    /**
     * 
     * @type {CustomerEmailDto}
     * @memberof DetailsResponseDto
     */
    customerEmail: CustomerEmailDto;
    /**
     * 
     * @type {Array<DocumentSettingDto>}
     * @memberof DetailsResponseDto
     */
    documentSettings: Array<DocumentSettingDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum DetailsResponseDtoActiveStepEnum {
    CustomerInformation = 'CustomerInformation',
    Details = 'Details',
    Questionnaire = 'Questionnaire',
    DocumentSetup = 'DocumentSetup',
    EmailSetup = 'EmailSetup',
    ReviewAndConfirm = 'ReviewAndConfirm',
    Booked = 'Booked'
}
/**
    * @export
    * @enum {string}
    */
export enum DetailsResponseDtoFlowEnum {
    B2b = 'b2b',
    B2c = 'b2c'
}
/**
    * @export
    * @enum {string}
    */
export enum DetailsResponseDtoTypeEnum {
    Proposal = 'proposal',
    CoverNote = 'coverNote'
}

/**
 * 
 * @export
 * @interface DocumentDto
 */
export interface DocumentDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    type: DocumentDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    extension: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DocumentDtoTypeEnum {
    LogoUpload = 'logoUpload',
    InsurerEmailUpload = 'insurerEmailUpload',
    InsurerDocument = '[\"insurerDocument\"]'
}

/**
 * 
 * @export
 * @interface DocumentSettingDto
 */
export interface DocumentSettingDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentSettingDto
     */
    key: string;
    /**
     * 
     * @type {object}
     * @memberof DocumentSettingDto
     */
    value: object;
}
/**
 * 
 * @export
 * @interface FormBlockDto
 */
export interface FormBlockDto {
    /**
     * 
     * @type {string}
     * @memberof FormBlockDto
     */
    name: string;
    /**
     * 
     * @type {Array<FormFieldDto>}
     * @memberof FormBlockDto
     */
    fields: Array<FormFieldDto>;
    /**
     * 
     * @type {string}
     * @memberof FormBlockDto
     */
    ranking: string;
    /**
     * 
     * @type {Array<FormBlockDto | FormInquiryBlockDto>}
     * @memberof FormBlockDto
     */
    subBlocks: Array<FormBlockDto | FormInquiryBlockDto>;
}
/**
 * 
 * @export
 * @interface FormConstraintDto
 */
export interface FormConstraintDto {
    /**
     * 
     * @type {string}
     * @memberof FormConstraintDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FormConstraintDto
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof FormConstraintDto
     */
    expression: string;
}
/**
 * 
 * @export
 * @interface FormDto
 */
export interface FormDto {
    /**
     * 
     * @type {string}
     * @memberof FormDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof FormDto
     */
    name: string;
    /**
     * 
     * @type {Array<FormBlockDto>}
     * @memberof FormDto
     */
    blocks: Array<FormBlockDto>;
}
/**
 * 
 * @export
 * @interface FormFieldDto
 */
export interface FormFieldDto {
    /**
     * 
     * @type {string}
     * @memberof FormFieldDto
     */
    name: string;
    /**
     * 
     * @type {FormInputDto}
     * @memberof FormFieldDto
     */
    input: FormInputDto;
    /**
     * 
     * @type {string}
     * @memberof FormFieldDto
     */
    ranking: string;
}
/**
 * 
 * @export
 * @interface FormFileValueDto
 */
export interface FormFileValueDto {
    /**
     * 
     * @type {string}
     * @memberof FormFileValueDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FormFileValueDto
     */
    name: string;
}
/**
 * 
 * @export
 * @interface FormInputDto
 */
export interface FormInputDto {
    /**
     * 
     * @type {string}
     * @memberof FormInputDto
     */
    dataType: FormInputDtoDataTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FormInputDto
     */
    viewType: FormInputDtoViewTypeEnum;
    /**
     * 
     * @type {Array<FormConstraintDto>}
     * @memberof FormInputDto
     */
    constraints: Array<FormConstraintDto>;
    /**
     * 
     * @type {string}
     * @memberof FormInputDto
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof FormInputDto
     */
    placeholder: string;
    /**
     * 
     * @type {string}
     * @memberof FormInputDto
     */
    defaultValue: string;
    /**
     * 
     * @type {string | Array<FormFileValueDto>}
     * @memberof FormInputDto
     */
    value: string | Array<FormFileValueDto>;
    /**
     * 
     * @type {Array<FormOptionDto>}
     * @memberof FormInputDto
     */
    options: Array<FormOptionDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum FormInputDtoDataTypeEnum {
    Text = 'Text',
    Number = 'Number',
    File = 'File',
    Boolean = 'Boolean'
}
/**
    * @export
    * @enum {string}
    */
export enum FormInputDtoViewTypeEnum {
    Text = 'Text',
    Select = 'Select',
    Textarea = 'Textarea',
    File = 'File',
    CheckBox = 'CheckBox'
}

/**
 * 
 * @export
 * @interface FormInquiryBlockDto
 */
export interface FormInquiryBlockDto {
    /**
     * 
     * @type {string}
     * @memberof FormInquiryBlockDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FormInquiryBlockDto
     */
    inquiryHash: string;
    /**
     * 
     * @type {Array<FormFieldDto>}
     * @memberof FormInquiryBlockDto
     */
    fields: Array<FormFieldDto>;
    /**
     * 
     * @type {string}
     * @memberof FormInquiryBlockDto
     */
    ranking: string;
    /**
     * 
     * @type {Array<FormBlockDto>}
     * @memberof FormInquiryBlockDto
     */
    subBlocks: Array<FormBlockDto>;
}
/**
 * 
 * @export
 * @interface FormOptionDto
 */
export interface FormOptionDto {
    /**
     * 
     * @type {string}
     * @memberof FormOptionDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof FormOptionDto
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof FormOptionDto
     */
    ConditionalFieldName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormOptionDto
     */
    ConditionalFieldValues: Array<string>;
}
/**
 * 
 * @export
 * @interface FormValidationViolationDto
 */
export interface FormValidationViolationDto {
    /**
     * Path to invalid form field
     * @type {string}
     * @memberof FormValidationViolationDto
     */
    propertyPath: string;
    /**
     * Human readable validation error message
     * @type {string}
     * @memberof FormValidationViolationDto
     */
    message: string;
    /**
     * Example value to use
     * @type {string}
     * @memberof FormValidationViolationDto
     */
    propertyExample: string;
    /**
     * Applied validation rule
     * @type {string}
     * @memberof FormValidationViolationDto
     */
    rule: string;
}
/**
 * 
 * @export
 * @interface HttpErrorReponseDto
 */
export interface HttpErrorReponseDto {
    /**
     * HTTP status code
     * @type {number}
     * @memberof HttpErrorReponseDto
     */
    status: number;
    /**
     * Human readable error message
     * @type {string}
     * @memberof HttpErrorReponseDto
     */
    message: string;
    /**
     * Any form validation violations
     * @type {Array<FormValidationViolationDto>}
     * @memberof HttpErrorReponseDto
     */
    violations: Array<FormValidationViolationDto>;
}
/**
 * 
 * @export
 * @interface InquiryDto
 */
export interface InquiryDto {
    /**
     * 
     * @type {string}
     * @memberof InquiryDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InquiryDto
     */
    hash: string;
    /**
     * 
     * @type {boolean}
     * @memberof InquiryDto
     */
    isDeleted: boolean;
    /**
     * 
     * @type {string}
     * @memberof InquiryDto
     */
    selectedAgencyNumber: string;
    /**
     * 
     * @type {number}
     * @memberof InquiryDto
     */
    selectedFrequency: number;
    /**
     * 
     * @type {ProductDto}
     * @memberof InquiryDto
     */
    product: ProductDto;
    /**
     * 
     * @type {OfferDto}
     * @memberof InquiryDto
     */
    offer: OfferDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof InquiryDto
     */
    documentTypesToAttachToCustomerEmail: Array<InquiryDtoDocumentTypesToAttachToCustomerEmailEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InquiryDto
     */
    documentTypesToAttachToConfirmationEmail: Array<InquiryDtoDocumentTypesToAttachToConfirmationEmailEnum>;
    /**
     * 
     * @type {Array<DocumentDto>}
     * @memberof InquiryDto
     */
    documents: Array<DocumentDto>;
    /**
     * 
     * @type {Array<DocumentSettingDto>}
     * @memberof InquiryDto
     */
    documentSettings: Array<DocumentSettingDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum InquiryDtoDocumentTypesToAttachToCustomerEmailEnum {
    OrderDocument = 'orderDocument',
    ConsultationDocument = 'consultationDocument',
    ProposalConsultationDocument = 'proposalConsultationDocument',
    ProposalDocument = 'proposalDocument',
    ProposalOrderDocument = 'proposalOrderDocument',
    PolicyDocument = 'policyDocument',
    InsuranceConfirmationDocument = 'insuranceConfirmationDocument'
}
/**
    * @export
    * @enum {string}
    */
export enum InquiryDtoDocumentTypesToAttachToConfirmationEmailEnum {
    OrderDocument = 'orderDocument',
    ConsultationDocument = 'consultationDocument',
    ProposalConsultationDocument = 'proposalConsultationDocument',
    ProposalDocument = 'proposalDocument',
    ProposalOrderDocument = 'proposalOrderDocument',
    PolicyDocument = 'policyDocument',
    InsuranceConfirmationDocument = 'insuranceConfirmationDocument'
}

/**
 * 
 * @export
 * @interface InquirySummaryDto
 */
export interface InquirySummaryDto {
    /**
     * 
     * @type {string}
     * @memberof InquirySummaryDto
     */
    productName: string;
    /**
     * 
     * @type {string}
     * @memberof InquirySummaryDto
     */
    insurerName: string;
    /**
     * 
     * @type {string}
     * @memberof InquirySummaryDto
     */
    insurerLogo: string;
    /**
     * 
     * @type {string}
     * @memberof InquirySummaryDto
     */
    tariffName: string;
    /**
     * 
     * @type {number}
     * @memberof InquirySummaryDto
     */
    yearlyPremium: number;
    /**
     * 
     * @type {string}
     * @memberof InquirySummaryDto
     */
    inquiryHash: string;
    /**
     * 
     * @type {boolean}
     * @memberof InquirySummaryDto
     */
    isSelected: boolean;
}
/**
 * 
 * @export
 * @interface InsurerDto
 */
export interface InsurerDto {
    /**
     * 
     * @type {string}
     * @memberof InsurerDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InsurerDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof InsurerDto
     */
    tag: string;
    /**
     * 
     * @type {string}
     * @memberof InsurerDto
     */
    logo: string;
}
/**
 * 
 * @export
 * @interface OfferDto
 */
export interface OfferDto {
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    type: OfferDtoTypeEnum;
    /**
     * 
     * @type {Array<VariantDto>}
     * @memberof OfferDto
     */
    variants: Array<VariantDto>;
    /**
     * 
     * @type {number}
     * @memberof OfferDto
     */
    frequency: number;
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    method: OfferDtoMethodEnum;
    /**
     * 
     * @type {InsurerDto}
     * @memberof OfferDto
     */
    insurer: InsurerDto;
    /**
     * 
     * @type {TariffDto}
     * @memberof OfferDto
     */
    tariff: TariffDto;
    /**
     * 
     * @type {CalculatorDto}
     * @memberof OfferDto
     */
    calculator: CalculatorDto;
    /**
     * 
     * @type {PremiumDto}
     * @memberof OfferDto
     */
    premium: PremiumDto;
}

/**
    * @export
    * @enum {string}
    */
export enum OfferDtoTypeEnum {
    CalculatorOffer = 'calculatorOffer',
    TenderingOffer = 'tenderingOffer'
}
/**
    * @export
    * @enum {string}
    */
export enum OfferDtoMethodEnum {
    DirectDebit = 'directDebit',
    Invoice = 'invoice'
}

/**
 * 
 * @export
 * @interface PaymentDetailsDto
 */
export interface PaymentDetailsDto {
    /**
     * 
     * @type {BankDetailsInfoDto}
     * @memberof PaymentDetailsDto
     */
    bankDetails: BankDetailsInfoDto;
    /**
     * 
     * @type {Array<PaymentInfoDto>}
     * @memberof PaymentDetailsDto
     */
    paymentInfo: Array<PaymentInfoDto>;
}
/**
 * 
 * @export
 * @interface PaymentInfoDto
 */
export interface PaymentInfoDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentInfoDto
     */
    productName: string;
    /**
     * 
     * @type {Array<PaymentItemDto>}
     * @memberof PaymentInfoDto
     */
    data: Array<PaymentItemDto>;
}
/**
 * 
 * @export
 * @interface PaymentItemDto
 */
export interface PaymentItemDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentItemDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentItemDto
     */
    value: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentItemDto
     */
    surcharge?: number;
}
/**
 * 
 * @export
 * @interface PremiumDto
 */
export interface PremiumDto {
    /**
     * 
     * @type {number}
     * @memberof PremiumDto
     */
    frequencyGrossPremium: number;
    /**
     * 
     * @type {number}
     * @memberof PremiumDto
     */
    frequencyGrossMinimumPremium: number;
    /**
     * 
     * @type {number}
     * @memberof PremiumDto
     */
    correctedAnnualNetPremium: number;
    /**
     * 
     * @type {number}
     * @memberof PremiumDto
     */
    correctedAnnualGrossPremium: number;
}
/**
 * 
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    tag: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    shortName: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    longName: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDto
     */
    isDeleted: boolean;
}
/**
 * 
 * @export
 * @interface SelectedTariffsDto
 */
export interface SelectedTariffsDto {
    /**
     * 
     * @type {Array<TariffsDataDto>}
     * @memberof SelectedTariffsDto
     */
    tariffsData: Array<TariffsDataDto>;
    /**
     * 
     * @type {string}
     * @memberof SelectedTariffsDto
     */
    sum: string;
}
/**
 * 
 * @export
 * @interface StepDto
 */
export interface StepDto {
    /**
     * Normalized process step name
     * @type {string}
     * @memberof StepDto
     */
    slug: string;
    /**
     * Process step enum
     * @type {string}
     * @memberof StepDto
     */
    type: StepDtoTypeEnum;
    /**
     * Flows for which this step is available
     * @type {Array<string>}
     * @memberof StepDto
     */
    flows: Array<StepDtoFlowsEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum StepDtoTypeEnum {
    CustomerInformation = 'CustomerInformation',
    Details = 'Details',
    Questionnaire = 'Questionnaire',
    DocumentSetup = 'DocumentSetup',
    EmailSetup = 'EmailSetup',
    ReviewAndConfirm = 'ReviewAndConfirm',
    Booked = 'Booked'
}
/**
    * @export
    * @enum {string}
    */
export enum StepDtoFlowsEnum {
    B2b = 'b2b',
    B2c = 'b2c'
}

/**
 * 
 * @export
 * @interface StepLinksDto
 */
export interface StepLinksDto {
    /**
     * Slug of previous step (or null)
     * @type {string}
     * @memberof StepLinksDto
     */
    prevStep: string;
    /**
     * Slug of current step
     * @type {string}
     * @memberof StepLinksDto
     */
    currStep: string;
    /**
     * Slug of next step (or null)
     * @type {string}
     * @memberof StepLinksDto
     */
    nextStep: string;
}
/**
 * 
 * @export
 * @interface StepListResponseDto
 */
export interface StepListResponseDto {
    /**
     * ID of corresponding BookingSet
     * @type {string}
     * @memberof StepListResponseDto
     */
    id: string;
    /**
     * Number of displayed steps
     * @type {number}
     * @memberof StepListResponseDto
     */
    count: number;
    /**
     * Number of total steps
     * @type {number}
     * @memberof StepListResponseDto
     */
    total: number;
    /**
     * 
     * @type {StepListResponseDtoEmbedded}
     * @memberof StepListResponseDto
     */
    _embedded: StepListResponseDtoEmbedded;
    /**
     * 
     * @type {StepMetadataDto}
     * @memberof StepListResponseDto
     */
    metadata: StepMetadataDto;
}
/**
 * 
 * @export
 * @interface StepListResponseDtoEmbedded
 */
export interface StepListResponseDtoEmbedded {
    /**
     * 
     * @type {Array<StepDto>}
     * @memberof StepListResponseDtoEmbedded
     */
    steps?: Array<StepDto>;
}
/**
 * 
 * @export
 * @interface StepMetadataDto
 */
export interface StepMetadataDto {
    /**
     * 
     * @type {StepLinksDto}
     * @memberof StepMetadataDto
     */
    links: StepLinksDto;
}
/**
 * 
 * @export
 * @interface SummaryItemDto
 */
export interface SummaryItemDto {
    /**
     * 
     * @type {string}
     * @memberof SummaryItemDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SummaryItemDto
     */
    value: string;
}
/**
 * 
 * @export
 * @interface SummaryResponseDto
 */
export interface SummaryResponseDto {
    /**
     * 
     * @type {string}
     * @memberof SummaryResponseDto
     */
    id: string;
    /**
     * 
     * @type {AgencyNumberDto}
     * @memberof SummaryResponseDto
     */
    agencyNumber: AgencyNumberDto;
    /**
     * 
     * @type {Array<SummaryItemDto>}
     * @memberof SummaryResponseDto
     */
    customerInformation: Array<SummaryItemDto>;
    /**
     * 
     * @type {PaymentDetailsDto}
     * @memberof SummaryResponseDto
     */
    paymentDetails: PaymentDetailsDto;
    /**
     * 
     * @type {SelectedTariffsDto}
     * @memberof SummaryResponseDto
     */
    selectedTariffs: SelectedTariffsDto;
    /**
     * 
     * @type {Array<SummaryItemDto>}
     * @memberof SummaryResponseDto
     */
    brokerDetails: Array<SummaryItemDto>;
}
/**
 * 
 * @export
 * @interface TariffDto
 */
export interface TariffDto {
    /**
     * 
     * @type {string}
     * @memberof TariffDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TariffDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TariffDto
     */
    tag: string;
}
/**
 * 
 * @export
 * @interface TariffsDataDto
 */
export interface TariffsDataDto {
    /**
     * 
     * @type {string}
     * @memberof TariffsDataDto
     */
    productName: string;
    /**
     * 
     * @type {string}
     * @memberof TariffsDataDto
     */
    tariffName: string;
    /**
     * 
     * @type {string}
     * @memberof TariffsDataDto
     */
    tariffLogo: string;
    /**
     * 
     * @type {string}
     * @memberof TariffsDataDto
     */
    premium: string;
    /**
     * 
     * @type {string}
     * @memberof TariffsDataDto
     */
    tariffId: string;
    /**
     * 
     * @type {string}
     * @memberof TariffsDataDto
     */
    offerId: string;
    /**
     * 
     * @type {string}
     * @memberof TariffsDataDto
     */
    groupId: string;
}
/**
 * 
 * @export
 * @interface UpdateFormDataDto
 */
export interface UpdateFormDataDto {
    /**
     * Key/value pairs of form data for current/active process step
     * @type {{ [key: string]: string | object; }}
     * @memberof UpdateFormDataDto
     */
    data: { [key: string]: string | object; };
}
/**
 * 
 * @export
 * @interface UpdateInquiryDto
 */
export interface UpdateInquiryDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateInquiryDto
     */
    inquiryHash: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateInquiryDto
     */
    selected: boolean;
}
/**
 * 
 * @export
 * @interface VariantDto
 */
export interface VariantDto {
    /**
     * 
     * @type {number}
     * @memberof VariantDto
     */
    frequency: number;
    /**
     * 
     * @type {number}
     * @memberof VariantDto
     */
    premiumCent: number;
    /**
     * 
     * @type {number}
     * @memberof VariantDto
     */
    annualPremiumCent: number;
    /**
     * 
     * @type {string}
     * @memberof VariantDto
     */
    premiumFormatted: string;
    /**
     * 
     * @type {string}
     * @memberof VariantDto
     */
    paymentMethods: VariantDtoPaymentMethodsEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum VariantDtoPaymentMethodsEnum {
    DirectDebit = 'directDebit',
    Invoice = 'invoice'
}


/**
 * AppHealthCheckApi - axios parameter creator
 * @export
 */
export const AppHealthCheckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckControllerCheckAppHealth: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/bookings/v1/health-check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppHealthCheckApi - functional programming interface
 * @export
 */
export const AppHealthCheckApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppHealthCheckApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCheckControllerCheckAppHealth(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCheckControllerCheckAppHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppHealthCheckApi - factory interface
 * @export
 */
export const AppHealthCheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppHealthCheckApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckControllerCheckAppHealth(options?: any): AxiosPromise<void> {
            return localVarFp.healthCheckControllerCheckAppHealth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppHealthCheckApi - object-oriented interface
 * @export
 * @class AppHealthCheckApi
 * @extends {BaseAPI}
 */
export class AppHealthCheckApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppHealthCheckApi
     */
    public healthCheckControllerCheckAppHealth(options?: any) {
        return AppHealthCheckApiFp(this.configuration).healthCheckControllerCheckAppHealth(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BookingSetApi - axios parameter creator
 * @export
 */
export const BookingSetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {CloneBookingSetDto} cloneBookingSetDto Clone BookingSet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingSetControllerCloneBooking: async (id: string, cloneBookingSetDto: CloneBookingSetDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingSetControllerCloneBooking', 'id', id)
            // verify required parameter 'cloneBookingSetDto' is not null or undefined
            assertParamExists('bookingSetControllerCloneBooking', 'cloneBookingSetDto', cloneBookingSetDto)
            const localVarPath = `/api/bookings/v1/bookingSets/{id}/clone`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cloneBookingSetDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateBookingSetDto} createBookingSetDto Insert or update BookingSet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingSetControllerCreate: async (createBookingSetDto: CreateBookingSetDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBookingSetDto' is not null or undefined
            assertParamExists('bookingSetControllerCreate', 'createBookingSetDto', createBookingSetDto)
            const localVarPath = `/api/bookings/v1/bookingSets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBookingSetDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingSetControllerGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingSetControllerGet', 'id', id)
            const localVarPath = `/api/bookings/v1/bookingSets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingSetControllerGetDetails: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingSetControllerGetDetails', 'id', id)
            const localVarPath = `/api/bookings/v1/bookingSets/{id}/details`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingSetControllerGetSummary: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingSetControllerGetSummary', 'id', id)
            const localVarPath = `/api/bookings/v1/bookingSets/{id}/summary`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateFormDataDto} updateFormDataDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingSetControllerUpdateFormData: async (id: string, updateFormDataDto: UpdateFormDataDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingSetControllerUpdateFormData', 'id', id)
            // verify required parameter 'updateFormDataDto' is not null or undefined
            assertParamExists('bookingSetControllerUpdateFormData', 'updateFormDataDto', updateFormDataDto)
            const localVarPath = `/api/bookings/v1/bookingSets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFormDataDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingSetApi - functional programming interface
 * @export
 */
export const BookingSetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookingSetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {CloneBookingSetDto} cloneBookingSetDto Clone BookingSet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingSetControllerCloneBooking(id: string, cloneBookingSetDto: CloneBookingSetDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateBookingSetResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingSetControllerCloneBooking(id, cloneBookingSetDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateBookingSetDto} createBookingSetDto Insert or update BookingSet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingSetControllerCreate(createBookingSetDto: CreateBookingSetDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateBookingSetResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingSetControllerCreate(createBookingSetDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingSetControllerGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingSetResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingSetControllerGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingSetControllerGetDetails(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingSetControllerGetDetails(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingSetControllerGetSummary(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SummaryResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingSetControllerGetSummary(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateFormDataDto} updateFormDataDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingSetControllerUpdateFormData(id: string, updateFormDataDto: UpdateFormDataDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingSetControllerUpdateFormData(id, updateFormDataDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BookingSetApi - factory interface
 * @export
 */
export const BookingSetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookingSetApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {CloneBookingSetDto} cloneBookingSetDto Clone BookingSet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingSetControllerCloneBooking(id: string, cloneBookingSetDto: CloneBookingSetDto, options?: any): AxiosPromise<CreateBookingSetResponseDto> {
            return localVarFp.bookingSetControllerCloneBooking(id, cloneBookingSetDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateBookingSetDto} createBookingSetDto Insert or update BookingSet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingSetControllerCreate(createBookingSetDto: CreateBookingSetDto, options?: any): AxiosPromise<CreateBookingSetResponseDto> {
            return localVarFp.bookingSetControllerCreate(createBookingSetDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingSetControllerGet(id: string, options?: any): AxiosPromise<BookingSetResponseDto> {
            return localVarFp.bookingSetControllerGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingSetControllerGetDetails(id: string, options?: any): AxiosPromise<DetailsResponseDto> {
            return localVarFp.bookingSetControllerGetDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingSetControllerGetSummary(id: string, options?: any): AxiosPromise<SummaryResponseDto> {
            return localVarFp.bookingSetControllerGetSummary(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateFormDataDto} updateFormDataDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingSetControllerUpdateFormData(id: string, updateFormDataDto: UpdateFormDataDto, options?: any): AxiosPromise<void> {
            return localVarFp.bookingSetControllerUpdateFormData(id, updateFormDataDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookingSetApi - object-oriented interface
 * @export
 * @class BookingSetApi
 * @extends {BaseAPI}
 */
export class BookingSetApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {CloneBookingSetDto} cloneBookingSetDto Clone BookingSet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingSetApi
     */
    public bookingSetControllerCloneBooking(id: string, cloneBookingSetDto: CloneBookingSetDto, options?: any) {
        return BookingSetApiFp(this.configuration).bookingSetControllerCloneBooking(id, cloneBookingSetDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateBookingSetDto} createBookingSetDto Insert or update BookingSet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingSetApi
     */
    public bookingSetControllerCreate(createBookingSetDto: CreateBookingSetDto, options?: any) {
        return BookingSetApiFp(this.configuration).bookingSetControllerCreate(createBookingSetDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingSetApi
     */
    public bookingSetControllerGet(id: string, options?: any) {
        return BookingSetApiFp(this.configuration).bookingSetControllerGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingSetApi
     */
    public bookingSetControllerGetDetails(id: string, options?: any) {
        return BookingSetApiFp(this.configuration).bookingSetControllerGetDetails(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingSetApi
     */
    public bookingSetControllerGetSummary(id: string, options?: any) {
        return BookingSetApiFp(this.configuration).bookingSetControllerGetSummary(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateFormDataDto} updateFormDataDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingSetApi
     */
    public bookingSetControllerUpdateFormData(id: string, updateFormDataDto: UpdateFormDataDto, options?: any) {
        return BookingSetApiFp(this.configuration).bookingSetControllerUpdateFormData(id, updateFormDataDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InquiryApi - axios parameter creator
 * @export
 */
export const InquiryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inquiryControllerGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inquiryControllerGet', 'id', id)
            const localVarPath = `/api/bookings/v1/bookingSet/{id}/inquiries`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<UpdateInquiryDto>} updateInquiryDto Update inquiries for BookingSet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inquiryControllerUpdate: async (id: string, updateInquiryDto: Array<UpdateInquiryDto>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inquiryControllerUpdate', 'id', id)
            // verify required parameter 'updateInquiryDto' is not null or undefined
            assertParamExists('inquiryControllerUpdate', 'updateInquiryDto', updateInquiryDto)
            const localVarPath = `/api/bookings/v1/bookingSet/{id}/inquiries`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInquiryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InquiryApi - functional programming interface
 * @export
 */
export const InquiryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InquiryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inquiryControllerGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InquirySummaryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inquiryControllerGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<UpdateInquiryDto>} updateInquiryDto Update inquiries for BookingSet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inquiryControllerUpdate(id: string, updateInquiryDto: Array<UpdateInquiryDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inquiryControllerUpdate(id, updateInquiryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InquiryApi - factory interface
 * @export
 */
export const InquiryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InquiryApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inquiryControllerGet(id: string, options?: any): AxiosPromise<Array<InquirySummaryDto>> {
            return localVarFp.inquiryControllerGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<UpdateInquiryDto>} updateInquiryDto Update inquiries for BookingSet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inquiryControllerUpdate(id: string, updateInquiryDto: Array<UpdateInquiryDto>, options?: any): AxiosPromise<void> {
            return localVarFp.inquiryControllerUpdate(id, updateInquiryDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InquiryApi - object-oriented interface
 * @export
 * @class InquiryApi
 * @extends {BaseAPI}
 */
export class InquiryApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryApi
     */
    public inquiryControllerGet(id: string, options?: any) {
        return InquiryApiFp(this.configuration).inquiryControllerGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Array<UpdateInquiryDto>} updateInquiryDto Update inquiries for BookingSet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryApi
     */
    public inquiryControllerUpdate(id: string, updateInquiryDto: Array<UpdateInquiryDto>, options?: any) {
        return InquiryApiFp(this.configuration).inquiryControllerUpdate(id, updateInquiryDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProcessStepApi - axios parameter creator
 * @export
 */
export const ProcessStepApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} stepSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerActivate: async (id: string, stepSlug: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepControllerActivate', 'id', id)
            // verify required parameter 'stepSlug' is not null or undefined
            assertParamExists('stepControllerActivate', 'stepSlug', stepSlug)
            const localVarPath = `/api/bookings/v1/bookingSets/{id}/steps/activate/{stepSlug}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"stepSlug"}}`, encodeURIComponent(String(stepSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerList: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepControllerList', 'id', id)
            const localVarPath = `/api/bookings/v1/bookingSets/{id}/steps`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProcessStepApi - functional programming interface
 * @export
 */
export const ProcessStepApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProcessStepApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} stepSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepControllerActivate(id: string, stepSlug: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingSetResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepControllerActivate(id, stepSlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepControllerList(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepListResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepControllerList(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProcessStepApi - factory interface
 * @export
 */
export const ProcessStepApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProcessStepApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} stepSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerActivate(id: string, stepSlug: string, options?: any): AxiosPromise<BookingSetResponseDto> {
            return localVarFp.stepControllerActivate(id, stepSlug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerList(id: string, options?: any): AxiosPromise<StepListResponseDto> {
            return localVarFp.stepControllerList(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProcessStepApi - object-oriented interface
 * @export
 * @class ProcessStepApi
 * @extends {BaseAPI}
 */
export class ProcessStepApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {string} stepSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessStepApi
     */
    public stepControllerActivate(id: string, stepSlug: string, options?: any) {
        return ProcessStepApiFp(this.configuration).stepControllerActivate(id, stepSlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessStepApi
     */
    public stepControllerList(id: string, options?: any) {
        return ProcessStepApiFp(this.configuration).stepControllerList(id, options).then((request) => request(this.axios, this.basePath));
    }
}


