import {
  useQuery,
  useMutation,
  UseMutationResult,
  UseQueryResult,
  UseQueryOptions,
} from 'react-query';
import { AxiosError } from 'axios';

import { Broker, BrokerContact, BrokerApi } from '@api/broker/api';
import { ApiQuery } from '@enums';
import { helpers } from '@helpers';

const brokerInstance = new BrokerApi(
  undefined,
  helpers.isWidget
    ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/broker/v1`
    : '/api/broker/v1'
);

const useBrokerInfo = (brokerId: number): UseQueryResult<Broker, AxiosError> =>
  useQuery(
    [ApiQuery.BrokerInfo, brokerId],
    () => brokerInstance.byIDBrokerRequest(brokerId.toString()).then((res) => res.data),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      retry: false,
    }
  );

export const useBrokerContacts = (
  brokerId: number,
  options: UseQueryOptions<BrokerContact, AxiosError> = {}
): UseQueryResult<BrokerContact, AxiosError> =>
  useQuery(
    [ApiQuery.BrokerContacts, brokerId],
    () => brokerInstance.byIDBrokerContactRequest(brokerId.toString()).then((res) => res.data),
    {
      refetchOnMount: true,
      ...options,
    }
  );

const useUpdateBrokerContacts = (
  brokerId: string
): UseMutationResult<BrokerContact, AxiosError, BrokerContact, unknown> =>
  useMutation((body: BrokerContact) =>
    brokerInstance.updateBrokerContactRequest(brokerId, body).then((res) => res.data)
  );

const BrokerInfo = {
  useBrokerInfo,
  useBrokerContacts,
  useUpdateBrokerContacts,
};

export default BrokerInfo;
