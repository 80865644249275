import React from 'react';

import { BootingWrapper } from '@shared';

import { MainWrapper, MainContent } from '../Main/components';

type Props = {
  children: React.ReactNode;
  pageClassName?: string;
  headerClassName?: string;
};

const Simple: React.FC<Props> = (props) => {
  const { children, pageClassName, headerClassName } = props;

  return (
    <BootingWrapper>
      <MainWrapper>
        <MainContent
          pageClassName={pageClassName}
          headerClassName={headerClassName}
          hideSteps={true}
        >
          {children}
        </MainContent>
      </MainWrapper>
    </BootingWrapper>
  );
};

export default Simple;
