/* tslint:disable */
/* eslint-disable */
/**
 * Offer Orchestrator API
 * API to handle the offers
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AppliedDiscountDto
 */
export interface AppliedDiscountDto {
    /**
     * 
     * @type {number}
     * @memberof AppliedDiscountDto
     */
    appliedDiscount: number;
}
/**
 * 
 * @export
 * @interface AppliedFilterDto
 */
export interface AppliedFilterDto {
    /**
     * 
     * @type {string}
     * @memberof AppliedFilterDto
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof AppliedFilterDto
     */
    value: string;
}
/**
 * 
 * @export
 * @interface BankInfoDto
 */
export interface BankInfoDto {
    /**
     * 
     * @type {string}
     * @memberof BankInfoDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BankInfoDto
     */
    holder: string;
    /**
     * 
     * @type {string}
     * @memberof BankInfoDto
     */
    iban: string;
    /**
     * 
     * @type {string}
     * @memberof BankInfoDto
     */
    bic: string;
}
/**
 * 
 * @export
 * @interface BiproData
 */
export interface BiproData {
    /**
     * 
     * @type {object}
     * @memberof BiproData
     */
    request: object;
    /**
     * 
     * @type {object}
     * @memberof BiproData
     */
    response: object;
}
/**
 * 
 * @export
 * @interface CalculatorDto
 */
export interface CalculatorDto {
    /**
     * 
     * @type {string}
     * @memberof CalculatorDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CalculatorDto
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ColumnCollectionResponseDto
 */
export interface ColumnCollectionResponseDto {
    /**
     * 
     * @type {number}
     * @memberof ColumnCollectionResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof ColumnCollectionResponseDto
     */
    total: number;
    /**
     * 
     * @type {EmbeddedDisplayColumn}
     * @memberof ColumnCollectionResponseDto
     */
    _embedded: EmbeddedDisplayColumn;
}
/**
 * 
 * @export
 * @interface ColumnDto
 */
export interface ColumnDto {
    /**
     * 
     * @type {string}
     * @memberof ColumnDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ColumnDto
     */
    featureId: string;
    /**
     * 
     * @type {string}
     * @memberof ColumnDto
     */
    productId: string;
    /**
     * 
     * @type {boolean}
     * @memberof ColumnDto
     */
    active: boolean;
    /**
     * 
     * @type {number}
     * @memberof ColumnDto
     */
    rank: number;
}
/**
 * 
 * @export
 * @interface ComparisonDto
 */
export interface ComparisonDto {
    /**
     * 
     * @type {OfferDto}
     * @memberof ComparisonDto
     */
    offer: OfferDto;
    /**
     * 
     * @type {Array<FeatureCategoryDto>}
     * @memberof ComparisonDto
     */
    featuresCategories: Array<FeatureCategoryDto>;
    /**
     * 
     * @type {Array<DocumentWithUrl>}
     * @memberof ComparisonDto
     */
    documents: Array<DocumentWithUrl>;
}
/**
 * 
 * @export
 * @interface ComparisonResponseDto
 */
export interface ComparisonResponseDto {
    /**
     * 
     * @type {number}
     * @memberof ComparisonResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof ComparisonResponseDto
     */
    total: number;
    /**
     * 
     * @type {EmbeddedComparison}
     * @memberof ComparisonResponseDto
     */
    _embedded: EmbeddedComparison;
}
/**
 * 
 * @export
 * @interface CustomerDto
 */
export interface CustomerDto {
    /**
     * 
     * @type {object}
     * @memberof CustomerDto
     */
    salutation: object;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    streetNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    zip: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    paymentType: CustomerDtoPaymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    company: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    legalForm: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    companyStreet?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    companyStreetNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    companyCity?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    companyZip?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    birthDate?: string;
    /**
     * 
     * @type {BankInfoDto}
     * @memberof CustomerDto
     */
    bankInfo?: BankInfoDto;
}

/**
    * @export
    * @enum {string}
    */
export enum CustomerDtoPaymentTypeEnum {
    Invoice = 'invoice',
    DirectDebit = 'directDebit'
}

/**
 * 
 * @export
 * @interface DefaultConfiguration
 */
export interface DefaultConfiguration {
    /**
     * 
     * @type {number}
     * @memberof DefaultConfiguration
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof DefaultConfiguration
     */
    offset: number;
    /**
     * 
     * @type {Array<DefaultConfigurationOrder>}
     * @memberof DefaultConfiguration
     */
    order?: Array<DefaultConfigurationOrder>;
}
/**
 * 
 * @export
 * @interface DefaultConfigurationOrder
 */
export interface DefaultConfigurationOrder {
    /**
     * 
     * @type {string}
     * @memberof DefaultConfigurationOrder
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DefaultConfigurationOrder
     */
    values: string;
}
/**
 * 
 * @export
 * @interface Discount
 */
export interface Discount {
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    id: string;
    /**
     * 
     * @type {Array<Discount>}
     * @memberof Discount
     */
    discounts: Array<Discount>;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    discountedGrossPremium: number;
    /**
     * 
     * @type {boolean}
     * @memberof Discount
     */
    showDiscount: boolean;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    maxDiscount: number;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    appliedDiscount: number;
    /**
     * 
     * @type {boolean}
     * @memberof Discount
     */
    isDeleted: boolean;
}
/**
 * 
 * @export
 * @interface DiscountDto
 */
export interface DiscountDto {
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    type: DiscountDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DiscountDto
     */
    discountPercentage?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DiscountDto
     */
    isSelected: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiscountDto
     */
    isDeleted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiscountDto
     */
    isMinDiscountable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiscountDto
     */
    showDiscount: boolean;
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    coordinatedWith: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    agreementDate: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof DiscountDto
     */
    price?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum DiscountDtoTypeEnum {
    CampaignDiscount = 'campaign-discount',
    FrameDiscount = 'frame-discount',
    AccountDiscount = 'account-discount',
    BrokerDiscount = 'broker-discount',
    BookingDiscount = 'booking-discount',
    TargetDiscount = 'target-discount'
}

/**
 * 
 * @export
 * @interface DisplayColumnsDto
 */
export interface DisplayColumnsDto {
    /**
     * 
     * @type {string}
     * @memberof DisplayColumnsDto
     */
    featureName: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayColumnsDto
     */
    featureId: string;
}
/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     *    *  INSURER_DOC = 1,    *  CONDITIONS = 2,    *  PROPOSAL = 3,    *  OTHER = 4,    *  INSURER_CUSTOM = 5,
     * @type {number}
     * @memberof Document
     */
    type: DocumentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    title: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    fileId: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    extension: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DocumentTypeEnum {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5
}

/**
 * 
 * @export
 * @interface DocumentWithUrl
 */
export interface DocumentWithUrl {
    /**
     *    *  INSURER_DOC = 1,    *  CONDITIONS = 2,    *  PROPOSAL = 3,    *  OTHER = 4,    *  INSURER_CUSTOM = 5,
     * @type {number}
     * @memberof DocumentWithUrl
     */
    type: DocumentWithUrlTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DocumentWithUrl
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentWithUrl
     */
    title: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentWithUrl
     */
    fileId: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentWithUrl
     */
    extension: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentWithUrl
     */
    url?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DocumentWithUrlTypeEnum {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5
}

/**
 * 
 * @export
 * @interface Embedded
 */
export interface Embedded {
    /**
     * 
     * @type {Array<SalesCampaign>}
     * @memberof Embedded
     */
    salesCampaigns: Array<SalesCampaign>;
}
/**
 * 
 * @export
 * @interface EmbeddedComparison
 */
export interface EmbeddedComparison {
    /**
     * 
     * @type {Array<ComparisonDto>}
     * @memberof EmbeddedComparison
     */
    comparisons: Array<ComparisonDto>;
}
/**
 * 
 * @export
 * @interface EmbeddedDisplayColumn
 */
export interface EmbeddedDisplayColumn {
    /**
     * 
     * @type {Array<OfferDisplayColumn>}
     * @memberof EmbeddedDisplayColumn
     */
    columns: Array<OfferDisplayColumn>;
}
/**
 * 
 * @export
 * @interface EmbeddedOfferList
 */
export interface EmbeddedOfferList {
    /**
     * 
     * @type {Array<OfferDto>}
     * @memberof EmbeddedOfferList
     */
    offers: Array<OfferDto>;
}
/**
 * 
 * @export
 * @interface EmbeddedOffers
 */
export interface EmbeddedOffers {
    /**
     * 
     * @type {Array<OfferGroupResponseDto>}
     * @memberof EmbeddedOffers
     */
    offerGroups: Array<OfferGroupResponseDto>;
}
/**
 * 
 * @export
 * @interface EmbeddedTenderingOffers
 */
export interface EmbeddedTenderingOffers {
    /**
     * 
     * @type {Array<OfferDto>}
     * @memberof EmbeddedTenderingOffers
     */
    tenderingOffers: Array<OfferDto>;
}
/**
 * 
 * @export
 * @interface Feature
 */
export interface Feature {
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    formattedValue: string;
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    iconType?: FeatureIconTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    categoryId: string;
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    example: string;
    /**
     * 
     * @type {number}
     * @memberof Feature
     */
    footnoteIndex?: number;
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    info: string;
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    type: string;
    /**
     * 
     * @type {boolean}
     * @memberof Feature
     */
    isRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Feature
     */
    isHighlighted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Feature
     */
    isExcludedFromList?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Feature
     */
    isDynamic?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Feature
     */
    rank: number;
}

/**
    * @export
    * @enum {string}
    */
export enum FeatureIconTypeEnum {
    Check = 'check',
    CheckAverage = 'check-average',
    Close = 'close'
}

/**
 * 
 * @export
 * @interface FeatureCategoryDto
 */
export interface FeatureCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof FeatureCategoryDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof FeatureCategoryDto
     */
    rank: number;
    /**
     * 
     * @type {Array<FeatureItemDto>}
     * @memberof FeatureCategoryDto
     */
    features: Array<FeatureItemDto>;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureCategoryDto
     */
    isExcludedFromList: boolean;
    /**
     * 
     * @type {string}
     * @memberof FeatureCategoryDto
     */
    id: string;
}
/**
 * 
 * @export
 * @interface FeatureDto
 */
export interface FeatureDto {
    /**
     * 
     * @type {string}
     * @memberof FeatureDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureDto
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureDto
     */
    iconType: FeatureDtoIconTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FeatureDto
     */
    info?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureDto
     */
    example?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum FeatureDtoIconTypeEnum {
    Check = 'check',
    CheckAverage = 'check-average',
    Close = 'close'
}

/**
 * 
 * @export
 * @interface FeatureGroupDto
 */
export interface FeatureGroupDto {
    /**
     * 
     * @type {string}
     * @memberof FeatureGroupDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureGroupDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof FeatureGroupDto
     */
    rank: number;
    /**
     * 
     * @type {Array<FeatureCategoryDto>}
     * @memberof FeatureGroupDto
     */
    featureCategories: Array<FeatureCategoryDto>;
}
/**
 * 
 * @export
 * @interface FeatureItemDto
 */
export interface FeatureItemDto {
    /**
     * 
     * @type {string}
     * @memberof FeatureItemDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureItemDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureItemDto
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureItemDto
     */
    rawValue?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureItemDto
     */
    iconType: FeatureItemDtoIconTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof FeatureItemDto
     */
    rank: number;
    /**
     * 
     * @type {string}
     * @memberof FeatureItemDto
     */
    info?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureItemDto
     */
    example?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureItemDto
     */
    hasDifference?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FeatureItemDto
     */
    footnoteIndex?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureItemDto
     */
    type?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureItemDto
     */
    isRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureItemDto
     */
    isDynamic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureItemDto
     */
    isExcludedFromList?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum FeatureItemDtoIconTypeEnum {
    Check = 'check',
    CheckAverage = 'check-average',
    Close = 'close'
}

/**
 * 
 * @export
 * @interface Filter
 */
export interface Filter {
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    featureId: string;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    type: FilterTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof Filter
     */
    productIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    comparisonOperator?: FilterComparisonOperatorEnum;
    /**
     * 
     * @type {Array<FilterOptions>}
     * @memberof Filter
     */
    options?: Array<FilterOptions>;
}

/**
    * @export
    * @enum {string}
    */
export enum FilterTypeEnum {
    RadioButton = 'radio-button',
    RadioButtonMin = 'radio-button-min',
    RadioButtonMax = 'radio-button-max',
    RadioButtonMoneyMin = 'radio-button-money-min',
    RadioButtonMoneyMax = 'radio-button-money-max',
    CheckboxPeriodYearly = 'checkbox-period-yearly',
    Checkbox = 'checkbox'
}
/**
    * @export
    * @enum {string}
    */
export enum FilterComparisonOperatorEnum {
    Gt = 'gt',
    Gte = 'gte',
    Lt = 'lt',
    Lte = 'lte'
}

/**
 * 
 * @export
 * @interface FilterCollectionDto
 */
export interface FilterCollectionDto {
    /**
     * 
     * @type {number}
     * @memberof FilterCollectionDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof FilterCollectionDto
     */
    total: number;
    /**
     * 
     * @type {FilterEmbeddedCollectionDto}
     * @memberof FilterCollectionDto
     */
    _embedded: FilterEmbeddedCollectionDto;
}
/**
 * 
 * @export
 * @interface FilterConditionDto
 */
export interface FilterConditionDto {
    /**
     * 
     * @type {string}
     * @memberof FilterConditionDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FilterConditionDto
     */
    defaultValue?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterConditionDto
     */
    example?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FilterConditionDto
     */
    isActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof FilterConditionDto
     */
    expression: string;
}
/**
 * 
 * @export
 * @interface FilterDto
 */
export interface FilterDto {
    /**
     * 
     * @type {TariffFeatureFilterOptionDto}
     * @memberof FilterDto
     */
    tariffFeature?: TariffFeatureFilterOptionDto;
    /**
     * 
     * @type {string}
     * @memberof FilterDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FilterDto
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof FilterDto
     */
    description?: string;
    /**
     * 
     * @type {Array<FilterConditionDto>}
     * @memberof FilterDto
     */
    conditions?: Array<FilterConditionDto>;
    /**
     * 
     * @type {number}
     * @memberof FilterDto
     */
    rank: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDto
     */
    productIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FilterDto
     */
    type: FilterDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FilterDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterDto
     */
    updatedAt?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum FilterDtoTypeEnum {
    RadioButton = 'radio-button',
    RadioButtonMin = 'radio-button-min',
    RadioButtonMax = 'radio-button-max',
    RadioButtonMoneyMin = 'radio-button-money-min',
    RadioButtonMoneyMax = 'radio-button-money-max',
    CheckboxPeriodYearly = 'checkbox-period-yearly',
    Checkbox = 'checkbox'
}

/**
 * 
 * @export
 * @interface FilterEmbeddedCollectionDto
 */
export interface FilterEmbeddedCollectionDto {
    /**
     * 
     * @type {Array<FilterDto>}
     * @memberof FilterEmbeddedCollectionDto
     */
    filters: Array<FilterDto>;
}
/**
 * 
 * @export
 * @interface FilterOptions
 */
export interface FilterOptions {
    /**
     * 
     * @type {string}
     * @memberof FilterOptions
     */
    value: string;
    /**
     * 
     * @type {boolean}
     * @memberof FilterOptions
     */
    selected: boolean;
}
/**
 * 
 * @export
 * @interface ForbiddenExceptionDto
 */
export interface ForbiddenExceptionDto {
    /**
     * 
     * @type {object}
     * @memberof ForbiddenExceptionDto
     */
    statusCode: object;
    /**
     * 
     * @type {string}
     * @memberof ForbiddenExceptionDto
     */
    message: string;
    /**
     * 
     * @type {object}
     * @memberof ForbiddenExceptionDto
     */
    error: object;
}
/**
 * 
 * @export
 * @interface GetSetOrderRequestDto
 */
export interface GetSetOrderRequestDto {
    /**
     * 
     * @type {CustomerDto}
     * @memberof GetSetOrderRequestDto
     */
    customer: CustomerDto;
    /**
     * 
     * @type {string}
     * @memberof GetSetOrderRequestDto
     */
    frequency: string;
    /**
     * 
     * @type {string}
     * @memberof GetSetOrderRequestDto
     */
    daContent?: string;
}
/**
 * 
 * @export
 * @interface GetSetOrderResponseDto
 */
export interface GetSetOrderResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetSetOrderResponseDto
     */
    hash: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetSetOrderResponseDto
     */
    isGetOrderSuccess: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetSetOrderResponseDto
     */
    isSetOrderSuccess: boolean | null;
}
/**
 * 
 * @export
 * @interface Insurer
 */
export interface Insurer {
    /**
     * 
     * @type {string}
     * @memberof Insurer
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Insurer
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Insurer
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Insurer
     */
    tag: string;
    /**
     * 
     * @type {string}
     * @memberof Insurer
     */
    logo?: string;
}
/**
 * 
 * @export
 * @interface InsurerReasonDto
 */
export interface InsurerReasonDto {
    /**
     * 
     * @type {string}
     * @memberof InsurerReasonDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InsurerReasonDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof InsurerReasonDto
     */
    logo: string;
    /**
     * 
     * @type {boolean}
     * @memberof InsurerReasonDto
     */
    hasOffer: boolean;
    /**
     * 
     * @type {Array<Reason>}
     * @memberof InsurerReasonDto
     */
    reasons: Array<Reason>;
}
/**
 * 
 * @export
 * @interface InsurerReasonResponseDto
 */
export interface InsurerReasonResponseDto {
    /**
     * 
     * @type {number}
     * @memberof InsurerReasonResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof InsurerReasonResponseDto
     */
    total: number;
    /**
     * 
     * @type {InsurerReasonResponseEmbedded}
     * @memberof InsurerReasonResponseDto
     */
    _embedded: InsurerReasonResponseEmbedded;
}
/**
 * 
 * @export
 * @interface InsurerReasonResponseEmbedded
 */
export interface InsurerReasonResponseEmbedded {
    /**
     * 
     * @type {Array<InsurerReasonDto>}
     * @memberof InsurerReasonResponseEmbedded
     */
    insurers: Array<InsurerReasonDto>;
}
/**
 * 
 * @export
 * @interface InsurerSpecificProfession
 */
export interface InsurerSpecificProfession {
    /**
     * 
     * @type {string}
     * @memberof InsurerSpecificProfession
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InsurerSpecificProfession
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof InsurerSpecificProfession
     */
    professionAliasId?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurerSpecificProfession
     */
    professionId?: string;
}
/**
 * 
 * @export
 * @interface IsTendering
 */
export interface IsTendering {
    /**
     * 
     * @type {boolean}
     * @memberof IsTendering
     */
    isTendering: boolean;
}
/**
 * 
 * @export
 * @interface NotFoundExceptionDto
 */
export interface NotFoundExceptionDto {
    /**
     * 
     * @type {object}
     * @memberof NotFoundExceptionDto
     */
    statusCode: object;
    /**
     * 
     * @type {string}
     * @memberof NotFoundExceptionDto
     */
    message: string;
    /**
     * 
     * @type {object}
     * @memberof NotFoundExceptionDto
     */
    error: object;
}
/**
 * 
 * @export
 * @interface OfferDetailDto
 */
export interface OfferDetailDto {
    /**
     * 
     * @type {Array<FeatureGroupDto>}
     * @memberof OfferDetailDto
     */
    featureCategoryGroups: Array<FeatureGroupDto>;
    /**
     * 
     * @type {Array<DocumentWithUrl>}
     * @memberof OfferDetailDto
     */
    documents: Array<DocumentWithUrl>;
}
/**
 * 
 * @export
 * @interface OfferDisplayColumn
 */
export interface OfferDisplayColumn {
    /**
     * 
     * @type {string}
     * @memberof OfferDisplayColumn
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDisplayColumn
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDisplayColumn
     */
    featureId: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDisplayColumn
     */
    productId: string;
    /**
     * 
     * @type {boolean}
     * @memberof OfferDisplayColumn
     */
    active: boolean;
    /**
     * 
     * @type {number}
     * @memberof OfferDisplayColumn
     */
    rank: number;
}
/**
 * 
 * @export
 * @interface OfferDto
 */
export interface OfferDto {
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    type: OfferDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    status: OfferDtoStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof OfferDto
     */
    allowBooking: boolean;
    /**
     * 
     * @type {Array<Feature>}
     * @memberof OfferDto
     */
    basicFeatures: Array<Feature>;
    /**
     * 
     * @type {Tariff}
     * @memberof OfferDto
     */
    tariff: Tariff;
    /**
     * 
     * @type {Array<InsurerSpecificProfession>}
     * @memberof OfferDto
     */
    insurerSpecificProfessions: Array<InsurerSpecificProfession>;
    /**
     * 
     * @type {CalculatorDto}
     * @memberof OfferDto
     */
    calculator: CalculatorDto;
    /**
     * 
     * @type {boolean}
     * @memberof OfferDto
     */
    isSetOrder?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfferDto
     */
    isExcelCalculator?: boolean;
    /**
     * 
     * @type {Insurer}
     * @memberof OfferDto
     */
    insurer: Insurer;
    /**
     * 
     * @type {object}
     * @memberof OfferDto
     */
    salesCampaign: object;
    /**
     * 
     * @type {Array<FeatureDto>}
     * @memberof OfferDto
     */
    highlightFeatures: Array<FeatureDto>;
    /**
     * 
     * @type {OfferPriceDto}
     * @memberof OfferDto
     */
    price: OfferPriceDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof OfferDto
     */
    paymentMethods: Array<OfferDtoPaymentMethodsEnum>;
    /**
     * 
     * @type {number}
     * @memberof OfferDto
     */
    paymentInterval?: number;
    /**
     * 
     * @type {Array<Document>}
     * @memberof OfferDto
     */
    documents: Array<Document>;
    /**
     * 
     * @type {Array<Feature>}
     * @memberof OfferDto
     */
    features?: Array<Feature>;
    /**
     * 
     * @type {Array<OfferVariant>}
     * @memberof OfferDto
     */
    offerVariants?: Array<OfferVariant>;
    /**
     * 
     * @type {Array<OrderDocumentData>}
     * @memberof OfferDto
     */
    orderDocumentData?: Array<OrderDocumentData>;
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    updatedAt: string;
    /**
     * 
     * @type {Premium}
     * @memberof OfferDto
     */
    premium: Premium;
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    expiration: string;
}

/**
    * @export
    * @enum {string}
    */
export enum OfferDtoTypeEnum {
    CalculatorOffer = 'calculatorOffer',
    TenderingOffer = 'tenderingOffer'
}
/**
    * @export
    * @enum {string}
    */
export enum OfferDtoStatusEnum {
    InRelease = 'inRelease',
    Released = 'released'
}
/**
    * @export
    * @enum {string}
    */
export enum OfferDtoPaymentMethodsEnum {
    Invoice = 'invoice',
    DirectDebit = 'directDebit'
}

/**
 * 
 * @export
 * @interface OfferGroupCreatedResponseDto
 */
export interface OfferGroupCreatedResponseDto {
    /**
     * 
     * @type {string}
     * @memberof OfferGroupCreatedResponseDto
     */
    id: string;
}
/**
 * 
 * @export
 * @interface OfferGroupListResponseDto
 */
export interface OfferGroupListResponseDto {
    /**
     * 
     * @type {number}
     * @memberof OfferGroupListResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof OfferGroupListResponseDto
     */
    total: number;
    /**
     * 
     * @type {EmbeddedOffers}
     * @memberof OfferGroupListResponseDto
     */
    _embedded: EmbeddedOffers;
}
/**
 * 
 * @export
 * @interface OfferGroupRequestDto
 */
export interface OfferGroupRequestDto {
    /**
     * 
     * @type {string}
     * @memberof OfferGroupRequestDto
     */
    inquiryId: string;
    /**
     * 
     * @type {string}
     * @memberof OfferGroupRequestDto
     */
    questionAnswerSetId?: string;
    /**
     * 
     * @type {Array<QuestionAnswerSet>}
     * @memberof OfferGroupRequestDto
     */
    questionAnswerSets: Array<QuestionAnswerSet>;
    /**
     * 
     * @type {boolean}
     * @memberof OfferGroupRequestDto
     */
    calculateOffers?: boolean;
}
/**
 * 
 * @export
 * @interface OfferGroupResponseDto
 */
export interface OfferGroupResponseDto {
    /**
     * 
     * @type {string}
     * @memberof OfferGroupResponseDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OfferGroupResponseDto
     */
    inquiryHash: string;
    /**
     * 
     * @type {string}
     * @memberof OfferGroupResponseDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof OfferGroupResponseDto
     */
    inquiryId: string;
    /**
     * 
     * @type {Array<QASetMetaDto>}
     * @memberof OfferGroupResponseDto
     */
    qaSetsMetas?: Array<QASetMetaDto>;
    /**
     * 
     * @type {boolean}
     * @memberof OfferGroupResponseDto
     */
    isCalculation: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfferGroupResponseDto
     */
    isTendering: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfferGroupResponseDto
     */
    tenderingReason?: OfferGroupResponseDtoTenderingReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof OfferGroupResponseDto
     */
    status: OfferGroupResponseDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof OfferGroupResponseDto
     */
    selectedOfferId: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum OfferGroupResponseDtoTenderingReasonEnum {
    ProfessionBlocked = 'profession.blocked',
    ActiveCalculatorsNotFound = 'activeCalculators.notFound',
    CalculatorsNotFound = 'calculators.notFound',
    InsurerSpecificProfesssionMatchingNotFound = 'insurerSpecificProfesssionMatching.notFound',
    InsurerSpecificProfesssionNotMatched = 'insurerSpecificProfesssion.notMatched',
    AcceptanceCriteriaRejected = 'acceptanceCriteria.rejected',
    IqsResponseFailed = 'iqsResponse.failed',
    CalculationFailed = 'calculation.failed',
    CalculationTimeout = 'calculation.timeout'
}
/**
    * @export
    * @enum {string}
    */
export enum OfferGroupResponseDtoStatusEnum {
    InProgress = 'IN_PROGRESS',
    Done = 'DONE'
}

/**
 * 
 * @export
 * @interface OfferListConfigurationProcessingDto
 */
export interface OfferListConfigurationProcessingDto {
    /**
     * 
     * @type {string}
     * @memberof OfferListConfigurationProcessingDto
     */
    offerGroupId: string;
    /**
     * 
     * @type {boolean}
     * @memberof OfferListConfigurationProcessingDto
     */
    isCalculation: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfferListConfigurationProcessingDto
     */
    isTendering: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfferListConfigurationProcessingDto
     */
    status: OfferListConfigurationProcessingDtoStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum OfferListConfigurationProcessingDtoStatusEnum {
    InProgress = 'IN_PROGRESS',
    Done = 'DONE'
}

/**
 * 
 * @export
 * @interface OfferListConfigurationResponseDto
 */
export interface OfferListConfigurationResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof OfferListConfigurationResponseDto
     */
    hasExpiredOffers: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfferListConfigurationResponseDto
     */
    filterHash: string;
    /**
     * 
     * @type {Array<Filter>}
     * @memberof OfferListConfigurationResponseDto
     */
    filters: Array<Filter>;
    /**
     * 
     * @type {Array<DisplayColumnsDto>}
     * @memberof OfferListConfigurationResponseDto
     */
    details: Array<DisplayColumnsDto>;
    /**
     * 
     * @type {DefaultConfiguration}
     * @memberof OfferListConfigurationResponseDto
     */
    defaultConfiguration: DefaultConfiguration;
    /**
     * 
     * @type {PriceRangeDto}
     * @memberof OfferListConfigurationResponseDto
     */
    priceRange: PriceRangeDto;
    /**
     * 
     * @type {boolean}
     * @memberof OfferListConfigurationResponseDto
     */
    showBestOfferPerTariffOnly: boolean;
    /**
     * 
     * @type {Array<AppliedFilterDto>}
     * @memberof OfferListConfigurationResponseDto
     */
    appliedFilters?: Array<AppliedFilterDto>;
    /**
     * 
     * @type {Array<Sorting>}
     * @memberof OfferListConfigurationResponseDto
     */
    sortings?: Array<Sorting>;
    /**
     * 
     * @type {Sorting}
     * @memberof OfferListConfigurationResponseDto
     */
    appliedSorting?: Sorting;
    /**
     * 
     * @type {Settings}
     * @memberof OfferListConfigurationResponseDto
     */
    settings: Settings;
}
/**
 * 
 * @export
 * @interface OfferListDto
 */
export interface OfferListDto {
    /**
     * 
     * @type {number}
     * @memberof OfferListDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof OfferListDto
     */
    total: number;
    /**
     * 
     * @type {EmbeddedOfferList}
     * @memberof OfferListDto
     */
    _embedded: EmbeddedOfferList;
    /**
     * 
     * @type {number}
     * @memberof OfferListDto
     */
    totalAvailableOffersCount: number;
}
/**
 * 
 * @export
 * @interface OfferPriceDto
 */
export interface OfferPriceDto {
    /**
     * 
     * @type {number}
     * @memberof OfferPriceDto
     */
    gross: number;
    /**
     * 
     * @type {number}
     * @memberof OfferPriceDto
     */
    finalGross: number;
    /**
     * 
     * @type {number}
     * @memberof OfferPriceDto
     */
    currency: number;
    /**
     * 
     * @type {number}
     * @memberof OfferPriceDto
     */
    discount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OfferPriceDto
     */
    showDiscount: boolean;
}
/**
 * 
 * @export
 * @interface OfferSalesCampaignDto
 */
export interface OfferSalesCampaignDto {
    /**
     * 
     * @type {string}
     * @memberof OfferSalesCampaignDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferSalesCampaignDto
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferSalesCampaignDto
     */
    tooltip?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OfferSalesCampaignDto
     */
    isShowOnTop?: boolean;
}
/**
 * 
 * @export
 * @interface OfferVariant
 */
export interface OfferVariant {
    /**
     * 
     * @type {number}
     * @memberof OfferVariant
     */
    frequency: number;
    /**
     * 
     * @type {Premium}
     * @memberof OfferVariant
     */
    premium: Premium;
    /**
     * 
     * @type {Array<Feature>}
     * @memberof OfferVariant
     */
    features: Array<Feature>;
    /**
     * 
     * @type {BiproData}
     * @memberof OfferVariant
     */
    biproData?: BiproData;
}
/**
 * 
 * @export
 * @interface OrderDocumentData
 */
export interface OrderDocumentData {
    /**
     *  * POOL_ADDITIONAL_DATA = 1,  * CALCULATOR_ADDITIONAL_DATA = 2 (da),  * CALCULATOR_DOC_DATA = 3 (cd),  * PROPOSAL_DOC_DATA = 4
     * @type {number}
     * @memberof OrderDocumentData
     */
    type: OrderDocumentDataTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderDocumentData
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDocumentData
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDocumentData
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDocumentData
     */
    iconType: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum OrderDocumentDataTypeEnum {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4
}

/**
 * 
 * @export
 * @interface Params
 */
export interface Params {
    /**
     * 
     * @type {number}
     * @memberof Params
     */
    frequency: number;
    /**
     * 
     * @type {number}
     * @memberof Params
     */
    duration: number;
    /**
     * 
     * @type {number}
     * @memberof Params
     */
    deductible: number;
    /**
     * 
     * @type {number}
     * @memberof Params
     */
    coverageSum: number;
}
/**
 * 
 * @export
 * @interface Premium
 */
export interface Premium {
    /**
     * 
     * @type {number}
     * @memberof Premium
     */
    frequencyNetPremium?: number;
    /**
     * 
     * @type {number}
     * @memberof Premium
     */
    frequencyNetMinimumPremium?: number;
    /**
     * 
     * @type {number}
     * @memberof Premium
     */
    frequencyNetContractMinimumPremium?: number;
    /**
     * 
     * @type {number}
     * @memberof Premium
     */
    frequencyGrossPremium: number;
    /**
     * 
     * @type {number}
     * @memberof Premium
     */
    frequencyGrossMinimumPremium: number;
    /**
     * 
     * @type {number}
     * @memberof Premium
     */
    frequencyGrossContractMinimumPremium?: number;
    /**
     * 
     * @type {number}
     * @memberof Premium
     */
    correctedAnnualNetPremium: number;
    /**
     * 
     * @type {number}
     * @memberof Premium
     */
    correctedAnnualNetMinimumPremium?: number;
    /**
     * 
     * @type {number}
     * @memberof Premium
     */
    correctedAnnualNetContractMinimumPremium?: number;
    /**
     * 
     * @type {number}
     * @memberof Premium
     */
    correctedAnnualGrossPremium?: number;
    /**
     * 
     * @type {number}
     * @memberof Premium
     */
    correctedAnnualGrossMinimumPremium?: number;
    /**
     * 
     * @type {number}
     * @memberof Premium
     */
    correctedAnnualGrossContractMinimumPremium?: number;
    /**
     * 
     * @type {number}
     * @memberof Premium
     */
    discountedFrequencyGrossPremium?: number;
    /**
     * 
     * @type {number}
     * @memberof Premium
     */
    appliedTaxRate?: number;
}
/**
 * 
 * @export
 * @interface PremiumDto
 */
export interface PremiumDto {
    /**
     * 
     * @type {number}
     * @memberof PremiumDto
     */
    frequencyGrossPremium?: number;
    /**
     * 
     * @type {number}
     * @memberof PremiumDto
     */
    frequencyGrossMinimumPremium?: number;
    /**
     * 
     * @type {number}
     * @memberof PremiumDto
     */
    correctedAnnualNetPremium?: number;
}
/**
 * 
 * @export
 * @interface PriceRangeDto
 */
export interface PriceRangeDto {
    /**
     * 
     * @type {number}
     * @memberof PriceRangeDto
     */
    min: number;
    /**
     * 
     * @type {number}
     * @memberof PriceRangeDto
     */
    max: number;
}
/**
 * 
 * @export
 * @interface QASetMetaDto
 */
export interface QASetMetaDto {
    /**
     * 
     * @type {string}
     * @memberof QASetMetaDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QASetMetaDto
     */
    type: QASetMetaDtoTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum QASetMetaDtoTypeEnum {
    Calculator = 'calculator',
    Tendering = 'tendering'
}

/**
 * 
 * @export
 * @interface QuestionAnswerSet
 */
export interface QuestionAnswerSet {
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSet
     */
    questionId: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSet
     */
    questionType?: string;
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerSet
     */
    blockIndex?: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSet
     */
    answerId: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSet
     */
    answerText: string;
}
/**
 * 
 * @export
 * @interface Reason
 */
export interface Reason {
    /**
     * 
     * @type {string}
     * @memberof Reason
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof Reason
     */
    code: string;
}
/**
 * 
 * @export
 * @interface SalesCampaign
 */
export interface SalesCampaign {
    /**
     * 
     * @type {string}
     * @memberof SalesCampaign
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof SalesCampaign
     */
    isShowOnTop: boolean;
    /**
     * 
     * @type {string}
     * @memberof SalesCampaign
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof SalesCampaign
     */
    info: string;
    /**
     * 
     * @type {string}
     * @memberof SalesCampaign
     */
    accountId: string;
    /**
     * 
     * @type {string}
     * @memberof SalesCampaign
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof SalesCampaign
     */
    insurerId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SalesCampaign
     */
    tariffIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SalesCampaign
     */
    industryIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SalesCampaign
     */
    professionIds: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof SalesCampaign
     */
    insuranceSums: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof SalesCampaign
     */
    deductibles: Array<number>;
}
/**
 * 
 * @export
 * @interface SalesCampaignCollectionResponseDto
 */
export interface SalesCampaignCollectionResponseDto {
    /**
     * 
     * @type {number}
     * @memberof SalesCampaignCollectionResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof SalesCampaignCollectionResponseDto
     */
    total: number;
    /**
     * 
     * @type {Embedded}
     * @memberof SalesCampaignCollectionResponseDto
     */
    _embedded: Embedded;
}
/**
 * 
 * @export
 * @interface SalesCampaignDto
 */
export interface SalesCampaignDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof SalesCampaignDto
     */
    professionIds: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof SalesCampaignDto
     */
    insuranceSums: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof SalesCampaignDto
     */
    deductibles: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof SalesCampaignDto
     */
    isShowOnTop: boolean;
    /**
     * 
     * @type {string}
     * @memberof SalesCampaignDto
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof SalesCampaignDto
     */
    info: string;
    /**
     * 
     * @type {string}
     * @memberof SalesCampaignDto
     */
    accountId: string;
    /**
     * 
     * @type {string}
     * @memberof SalesCampaignDto
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof SalesCampaignDto
     */
    insurerId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SalesCampaignDto
     */
    tariffIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SalesCampaignDto
     */
    industryIds: Array<string>;
}
/**
 * 
 * @export
 * @interface Settings
 */
export interface Settings {
    /**
     * 
     * @type {boolean}
     * @memberof Settings
     */
    optionalTenderingProcess: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Settings
     */
    hideBookingDiscount: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Settings
     */
    hideTargetPremium: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Settings
     */
    isTendering: boolean;
}
/**
 * 
 * @export
 * @interface Sorting
 */
export interface Sorting {
    /**
     * 
     * @type {string}
     * @memberof Sorting
     */
    code: SortingCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof Sorting
     */
    order: SortingOrderEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SortingCodeEnum {
    Relevance = 'relevance',
    FinalGross = 'finalGross',
    Deductible = 'deductible',
    CoverageSum = 'coverageSum',
    Insurer = 'insurer'
}
/**
    * @export
    * @enum {string}
    */
export enum SortingOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}

/**
 * 
 * @export
 * @interface Tariff
 */
export interface Tariff {
    /**
     * 
     * @type {string}
     * @memberof Tariff
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Tariff
     */
    tag: string;
    /**
     * 
     * @type {string}
     * @memberof Tariff
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Tariff
     */
    calculatorId: string;
}
/**
 * 
 * @export
 * @interface TariffFeatureFilterOptionDto
 */
export interface TariffFeatureFilterOptionDto {
    /**
     * 
     * @type {string}
     * @memberof TariffFeatureFilterOptionDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TariffFeatureFilterOptionDto
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface TenderingOfferDto
 */
export interface TenderingOfferDto {
    /**
     * 
     * @type {Insurer}
     * @memberof TenderingOfferDto
     */
    insurer: Insurer;
    /**
     * 
     * @type {string}
     * @memberof TenderingOfferDto
     */
    status?: TenderingOfferDtoStatusEnum;
    /**
     * 
     * @type {Array<InsurerSpecificProfession>}
     * @memberof TenderingOfferDto
     */
    insurerSpecificProfessions: Array<InsurerSpecificProfession>;
    /**
     * 
     * @type {Tariff}
     * @memberof TenderingOfferDto
     */
    tariff: Tariff;
    /**
     * 
     * @type {Params}
     * @memberof TenderingOfferDto
     */
    params: Params;
    /**
     * 
     * @type {PremiumDto}
     * @memberof TenderingOfferDto
     */
    premium?: PremiumDto;
    /**
     * 
     * @type {OfferSalesCampaignDto}
     * @memberof TenderingOfferDto
     */
    salesCampaign?: OfferSalesCampaignDto;
    /**
     * 
     * @type {boolean}
     * @memberof TenderingOfferDto
     */
    allowBooking: boolean;
    /**
     * 
     * @type {Array<Feature>}
     * @memberof TenderingOfferDto
     */
    features: Array<Feature>;
    /**
     * 
     * @type {Array<Document>}
     * @memberof TenderingOfferDto
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenderingOfferDto
     */
    paymentMethods?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TenderingOfferDto
     */
    professionId?: string;
    /**
     * 
     * @type {Array<OrderDocumentData>}
     * @memberof TenderingOfferDto
     */
    orderDocumentData?: Array<OrderDocumentData>;
    /**
     * 
     * @type {string}
     * @memberof TenderingOfferDto
     */
    expiration?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum TenderingOfferDtoStatusEnum {
    InRelease = 'inRelease',
    Released = 'released'
}

/**
 * 
 * @export
 * @interface TenderingOfferStatusDto
 */
export interface TenderingOfferStatusDto {
    /**
     * 
     * @type {string}
     * @memberof TenderingOfferStatusDto
     */
    status: TenderingOfferStatusDtoStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TenderingOfferStatusDtoStatusEnum {
    InRelease = 'inRelease',
    Released = 'released'
}

/**
 * 
 * @export
 * @interface TenderingOffersResponseDto
 */
export interface TenderingOffersResponseDto {
    /**
     * 
     * @type {number}
     * @memberof TenderingOffersResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof TenderingOffersResponseDto
     */
    total: number;
    /**
     * 
     * @type {EmbeddedTenderingOffers}
     * @memberof TenderingOffersResponseDto
     */
    _embedded: EmbeddedTenderingOffers;
}

/**
 * AppHealthCheckApi - axios parameter creator
 * @export
 */
export const AppHealthCheckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckControllerCheckAppHealth: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/offer/v1/health-check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppHealthCheckApi - functional programming interface
 * @export
 */
export const AppHealthCheckApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppHealthCheckApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCheckControllerCheckAppHealth(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCheckControllerCheckAppHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppHealthCheckApi - factory interface
 * @export
 */
export const AppHealthCheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppHealthCheckApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckControllerCheckAppHealth(options?: any): AxiosPromise<void> {
            return localVarFp.healthCheckControllerCheckAppHealth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppHealthCheckApi - object-oriented interface
 * @export
 * @class AppHealthCheckApi
 * @extends {BaseAPI}
 */
export class AppHealthCheckApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppHealthCheckApi
     */
    public healthCheckControllerCheckAppHealth(options?: any) {
        return AppHealthCheckApiFp(this.configuration).healthCheckControllerCheckAppHealth(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ComparisonOfferApi - axios parameter creator
 * @export
 */
export const ComparisonOfferApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerAddOfferToComparison: async (id: any, offerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerGroupControllerAddOfferToComparison', 'id', id)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offerGroupControllerAddOfferToComparison', 'offerId', offerId)
            const localVarPath = `/api/offer/v1/offer-groups/{id}/comparisonOffers/{offerId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerGetOffersForComparison: async (id: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerGroupControllerGetOffersForComparison', 'id', id)
            const localVarPath = `/api/offer/v1/offer-groups/{id}/comparisonOffers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerRemoveOfferFromComparison: async (id: any, offerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerGroupControllerRemoveOfferFromComparison', 'id', id)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offerGroupControllerRemoveOfferFromComparison', 'offerId', offerId)
            const localVarPath = `/api/offer/v1/offer-groups/{id}/comparisonOffers/{offerId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerRemoveOffersFromComparison: async (id: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerGroupControllerRemoveOffersFromComparison', 'id', id)
            const localVarPath = `/api/offer/v1/offer-groups/{id}/comparisonOffers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ComparisonOfferApi - functional programming interface
 * @export
 */
export const ComparisonOfferApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ComparisonOfferApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerGroupControllerAddOfferToComparison(id: any, offerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerGroupControllerAddOfferToComparison(id, offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerGroupControllerGetOffersForComparison(id: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparisonResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerGroupControllerGetOffersForComparison(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerGroupControllerRemoveOfferFromComparison(id: any, offerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerGroupControllerRemoveOfferFromComparison(id, offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerGroupControllerRemoveOffersFromComparison(id: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerGroupControllerRemoveOffersFromComparison(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ComparisonOfferApi - factory interface
 * @export
 */
export const ComparisonOfferApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ComparisonOfferApiFp(configuration)
    return {
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerAddOfferToComparison(id: any, offerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.offerGroupControllerAddOfferToComparison(id, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerGetOffersForComparison(id: any, options?: any): AxiosPromise<ComparisonResponseDto> {
            return localVarFp.offerGroupControllerGetOffersForComparison(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerRemoveOfferFromComparison(id: any, offerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.offerGroupControllerRemoveOfferFromComparison(id, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerRemoveOffersFromComparison(id: any, options?: any): AxiosPromise<void> {
            return localVarFp.offerGroupControllerRemoveOffersFromComparison(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ComparisonOfferApi - object-oriented interface
 * @export
 * @class ComparisonOfferApi
 * @extends {BaseAPI}
 */
export class ComparisonOfferApi extends BaseAPI {
    /**
     * 
     * @param {any} id 
     * @param {string} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparisonOfferApi
     */
    public offerGroupControllerAddOfferToComparison(id: any, offerId: string, options?: any) {
        return ComparisonOfferApiFp(this.configuration).offerGroupControllerAddOfferToComparison(id, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparisonOfferApi
     */
    public offerGroupControllerGetOffersForComparison(id: any, options?: any) {
        return ComparisonOfferApiFp(this.configuration).offerGroupControllerGetOffersForComparison(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} id 
     * @param {string} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparisonOfferApi
     */
    public offerGroupControllerRemoveOfferFromComparison(id: any, offerId: string, options?: any) {
        return ComparisonOfferApiFp(this.configuration).offerGroupControllerRemoveOfferFromComparison(id, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparisonOfferApi
     */
    public offerGroupControllerRemoveOffersFromComparison(id: any, options?: any) {
        return ComparisonOfferApiFp(this.configuration).offerGroupControllerRemoveOffersFromComparison(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FilterApi - axios parameter creator
 * @export
 */
export const FilterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FilterDto} filterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerCreate: async (filterDto: FilterDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterDto' is not null or undefined
            assertParamExists('filterControllerCreate', 'filterDto', filterDto)
            const localVarPath = `/api/offer/v1/filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('filterControllerDelete', 'id', id)
            const localVarPath = `/api/offer/v1/filters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {number} [pagination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerFind: async (limit?: number, offset?: number, pagination?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/offer/v1/filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerFindById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('filterControllerFindById', 'id', id)
            const localVarPath = `/api/offer/v1/filters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {FilterDto} filterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerUpdate: async (id: string, filterDto: FilterDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('filterControllerUpdate', 'id', id)
            // verify required parameter 'filterDto' is not null or undefined
            assertParamExists('filterControllerUpdate', 'filterDto', filterDto)
            const localVarPath = `/api/offer/v1/filters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilterApi - functional programming interface
 * @export
 */
export const FilterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FilterDto} filterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterControllerCreate(filterDto: FilterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filterControllerCreate(filterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterControllerDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filterControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {number} [pagination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterControllerFind(limit?: number, offset?: number, pagination?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilterCollectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filterControllerFind(limit, offset, pagination, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterControllerFindById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filterControllerFindById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {FilterDto} filterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterControllerUpdate(id: string, filterDto: FilterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filterControllerUpdate(id, filterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FilterApi - factory interface
 * @export
 */
export const FilterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilterApiFp(configuration)
    return {
        /**
         * 
         * @param {FilterDto} filterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerCreate(filterDto: FilterDto, options?: any): AxiosPromise<FilterDto> {
            return localVarFp.filterControllerCreate(filterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.filterControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {number} [pagination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerFind(limit?: number, offset?: number, pagination?: number, options?: any): AxiosPromise<FilterCollectionDto> {
            return localVarFp.filterControllerFind(limit, offset, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerFindById(id: string, options?: any): AxiosPromise<FilterDto> {
            return localVarFp.filterControllerFindById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {FilterDto} filterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerUpdate(id: string, filterDto: FilterDto, options?: any): AxiosPromise<FilterDto> {
            return localVarFp.filterControllerUpdate(id, filterDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FilterApi - object-oriented interface
 * @export
 * @class FilterApi
 * @extends {BaseAPI}
 */
export class FilterApi extends BaseAPI {
    /**
     * 
     * @param {FilterDto} filterDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterApi
     */
    public filterControllerCreate(filterDto: FilterDto, options?: any) {
        return FilterApiFp(this.configuration).filterControllerCreate(filterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterApi
     */
    public filterControllerDelete(id: string, options?: any) {
        return FilterApiFp(this.configuration).filterControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [pagination] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterApi
     */
    public filterControllerFind(limit?: number, offset?: number, pagination?: number, options?: any) {
        return FilterApiFp(this.configuration).filterControllerFind(limit, offset, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterApi
     */
    public filterControllerFindById(id: string, options?: any) {
        return FilterApiFp(this.configuration).filterControllerFindById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {FilterDto} filterDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterApi
     */
    public filterControllerUpdate(id: string, filterDto: FilterDto, options?: any) {
        return FilterApiFp(this.configuration).filterControllerUpdate(id, filterDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OfferApi - axios parameter creator
 * @export
 */
export const OfferApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {GetSetOrderRequestDto} getSetOrderRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerCreateOrder: async (id: any, offerId: string, getSetOrderRequestDto: GetSetOrderRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerGroupControllerCreateOrder', 'id', id)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offerGroupControllerCreateOrder', 'offerId', offerId)
            // verify required parameter 'getSetOrderRequestDto' is not null or undefined
            assertParamExists('offerGroupControllerCreateOrder', 'getSetOrderRequestDto', getSetOrderRequestDto)
            const localVarPath = `/api/offer/v1/offer-groups/{id}/offers/{offerId}/orders`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getSetOrderRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerGetOfferById: async (id: any, offerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerGroupControllerGetOfferById', 'id', id)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offerGroupControllerGetOfferById', 'offerId', offerId)
            const localVarPath = `/api/offer/v1/offer-groups/{id}/offers/{offerId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerGetOfferDetails: async (id: any, offerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerGroupControllerGetOfferDetails', 'id', id)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offerGroupControllerGetOfferDetails', 'offerId', offerId)
            const localVarPath = `/api/offer/v1/offer-groups/{id}/offers/{offerId}/details`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} showBestOfferPerTariffOnly 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {number} [pagination] 
         * @param {string} [filterId] 
         * @param {string} [filterCalculatorId] 
         * @param {'inRelease' | 'released'} [filterStatus] 
         * @param {string} [filterTariffId] 
         * @param {string} [filterTariffName] 
         * @param {string} [filterTariffTag] 
         * @param {string} [filterInsurerId] 
         * @param {string} [filterInsurerName] 
         * @param {string} [filterInsurerLogo] 
         * @param {string} [filterDiscountDiscountedGrossPremium] 
         * @param {string} [filterDiscountShowDiscount] 
         * @param {string} [filterDiscountAppliedDiscount] 
         * @param {string} [filterDiscountIsDeleted] 
         * @param {string} [filterFeaturesIdValue] 
         * @param {string} [filterFeaturesIdIconType] 
         * @param {string} [filterFeaturesIdValueLtGtLteGte] 
         * @param {'asc' | 'desc'} [orderRelevance] Only one order will be applied
         * @param {'asc' | 'desc'} [orderFinalGross] Only one order will be applied
         * @param {'asc' | 'desc'} [orderDeductible] Only one order will be applied
         * @param {'asc' | 'desc'} [orderCoverageSum] Only one order will be applied
         * @param {'asc' | 'desc'} [orderInsurer] Only one order will be applied
         * @param {'resultPage' | 'insurerPortal' | 'accountPortal'} [origin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerGetOffers: async (id: string, showBestOfferPerTariffOnly: boolean, limit?: number, offset?: number, pagination?: number, filterId?: string, filterCalculatorId?: string, filterStatus?: 'inRelease' | 'released', filterTariffId?: string, filterTariffName?: string, filterTariffTag?: string, filterInsurerId?: string, filterInsurerName?: string, filterInsurerLogo?: string, filterDiscountDiscountedGrossPremium?: string, filterDiscountShowDiscount?: string, filterDiscountAppliedDiscount?: string, filterDiscountIsDeleted?: string, filterFeaturesIdValue?: string, filterFeaturesIdIconType?: string, filterFeaturesIdValueLtGtLteGte?: string, orderRelevance?: 'asc' | 'desc', orderFinalGross?: 'asc' | 'desc', orderDeductible?: 'asc' | 'desc', orderCoverageSum?: 'asc' | 'desc', orderInsurer?: 'asc' | 'desc', origin?: 'resultPage' | 'insurerPortal' | 'accountPortal', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerGroupControllerGetOffers', 'id', id)
            // verify required parameter 'showBestOfferPerTariffOnly' is not null or undefined
            assertParamExists('offerGroupControllerGetOffers', 'showBestOfferPerTariffOnly', showBestOfferPerTariffOnly)
            const localVarPath = `/api/offer/v1/offer-groups/{id}/offers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }

            if (showBestOfferPerTariffOnly !== undefined) {
                localVarQueryParameter['showBestOfferPerTariffOnly'] = showBestOfferPerTariffOnly;
            }

            if (filterId !== undefined) {
                localVarQueryParameter['filter[id]'] = filterId;
            }

            if (filterCalculatorId !== undefined) {
                localVarQueryParameter['filter[calculatorId]'] = filterCalculatorId;
            }

            if (filterStatus !== undefined) {
                localVarQueryParameter['filter[status]'] = filterStatus;
            }

            if (filterTariffId !== undefined) {
                localVarQueryParameter['filter[tariff.id]'] = filterTariffId;
            }

            if (filterTariffName !== undefined) {
                localVarQueryParameter['filter[tariff.name]'] = filterTariffName;
            }

            if (filterTariffTag !== undefined) {
                localVarQueryParameter['filter[tariff.tag]'] = filterTariffTag;
            }

            if (filterInsurerId !== undefined) {
                localVarQueryParameter['filter[insurer.id]'] = filterInsurerId;
            }

            if (filterInsurerName !== undefined) {
                localVarQueryParameter['filter[insurer.name]'] = filterInsurerName;
            }

            if (filterInsurerLogo !== undefined) {
                localVarQueryParameter['filter[insurer.logo]'] = filterInsurerLogo;
            }

            if (filterDiscountDiscountedGrossPremium !== undefined) {
                localVarQueryParameter['filter[discount.discountedGrossPremium]'] = filterDiscountDiscountedGrossPremium;
            }

            if (filterDiscountShowDiscount !== undefined) {
                localVarQueryParameter['filter[discount.showDiscount]'] = filterDiscountShowDiscount;
            }

            if (filterDiscountAppliedDiscount !== undefined) {
                localVarQueryParameter['filter[discount.appliedDiscount]'] = filterDiscountAppliedDiscount;
            }

            if (filterDiscountIsDeleted !== undefined) {
                localVarQueryParameter['filter[discount.isDeleted]'] = filterDiscountIsDeleted;
            }

            if (filterFeaturesIdValue !== undefined) {
                localVarQueryParameter['filter[features][{id}][value]'] = filterFeaturesIdValue;
            }

            if (filterFeaturesIdIconType !== undefined) {
                localVarQueryParameter['filter[features][{id}][iconType]'] = filterFeaturesIdIconType;
            }

            if (filterFeaturesIdValueLtGtLteGte !== undefined) {
                localVarQueryParameter['filter[features][{id}][value][lt|gt|lte|gte]'] = filterFeaturesIdValueLtGtLteGte;
            }

            if (orderRelevance !== undefined) {
                localVarQueryParameter['order[relevance]'] = orderRelevance;
            }

            if (orderFinalGross !== undefined) {
                localVarQueryParameter['order[finalGross]'] = orderFinalGross;
            }

            if (orderDeductible !== undefined) {
                localVarQueryParameter['order[deductible]'] = orderDeductible;
            }

            if (orderCoverageSum !== undefined) {
                localVarQueryParameter['order[coverageSum]'] = orderCoverageSum;
            }

            if (orderInsurer !== undefined) {
                localVarQueryParameter['order[insurer]'] = orderInsurer;
            }

            if (origin !== undefined) {
                localVarQueryParameter['origin'] = origin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferApi - functional programming interface
 * @export
 */
export const OfferApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfferApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {GetSetOrderRequestDto} getSetOrderRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerGroupControllerCreateOrder(id: any, offerId: string, getSetOrderRequestDto: GetSetOrderRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSetOrderResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerGroupControllerCreateOrder(id, offerId, getSetOrderRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerGroupControllerGetOfferById(id: any, offerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerGroupControllerGetOfferById(id, offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerGroupControllerGetOfferDetails(id: any, offerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerGroupControllerGetOfferDetails(id, offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} showBestOfferPerTariffOnly 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {number} [pagination] 
         * @param {string} [filterId] 
         * @param {string} [filterCalculatorId] 
         * @param {'inRelease' | 'released'} [filterStatus] 
         * @param {string} [filterTariffId] 
         * @param {string} [filterTariffName] 
         * @param {string} [filterTariffTag] 
         * @param {string} [filterInsurerId] 
         * @param {string} [filterInsurerName] 
         * @param {string} [filterInsurerLogo] 
         * @param {string} [filterDiscountDiscountedGrossPremium] 
         * @param {string} [filterDiscountShowDiscount] 
         * @param {string} [filterDiscountAppliedDiscount] 
         * @param {string} [filterDiscountIsDeleted] 
         * @param {string} [filterFeaturesIdValue] 
         * @param {string} [filterFeaturesIdIconType] 
         * @param {string} [filterFeaturesIdValueLtGtLteGte] 
         * @param {'asc' | 'desc'} [orderRelevance] Only one order will be applied
         * @param {'asc' | 'desc'} [orderFinalGross] Only one order will be applied
         * @param {'asc' | 'desc'} [orderDeductible] Only one order will be applied
         * @param {'asc' | 'desc'} [orderCoverageSum] Only one order will be applied
         * @param {'asc' | 'desc'} [orderInsurer] Only one order will be applied
         * @param {'resultPage' | 'insurerPortal' | 'accountPortal'} [origin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerGroupControllerGetOffers(id: string, showBestOfferPerTariffOnly: boolean, limit?: number, offset?: number, pagination?: number, filterId?: string, filterCalculatorId?: string, filterStatus?: 'inRelease' | 'released', filterTariffId?: string, filterTariffName?: string, filterTariffTag?: string, filterInsurerId?: string, filterInsurerName?: string, filterInsurerLogo?: string, filterDiscountDiscountedGrossPremium?: string, filterDiscountShowDiscount?: string, filterDiscountAppliedDiscount?: string, filterDiscountIsDeleted?: string, filterFeaturesIdValue?: string, filterFeaturesIdIconType?: string, filterFeaturesIdValueLtGtLteGte?: string, orderRelevance?: 'asc' | 'desc', orderFinalGross?: 'asc' | 'desc', orderDeductible?: 'asc' | 'desc', orderCoverageSum?: 'asc' | 'desc', orderInsurer?: 'asc' | 'desc', origin?: 'resultPage' | 'insurerPortal' | 'accountPortal', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerGroupControllerGetOffers(id, showBestOfferPerTariffOnly, limit, offset, pagination, filterId, filterCalculatorId, filterStatus, filterTariffId, filterTariffName, filterTariffTag, filterInsurerId, filterInsurerName, filterInsurerLogo, filterDiscountDiscountedGrossPremium, filterDiscountShowDiscount, filterDiscountAppliedDiscount, filterDiscountIsDeleted, filterFeaturesIdValue, filterFeaturesIdIconType, filterFeaturesIdValueLtGtLteGte, orderRelevance, orderFinalGross, orderDeductible, orderCoverageSum, orderInsurer, origin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfferApi - factory interface
 * @export
 */
export const OfferApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfferApiFp(configuration)
    return {
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {GetSetOrderRequestDto} getSetOrderRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerCreateOrder(id: any, offerId: string, getSetOrderRequestDto: GetSetOrderRequestDto, options?: any): AxiosPromise<GetSetOrderResponseDto> {
            return localVarFp.offerGroupControllerCreateOrder(id, offerId, getSetOrderRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerGetOfferById(id: any, offerId: string, options?: any): AxiosPromise<OfferDto> {
            return localVarFp.offerGroupControllerGetOfferById(id, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerGetOfferDetails(id: any, offerId: string, options?: any): AxiosPromise<OfferDetailDto> {
            return localVarFp.offerGroupControllerGetOfferDetails(id, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} showBestOfferPerTariffOnly 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {number} [pagination] 
         * @param {string} [filterId] 
         * @param {string} [filterCalculatorId] 
         * @param {'inRelease' | 'released'} [filterStatus] 
         * @param {string} [filterTariffId] 
         * @param {string} [filterTariffName] 
         * @param {string} [filterTariffTag] 
         * @param {string} [filterInsurerId] 
         * @param {string} [filterInsurerName] 
         * @param {string} [filterInsurerLogo] 
         * @param {string} [filterDiscountDiscountedGrossPremium] 
         * @param {string} [filterDiscountShowDiscount] 
         * @param {string} [filterDiscountAppliedDiscount] 
         * @param {string} [filterDiscountIsDeleted] 
         * @param {string} [filterFeaturesIdValue] 
         * @param {string} [filterFeaturesIdIconType] 
         * @param {string} [filterFeaturesIdValueLtGtLteGte] 
         * @param {'asc' | 'desc'} [orderRelevance] Only one order will be applied
         * @param {'asc' | 'desc'} [orderFinalGross] Only one order will be applied
         * @param {'asc' | 'desc'} [orderDeductible] Only one order will be applied
         * @param {'asc' | 'desc'} [orderCoverageSum] Only one order will be applied
         * @param {'asc' | 'desc'} [orderInsurer] Only one order will be applied
         * @param {'resultPage' | 'insurerPortal' | 'accountPortal'} [origin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerGetOffers(id: string, showBestOfferPerTariffOnly: boolean, limit?: number, offset?: number, pagination?: number, filterId?: string, filterCalculatorId?: string, filterStatus?: 'inRelease' | 'released', filterTariffId?: string, filterTariffName?: string, filterTariffTag?: string, filterInsurerId?: string, filterInsurerName?: string, filterInsurerLogo?: string, filterDiscountDiscountedGrossPremium?: string, filterDiscountShowDiscount?: string, filterDiscountAppliedDiscount?: string, filterDiscountIsDeleted?: string, filterFeaturesIdValue?: string, filterFeaturesIdIconType?: string, filterFeaturesIdValueLtGtLteGte?: string, orderRelevance?: 'asc' | 'desc', orderFinalGross?: 'asc' | 'desc', orderDeductible?: 'asc' | 'desc', orderCoverageSum?: 'asc' | 'desc', orderInsurer?: 'asc' | 'desc', origin?: 'resultPage' | 'insurerPortal' | 'accountPortal', options?: any): AxiosPromise<OfferListDto> {
            return localVarFp.offerGroupControllerGetOffers(id, showBestOfferPerTariffOnly, limit, offset, pagination, filterId, filterCalculatorId, filterStatus, filterTariffId, filterTariffName, filterTariffTag, filterInsurerId, filterInsurerName, filterInsurerLogo, filterDiscountDiscountedGrossPremium, filterDiscountShowDiscount, filterDiscountAppliedDiscount, filterDiscountIsDeleted, filterFeaturesIdValue, filterFeaturesIdIconType, filterFeaturesIdValueLtGtLteGte, orderRelevance, orderFinalGross, orderDeductible, orderCoverageSum, orderInsurer, origin, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfferApi - object-oriented interface
 * @export
 * @class OfferApi
 * @extends {BaseAPI}
 */
export class OfferApi extends BaseAPI {
    /**
     * 
     * @param {any} id 
     * @param {string} offerId 
     * @param {GetSetOrderRequestDto} getSetOrderRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerGroupControllerCreateOrder(id: any, offerId: string, getSetOrderRequestDto: GetSetOrderRequestDto, options?: any) {
        return OfferApiFp(this.configuration).offerGroupControllerCreateOrder(id, offerId, getSetOrderRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} id 
     * @param {string} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerGroupControllerGetOfferById(id: any, offerId: string, options?: any) {
        return OfferApiFp(this.configuration).offerGroupControllerGetOfferById(id, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} id 
     * @param {string} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerGroupControllerGetOfferDetails(id: any, offerId: string, options?: any) {
        return OfferApiFp(this.configuration).offerGroupControllerGetOfferDetails(id, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {boolean} showBestOfferPerTariffOnly 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [pagination] 
     * @param {string} [filterId] 
     * @param {string} [filterCalculatorId] 
     * @param {'inRelease' | 'released'} [filterStatus] 
     * @param {string} [filterTariffId] 
     * @param {string} [filterTariffName] 
     * @param {string} [filterTariffTag] 
     * @param {string} [filterInsurerId] 
     * @param {string} [filterInsurerName] 
     * @param {string} [filterInsurerLogo] 
     * @param {string} [filterDiscountDiscountedGrossPremium] 
     * @param {string} [filterDiscountShowDiscount] 
     * @param {string} [filterDiscountAppliedDiscount] 
     * @param {string} [filterDiscountIsDeleted] 
     * @param {string} [filterFeaturesIdValue] 
     * @param {string} [filterFeaturesIdIconType] 
     * @param {string} [filterFeaturesIdValueLtGtLteGte] 
     * @param {'asc' | 'desc'} [orderRelevance] Only one order will be applied
     * @param {'asc' | 'desc'} [orderFinalGross] Only one order will be applied
     * @param {'asc' | 'desc'} [orderDeductible] Only one order will be applied
     * @param {'asc' | 'desc'} [orderCoverageSum] Only one order will be applied
     * @param {'asc' | 'desc'} [orderInsurer] Only one order will be applied
     * @param {'resultPage' | 'insurerPortal' | 'accountPortal'} [origin] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerGroupControllerGetOffers(id: string, showBestOfferPerTariffOnly: boolean, limit?: number, offset?: number, pagination?: number, filterId?: string, filterCalculatorId?: string, filterStatus?: 'inRelease' | 'released', filterTariffId?: string, filterTariffName?: string, filterTariffTag?: string, filterInsurerId?: string, filterInsurerName?: string, filterInsurerLogo?: string, filterDiscountDiscountedGrossPremium?: string, filterDiscountShowDiscount?: string, filterDiscountAppliedDiscount?: string, filterDiscountIsDeleted?: string, filterFeaturesIdValue?: string, filterFeaturesIdIconType?: string, filterFeaturesIdValueLtGtLteGte?: string, orderRelevance?: 'asc' | 'desc', orderFinalGross?: 'asc' | 'desc', orderDeductible?: 'asc' | 'desc', orderCoverageSum?: 'asc' | 'desc', orderInsurer?: 'asc' | 'desc', origin?: 'resultPage' | 'insurerPortal' | 'accountPortal', options?: any) {
        return OfferApiFp(this.configuration).offerGroupControllerGetOffers(id, showBestOfferPerTariffOnly, limit, offset, pagination, filterId, filterCalculatorId, filterStatus, filterTariffId, filterTariffName, filterTariffTag, filterInsurerId, filterInsurerName, filterInsurerLogo, filterDiscountDiscountedGrossPremium, filterDiscountShowDiscount, filterDiscountAppliedDiscount, filterDiscountIsDeleted, filterFeaturesIdValue, filterFeaturesIdIconType, filterFeaturesIdValueLtGtLteGte, orderRelevance, orderFinalGross, orderDeductible, orderCoverageSum, orderInsurer, origin, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OfferDiscountApi - axios parameter creator
 * @export
 */
export const OfferDiscountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {DiscountDto} discountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerAddDiscount: async (id: any, offerId: string, discountDto: DiscountDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerGroupControllerAddDiscount', 'id', id)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offerGroupControllerAddDiscount', 'offerId', offerId)
            // verify required parameter 'discountDto' is not null or undefined
            assertParamExists('offerGroupControllerAddDiscount', 'discountDto', discountDto)
            const localVarPath = `/api/offer/v1/offer-groups/{id}/offers/{offerId}/discounts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(discountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerDeleteDiscount: async (id: any, offerId: string, type: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerGroupControllerDeleteDiscount', 'id', id)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offerGroupControllerDeleteDiscount', 'offerId', offerId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('offerGroupControllerDeleteDiscount', 'type', type)
            const localVarPath = `/api/offer/v1/offer-groups/{id}/offers/{offerId}/discounts/{type}/type`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerGetOfferDiscount: async (id: any, offerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerGroupControllerGetOfferDiscount', 'id', id)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offerGroupControllerGetOfferDiscount', 'offerId', offerId)
            const localVarPath = `/api/offer/v1/offer-groups/{id}/offers/{offerId}/discounts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {AppliedDiscountDto} appliedDiscountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerUpdateAppliedDiscount: async (id: any, offerId: string, appliedDiscountDto: AppliedDiscountDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerGroupControllerUpdateAppliedDiscount', 'id', id)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offerGroupControllerUpdateAppliedDiscount', 'offerId', offerId)
            // verify required parameter 'appliedDiscountDto' is not null or undefined
            assertParamExists('offerGroupControllerUpdateAppliedDiscount', 'appliedDiscountDto', appliedDiscountDto)
            const localVarPath = `/api/offer/v1/offer-groups/{id}/offers/{offerId}/discounts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appliedDiscountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferDiscountApi - functional programming interface
 * @export
 */
export const OfferDiscountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfferDiscountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {DiscountDto} discountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerGroupControllerAddDiscount(id: any, offerId: string, discountDto: DiscountDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Discount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerGroupControllerAddDiscount(id, offerId, discountDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerGroupControllerDeleteDiscount(id: any, offerId: string, type: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerGroupControllerDeleteDiscount(id, offerId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerGroupControllerGetOfferDiscount(id: any, offerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Discount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerGroupControllerGetOfferDiscount(id, offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {AppliedDiscountDto} appliedDiscountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerGroupControllerUpdateAppliedDiscount(id: any, offerId: string, appliedDiscountDto: AppliedDiscountDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Discount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerGroupControllerUpdateAppliedDiscount(id, offerId, appliedDiscountDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfferDiscountApi - factory interface
 * @export
 */
export const OfferDiscountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfferDiscountApiFp(configuration)
    return {
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {DiscountDto} discountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerAddDiscount(id: any, offerId: string, discountDto: DiscountDto, options?: any): AxiosPromise<Discount> {
            return localVarFp.offerGroupControllerAddDiscount(id, offerId, discountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerDeleteDiscount(id: any, offerId: string, type: string, options?: any): AxiosPromise<void> {
            return localVarFp.offerGroupControllerDeleteDiscount(id, offerId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerGetOfferDiscount(id: any, offerId: string, options?: any): AxiosPromise<Discount> {
            return localVarFp.offerGroupControllerGetOfferDiscount(id, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {AppliedDiscountDto} appliedDiscountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerUpdateAppliedDiscount(id: any, offerId: string, appliedDiscountDto: AppliedDiscountDto, options?: any): AxiosPromise<Discount> {
            return localVarFp.offerGroupControllerUpdateAppliedDiscount(id, offerId, appliedDiscountDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfferDiscountApi - object-oriented interface
 * @export
 * @class OfferDiscountApi
 * @extends {BaseAPI}
 */
export class OfferDiscountApi extends BaseAPI {
    /**
     * 
     * @param {any} id 
     * @param {string} offerId 
     * @param {DiscountDto} discountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferDiscountApi
     */
    public offerGroupControllerAddDiscount(id: any, offerId: string, discountDto: DiscountDto, options?: any) {
        return OfferDiscountApiFp(this.configuration).offerGroupControllerAddDiscount(id, offerId, discountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} id 
     * @param {string} offerId 
     * @param {string} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferDiscountApi
     */
    public offerGroupControllerDeleteDiscount(id: any, offerId: string, type: string, options?: any) {
        return OfferDiscountApiFp(this.configuration).offerGroupControllerDeleteDiscount(id, offerId, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} id 
     * @param {string} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferDiscountApi
     */
    public offerGroupControllerGetOfferDiscount(id: any, offerId: string, options?: any) {
        return OfferDiscountApiFp(this.configuration).offerGroupControllerGetOfferDiscount(id, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} id 
     * @param {string} offerId 
     * @param {AppliedDiscountDto} appliedDiscountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferDiscountApi
     */
    public offerGroupControllerUpdateAppliedDiscount(id: any, offerId: string, appliedDiscountDto: AppliedDiscountDto, options?: any) {
        return OfferDiscountApiFp(this.configuration).offerGroupControllerUpdateAppliedDiscount(id, offerId, appliedDiscountDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OfferDisplayColumnApi - axios parameter creator
 * @export
 */
export const OfferDisplayColumnApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ColumnDto} columnDto Offer Display Column Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerDisplayColumnControllerCreateColumn: async (columnDto: ColumnDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'columnDto' is not null or undefined
            assertParamExists('offerDisplayColumnControllerCreateColumn', 'columnDto', columnDto)
            const localVarPath = `/api/offer/v1/offer-display-columns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(columnDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerDisplayColumnControllerDeleteColumn: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerDisplayColumnControllerDeleteColumn', 'id', id)
            const localVarPath = `/api/offer/v1/offer-display-columns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerDisplayColumnControllerGetColumn: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerDisplayColumnControllerGetColumn', 'id', id)
            const localVarPath = `/api/offer/v1/offer-display-columns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {number} [pagination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerDisplayColumnControllerGetColumns: async (productId: string, limit?: number, offset?: number, pagination?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('offerDisplayColumnControllerGetColumns', 'productId', productId)
            const localVarPath = `/api/offer/v1/offer-display-columns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }

            if (productId !== undefined) {
                localVarQueryParameter['product_id'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ColumnDto} columnDto Offer Display Column Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerDisplayColumnControllerUpdateColumn: async (id: string, columnDto: ColumnDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerDisplayColumnControllerUpdateColumn', 'id', id)
            // verify required parameter 'columnDto' is not null or undefined
            assertParamExists('offerDisplayColumnControllerUpdateColumn', 'columnDto', columnDto)
            const localVarPath = `/api/offer/v1/offer-display-columns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(columnDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferDisplayColumnApi - functional programming interface
 * @export
 */
export const OfferDisplayColumnApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfferDisplayColumnApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ColumnDto} columnDto Offer Display Column Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerDisplayColumnControllerCreateColumn(columnDto: ColumnDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferDisplayColumn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerDisplayColumnControllerCreateColumn(columnDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerDisplayColumnControllerDeleteColumn(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerDisplayColumnControllerDeleteColumn(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerDisplayColumnControllerGetColumn(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferDisplayColumn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerDisplayColumnControllerGetColumn(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {number} [pagination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerDisplayColumnControllerGetColumns(productId: string, limit?: number, offset?: number, pagination?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ColumnCollectionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerDisplayColumnControllerGetColumns(productId, limit, offset, pagination, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ColumnDto} columnDto Offer Display Column Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerDisplayColumnControllerUpdateColumn(id: string, columnDto: ColumnDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferDisplayColumn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerDisplayColumnControllerUpdateColumn(id, columnDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfferDisplayColumnApi - factory interface
 * @export
 */
export const OfferDisplayColumnApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfferDisplayColumnApiFp(configuration)
    return {
        /**
         * 
         * @param {ColumnDto} columnDto Offer Display Column Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerDisplayColumnControllerCreateColumn(columnDto: ColumnDto, options?: any): AxiosPromise<OfferDisplayColumn> {
            return localVarFp.offerDisplayColumnControllerCreateColumn(columnDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerDisplayColumnControllerDeleteColumn(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.offerDisplayColumnControllerDeleteColumn(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerDisplayColumnControllerGetColumn(id: string, options?: any): AxiosPromise<OfferDisplayColumn> {
            return localVarFp.offerDisplayColumnControllerGetColumn(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {number} [pagination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerDisplayColumnControllerGetColumns(productId: string, limit?: number, offset?: number, pagination?: number, options?: any): AxiosPromise<ColumnCollectionResponseDto> {
            return localVarFp.offerDisplayColumnControllerGetColumns(productId, limit, offset, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ColumnDto} columnDto Offer Display Column Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerDisplayColumnControllerUpdateColumn(id: string, columnDto: ColumnDto, options?: any): AxiosPromise<OfferDisplayColumn> {
            return localVarFp.offerDisplayColumnControllerUpdateColumn(id, columnDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfferDisplayColumnApi - object-oriented interface
 * @export
 * @class OfferDisplayColumnApi
 * @extends {BaseAPI}
 */
export class OfferDisplayColumnApi extends BaseAPI {
    /**
     * 
     * @param {ColumnDto} columnDto Offer Display Column Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferDisplayColumnApi
     */
    public offerDisplayColumnControllerCreateColumn(columnDto: ColumnDto, options?: any) {
        return OfferDisplayColumnApiFp(this.configuration).offerDisplayColumnControllerCreateColumn(columnDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferDisplayColumnApi
     */
    public offerDisplayColumnControllerDeleteColumn(id: string, options?: any) {
        return OfferDisplayColumnApiFp(this.configuration).offerDisplayColumnControllerDeleteColumn(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferDisplayColumnApi
     */
    public offerDisplayColumnControllerGetColumn(id: string, options?: any) {
        return OfferDisplayColumnApiFp(this.configuration).offerDisplayColumnControllerGetColumn(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [pagination] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferDisplayColumnApi
     */
    public offerDisplayColumnControllerGetColumns(productId: string, limit?: number, offset?: number, pagination?: number, options?: any) {
        return OfferDisplayColumnApiFp(this.configuration).offerDisplayColumnControllerGetColumns(productId, limit, offset, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ColumnDto} columnDto Offer Display Column Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferDisplayColumnApi
     */
    public offerDisplayColumnControllerUpdateColumn(id: string, columnDto: ColumnDto, options?: any) {
        return OfferDisplayColumnApiFp(this.configuration).offerDisplayColumnControllerUpdateColumn(id, columnDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OfferGroupApi - axios parameter creator
 * @export
 */
export const OfferGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {OfferGroupRequestDto} offerGroupRequestDto Inquiry Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerCreateOfferGroup: async (offerGroupRequestDto: OfferGroupRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerGroupRequestDto' is not null or undefined
            assertParamExists('offerGroupControllerCreateOfferGroup', 'offerGroupRequestDto', offerGroupRequestDto)
            const localVarPath = `/api/offer/v1/offer-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offerGroupRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerGetInsurers: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerGroupControllerGetInsurers', 'id', id)
            const localVarPath = `/api/offer/v1/offer-groups/{id}/insurers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerGetOfferGroup: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerGroupControllerGetOfferGroup', 'id', id)
            const localVarPath = `/api/offer/v1/offer-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} [offset] 
         * @param {string} [inquiryHash] 
         * @param {string} [inquiryId] 
         * @param {'asc' | 'desc'} [orderCreatedAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerGetOfferGroups: async (limit: number, offset?: number, inquiryHash?: string, inquiryId?: string, orderCreatedAt?: 'asc' | 'desc', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('offerGroupControllerGetOfferGroups', 'limit', limit)
            const localVarPath = `/api/offer/v1/offer-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (inquiryHash !== undefined) {
                localVarQueryParameter['inquiryHash'] = inquiryHash;
            }

            if (inquiryId !== undefined) {
                localVarQueryParameter['inquiryId'] = inquiryId;
            }

            if (orderCreatedAt !== undefined) {
                localVarQueryParameter['order[createdAt]'] = orderCreatedAt;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} id 
         * @param {IsTendering} isTendering 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerSetIsTendering: async (id: any, isTendering: IsTendering, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerGroupControllerSetIsTendering', 'id', id)
            // verify required parameter 'isTendering' is not null or undefined
            assertParamExists('offerGroupControllerSetIsTendering', 'isTendering', isTendering)
            const localVarPath = `/api/offer/v1/offer-groups/{id}/isTendering`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isTendering, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferGroupApi - functional programming interface
 * @export
 */
export const OfferGroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfferGroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {OfferGroupRequestDto} offerGroupRequestDto Inquiry Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerGroupControllerCreateOfferGroup(offerGroupRequestDto: OfferGroupRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferGroupCreatedResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerGroupControllerCreateOfferGroup(offerGroupRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerGroupControllerGetInsurers(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsurerReasonResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerGroupControllerGetInsurers(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerGroupControllerGetOfferGroup(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferGroupResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerGroupControllerGetOfferGroup(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} [offset] 
         * @param {string} [inquiryHash] 
         * @param {string} [inquiryId] 
         * @param {'asc' | 'desc'} [orderCreatedAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerGroupControllerGetOfferGroups(limit: number, offset?: number, inquiryHash?: string, inquiryId?: string, orderCreatedAt?: 'asc' | 'desc', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferGroupListResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerGroupControllerGetOfferGroups(limit, offset, inquiryHash, inquiryId, orderCreatedAt, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} id 
         * @param {IsTendering} isTendering 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerGroupControllerSetIsTendering(id: any, isTendering: IsTendering, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerGroupControllerSetIsTendering(id, isTendering, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfferGroupApi - factory interface
 * @export
 */
export const OfferGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfferGroupApiFp(configuration)
    return {
        /**
         * 
         * @param {OfferGroupRequestDto} offerGroupRequestDto Inquiry Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerCreateOfferGroup(offerGroupRequestDto: OfferGroupRequestDto, options?: any): AxiosPromise<OfferGroupCreatedResponseDto> {
            return localVarFp.offerGroupControllerCreateOfferGroup(offerGroupRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerGetInsurers(id: string, options?: any): AxiosPromise<InsurerReasonResponseDto> {
            return localVarFp.offerGroupControllerGetInsurers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerGetOfferGroup(id: string, options?: any): AxiosPromise<OfferGroupResponseDto> {
            return localVarFp.offerGroupControllerGetOfferGroup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} [offset] 
         * @param {string} [inquiryHash] 
         * @param {string} [inquiryId] 
         * @param {'asc' | 'desc'} [orderCreatedAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerGetOfferGroups(limit: number, offset?: number, inquiryHash?: string, inquiryId?: string, orderCreatedAt?: 'asc' | 'desc', options?: any): AxiosPromise<OfferGroupListResponseDto> {
            return localVarFp.offerGroupControllerGetOfferGroups(limit, offset, inquiryHash, inquiryId, orderCreatedAt, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} id 
         * @param {IsTendering} isTendering 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerSetIsTendering(id: any, isTendering: IsTendering, options?: any): AxiosPromise<void> {
            return localVarFp.offerGroupControllerSetIsTendering(id, isTendering, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfferGroupApi - object-oriented interface
 * @export
 * @class OfferGroupApi
 * @extends {BaseAPI}
 */
export class OfferGroupApi extends BaseAPI {
    /**
     * 
     * @param {OfferGroupRequestDto} offerGroupRequestDto Inquiry Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferGroupApi
     */
    public offerGroupControllerCreateOfferGroup(offerGroupRequestDto: OfferGroupRequestDto, options?: any) {
        return OfferGroupApiFp(this.configuration).offerGroupControllerCreateOfferGroup(offerGroupRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferGroupApi
     */
    public offerGroupControllerGetInsurers(id: string, options?: any) {
        return OfferGroupApiFp(this.configuration).offerGroupControllerGetInsurers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferGroupApi
     */
    public offerGroupControllerGetOfferGroup(id: string, options?: any) {
        return OfferGroupApiFp(this.configuration).offerGroupControllerGetOfferGroup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {number} [offset] 
     * @param {string} [inquiryHash] 
     * @param {string} [inquiryId] 
     * @param {'asc' | 'desc'} [orderCreatedAt] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferGroupApi
     */
    public offerGroupControllerGetOfferGroups(limit: number, offset?: number, inquiryHash?: string, inquiryId?: string, orderCreatedAt?: 'asc' | 'desc', options?: any) {
        return OfferGroupApiFp(this.configuration).offerGroupControllerGetOfferGroups(limit, offset, inquiryHash, inquiryId, orderCreatedAt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} id 
     * @param {IsTendering} isTendering 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferGroupApi
     */
    public offerGroupControllerSetIsTendering(id: any, isTendering: IsTendering, options?: any) {
        return OfferGroupApiFp(this.configuration).offerGroupControllerSetIsTendering(id, isTendering, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OfferListConfigurationApi - axios parameter creator
 * @export
 */
export const OfferListConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} offerGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerListConfigurationControllerGetOfferListConfiguration: async (offerGroupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerGroupId' is not null or undefined
            assertParamExists('offerListConfigurationControllerGetOfferListConfiguration', 'offerGroupId', offerGroupId)
            const localVarPath = `/api/offer/v1/offer-groups/{offerGroupId}/configuration`
                .replace(`{${"offerGroupId"}}`, encodeURIComponent(String(offerGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferListConfigurationApi - functional programming interface
 * @export
 */
export const OfferListConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfferListConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} offerGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerListConfigurationControllerGetOfferListConfiguration(offerGroupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferListConfigurationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerListConfigurationControllerGetOfferListConfiguration(offerGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfferListConfigurationApi - factory interface
 * @export
 */
export const OfferListConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfferListConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} offerGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerListConfigurationControllerGetOfferListConfiguration(offerGroupId: string, options?: any): AxiosPromise<OfferListConfigurationResponseDto> {
            return localVarFp.offerListConfigurationControllerGetOfferListConfiguration(offerGroupId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfferListConfigurationApi - object-oriented interface
 * @export
 * @class OfferListConfigurationApi
 * @extends {BaseAPI}
 */
export class OfferListConfigurationApi extends BaseAPI {
    /**
     * 
     * @param {string} offerGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferListConfigurationApi
     */
    public offerListConfigurationControllerGetOfferListConfiguration(offerGroupId: string, options?: any) {
        return OfferListConfigurationApiFp(this.configuration).offerListConfigurationControllerGetOfferListConfiguration(offerGroupId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SalesCampaignApi - axios parameter creator
 * @export
 */
export const SalesCampaignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SalesCampaignDto} salesCampaignDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesCampaignControllerCreateSalesCampaign: async (salesCampaignDto: SalesCampaignDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'salesCampaignDto' is not null or undefined
            assertParamExists('salesCampaignControllerCreateSalesCampaign', 'salesCampaignDto', salesCampaignDto)
            const localVarPath = `/api/offer/v1/sales-campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(salesCampaignDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesCampaignControllerDeleteSalesCampaign: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('salesCampaignControllerDeleteSalesCampaign', 'id', id)
            const localVarPath = `/api/offer/v1/sales-campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesCampaignControllerGetSalesCampaign: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('salesCampaignControllerGetSalesCampaign', 'id', id)
            const localVarPath = `/api/offer/v1/sales-campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {number} [pagination] 
         * @param {string} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesCampaignControllerGetSalesCampaigns: async (limit?: number, offset?: number, pagination?: number, accountId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/offer/v1/sales-campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['account_id'] = accountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {SalesCampaignDto} salesCampaignDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesCampaignControllerUpdateSalesCampaign: async (id: string, salesCampaignDto: SalesCampaignDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('salesCampaignControllerUpdateSalesCampaign', 'id', id)
            // verify required parameter 'salesCampaignDto' is not null or undefined
            assertParamExists('salesCampaignControllerUpdateSalesCampaign', 'salesCampaignDto', salesCampaignDto)
            const localVarPath = `/api/offer/v1/sales-campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(salesCampaignDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SalesCampaignApi - functional programming interface
 * @export
 */
export const SalesCampaignApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SalesCampaignApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SalesCampaignDto} salesCampaignDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salesCampaignControllerCreateSalesCampaign(salesCampaignDto: SalesCampaignDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesCampaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salesCampaignControllerCreateSalesCampaign(salesCampaignDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salesCampaignControllerDeleteSalesCampaign(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salesCampaignControllerDeleteSalesCampaign(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salesCampaignControllerGetSalesCampaign(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesCampaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salesCampaignControllerGetSalesCampaign(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {number} [pagination] 
         * @param {string} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salesCampaignControllerGetSalesCampaigns(limit?: number, offset?: number, pagination?: number, accountId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesCampaignCollectionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salesCampaignControllerGetSalesCampaigns(limit, offset, pagination, accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {SalesCampaignDto} salesCampaignDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salesCampaignControllerUpdateSalesCampaign(id: string, salesCampaignDto: SalesCampaignDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesCampaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salesCampaignControllerUpdateSalesCampaign(id, salesCampaignDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SalesCampaignApi - factory interface
 * @export
 */
export const SalesCampaignApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SalesCampaignApiFp(configuration)
    return {
        /**
         * 
         * @param {SalesCampaignDto} salesCampaignDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesCampaignControllerCreateSalesCampaign(salesCampaignDto: SalesCampaignDto, options?: any): AxiosPromise<SalesCampaign> {
            return localVarFp.salesCampaignControllerCreateSalesCampaign(salesCampaignDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesCampaignControllerDeleteSalesCampaign(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.salesCampaignControllerDeleteSalesCampaign(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesCampaignControllerGetSalesCampaign(id: string, options?: any): AxiosPromise<SalesCampaign> {
            return localVarFp.salesCampaignControllerGetSalesCampaign(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {number} [pagination] 
         * @param {string} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesCampaignControllerGetSalesCampaigns(limit?: number, offset?: number, pagination?: number, accountId?: string, options?: any): AxiosPromise<SalesCampaignCollectionResponseDto> {
            return localVarFp.salesCampaignControllerGetSalesCampaigns(limit, offset, pagination, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {SalesCampaignDto} salesCampaignDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesCampaignControllerUpdateSalesCampaign(id: string, salesCampaignDto: SalesCampaignDto, options?: any): AxiosPromise<SalesCampaign> {
            return localVarFp.salesCampaignControllerUpdateSalesCampaign(id, salesCampaignDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SalesCampaignApi - object-oriented interface
 * @export
 * @class SalesCampaignApi
 * @extends {BaseAPI}
 */
export class SalesCampaignApi extends BaseAPI {
    /**
     * 
     * @param {SalesCampaignDto} salesCampaignDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesCampaignApi
     */
    public salesCampaignControllerCreateSalesCampaign(salesCampaignDto: SalesCampaignDto, options?: any) {
        return SalesCampaignApiFp(this.configuration).salesCampaignControllerCreateSalesCampaign(salesCampaignDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesCampaignApi
     */
    public salesCampaignControllerDeleteSalesCampaign(id: string, options?: any) {
        return SalesCampaignApiFp(this.configuration).salesCampaignControllerDeleteSalesCampaign(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesCampaignApi
     */
    public salesCampaignControllerGetSalesCampaign(id: string, options?: any) {
        return SalesCampaignApiFp(this.configuration).salesCampaignControllerGetSalesCampaign(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [pagination] 
     * @param {string} [accountId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesCampaignApi
     */
    public salesCampaignControllerGetSalesCampaigns(limit?: number, offset?: number, pagination?: number, accountId?: string, options?: any) {
        return SalesCampaignApiFp(this.configuration).salesCampaignControllerGetSalesCampaigns(limit, offset, pagination, accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {SalesCampaignDto} salesCampaignDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesCampaignApi
     */
    public salesCampaignControllerUpdateSalesCampaign(id: string, salesCampaignDto: SalesCampaignDto, options?: any) {
        return SalesCampaignApiFp(this.configuration).salesCampaignControllerUpdateSalesCampaign(id, salesCampaignDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SelectedOfferApi - axios parameter creator
 * @export
 */
export const SelectedOfferApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {any} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerGetSelectedOffers: async (id: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerGroupControllerGetSelectedOffers', 'id', id)
            const localVarPath = `/api/offer/v1/offer-groups/{id}/selectedOffers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} id 
         * @param {any} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerSetSelectedOffers: async (id: any, offerId: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerGroupControllerSetSelectedOffers', 'id', id)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offerGroupControllerSetSelectedOffers', 'offerId', offerId)
            const localVarPath = `/api/offer/v1/offer-groups/{id}/selectedOffers/{offerId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SelectedOfferApi - functional programming interface
 * @export
 */
export const SelectedOfferApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SelectedOfferApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {any} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerGroupControllerGetSelectedOffers(id: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerGroupControllerGetSelectedOffers(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} id 
         * @param {any} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerGroupControllerSetSelectedOffers(id: any, offerId: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerGroupControllerSetSelectedOffers(id, offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SelectedOfferApi - factory interface
 * @export
 */
export const SelectedOfferApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SelectedOfferApiFp(configuration)
    return {
        /**
         * 
         * @param {any} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerGetSelectedOffers(id: any, options?: any): AxiosPromise<OfferDto> {
            return localVarFp.offerGroupControllerGetSelectedOffers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} id 
         * @param {any} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerSetSelectedOffers(id: any, offerId: any, options?: any): AxiosPromise<void> {
            return localVarFp.offerGroupControllerSetSelectedOffers(id, offerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SelectedOfferApi - object-oriented interface
 * @export
 * @class SelectedOfferApi
 * @extends {BaseAPI}
 */
export class SelectedOfferApi extends BaseAPI {
    /**
     * 
     * @param {any} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelectedOfferApi
     */
    public offerGroupControllerGetSelectedOffers(id: any, options?: any) {
        return SelectedOfferApiFp(this.configuration).offerGroupControllerGetSelectedOffers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} id 
     * @param {any} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelectedOfferApi
     */
    public offerGroupControllerSetSelectedOffers(id: any, offerId: any, options?: any) {
        return SelectedOfferApiFp(this.configuration).offerGroupControllerSetSelectedOffers(id, offerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TenderingOfferApi - axios parameter creator
 * @export
 */
export const TenderingOfferApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {any} id 
         * @param {TenderingOfferDto} tenderingOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerCreateTenderingOffer: async (id: any, tenderingOfferDto: TenderingOfferDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerGroupControllerCreateTenderingOffer', 'id', id)
            // verify required parameter 'tenderingOfferDto' is not null or undefined
            assertParamExists('offerGroupControllerCreateTenderingOffer', 'tenderingOfferDto', tenderingOfferDto)
            const localVarPath = `/api/offer/v1/offer-groups/{id}/tenderingOffers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenderingOfferDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerGetTenderingOffer: async (id: any, offerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerGroupControllerGetTenderingOffer', 'id', id)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offerGroupControllerGetTenderingOffer', 'offerId', offerId)
            const localVarPath = `/api/offer/v1/offer-groups/{id}/tenderingOffers/{offerId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} id 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {number} [pagination] 
         * @param {'inRelease' | 'released'} [filterStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerGetTenderingOffers: async (id: any, limit?: number, offset?: number, pagination?: number, filterStatus?: 'inRelease' | 'released', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerGroupControllerGetTenderingOffers', 'id', id)
            const localVarPath = `/api/offer/v1/offer-groups/{id}/tenderingOffers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }

            if (filterStatus !== undefined) {
                localVarQueryParameter['filter[status]'] = filterStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerRemoveTenderingOffer: async (id: any, offerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerGroupControllerRemoveTenderingOffer', 'id', id)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offerGroupControllerRemoveTenderingOffer', 'offerId', offerId)
            const localVarPath = `/api/offer/v1/offer-groups/{id}/tenderingOffers/{offerId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {TenderingOfferDto} tenderingOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerUpdateTenderingOffer: async (id: any, offerId: string, tenderingOfferDto: TenderingOfferDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerGroupControllerUpdateTenderingOffer', 'id', id)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offerGroupControllerUpdateTenderingOffer', 'offerId', offerId)
            // verify required parameter 'tenderingOfferDto' is not null or undefined
            assertParamExists('offerGroupControllerUpdateTenderingOffer', 'tenderingOfferDto', tenderingOfferDto)
            const localVarPath = `/api/offer/v1/offer-groups/{id}/tenderingOffers/{offerId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenderingOfferDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {TenderingOfferStatusDto} tenderingOfferStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerUpdateTenderingOfferStatus: async (id: any, offerId: string, tenderingOfferStatusDto: TenderingOfferStatusDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerGroupControllerUpdateTenderingOfferStatus', 'id', id)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offerGroupControllerUpdateTenderingOfferStatus', 'offerId', offerId)
            // verify required parameter 'tenderingOfferStatusDto' is not null or undefined
            assertParamExists('offerGroupControllerUpdateTenderingOfferStatus', 'tenderingOfferStatusDto', tenderingOfferStatusDto)
            const localVarPath = `/api/offer/v1/offer-groups/{id}/tenderingOffers/{offerId}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenderingOfferStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenderingOfferApi - functional programming interface
 * @export
 */
export const TenderingOfferApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenderingOfferApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {any} id 
         * @param {TenderingOfferDto} tenderingOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerGroupControllerCreateTenderingOffer(id: any, tenderingOfferDto: TenderingOfferDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerGroupControllerCreateTenderingOffer(id, tenderingOfferDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerGroupControllerGetTenderingOffer(id: any, offerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerGroupControllerGetTenderingOffer(id, offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} id 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {number} [pagination] 
         * @param {'inRelease' | 'released'} [filterStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerGroupControllerGetTenderingOffers(id: any, limit?: number, offset?: number, pagination?: number, filterStatus?: 'inRelease' | 'released', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenderingOffersResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerGroupControllerGetTenderingOffers(id, limit, offset, pagination, filterStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerGroupControllerRemoveTenderingOffer(id: any, offerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerGroupControllerRemoveTenderingOffer(id, offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {TenderingOfferDto} tenderingOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerGroupControllerUpdateTenderingOffer(id: any, offerId: string, tenderingOfferDto: TenderingOfferDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerGroupControllerUpdateTenderingOffer(id, offerId, tenderingOfferDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {TenderingOfferStatusDto} tenderingOfferStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerGroupControllerUpdateTenderingOfferStatus(id: any, offerId: string, tenderingOfferStatusDto: TenderingOfferStatusDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerGroupControllerUpdateTenderingOfferStatus(id, offerId, tenderingOfferStatusDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TenderingOfferApi - factory interface
 * @export
 */
export const TenderingOfferApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenderingOfferApiFp(configuration)
    return {
        /**
         * 
         * @param {any} id 
         * @param {TenderingOfferDto} tenderingOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerCreateTenderingOffer(id: any, tenderingOfferDto: TenderingOfferDto, options?: any): AxiosPromise<OfferDto> {
            return localVarFp.offerGroupControllerCreateTenderingOffer(id, tenderingOfferDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerGetTenderingOffer(id: any, offerId: string, options?: any): AxiosPromise<OfferDto> {
            return localVarFp.offerGroupControllerGetTenderingOffer(id, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} id 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {number} [pagination] 
         * @param {'inRelease' | 'released'} [filterStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerGetTenderingOffers(id: any, limit?: number, offset?: number, pagination?: number, filterStatus?: 'inRelease' | 'released', options?: any): AxiosPromise<TenderingOffersResponseDto> {
            return localVarFp.offerGroupControllerGetTenderingOffers(id, limit, offset, pagination, filterStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerRemoveTenderingOffer(id: any, offerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.offerGroupControllerRemoveTenderingOffer(id, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {TenderingOfferDto} tenderingOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerUpdateTenderingOffer(id: any, offerId: string, tenderingOfferDto: TenderingOfferDto, options?: any): AxiosPromise<void> {
            return localVarFp.offerGroupControllerUpdateTenderingOffer(id, offerId, tenderingOfferDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} id 
         * @param {string} offerId 
         * @param {TenderingOfferStatusDto} tenderingOfferStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGroupControllerUpdateTenderingOfferStatus(id: any, offerId: string, tenderingOfferStatusDto: TenderingOfferStatusDto, options?: any): AxiosPromise<void> {
            return localVarFp.offerGroupControllerUpdateTenderingOfferStatus(id, offerId, tenderingOfferStatusDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenderingOfferApi - object-oriented interface
 * @export
 * @class TenderingOfferApi
 * @extends {BaseAPI}
 */
export class TenderingOfferApi extends BaseAPI {
    /**
     * 
     * @param {any} id 
     * @param {TenderingOfferDto} tenderingOfferDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenderingOfferApi
     */
    public offerGroupControllerCreateTenderingOffer(id: any, tenderingOfferDto: TenderingOfferDto, options?: any) {
        return TenderingOfferApiFp(this.configuration).offerGroupControllerCreateTenderingOffer(id, tenderingOfferDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} id 
     * @param {string} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenderingOfferApi
     */
    public offerGroupControllerGetTenderingOffer(id: any, offerId: string, options?: any) {
        return TenderingOfferApiFp(this.configuration).offerGroupControllerGetTenderingOffer(id, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} id 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [pagination] 
     * @param {'inRelease' | 'released'} [filterStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenderingOfferApi
     */
    public offerGroupControllerGetTenderingOffers(id: any, limit?: number, offset?: number, pagination?: number, filterStatus?: 'inRelease' | 'released', options?: any) {
        return TenderingOfferApiFp(this.configuration).offerGroupControllerGetTenderingOffers(id, limit, offset, pagination, filterStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} id 
     * @param {string} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenderingOfferApi
     */
    public offerGroupControllerRemoveTenderingOffer(id: any, offerId: string, options?: any) {
        return TenderingOfferApiFp(this.configuration).offerGroupControllerRemoveTenderingOffer(id, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} id 
     * @param {string} offerId 
     * @param {TenderingOfferDto} tenderingOfferDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenderingOfferApi
     */
    public offerGroupControllerUpdateTenderingOffer(id: any, offerId: string, tenderingOfferDto: TenderingOfferDto, options?: any) {
        return TenderingOfferApiFp(this.configuration).offerGroupControllerUpdateTenderingOffer(id, offerId, tenderingOfferDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} id 
     * @param {string} offerId 
     * @param {TenderingOfferStatusDto} tenderingOfferStatusDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenderingOfferApi
     */
    public offerGroupControllerUpdateTenderingOfferStatus(id: any, offerId: string, tenderingOfferStatusDto: TenderingOfferStatusDto, options?: any) {
        return TenderingOfferApiFp(this.configuration).offerGroupControllerUpdateTenderingOfferStatus(id, offerId, tenderingOfferStatusDto, options).then((request) => request(this.axios, this.basePath));
    }
}


