export enum AccountSetting {
  SLS = 'Success Page Adaption SLS',
}

export enum IconType {
  CheckIcon = 'check',
  CheckAverageIcon = 'check-average',
  CloseIcon = 'close',
  FavIcon = 'favicon',
  CheckAveragePart = '-average',
}

export enum IconColor {
  Green = '#39aa36',
  Yellow = '#f0ca45',
  Red = 'red',
}

export enum ResultConfigurationConflict {
  InProgress = 'IN_PROGRESS',
}

export enum KeyName {
  Enter = 'Enter',
}

export enum ContractType {
  NotPrevious = 1,
  KnownPrevious = 2,
  UnknownPrevious = 3,
}

export enum URLPart {
  ComparisonPage = '/comparison',
  BookingPage = '/booking',
  Broker = '/broker',
  ProductPage = '/pp',
  TenderPage = '/tender',
  ResultList = '/rl',
  InquiryPage = '/inquiry',
  InquiryGroup = '/ig',
  LandingPage = '/p',
  InquiryPageTab1 = '/1',
  InquiryPageTab3 = '/2',
  InquiryPageTab4 = '/3',
  InquiryOld = '/ra',
  DealBreaksQuery = 'deal-breaks-query',
  DealBreak = '/dealbreak',
  DealBreaks = '/dealBreaks',
  BrokerDealBreak = '/dealBreak',
  Tender = '/tender',
  Request = '/request',
  New = '/new',
  Success = '/success',
  ReadOnlyQuestionnaire = '/read-only-questionnaire',
  BrokerHash = 'bHash',
}

export enum ErrorsURLPart {
  Type403 = '/error?type=403',
  Type503 = '/error?type=503',
}

export enum PaymentIntervalValue {
  Month = '1',
  Quarter = '3',
  HalfYear = '6',
  Year = '12',
}

export enum PremiumPaymentIntervals {
  Month = 'month',
  Quarter = 'quarter',
  HalfYear = 'halfyear',
  Year = 'year',
}

export enum ProfessionsPart {
  Aliases = '/profession-aliases/',
}

export enum ProfessionList {
  All = 'all',
}

export enum ProfessionEnum {
  anotherProfessionHash = 'cb1bad04',
  anotherProfessionName = 'Andere/sonstige Tätigkeit',
}

export enum LoadingStatus {
  Idle = 'idle',
  Pending = 'pending',
  Succeeded = 'succeeded',
  Failed = 'failed',
}
export enum SessionStorage {
  Iframe = 'iframe',
  Customer = 'customer',
}

export enum FeatureFlag {
  useProductPage = 'useProductPage',
  useResultPage = 'useResultPage',
  useQuestionnairePage = 'useQuestionnairePage',
  useTenderPage = 'useTenderPage',
  useWidgetLandingPage = 'useWidgetLandingPage',
}

export enum UserRoles {
  B2B = 'b2b',
  B2C = 'b2c',
}

export enum ApiQuery {
  FrontendSettings = 'global/getFrontendSettings',
  CheckSession = 'global/checkSession',
  ContactInfo = 'global/getContactInfo',
  BrokerSettings = 'global/getBrokerSettings',
  BrokerInfo = 'global/getBrokerInfo',
  BrokerContacts = 'global/getBrokerContacts',
  TokenDetails = 'global/getTokenDetails',
  Settings = 'global/getSettings',
  PoolDesign = 'global/getPoolDesign',
  InquiryInfo = 'global/getInquiryInfo',
  InquiryData = 'global/getInquiryData',
  InquiryGroupsData = 'global/getInquiryGroupsData',
  QuestionInfo = 'global/getQuestionInfo',
  CustomerData = 'global/getCustomerData',
  Consultations = 'consultation/consultations',
  ConsultationById = 'consultation/consultationById',
  QuestionAnswerSets = 'consultation/questionAnswerSets',
  QuestionAnswerSet = 'questionanswerset/questionanswerset',
  QuestionAnswerCategoryQuestions = 'questionanswerset/category/questions',
  QuestionAnswerGroupQuestions = 'questionanswerset/group/questions',
  ProfessionAliases = 'professions/getProfessionAliases',
  ContentAnalysesQuestionPages = 'contentAnalysis/getContentAnalysisQuestionPages',
  ContentAnalyses = 'contentAnalysis/getContentAnalysis',
  Ranges = 'ranges/getRanges',
  Products = 'premiumPredictions/getProductsPremium',
  PremiumPredictions = 'premiumPredictions/getPremiumPredictions',
  ProductRecommendations = 'product/getProductResult',
  Insurers = 'insurers/getInsurers',
  BookingSteps = 'booking/getSteps',
  AccountSettings = 'account/settings',
  RiskAnalyses = 'riskAnalyses/getRiskAnalyses',
  RiskAnalysesQuestionPages = 'riskAnalyses/getQuestionPages',
  FileServiceApiFileV1Documents = 'FileService:api/file/v1/documents',
  SearchCalculatorAccountRequest = 'calculator/searchCalculatorAccountRequest',
  RiskAssessment = 'riskAssessment/createRiskAssessment',
  EmailTemplate = 'riskAssessment/getEmailTemplate',
  OfferInsurersData = 'result/getInsurersData',
  Offer = 'result/getOffers',
  Inquiries = 'consultation/getInquiries',
  Inquiry = 'consultation/getInquiry',
  CreateInquiry = 'consultation/createInquiry',
  ComparisonData = 'comparison/getComparisonData',
  TenderRequest = 'tenderRequest/getTenderRequest',
  DiscountData = 'discounts/getDiscountData',
  Discount = 'discount/addDiscount',
  ComparisonOffers = 'comparison/getComparisonOffers',
  ConsultationInquiriesOfferGroup = 'Consultation:inquiries/offerGroup',
  SubscriptionInquiriesSubscriptions = 'Subscription:inquiries/subscriptions',
  ContractApiContractsV1Contracts = 'Contract:api/contracts/v1/contracts',
  ContractApiContractsV1ContractsDocuments = 'Contract:api/contracts/v1/contracts/documents',
  E2EInquiriesAnswers = 'E2E:inquiries/answers',
  OfferListConfigurationOfferOfferGroupsConfiguration = 'OfferListConfiguration:offer/offer-groups/configuration',
  SubmitQuestionAnswerSet = '/inquiries/questionAnswerSets/complete',
  SelectedOffers = 'Consultation:api/consultation/v1/consultations/selectedOffers',
  SearchProfessionAlias = 'masterdata/searchProfessionAliasRequest',
}
export enum BookingStep {
  Booked = 'booked',
  CustomerInfo = 'customerInformation',
  Details = 'details',
  DocumentSetup = 'documentSetup',
  EmailSetup = 'emailSetup',
  ReviewConfirm = 'reviewAndConfirm',
  Questionnaire = 'questionnaire',
}

export enum BookingReviewHeader {
  AgencyNumber = 'agencyNumber',
  BrokerData = 'brokerData',
  CustomerInfo = 'customerInformation',
  PaymentDetails = 'paymentDetails',
  DocumentSetup = 'documentSetup',
  EmailSetup = 'emailSetup',
  SelectedTariff = 'selectedTariff',
  RiskQuestions = 'riskQuestions',
}

export enum BookingInputNamePart {
  Files = 'files',
}
export enum BookingFormTitle {
  AgencyNumber = 'agencyNumber',
  BankDetails = 'bankDetails',
  PaymentDetails = 'paymentDetails',
  ConfigureInsurerEmail = 'configureInsurerEmail',
  ConfigureCustomerEmail = 'configureCustomerEmail',
  ConfigureCustomerEmailDocs = 'configureCustomerEmailDocs',
  ConfigureCustomerEmailCheckbox = 'configureCustomerEmailCheckbox',
  ConfigureCustomerEmailInsurerDocs = 'configureCustomerEmailInsurerDocs',
  ConfigureConfirmationEmail = 'configureConfirmationEmail',
  ConfigureConfirmationEmailInsurerDocs = 'configureConfirmationEmailInsurerDocs',
  ReviewAndConfirmCheckboxes = 'reviewAndConfirmCheckboxes',
}
export enum BookingFormSubTitle {
  GeneralDocuments = 'generalDocuments',
}
export enum BookingInputName {
  IncludeCoverLetter = 'blockProposalDocumentIncludeCoverLetter',
  PaymentInterval = 'frequency',
  PaymentMethod = 'paymentMethod',
  PaymentAuthorization = 'paymentAuthorization',
  ProposalDocumentComposeMessage = 'blockProposalDocumentComposeMessageArea',
  LogoUpload = 'logoUpload',
  InsurerEmailUpload = 'insurerEmailUpload',
  LogoUploadCheckbox = 'useLogoUpload',
  InsurerEmailUploadCheckbox = 'useInsurerEmailUpload',
  DifferentPostalAddress = 'differingPostalAddress',
  PostalCity = 'postalCity',
  PostalAddressStreet = 'postalAddressStreet',
  PostalAddressNumber = 'postalAddressNo',
  PostalCode = 'postalPostalCode',
  InsuranceTermsConfirmation = 'insuranceTermsConfirmation',
  BrokerMandateConfirmation = 'brokerMandateConfirmation',
  GeneralDataProcessingConfirmation = 'generalDataProcessingConfirmation',
  BrokerMandateUpload = 'brokerMandateUpload',
  RiskAnalysisUpload = 'riskAnalysisUpload',
  SendCustomerEmail = 'sendCustomerEmail',
  FurtherBookingDocUpload = 'furtherBookingDocUpload',
  CustomerBrokerageAgreementDocUpload = 'customerBrokerageAgreementDoc',
  ExternalConsultationProtocollDocUpload = 'externalConsultationProtocollDoc',
  CustomerContactPersonEmail = 'customerContactPersonEmail',
  EmailCc = 'emailCc',
  EmailBody = 'emailBody',
  PayerType = 'payerType',
  BankIban = 'bankIban',
  BankBic = 'bankBic',
  BankName = 'bankName',
  PayerCompanyName = 'payerCompanyName',
  PayerCompanyLegalForm = 'payerCompanyLegalForm',
  PayerFirstName = 'payerFirstName',
  PayerLastName = 'payerLastName',
  PayerStreet = 'payerStreet',
  PayerStreetNumber = 'payerStreetNumber',
  PayerPostcode = 'payerPostcode',
  PayerCity = 'payerCity',
  BankAccountHolder = 'bankAccountHolder',
  PayerSalutation = 'payerSalutation',
}
export enum BookingInquiryHash {
  General = 'general',
}

export enum BookingBlockWithAccordion {
  ConfigureConsultationProtocol = 'configureConsultationProtocol',
  ConfigureConfirmationEmailInsurerDocs = 'configureConfirmationEmailInsurerDocs',
  ConfigureCustomerEmailInsurerDocs = 'configureCustomerEmailInsurerDocs',
  EditBrokerInformation = 'editBrokerInformation',
}

export enum BookingParamKey {
  FlowType = 'flowType',
}

export enum UnlimitedValue {
  Value = 123123123,
}

export enum StateDisplayName {
  global = 'GlobalStateContext',
  main = 'MainStateContext',
}

export enum ProductTag {
  CompanyLow = 'firmenrecht',
  Building = 'gebaeude',
}

export enum ToolbarOption {
  bold = 'bold',
  italic = 'italic',
  underline = 'underline',
  removeformat = 'removeformat',
  variables = 'variables',
  bullist = 'bullist',
  numlist = 'numlist',
  outdent = 'outdent',
  indent = 'indent',
  table = 'table',
}

// TODO: find better way to keep setting ids
export enum AccountSettingId {
  RiskAnalysisUploadRequired = '106',
  RiskAnalysisRiskAssessment = '102',
}

export enum YesNoValue {
  Yes = 'Ja',
  No = 'Nein',
}

export enum CookiesEnum {
  ShowBrokerTutorialModal = 'showBrokerTutorial',
}

export enum SessionStatus {
  Loading = 'loading',
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',
}

export enum Step {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
}

export enum PortalsId {
  BookingFormSubBlockTitlePortal = 'booking-sub-block-title',
}

export enum CategoryRank {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
}

export enum Partners {
  Wert14 = 'Wert14',
}

export enum FilterValues {
  All = 'all',
  ShowBestOfferPerTariffOnly = 'showBestOfferPerTariffOnly',
  ResultPage = 'resultPage',
}

export enum FilterType {
  RadioButton = 'radio-button',
  RadioButtonMin = 'radio-button-min',
  RadioButtonMax = 'radio-button-max',
  RadioButtonMoneyMin = 'radio-button-money-min',
  RadioButtonMoneyMax = 'radio-button-money-max',
  CheckboxPeriodYearly = 'checkbox-period-yearly',
  Checkbox = 'checkbox',
}

export enum TenderRequestType {
  BackOffice = 2,
  BrokerManaged = 3,
}

export enum InquiryStatus {
  OfferAvailable = 'offerAvailable',
}

export enum DocumentsType {
  ProposalDocument = 'proposalDocument',
  ProposalOrderDocument = 'proposalOrderDocument',
  ProposalConsultationDocument = 'proposalConsultationDocument',
  InsurerDocument = 'insurerDocument',
  InsurerAttachmentsDocument = 'insurerEmailUpload',
  OrderDocument = 'orderDocument',
  ConsultationDocument = 'consultationDocument',
  RiskAnalysisDocument = 'riskAnalysisUpload',
  BrokerMandateDocument = 'brokerMandateUpload',
  FurtherBookingDocument = 'furtherBookingDocUpload',
  CustomerBrokerageAgreementDocument = 'customerBrokerageAgreementDoc',
  ExternalConsultationProtocollDocument = 'externalConsultationProtocollDoc',
}

export enum KeyToOrderArrayBy {
  Type = 'type',
}

export enum ErrorType {
  Manual = 'manual',
}

export enum DiscountType {
  AccountDiscount = 'account-discount',
  BookingDiscount = 'booking-discount',
  TargetDiscount = 'target-discount',
}
export enum FileSizes {
  MB_10 = 10485760,
  MB_5 = 5242880,
}

export enum OfferStatus {
  Released = 'released',
}

export enum FilterKeyParts {
  FilterFeatures = 'filter[features]',
  FilterStatus = 'filter[status]',
  FilterValue = '[value]',
  FilterPrefix = 'filter',
}

export enum SortingKeyParts {
  SortingPrefix = 'order',
}

export enum PayerTypeOptions {
  ThisCompanyOrCustomer = 'customer',
  AnotherCompany = 'differentCompany',
  SomeoneElse = 'differentPrivate',
}
