import React from 'react';

import { useTranslation } from 'react-i18next';

interface Props {
  productName?: string;
  professionAlias?: string;
}

export const InfoHeaderButtonsContainerId = 'info-header-buttons';

const InfoHeader: React.FC<Props> = ({ productName, professionAlias }) => {
  const { t } = useTranslation();
  return (
    <div className="container--custom">
      <div className="bx--row pe-5 ps-5 info-header">
        <h3 className="info-header__title">
          {productName
            ? `${productName}, ${professionAlias}`
            : `${t('infoHeader.title')} ${professionAlias}`}
        </h3>
        <div id={InfoHeaderButtonsContainerId} className="info-header__btns" />
      </div>
    </div>
  );
};

export default InfoHeader;
