import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { API, helpers } from '@helpers';
import { LoadingStatus } from '@enums';
import { HomeResultState, HomeData } from '@typings/home';

const initialState = {
  loading: LoadingStatus.Idle,
} as HomeResultState;

export const getData = createAsyncThunk('home/getData', async (args, thunkApi) => {
  try {
    return await API.get<HomeData>(
      helpers.isWidget
        ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1/landing-page/settings.json`
        : `/api/e2e/v1/landing-page/settings.json`
    );
  } catch (err) {
    const axiosError = err as AxiosError<Error>;
    return thunkApi.rejectWithValue(axiosError.response?.data);
  }
});

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getData.fulfilled, (state, { payload }) => {
      state.loading = LoadingStatus.Succeeded;
      state.errors = '';
      state.homeData = payload;
    });
    builder.addCase(getData.pending, (state) => {
      state.loading = LoadingStatus.Pending;
      state.errors = '';
    });
    builder.addCase(getData.rejected, (state, { payload }) => {
      state.loading = LoadingStatus.Failed;
      state.errors = payload;
    });
  },
});

export default homeSlice.reducer;
