import create, { GetState, SetState } from 'zustand';

import { ControlledItem, BookingSlice } from '@typings/bookingSet';

const bookingSlice = (set: SetState<BookingSlice>, get: GetState<BookingSlice>): BookingSlice => ({
  paymentMethodsValues: [],
  setPaymentMethodValuesArray: (payload: ControlledItem) => {
    const { paymentMethodsValues } = get();
    const existingValue = paymentMethodsValues.find((i) => i.inquiryHash === payload.inquiryHash);
    let newValues = paymentMethodsValues;
    if (existingValue) {
      newValues = paymentMethodsValues.map((item: ControlledItem) =>
        item.inquiryHash === payload.inquiryHash ? payload : item
      );
    } else {
      newValues = [...paymentMethodsValues, payload];
    }
    set({ paymentMethodsValues: newValues });
  },
});

const useStore = create<BookingSlice>((set, get) => bookingSlice(set, get));

export default useStore;
