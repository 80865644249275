import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';

import { InsurerReasonResponseDto, OfferGroupApi } from '@api/offerOrchestrator';
import { ApiQuery } from '@enums';
import { helpers } from '@helpers';

const offerInstance = new OfferGroupApi(
  undefined,
  helpers.isWidget ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest` : ''
);

const useDissmisalModalCalculators = (
  offerGroupId: string,
  options: UseQueryOptions<InsurerReasonResponseDto, AxiosError> = {}
): UseQueryResult<InsurerReasonResponseDto, AxiosError> =>
  useQuery(
    [ApiQuery.OfferInsurersData, offerGroupId],
    () => offerInstance.offerGroupControllerGetInsurers(offerGroupId).then((res) => res.data),
    { keepPreviousData: false, ...options }
  );

const dissmisalModalCalculators = {
  useDissmisalModalCalculators,
};

export default dissmisalModalCalculators;
