import create from 'zustand';

export enum EventType {
  RouteChange = 'route_change',
  SignAContract = 'sign_a_contract',
}

export interface TSEvent {
  name: EventType;
  payload: unknown;
}
export interface TSConfig {
  renderId?: string;
  brokerCode: string;
  professionAliasIds?: number[];
  productId?: number;
  header?: boolean;
  footer?: boolean;
  fallbackUrl?: string;
  minimalLayout?: boolean;
  eventsCallback?: (event: TSEvent) => void;
}

interface GlobalState {
  portalConfig?: TSConfig;
}

const useGlobalStore = create<GlobalState>((set, get) => ({
  portalConfig: undefined,
}));

export default useGlobalStore;
