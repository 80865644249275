import React, { useEffect } from 'react';

import { useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { Loader } from '@shared';
import { UrlParams } from '@typings/global';
import { ParamTypes } from '@typings/params';
import {
  MainStateContextProvider,
  useInquiryData,
  useInquiryGroupsData,
  useInquiryInfo,
} from '@hooks';

type Props = {
  children: React.ReactNode;
};

const MainWrapper: React.FC<Props> = (props) => {
  const { children } = props;

  const { inquiryGroupHash } = useParams<ParamTypes>();

  const { inquiryHash = '' } = useParams<{ inquiryHash: string }>();

  const {
    data: inquiryInfo,
    isError: useInquiryInfoFailed,
    isLoading: useInquiryInfoLoading,
  } = useInquiryInfo(inquiryHash);
  const {
    data: inquiryData,
    isError: useInquiryDataFailed,
    isLoading: useInquiryDataLoading,
  } = useInquiryData(inquiryHash);
  const {
    data: inquiryGroupsData,
    isError: useInquiryGroupsDataFailed,
    isLoading: useInquiryGroupsDataLoading,
  } = useInquiryGroupsData(inquiryGroupHash || inquiryInfo?.groupHash);

  const { t } = useTranslation();

  useEffect(() => {
    if (useInquiryInfoFailed || useInquiryDataFailed || useInquiryGroupsDataFailed) {
      toast.info(`${t('apiErrorMessage')}`);
    }
  }, [useInquiryInfoFailed, useInquiryDataFailed, useInquiryGroupsDataFailed, t]);

  const location = useLocation();

  const params: UrlParams = {
    inquiryHash,
    datasetCalculationResult: (queryString.parse(location.search).c as string) || '',
  };

  if (useInquiryInfoLoading || useInquiryDataLoading || useInquiryGroupsDataLoading) {
    return <Loader />;
  }

  return (
    <MainStateContextProvider
      urlParams={params}
      inquiryInfo={inquiryInfo}
      inquiryData={inquiryData}
      inquiryGroupsData={inquiryGroupsData}
    >
      {children}
    </MainStateContextProvider>
  );
};

export default MainWrapper;
