import React, { useState, useEffect } from 'react';

import { Checkbox } from '@developers/graphite/carbon-components';
import { useTranslation } from 'react-i18next';

import { FilterValues } from '@enums';
import { useOnDepsChange } from '@hooks';
import { Filter } from '@api/offerOrchestrator';

import { useFilterTranslation } from '../../hooks';

type Props = {
  filter: Filter;
  addActiveFilter: (
    value: string[],
    featureId: string,
    comparisonOperator?: string | null,
    remove?: boolean
  ) => void;
};

const ResultFiltersCheckboxes: React.FC<Props> = ({ filter, addActiveFilter }) => {
  const { t } = useTranslation();
  const [filteredOptions] = useState(filter.options);

  const defaultActiveCheckboxes: string[] = [];
  const [activeCheckboxes, setActiveCheckboxes] = useState<string[]>(defaultActiveCheckboxes);
  const filterOptionsTranslation = useFilterTranslation;

  // INFO at this moment, we don't support this feature but it will be in the future
  // const filterOptions = (value: string) => {
  //   const filteredArr = filterOptionsByText(value, filter.options) as FilterOption[];
  //   filterOptionsFunc(filteredArr);
  // };

  const addActiveCheckboxes = (value: string) => {
    if (value === FilterValues.All) {
      setActiveCheckboxes([]);
    } else if (value !== FilterValues.All && !activeCheckboxes.includes(value)) {
      setActiveCheckboxes([...activeCheckboxes, value]);
    } else {
      setActiveCheckboxes([...activeCheckboxes.filter((el) => el !== value)]);
    }
  };

  useEffect(() => {
    filteredOptions?.forEach((option) => {
      if (!option.selected || option.value === 'all') return;
      defaultActiveCheckboxes.push(option.value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useOnDepsChange(() => {
    if (activeCheckboxes.length > 0) {
      addActiveFilter(activeCheckboxes, filter.featureId);
    } else {
      addActiveFilter(activeCheckboxes, filter.featureId, null, true);
    }
  }, [activeCheckboxes]);

  return (
    <div className="result-checkboxes">
      <fieldset className="bx--fieldset">
        <legend className="bx--label">{filter.label}</legend>
        {/* INFO at this moment, we don't support this feature but it will be in the future */}
        {/* {filter.searchable && (
          <>
            <Search
              id="search-1"
              placeholder={t('general.search')}
              size="sm"
              labelText=""
              onChange={(e) => {
                filterOptions(e.currentTarget.value);
              }}
            />
          </>
        )} */}
        <div className="result-checkboxes__list">
          {filteredOptions?.map((option) => {
            return (
              <Checkbox
                data-qa={`${filter.type}_option`}
                key={option.value}
                labelText={filterOptionsTranslation(filter.type, option.value)}
                id={`${filter.featureId}_${option.value}`}
                checked={
                  (option.value === FilterValues.All && activeCheckboxes.length === 0) ||
                  activeCheckboxes.includes(option.value)
                }
                name={`${filter.featureId}_${option.value}`}
                onChange={() => addActiveCheckboxes(option.value)}
              />
            );
          })}
          {filteredOptions?.length === 0 && <div className="mt-2">{t('general.notFound')}</div>}
        </div>
      </fieldset>
    </div>
  );
};

export default ResultFiltersCheckboxes;
