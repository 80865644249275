import { useEffect } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import useFrontendSettings from '@hooks/queries/useFrontendSettings';
import { helpers } from '@helpers';
import { ErrorsURLPart, SessionStatus } from '@enums';
import { useBrokerSettings, useCheckSession } from '@hooks';
import { Sessions } from '@hooks/queries/useCheckSession';
import { Paths } from '@routes';

export interface SessionData {
  data: Sessions | null | undefined;
  status: SessionStatus;
  error: Error | null;
}

const useSession = (): SessionData => {
  const history = useHistory();
  const location = useLocation();

  const { data: frontendSettings, isError: useFrontendSettingsFailed } = useFrontendSettings();

  const {
    data: sessionData,
    isSuccess: useCheckSessionSucceeded,
    isLoading: useCheckSessionLoading,
    isError: useCheckSessionFailed = false,
    error: useCheckSessionError,
    isIdle: useCheckSessionIsIdle,
  } = useCheckSession({
    enabled: location.pathname !== Paths.Root && !!frontendSettings?.baseUrl,
    retry: 0,
  });

  const { isError: useBrokerSettingsFailed } = useBrokerSettings({
    enabled: !!frontendSettings?.baseUrl && useCheckSessionSucceeded,
  });

  useEffect(() => {
    const isAuthorized = (url: string) => {
      const hasLoginParameter = helpers.hasLoginParameter();
      if (hasLoginParameter) {
        history.push(ErrorsURLPart.Type403);
      } else {
        window.location.replace(url);
      }
    };

    if (useFrontendSettingsFailed || useBrokerSettingsFailed) {
      history.push(ErrorsURLPart.Type503);
    }

    if (frontendSettings?.baseUrl) {
      if (location.pathname === Paths.Root) {
        isAuthorized(frontendSettings.baseUrl);
      }
      if (useCheckSessionFailed) {
        isAuthorized(`${frontendSettings.baseUrl}${location.pathname}`);
      }
    }
  }, [
    frontendSettings?.baseUrl,
    history,
    location.pathname,
    useBrokerSettingsFailed,
    useCheckSessionFailed,
    useFrontendSettingsFailed,
  ]);

  const sessionStatus = {
    [SessionStatus.Authenticated]: {
      data: sessionData,
      status: useCheckSessionSucceeded,
      error: null,
    },
    [SessionStatus.Loading]: {
      data: undefined,
      status: useCheckSessionLoading || useCheckSessionIsIdle,
      error: null,
    },
    [SessionStatus.Unauthenticated]: {
      data: null,
      status: useCheckSessionFailed,
      error: useCheckSessionError,
    },
  };

  const currentSessionstatus = Object.keys(sessionStatus).find(
    (status) => sessionStatus[status as keyof typeof sessionStatus].status === true
  );

  const returnData: SessionData = {
    data: sessionStatus[currentSessionstatus as keyof typeof sessionStatus]?.data,
    status: currentSessionstatus as SessionStatus,
    error: sessionStatus[currentSessionstatus as keyof typeof sessionStatus]?.error,
  };

  return returnData;
};

export default useSession;
