import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';

import { ArchivesApi, ArchiveForm, Archive } from '@api/fileService';
import { helpers } from '@helpers';

const archivesInstance = new ArchivesApi(
  undefined,
  helpers.isWidget ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest` : ''
);

const useGetArchive = (): UseMutationResult<Archive, AxiosError, ArchiveForm> =>
  useMutation(async (archive: ArchiveForm) => {
    const res = await archivesInstance.apiFileV1ArchivesPost(archive);
    return res.data;
  });

const archives = {
  useGetArchive,
};

export default archives;
