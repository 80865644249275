import React from 'react';

import { Dropdown } from '@developers/graphite/carbon-components';
import { useTranslation } from 'react-i18next';

import { ConfigOrderItem } from '@typings/resultPage';

type Props = {
  order: ConfigOrderItem[];
  appliedSorting: ConfigOrderItem;
  addSortingParams: (key: string, value: string) => void;
};

const ResultSorting: React.FC<Props> = ({ order, appliedSorting, addSortingParams }) => {
  const { t } = useTranslation();

  const initialSortingItem = order.find(
    (i) => appliedSorting.code === i.code && appliedSorting.order === i.order
  );

  const returnSortValue = (code: string, sortOrder: string) =>
    `${t(`resultSortValues.${code}${sortOrder}`)}`;

  return (
    <div className="result-sort">
      <Dropdown
        data-qa="result-sorting"
        ariaLabel="Dropdown"
        id="sort-id"
        label=""
        titleText={`${t('result.sortBy')}`}
        type="inline"
        items={order}
        itemToString={(item) => (item ? returnSortValue(item.code, item.order) : '')}
        itemToElement={(item) =>
          item ? (
            <span data-qa="result-sorting_option">{returnSortValue(item.code, item.order)}</span>
          ) : (
            <span />
          )
        }
        onChange={(item) =>
          addSortingParams(item.selectedItem?.code || '', item.selectedItem?.order || '')
        }
        initialSelectedItem={initialSortingItem}
        light
      />
    </div>
  );
};

export default ResultSorting;
