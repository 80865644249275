import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { helpers } from '@helpers';
import { FrontendSettings, useGlobalState, Settings, useMainState } from '@hooks';
import { SessionStorage } from '@enums';
import { Paths } from '@routes';

type Props = {
  settings: Settings;
  frontendSettings: FrontendSettings;
};

const HeaderTopBar: React.FC<Props> = ({ settings, frontendSettings }) => {
  const { t } = useTranslation();
  const { inquiryGroupsData } = useMainState();
  const { brokerSettings } = useGlobalState();

  // hasLandingPageUrlOnTopBar - Neue Anfrage (new inquarity link to landing page) - URL - [HOST]/p/[BROKER_CODE]
  // externalWebsiteUrl - externalWebsiteLabel - button "zuruck zu thinksurance" (empty don't show)
  // brokerName - Sebastian Leifeld (empty don't show)
  // brokerPortalLoginUrl - Meine Vorgänge (empty don't show)

  const customerFullName: string | undefined = helpers.jsonParserFromSession(
    SessionStorage.Customer
  )?.fullName;

  return (
    <Row className="d-print-none" data-testid="header-component">
      <Col className="text-right bg-primary text-white py-1 top-bar">
        {brokerSettings?.fullName && `${t('general.logged')} ${brokerSettings.fullName}`}
        {(inquiryGroupsData?.customer?.fullName || customerFullName) && (
          <span>
            {' '}
            {t('general.with')} {inquiryGroupsData?.customer?.fullName || customerFullName}
          </span>
        )}
        {settings?.brokerPortalLoginUrl && (
          <span data-qa="header-broker-portal-login-url">
            {' '}
            ({' '}
            <a data-qa="redirect-login-link" href={settings.brokerPortalLoginUrl}>
              {t('general.myOperations')}
            </a>{' '}
            {settings?.hasLandingPageUrlOnTopBar && (
              <span data-qa="header-lp-url-on-topbar">
                |{' '}
                <a data-qa="return-landing-page" href={Paths.Root}>
                  '{t('general.newRequest')}
                </a>{' '}
              </span>
            )}
            ){' '}
          </span>
        )}{' '}
        {settings?.externalWebsiteUrl && (
          <span data-qa="header-external-website-url">
            <a className="no-btn btn-header" href={settings.externalWebsiteUrl}>
              {settings.externalWebsiteLabel}
            </a>
          </span>
        )}
      </Col>
    </Row>
  );
};

export default HeaderTopBar;
