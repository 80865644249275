/* eslint-disable @typescript-eslint/no-explicit-any */
export const openBlob = (
  blob: BlobPart,
  contentType: string,
  newTab: boolean,
  downloadName?: string
): void => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([blob], { type: contentType });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  // TODO: remove any https://stackoverflow.com/questions/69552023/after-update-typescript-3-7-2-to-latest-typescript-4-4-4-error-ts2339
  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
    (window.navigator as any).msSaveOrOpenBlob(newBlob);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);

  const link = document.createElement('a');
  link.href = data;

  if (newTab) {
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
  }

  if (downloadName) {
    link.download = downloadName;
  }

  document.body.appendChild(link);
  link.click();
  link.remove();

  // in case the Blob uses a lot of memory
  setTimeout(() => window.URL.revokeObjectURL(data), 7000);
};

export const openUrlInTheNewTab = (url: string): void => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadFile = (url: string): void => {
  window.location.href = url;
};

export const getCookieValue = (name: string): string =>
  document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';

export const isIE = (): boolean => !!(document as any)?.documentMode;
