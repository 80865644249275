import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';

import { ConsultationApi, PremiumPredictionList } from '@api/consultation';
import { ApiQuery } from '@enums';
import { helpers } from '@helpers';

const consultationInstance = new ConsultationApi(
  undefined,
  helpers.isWidget
    ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/consultation/v1`
    : '/api/consultation/v1'
);

const usePremiumPredictions = (
  consultationId: string,
  options: UseQueryOptions<PremiumPredictionList, AxiosError> = {}
): UseQueryResult<PremiumPredictionList, AxiosError> =>
  useQuery(
    [ApiQuery.PremiumPredictions, consultationId],
    () => consultationInstance.getPremiumPredictionsRequest(consultationId).then((res) => res.data),
    { keepPreviousData: true, refetchOnMount: false, retry: false, ...options }
  );

const premiumPredictions = {
  usePremiumPredictions,
};

export default premiumPredictions;
