import React from 'react';

import { Pagination } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { useFeature } from 'flagged';
import { useTranslation } from 'react-i18next';

import { FeatureFlag, URLPart } from '@enums';
import { helpers } from '@helpers';
import { useGlobalState, useMainState } from '@hooks';
import { ReactRouterValidatedLink } from '@shared';
import useGlobalStore from '@useGlobalStore';
import { Paths } from '@routes';

interface Props {
  currentPage: URLPart;
  pageOrder: URLPart[];
}

const LandingAndProductStep: React.FC<Props> = ({ currentPage, pageOrder }) => {
  const { t } = useTranslation();
  const portalConfig = useGlobalStore((state) => state.portalConfig);

  const showProductPage = useFeature(FeatureFlag.useProductPage);

  const { frontendSettings, settings } = useGlobalState();
  const { inquiryInfo } = useMainState();

  const productPageUrl = showProductPage
    ? `${URLPart.ProductPage}/${inquiryInfo?.groupHash}`
    : `${frontendSettings.baseUrl}${URLPart.ProductPage}/${inquiryInfo?.groupHash}`;

  const showLandingPageOnWidget =
    helpers.envVariables.REACT_APP_IS_WIDGET && portalConfig?.professionAliasIds
      ? !portalConfig?.professionAliasIds
      : true;

  const showProductPageOnWidget =
    helpers.envVariables.REACT_APP_IS_WIDGET && portalConfig?.productId
      ? !portalConfig?.productId
      : true;

  const currentPageIsActive =
    currentPage === URLPart.ProductPage || currentPage === URLPart.LandingPage;

  const showLandingPageLink =
    (!settings?.skipLandingPage || showProductPage) && showLandingPageOnWidget;

  const currentPageIsCompleted =
    pageOrder.indexOf(currentPage) > pageOrder.indexOf(URLPart.ProductPage) &&
    showProductPageOnWidget;

  return (
    <>
      <div className="step-title">
        {t('steps.activity')}{' '}
        {showLandingPageLink ? (
          <ReactRouterValidatedLink url={Paths.Root} fallbackClassName="link-icon_skeleton">
            <FontAwesomeIcon icon={faPencilAlt} />
          </ReactRouterValidatedLink>
        ) : (
          <>
            {currentPageIsCompleted && (
              <ReactRouterValidatedLink url={productPageUrl} fallbackClassName="link-icon_skeleton">
                <FontAwesomeIcon icon={faPencilAlt} />
              </ReactRouterValidatedLink>
            )}
          </>
        )}{' '}
        {t('general.and')} {t('steps.riskAnalysis')}{' '}
        {currentPageIsCompleted && (
          <ReactRouterValidatedLink url={productPageUrl} fallbackClassName="link-icon_skeleton">
            <FontAwesomeIcon icon={faPencilAlt} />
          </ReactRouterValidatedLink>
        )}
      </div>
      <div>
        <Pagination className="justify-content-center mb-0">
          <Pagination.Item
            active={currentPageIsActive}
            disabled={!currentPageIsCompleted && !currentPageIsActive}
          >
            1
          </Pagination.Item>
        </Pagination>
      </div>
    </>
  );
};

export default LandingAndProductStep;
