import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';
import axios from 'axios';

import { API, helpers } from '@helpers';
import { ApiQuery } from '@enums';
import { Profession } from '@typings/professionSearch';

export interface InquiryCustomer {
  id: number;
  firstName: string;
  lastName: string;
  company: string;
  street: string;
  streetNumber: string;
  zip: string;
  city: string;
  phone: string;
  email: string;
  salutation: string;
  title: string;
  legalForm: boolean;
  fullName: string;
}

export interface InquiryGroupsData {
  id: number;
  hash: string;
  // WTF ????? This doesn't exist on response of server
  customer: InquiryCustomer | null;
  professionAliases: Profession[];
}

const useInquiryGroupsData = (
  inquiryGroupId?: string,
  options: UseQueryOptions<InquiryGroupsData, Error> = {}
): QueryObserverResult<InquiryGroupsData, Error> => {
  return useQuery<InquiryGroupsData, Error>(
    [ApiQuery.InquiryGroupsData, inquiryGroupId],
    async () => {
      const response = await API.get<InquiryGroupsData>(
        helpers.isWidget
          ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1/inquiry-groups/${inquiryGroupId}.json?groups[]=customer:read`
          : `/api/e2e/v1/inquiry-groups/${inquiryGroupId}.json?groups[]=customer:read`
      );

      return response;
    },
    {
      ...options,
      enabled:
        (options.enabled ?? true) &&
        !!inquiryGroupId &&
        !!axios.defaults.headers.common.Authorization,
    }
  );
};

export default useInquiryGroupsData;
