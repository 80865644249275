import React, { lazy, useEffect, useState } from 'react';

import { Switch, Redirect, Route, useHistory } from 'react-router-dom';

import { FeatureFlagRedirection, RouteWithLayout } from '@shared';
import {
  Main as MainLayout,
  Landing as LandingLayout,
  ErrorPage as ErrorPageLayout,
  Simple as SimpleLayout,
} from '@layouts';
import { helpers } from '@helpers';
import { TenderSuccessPage as TenderSuccessPageView } from '@pages/Tender/Components';
import useGlobalStore, { EventType } from '@useGlobalStore';

const ErrorPageView = lazy(() => import('@pages/ErrorPage'));
const ComparisonView = lazy(() => import('@pages/Comparison'));
const HomeView = lazy(() => import('@pages/Home'));
const ProductView = lazy(() => import('@pages/Product'));
const BookingView = lazy(() => import('@pages/Booking'));
const ResultView = lazy(() => import('@pages/Result'));
const TenderView = lazy(() => import('@pages/Tender'));
const QuestionnaireView = lazy(() => import('@pages/Questionnaire/QuestionnaireWrapper'));
const ReadOnlyQuestionnaireView = lazy(() => import('@pages/Questionnaire/ReadOnlyQuestionnaire'));

export enum Paths {
  Root = '/',
  Home = '/p/:brokerCode',
}

const Routes: React.FC = () => {
  const history = useHistory();
  const [currentRoute, setCurrentRoute] = useState('');
  const { eventsCallback } = useGlobalStore((state) => ({
    eventsCallback: state.portalConfig?.eventsCallback,
  }));

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return history.listen((location: any) => {
      currentRoute !== location?.pathname &&
        eventsCallback &&
        eventsCallback({
          name: EventType.RouteChange,
          payload: location?.pathname,
        });
      setCurrentRoute(location?.pathname);
    });
    // INFO we don't want to call useEffect on update of currentRoute
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, eventsCallback]);

  return (
    <Switch>
      <Redirect from="/api/if/:brokerCode" to="/p/:brokerCode" />

      <RouteWithLayout component={HomeView} exact layout={LandingLayout} path="/" />

      {helpers.envVariables.REACT_APP_USE_PRODUCT_PAGE ||
      helpers.envVariables.REACT_APP_USE_QUESTIONNAIRE_PAGE ? (
        <RouteWithLayout
          component={ProductView}
          exact
          layout={MainLayout}
          path="/pp/:inquiryGroupHash"
        />
      ) : (
        <Route component={FeatureFlagRedirection} exact path="/pp/:inquiryGroupHash" />
      )}

      {helpers.envVariables.REACT_APP_USE_BOOKING_PAGE ? (
        <RouteWithLayout
          component={BookingView}
          exact
          layout={MainLayout}
          path="/booking/:inquiryHash/:offerId"
        />
      ) : (
        <Route component={FeatureFlagRedirection} exact path="/booking/:inquiryHash/:offerId" />
      )}

      {helpers.envVariables.REACT_APP_USE_RESULT_PAGE ? (
        <RouteWithLayout
          component={ResultView}
          exact
          layout={MainLayout}
          path="/rl/:inquiryHash"
          className="result-page-class"
        />
      ) : (
        <Route component={FeatureFlagRedirection} exact path="/rl/:inquiryHash" />
      )}

      {helpers.envVariables.REACT_APP_USE_DEALBREAK_PAGE && (
        <Route component={FeatureFlagRedirection} exact path="/dealbreak/:inquiryHash/start" />
      )}

      {helpers.envVariables.REACT_APP_USE_QUESTIONNAIRE_PAGE ? (
        <RouteWithLayout
          component={QuestionnaireView}
          exact
          layout={MainLayout}
          path="/inquiry/:inquiryHash"
          headerClassName="questionnaire-page__header"
          id="questionnaire-header"
        />
      ) : (
        <Route component={FeatureFlagRedirection} exact path="/inquiry/:inquiryHash" />
      )}

      {helpers.envVariables.REACT_APP_USE_TENDER_PAGE ? (
        <RouteWithLayout
          component={TenderView}
          exact
          layout={MainLayout}
          path="/tender/:inquiryHash/request"
          headerClassName="questionnaire-page__header"
          id="questionnaire-header"
        />
      ) : (
        <Route component={FeatureFlagRedirection} exact path="/tender/:inquiryHash/request" />
      )}

      {helpers.envVariables.REACT_APP_USE_TENDER_PAGE ? (
        <RouteWithLayout
          component={TenderSuccessPageView}
          exact
          layout={SimpleLayout}
          path="/tender/:inquiryHash/request/success"
        />
      ) : (
        <Route
          component={FeatureFlagRedirection}
          exact
          path="/tender/:inquiryHash/request/success"
        />
      )}

      <RouteWithLayout
        component={HomeView}
        exact
        layout={LandingLayout}
        path="/p/:brokerCode/:productTag"
      />

      <RouteWithLayout
        component={ComparisonView}
        exact
        layout={MainLayout}
        path="/comparison/:inquiryHash"
      />

      <RouteWithLayout
        component={ReadOnlyQuestionnaireView}
        exact
        layout={SimpleLayout}
        path="/read-only-questionnaire/:inquiryHash"
      />

      <RouteWithLayout component={ErrorPageView} exact layout={ErrorPageLayout} path="/error" />

      <Redirect to="/error" />
    </Switch>
  );
};

export default Routes;
