import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';

import { InsurerApi, InsurerList } from '@api/masterData';
import { ApiQuery } from '@enums';
import { InsurerParams } from '@typings/insurer';
import { helpers } from '@helpers';

const insurerInstance = new InsurerApi(
  undefined,
  helpers.isWidget
    ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/masterdata/v1`
    : '/api/masterdata/v1'
);

const useInsurers = (
  { insurerIds, name, tag, limit, offset, noPagination }: Partial<InsurerParams>,
  options: UseQueryOptions<InsurerList, AxiosError> = {}
): UseQueryResult<InsurerList, AxiosError> =>
  useQuery(
    [ApiQuery.Insurers, insurerIds, name, tag, limit, offset, noPagination],
    () =>
      insurerInstance
        .searchInsurerRequest(insurerIds, name, tag, limit, offset, noPagination)
        .then((res) => res.data),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      retry: false,
      ...options,
    }
  );

const Insurers = {
  useInsurers,
};

export default Insurers;
