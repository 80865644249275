import React from 'react';

import { SkeletonText, CheckboxSkeleton } from '@developers/graphite/carbon-components';

const ResultFiltersSkeleton: React.FC = () => {
  return (
    <div className="result-filters-skeleton">
      <div className="filter-skeleton">
        <SkeletonText className="mt-7" />
      </div>
      <div className="mt-7 filter-skeleton">
        <SkeletonText />
        <SkeletonText width="60%" />
        <SkeletonText width="60%" />
        <SkeletonText width="60%" />
      </div>
      <div className="mt-7 filter-skeleton">
        <SkeletonText />
        <SkeletonText width="60%" />
        <SkeletonText width="60%" />
        <SkeletonText width="60%" />
        <SkeletonText width="60%" />
      </div>
      <div className="mt-7 filter-skeleton">
        <SkeletonText />
        <SkeletonText width="60%" />
        <SkeletonText width="60%" />
      </div>
      <div className="mt-7 filter-skeleton">
        <CheckboxSkeleton />
      </div>
    </div>
  );
};

export default ResultFiltersSkeleton;
