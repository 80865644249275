import React, { useState, useEffect } from 'react';

import Cookies from 'universal-cookie';
import { GdsThemeProvider } from '@developers/graphite';
import { FlagsProvider } from 'flagged';

import { GlobalStateContextProvider } from '@hooks';
import useBootingServices from '@hooks/useBootingServices';
import { FeatureFlag, IconType, CookiesEnum } from '@enums';
import { BrokerTutorialModal, RedirectToAdvisoryModal, HelmetWrapper, Loader } from '@shared';
import { FrontendSettings } from '@hooks/queries/useFrontendSettings';
import { Sessions } from '@hooks/queries/useCheckSession';
import { BrokerSettings } from '@hooks/queries/useBrokerSettings';
import { helpers } from '@helpers';

type Props = {
  children: React.ReactNode;
};

const BootingWrapper: React.FC<Props> = ({ children }) => {
  const cookies = new Cookies();
  const cookieForModal = cookies.get(CookiesEnum.ShowBrokerTutorialModal);
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);

  const { data, loading, errors } = useBootingServices();
  const {
    frontendSettings,
    session,
    settings,
    poolDesign,
    contactInfo,
    brokerSettings,
    tokenDetails,
  } = data;

  const accountTag = brokerSettings?.pool.tag;
  const isBrokerB2B = session?.roles.includes('b2b');
  const displayRedirectionModal: boolean =
    helpers.envVariables.REACT_APP_DISPLAY_REDIRECTION_MODAL && !!isBrokerB2B;

  useEffect(() => {
    if (!helpers.isWidget) {
      if (poolDesign && poolDesign[0]?.faviconUrl) {
        const favicon = document.getElementById(
          IconType.FavIcon
        ) as unknown as HTMLHyperlinkElementUtils;
        favicon.href = poolDesign[0].faviconUrl;
      }
    }
  }, [poolDesign]);

  useEffect(() => {
    if (settings?.hasBrokerTutorial && !cookieForModal) {
      setShowModal(true);
    }
  }, [cookieForModal, settings?.hasBrokerTutorial]);

  return (
    <>
      {loading || errors.length > 0 ? (
        <Loader />
      ) : (
        <>
          <HelmetWrapper settings={settings} session={session} />
          <GdsThemeProvider
            theme={
              poolDesign && poolDesign[0]
                ? poolDesign[0]
                : {
                    primaryColor: 'white',
                    secondaryColor: 'white',
                    mainFontFamily: 'IBM Plex Sans',
                  }
            }
            loading={false}
          >
            <GlobalStateContextProvider
              frontendSettings={
                helpers.isWidget
                  ? { baseUrl: helpers.envVariables.REACT_APP_WIDGET_BASE_URL }
                  : (frontendSettings as FrontendSettings)
              }
              sessions={session as Sessions}
              contactInfo={contactInfo}
              brokerSettings={brokerSettings as BrokerSettings}
              tokenDetails={tokenDetails}
              settings={settings}
            >
              <FlagsProvider
                features={{
                  [FeatureFlag.useProductPage]:
                    helpers.envVariables.REACT_APP_USE_PRODUCT_PAGE ?? false,
                  [FeatureFlag.useQuestionnairePage]:
                    helpers.envVariables.REACT_APP_USE_QUESTIONNAIRE_PAGE ?? false,
                  [FeatureFlag.useResultPage]:
                    helpers.envVariables.REACT_APP_USE_RESULT_PAGE ?? false,
                  [FeatureFlag.useTenderPage]:
                    helpers.envVariables.REACT_APP_USE_TENDER_PAGE ?? false,
                }}
              >
                <BrokerTutorialModal show={showModal} handleClose={handleCloseModal} />
                {displayRedirectionModal && accountTag && (
                  <RedirectToAdvisoryModal accountTag={accountTag} />
                )}
                {children}
              </FlagsProvider>
            </GlobalStateContextProvider>
          </GdsThemeProvider>
        </>
      )}
    </>
  );
};

export default BootingWrapper;
