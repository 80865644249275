/* tslint:disable */
/* eslint-disable */
/**
 * Authorization API
 * Authorization API provides data relevant for consultations.
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * BTokenOldHolder is DTO used in API response
 * @export
 * @interface BTokenOldHolder
 */
export interface BTokenOldHolder {
    /**
     * 
     * @type {string}
     * @memberof BTokenOldHolder
     */
    brokerInternalId: string;
    /**
     * 
     * @type {string}
     * @memberof BTokenOldHolder
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof BTokenOldHolder
     */
    poolTag: string;
}
/**
 * CodeCredentialsHolder is DTO used in API response
 * @export
 * @interface CodeCredentialsHolder
 */
export interface CodeCredentialsHolder {
    /**
     * 
     * @type {string}
     * @memberof CodeCredentialsHolder
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof CodeCredentialsHolder
     */
    consultationHash?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeCredentialsHolder
     */
    inquiryHash?: string;
}
/**
 * CompleteTokenHolder is DTO used in API response
 * @export
 * @interface CompleteTokenHolder
 */
export interface CompleteTokenHolder {
    /**
     * 
     * @type {string}
     * @memberof CompleteTokenHolder
     */
    accessToken: string;
    /**
     * 
     * @type {string}
     * @memberof CompleteTokenHolder
     */
    expiry: string;
    /**
     * 
     * @type {string}
     * @memberof CompleteTokenHolder
     */
    id: string;
    /**
     * 
     * @type {UserHolder}
     * @memberof CompleteTokenHolder
     */
    user: UserHolder;
}
/**
 * ErrorHolder is DTO used in API response
 * @export
 * @interface ErrorHolder
 */
export interface ErrorHolder {
    /**
     * 
     * @type {string}
     * @memberof ErrorHolder
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ErrorHolder
     */
    violations?: { [key: string]: Array<string>; };
}
/**
 * HashHolder is DTO used in API response
 * @export
 * @interface HashHolder
 */
export interface HashHolder {
    /**
     * 
     * @type {string}
     * @memberof HashHolder
     */
    hash: string;
}
/**
 * IDHolder is DTO used in API response
 * @export
 * @interface IDHolder
 */
export interface IDHolder {
    /**
     * 
     * @type {string}
     * @memberof IDHolder
     */
    id?: string;
}
/**
 * TokenCredentialsHolder is DTO used in API response
 * @export
 * @interface TokenCredentialsHolder
 */
export interface TokenCredentialsHolder {
    /**
     * 
     * @type {string}
     * @memberof TokenCredentialsHolder
     */
    token: string;
}
/**
 * TokenHolder is DTO used in API response
 * @export
 * @interface TokenHolder
 */
export interface TokenHolder {
    /**
     * 
     * @type {string}
     * @memberof TokenHolder
     */
    accessToken: string;
    /**
     * 
     * @type {string}
     * @memberof TokenHolder
     */
    expiry: string;
    /**
     * 
     * @type {string}
     * @memberof TokenHolder
     */
    id: string;
}
/**
 * UserHolder represents DTO for API response
 * @export
 * @interface UserHolder
 */
export interface UserHolder {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof UserHolder
     */
    additionalFields: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof UserHolder
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserHolder
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserHolder
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserHolder
     */
    lastName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserHolder
     */
    roles: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserHolder
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof UserHolder
     */
    username: string;
}
/**
 * UsernameAndPasswordCredentialsHolder is DTO used in API response
 * @export
 * @interface UsernameAndPasswordCredentialsHolder
 */
export interface UsernameAndPasswordCredentialsHolder {
    /**
     * 
     * @type {string}
     * @memberof UsernameAndPasswordCredentialsHolder
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof UsernameAndPasswordCredentialsHolder
     */
    username: string;
}

/**
 * AnonymousApi - axios parameter creator
 * @export
 */
export const AnonymousApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        anonymousAuthorizationRequest: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/anonymous`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnonymousApi - functional programming interface
 * @export
 */
export const AnonymousApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnonymousApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async anonymousAuthorizationRequest(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteTokenHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.anonymousAuthorizationRequest(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnonymousApi - factory interface
 * @export
 */
export const AnonymousApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnonymousApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        anonymousAuthorizationRequest(options?: any): AxiosPromise<CompleteTokenHolder> {
            return localVarFp.anonymousAuthorizationRequest(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnonymousApi - object-oriented interface
 * @export
 * @class AnonymousApi
 * @extends {BaseAPI}
 */
export class AnonymousApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnonymousApi
     */
    public anonymousAuthorizationRequest(options?: any) {
        return AnonymousApiFp(this.configuration).anonymousAuthorizationRequest(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BrokerApi - axios parameter creator
 * @export
 */
export const BrokerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UsernameAndPasswordCredentialsHolder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerAuthorizationRequest: async (body?: UsernameAndPasswordCredentialsHolder, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/brokers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CodeCredentialsHolder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerCodeAuthorizationRequest: async (body?: CodeCredentialsHolder, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bCodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TokenCredentialsHolder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerTokenAuthorizationRequest: async (body?: TokenCredentialsHolder, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/bTokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BTokenOldHolder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerTokenOldAuthorizationRequest: async (body?: BTokenOldHolder, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bTokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrokerApi - functional programming interface
 * @export
 */
export const BrokerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrokerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UsernameAndPasswordCredentialsHolder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokerAuthorizationRequest(body?: UsernameAndPasswordCredentialsHolder, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteTokenHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokerAuthorizationRequest(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CodeCredentialsHolder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokerCodeAuthorizationRequest(body?: CodeCredentialsHolder, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteTokenHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokerCodeAuthorizationRequest(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TokenCredentialsHolder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokerTokenAuthorizationRequest(body?: TokenCredentialsHolder, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteTokenHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokerTokenAuthorizationRequest(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BTokenOldHolder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokerTokenOldAuthorizationRequest(body?: BTokenOldHolder, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteTokenHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokerTokenOldAuthorizationRequest(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BrokerApi - factory interface
 * @export
 */
export const BrokerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrokerApiFp(configuration)
    return {
        /**
         * 
         * @param {UsernameAndPasswordCredentialsHolder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerAuthorizationRequest(body?: UsernameAndPasswordCredentialsHolder, options?: any): AxiosPromise<CompleteTokenHolder> {
            return localVarFp.brokerAuthorizationRequest(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CodeCredentialsHolder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerCodeAuthorizationRequest(body?: CodeCredentialsHolder, options?: any): AxiosPromise<CompleteTokenHolder> {
            return localVarFp.brokerCodeAuthorizationRequest(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TokenCredentialsHolder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerTokenAuthorizationRequest(body?: TokenCredentialsHolder, options?: any): AxiosPromise<CompleteTokenHolder> {
            return localVarFp.brokerTokenAuthorizationRequest(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BTokenOldHolder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerTokenOldAuthorizationRequest(body?: BTokenOldHolder, options?: any): AxiosPromise<CompleteTokenHolder> {
            return localVarFp.brokerTokenOldAuthorizationRequest(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BrokerApi - object-oriented interface
 * @export
 * @class BrokerApi
 * @extends {BaseAPI}
 */
export class BrokerApi extends BaseAPI {
    /**
     * 
     * @param {UsernameAndPasswordCredentialsHolder} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokerApi
     */
    public brokerAuthorizationRequest(body?: UsernameAndPasswordCredentialsHolder, options?: any) {
        return BrokerApiFp(this.configuration).brokerAuthorizationRequest(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CodeCredentialsHolder} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokerApi
     */
    public brokerCodeAuthorizationRequest(body?: CodeCredentialsHolder, options?: any) {
        return BrokerApiFp(this.configuration).brokerCodeAuthorizationRequest(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TokenCredentialsHolder} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokerApi
     */
    public brokerTokenAuthorizationRequest(body?: TokenCredentialsHolder, options?: any) {
        return BrokerApiFp(this.configuration).brokerTokenAuthorizationRequest(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BTokenOldHolder} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokerApi
     */
    public brokerTokenOldAuthorizationRequest(body?: BTokenOldHolder, options?: any) {
        return BrokerApiFp(this.configuration).brokerTokenOldAuthorizationRequest(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HashApi - axios parameter creator
 * @export
 */
export const HashApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {HashHolder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hashAuthorizationRequest: async (body?: HashHolder, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/hashes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HashApi - functional programming interface
 * @export
 */
export const HashApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HashApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {HashHolder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hashAuthorizationRequest(body?: HashHolder, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteTokenHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hashAuthorizationRequest(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HashApi - factory interface
 * @export
 */
export const HashApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HashApiFp(configuration)
    return {
        /**
         * 
         * @param {HashHolder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hashAuthorizationRequest(body?: HashHolder, options?: any): AxiosPromise<CompleteTokenHolder> {
            return localVarFp.hashAuthorizationRequest(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HashApi - object-oriented interface
 * @export
 * @class HashApi
 * @extends {BaseAPI}
 */
export class HashApi extends BaseAPI {
    /**
     * 
     * @param {HashHolder} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HashApi
     */
    public hashAuthorizationRequest(body?: HashHolder, options?: any) {
        return HashApiFp(this.configuration).hashAuthorizationRequest(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TokenApi - axios parameter creator
 * @export
 */
export const TokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTokenAuthorizationRequest: async (tokenId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenId' is not null or undefined
            assertParamExists('deleteTokenAuthorizationRequest', 'tokenId', tokenId)
            const localVarPath = `/v1/tokens/{tokenId}`
                .replace(`{${"tokenId"}}`, encodeURIComponent(String(tokenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        encodeTokenAuthorizationRequest: async (tokenId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenId' is not null or undefined
            assertParamExists('encodeTokenAuthorizationRequest', 'tokenId', tokenId)
            const localVarPath = `/v1/tokens/{tokenId}/hashes`
                .replace(`{${"tokenId"}}`, encodeURIComponent(String(tokenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenAuthorizationRequest: async (tokenId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenId' is not null or undefined
            assertParamExists('getTokenAuthorizationRequest', 'tokenId', tokenId)
            const localVarPath = `/v1/tokens/{tokenId}/accessTokens`
                .replace(`{${"tokenId"}}`, encodeURIComponent(String(tokenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenRenewRequest: async (tokenId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenId' is not null or undefined
            assertParamExists('getTokenRenewRequest', 'tokenId', tokenId)
            const localVarPath = `/v1/tokens/{tokenId}/renew`
                .replace(`{${"tokenId"}}`, encodeURIComponent(String(tokenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAuthorizationRequest: async (tokenId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenId' is not null or undefined
            assertParamExists('getUserAuthorizationRequest', 'tokenId', tokenId)
            const localVarPath = `/v1/tokens/{tokenId}/users`
                .replace(`{${"tokenId"}}`, encodeURIComponent(String(tokenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TokenApi - functional programming interface
 * @export
 */
export const TokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TokenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTokenAuthorizationRequest(tokenId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTokenAuthorizationRequest(tokenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async encodeTokenAuthorizationRequest(tokenId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HashHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.encodeTokenAuthorizationRequest(tokenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTokenAuthorizationRequest(tokenId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTokenAuthorizationRequest(tokenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTokenRenewRequest(tokenId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTokenRenewRequest(tokenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAuthorizationRequest(tokenId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAuthorizationRequest(tokenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TokenApi - factory interface
 * @export
 */
export const TokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TokenApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTokenAuthorizationRequest(tokenId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTokenAuthorizationRequest(tokenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        encodeTokenAuthorizationRequest(tokenId: string, options?: any): AxiosPromise<HashHolder> {
            return localVarFp.encodeTokenAuthorizationRequest(tokenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenAuthorizationRequest(tokenId: string, options?: any): AxiosPromise<TokenHolder> {
            return localVarFp.getTokenAuthorizationRequest(tokenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenRenewRequest(tokenId: string, options?: any): AxiosPromise<TokenHolder> {
            return localVarFp.getTokenRenewRequest(tokenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAuthorizationRequest(tokenId: string, options?: any): AxiosPromise<UserHolder> {
            return localVarFp.getUserAuthorizationRequest(tokenId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TokenApi - object-oriented interface
 * @export
 * @class TokenApi
 * @extends {BaseAPI}
 */
export class TokenApi extends BaseAPI {
    /**
     * 
     * @param {string} tokenId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public deleteTokenAuthorizationRequest(tokenId: string, options?: any) {
        return TokenApiFp(this.configuration).deleteTokenAuthorizationRequest(tokenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tokenId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public encodeTokenAuthorizationRequest(tokenId: string, options?: any) {
        return TokenApiFp(this.configuration).encodeTokenAuthorizationRequest(tokenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tokenId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public getTokenAuthorizationRequest(tokenId: string, options?: any) {
        return TokenApiFp(this.configuration).getTokenAuthorizationRequest(tokenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tokenId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public getTokenRenewRequest(tokenId: string, options?: any) {
        return TokenApiFp(this.configuration).getTokenRenewRequest(tokenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tokenId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public getUserAuthorizationRequest(tokenId: string, options?: any) {
        return TokenApiFp(this.configuration).getUserAuthorizationRequest(tokenId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UsernameAndPasswordCredentialsHolder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAuthorizationRequest: async (body?: UsernameAndPasswordCredentialsHolder, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TokenCredentialsHolder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userTokenAuthorizationRequest: async (body?: TokenCredentialsHolder, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/uTokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UsernameAndPasswordCredentialsHolder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAuthorizationRequest(body?: UsernameAndPasswordCredentialsHolder, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteTokenHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAuthorizationRequest(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TokenCredentialsHolder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userTokenAuthorizationRequest(body?: TokenCredentialsHolder, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteTokenHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userTokenAuthorizationRequest(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {UsernameAndPasswordCredentialsHolder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAuthorizationRequest(body?: UsernameAndPasswordCredentialsHolder, options?: any): AxiosPromise<CompleteTokenHolder> {
            return localVarFp.userAuthorizationRequest(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TokenCredentialsHolder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userTokenAuthorizationRequest(body?: TokenCredentialsHolder, options?: any): AxiosPromise<CompleteTokenHolder> {
            return localVarFp.userTokenAuthorizationRequest(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {UsernameAndPasswordCredentialsHolder} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userAuthorizationRequest(body?: UsernameAndPasswordCredentialsHolder, options?: any) {
        return UserApiFp(this.configuration).userAuthorizationRequest(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TokenCredentialsHolder} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userTokenAuthorizationRequest(body?: TokenCredentialsHolder, options?: any) {
        return UserApiFp(this.configuration).userTokenAuthorizationRequest(body, options).then((request) => request(this.axios, this.basePath));
    }
}


