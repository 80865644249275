import React, { useState } from 'react';

import { Tag, Button, ButtonSkeleton, TooltipIcon } from '@developers/graphite/carbon-components';
import { Information16, ChevronRight16 } from '@developers/graphite/carbon-icons';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { currencyHelpers } from '@developers/toolbox';
import { ValidatedLink } from '@developers/graphite';

import { OfferPremium, OfferPrice } from '@typings/resultPage';
import { URLPart, KeyName, UserRoles } from '@enums';
import { offerServices, configuration } from '@services/offer';
import { inquiriesService } from '@services/consultation';
import { useGlobalState, useMainState } from '@hooks';
import { helpers } from '@helpers';

import { DiscountModal } from '..';

type Props = {
  offerId: string;
  price: OfferPrice;
  filterHash: string;
  offerGroupId: string;
  allowBooking: boolean;
  offerExpiration: string;
  premium: OfferPremium;
};

const ResultOfferPrices: React.FC<Props> = ({
  price,
  offerId,
  filterHash,
  offerGroupId,
  allowBooking,
  offerExpiration,
  premium,
}) => {
  const { sessions } = useGlobalState();
  const { t } = useTranslation();
  const history = useHistory();

  const { inquiryData } = useMainState();

  const [open, toggleComposeModal] = useState(false);
  const [isOwnModal, changeOwnModal] = useState(false);
  const { inquiryHash } = useParams<{ inquiryHash: string }>();

  const setSelectedOfferForBooking = offerServices.useSetSelectedOfferForBooking();

  const { data: offerGroupDetails } = inquiriesService.useGetOfferGroup(inquiryData?.id.toString());
  const { data: configurationData } = configuration.useGetConfiguration(
    offerGroupDetails?.id as string
  );

  // eslint-disable-next-line react/no-danger
  const currency = <span dangerouslySetInnerHTML={{ __html: `&#${price.currency};` }} />; // TODO remove dangerouslySetInnerHTML

  // TMP: return the prices
  const formattedDefaultValue = currencyHelpers.formatCurrency(+price.finalGross, {
    decimalScale: 2,
  });
  const formattedGrossValue = currencyHelpers.convertFromCents(price.gross);
  const formattedMinimumPremium = currencyHelpers.convertFromCents(
    premium?.frequencyGrossMinimumPremium
  );

  const isGrossValueBiggerThenMinimumPremium = formattedGrossValue > formattedMinimumPremium;

  const fixedPricePart = formattedDefaultValue.slice(0, -2);
  const decimalPricePart = formattedDefaultValue.slice(-2);

  const openModal = (isOwnModalType: boolean) => {
    toggleComposeModal(true);
    changeOwnModal(isOwnModalType);
  };

  const goToBooking = (link: string) => {
    const selectMutateOffer = {
      inquiryId: String(inquiryData?.id),
      offerId,
    };
    setSelectedOfferForBooking.mutate(selectMutateOffer, {
      onSuccess: () => {
        history.push(link);
      },
      onError: () => {
        toast.info(`${t('apiErrorMessage')}`);
      },
    });
  };

  const configurationSettings = configurationData?.settings;

  const discountsNotAvailable =
    configurationSettings?.hideBookingDiscount &&
    configurationSettings?.hideTargetPremium &&
    !price.discount;

  const displayDiscountButton =
    allowBooking &&
    !helpers.isWidget &&
    sessions.roles.includes(UserRoles.B2B) &&
    !discountsNotAvailable;

  const coverageLink = `${URLPart.BookingPage}/${inquiryHash}/${offerId}?${filterHash}`;

  const isExpiredOffer = helpers.checkIsOfferExpired(offerExpiration);

  return (
    <div className="offer-prices__wrapper">
      {open ? (
        <DiscountModal
          discountValue={price.discount || 0}
          gross={price.gross}
          finalPrice={price.finalGross}
          currency={price.currency}
          id={offerId}
          open={open}
          toggleComposeModal={toggleComposeModal}
          isOwnDiscount={isOwnModal}
          offerGroupId={offerGroupId}
        />
      ) : (
        <></>
      )}
      <div className="offer-prices">
        {price.showDiscount && price.discount && isGrossValueBiggerThenMinimumPremium && (
          <div>
            <span className="offer-prices__gross me-2">
              {formattedGrossValue} {currency}
            </span>
            <Tag type="red">-{price.discount}%</Tag>
          </div>
        )}
        <div className="offer-prices__final-gross mt-1">
          <span>{fixedPricePart}</span>
          <sup className="offer-prices__sup">{decimalPricePart}</sup> {currency}
          {displayDiscountButton && (
            <div
              data-qa="offer-rabatt_button"
              onClick={() => openModal(!price.discount)}
              className="offer-prices__discount"
              role="button"
              tabIndex={0}
              onKeyDown={(e) => e.key === KeyName.Enter && openModal(!price.discount)}
            >
              {t('result.discount')}
            </div>
          )}
        </div>
        {allowBooking && !isExpiredOffer && (
          <ValidatedLink
            url={coverageLink}
            render={(url) => (
              <Button
                data-qa="offer-prices-discount_button"
                kind="tertiary"
                size="field"
                renderIcon={ChevronRight16}
                className="mt-2 d-print-none offer-prices__discount-button"
                onClick={() => goToBooking(url)}
              >
                {t('result.chooseTariff')}
              </Button>
            )}
            renderFallback={() => <ButtonSkeleton />}
          />
        )}

        {!allowBooking && !isExpiredOffer && (
          <div className="no-booking-info">
            <Information16 className="mr-2" />
            <span>{t('result.noConnection')}</span>
          </div>
        )}

        {isExpiredOffer && (
          // TODO: reuse here ButtonWithTooltip when we change implementation in GDS
          <TooltipIcon
            direction="bottom"
            align="end"
            tooltipText={t('result.offerExpiredButtonTooltip') as string}
          >
            <Button
              className="mt-2 d-print-none offer-prices__expired"
              kind="tertiary"
              size="field"
              renderIcon={ChevronRight16}
              disabled={true}
            >
              {t('result.tariffsExpired')}
            </Button>
          </TooltipIcon>
        )}
      </div>
    </div>
  );
};

export default ResultOfferPrices;
