/* eslint-disable react/jsx-pascal-case */
import React from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  CheckmarkOutline32,
  Number_132,
  Number_232,
  Number_332,
  Number_432,
  Number_532,
} from '@developers/graphite/carbon-icons';
import { Button, ListItem, UnorderedList } from '@developers/graphite/carbon-components';
import { ValidatedLink } from '@developers/graphite';

import { URLPart } from '@enums';
import { useMainState } from '@hooks';
import useGlobalStore from '@useGlobalStore';

type Props = {
  inquiryId: string | undefined;
};

const SuccessPageB2C: React.FC<Props> = ({ inquiryId }) => {
  const { t } = useTranslation();
  const { inquiryInfo } = useMainState();
  const portalConfig = useGlobalStore((state) => state.portalConfig);

  const listItems: string[] = t('tender.successPage.listItemsb2c', { returnObjects: true });

  const orderedListCustomNumbers = [
    { icon: <Number_132 className="b2c-success__list-number" /> },
    { icon: <Number_232 className="b2c-success__list-number" /> },
    { icon: <Number_332 className="b2c-success__list-number" /> },
    { icon: <Number_432 className="b2c-success__list-number" /> },
    { icon: <Number_532 className="b2c-success__list-number" /> },
  ];

  return (
    <div className="bx--grid container--custom">
      <div className="b2c-success__content">
        <div className="b2c-success__wrapper">
          <div className="b2c-success__icon">
            <CheckmarkOutline32 className="b2c-success__success-icon" />
          </div>
          <div className="b2c-success__title">{t('tender.successPage.title')}</div>
          <div className="b2c-success__sub-title">
            {t('tender.successPage.subtitleStart', {
              variable: inquiryId,
            })}
          </div>
          <div className="b2c-success__list-title">{t('tender.successPage.listTitle')}</div>

          <UnorderedList className="bcb-success__list">
            {listItems.map((item, index) => {
              return (
                <ListItem
                  className="b2c-success__list-item"
                  key={item}
                  data-qa="b2c-success__list-item"
                >
                  {orderedListCustomNumbers[index].icon}
                  <span>{item}</span>
                </ListItem>
              );
            })}
          </UnorderedList>

          {!portalConfig?.productId && (
            <ValidatedLink
              url={`${URLPart.ProductPage}/${inquiryInfo?.groupHash}`}
              render={(url) => (
                <Button as={Link} to={url} className="b2c-success__back-btn">
                  {t('tender.successPage.buttonText')}
                </Button>
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SuccessPageB2C;
