import React from 'react';

import { get } from 'lodash';
import { WarningFilled16 } from '@developers/graphite/carbon-icons';
import { Controller, Control, RegisterOptions, FieldErrors } from 'react-hook-form';
import cx from 'classnames';
import {
  FileUploaderDropContainer,
  FileUploaderItem,
  FileUploaderDropContainerProps,
  FileUploaderItemProps,
} from '@developers/graphite/carbon-components';

import { ErrorSubject, UploaderValue } from '@typings/fileUploader';
import { Document } from '@api/fileService';

interface Props {
  name: string;
  className?: string;
  control: Control;
  rules?: RegisterOptions;
  title?: React.ReactNode;
  description?: string;
  light?: boolean;
  fileUploaderContainer: FileUploaderDropContainerProps;
  fileUploaderItem: FileUploaderItemProps;
  errors: FieldErrors;
  errorSubject: ErrorSubject;
  multiple?: boolean;
  defaultValue?: UploaderValue[] | UploaderValue | null;
  fieldErrorMessage?: string;
  disabled?: boolean;
}

const ControlledFileUploader: React.FC<Props> = ({
  name,
  errors,
  className,
  control,
  rules,
  title,
  description,
  light,
  fileUploaderContainer,
  fileUploaderItem,
  errorSubject,
  multiple = false,
  defaultValue = null,
  fieldErrorMessage,
  disabled = false,
}) => {
  const renderFileItems = (value: unknown[]): JSX.Element | JSX.Element[] => {
    if (!value) return <></>;

    const renderFileItem = (item: UploaderValue) => {
      if (!item.file) return <></>;

      const error = multiple ? errors[item.innerId] : get(errors, name);

      return (
        <FileUploaderItem
          key={item.innerId}
          uuid={item.innerId}
          name={
            (item.file as File)?.lastModified
              ? item.file.name
              : `${item.file.name}.${(item.file as Document)?.extension}`
          }
          status={item.status}
          invalid={!!error?.message}
          errorSubject={errorSubject[error?.type]}
          errorBody={error?.message}
          {...fileUploaderItem}
        />
      );
    };

    if (Array.isArray(value)) {
      return value.map((item) => renderFileItem(item));
    }

    return renderFileItem(value);
  };

  return (
    <div
      className={cx('bx--file__container controlled-file-uploader', {
        'controlled-file-uploader--light': light,
        'controlled-file-uploader--disabled': disabled,
        className,
      })}
    >
      {title && (
        <strong className="bx--file--label controlled-file-uploader__title">{title}</strong>
      )}
      {description && <p className="bx--label-description">{description}</p>}
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        rules={rules}
        render={({ value }) => (
          <>
            <FileUploaderDropContainer
              multiple={multiple}
              disabled={disabled}
              {...fileUploaderContainer}
            />
            {renderFileItems(value)}
            {fieldErrorMessage && (
              <div className="controlled-file-uploader__error-wrapper">
                <WarningFilled16 className="controlled-file-uploader__error-icon" />
                <span className="controlled-file-uploader__error-text">{fieldErrorMessage}</span>
              </div>
            )}
          </>
        )}
      />
    </div>
  );
};

export default ControlledFileUploader;
