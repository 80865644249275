import numeral from 'numeral';

const fallbackLocale = 'de';

const setup = (locale: string): void => {
  // we need to check if locale is alredy registered otherwise it fails for example in case of hot reload
  if (numeral.locales.de === undefined) {
    numeral.register('locale', 'de', {
      delimiters: {
        thousands: '.',
        decimal: ',',
      },
      abbreviations: {
        thousand: 'Tsd.',
        million: 'Mio.',
        billion: 'Mrd.',
        trillion: 'Bio.',
      },
      ordinal() {
        return '.';
      },
      currency: {
        symbol: '€',
      },
    });
  }

  // we need to check if locale is alredy registered otherwise it fails for example in case of hot reload
  if (numeral.locales.fr === undefined) {
    numeral.register('locale', 'fr', {
      delimiters: {
        thousands: ' ',
        decimal: ',',
      },
      abbreviations: {
        thousand: 'k',
        million: 'M',
        billion: 'Md',
        trillion: 'Bn',
      },
      ordinal(number: number) {
        return number === 1 ? 'er' : 'e';
      },
      currency: {
        symbol: '€',
      },
    });
  }

  // check if settings for the provided locale exist
  if (numeral.locales[locale]) {
    // set locale
    numeral.locale(locale);
  } else {
    // set fallback locale
    numeral.locale(fallbackLocale);
  }
};

export { setup };
