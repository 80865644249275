import { useEffect } from 'react';

import { helpers } from '@helpers';

import { useGlobalState } from './useGlobalState';

type Heap = {
  identify: (s: string) => void;
  resetIdentity: () => void;
};

declare global {
  interface Window {
    heap: Heap;
  }
}

function useIdentifyHeap(): void {
  const { brokerSettings } = useGlobalState();

  useEffect(() => {
    if (!helpers.envVariables.REACT_APP_HEAP_ANALYTICS || !window.heap) {
      return;
    }

    window.heap.identify(brokerSettings?.brokerCode);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brokerSettings?.brokerCode, !window.heap]);
}
export default useIdentifyHeap;
