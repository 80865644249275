import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { API, helpers } from '@helpers';
import { ApiQuery } from '@enums';

export interface InquiryCustomer {
  id: number;
  firstName: string;
  lastName: string;
  company: string;
  street: string;
  streetNumber: string;
  zip: string;
  city: string;
  phone: string;
  email: string;
  salutation: string;
  title: string;
  legalForm: boolean;
  fullName: string;
}

const useCustomerData = (
  customerId?: string,
  options: UseQueryOptions<InquiryCustomer, Error> = {}
): QueryObserverResult<InquiryCustomer, Error> => {
  return useQuery<InquiryCustomer, Error>(
    [ApiQuery.CustomerData, customerId],
    async () => {
      const response = await API.get<InquiryCustomer>(
        helpers.isWidget
          ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1/customers/${customerId}.json`
          : `/api/e2e/v1/customers/${customerId}.json`
      );

      return response;
    },
    {
      ...options,
      enabled: (options.enabled ?? true) && !!customerId,
    }
  );
};

export default useCustomerData;
