import React from 'react';

import { TooltipDefinition, TooltipIcon } from '@developers/graphite/carbon-components';

import { Highlight } from '@typings/resultPage';

import { DefinedIcon } from '..';

type Props = {
  highlightFeatures: Highlight[];
};

const ResultOfferHighlight: React.FC<Props> = ({ highlightFeatures }) => {
  return (
    <ul className="offer-highlight__ul">
      {highlightFeatures.map((line) => {
        const { id, iconType, value, info, name } = line;
        return (
          <li key={id} className="offer-highlight mb-2">
            <div className="me-3">
              {value ? (
                <TooltipIcon
                  // eslint-disable-next-line react/no-danger
                  tooltipText={<div dangerouslySetInnerHTML={{ __html: value }} />}
                  direction="bottom"
                >
                  <DefinedIcon iconType={iconType} />
                </TooltipIcon>
              ) : (
                <DefinedIcon iconType={iconType} />
              )}
            </div>

            {info ? (
              <TooltipDefinition className="offer-highlight__text" tooltipText={info} align="start">
                {name}
              </TooltipDefinition>
            ) : (
              <span className="offer-highlight__text">{name}</span>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default ResultOfferHighlight;
