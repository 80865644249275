export const defaults = (() => {
  const values = {
    baseUrl: '',
  };

  return {
    get baseUrl() {
      return values.baseUrl;
    },
    set baseUrl(url: string) {
      values.baseUrl = url;
    },
  };
})();
