import { IconType, IconColor } from '@enums';

export const defineIconColor = (iconType: string | null | undefined): string | undefined => {
  let iconColor;
  switch (iconType) {
    case IconType.CheckIcon:
      iconColor = IconColor.Green;
      break;
    case IconType.CheckAverageIcon:
      iconColor = IconColor.Yellow;
      break;
    case IconType.CloseIcon:
      iconColor = IconColor.Red;
      break;
    default:
  }
  return iconColor;
};
export default defineIconColor;
