import { useTranslation } from 'react-i18next';

import { AccountSetting, UserRoles } from '@enums';
import { BookingSetResponseDtoSettings, CreateBookingSetDtoTypeEnum } from '@api/booking';

interface TextsForBookingSuccessStep {
  title: string;
  textArr: string[];
}

interface TextKeys {
  titleKey: string;
  textArrKey: string;
}

export const useTextsForBookingSuccessStep = (
  role: string,
  flow: string | null,
  accountSettings?: BookingSetResponseDtoSettings[]
): TextsForBookingSuccessStep => {
  const { t } = useTranslation();
  let title = '';
  let textArr: string[] = [];
  let titleKey = '';
  let textArrKey = '';

  if (accountSettings)
    ({ titleKey, textArrKey } = getTextKeysBasedOnSettings(accountSettings, flow));

  if (titleKey === '' || textArrKey === '')
    ({ titleKey, textArrKey } = getTextKeysBasedOnRole(role, flow));

  title = t(titleKey);
  textArr = t(textArrKey, { returnObjects: true });

  return { title, textArr };
};

export default useTextsForBookingSuccessStep;

const getTextKeysBasedOnRole = (role: string, flow: string | null): TextKeys => {
  let titleKey = '';
  let textArrKey = '';

  switch (role) {
    case UserRoles.B2B: {
      if (flow === CreateBookingSetDtoTypeEnum.Proposal) {
        titleKey = `bookingSuccessStep.title.proposal`;
        textArrKey = `bookingSuccessStep.texts.proposal`;
      } else {
        titleKey = `bookingSuccessStep.title.cover`;
        textArrKey = `bookingSuccessStep.texts.cover`;
      }
      break;
    }
    case UserRoles.B2C:
      titleKey = `bookingSuccessStep.title.b2c`;
      textArrKey = `bookingSuccessStep.texts.b2c`;
      break;
  }
  return { titleKey, textArrKey };
};

const getTextKeysBasedOnSettings = (
  accountSettings: BookingSetResponseDtoSettings[],
  flow: string | null
): TextKeys => {
  let titleKey = '';
  let textArrKey = '';

  // TMP: Right now we are using only first setting, but this should be improved in the future to support many settings
  switch (accountSettings[0].name) {
    case AccountSetting.SLS: {
      if (accountSettings[0].value && flow === CreateBookingSetDtoTypeEnum.CoverNote) {
        titleKey = `bookingSuccessStep.title.SLS`;
        textArrKey = `bookingSuccessStep.texts.SLS`;
        break;
      }
    }
  }

  return { titleKey, textArrKey };
};
