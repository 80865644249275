import React from 'react';

import { helpers } from '@helpers';

const MaintenancePage: React.FC = () => {
  return (
    <>
      <style>
        {`#root{
            overflow: hidden;
          }`}
      </style>

      <iframe
        title="Die Plattform ist kurzzeitig wegen Wartungsarbeiten außer Betrieb"
        src={helpers.envVariables.REACT_APP_MAINTENANCE_URL}
        allowFullScreen
        style={{
          overflow: 'hidden',
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: '100%',
          height: '100%',
          border: 'none',
          margin: 0,
          padding: 0,
          zIndex: 999999,
        }}
      />
    </>
  );
};

export default MaintenancePage;
