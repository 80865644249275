import { faCheck, faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { IconType, IconColor } from '@enums';

type Icons = {
  icon: IconDefinition | undefined;
  iconColor: IconColor | undefined;
};

export const defineIcons = (iconType: string | null | undefined): Icons => {
  let icon;
  let iconColor;
  switch (iconType) {
    case IconType.CheckIcon:
      icon = faCheck;
      iconColor = IconColor.Green;
      break;
    case IconType.CheckAverageIcon:
      icon = faCheck;
      iconColor = IconColor.Yellow;
      break;
    case IconType.CloseIcon:
      icon = faTimes;
      iconColor = IconColor.Red;
      break;
    default:
  }
  return { icon, iconColor };
};
export default defineIcons;
