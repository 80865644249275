import React, { useEffect } from 'react';

import { v4 as uuidv4 } from 'uuid';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FieldValues, UseFormMethods } from 'react-hook-form';

import { helpers } from '@helpers';
import { ControlledFileUploader } from '@shared';
import { documentsService } from '@services/fileService';
import { useUploadValidation } from '@hooks';
import { FileStatus, UploaderValue } from '@typings/fileUploader';
import { Document } from '@api/fileService';

interface Props {
  acceptedFileTypes: string[];
  acceptedFileMIMETypes: string[];
  fileSizeLimit: number;
  fieldName: string;
  docType: string;
  docPath: string;
  methods: UseFormMethods<FieldValues>;
  isMultipleFilesUploader?: boolean;
  defaultValue?: UploaderValue[] | UploaderValue;
  invalidText?: string;
  maxFileAmount?: number;
  filesTotalSizeLimit?: number;
}

// TODO: refactor and combine with ControlledFileUploader.
// We should have a generic component that support single or multiply file upload.
const FileUploader: React.FC<Props> = ({
  acceptedFileTypes,
  fileSizeLimit,
  fieldName,
  docType,
  docPath,
  methods,
  defaultValue,
  isMultipleFilesUploader = false,
  acceptedFileMIMETypes,
  invalidText,
  maxFileAmount,
  filesTotalSizeLimit,
}) => {
  const { t } = useTranslation();
  const { setValue, trigger, errors, control, getValues, setError, clearErrors } = methods;
  const uploadDocument = documentsService.useUploadDocument();
  const deleteDocument = documentsService.useDeleteDocument();
  const { singleFileValidations, multipleFileValidations, errorSubject } = useUploadValidation({
    setError: isMultipleFilesUploader ? setError : undefined,
    acceptedFileTypes,
    fileSizeLimit,
    maxFileAmount,
    filesTotalSizeLimit,
  });
  useEffect(() => {
    if (defaultValue) {
      setValue(fieldName, defaultValue);
    }
  }, [defaultValue, fieldName, setValue]);

  // -----------------------handlers for MULTIPLE FILES uploader-----------------------------

  const onMultipleFilesAdd = (
    _event: React.DragEvent<HTMLElement>,
    content: {
      addedFiles: File[];
    }
  ) => {
    const newFiles = content.addedFiles;
    const newFilesArr: UploaderValue[] = [];
    // Add additional properties to be able to show files as FileUploaderItem components
    newFiles.forEach((file) => {
      newFilesArr.push({ innerId: uuidv4(), file, status: FileStatus.Uploading, error: false });
    });
    // Adding new files to the existing ones
    const currentInputValue = getValues(fieldName);
    setValue(fieldName, currentInputValue ? [...currentInputValue, ...newFilesArr] : newFilesArr);
    trigger(fieldName).then(() => {
      const filesToUpload: UploaderValue[] = [];
      const invalidFiles: UploaderValue[] = [];
      newFilesArr.forEach((item) => {
        if (errors[item.innerId]?.message) {
          invalidFiles.push({ ...item, status: FileStatus.Edit });
        } else {
          filesToUpload.push(item);
        }
      });
      const result = getValues(fieldName).map((item: UploaderValue) => {
        const invalidFile = invalidFiles.find((file) => file.innerId === item.innerId);
        return invalidFile || item;
      });
      // showing all files on the page with FileStatus.Edit and all not valid files with FileStatus.Edit and errors
      setValue(fieldName, result);
      // calling file-server only for valid files
      if (filesToUpload.length > 0) {
        dynamicArrayMutation(filesToUpload, 0);
      }
    });
  };

  const dynamicArrayMutation = (fileArr: UploaderValue[], index: number) => {
    const mutateVariables = {
      docType,
      docPath,
      file: fileArr[index].file as File,
    };
    uploadDocument.mutate(mutateVariables, {
      onSettled: (data, error) => {
        let updatedFile = {};
        if (data) {
          updatedFile = {
            ...fileArr[index],
            file: { ...data, size: (fileArr[index].file as File).size },
            status: FileStatus.Edit,
          };
        }
        if (error) {
          updatedFile = {
            ...fileArr[index],
            status: FileStatus.Edit,
            error: true,
          };
        }
        const currentInputValue = getValues(fieldName);
        // Changing existing file with the new file (regarding its id)
        const result = currentInputValue.map((item: UploaderValue) =>
          item.innerId === fileArr[index].innerId ? updatedFile : item
        );
        setValue(fieldName, result);
        // trigger file input validation one more time to be able to catch updatedFile.error errors
        if (error) {
          trigger(fieldName);
        }
        // if there is more files to upload call the function one more time
        if (fileArr.length - 1 > index) {
          dynamicArrayMutation(fileArr, index + 1);
        }
      },
    });
  };

  const onMultipleFilesDelete = (event: unknown, content: { uuid: string }) => {
    const currentValue: UploaderValue[] = getValues(fieldName);
    const filteredArr = currentValue.filter(
      (element: UploaderValue) => element.innerId !== content.uuid
    );
    const itemToDelete = currentValue.find(
      (element: UploaderValue) => element.innerId === content.uuid
    );
    // eslint-disable-next-line no-underscore-dangle
    const itemIdFromFileService = (itemToDelete?.file as Document)._id;
    if (itemIdFromFileService) {
      deleteDocument.mutate(itemIdFromFileService);
    }
    setValue(fieldName, filteredArr);
    trigger(fieldName);
  };

  // -----------------------handlers for SINGLE FILE uploader-----------------------------

  const onSingleFileAdd = (
    _event: React.DragEvent<HTMLElement>,
    content: {
      addedFiles: File[];
    }
  ) => {
    const file = content.addedFiles[0];

    const newFile = {
      innerId: uuidv4(),
      file,
      status: FileStatus.Uploading,
      error: false,
    };
    setValue(fieldName, newFile);

    trigger(fieldName).then((isValid) => {
      if (!isValid) {
        setValue(fieldName, {
          ...newFile,
          status: FileStatus.Edit,
        });
      } else {
        const filePayload = {
          docType,
          docPath,
          file,
        };

        uploadDocument.mutate(filePayload, {
          onSuccess: (data) => {
            const fileData = {
              file: data,
              status: FileStatus.Edit,
              error: false,
            };
            setValue(fieldName, fileData);
            clearErrors(fieldName);
          },
          onError: () => {
            newFile.status = FileStatus.Edit;
            newFile.error = true;
            setValue(fieldName, newFile);
            trigger(fieldName);
          },
        });
      }
    });
  };

  const onSingleFileDelete = (event: unknown, content: { uuid: string }) => {
    setValue(fieldName, null);
  };

  const multipleFilesError = isMultipleFilesUploader && get(errors, fieldName);

  return (
    <ControlledFileUploader
      rules={isMultipleFilesUploader ? multipleFileValidations : singleFileValidations}
      fileUploaderContainer={{
        accept: acceptedFileMIMETypes,
        labelText: `${t(`fileUpload.containerText`)}`,
        tabIndex: 0,
        onAddFiles: isMultipleFilesUploader ? onMultipleFilesAdd : onSingleFileAdd,
        'aria-label': 'file-uploader-input',
      }}
      fileUploaderItem={{
        onDelete: isMultipleFilesUploader ? onMultipleFilesDelete : onSingleFileDelete,
      }}
      name={fieldName}
      control={control}
      description={
        isMultipleFilesUploader
          ? t('fileUpload.descriptionMultipleFiles', {
              types: acceptedFileTypes.join(', '),
              fileSize: helpers.formatBytes(fileSizeLimit),
              maxFileAmount: maxFileAmount || 1,
              filesTotalSizeLimit: helpers.formatBytes(filesTotalSizeLimit || fileSizeLimit),
            })
          : t('fileUpload.description', {
              types: acceptedFileTypes.join(', '),
              fileSize: helpers.formatBytes(fileSizeLimit),
            })
      }
      className="general-data__file-uploader"
      multiple={isMultipleFilesUploader}
      defaultValue={defaultValue || (isMultipleFilesUploader ? [] : null)}
      errors={errors}
      errorSubject={errorSubject}
      fieldErrorMessage={multipleFilesError?.message || invalidText}
    />
  );
};
export default FileUploader;
