import create from 'zustand';

import { ConfigAppliedFilters, FormattedResult } from '@typings/resultPage';

type State = {
  discount: number;
  agreementDate: string;
  coordinatedWith: string;
  comment: string;
  discountType: string;
  price: number;
  isSuccessAddRemoveComparison: boolean;
  activeFilters: ConfigAppliedFilters[];
  resultTable: FormattedResult;
  tenderReason: string;
  setDiscount: (discount: number) => void;
  setAgreementDate: (agreementDate: string) => void;
  setCoordinatedWith: (coordinatedWith: string) => void;
  setComment: (comment: string) => void;
  setDiscountType: (discountType: string) => void;
  setPrice: (price: number) => void;
  setIsSuccessAddRemoveComparison: (isSuccessAddRemoveComparison: boolean) => void;
  setActiveFilters: (activeFilters: ConfigAppliedFilters[]) => void;
  setResultTable: (resultTable: FormattedResult) => void;
  setTenderReason: (tenderReason: string) => void;
};

const useStore = create<State>((set) => ({
  discount: 0,
  agreementDate: '',
  coordinatedWith: '',
  comment: '',
  discountType: 'booking-discount',
  price: 0,
  isSuccessAddRemoveComparison: true,
  activeFilters: [],
  resultTable: {} as FormattedResult,
  tenderReason: '',
  setDiscount: (payload: number) => set({ discount: payload }),
  setAgreementDate: (payload: string) => set({ agreementDate: payload }),
  setCoordinatedWith: (payload: string) => set({ coordinatedWith: payload }),
  setComment: (payload: string) => set({ comment: payload }),
  setDiscountType: (payload: string) => set({ discountType: payload }),
  setPrice: (payload: number) => set({ price: payload }),
  setIsSuccessAddRemoveComparison: (payload: boolean) =>
    set({ isSuccessAddRemoveComparison: payload }),
  setActiveFilters: (payload: ConfigAppliedFilters[]) => set({ activeFilters: payload }),
  setResultTable: (payload: FormattedResult) => set({ resultTable: payload }),
  setTenderReason: (payload: string) => set({ tenderReason: payload }),
}));

export default useStore;
