import React, { useState } from 'react';

import {
  Button,
  ComposedModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  TextInput,
  TextInputSkeleton,
} from '@developers/graphite/carbon-components';
import { useTranslation } from 'react-i18next';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';
import { regexHelpers } from '@developers/toolbox';
import { LabelWithTooltip } from '@developers/graphite';

import { inquiriesService, subscriptionServices } from '@services/consultation';
import { useGlobalState, useMainState } from '@hooks';

import { DefinedIcon } from '..';

type Props = {
  open: boolean;
  setSaveRequestModalVisible: (arg: boolean) => void;
};

type IFormInput = {
  email: string;
  phone?: string;
};

const SaveRequestModal: React.FC<Props> = ({ open, setSaveRequestModalVisible }) => {
  const { brokerSettings, settings } = useGlobalState();
  const { inquiryInfo, inquiryData } = useMainState();
  const { t } = useTranslation();
  const { control, handleSubmit, errors } = useForm<IFormInput>();

  const [disableInput, setDisableInput] = useState(false);

  const saveRequestData = subscriptionServices.useSubscription();

  const { data: inquiry } = inquiriesService.useGetInquiry(inquiryData?.id.toString());
  const privacyPolicyUrl = settings?.privacyPolicyUrl ?? '';
  const generalConditionsUrl = settings?.generalConditionsUrl ?? '';

  const customerDetails = inquiry?.consultation?.customer?.contactPerson;
  const defaultEmailValue = customerDetails?.email || '';
  const defaultPhoneValue = customerDetails?.phone || '';

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    const subscriptionData = { pageType: 'result', ...data };
    saveRequestData.mutate(
      { inquiryId: String(inquiryData?.id), body: subscriptionData },
      {
        onSuccess: () => {
          setDisableInput(true);
          setSaveRequestModalVisible(false);
        },
        onError: () => {
          toast.info(`${t('apiErrorMessage')}`);
        },
      }
    );
  };

  const features: string[] = [
    t('subscriptionModal.feature2'),
    t('subscriptionModal.feature3'),
    t('subscriptionModal.feature4'),
    t('subscriptionModal.feature5'),
  ];

  return (
    <ComposedModal
      open={open}
      preventCloseOnClickOutside={true}
      onClose={() => setSaveRequestModalVisible(false)}
      size="sm"
      className="dismissal-modal"
    >
      <ModalHeader className="font-weight-bold h5">
        <h5>
          {t('subscriptionModal.title')} - {inquiryInfo?.productName} {t('general.for')}{' '}
          {inquiryInfo?.professionAlias}
        </h5>
      </ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <p className="mb-4">{t('subscriptionModal.description')}</p>
          <ul className="mb-4">
            {features.map((text) => {
              return (
                <li key={text} className="d-flex mb-2">
                  <div className="me-3">
                    <DefinedIcon iconType="check" className="text-secondary" />
                  </div>
                  <span>{text}</span>
                </li>
              );
            })}
          </ul>

          <div className="mt-4 mb-4">
            {inquiry ? (
              <Controller
                name="email"
                control={control}
                defaultValue={defaultEmailValue}
                rules={{
                  required: `${t('validationMessage.wrongEmail')}`,
                  pattern: {
                    value: regexHelpers.patterns.email,
                    message: `${t('validationMessage.wrongEmail')}`,
                  },
                }}
                render={({ onChange, value }) => (
                  <TextInput
                    id="save-request-modal-email"
                    labelText={
                      <LabelWithTooltip
                        label={`${t('general.emailAddressLabel')}`}
                        tooltipText={`${t('subscriptionModal.emailTooltip')}`}
                        direction="right"
                      />
                    }
                    value={value}
                    /* TMP For now, we are not able to change data if we already submit request,
                    and because of that, input needs to be disabled in that case */
                    disabled={disableInput || !!customerDetails}
                    invalid={!!errors?.email}
                    invalidText={errors?.email?.message}
                    onChange={onChange}
                    data-qa="subscriptionEmail"
                  />
                )}
              />
            ) : (
              <TextInputSkeleton />
            )}
          </div>

          <div className="mb-4">
            {inquiry ? (
              <Controller
                name="phone"
                control={control}
                defaultValue={defaultPhoneValue}
                render={({ onChange, value }) => (
                  <TextInput
                    id="save-request-modal-phone"
                    labelText={
                      <LabelWithTooltip
                        label={`${t('general.phoneLabel')} (${t('general.optional')})`}
                        tooltipText={`${t('subscriptionModal.phoneTooltip')}`}
                        direction="right"
                      />
                    }
                    value={value}
                    /* TMP For now, we are not able to change data if we already submit request,
                     and because of that, input needs to be disabled in that case */
                    disabled={disableInput || !!customerDetails}
                    onChange={onChange}
                    data-qa="subscriptionPhone"
                  />
                )}
              />
            ) : (
              <TextInputSkeleton />
            )}
          </div>
          <p className="mt-4">
            <>
              {t('subscriptionModal.info.statement')}{' '}
              <a
                href={privacyPolicyUrl}
                data-qa="privacy-policy-url"
                target="_blank"
                rel="noreferrer"
              >
                {t('subscriptionModal.info.privacyPolicy')}
              </a>{' '}
              {t('subscriptionModal.info.and')}{' '}
              <a
                href={generalConditionsUrl}
                data-qa="general-conditions-url"
                target="_blank"
                rel="noreferrer"
              >
                {t('subscriptionModal.info.generalConditions')}
              </a>{' '}
              {t('general.from')} {brokerSettings.pool.name}.
            </>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              setSaveRequestModalVisible(false);
            }}
            kind="ghost"
          >
            {t('general.cancel')}
          </Button>
          <Button type="submit" kind="primary">
            {t('result.saveRequestButton')}
          </Button>
        </ModalFooter>
      </Form>
    </ComposedModal>
  );
};
export default SaveRequestModal;
