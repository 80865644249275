/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Mode } from '@developers/toolbox';
import { isAfter } from 'date-fns';

import { SessionStorage } from '@enums';

interface EnvVariables {
  readonly mode: string;
  REACT_APP_HEAP_ANALYTICS: boolean;
  REACT_APP_HEAP_ANALYTICS_KEY: string;
  REACT_APP_STONLY_KEY: string;
  REACT_APP_MOUSE_FLOW: boolean;
  REACT_APP_MOUSE_FLOW_B2B_KEY: string;
  REACT_APP_MOUSE_FLOW_B2C_KEY: string;
  REACT_APP_USE_RESULT_PAGE: boolean;
  REACT_APP_USE_BOOKING_PAGE: boolean;
  REACT_APP_USE_DEALBREAK_PAGE: boolean;
  REACT_APP_USE_PRODUCT_PAGE: boolean;
  REACT_APP_USE_QUESTIONNAIRE_PAGE: boolean;
  REACT_APP_USE_COMPARISON_PAGE: boolean;
  REACT_APP_USE_TENDER_PAGE: boolean;
  REACT_APP_IS_WIDGET: boolean;
  REACT_APP_IS_WIDGET_DEV_MODE: boolean;
  REACT_APP_BASE_URL: string;
  REACT_APP_API_CONTROLLER: string;
  REACT_APP_WIDGET_BASE_URL: string;
  REACT_APP_TRANSLATIONS_URL: string;
  REACT_APP_ADVISORY_BASE_URL: string;
  REACT_APP_ADVISORY_REDIRECT_TIMEOUT: string;
  REACT_APP_DISPLAY_REDIRECTION_MODAL: boolean;
  REACT_APP_MOUSEFLOW_DISABLED_POOLS: string;
  REACT_APP_GOOGLE_RECAPTCHA_V2_KEY: string;
  REACT_APP_WIDGET_USE_LANDING_PAGE: boolean;
  REACT_APP_MAINTENANCE_URL: string;
}

// TMP Remove ternary operator after we move to Next.js
export const envVariables: EnvVariables =
  process.env.NODE_ENV === Mode.Development ||
  process.env.REACT_APP_IS_WIDGET?.toLowerCase() === 'true'
    ? {
        mode: process.env.NODE_ENV || '',
        REACT_APP_HEAP_ANALYTICS: process.env.REACT_APP_HEAP_ANALYTICS?.toLowerCase() === 'true',
        REACT_APP_HEAP_ANALYTICS_KEY: process.env.REACT_APP_HEAP_ANALYTICS_KEY || '',
        REACT_APP_STONLY_KEY: process.env.REACT_APP_STONLY_KEY || '',
        REACT_APP_MOUSE_FLOW: process.env.REACT_APP_MOUSE_FLOW?.toLowerCase() === 'true',
        REACT_APP_MOUSE_FLOW_B2B_KEY: process.env.REACT_APP_MOUSE_FLOW_B2B_KEY || '',
        REACT_APP_MOUSE_FLOW_B2C_KEY: process.env.REACT_APP_MOUSE_FLOW_B2C_KEY || '',
        REACT_APP_USE_RESULT_PAGE: process.env.REACT_APP_USE_RESULT_PAGE?.toLowerCase() === 'true',
        REACT_APP_USE_BOOKING_PAGE:
          process.env.REACT_APP_USE_BOOKING_PAGE?.toLowerCase() === 'true',
        REACT_APP_USE_PRODUCT_PAGE:
          process.env.REACT_APP_USE_PRODUCT_PAGE?.toLowerCase() === 'true',
        REACT_APP_USE_DEALBREAK_PAGE:
          process.env.REACT_APP_USE_DEALBREAK_PAGE?.toLowerCase() === 'true',
        REACT_APP_USE_QUESTIONNAIRE_PAGE:
          process.env.REACT_APP_USE_QUESTIONNAIRE_PAGE?.toLowerCase() === 'true',
        REACT_APP_USE_COMPARISON_PAGE:
          process.env.REACT_APP_USE_COMPARISON_PAGE?.toLowerCase() === 'true',
        REACT_APP_USE_TENDER_PAGE: process.env.REACT_APP_USE_TENDER_PAGE?.toLowerCase() === 'true',
        REACT_APP_IS_WIDGET: process.env.REACT_APP_IS_WIDGET?.toLowerCase() === 'true',
        REACT_APP_IS_WIDGET_DEV_MODE:
          process.env.REACT_APP_IS_WIDGET_DEV_MODE?.toLowerCase() === 'true',
        REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL || '',
        REACT_APP_API_CONTROLLER: process.env.REACT_APP_API_CONTROLLER || '',
        REACT_APP_TRANSLATIONS_URL: process.env.REACT_APP_TRANSLATIONS_URL || '',
        REACT_APP_WIDGET_BASE_URL: process.env.REACT_APP_WIDGET_BASE_URL || '',
        REACT_APP_ADVISORY_BASE_URL: process.env.REACT_APP_ADVISORY_BASE_URL || '',
        REACT_APP_ADVISORY_REDIRECT_TIMEOUT: process.env.REACT_APP_ADVISORY_REDIRECT_TIMEOUT || '0',
        REACT_APP_DISPLAY_REDIRECTION_MODAL:
          process.env.REACT_APP_DISPLAY_REDIRECTION_MODAL?.toLowerCase() === 'true',
        REACT_APP_MOUSEFLOW_DISABLED_POOLS: process.env.REACT_APP_MOUSEFLOW_DISABLED_POOLS || '',
        REACT_APP_GOOGLE_RECAPTCHA_V2_KEY: process.env.REACT_APP_GOOGLE_RECAPTCHA_V2_KEY || '',
        REACT_APP_WIDGET_USE_LANDING_PAGE:
          process.env.REACT_APP_WIDGET_USE_LANDING_PAGE?.toLowerCase() === 'true',
        REACT_APP_MAINTENANCE_URL: process.env.REACT_APP_MAINTENANCE_URL ?? '',
      }
    : {
        mode: process.env.NODE_ENV || '',
        REACT_APP_HEAP_ANALYTICS: false,
        REACT_APP_HEAP_ANALYTICS_KEY: '',
        REACT_APP_STONLY_KEY: '',
        REACT_APP_MOUSE_FLOW: false,
        REACT_APP_MOUSE_FLOW_B2B_KEY: '',
        REACT_APP_MOUSE_FLOW_B2C_KEY: '',
        REACT_APP_USE_PRODUCT_PAGE: false,
        REACT_APP_USE_RESULT_PAGE: false,
        REACT_APP_USE_DEALBREAK_PAGE: true,
        REACT_APP_USE_BOOKING_PAGE: false,
        REACT_APP_USE_QUESTIONNAIRE_PAGE: false,
        REACT_APP_USE_COMPARISON_PAGE: false,
        REACT_APP_USE_TENDER_PAGE: false,
        REACT_APP_IS_WIDGET: false,
        REACT_APP_IS_WIDGET_DEV_MODE: false,
        REACT_APP_BASE_URL: '',
        REACT_APP_API_CONTROLLER: '',
        REACT_APP_TRANSLATIONS_URL: '',
        REACT_APP_WIDGET_BASE_URL: '',
        REACT_APP_ADVISORY_BASE_URL: '',
        REACT_APP_ADVISORY_REDIRECT_TIMEOUT: '0',
        REACT_APP_DISPLAY_REDIRECTION_MODAL: false,
        REACT_APP_MOUSEFLOW_DISABLED_POOLS: '',
        REACT_APP_GOOGLE_RECAPTCHA_V2_KEY: '',
        REACT_APP_WIDGET_USE_LANDING_PAGE: false,
        REACT_APP_MAINTENANCE_URL: '',
      };

const checkCurrencyTypeFormat = (element: string): boolean => {
  return /^([0-9.]+)+,[0-9]{2}$/.test(element);
};

// INFO: there are couples of any in this function, because params that come to the function could be different
const flatTheObject = (objectItem: any, keyName: string): any => {
  let flatObject = {};
  const flatTheObjectFunc = (item: any, name: string) => {
    const isArray = Array.isArray(item);
    const objectEntries = isArray ? item : Object.entries(item);
    objectEntries.forEach((element: any[], key: number) => {
      const elementToCheck = isArray ? element : element[1];
      const elementType = typeof elementToCheck;
      const objKey = isArray ? key : element[0];
      const objFullKey = name ? `${name}[${objKey}]` : `${objKey}`;
      if (elementType === 'string' || elementType === 'number') {
        const elementObject = { [objFullKey]: element[1] };
        flatObject = { ...flatObject, ...elementObject };
      } else if (elementType === 'object') {
        flatTheObjectFunc(elementToCheck, objFullKey);
      }
    });
    return flatObject;
  };
  return flatTheObjectFunc(objectItem, keyName);
};

const getNumberOfStars = (i: number): number => {
  let numberOfStars = 0;
  switch (i) {
    case 0:
      numberOfStars = 3;
      break;
    case 1:
      numberOfStars = 2;
      break;
    case 2:
      numberOfStars = 1;
      break;
    default:
      numberOfStars = 0;
  }
  return numberOfStars;
};

const setStickyPosition = (left = 'auto'): void => {
  const sticky = document.querySelector<HTMLDivElement>('.sticky');
  if (sticky) {
    sticky.style.left = left;
  }
};

/**
 * @param {string} url
 */
function removeTrailingSlashes(url: string): string {
  return url.replace(/\/+$/, '');
}

// TODO: refactor this and reduce complexity
const highlightItemsWhileScrolling = (
  blockScrollTop: number,
  list: string[],
  idPart: string,
  scrollPosition: number,
  currentIndex: number
): { activeIndex: number; currentScrollPosition: number } => {
  let activeIndex = currentIndex;
  let currentScrollPosition = scrollPosition;
  const extraTopOffset = 5;
  list.forEach((item, index) => {
    const el = document.getElementById(`${idPart}${index}`);
    const prevEl = document.getElementById(`${idPart}${index - 1}`);
    const nextEl = document.getElementById(`${idPart}${index + 1}`);
    if (el) {
      if (scrollPosition > blockScrollTop) {
        // scrolling up
        if (
          prevEl &&
          blockScrollTop <= el.offsetTop - extraTopOffset &&
          blockScrollTop > prevEl.offsetTop
        ) {
          activeIndex = index - 1;
          currentScrollPosition = el.offsetTop;
        } else if (!prevEl && blockScrollTop <= el.offsetTop - extraTopOffset) {
          activeIndex = index;
          currentScrollPosition = el.offsetTop;
        }
      } else if (scrollPosition < blockScrollTop) {
        // scrolling down
        if (
          nextEl
            ? blockScrollTop >= el.offsetTop && blockScrollTop < nextEl.offsetTop
            : blockScrollTop >= el.offsetTop
        ) {
          activeIndex = index;
          currentScrollPosition = el.offsetTop;
        }
      }
    }
  });
  return { activeIndex, currentScrollPosition };
};

// TODO: refactor this and reduce complexity
const getTheArrowPosition = (keyCode: number, cursor: number, list: any[]) => {
  let index = cursor;
  if (keyCode === 38 && cursor >= 0) {
    // up
    if (cursor === 0) {
      index = list.length;
    } else {
      index = cursor - 1;
    }
  } else if (keyCode === 40 && cursor <= list.length - 1) {
    // down
    if (cursor === list.length - 1) {
      index = -1;
    } else {
      index = cursor + 1;
    }
  }
  return index;
};

const getUniqId = () => {
  return `_${(
    new Date().getUTCMilliseconds().toString() + new Date().getTime().toString()
  ).toString()}`;
};

// TODO: refactor this URLSearchParams, and check for IE does it work
// const urlParams = new URLSearchParams(window.location.search);
// const myParam = urlParams.get('myParam');
/**
 * @return {[index: string]:string}
 */
const getParameters = () => {
  const query = window.location.search.replace(/^\?/g, '');
  const pairs = query.split('&');

  const parameters: { [index: string]: string } = {};
  for (let i = 0; i < pairs.length; i++) {
    const split = pairs[i].split('=');
    if (split.length === 2) {
      const [key, value] = split;
      parameters[key] = value;
    }
  }

  return parameters;
};

/**
 * @return {boolean}
 */
const hasLoginParameter = () => {
  const parameters = getParameters();
  return parameters.login === 'true';
};

/**
 * @return {string}
 */
const getCurrentUrlWithoutLoginParameter = () => {
  const parameters = getParameters();
  const path = window.location.pathname;

  let query = '';
  for (const key in parameters) {
    if (key !== 'login') {
      query += `${key}=${parameters[key]}`;
    }
  }

  if (query.length === 0) {
    return path;
  }

  return `${path}?${query}`;
};

const jsonParserFromSession = (sessionItem: SessionStorage) => {
  const item = sessionStorage.getItem(sessionItem);
  const parsedItem = item ? JSON.parse(item) : null;
  return parsedItem;
};

const getLocale = () => {
  return document.documentElement.lang;
};

const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`;
};

/**
 * The function which creates URL with a query if a query that does not exist or extend current if exist
 * @param parameterKey query key in the URL you want to add/change
 * @returns
 */
const getUrlPathWithParam = (
  currentParamValue: string,
  newParam: string,
  locationSearch: string,
  locationPathname: string,
  parameterKey: string
): string => {
  let urlPath;
  if (!currentParamValue) {
    let search = locationSearch;
    if (search) search = `${locationSearch}&${parameterKey}`;
    else search = `?${parameterKey}`;
    urlPath = `${locationPathname}${search}=${newParam}`;
  } else {
    const searchParams = new URLSearchParams(locationSearch);
    searchParams.set(parameterKey, newParam);
    urlPath = `${locationPathname}?${searchParams}`;
  }
  return urlPath;
};

function orderElementsInArrBySpecificValue<T>(
  unorderedArray: T[],
  objectKey: keyof T,
  orderValues: string[]
) {
  const orderedArray: T[] = [];
  orderValues.forEach((orderValue) => {
    unorderedArray.forEach((element) => {
      if (String(element[objectKey]).includes(orderValue)) {
        orderedArray.push(element);
      }
    });
  });

  return orderedArray;
}

const isWidget = envVariables.REACT_APP_IS_WIDGET;
const checkIsOfferExpired = (offerExpiration: string) => {
  return !!offerExpiration && isAfter(new Date(), new Date(offerExpiration));
};

const helpers = {
  checkCurrencyTypeFormat,
  envVariables,
  flatTheObject,
  getNumberOfStars,
  setStickyPosition,
  removeTrailingSlashes,
  highlightItemsWhileScrolling,
  getTheArrowPosition,
  getUniqId,
  hasLoginParameter,
  getCurrentUrlWithoutLoginParameter,
  jsonParserFromSession,
  getLocale,
  formatBytes,
  getUrlPathWithParam,
  orderElementsInArrBySpecificValue,
  isWidget,
  checkIsOfferExpired,
};

export default helpers;
