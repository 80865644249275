import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';

import { ConsultationApi, ProductRecommendation } from '@api/consultation';
import { ApiQuery } from '@enums';
import { helpers } from '@helpers';

const consultationInstance = new ConsultationApi(
  undefined,
  helpers.isWidget
    ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/consultation/v1`
    : '/api/consultation/v1'
);

const useProductRecommendations = (
  consultationId: string,
  options: UseQueryOptions<ProductRecommendation, AxiosError> = {}
): UseQueryResult<ProductRecommendation, AxiosError> =>
  useQuery(
    [ApiQuery.ProductRecommendations, consultationId],
    () =>
      consultationInstance
        .productRecommendationByConsultationIDRequest(consultationId)
        .then((res) => res.data),
    { keepPreviousData: true, refetchOnMount: false, ...options }
  );

const productRecommendations = {
  useProductRecommendations,
};

export default productRecommendations;
