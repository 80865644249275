/* tslint:disable */
/* eslint-disable */
/**
 * Consultation API
 * Consultation API provides data relevant for consultations.
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Account holds most basic and crucial Account data used for REST entity
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    PDFLogoDocumentID?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    accountLogoDocumentID?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    accountPictureID?: string;
    /**
     * 
     * @type {AccountAddress}
     * @memberof Account
     */
    address?: AccountAddress;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    advisorySuiteTitle: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    agentNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    branch?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    companyRegistrationNumber?: string;
    /**
     * 
     * @type {AccountContact}
     * @memberof Account
     */
    contact?: AccountContact;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    director?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    email?: string;
    /**
     * 
     * @type {Array<AccountEmail>}
     * @memberof Account
     */
    emails?: Array<AccountEmail>;
    /**
     * 
     * @type {Entity}
     * @memberof Account
     */
    entity: Entity;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    fax?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    imprint?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    isTest: boolean;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    notificationUserHash?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    supervisoryBoard?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    tag: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    taxID?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    website?: string;
}
/**
 * AccountAddress holds most basic and crucial AccountAddress data used for REST entity
 * @export
 * @interface AccountAddress
 */
export interface AccountAddress {
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    postcode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    streetNumber?: string;
}
/**
 * AccountContact holds most basic and crucial AccountContact data used for REST entity
 * @export
 * @interface AccountContact
 */
export interface AccountContact {
    /**
     * 
     * @type {string}
     * @memberof AccountContact
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountContact
     */
    name?: string;
    /**
     * 
     * @type {Array<AccountPhone>}
     * @memberof AccountContact
     */
    phones?: Array<AccountPhone>;
    /**
     * 
     * @type {string}
     * @memberof AccountContact
     */
    workingTime?: string;
}
/**
 * AccountEmail holds most basic and crucial AccountEmail data used for REST entity
 * @export
 * @interface AccountEmail
 */
export interface AccountEmail {
    /**
     * 
     * @type {string}
     * @memberof AccountEmail
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AccountEmail
     */
    type: AccountEmailTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AccountEmailTypeEnum {
    Deal = 'deal',
    DealCc = 'dealCC',
    DealNoAgencyNumber = 'dealNoAgencyNumber',
    DealPartnerAgencyNumber = 'dealPartnerAgencyNumber',
    Tendering = 'tendering',
    TenderingCc = 'tenderingCC',
    Test = 'test',
    Registration = 'registration',
    RegistrationCc = 'registrationCC',
    ServiceTeam = 'serviceTeam',
    InsurerAutoCc = 'insurerAutoCC',
    TenderingBackoffice = 'tenderingBackoffice',
    TenderingRequestInsurerCc = 'tenderingRequestInsurerCC',
    TenderingOffer = 'tenderingOffer',
    Unknown = 'unknown'
}

/**
 * AccountPhone holds most basic and crucial AccountPhone data used for REST entity
 * @export
 * @interface AccountPhone
 */
export interface AccountPhone {
    /**
     * 
     * @type {string}
     * @memberof AccountPhone
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountPhone
     */
    phone: string;
}
/**
 * Address is DTO used for API
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    postcode?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    streetNumber?: string;
}
/**
 * AgencyNumberPrioritization holds most basic and crucial AgencyNumberPrioritization data used for REST entity
 * @export
 * @interface AgencyNumberPrioritization
 */
export interface AgencyNumberPrioritization {
    /**
     * 
     * @type {string}
     * @memberof AgencyNumberPrioritization
     */
    agencyNumberPrioritizationLogic: string;
}
/**
 * AgencyNumberPrioritizationError is an Error Representation object
 * @export
 * @interface AgencyNumberPrioritizationError
 */
export interface AgencyNumberPrioritizationError {
    /**
     * 
     * @type {string}
     * @memberof AgencyNumberPrioritizationError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof AgencyNumberPrioritizationError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * Analysis holds most basic and crucial ContentAnalysis data used for REST entity
 * @export
 * @interface Analysis
 */
export interface Analysis {
    /**
     * 
     * @type {Array<AnsweredQuestion>}
     * @memberof Analysis
     */
    answeredQuestions: Array<AnsweredQuestion>;
    /**
     * 
     * @type {Entity}
     * @memberof Analysis
     */
    entity: Entity;
    /**
     * 
     * @type {string}
     * @memberof Analysis
     */
    hash: string;
}
/**
 * AnalysisError is an Error Representation object
 * @export
 * @interface AnalysisError
 */
export interface AnalysisError {
    /**
     * 
     * @type {string}
     * @memberof AnalysisError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof AnalysisError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * AnsweredQuestion holds most basic and crucial AnsweredQuestion data used for REST entity
 * @export
 * @interface AnsweredQuestion
 */
export interface AnsweredQuestion {
    /**
     * 
     * @type {string}
     * @memberof AnsweredQuestion
     */
    answerId?: string;
    /**
     * 
     * @type {string}
     * @memberof AnsweredQuestion
     */
    answerText?: string;
    /**
     * 
     * @type {Entity}
     * @memberof AnsweredQuestion
     */
    entity: Entity;
    /**
     * 
     * @type {string}
     * @memberof AnsweredQuestion
     */
    questionId: string;
}
/**
 * Broker holds most basic and Broker data used for RESTful response
 * @export
 * @interface Broker
 */
export interface Broker {
    /**
     * 
     * @type {Account}
     * @memberof Broker
     */
    account: Account;
    /**
     * 
     * @type {BrokerAddress}
     * @memberof Broker
     */
    address?: BrokerAddress;
    /**
     * 
     * @type {string}
     * @memberof Broker
     */
    brokerCode: string;
    /**
     * 
     * @type {string}
     * @memberof Broker
     */
    company?: string;
    /**
     * 
     * @type {BrokerContact}
     * @memberof Broker
     */
    contact: BrokerContact;
    /**
     * 
     * @type {Entity}
     * @memberof Broker
     */
    entity: Entity;
    /**
     * 
     * @type {string}
     * @memberof Broker
     */
    guildNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Broker
     */
    internalId: string;
    /**
     * 
     * @type {string}
     * @memberof Broker
     */
    notificationUserHash: string;
    /**
     * 
     * @type {BrokerPerson}
     * @memberof Broker
     */
    person?: BrokerPerson;
    /**
     * 
     * @type {string}
     * @memberof Broker
     */
    publicAgencyRegistrationNo?: string;
    /**
     * 
     * @type {string}
     * @memberof Broker
     */
    registrationNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof Broker
     */
    type: number;
    /**
     * 
     * @type {BrokerWert14}
     * @memberof Broker
     */
    wert14?: BrokerWert14;
}
/**
 * BrokerAddress holds the address data of a Broker used for RESTFUL response
 * @export
 * @interface BrokerAddress
 */
export interface BrokerAddress {
    /**
     * 
     * @type {string}
     * @memberof BrokerAddress
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerAddress
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerAddress
     */
    street_number?: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerAddress
     */
    zip?: string;
}
/**
 * BrokerContact holds most basic and Broker data used for RESTful response
 * @export
 * @interface BrokerContact
 */
export interface BrokerContact {
    /**
     * 
     * @type {string}
     * @memberof BrokerContact
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerContact
     */
    phone?: string;
}
/**
 * BrokerPerson holds most basic and Broker data used for RESTful response
 * @export
 * @interface BrokerPerson
 */
export interface BrokerPerson {
    /**
     * 
     * @type {string}
     * @memberof BrokerPerson
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerPerson
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerPerson
     */
    lastName?: string;
}
/**
 * BrokerWert14 holds the Wert14 data of a Broker used for RESTFUL response
 * @export
 * @interface BrokerWert14
 */
export interface BrokerWert14 {
    /**
     * 
     * @type {string}
     * @memberof BrokerWert14
     */
    apiKey: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerWert14
     */
    signatureHash: string;
}
/**
 * Company is DTO used for API
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {Address}
     * @memberof Company
     */
    address?: Address;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    differingPostalAddress: boolean;
    /**
     * 
     * @type {LegalForm}
     * @memberof Company
     */
    legalForm?: LegalForm;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    registrationCity?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    registrationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    website?: string;
}
/**
 * 
 * @export
 * @interface Condition
 */
export interface Condition {
    /**
     * in: query
     * @type {string}
     * @memberof Condition
     */
    error: string;
    /**
     * in: query
     * @type {string}
     * @memberof Condition
     */
    id: string;
    /**
     * in: query
     * @type {string}
     * @memberof Condition
     */
    itemId: string;
    /**
     * in: query
     * @type {string}
     * @memberof Condition
     */
    name: string;
    /**
     * 
     * @type {Array<Rule>}
     * @memberof Condition
     */
    rules: Array<Rule>;
    /**
     * in: query
     * @type {string}
     * @memberof Condition
     */
    type?: string;
}
/**
 * Consultation holds most basic and crucial Consultation data used for REST entity
 * @export
 * @interface Consultation
 */
export interface Consultation {
    /**
     * 
     * @type {Broker}
     * @memberof Consultation
     */
    broker: Broker;
    /**
     * 
     * @type {string}
     * @memberof Consultation
     */
    buildingAnalysisId?: string;
    /**
     * 
     * @type {string}
     * @memberof Consultation
     */
    contentAnalysisId?: string;
    /**
     * 
     * @type {Customer}
     * @memberof Consultation
     */
    customer: Customer;
    /**
     * 
     * @type {Entity}
     * @memberof Consultation
     */
    entity: Entity;
    /**
     * 
     * @type {string}
     * @memberof Consultation
     */
    hash: string;
    /**
     * 
     * @type {string}
     * @memberof Consultation
     */
    name?: string;
    /**
     * SelectedProfessionAliases type is a \"generic\" which holds a slice of ProfessionAlias
     * @type {Array<SelectedProfessionAlias>}
     * @memberof Consultation
     */
    professionAliases: Array<SelectedProfessionAlias>;
    /**
     * 
     * @type {string}
     * @memberof Consultation
     */
    riskAnalysisId?: string;
}
/**
 * ConsultationError is an Error Representation object
 * @export
 * @interface ConsultationError
 */
export interface ConsultationError {
    /**
     * 
     * @type {string}
     * @memberof ConsultationError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ConsultationError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * ConsultationList is a Representation object
 * @export
 * @interface ConsultationList
 */
export interface ConsultationList {
    /**
     * 
     * @type {EmbeddedConsultationTeasers}
     * @memberof ConsultationList
     */
    _embedded: EmbeddedConsultationTeasers;
    /**
     * 
     * @type {number}
     * @memberof ConsultationList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof ConsultationList
     */
    total: number;
}
/**
 * ConsultationTeaser holds most basic and crucial Consultation data used for RESTful HAL
 * @export
 * @interface ConsultationTeaser
 */
export interface ConsultationTeaser {
    /**
     * 
     * @type {string}
     * @memberof ConsultationTeaser
     */
    hash: string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationTeaser
     */
    id: string;
}
/**
 * ConsultationWithProductsAndProfessions represents DTO that holds Inquiries and Professions connected to a Consultation
 * @export
 * @interface ConsultationWithProductsAndProfessions
 */
export interface ConsultationWithProductsAndProfessions {
    /**
     * 
     * @type {string}
     * @memberof ConsultationWithProductsAndProfessions
     */
    consultationHash: string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationWithProductsAndProfessions
     */
    consultationId: string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationWithProductsAndProfessions
     */
    consultationName: string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationWithProductsAndProfessions
     */
    createdAt: string;
    /**
     * 
     * @type {CustomerTeaser}
     * @memberof ConsultationWithProductsAndProfessions
     */
    customer: CustomerTeaser;
    /**
     * 
     * @type {boolean}
     * @memberof ConsultationWithProductsAndProfessions
     */
    isDeletable?: boolean;
    /**
     * ProductsWithCount represents a slice of ProductWithCount DTOs
     * @type {Array<ProductWithCount>}
     * @memberof ConsultationWithProductsAndProfessions
     */
    productsWithCount: Array<ProductWithCount>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConsultationWithProductsAndProfessions
     */
    professionAliasNames: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConsultationWithProductsAndProfessions
     */
    professionNames: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ConsultationWithProductsAndProfessions
     */
    updatedAt: string;
}
/**
 * ConsultationsWithProductsAndProfessionsList is a Representation object
 * @export
 * @interface ConsultationsWithProductsAndProfessionsList
 */
export interface ConsultationsWithProductsAndProfessionsList {
    /**
     * 
     * @type {EmbeddedConsultationsWithProductsAndProfessions}
     * @memberof ConsultationsWithProductsAndProfessionsList
     */
    _embedded: EmbeddedConsultationsWithProductsAndProfessions;
    /**
     * 
     * @type {number}
     * @memberof ConsultationsWithProductsAndProfessionsList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof ConsultationsWithProductsAndProfessionsList
     */
    total: number;
}
/**
 * ContactPerson is DTO used for API
 * @export
 * @interface ContactPerson
 */
export interface ContactPerson {
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    birthday?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    salutation?: ContactPersonSalutationEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    title?: ContactPersonTitleEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ContactPersonSalutationEnum {
    Mr = 'Mr',
    Ms = 'Ms',
    Company = 'Company',
    Other = 'Other',
    Undefined = 'Undefined'
}
/**
    * @export
    * @enum {string}
    */
export enum ContactPersonTitleEnum {
    PhD = 'PhD',
    Prof = 'Prof',
    ProfPhD = 'Prof-PhD',
    None = 'None'
}

/**
 * 
 * @export
 * @interface Conversation
 */
export interface Conversation {
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    type: string;
}
/**
 * CreateNewTenderRequest holds most basic and crucial TenderRequest data used for REST entity
 * @export
 * @interface CreateNewTenderRequest
 */
export interface CreateNewTenderRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateNewTenderRequest
     */
    flowType: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewTenderRequest
     */
    manualTenderReasonText?: string;
}
/**
 * CreateTenderRequestResponse is a Representation object which carries Response data
 * @export
 * @interface CreateTenderRequestResponse
 */
export interface CreateTenderRequestResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateTenderRequestResponse
     */
    tenderRequestID: string;
}
/**
 * Customer is DTO used for API responses
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    accountType: CustomerAccountTypeEnum;
    /**
     * 
     * @type {Address}
     * @memberof Customer
     */
    billingAddress?: Address;
    /**
     * 
     * @type {Company}
     * @memberof Customer
     */
    company: Company;
    /**
     * 
     * @type {ContactPerson}
     * @memberof Customer
     */
    contactPerson?: ContactPerson;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    creatorId: string;
    /**
     * 
     * @type {Entity}
     * @memberof Customer
     */
    entity: Entity;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    internalId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    isSnapshot: boolean;
    /**
     * 
     * @type {Customer}
     * @memberof Customer
     */
    original?: Customer;
}

/**
    * @export
    * @enum {string}
    */
export enum CustomerAccountTypeEnum {
    Private = 'private',
    Company = 'company'
}

/**
 * 
 * @export
 * @interface CustomerEmailError
 */
export interface CustomerEmailError {
    /**
     * 
     * @type {string}
     * @memberof CustomerEmailError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof CustomerEmailError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface CustomerMessage
 */
export interface CustomerMessage {
    /**
     * 
     * @type {string}
     * @memberof CustomerMessage
     */
    bodyEmail: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerMessage
     */
    brokerEmail: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerMessage
     */
    customerEmail: string;
}
/**
 * CustomerOverview represents DTO that holds data for customers list page in advisory suit
 * @export
 * @interface CustomerOverview
 */
export interface CustomerOverview {
    /**
     * 
     * @type {string}
     * @memberof CustomerOverview
     */
    brokerName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOverview
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOverview
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOverview
     */
    lastConsultation: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOverview
     */
    settlement: string;
}
/**
 * CustomerOverviewList is DTO for CustomerOverviews list
 * @export
 * @interface CustomerOverviewList
 */
export interface CustomerOverviewList {
    /**
     * 
     * @type {EmbeddedCustomerOverviews}
     * @memberof CustomerOverviewList
     */
    _embedded: EmbeddedCustomerOverviews;
    /**
     * 
     * @type {number}
     * @memberof CustomerOverviewList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerOverviewList
     */
    total: number;
}
/**
 * CustomerTeaser is DTO used for API responses
 * @export
 * @interface CustomerTeaser
 */
export interface CustomerTeaser {
    /**
     * 
     * @type {Address}
     * @memberof CustomerTeaser
     */
    billingAddress?: Address;
    /**
     * 
     * @type {Company}
     * @memberof CustomerTeaser
     */
    company?: Company;
    /**
     * 
     * @type {string}
     * @memberof CustomerTeaser
     */
    contactName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTeaser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTeaser
     */
    phone?: string;
}
/**
 * Document document
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * version
     * @type {number}
     * @memberof Document
     */
    __v?: number;
    /**
     * MongoDB ObjectId
     * @type {string}
     * @memberof Document
     */
    _id: string;
    /**
     * created date
     * @type {string}
     * @memberof Document
     */
    created?: string;
    /**
     * extension
     * @type {string}
     * @memberof Document
     */
    extension: string;
    /**
     * use for the name file stored in S3
     * @type {string}
     * @memberof Document
     */
    hash?: string;
    /**
     * is deleted
     * @type {boolean}
     * @memberof Document
     */
    isDeleted?: boolean;
    /**
     * mime type
     * @type {string}
     * @memberof Document
     */
    mimeType: string;
    /**
     * name
     * @type {string}
     * @memberof Document
     */
    name: string;
    /**
     * path
     * @type {string}
     * @memberof Document
     */
    path: string;
    /**
     * status
     * @type {number}
     * @memberof Document
     */
    status?: number;
    /**
     * document type
     * @type {string}
     * @memberof Document
     */
    type?: string;
    /**
     * url
     * @type {string}
     * @memberof Document
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface E2EB2CCreateSubscription
 */
export interface E2EB2CCreateSubscription {
    /**
     * 
     * @type {string}
     * @memberof E2EB2CCreateSubscription
     */
    email: string;
    /**
     * PageType represents the type of pages
     * @type {string}
     * @memberof E2EB2CCreateSubscription
     */
    pageType: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof E2EB2CCreateSubscription
     */
    params?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof E2EB2CCreateSubscription
     */
    phone?: string;
}
/**
 * EmbeddedConsultationTeasers is a Representation object of ConsultationTeasers
 * @export
 * @interface EmbeddedConsultationTeasers
 */
export interface EmbeddedConsultationTeasers {
    /**
     * ConsultationTeasers type is a \"generic\" which holds a slice of ConsultationTeaser
     * @type {Array<ConsultationTeaser>}
     * @memberof EmbeddedConsultationTeasers
     */
    consultations: Array<ConsultationTeaser>;
}
/**
 * EmbeddedConsultationsWithProductsAndProfessions is a Representation object of ConsultationsWithProductsAndProfessions
 * @export
 * @interface EmbeddedConsultationsWithProductsAndProfessions
 */
export interface EmbeddedConsultationsWithProductsAndProfessions {
    /**
     * ConsultationsWithProductsAndProfessions represents a slice of ConsultationWithProductsAndProfessions DTOs
     * @type {Array<ConsultationWithProductsAndProfessions>}
     * @memberof EmbeddedConsultationsWithProductsAndProfessions
     */
    consultationsWithProductsAndProfessions: Array<ConsultationWithProductsAndProfessions>;
}
/**
 * EmbeddedCustomerOverviews is wrapping DTO for CustomerOverviews
 * @export
 * @interface EmbeddedCustomerOverviews
 */
export interface EmbeddedCustomerOverviews {
    /**
     * CustomerOverviews is a collection of CustomerOverview
     * @type {Array<CustomerOverview>}
     * @memberof EmbeddedCustomerOverviews
     */
    customerOverviews: Array<CustomerOverview>;
}
/**
 * EmbeddedInquiriesWithProduct is a Representation object of InquiriesWithProduct
 * @export
 * @interface EmbeddedInquiriesWithProduct
 */
export interface EmbeddedInquiriesWithProduct {
    /**
     * InquiriesWithProduct type is a \"generic\" which holds a slice of InquiryWithProduct
     * @type {Array<InquiryWithProduct>}
     * @memberof EmbeddedInquiriesWithProduct
     */
    inquiries: Array<InquiryWithProduct>;
}
/**
 * EmbeddedInquiryTeasers is a Representation object of InquiryTeasers
 * @export
 * @interface EmbeddedInquiryTeasers
 */
export interface EmbeddedInquiryTeasers {
    /**
     * InquiryTeasers type is a \"generic\" which holds a slice of InquiryTeaser
     * @type {Array<InquiryTeaser>}
     * @memberof EmbeddedInquiryTeasers
     */
    inquiries: Array<InquiryTeaser>;
}
/**
 * EmbeddedPremiumPredictions is a Representation object of EmbeddedPremiumPredictions
 * @export
 * @interface EmbeddedPremiumPredictions
 */
export interface EmbeddedPremiumPredictions {
    /**
     * 
     * @type {Array<PremiumPrediction>}
     * @memberof EmbeddedPremiumPredictions
     */
    premiumPredictions: Array<PremiumPrediction>;
}
/**
 * EmbeddedProfessionAliases is a Representation object of ProfessionAliases
 * @export
 * @interface EmbeddedProfessionAliases
 */
export interface EmbeddedProfessionAliases {
    /**
     * ProfessionAliases type is a \"generic\" which holds a slice of ProfessionAlias
     * @type {Array<ProfessionAlias>}
     * @memberof EmbeddedProfessionAliases
     */
    professionAliases: Array<ProfessionAlias>;
}
/**
 * EmbeddedQuestionPages is a Representation object of QuestionTeasers
 * @export
 * @interface EmbeddedQuestionPages
 */
export interface EmbeddedQuestionPages {
    /**
     * QuestionPages holds most basic and crucial QuestionPages data used for REST entity
     * @type {Array<QuestionPage>}
     * @memberof EmbeddedQuestionPages
     */
    pages: Array<QuestionPage>;
}
/**
 * EmbeddedRanges is a representation object of Ranges
 * @export
 * @interface EmbeddedRanges
 */
export interface EmbeddedRanges {
    /**
     * 
     * @type {Array<Range>}
     * @memberof EmbeddedRanges
     */
    ranges: Array<Range>;
}
/**
 * Entity holds most basic and crucial Entity data used for REST entity
 * @export
 * @interface Entity
 */
export interface Entity {
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof Entity
     */
    isActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Entity
     */
    isDeleted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Entity
     */
    isLocked: boolean;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    updatedAt: string;
}
/**
 * FileImportTemplate is a struct that describes file import template data
 * @export
 * @interface FileImportTemplate
 */
export interface FileImportTemplate {
    /**
     * 
     * @type {string}
     * @memberof FileImportTemplate
     */
    extension?: string;
    /**
     * 
     * @type {string}
     * @memberof FileImportTemplate
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof FileImportTemplate
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FileImportTemplate
     */
    name?: string;
}
/**
 * FinishOrder is DTO for API
 * @export
 * @interface FinishOrder
 */
export interface FinishOrder {
    /**
     * 
     * @type {number}
     * @memberof FinishOrder
     */
    frequency?: number;
    /**
     * 
     * @type {number}
     * @memberof FinishOrder
     */
    premiumInCents?: number;
}
/**
 * 
 * @export
 * @interface HTTPError
 */
export interface HTTPError {
    /**
     * 
     * @type {string}
     * @memberof HTTPError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof HTTPError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * Industry holds most basic and crucial Industry data used for REST entity
 * @export
 * @interface Industry
 */
export interface Industry {
    /**
     * 
     * @type {Entity}
     * @memberof Industry
     */
    entity: Entity;
    /**
     * 
     * @type {string}
     * @memberof Industry
     */
    hash: string;
    /**
     * 
     * @type {string}
     * @memberof Industry
     */
    info?: string;
    /**
     * 
     * @type {string}
     * @memberof Industry
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Industry
     */
    text?: string;
}
/**
 * Inquiry holds most basic and crucial Inquiry data used for REST entity
 * @export
 * @interface Inquiry
 */
export interface Inquiry {
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    IsTenderBrokerManaged: boolean;
    /**
     * required false
     * @type {string}
     * @memberof Inquiry
     */
    bookedContractID?: string;
    /**
     * 
     * @type {string}
     * @memberof Inquiry
     */
    bookingSetId?: string;
    /**
     * 
     * @type {string}
     * @memberof Inquiry
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof Inquiry
     */
    confirmedAt?: string;
    /**
     * 
     * @type {Consultation}
     * @memberof Inquiry
     */
    consultation: Consultation;
    /**
     * 
     * @type {string}
     * @memberof Inquiry
     */
    contractDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Inquiry
     */
    contractId?: string;
    /**
     * 
     * @type {string}
     * @memberof Inquiry
     */
    conversationId?: string;
    /**
     * 
     * @type {Entity}
     * @memberof Inquiry
     */
    entity: Entity;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    hasBookingSet: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    hasComparison: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    hasContract: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    hasConversation: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    hasOfferGroup: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    hasOffers: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    hasProposal: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    hasRiskAssessment: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    hasSelectedOffer: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    hasTender: boolean;
    /**
     * 
     * @type {string}
     * @memberof Inquiry
     */
    hash: string;
    /**
     * 
     * @type {Insurer}
     * @memberof Inquiry
     */
    insurer?: Insurer;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    isBookable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    isBooked: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    isCalculationDone: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    isOfferGroupCreatable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    isOfferSelectable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    isRiskAssessmentCloneable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    isRiskAssessmentCreatable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    isTenderCreatable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    isTest: boolean;
    /**
     * 
     * @type {string}
     * @memberof Inquiry
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Inquiry
     */
    offerGroupId?: string;
    /**
     * 
     * @type {Product}
     * @memberof Inquiry
     */
    product: Product;
    /**
     * 
     * @type {string}
     * @memberof Inquiry
     */
    proposalDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Inquiry
     */
    proposalId?: string;
    /**
     * 
     * @type {string}
     * @memberof Inquiry
     */
    selectedOfferId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    showBookedInfoText: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    showChangeTariffButton: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    showContactButton: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    showIsBeingCalculatedInfoText: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    showProposalCreatedInfoText: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    showRiskAssessmentButton: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    showRiskAssessmentIncompleteInfoText: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    showSelectTariffButton: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    showStartTenderingButton: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Inquiry
     */
    showViewTenderingButton: boolean;
    /**
     * 
     * @type {string}
     * @memberof Inquiry
     */
    status: InquiryStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Inquiry
     */
    tenderId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InquiryStatusEnum {
    Created = 'created',
    RiskAssessmentCreated = 'riskAssessmentCreated',
    RiskAssessmentCompleted = 'riskAssessmentCompleted',
    OfferGroupCreated = 'offerGroupCreated',
    OfferAvailable = 'offerAvailable',
    Tendering = 'tendering',
    ComparisonCreated = 'comparisonCreated',
    OfferSelected = 'offerSelected',
    BookingStarted = 'bookingStarted',
    ProposalCreated = 'proposalCreated',
    OrderStarted = 'orderStarted',
    Ordered = 'ordered',
    Unknown = 'unknown',
    Cancelled = 'cancelled'
}

/**
 * 
 * @export
 * @interface InquiryDoc
 */
export interface InquiryDoc {
    /**
     * 
     * @type {string}
     * @memberof InquiryDoc
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof InquiryDoc
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof InquiryDoc
     */
    type?: string;
}
/**
 * InquiryError is an Error Representation object
 * @export
 * @interface InquiryError
 */
export interface InquiryError {
    /**
     * 
     * @type {string}
     * @memberof InquiryError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof InquiryError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * InquiryList is a Representation object
 * @export
 * @interface InquiryList
 */
export interface InquiryList {
    /**
     * 
     * @type {EmbeddedInquiryTeasers}
     * @memberof InquiryList
     */
    _embedded: EmbeddedInquiryTeasers;
    /**
     * 
     * @type {number}
     * @memberof InquiryList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof InquiryList
     */
    total: number;
}
/**
 * InquiryTeaser holds most basic and crucial Inquiry data used for RESTful HAL
 * @export
 * @interface InquiryTeaser
 */
export interface InquiryTeaser {
    /**
     * 
     * @type {string}
     * @memberof InquiryTeaser
     */
    consultationId: string;
    /**
     * 
     * @type {string}
     * @memberof InquiryTeaser
     */
    hash: string;
    /**
     * 
     * @type {string}
     * @memberof InquiryTeaser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InquiryTeaser
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof InquiryTeaser
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof InquiryTeaser
     */
    status: InquiryTeaserStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum InquiryTeaserStatusEnum {
    Created = 'created',
    RiskAssessmentCreated = 'riskAssessmentCreated',
    RiskAssessmentCompleted = 'riskAssessmentCompleted',
    OfferGroupCreated = 'offerGroupCreated',
    OfferAvailable = 'offerAvailable',
    Tendering = 'tendering',
    ComparisonCreated = 'comparisonCreated',
    OfferSelected = 'offerSelected',
    BookingStarted = 'bookingStarted',
    ProposalCreated = 'proposalCreated',
    OrderStarted = 'orderStarted',
    Ordered = 'ordered',
    Unknown = 'unknown',
    Cancelled = 'cancelled'
}

/**
 * InquiryWithProduct holds most basic and crucial Inquiry with the corresponding Product used for RESTful HAL
 * @export
 * @interface InquiryWithProduct
 */
export interface InquiryWithProduct {
    /**
     * 
     * @type {string}
     * @memberof InquiryWithProduct
     */
    consultationId: string;
    /**
     * 
     * @type {string}
     * @memberof InquiryWithProduct
     */
    hash: string;
    /**
     * 
     * @type {string}
     * @memberof InquiryWithProduct
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InquiryWithProduct
     */
    name: string;
    /**
     * 
     * @type {Product}
     * @memberof InquiryWithProduct
     */
    product: Product;
    /**
     * 
     * @type {string}
     * @memberof InquiryWithProduct
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof InquiryWithProduct
     */
    status: InquiryWithProductStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum InquiryWithProductStatusEnum {
    Created = 'created',
    RiskAssessmentCreated = 'riskAssessmentCreated',
    RiskAssessmentCompleted = 'riskAssessmentCompleted',
    OfferGroupCreated = 'offerGroupCreated',
    OfferAvailable = 'offerAvailable',
    Tendering = 'tendering',
    ComparisonCreated = 'comparisonCreated',
    OfferSelected = 'offerSelected',
    BookingStarted = 'bookingStarted',
    ProposalCreated = 'proposalCreated',
    OrderStarted = 'orderStarted',
    Ordered = 'ordered',
    Unknown = 'unknown',
    Cancelled = 'cancelled'
}

/**
 * InquiryWithProductList is a Representation object
 * @export
 * @interface InquiryWithProductList
 */
export interface InquiryWithProductList {
    /**
     * 
     * @type {EmbeddedInquiriesWithProduct}
     * @memberof InquiryWithProductList
     */
    _embedded: EmbeddedInquiriesWithProduct;
    /**
     * 
     * @type {number}
     * @memberof InquiryWithProductList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof InquiryWithProductList
     */
    total: number;
}
/**
 * 
 * @export
 * @interface Insurer
 */
export interface Insurer {
    /**
     * 
     * @type {Entity}
     * @memberof Insurer
     */
    entity: Entity;
    /**
     * 
     * @type {string}
     * @memberof Insurer
     */
    externalId?: string;
    /**
     * 
     * @type {string}
     * @memberof Insurer
     */
    icon?: string;
    /**
     * 
     * @type {string}
     * @memberof Insurer
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Insurer
     */
    tag: string;
    /**
     * 
     * @type {string}
     * @memberof Insurer
     */
    type: string;
}
/**
 * 
 * @export
 * @interface LegalForm
 */
export interface LegalForm {
    /**
     * 
     * @type {string}
     * @memberof LegalForm
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LegalForm
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof LegalForm
     */
    visibleToCompany: boolean;
}
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    authorHash: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    authorNickname: string;
    /**
     * 
     * @type {Conversation}
     * @memberof Message
     */
    conversation: Conversation;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    fileKey: string;
    /**
     * 
     * @type {boolean}
     * @memberof Message
     */
    forwardCopy: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Message
     */
    forwarded: boolean;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof Message
     */
    reasonID: number;
    /**
     * 
     * @type {boolean}
     * @memberof Message
     */
    replied: boolean;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    replySourceID: string;
    /**
     * 
     * @type {boolean}
     * @memberof Message
     */
    reviewed: boolean;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    type: string;
}
/**
 * MessageAttributes represents attributes of a message
 * @export
 * @interface MessageAttributes
 */
export interface MessageAttributes {
    /**
     * 
     * @type {string}
     * @memberof MessageAttributes
     */
    authorHash: string;
    /**
     * 
     * @type {string}
     * @memberof MessageAttributes
     */
    fileKey: string;
    /**
     * 
     * @type {number}
     * @memberof MessageAttributes
     */
    reasonId: number;
    /**
     * 
     * @type {boolean}
     * @memberof MessageAttributes
     */
    reviewed: boolean;
    /**
     * 
     * @type {string}
     * @memberof MessageAttributes
     */
    text: string;
}
/**
 * 
 * @export
 * @interface MessageError
 */
export interface MessageError {
    /**
     * 
     * @type {string}
     * @memberof MessageError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof MessageError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * MessageToSend represents a single message to be sent
 * @export
 * @interface MessageToSend
 */
export interface MessageToSend {
    /**
     * 
     * @type {MessageAttributes}
     * @memberof MessageToSend
     */
    attributes: MessageAttributes;
    /**
     * 
     * @type {string}
     * @memberof MessageToSend
     */
    type: string;
}
/**
 * MessagesToSendData holds the data of the messages to send
 * @export
 * @interface MessagesToSendData
 */
export interface MessagesToSendData {
    /**
     * MessagesToSend represents a collection of messages to be sent
     * @type {Array<MessageToSend>}
     * @memberof MessagesToSendData
     */
    data?: Array<MessageToSend>;
}
/**
 * Error is DTO used for API responses
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    status: number;
    /**
     * 
     * @type {Array<Violation>}
     * @memberof ModelError
     */
    violations?: Array<Violation>;
}
/**
 * 
 * @export
 * @interface NotificationInquiry
 */
export interface NotificationInquiry {
    /**
     * 
     * @type {string}
     * @memberof NotificationInquiry
     */
    inquiryId: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInquiry
     */
    tenderId: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInquiry
     */
    type: string;
}
/**
 * 
 * @export
 * @interface NotificationInquiryError
 */
export interface NotificationInquiryError {
    /**
     * 
     * @type {string}
     * @memberof NotificationInquiryError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof NotificationInquiryError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * Offer DTO for response
 * @export
 * @interface Offer
 */
export interface Offer {
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    groupId: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    id: string;
}
/**
 * OfferGroup DTO for response
 * @export
 * @interface OfferGroup
 */
export interface OfferGroup {
    /**
     * 
     * @type {string}
     * @memberof OfferGroup
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof OfferGroup
     */
    isCalculation: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfferGroup
     */
    isProcessed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfferGroup
     */
    isTendering: boolean;
}
/**
 * OfferGroupReference DTO for response that holds only OfferGroupID
 * @export
 * @interface OfferGroupReference
 */
export interface OfferGroupReference {
    /**
     * 
     * @type {boolean}
     * @memberof OfferGroupReference
     */
    isTendering: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfferGroupReference
     */
    offerGroupID: string;
    /**
     * 
     * @type {string}
     * @memberof OfferGroupReference
     */
    questionAnswerSetStatus: OfferGroupReferenceQuestionAnswerSetStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum OfferGroupReferenceQuestionAnswerSetStatusEnum {
    IsLocked = 'isLocked',
    IsEditable = 'isEditable',
    IsCompleted = 'isCompleted'
}

/**
 * Overview holds most basic and crucial Consultation and Inquiry data used for REST entity
 * @export
 * @interface Overview
 */
export interface Overview {
    /**
     * 
     * @type {Consultation}
     * @memberof Overview
     */
    consultation: Consultation;
    /**
     * 
     * @type {Array<Inquiry>}
     * @memberof Overview
     */
    inquiries: Array<Inquiry>;
}
/**
 * Payment is DTO used for API responses
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    bankAccountHolder?: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    bankName?: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    bic?: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    customerId: string;
    /**
     * 
     * @type {Entity}
     * @memberof Payment
     */
    entity: Entity;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    iban?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Payment
     */
    isPreferred: boolean;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    paymentMethod: PaymentPaymentMethodEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PaymentPaymentMethodEnum {
    DirectDebit = 'directDebit',
    Invoice = 'invoice',
    None = 'none'
}

/**
 * PossibleAnswer holds most basic and crucial PossibleAnswer data used for REST entity
 * @export
 * @interface PossibleAnswer
 */
export interface PossibleAnswer {
    /**
     * 
     * @type {string}
     * @memberof PossibleAnswer
     */
    defaultValue: string;
    /**
     * 
     * @type {Entity}
     * @memberof PossibleAnswer
     */
    entity: Entity;
    /**
     * 
     * @type {string}
     * @memberof PossibleAnswer
     */
    value: string;
}
/**
 * 
 * @export
 * @interface PremiumPrediction
 */
export interface PremiumPrediction {
    /**
     * 
     * @type {number}
     * @memberof PremiumPrediction
     */
    higherEnd: number;
    /**
     * 
     * @type {number}
     * @memberof PremiumPrediction
     */
    insuranceSum: number;
    /**
     * 
     * @type {number}
     * @memberof PremiumPrediction
     */
    lowerEnd: number;
    /**
     * 
     * @type {number}
     * @memberof PremiumPrediction
     */
    median: number;
    /**
     * 
     * @type {string}
     * @memberof PremiumPrediction
     */
    productId: string;
}
/**
 * 
 * @export
 * @interface PremiumPredictionError
 */
export interface PremiumPredictionError {
    /**
     * 
     * @type {string}
     * @memberof PremiumPredictionError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof PremiumPredictionError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * PremiumPredictionList is a Representation object
 * @export
 * @interface PremiumPredictionList
 */
export interface PremiumPredictionList {
    /**
     * 
     * @type {EmbeddedPremiumPredictions}
     * @memberof PremiumPredictionList
     */
    _embedded: EmbeddedPremiumPredictions;
    /**
     * 
     * @type {number}
     * @memberof PremiumPredictionList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof PremiumPredictionList
     */
    total: number;
}
/**
 * Product holds most basic and crucial Product data used for REST entity
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {Entity}
     * @memberof Product
     */
    entity: Entity;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    example?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    groupID?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    icon: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    info?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    isCommercial: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    isCorporate: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    isLead: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    isNameObligatory: boolean;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    nameLong?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    nameShort?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    rank: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    tag: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    text?: string;
}
/**
 * 
 * @export
 * @interface ProductCategory
 */
export interface ProductCategory {
    /**
     * 
     * @type {string}
     * @memberof ProductCategory
     */
    categoryName: string;
    /**
     * 
     * @type {Array<ProductPDF>}
     * @memberof ProductCategory
     */
    products: Array<ProductPDF>;
}
/**
 * ProductGroupWithProducts represents the main entity of productgroup module
 * @export
 * @interface ProductGroupWithProducts
 */
export interface ProductGroupWithProducts {
    /**
     * 
     * @type {Entity}
     * @memberof ProductGroupWithProducts
     */
    entity: Entity;
    /**
     * 
     * @type {string}
     * @memberof ProductGroupWithProducts
     */
    name: string;
    /**
     * Products type is a \"generic\" which holds a slice of ProductTeaser
     * @type {Array<Product>}
     * @memberof ProductGroupWithProducts
     */
    products: Array<Product>;
    /**
     * 
     * @type {number}
     * @memberof ProductGroupWithProducts
     */
    rank: number;
}
/**
 * 
 * @export
 * @interface ProductPDF
 */
export interface ProductPDF {
    /**
     * ConsultationNeeded represents the Entscheidung field. Allowed values are [UNKNOWN,YES,NO]:
     * @type {string}
     * @memberof ProductPDF
     */
    consultationNeeded: ProductPDFConsultationNeededEnum;
    /**
     * ExistingProtection represents the Bestehender Schutz field. Allowed values are [UNKNOWN,YES,NO]:
     * @type {string}
     * @memberof ProductPDF
     */
    existingProtection: ProductPDFExistingProtectionEnum;
    /**
     * InsurerName represents the Verisicherer field
     * @type {string}
     * @memberof ProductPDF
     */
    insurerName?: string;
    /**
     * InsurerSum represents the Leistung (in €) field from Schutz vorhanden?. It\'s required if InsurerSumIsUnlimited is false.
     * @type {number}
     * @memberof ProductPDF
     */
    insurerSum?: number;
    /**
     * InsurerSumIsUnlimited represents the Leistung (in €) field from Schutz vorhanden? field if the user type the string \"unlimited\", then it should be true. It defaults to false.
     * @type {boolean}
     * @memberof ProductPDF
     */
    insurerSumUnlimited?: boolean;
    /**
     * Premium represents the Prämie (in €) field from Schutz vorhanden? column
     * @type {number}
     * @memberof ProductPDF
     */
    premium?: number;
    /**
     * PremiumMax represents the Bin field from Prämie (in €)
     * @type {number}
     * @memberof ProductPDF
     */
    premiumMax?: number;
    /**
     * PremiumMin represents the Von field from Prämie (in €)
     * @type {number}
     * @memberof ProductPDF
     */
    premiumMin?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductPDF
     */
    productId: string;
    /**
     * TargetInsuranceSum represents the Soll-Leistung (in €) field. It\'s required if targetInsuranceSumUnlimited is false.
     * @type {number}
     * @memberof ProductPDF
     */
    targetInsuranceSum?: number;
    /**
     * TargetInsuranceSumIsUnlimited represents the Soll-Leistung (in €) field if the user type the string \"unlimited\", then it should be true. It defaults to false.
     * @type {boolean}
     * @memberof ProductPDF
     */
    targetInsuranceSumUnlimited?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum ProductPDFConsultationNeededEnum {
    Unknown = 'UNKNOWN',
    Yes = 'YES',
    No = 'NO'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductPDFExistingProtectionEnum {
    Unknown = 'UNKNOWN',
    Yes = 'YES',
    No = 'NO'
}

/**
 * 
 * @export
 * @interface ProductPDFError
 */
export interface ProductPDFError {
    /**
     * 
     * @type {string}
     * @memberof ProductPDFError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ProductPDFError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface ProductPDFRequest
 */
export interface ProductPDFRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductPDFRequest
     */
    accountId?: string;
    /**
     * CompanyName represents the Firmenname field
     * @type {string}
     * @memberof ProductPDFRequest
     */
    companyName?: string;
    /**
     * Date represents the Datum field
     * @type {string}
     * @memberof ProductPDFRequest
     */
    date?: string;
    /**
     * EmailAddress represents the E-Mail-Adresse field
     * @type {string}
     * @memberof ProductPDFRequest
     */
    emailAddress?: string;
    /**
     * Name represents the Name field
     * @type {string}
     * @memberof ProductPDFRequest
     */
    name?: string;
    /**
     * Notes represents the Notizen field
     * @type {string}
     * @memberof ProductPDFRequest
     */
    notes?: string;
    /**
     * PhoneNumber represents the Telefonnummer field
     * @type {string}
     * @memberof ProductPDFRequest
     */
    phoneNumber?: string;
    /**
     * ProductCategories represents the list of products grouped by Product Category
     * @type {Array<ProductCategory>}
     * @memberof ProductPDFRequest
     */
    productCategories: Array<ProductCategory>;
    /**
     * SignatureFields represents the Unterschriftenfelder hinzufügen field
     * @type {boolean}
     * @memberof ProductPDFRequest
     */
    signatureFields?: boolean;
}
/**
 * ProductRating holds most basic and crucial ProductRating data used for REST entity
 * @export
 * @interface ProductRating
 */
export interface ProductRating {
    /**
     * 
     * @type {Entity}
     * @memberof ProductRating
     */
    entity: Entity;
    /**
     * 
     * @type {string}
     * @memberof ProductRating
     */
    name: string;
    /**
     * Products type is a \"generic\" which holds a slice of ProductTeaser
     * @type {Array<Product>}
     * @memberof ProductRating
     */
    products: Array<Product>;
    /**
     * 
     * @type {number}
     * @memberof ProductRating
     */
    rank: number;
    /**
     * 
     * @type {number}
     * @memberof ProductRating
     */
    rating: number;
}
/**
 * ProductRecommendation holds most basic and crucial ProductRecommendation data used for REST entity
 * @export
 * @interface ProductRecommendation
 */
export interface ProductRecommendation {
    /**
     * 
     * @type {Array<ProductGroupWithProducts>}
     * @memberof ProductRecommendation
     */
    productGroups: Array<ProductGroupWithProducts>;
    /**
     * 
     * @type {Array<ProductRating>}
     * @memberof ProductRecommendation
     */
    productRatings: Array<ProductRating>;
}
/**
 * ProductRecommendationError is an Error Representation object
 * @export
 * @interface ProductRecommendationError
 */
export interface ProductRecommendationError {
    /**
     * 
     * @type {string}
     * @memberof ProductRecommendationError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ProductRecommendationError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * ProductWithCount is a DTO that holds product name and a number how many times this product appears in inquiries
 * @export
 * @interface ProductWithCount
 */
export interface ProductWithCount {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductWithCount
     */
    associatedInquiries: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProductWithCount
     */
    productName: string;
}
/**
 * Profession holds most basic and crucial Profession data used for REST entity
 * @export
 * @interface Profession
 */
export interface Profession {
    /**
     * 
     * @type {Entity}
     * @memberof Profession
     */
    entity: Entity;
    /**
     * 
     * @type {string}
     * @memberof Profession
     */
    hash: string;
    /**
     * 
     * @type {Industry}
     * @memberof Profession
     */
    industry: Industry;
    /**
     * 
     * @type {string}
     * @memberof Profession
     */
    info?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Profession
     */
    isOffice: boolean;
    /**
     * 
     * @type {string}
     * @memberof Profession
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Profession
     */
    text?: string;
}
/**
 * ProfessionAlias holds most basic and crucial ProfessionAlias data used for REST entity
 * @export
 * @interface ProfessionAlias
 */
export interface ProfessionAlias {
    /**
     * 
     * @type {string}
     * @memberof ProfessionAlias
     */
    alias: string;
    /**
     * 
     * @type {Entity}
     * @memberof ProfessionAlias
     */
    entity: Entity;
    /**
     * 
     * @type {string}
     * @memberof ProfessionAlias
     */
    hash: string;
    /**
     * 
     * @type {Profession}
     * @memberof ProfessionAlias
     */
    profession: Profession;
}
/**
 * ProfessionAliasError is an Error Representation object
 * @export
 * @interface ProfessionAliasError
 */
export interface ProfessionAliasError {
    /**
     * 
     * @type {string}
     * @memberof ProfessionAliasError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ProfessionAliasError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * ProfessionAliasList is a Representation object
 * @export
 * @interface ProfessionAliasList
 */
export interface ProfessionAliasList {
    /**
     * 
     * @type {EmbeddedProfessionAliases}
     * @memberof ProfessionAliasList
     */
    _embedded: EmbeddedProfessionAliases;
    /**
     * 
     * @type {number}
     * @memberof ProfessionAliasList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof ProfessionAliasList
     */
    total: number;
}
/**
 * ProfessionError is an Error Representation object
 * @export
 * @interface ProfessionError
 */
export interface ProfessionError {
    /**
     * 
     * @type {string}
     * @memberof ProfessionError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ProfessionError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * Question holds most basic and crucial Question data used for REST entity
 * @export
 * @interface Question
 */
export interface Question {
    /**
     * 
     * @type {boolean}
     * @memberof Question
     */
    acceptNoAnswer?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    answerOrder?: number;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    contentAnalysisType: number;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    defaultAnswer?: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    dinQuestionCode?: string;
    /**
     * 
     * @type {Entity}
     * @memberof Question
     */
    entity: Entity;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    example?: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    fieldType?: QuestionFieldTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    info?: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    placeholder?: string;
    /**
     * PossibleAnswers is a generic of PossibleAnswer slice
     * @type {Array<PossibleAnswer>}
     * @memberof Question
     */
    possibleAnswers?: Array<PossibleAnswer>;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    rank?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Question
     */
    readOnly?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    recommendedAnswer?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Question
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    riskId?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Question
     */
    viewSettings?: { [key: string]: object; };
    /**
     * 
     * @type {Array<Condition>}
     * @memberof Question
     */
    visibilityConditions?: Array<Condition>;
}

/**
    * @export
    * @enum {string}
    */
export enum QuestionFieldTypeEnum {
    String = 'string',
    Textarea = 'textarea',
    Radio = 'radio',
    Checkbox = 'checkbox',
    Select = 'select',
    Email = 'email',
    Date = 'date',
    File = 'file',
    Auto = 'auto',
    Currency = 'currency',
    Checkboxlist = 'checkboxlist',
    Integer = 'integer',
    ProfessionEntity = 'professionEntity',
    Safety = 'safety',
    InsurerList = 'insurerList',
    YesNo = 'yesNo',
    Url = 'url',
    Api = 'api'
}

/**
 * QuestionAnswerSet holds QuestionAnswer and Inquiry data used for REST entity
 * @export
 * @interface QuestionAnswerSet
 */
export interface QuestionAnswerSet {
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSet
     */
    accountId: string;
    /**
     * 
     * @type {{ [key: string]: Array<QuestionAnswerSetPossibleSelectedAnswer>; }}
     * @memberof QuestionAnswerSet
     */
    answerMap?: { [key: string]: Array<QuestionAnswerSetPossibleSelectedAnswer>; };
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSet
     */
    baseObjectID: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSet
     */
    baseObjectType: QuestionAnswerSetBaseObjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSet
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSet
     */
    fillingStatus: QuestionAnswerSetFillingStatusEnum;
    /**
     * 
     * @type {Array<QuestionAnswerSetGroup>}
     * @memberof QuestionAnswerSet
     */
    groups: Array<QuestionAnswerSetGroup>;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSet
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSet
     */
    inquiryHash?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSet
     */
    inquiryId?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSet
     */
    origin: QuestionAnswerSetOriginEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSet
     */
    predecessorId: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSet
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSet
     */
    productName: string;
    /**
     * ProfessionAliases represents slice of ProfessionAlias
     * @type {Array<QuestionAnswerSetProfessionAlias>}
     * @memberof QuestionAnswerSet
     */
    professionAliases: Array<QuestionAnswerSetProfessionAlias>;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSet
     */
    status: QuestionAnswerSetStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSet
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSet
     */
    updateAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum QuestionAnswerSetBaseObjectTypeEnum {
    Inquiry = 'inquiry',
    Consultation = 'consultation',
    DinAnalysis = 'din_analysis'
}
/**
    * @export
    * @enum {string}
    */
export enum QuestionAnswerSetFillingStatusEnum {
    Full = 'full',
    Half = 'half',
    Empty = 'empty'
}
/**
    * @export
    * @enum {string}
    */
export enum QuestionAnswerSetOriginEnum {
    RiskAssessment = 'riskAssessment',
    RiskAnalysis = 'riskAnalysis',
    ContentAnalysis = 'contentAnalysis',
    BuildingAnalysis = 'buildingAnalysis',
    Tendering = 'tendering',
    Booking = 'booking',
    NormedRiskAnalysis = 'normedRiskAnalysis'
}
/**
    * @export
    * @enum {string}
    */
export enum QuestionAnswerSetStatusEnum {
    IsLocked = 'isLocked',
    IsEditable = 'isEditable',
    IsCompleted = 'isCompleted'
}

/**
 * Category is dto for Category
 * @export
 * @interface QuestionAnswerSetCategory
 */
export interface QuestionAnswerSetCategory {
    /**
     * Categories are dto for Categories
     * @type {Array<QuestionAnswerSetCategory>}
     * @memberof QuestionAnswerSetCategory
     */
    children: Array<QuestionAnswerSetCategory>;
    /**
     * 
     * @type {FileImportTemplate}
     * @memberof QuestionAnswerSetCategory
     */
    fileImportTemplate?: FileImportTemplate;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetCategory
     */
    fillingStatus: QuestionAnswerSetCategoryFillingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetCategory
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerSetCategory
     */
    index: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetCategory
     */
    inquiryHash?: string;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionAnswerSetCategory
     */
    isTemplate: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetCategory
     */
    mappingType?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetCategory
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetCategory
     */
    originalId: string;
    /**
     * Questions is dto for Questions
     * @type {Array<QuestionAnswerSetQuestion>}
     * @memberof QuestionAnswerSetCategory
     */
    questions: Array<QuestionAnswerSetQuestion>;
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerSetCategory
     */
    rank: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetCategory
     */
    type: QuestionAnswerSetCategoryTypeEnum;
    /**
     * 
     * @type {Array<Condition>}
     * @memberof QuestionAnswerSetCategory
     */
    visibilityConditions: Array<Condition>;
}

/**
    * @export
    * @enum {string}
    */
export enum QuestionAnswerSetCategoryFillingStatusEnum {
    Full = 'full',
    Half = 'half',
    Empty = 'empty'
}
/**
    * @export
    * @enum {string}
    */
export enum QuestionAnswerSetCategoryTypeEnum {
    Object = 'object',
    Simple = 'simple'
}

/**
 * ContentBlock is dto for ContentBlock
 * @export
 * @interface QuestionAnswerSetContentBlock
 */
export interface QuestionAnswerSetContentBlock {
    /**
     * 
     * @type {Array<QuestionAnswerSetContentBlock>}
     * @memberof QuestionAnswerSetContentBlock
     */
    children: Array<QuestionAnswerSetContentBlock>;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetContentBlock
     */
    id: string;
    /**
     * Questions is dto for Questions
     * @type {Array<QuestionAnswerSetQuestion>}
     * @memberof QuestionAnswerSetContentBlock
     */
    questions: Array<QuestionAnswerSetQuestion>;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetContentBlock
     */
    title: string;
}
/**
 * Group is dto for Group
 * @export
 * @interface QuestionAnswerSetGroup
 */
export interface QuestionAnswerSetGroup {
    /**
     * Categories are dto for Categories
     * @type {Array<QuestionAnswerSetCategory>}
     * @memberof QuestionAnswerSetGroup
     */
    categories: Array<QuestionAnswerSetCategory>;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetGroup
     */
    fillingStatus: QuestionAnswerSetGroupFillingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetGroup
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetGroup
     */
    inquiryHash?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetGroup
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetGroup
     */
    originalId: string;
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerSetGroup
     */
    rank: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetGroup
     */
    type: QuestionAnswerSetGroupTypeEnum;
    /**
     * 
     * @type {Array<Condition>}
     * @memberof QuestionAnswerSetGroup
     */
    visibilityConditions: Array<Condition>;
}

/**
    * @export
    * @enum {string}
    */
export enum QuestionAnswerSetGroupFillingStatusEnum {
    Full = 'full',
    Half = 'half',
    Empty = 'empty'
}
/**
    * @export
    * @enum {string}
    */
export enum QuestionAnswerSetGroupTypeEnum {
    Object = 'object',
    Simple = 'simple',
    Mixed = 'mixed'
}

/**
 * PossibleAnswer is dto for PossibleAnswer
 * @export
 * @interface QuestionAnswerSetPossibleAnswer
 */
export interface QuestionAnswerSetPossibleAnswer {
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetPossibleAnswer
     */
    DefaultValue: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetPossibleAnswer
     */
    ID: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetPossibleAnswer
     */
    OriginalID: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetPossibleAnswer
     */
    Text: string;
}
/**
 * SelectedAnswer is dto for SelectedAnswer
 * @export
 * @interface QuestionAnswerSetPossibleSelectedAnswer
 */
export interface QuestionAnswerSetPossibleSelectedAnswer {
    /**
     * 
     * @type {Document}
     * @memberof QuestionAnswerSetPossibleSelectedAnswer
     */
    FileInfo?: Document;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetPossibleSelectedAnswer
     */
    ID: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetPossibleSelectedAnswer
     */
    OriginalID: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetPossibleSelectedAnswer
     */
    Text: string;
}
/**
 * 
 * @export
 * @interface QuestionAnswerSetProfessionAlias
 */
export interface QuestionAnswerSetProfessionAlias {
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetProfessionAlias
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionAnswerSetProfessionAlias
     */
    isSuggested?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetProfessionAlias
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetProfessionAlias
     */
    originalID: string;
}
/**
 * Question is dto for Question
 * @export
 * @interface QuestionAnswerSetQuestion
 */
export interface QuestionAnswerSetQuestion {
    /**
     * 
     * @type {boolean}
     * @memberof QuestionAnswerSetQuestion
     */
    acceptNoAnswer: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionAnswerSetQuestion
     */
    answered: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestion
     */
    defaultValue: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestion
     */
    dinQuestionCode: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestion
     */
    fieldType: QuestionAnswerSetQuestionFieldTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestion
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestion
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestion
     */
    originalId: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestion
     */
    parentOriginalId: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestion
     */
    placeholder?: string;
    /**
     * 
     * @type {Array<QuestionAnswerSetPossibleAnswer>}
     * @memberof QuestionAnswerSetQuestion
     */
    possibleAnswers: Array<QuestionAnswerSetPossibleAnswer>;
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerSetQuestion
     */
    rank: number;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionAnswerSetQuestion
     */
    readOnly: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestion
     */
    recommendedAnswer?: string;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionAnswerSetQuestion
     */
    required?: boolean;
    /**
     * 
     * @type {Array<QuestionAnswerSetPossibleSelectedAnswer>}
     * @memberof QuestionAnswerSetQuestion
     */
    selectedAnswers: Array<QuestionAnswerSetPossibleSelectedAnswer>;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestion
     */
    text: string;
    /**
     * 
     * @type {Array<Condition>}
     * @memberof QuestionAnswerSetQuestion
     */
    validations: Array<Condition>;
    /**
     * 
     * @type {ViewSettings}
     * @memberof QuestionAnswerSetQuestion
     */
    viewSettings?: ViewSettings;
    /**
     * 
     * @type {Array<Condition>}
     * @memberof QuestionAnswerSetQuestion
     */
    visibilityConditions: Array<Condition>;
}

/**
    * @export
    * @enum {string}
    */
export enum QuestionAnswerSetQuestionFieldTypeEnum {
    Checkbox = 'checkbox',
    Checkboxlist = 'checkboxlist',
    Radio = 'radio',
    Textarea = 'textarea',
    Input = 'input',
    String = 'string',
    Date = 'date',
    Integer = 'integer',
    Boolean = 'boolean',
    Currency = 'currency',
    File = 'file',
    Select = 'select',
    InsurerList = 'insurerList',
    ProfessionEntity = 'professionEntity',
    Api = 'api'
}

/**
 * 
 * @export
 * @interface QuestionAnswerSetQuestionAnswer
 */
export interface QuestionAnswerSetQuestionAnswer {
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    accountId: string;
    /**
     * 
     * @type {{ [key: string]: Array<QuestionAnswerSetPossibleSelectedAnswer>; }}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    answerMap?: { [key: string]: Array<QuestionAnswerSetPossibleSelectedAnswer>; };
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    baseObjectID: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    baseObjectType: QuestionAnswerSetQuestionAnswerBaseObjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    fillingStatus: QuestionAnswerSetQuestionAnswerFillingStatusEnum;
    /**
     * 
     * @type {Array<QuestionAnswerSetGroup>}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    groups: Array<QuestionAnswerSetGroup>;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    inquiryHash?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    inquiryId?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    origin: QuestionAnswerSetQuestionAnswerOriginEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    predecessorId: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    productName: string;
    /**
     * ProfessionAliases represents slice of ProfessionAlias
     * @type {Array<QuestionAnswerSetProfessionAlias>}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    professionAliases: Array<QuestionAnswerSetProfessionAlias>;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    status: QuestionAnswerSetQuestionAnswerStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswerSetQuestionAnswer
     */
    updateAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum QuestionAnswerSetQuestionAnswerBaseObjectTypeEnum {
    Inquiry = 'inquiry',
    Consultation = 'consultation',
    DinAnalysis = 'din_analysis'
}
/**
    * @export
    * @enum {string}
    */
export enum QuestionAnswerSetQuestionAnswerFillingStatusEnum {
    Full = 'full',
    Half = 'half',
    Empty = 'empty'
}
/**
    * @export
    * @enum {string}
    */
export enum QuestionAnswerSetQuestionAnswerOriginEnum {
    RiskAssessment = 'riskAssessment',
    RiskAnalysis = 'riskAnalysis',
    ContentAnalysis = 'contentAnalysis',
    BuildingAnalysis = 'buildingAnalysis',
    Tendering = 'tendering',
    Booking = 'booking',
    NormedRiskAnalysis = 'normedRiskAnalysis'
}
/**
    * @export
    * @enum {string}
    */
export enum QuestionAnswerSetQuestionAnswerStatusEnum {
    IsLocked = 'isLocked',
    IsEditable = 'isEditable',
    IsCompleted = 'isCompleted'
}

/**
 * QuestionCategoryWithQuestions holds most basic and crucial QuestionCategoryWithQuestions data used for REST entity
 * @export
 * @interface QuestionCategoryWithQuestions
 */
export interface QuestionCategoryWithQuestions {
    /**
     * 
     * @type {Entity}
     * @memberof QuestionCategoryWithQuestions
     */
    entity: Entity;
    /**
     * 
     * @type {FileImportTemplate}
     * @memberof QuestionCategoryWithQuestions
     */
    fileImportTemplate?: FileImportTemplate;
    /**
     * 
     * @type {string}
     * @memberof QuestionCategoryWithQuestions
     */
    mappingType?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionCategoryWithQuestions
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof QuestionCategoryWithQuestions
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionCategoryWithQuestions
     */
    questionCategoryGroupID: number;
    /**
     * Questions type is a \"generic\" which holds a slice of Question
     * @type {Array<Question>}
     * @memberof QuestionCategoryWithQuestions
     */
    questions?: Array<Question>;
    /**
     * 
     * @type {number}
     * @memberof QuestionCategoryWithQuestions
     */
    rank: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionCategoryWithQuestions
     */
    type: number;
    /**
     * 
     * @type {Array<Condition>}
     * @memberof QuestionCategoryWithQuestions
     */
    visibilityConditions?: Array<Condition>;
}
/**
 * QuestionPage holds most basic and crucial QuestionPage data used for REST entity
 * @export
 * @interface QuestionPage
 */
export interface QuestionPage {
    /**
     * QuestionCategoriesWithQuestions type is a \"generic\" which holds a slice of QuestionCategoryWithQuestions
     * @type {Array<QuestionCategoryWithQuestions>}
     * @memberof QuestionPage
     */
    categories?: Array<QuestionCategoryWithQuestions>;
    /**
     * 
     * @type {Entity}
     * @memberof QuestionPage
     */
    entity: Entity;
    /**
     * 
     * @type {number}
     * @memberof QuestionPage
     */
    number: number;
}
/**
 * QuestionPageError is an Error Representation object
 * @export
 * @interface QuestionPageError
 */
export interface QuestionPageError {
    /**
     * 
     * @type {string}
     * @memberof QuestionPageError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof QuestionPageError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * QuestionPageList is a Representation object
 * @export
 * @interface QuestionPageList
 */
export interface QuestionPageList {
    /**
     * 
     * @type {EmbeddedQuestionPages}
     * @memberof QuestionPageList
     */
    _embedded: EmbeddedQuestionPages;
    /**
     * 
     * @type {number}
     * @memberof QuestionPageList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionPageList
     */
    total: number;
}
/**
 * 
 * @export
 * @interface Range
 */
export interface Range {
    /**
     * 
     * @type {number}
     * @memberof Range
     */
    contentAnalysisType: number;
    /**
     * 
     * @type {boolean}
     * @memberof Range
     */
    dataQualityPoor: boolean;
    /**
     * 
     * @type {number}
     * @memberof Range
     */
    estimate: number;
    /**
     * 
     * @type {number}
     * @memberof Range
     */
    lowerLimit: number;
    /**
     * 
     * @type {boolean}
     * @memberof Range
     */
    professionAvailable: boolean;
    /**
     * 
     * @type {number}
     * @memberof Range
     */
    upperLimit: number;
    /**
     * 
     * @type {boolean}
     * @memberof Range
     */
    valueRelevant: boolean;
}
/**
 * RangeList is a Representation object
 * @export
 * @interface RangeList
 */
export interface RangeList {
    /**
     * 
     * @type {EmbeddedRanges}
     * @memberof RangeList
     */
    _embedded: EmbeddedRanges;
    /**
     * 
     * @type {number}
     * @memberof RangeList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof RangeList
     */
    total: number;
}
/**
 * 
 * @export
 * @interface RangesError
 */
export interface RangesError {
    /**
     * 
     * @type {string}
     * @memberof RangesError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof RangesError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * ReviewMessage represents review message data
 * @export
 * @interface ReviewMessage
 */
export interface ReviewMessage {
    /**
     * 
     * @type {string}
     * @memberof ReviewMessage
     */
    brokerNotificationHash: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewMessage
     */
    conversationHash: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReviewMessage
     */
    isReviewed: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReviewMessage
     */
    messageHash: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewMessage
     */
    type: string;
}
/**
 * 
 * @export
 * @interface RiskAssessment
 */
export interface RiskAssessment {
    /**
     * 
     * @type {string}
     * @memberof RiskAssessment
     */
    consultationId: string;
    /**
     * 
     * @type {string}
     * @memberof RiskAssessment
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof RiskAssessment
     */
    hash: string;
    /**
     * 
     * @type {string}
     * @memberof RiskAssessment
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RiskAssessment
     */
    url: string;
}
/**
 * 
 * @export
 * @interface RiskAssessmentEmail
 */
export interface RiskAssessmentEmail {
    /**
     * 
     * @type {string}
     * @memberof RiskAssessmentEmail
     */
    content: string;
}
/**
 * 
 * @export
 * @interface RiskAssessmentEmailError
 */
export interface RiskAssessmentEmailError {
    /**
     * 
     * @type {string}
     * @memberof RiskAssessmentEmailError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof RiskAssessmentEmailError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface RiskAssessmentError
 */
export interface RiskAssessmentError {
    /**
     * 
     * @type {string}
     * @memberof RiskAssessmentError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof RiskAssessmentError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface Rule
 */
export interface Rule {
    /**
     * 
     * @type {string}
     * @memberof Rule
     */
    conditionOperator: string;
    /**
     * 
     * @type {string}
     * @memberof Rule
     */
    expression: string;
    /**
     * 
     * @type {number}
     * @memberof Rule
     */
    group?: number;
    /**
     * 
     * @type {string}
     * @memberof Rule
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Rule
     */
    linkOperator: string;
    /**
     * 
     * @type {number}
     * @memberof Rule
     */
    rank?: number;
    /**
     * 
     * @type {string}
     * @memberof Rule
     */
    type: string;
}
/**
 * SaveAnalysis represents Analysis data used for REST entity for purpose of persisting
 * @export
 * @interface SaveAnalysis
 */
export interface SaveAnalysis {
    /**
     * 
     * @type {Array<SaveAnsweredQuestion>}
     * @memberof SaveAnalysis
     */
    answeredQuestions: Array<SaveAnsweredQuestion>;
}
/**
 * SaveAnsweredQuestion represents AnsweredQuestion data used for REST entity for purpose of persisting
 * @export
 * @interface SaveAnsweredQuestion
 */
export interface SaveAnsweredQuestion {
    /**
     * 
     * @type {string}
     * @memberof SaveAnsweredQuestion
     */
    answerId?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveAnsweredQuestion
     */
    answerText?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveAnsweredQuestion
     */
    questionId: string;
}
/**
 * SaveCompany is DTO used for API
 * @export
 * @interface SaveCompany
 */
export interface SaveCompany {
    /**
     * 
     * @type {Address}
     * @memberof SaveCompany
     */
    address?: Address;
    /**
     * 
     * @type {boolean}
     * @memberof SaveCompany
     */
    differingPostalAddress: boolean;
    /**
     * 
     * @type {string}
     * @memberof SaveCompany
     */
    legalForm?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveCompany
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveCompany
     */
    registrationCity?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveCompany
     */
    registrationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveCompany
     */
    website?: string;
}
/**
 * SaveConsultation holds most basic and crucial Consultation data used for REST entity
 * @export
 * @interface SaveConsultation
 */
export interface SaveConsultation {
    /**
     * 
     * @type {SaveCustomer}
     * @memberof SaveConsultation
     */
    customer?: SaveCustomer;
    /**
     * 
     * @type {string}
     * @memberof SaveConsultation
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveConsultation
     */
    name?: string;
    /**
     * SaveProfessionAliases type is a \"generic\" which holds a slice of SaveProfessionAlias
     * @type {Array<SaveProfessionAlias>}
     * @memberof SaveConsultation
     */
    professionAliases: Array<SaveProfessionAlias>;
}
/**
 * SaveContractID is DTO for API
 * @export
 * @interface SaveContractID
 */
export interface SaveContractID {
    /**
     * 
     * @type {string}
     * @memberof SaveContractID
     */
    contractId?: string;
}
/**
 * SaveCustomer is DTO used for API requests
 * @export
 * @interface SaveCustomer
 */
export interface SaveCustomer {
    /**
     * 
     * @type {string}
     * @memberof SaveCustomer
     */
    accountType: SaveCustomerAccountTypeEnum;
    /**
     * 
     * @type {Address}
     * @memberof SaveCustomer
     */
    billingAddress?: Address;
    /**
     * 
     * @type {SaveCompany}
     * @memberof SaveCustomer
     */
    company: SaveCompany;
    /**
     * 
     * @type {ContactPerson}
     * @memberof SaveCustomer
     */
    contactPerson?: ContactPerson;
    /**
     * 
     * @type {string}
     * @memberof SaveCustomer
     */
    internalId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SaveCustomerAccountTypeEnum {
    Private = 'private',
    Company = 'company'
}

/**
 * SaveInquiry holds most basic and crucial Inquiry data used for REST entity
 * @export
 * @interface SaveInquiry
 */
export interface SaveInquiry {
    /**
     * 
     * @type {string}
     * @memberof SaveInquiry
     */
    comment?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SaveInquiry
     */
    productIds: Array<string>;
}
/**
 * SaveInquiryIDs is DTO for API
 * @export
 * @interface SaveInquiryIDs
 */
export interface SaveInquiryIDs {
    /**
     * 
     * @type {Array<string>}
     * @memberof SaveInquiryIDs
     */
    inquiryIds?: Array<string>;
}
/**
 * SaveOfferGroup DTO for response
 * @export
 * @interface SaveOfferGroup
 */
export interface SaveOfferGroup {
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGroup
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof SaveOfferGroup
     */
    isCalculation: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveOfferGroup
     */
    isProcessed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveOfferGroup
     */
    isTendering: boolean;
}
/**
 * SavePayment is DTO used for API requests
 * @export
 * @interface SavePayment
 */
export interface SavePayment {
    /**
     * 
     * @type {string}
     * @memberof SavePayment
     */
    bankAccountHolder?: string;
    /**
     * 
     * @type {string}
     * @memberof SavePayment
     */
    bankName?: string;
    /**
     * 
     * @type {string}
     * @memberof SavePayment
     */
    bic?: string;
    /**
     * 
     * @type {string}
     * @memberof SavePayment
     */
    iban?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SavePayment
     */
    isPreferred: boolean;
    /**
     * 
     * @type {string}
     * @memberof SavePayment
     */
    paymentMethod: SavePaymentPaymentMethodEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SavePaymentPaymentMethodEnum {
    DirectDebit = 'directDebit',
    Invoice = 'invoice',
    None = 'none'
}

/**
 * SaveProfessionAlias holds most basic and crucial SaveProfessionAlias data used for REST entity
 * @export
 * @interface SaveProfessionAlias
 */
export interface SaveProfessionAlias {
    /**
     * 
     * @type {string}
     * @memberof SaveProfessionAlias
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SaveProfessionAlias
     */
    isSuggested: boolean;
    /**
     * 
     * @type {string}
     * @memberof SaveProfessionAlias
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SaveProfessionAlias
     */
    originalId: string;
}
/**
 * SaveProposalID is DTO for API
 * @export
 * @interface SaveProposalID
 */
export interface SaveProposalID {
    /**
     * 
     * @type {string}
     * @memberof SaveProposalID
     */
    proposalId?: string;
}
/**
 * 
 * @export
 * @interface SaveRiskAssessment
 */
export interface SaveRiskAssessment {
    /**
     * 
     * @type {Array<string>}
     * @memberof SaveRiskAssessment
     */
    productIds: Array<string>;
}
/**
 * SelectedOffer DTO for response
 * @export
 * @interface SelectedOffer
 */
export interface SelectedOffer {
    /**
     * 
     * @type {InquiryTeaser}
     * @memberof SelectedOffer
     */
    inquiry: InquiryTeaser;
    /**
     * 
     * @type {Offer}
     * @memberof SelectedOffer
     */
    offer: Offer;
}
/**
 * SelectedProfessionAlias holds most basic and crucial ProfessionAlias data used for REST entity
 * @export
 * @interface SelectedProfessionAlias
 */
export interface SelectedProfessionAlias {
    /**
     * 
     * @type {string}
     * @memberof SelectedProfessionAlias
     */
    description?: string;
    /**
     * 
     * @type {Entity}
     * @memberof SelectedProfessionAlias
     */
    entity: Entity;
    /**
     * 
     * @type {boolean}
     * @memberof SelectedProfessionAlias
     */
    isSuggested: boolean;
    /**
     * 
     * @type {string}
     * @memberof SelectedProfessionAlias
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SelectedProfessionAlias
     */
    originalId: string;
}
/**
 * SentMessage represents a message that has been sent
 * @export
 * @interface SentMessage
 */
export interface SentMessage {
    /**
     * 
     * @type {SentMessageAttributes}
     * @memberof SentMessage
     */
    attributes: SentMessageAttributes;
    /**
     * 
     * @type {string}
     * @memberof SentMessage
     */
    id: string;
    /**
     * 
     * @type {SentMessageRelationships}
     * @memberof SentMessage
     */
    relationships: SentMessageRelationships;
    /**
     * 
     * @type {string}
     * @memberof SentMessage
     */
    type: string;
}
/**
 * SentMessageAttributes represents attributes of sent message
 * @export
 * @interface SentMessageAttributes
 */
export interface SentMessageAttributes {
    /**
     * 
     * @type {string}
     * @memberof SentMessageAttributes
     */
    authorHash: string;
    /**
     * 
     * @type {string}
     * @memberof SentMessageAttributes
     */
    authorNickname: string;
    /**
     * 
     * @type {string}
     * @memberof SentMessageAttributes
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof SentMessageAttributes
     */
    fileKey: string;
    /**
     * 
     * @type {boolean}
     * @memberof SentMessageAttributes
     */
    forwardCopy: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SentMessageAttributes
     */
    forwarded: boolean;
    /**
     * 
     * @type {number}
     * @memberof SentMessageAttributes
     */
    reasonId: number;
    /**
     * 
     * @type {boolean}
     * @memberof SentMessageAttributes
     */
    replied: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SentMessageAttributes
     */
    reviewed: boolean;
    /**
     * 
     * @type {string}
     * @memberof SentMessageAttributes
     */
    text: string;
}
/**
 * SentMessageConversation represents sent message conversation
 * @export
 * @interface SentMessageConversation
 */
export interface SentMessageConversation {
    /**
     * 
     * @type {SentMessageData}
     * @memberof SentMessageConversation
     */
    data: SentMessageData;
}
/**
 * SentMessageData represents sent message data
 * @export
 * @interface SentMessageData
 */
export interface SentMessageData {
    /**
     * 
     * @type {string}
     * @memberof SentMessageData
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SentMessageData
     */
    type: string;
}
/**
 * SentMessageRelationships represents sent message relationships
 * @export
 * @interface SentMessageRelationships
 */
export interface SentMessageRelationships {
    /**
     * 
     * @type {SentMessageConversation}
     * @memberof SentMessageRelationships
     */
    conversation: SentMessageConversation;
}
/**
 * SentMessagesData holds sent messages
 * @export
 * @interface SentMessagesData
 */
export interface SentMessagesData {
    /**
     * SentMessages represents a collection of sent messages
     * @type {Array<SentMessage>}
     * @memberof SentMessagesData
     */
    data?: Array<SentMessage>;
}
/**
 * 
 * @export
 * @interface Subscription
 */
export interface Subscription {
    /**
     * PageType represents the type of pages
     * @type {string}
     * @memberof Subscription
     */
    pageType: string;
    /**
     * 
     * @type {boolean}
     * @memberof Subscription
     */
    subscribed: boolean;
}
/**
 * TenderOwner represents tender owner
 * @export
 * @interface TenderOwner
 */
export interface TenderOwner {
    /**
     * 
     * @type {string}
     * @memberof TenderOwner
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof TenderOwner
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof TenderOwner
     */
    lastName?: string;
}
/**
 * 
 * @export
 * @interface TenderRequest
 */
export interface TenderRequest {
    /**
     * 
     * @type {Customer}
     * @memberof TenderRequest
     */
    customer: Customer;
    /**
     * 
     * @type {Array<LegalForm>}
     * @memberof TenderRequest
     */
    legalForms: Array<LegalForm>;
    /**
     * 
     * @type {QuestionAnswerSetQuestionAnswer}
     * @memberof TenderRequest
     */
    questionAnswerSet: QuestionAnswerSetQuestionAnswer;
}
/**
 * 
 * @export
 * @interface TenderRequestError
 */
export interface TenderRequestError {
    /**
     * 
     * @type {string}
     * @memberof TenderRequestError
     */
    error: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof TenderRequestError
     */
    validation?: { [key: string]: Array<string>; };
}
/**
 * UpdateQuestionAnswerStatus holds data to change the status of Question Answer Set data used for REST entity
 * @export
 * @interface UpdateQuestionAnswerStatus
 */
export interface UpdateQuestionAnswerStatus {
    /**
     * 
     * @type {string}
     * @memberof UpdateQuestionAnswerStatus
     */
    status: UpdateQuestionAnswerStatusStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateQuestionAnswerStatusStatusEnum {
    IsEditable = 'isEditable',
    IsCompleted = ' isCompleted',
    IsLocked = ' isLocked'
}

/**
 * 
 * @export
 * @interface Variation
 */
export interface Variation {
    /**
     * 
     * @type {number}
     * @memberof Variation
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Variation
     */
    type: VariationTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Variation
     */
    views: number;
}

/**
    * @export
    * @enum {string}
    */
export enum VariationTypeEnum {
    VariationA = 'variationA',
    VariationB = 'variationB'
}

/**
 * ViewSettings is dto for ViewSettings
 * @export
 * @interface ViewSettings
 */
export interface ViewSettings {
    /**
     * 
     * @type {string}
     * @memberof ViewSettings
     */
    calculation?: string;
    /**
     * 
     * @type {string}
     * @memberof ViewSettings
     */
    date?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ViewSettings
     */
    decimal?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ViewSettings
     */
    step?: number;
}
/**
 * Violation is DTO used for API responses
 * @export
 * @interface Violation
 */
export interface Violation {
    /**
     * 
     * @type {string}
     * @memberof Violation
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Violation
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof Violation
     */
    propertyPath: string;
    /**
     * 
     * @type {string}
     * @memberof Violation
     */
    rule: string;
}

/**
 * AbtestingApi - axios parameter creator
 * @export
 */
export const AbtestingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVariationRequest: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/abtesting/variation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AbtestingApi - functional programming interface
 * @export
 */
export const AbtestingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AbtestingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVariationRequest(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Variation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVariationRequest(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AbtestingApi - factory interface
 * @export
 */
export const AbtestingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AbtestingApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVariationRequest(options?: any): AxiosPromise<Variation> {
            return localVarFp.getVariationRequest(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AbtestingApi - object-oriented interface
 * @export
 * @class AbtestingApi
 * @extends {BaseAPI}
 */
export class AbtestingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbtestingApi
     */
    public getVariationRequest(options?: any) {
        return AbtestingApiFp(this.configuration).getVariationRequest(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConsultationApi - axios parameter creator
 * @export
 */
export const ConsultationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDConsultationOverviewRequest: async (consultationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('byIDConsultationOverviewRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}/overview`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDConsultationRequest: async (consultationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('byIDConsultationRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SaveConsultation} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConsultationRequest: async (body?: SaveConsultation, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/consultations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveInquiry} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInquiryByConsultationIDRequest: async (consultationId: string, body?: SaveInquiry, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('createInquiryByConsultationIDRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}/inquiries`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveRiskAssessment} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRiskAssessmentRequest: async (consultationId: string, body?: SaveRiskAssessment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('createRiskAssessmentRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}/riskAssessment`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConsultationRequest: async (consultationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('deleteConsultationRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveInquiryIDs} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishOrderByConsultationIDRequest: async (consultationId: string, body?: SaveInquiryIDs, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('finishOrderByConsultationIDRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}/orders`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnswerSetRequest: async (consultationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('getAnswerSetRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}/questionAnswerSets`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentAnalysisRequest: async (consultationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('getContentAnalysisRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}/contentAnalyses`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInquiryByConsultationIDRequest: async (consultationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('getInquiryByConsultationIDRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}/inquiries`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentsByConsultationIDRequest: async (consultationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('getPaymentsByConsultationIDRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}/payments`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPremiumPredictionsRequest: async (consultationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('getPremiumPredictionsRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}/premiumPredictions`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRangesRequest: async (consultationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('getRangesRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}/ranges`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskAnalysisRequest: async (consultationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('getRiskAnalysisRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}/riskAnalyses`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {string} riskAssessmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskAssessmentEmailRequest: async (consultationId: string, riskAssessmentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('getRiskAssessmentEmailRequest', 'consultationId', consultationId)
            // verify required parameter 'riskAssessmentId' is not null or undefined
            assertParamExists('getRiskAssessmentEmailRequest', 'riskAssessmentId', riskAssessmentId)
            const localVarPath = `/consultations/{consultationId}/riskAssessment/{riskAssessmentId}/email`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)))
                .replace(`{${"riskAssessmentId"}}`, encodeURIComponent(String(riskAssessmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectedOffersByConsultationIDRequest: async (consultationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('getSelectedOffersByConsultationIDRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}/selectedOffers`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [hash] 
         * @param {string} [search] 
         * @param {string} [customerId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        overviewConsultationRequest: async (hash?: string, search?: string, customerId?: string, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/consultations/overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (hash !== undefined) {
                localVarQueryParameter['hash'] = hash;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (noPagination !== undefined) {
                localVarQueryParameter['noPagination'] = noPagination;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (includeLocked !== undefined) {
                localVarQueryParameter['includeLocked'] = includeLocked;
            }

            if (includeInactive !== undefined) {
                localVarQueryParameter['includeInactive'] = includeInactive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productRecommendationByConsultationIDRequest: async (consultationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('productRecommendationByConsultationIDRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}/productRecommendations`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        professionAliasesByConsultationIDRequest: async (consultationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('professionAliasesByConsultationIDRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}/professionAliases`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        professionsByConsultationIDRequest: async (consultationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('professionsByConsultationIDRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}/professions`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionPagesByConsultationIDRequest: async (consultationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('questionPagesByConsultationIDRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}/contentAnalysisQuestionPages`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionPagesByConsultationIDRequest_1: async (consultationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('questionPagesByConsultationIDRequest_1', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}/riskAnalysisQuestionPages`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [hash] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchConsultationRequest: async (hash?: string, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/consultations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (hash !== undefined) {
                localVarQueryParameter['hash'] = hash;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (noPagination !== undefined) {
                localVarQueryParameter['noPagination'] = noPagination;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (includeLocked !== undefined) {
                localVarQueryParameter['includeLocked'] = includeLocked;
            }

            if (includeInactive !== undefined) {
                localVarQueryParameter['includeInactive'] = includeInactive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {CustomerMessage} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCustomerMessageRequest: async (consultationId: string, body?: CustomerMessage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('sendCustomerMessageRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}/riskAssessment/customerMessage`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveInquiryIDs} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startBookingByConsultationIDRequest: async (consultationId: string, body?: SaveInquiryIDs, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('startBookingByConsultationIDRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}/bookings`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveInquiryIDs} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startOrderByConsultationIDRequest: async (consultationId: string, body?: SaveInquiryIDs, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('startOrderByConsultationIDRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}/orders`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveInquiryIDs} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startProposalByConsultationIDRequest: async (consultationId: string, body?: SaveInquiryIDs, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('startProposalByConsultationIDRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}/proposals`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveAnalysis} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentAnalysisRequest: async (consultationId: string, body?: SaveAnalysis, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('updateContentAnalysisRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}/contentAnalyses`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveCustomer} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerByConsultationIDRequest: async (consultationId: string, body?: SaveCustomer, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('updateCustomerByConsultationIDRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}/customers`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {Array<SavePayment>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentsByConsultationIDRequest: async (consultationId: string, body?: Array<SavePayment>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('updatePaymentsByConsultationIDRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}/payments`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveAnalysis} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRiskAnalysisRequest: async (consultationId: string, body?: SaveAnalysis, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('updateRiskAnalysisRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}/riskAnalyses`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConsultationApi - functional programming interface
 * @export
 */
export const ConsultationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConsultationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async byIDConsultationOverviewRequest(consultationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Overview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.byIDConsultationOverviewRequest(consultationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async byIDConsultationRequest(consultationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consultation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.byIDConsultationRequest(consultationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SaveConsultation} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConsultationRequest(body?: SaveConsultation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Analysis>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConsultationRequest(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveInquiry} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInquiryByConsultationIDRequest(consultationId: string, body?: SaveInquiry, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Inquiry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInquiryByConsultationIDRequest(consultationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveRiskAssessment} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRiskAssessmentRequest(consultationId: string, body?: SaveRiskAssessment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskAssessment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRiskAssessmentRequest(consultationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConsultationRequest(consultationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConsultationRequest(consultationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveInquiryIDs} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finishOrderByConsultationIDRequest(consultationId: string, body?: SaveInquiryIDs, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finishOrderByConsultationIDRequest(consultationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnswerSetRequest(consultationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuestionAnswerSet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnswerSetRequest(consultationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentAnalysisRequest(consultationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Analysis>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentAnalysisRequest(consultationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInquiryByConsultationIDRequest(consultationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InquiryWithProductList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInquiryByConsultationIDRequest(consultationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentsByConsultationIDRequest(consultationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Payment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentsByConsultationIDRequest(consultationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPremiumPredictionsRequest(consultationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PremiumPredictionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPremiumPredictionsRequest(consultationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRangesRequest(consultationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RangeList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRangesRequest(consultationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRiskAnalysisRequest(consultationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Analysis>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRiskAnalysisRequest(consultationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {string} riskAssessmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRiskAssessmentEmailRequest(consultationId: string, riskAssessmentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskAssessmentEmail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRiskAssessmentEmailRequest(consultationId, riskAssessmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelectedOffersByConsultationIDRequest(consultationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectedOffer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelectedOffersByConsultationIDRequest(consultationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [hash] 
         * @param {string} [search] 
         * @param {string} [customerId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async overviewConsultationRequest(hash?: string, search?: string, customerId?: string, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsultationsWithProductsAndProfessionsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.overviewConsultationRequest(hash, search, customerId, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productRecommendationByConsultationIDRequest(consultationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductRecommendation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productRecommendationByConsultationIDRequest(consultationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async professionAliasesByConsultationIDRequest(consultationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfessionAliasList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.professionAliasesByConsultationIDRequest(consultationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async professionsByConsultationIDRequest(consultationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Profession>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.professionsByConsultationIDRequest(consultationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionPagesByConsultationIDRequest(consultationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionPageList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionPagesByConsultationIDRequest(consultationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionPagesByConsultationIDRequest_1(consultationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionPageList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionPagesByConsultationIDRequest_1(consultationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [hash] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchConsultationRequest(hash?: string, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsultationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchConsultationRequest(hash, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {CustomerMessage} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendCustomerMessageRequest(consultationId: string, body?: CustomerMessage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskAssessment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendCustomerMessageRequest(consultationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveInquiryIDs} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startBookingByConsultationIDRequest(consultationId: string, body?: SaveInquiryIDs, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startBookingByConsultationIDRequest(consultationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveInquiryIDs} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startOrderByConsultationIDRequest(consultationId: string, body?: SaveInquiryIDs, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startOrderByConsultationIDRequest(consultationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveInquiryIDs} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startProposalByConsultationIDRequest(consultationId: string, body?: SaveInquiryIDs, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startProposalByConsultationIDRequest(consultationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveAnalysis} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContentAnalysisRequest(consultationId: string, body?: SaveAnalysis, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContentAnalysisRequest(consultationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveCustomer} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerByConsultationIDRequest(consultationId: string, body?: SaveCustomer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerByConsultationIDRequest(consultationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {Array<SavePayment>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePaymentsByConsultationIDRequest(consultationId: string, body?: Array<SavePayment>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Payment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePaymentsByConsultationIDRequest(consultationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveAnalysis} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRiskAnalysisRequest(consultationId: string, body?: SaveAnalysis, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRiskAnalysisRequest(consultationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConsultationApi - factory interface
 * @export
 */
export const ConsultationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConsultationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDConsultationOverviewRequest(consultationId: string, options?: any): AxiosPromise<Overview> {
            return localVarFp.byIDConsultationOverviewRequest(consultationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byIDConsultationRequest(consultationId: string, options?: any): AxiosPromise<Consultation> {
            return localVarFp.byIDConsultationRequest(consultationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SaveConsultation} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConsultationRequest(body?: SaveConsultation, options?: any): AxiosPromise<Analysis> {
            return localVarFp.createConsultationRequest(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveInquiry} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInquiryByConsultationIDRequest(consultationId: string, body?: SaveInquiry, options?: any): AxiosPromise<Array<Inquiry>> {
            return localVarFp.createInquiryByConsultationIDRequest(consultationId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveRiskAssessment} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRiskAssessmentRequest(consultationId: string, body?: SaveRiskAssessment, options?: any): AxiosPromise<RiskAssessment> {
            return localVarFp.createRiskAssessmentRequest(consultationId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConsultationRequest(consultationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteConsultationRequest(consultationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveInquiryIDs} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishOrderByConsultationIDRequest(consultationId: string, body?: SaveInquiryIDs, options?: any): AxiosPromise<void> {
            return localVarFp.finishOrderByConsultationIDRequest(consultationId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnswerSetRequest(consultationId: string, options?: any): AxiosPromise<Array<QuestionAnswerSet>> {
            return localVarFp.getAnswerSetRequest(consultationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentAnalysisRequest(consultationId: string, options?: any): AxiosPromise<Analysis> {
            return localVarFp.getContentAnalysisRequest(consultationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInquiryByConsultationIDRequest(consultationId: string, options?: any): AxiosPromise<InquiryWithProductList> {
            return localVarFp.getInquiryByConsultationIDRequest(consultationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentsByConsultationIDRequest(consultationId: string, options?: any): AxiosPromise<Array<Payment>> {
            return localVarFp.getPaymentsByConsultationIDRequest(consultationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPremiumPredictionsRequest(consultationId: string, options?: any): AxiosPromise<PremiumPredictionList> {
            return localVarFp.getPremiumPredictionsRequest(consultationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRangesRequest(consultationId: string, options?: any): AxiosPromise<RangeList> {
            return localVarFp.getRangesRequest(consultationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskAnalysisRequest(consultationId: string, options?: any): AxiosPromise<Analysis> {
            return localVarFp.getRiskAnalysisRequest(consultationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {string} riskAssessmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskAssessmentEmailRequest(consultationId: string, riskAssessmentId: string, options?: any): AxiosPromise<RiskAssessmentEmail> {
            return localVarFp.getRiskAssessmentEmailRequest(consultationId, riskAssessmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectedOffersByConsultationIDRequest(consultationId: string, options?: any): AxiosPromise<Array<SelectedOffer>> {
            return localVarFp.getSelectedOffersByConsultationIDRequest(consultationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [hash] 
         * @param {string} [search] 
         * @param {string} [customerId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        overviewConsultationRequest(hash?: string, search?: string, customerId?: string, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): AxiosPromise<ConsultationsWithProductsAndProfessionsList> {
            return localVarFp.overviewConsultationRequest(hash, search, customerId, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productRecommendationByConsultationIDRequest(consultationId: string, options?: any): AxiosPromise<ProductRecommendation> {
            return localVarFp.productRecommendationByConsultationIDRequest(consultationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        professionAliasesByConsultationIDRequest(consultationId: string, options?: any): AxiosPromise<ProfessionAliasList> {
            return localVarFp.professionAliasesByConsultationIDRequest(consultationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        professionsByConsultationIDRequest(consultationId: string, options?: any): AxiosPromise<Array<Profession>> {
            return localVarFp.professionsByConsultationIDRequest(consultationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionPagesByConsultationIDRequest(consultationId: string, options?: any): AxiosPromise<QuestionPageList> {
            return localVarFp.questionPagesByConsultationIDRequest(consultationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionPagesByConsultationIDRequest_1(consultationId: string, options?: any): AxiosPromise<QuestionPageList> {
            return localVarFp.questionPagesByConsultationIDRequest_1(consultationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [hash] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchConsultationRequest(hash?: string, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): AxiosPromise<ConsultationList> {
            return localVarFp.searchConsultationRequest(hash, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {CustomerMessage} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCustomerMessageRequest(consultationId: string, body?: CustomerMessage, options?: any): AxiosPromise<RiskAssessment> {
            return localVarFp.sendCustomerMessageRequest(consultationId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveInquiryIDs} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startBookingByConsultationIDRequest(consultationId: string, body?: SaveInquiryIDs, options?: any): AxiosPromise<void> {
            return localVarFp.startBookingByConsultationIDRequest(consultationId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveInquiryIDs} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startOrderByConsultationIDRequest(consultationId: string, body?: SaveInquiryIDs, options?: any): AxiosPromise<void> {
            return localVarFp.startOrderByConsultationIDRequest(consultationId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveInquiryIDs} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startProposalByConsultationIDRequest(consultationId: string, body?: SaveInquiryIDs, options?: any): AxiosPromise<void> {
            return localVarFp.startProposalByConsultationIDRequest(consultationId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveAnalysis} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentAnalysisRequest(consultationId: string, body?: SaveAnalysis, options?: any): AxiosPromise<void> {
            return localVarFp.updateContentAnalysisRequest(consultationId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveCustomer} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerByConsultationIDRequest(consultationId: string, body?: SaveCustomer, options?: any): AxiosPromise<Customer> {
            return localVarFp.updateCustomerByConsultationIDRequest(consultationId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {Array<SavePayment>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentsByConsultationIDRequest(consultationId: string, body?: Array<SavePayment>, options?: any): AxiosPromise<Array<Payment>> {
            return localVarFp.updatePaymentsByConsultationIDRequest(consultationId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consultationId 
         * @param {SaveAnalysis} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRiskAnalysisRequest(consultationId: string, body?: SaveAnalysis, options?: any): AxiosPromise<void> {
            return localVarFp.updateRiskAnalysisRequest(consultationId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConsultationApi - object-oriented interface
 * @export
 * @class ConsultationApi
 * @extends {BaseAPI}
 */
export class ConsultationApi extends BaseAPI {
    /**
     * 
     * @param {string} consultationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public byIDConsultationOverviewRequest(consultationId: string, options?: any) {
        return ConsultationApiFp(this.configuration).byIDConsultationOverviewRequest(consultationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public byIDConsultationRequest(consultationId: string, options?: any) {
        return ConsultationApiFp(this.configuration).byIDConsultationRequest(consultationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SaveConsultation} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public createConsultationRequest(body?: SaveConsultation, options?: any) {
        return ConsultationApiFp(this.configuration).createConsultationRequest(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {SaveInquiry} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public createInquiryByConsultationIDRequest(consultationId: string, body?: SaveInquiry, options?: any) {
        return ConsultationApiFp(this.configuration).createInquiryByConsultationIDRequest(consultationId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {SaveRiskAssessment} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public createRiskAssessmentRequest(consultationId: string, body?: SaveRiskAssessment, options?: any) {
        return ConsultationApiFp(this.configuration).createRiskAssessmentRequest(consultationId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public deleteConsultationRequest(consultationId: string, options?: any) {
        return ConsultationApiFp(this.configuration).deleteConsultationRequest(consultationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {SaveInquiryIDs} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public finishOrderByConsultationIDRequest(consultationId: string, body?: SaveInquiryIDs, options?: any) {
        return ConsultationApiFp(this.configuration).finishOrderByConsultationIDRequest(consultationId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public getAnswerSetRequest(consultationId: string, options?: any) {
        return ConsultationApiFp(this.configuration).getAnswerSetRequest(consultationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public getContentAnalysisRequest(consultationId: string, options?: any) {
        return ConsultationApiFp(this.configuration).getContentAnalysisRequest(consultationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public getInquiryByConsultationIDRequest(consultationId: string, options?: any) {
        return ConsultationApiFp(this.configuration).getInquiryByConsultationIDRequest(consultationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public getPaymentsByConsultationIDRequest(consultationId: string, options?: any) {
        return ConsultationApiFp(this.configuration).getPaymentsByConsultationIDRequest(consultationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public getPremiumPredictionsRequest(consultationId: string, options?: any) {
        return ConsultationApiFp(this.configuration).getPremiumPredictionsRequest(consultationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public getRangesRequest(consultationId: string, options?: any) {
        return ConsultationApiFp(this.configuration).getRangesRequest(consultationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public getRiskAnalysisRequest(consultationId: string, options?: any) {
        return ConsultationApiFp(this.configuration).getRiskAnalysisRequest(consultationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {string} riskAssessmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public getRiskAssessmentEmailRequest(consultationId: string, riskAssessmentId: string, options?: any) {
        return ConsultationApiFp(this.configuration).getRiskAssessmentEmailRequest(consultationId, riskAssessmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public getSelectedOffersByConsultationIDRequest(consultationId: string, options?: any) {
        return ConsultationApiFp(this.configuration).getSelectedOffersByConsultationIDRequest(consultationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [hash] 
     * @param {string} [search] 
     * @param {string} [customerId] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {boolean} [noPagination] 
     * @param {boolean} [includeDeleted] 
     * @param {boolean} [includeLocked] 
     * @param {boolean} [includeInactive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public overviewConsultationRequest(hash?: string, search?: string, customerId?: string, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any) {
        return ConsultationApiFp(this.configuration).overviewConsultationRequest(hash, search, customerId, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public productRecommendationByConsultationIDRequest(consultationId: string, options?: any) {
        return ConsultationApiFp(this.configuration).productRecommendationByConsultationIDRequest(consultationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public professionAliasesByConsultationIDRequest(consultationId: string, options?: any) {
        return ConsultationApiFp(this.configuration).professionAliasesByConsultationIDRequest(consultationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public professionsByConsultationIDRequest(consultationId: string, options?: any) {
        return ConsultationApiFp(this.configuration).professionsByConsultationIDRequest(consultationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public questionPagesByConsultationIDRequest(consultationId: string, options?: any) {
        return ConsultationApiFp(this.configuration).questionPagesByConsultationIDRequest(consultationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public questionPagesByConsultationIDRequest_1(consultationId: string, options?: any) {
        return ConsultationApiFp(this.configuration).questionPagesByConsultationIDRequest_1(consultationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [hash] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {boolean} [noPagination] 
     * @param {boolean} [includeDeleted] 
     * @param {boolean} [includeLocked] 
     * @param {boolean} [includeInactive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public searchConsultationRequest(hash?: string, limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any) {
        return ConsultationApiFp(this.configuration).searchConsultationRequest(hash, limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {CustomerMessage} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public sendCustomerMessageRequest(consultationId: string, body?: CustomerMessage, options?: any) {
        return ConsultationApiFp(this.configuration).sendCustomerMessageRequest(consultationId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {SaveInquiryIDs} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public startBookingByConsultationIDRequest(consultationId: string, body?: SaveInquiryIDs, options?: any) {
        return ConsultationApiFp(this.configuration).startBookingByConsultationIDRequest(consultationId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {SaveInquiryIDs} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public startOrderByConsultationIDRequest(consultationId: string, body?: SaveInquiryIDs, options?: any) {
        return ConsultationApiFp(this.configuration).startOrderByConsultationIDRequest(consultationId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {SaveInquiryIDs} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public startProposalByConsultationIDRequest(consultationId: string, body?: SaveInquiryIDs, options?: any) {
        return ConsultationApiFp(this.configuration).startProposalByConsultationIDRequest(consultationId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {SaveAnalysis} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public updateContentAnalysisRequest(consultationId: string, body?: SaveAnalysis, options?: any) {
        return ConsultationApiFp(this.configuration).updateContentAnalysisRequest(consultationId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {SaveCustomer} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public updateCustomerByConsultationIDRequest(consultationId: string, body?: SaveCustomer, options?: any) {
        return ConsultationApiFp(this.configuration).updateCustomerByConsultationIDRequest(consultationId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {Array<SavePayment>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public updatePaymentsByConsultationIDRequest(consultationId: string, body?: Array<SavePayment>, options?: any) {
        return ConsultationApiFp(this.configuration).updatePaymentsByConsultationIDRequest(consultationId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consultationId 
     * @param {SaveAnalysis} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApi
     */
    public updateRiskAnalysisRequest(consultationId: string, body?: SaveAnalysis, options?: any) {
        return ConsultationApiFp(this.configuration).updateRiskAnalysisRequest(consultationId, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        overviewCustomerRequest: async (limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/customers/overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (noPagination !== undefined) {
                localVarQueryParameter['noPagination'] = noPagination;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (includeLocked !== undefined) {
                localVarQueryParameter['includeLocked'] = includeLocked;
            }

            if (includeInactive !== undefined) {
                localVarQueryParameter['includeInactive'] = includeInactive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async overviewCustomerRequest(limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerOverviewList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.overviewCustomerRequest(limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        overviewCustomerRequest(limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): AxiosPromise<CustomerOverviewList> {
            return localVarFp.overviewCustomerRequest(limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {boolean} [noPagination] 
     * @param {boolean} [includeDeleted] 
     * @param {boolean} [includeLocked] 
     * @param {boolean} [includeInactive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public overviewCustomerRequest(limit?: number, offset?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any) {
        return CustomerApiFp(this.configuration).overviewCustomerRequest(limit, offset, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InquiryApi - axios parameter creator
 * @export
 */
export const InquiryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} inquiryId 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComparisonOfferByInquiryIDRequest: async (inquiryId: string, offerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryId' is not null or undefined
            assertParamExists('addComparisonOfferByInquiryIDRequest', 'inquiryId', inquiryId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('addComparisonOfferByInquiryIDRequest', 'offerId', offerId)
            const localVarPath = `/inquiries/{inquiryId}/comparisonOffers/{offerId}`
                .replace(`{${"inquiryId"}}`, encodeURIComponent(String(inquiryId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneAnswerSetByInquiryRequest: async (inquiryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryId' is not null or undefined
            assertParamExists('cloneAnswerSetByInquiryRequest', 'inquiryId', inquiryId)
            const localVarPath = `/inquiries/{inquiryId}/questionAnswerSets`
                .replace(`{${"inquiryId"}}`, encodeURIComponent(String(inquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {string} questionAnswerSetId 
         * @param {CreateNewTenderRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenderRequest: async (inquiryId: string, questionAnswerSetId: string, body?: CreateNewTenderRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryId' is not null or undefined
            assertParamExists('createTenderRequest', 'inquiryId', inquiryId)
            // verify required parameter 'questionAnswerSetId' is not null or undefined
            assertParamExists('createTenderRequest', 'questionAnswerSetId', questionAnswerSetId)
            const localVarPath = `/inquiries/{inquiryId}/questionAnswerSets/{questionAnswerSetId}/tenderRequest`
                .replace(`{${"inquiryId"}}`, encodeURIComponent(String(inquiryId)))
                .replace(`{${"questionAnswerSetId"}}`, encodeURIComponent(String(questionAnswerSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInquiryRequest: async (inquiryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryId' is not null or undefined
            assertParamExists('deleteInquiryRequest', 'inquiryId', inquiryId)
            const localVarPath = `/inquiries/{inquiryId}`
                .replace(`{${"inquiryId"}}`, encodeURIComponent(String(inquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {SaveOfferGroup} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishCalculationsIDByInquiryIDRequest: async (inquiryId: string, body?: SaveOfferGroup, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryId' is not null or undefined
            assertParamExists('finishCalculationsIDByInquiryIDRequest', 'inquiryId', inquiryId)
            const localVarPath = `/inquiries/{inquiryId}/calculations`
                .replace(`{${"inquiryId"}}`, encodeURIComponent(String(inquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {FinishOrder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishOrderByInquiryIDRequest: async (inquiryId: string, body?: FinishOrder, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryId' is not null or undefined
            assertParamExists('finishOrderByInquiryIDRequest', 'inquiryId', inquiryId)
            const localVarPath = `/inquiries/{inquiryId}/orders`
                .replace(`{${"inquiryId"}}`, encodeURIComponent(String(inquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {'calculatorOffer' | 'tenderingOffer'} offerType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgencyNumberPrioritizationRequest: async (inquiryId: string, offerType: 'calculatorOffer' | 'tenderingOffer', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryId' is not null or undefined
            assertParamExists('getAgencyNumberPrioritizationRequest', 'inquiryId', inquiryId)
            // verify required parameter 'offerType' is not null or undefined
            assertParamExists('getAgencyNumberPrioritizationRequest', 'offerType', offerType)
            const localVarPath = `/inquiries/{inquiryId}/agencyNumberPrioritizations`
                .replace(`{${"inquiryId"}}`, encodeURIComponent(String(inquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (offerType !== undefined) {
                localVarQueryParameter['offerType'] = offerType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnswerSetByInquiryRequest: async (inquiryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryId' is not null or undefined
            assertParamExists('getAnswerSetByInquiryRequest', 'inquiryId', inquiryId)
            const localVarPath = `/inquiries/{inquiryId}/questionAnswerSets`
                .replace(`{${"inquiryId"}}`, encodeURIComponent(String(inquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInquiryByIDRequest: async (inquiryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryId' is not null or undefined
            assertParamExists('getInquiryByIDRequest', 'inquiryId', inquiryId)
            const localVarPath = `/inquiries/{inquiryId}`
                .replace(`{${"inquiryId"}}`, encodeURIComponent(String(inquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInquiryDocsByIDRequest: async (inquiryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryId' is not null or undefined
            assertParamExists('getInquiryDocsByIDRequest', 'inquiryId', inquiryId)
            const localVarPath = `/inquiries/{inquiryId}/docs`
                .replace(`{${"inquiryId"}}`, encodeURIComponent(String(inquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferGroupByInquiryIDRequest: async (inquiryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryId' is not null or undefined
            assertParamExists('getOfferGroupByInquiryIDRequest', 'inquiryId', inquiryId)
            const localVarPath = `/inquiries/{inquiryId}/offerGroup`
                .replace(`{${"inquiryId"}}`, encodeURIComponent(String(inquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectedOffersByInquiryIDRequest: async (inquiryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryId' is not null or undefined
            assertParamExists('getSelectedOffersByInquiryIDRequest', 'inquiryId', inquiryId)
            const localVarPath = `/inquiries/{inquiryId}/selectedOffers`
                .replace(`{${"inquiryId"}}`, encodeURIComponent(String(inquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} questionAnswerSetId 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionAnswerByIDAndInquiryIDRequest: async (questionAnswerSetId: string, inquiryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionAnswerSetId' is not null or undefined
            assertParamExists('questionAnswerByIDAndInquiryIDRequest', 'questionAnswerSetId', questionAnswerSetId)
            // verify required parameter 'inquiryId' is not null or undefined
            assertParamExists('questionAnswerByIDAndInquiryIDRequest', 'inquiryId', inquiryId)
            const localVarPath = `/inquiries/{inquiryId}/questionAnswerSets/{questionAnswerSetId}/complete`
                .replace(`{${"questionAnswerSetId"}}`, encodeURIComponent(String(questionAnswerSetId)))
                .replace(`{${"inquiryId"}}`, encodeURIComponent(String(inquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [ids] 
         * @param {Array<string>} [hashes] 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInquiryRequest: async (ids?: Array<string>, hashes?: Array<string>, offset?: number, limit?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/inquiries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids.join(COLLECTION_FORMATS.csv);
            }

            if (hashes) {
                localVarQueryParameter['hashes'] = hashes.join(COLLECTION_FORMATS.csv);
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (noPagination !== undefined) {
                localVarQueryParameter['noPagination'] = noPagination;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (includeLocked !== undefined) {
                localVarQueryParameter['includeLocked'] = includeLocked;
            }

            if (includeInactive !== undefined) {
                localVarQueryParameter['includeInactive'] = includeInactive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {SaveContractID} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setContractIDByInquiryIDRequest: async (inquiryId: string, body?: SaveContractID, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryId' is not null or undefined
            assertParamExists('setContractIDByInquiryIDRequest', 'inquiryId', inquiryId)
            const localVarPath = `/inquiries/{inquiryId}/contracts`
                .replace(`{${"inquiryId"}}`, encodeURIComponent(String(inquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {SaveProposalID} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProposalIDByInquiryIDRequest: async (inquiryId: string, body?: SaveProposalID, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryId' is not null or undefined
            assertParamExists('setProposalIDByInquiryIDRequest', 'inquiryId', inquiryId)
            const localVarPath = `/inquiries/{inquiryId}/proposals`
                .replace(`{${"inquiryId"}}`, encodeURIComponent(String(inquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSelectedOfferByInquiryIDRequest: async (inquiryId: string, offerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryId' is not null or undefined
            assertParamExists('setSelectedOfferByInquiryIDRequest', 'inquiryId', inquiryId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('setSelectedOfferByInquiryIDRequest', 'offerId', offerId)
            const localVarPath = `/inquiries/{inquiryId}/selectedOffers/{offerId}`
                .replace(`{${"inquiryId"}}`, encodeURIComponent(String(inquiryId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the assigned tender owner if exists
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenderOwnerRequest: async (inquiryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryId' is not null or undefined
            assertParamExists('tenderOwnerRequest', 'inquiryId', inquiryId)
            const localVarPath = `/inquiries/{inquiryId}/tenderOwner`
                .replace(`{${"inquiryId"}}`, encodeURIComponent(String(inquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenderRequest: async (inquiryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryId' is not null or undefined
            assertParamExists('tenderRequest', 'inquiryId', inquiryId)
            const localVarPath = `/inquiries/{inquiryId}/tenderRequest`
                .replace(`{${"inquiryId"}}`, encodeURIComponent(String(inquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {string} questionAnswerSetId 
         * @param {UpdateQuestionAnswerStatus} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuestionAnswerStatusRequest: async (inquiryId: string, questionAnswerSetId: string, body?: UpdateQuestionAnswerStatus, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryId' is not null or undefined
            assertParamExists('updateQuestionAnswerStatusRequest', 'inquiryId', inquiryId)
            // verify required parameter 'questionAnswerSetId' is not null or undefined
            assertParamExists('updateQuestionAnswerStatusRequest', 'questionAnswerSetId', questionAnswerSetId)
            const localVarPath = `/inquiries/{inquiryId}/questionAnswerSets/{questionAnswerSetId}/statuses`
                .replace(`{${"inquiryId"}}`, encodeURIComponent(String(inquiryId)))
                .replace(`{${"questionAnswerSetId"}}`, encodeURIComponent(String(questionAnswerSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InquiryApi - functional programming interface
 * @export
 */
export const InquiryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InquiryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} inquiryId 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addComparisonOfferByInquiryIDRequest(inquiryId: string, offerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addComparisonOfferByInquiryIDRequest(inquiryId, offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cloneAnswerSetByInquiryRequest(inquiryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuestionAnswerSet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cloneAnswerSetByInquiryRequest(inquiryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {string} questionAnswerSetId 
         * @param {CreateNewTenderRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTenderRequest(inquiryId: string, questionAnswerSetId: string, body?: CreateNewTenderRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTenderRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTenderRequest(inquiryId, questionAnswerSetId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInquiryRequest(inquiryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInquiryRequest(inquiryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {SaveOfferGroup} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finishCalculationsIDByInquiryIDRequest(inquiryId: string, body?: SaveOfferGroup, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finishCalculationsIDByInquiryIDRequest(inquiryId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {FinishOrder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finishOrderByInquiryIDRequest(inquiryId: string, body?: FinishOrder, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finishOrderByInquiryIDRequest(inquiryId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {'calculatorOffer' | 'tenderingOffer'} offerType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgencyNumberPrioritizationRequest(inquiryId: string, offerType: 'calculatorOffer' | 'tenderingOffer', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgencyNumberPrioritization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgencyNumberPrioritizationRequest(inquiryId, offerType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnswerSetByInquiryRequest(inquiryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuestionAnswerSet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnswerSetByInquiryRequest(inquiryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInquiryByIDRequest(inquiryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Inquiry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInquiryByIDRequest(inquiryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInquiryDocsByIDRequest(inquiryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InquiryDoc>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInquiryDocsByIDRequest(inquiryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfferGroupByInquiryIDRequest(inquiryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfferGroupByInquiryIDRequest(inquiryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelectedOffersByInquiryIDRequest(inquiryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectedOffer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelectedOffersByInquiryIDRequest(inquiryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} questionAnswerSetId 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionAnswerByIDAndInquiryIDRequest(questionAnswerSetId: string, inquiryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferGroupReference>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionAnswerByIDAndInquiryIDRequest(questionAnswerSetId, inquiryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [ids] 
         * @param {Array<string>} [hashes] 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchInquiryRequest(ids?: Array<string>, hashes?: Array<string>, offset?: number, limit?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InquiryList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchInquiryRequest(ids, hashes, offset, limit, noPagination, includeDeleted, includeLocked, includeInactive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {SaveContractID} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setContractIDByInquiryIDRequest(inquiryId: string, body?: SaveContractID, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setContractIDByInquiryIDRequest(inquiryId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {SaveProposalID} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setProposalIDByInquiryIDRequest(inquiryId: string, body?: SaveProposalID, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setProposalIDByInquiryIDRequest(inquiryId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setSelectedOfferByInquiryIDRequest(inquiryId: string, offerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setSelectedOfferByInquiryIDRequest(inquiryId, offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the assigned tender owner if exists
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenderOwnerRequest(inquiryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenderOwner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenderOwnerRequest(inquiryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenderRequest(inquiryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenderRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenderRequest(inquiryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {string} questionAnswerSetId 
         * @param {UpdateQuestionAnswerStatus} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateQuestionAnswerStatusRequest(inquiryId: string, questionAnswerSetId: string, body?: UpdateQuestionAnswerStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateQuestionAnswerStatusRequest(inquiryId, questionAnswerSetId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InquiryApi - factory interface
 * @export
 */
export const InquiryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InquiryApiFp(configuration)
    return {
        /**
         * 
         * @param {string} inquiryId 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComparisonOfferByInquiryIDRequest(inquiryId: string, offerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.addComparisonOfferByInquiryIDRequest(inquiryId, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneAnswerSetByInquiryRequest(inquiryId: string, options?: any): AxiosPromise<Array<QuestionAnswerSet>> {
            return localVarFp.cloneAnswerSetByInquiryRequest(inquiryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {string} questionAnswerSetId 
         * @param {CreateNewTenderRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenderRequest(inquiryId: string, questionAnswerSetId: string, body?: CreateNewTenderRequest, options?: any): AxiosPromise<CreateTenderRequestResponse> {
            return localVarFp.createTenderRequest(inquiryId, questionAnswerSetId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInquiryRequest(inquiryId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteInquiryRequest(inquiryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {SaveOfferGroup} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishCalculationsIDByInquiryIDRequest(inquiryId: string, body?: SaveOfferGroup, options?: any): AxiosPromise<void> {
            return localVarFp.finishCalculationsIDByInquiryIDRequest(inquiryId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {FinishOrder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishOrderByInquiryIDRequest(inquiryId: string, body?: FinishOrder, options?: any): AxiosPromise<void> {
            return localVarFp.finishOrderByInquiryIDRequest(inquiryId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {'calculatorOffer' | 'tenderingOffer'} offerType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgencyNumberPrioritizationRequest(inquiryId: string, offerType: 'calculatorOffer' | 'tenderingOffer', options?: any): AxiosPromise<AgencyNumberPrioritization> {
            return localVarFp.getAgencyNumberPrioritizationRequest(inquiryId, offerType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnswerSetByInquiryRequest(inquiryId: string, options?: any): AxiosPromise<Array<QuestionAnswerSet>> {
            return localVarFp.getAnswerSetByInquiryRequest(inquiryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInquiryByIDRequest(inquiryId: string, options?: any): AxiosPromise<Inquiry> {
            return localVarFp.getInquiryByIDRequest(inquiryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInquiryDocsByIDRequest(inquiryId: string, options?: any): AxiosPromise<Array<InquiryDoc>> {
            return localVarFp.getInquiryDocsByIDRequest(inquiryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferGroupByInquiryIDRequest(inquiryId: string, options?: any): AxiosPromise<OfferGroup> {
            return localVarFp.getOfferGroupByInquiryIDRequest(inquiryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectedOffersByInquiryIDRequest(inquiryId: string, options?: any): AxiosPromise<SelectedOffer> {
            return localVarFp.getSelectedOffersByInquiryIDRequest(inquiryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} questionAnswerSetId 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionAnswerByIDAndInquiryIDRequest(questionAnswerSetId: string, inquiryId: string, options?: any): AxiosPromise<OfferGroupReference> {
            return localVarFp.questionAnswerByIDAndInquiryIDRequest(questionAnswerSetId, inquiryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [ids] 
         * @param {Array<string>} [hashes] 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {boolean} [noPagination] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeLocked] 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInquiryRequest(ids?: Array<string>, hashes?: Array<string>, offset?: number, limit?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any): AxiosPromise<InquiryList> {
            return localVarFp.searchInquiryRequest(ids, hashes, offset, limit, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {SaveContractID} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setContractIDByInquiryIDRequest(inquiryId: string, body?: SaveContractID, options?: any): AxiosPromise<void> {
            return localVarFp.setContractIDByInquiryIDRequest(inquiryId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {SaveProposalID} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProposalIDByInquiryIDRequest(inquiryId: string, body?: SaveProposalID, options?: any): AxiosPromise<void> {
            return localVarFp.setProposalIDByInquiryIDRequest(inquiryId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSelectedOfferByInquiryIDRequest(inquiryId: string, offerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.setSelectedOfferByInquiryIDRequest(inquiryId, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the assigned tender owner if exists
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenderOwnerRequest(inquiryId: string, options?: any): AxiosPromise<TenderOwner> {
            return localVarFp.tenderOwnerRequest(inquiryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenderRequest(inquiryId: string, options?: any): AxiosPromise<TenderRequest> {
            return localVarFp.tenderRequest(inquiryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inquiryId 
         * @param {string} questionAnswerSetId 
         * @param {UpdateQuestionAnswerStatus} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuestionAnswerStatusRequest(inquiryId: string, questionAnswerSetId: string, body?: UpdateQuestionAnswerStatus, options?: any): AxiosPromise<void> {
            return localVarFp.updateQuestionAnswerStatusRequest(inquiryId, questionAnswerSetId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InquiryApi - object-oriented interface
 * @export
 * @class InquiryApi
 * @extends {BaseAPI}
 */
export class InquiryApi extends BaseAPI {
    /**
     * 
     * @param {string} inquiryId 
     * @param {string} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryApi
     */
    public addComparisonOfferByInquiryIDRequest(inquiryId: string, offerId: string, options?: any) {
        return InquiryApiFp(this.configuration).addComparisonOfferByInquiryIDRequest(inquiryId, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inquiryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryApi
     */
    public cloneAnswerSetByInquiryRequest(inquiryId: string, options?: any) {
        return InquiryApiFp(this.configuration).cloneAnswerSetByInquiryRequest(inquiryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inquiryId 
     * @param {string} questionAnswerSetId 
     * @param {CreateNewTenderRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryApi
     */
    public createTenderRequest(inquiryId: string, questionAnswerSetId: string, body?: CreateNewTenderRequest, options?: any) {
        return InquiryApiFp(this.configuration).createTenderRequest(inquiryId, questionAnswerSetId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inquiryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryApi
     */
    public deleteInquiryRequest(inquiryId: string, options?: any) {
        return InquiryApiFp(this.configuration).deleteInquiryRequest(inquiryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inquiryId 
     * @param {SaveOfferGroup} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryApi
     */
    public finishCalculationsIDByInquiryIDRequest(inquiryId: string, body?: SaveOfferGroup, options?: any) {
        return InquiryApiFp(this.configuration).finishCalculationsIDByInquiryIDRequest(inquiryId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inquiryId 
     * @param {FinishOrder} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryApi
     */
    public finishOrderByInquiryIDRequest(inquiryId: string, body?: FinishOrder, options?: any) {
        return InquiryApiFp(this.configuration).finishOrderByInquiryIDRequest(inquiryId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inquiryId 
     * @param {'calculatorOffer' | 'tenderingOffer'} offerType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryApi
     */
    public getAgencyNumberPrioritizationRequest(inquiryId: string, offerType: 'calculatorOffer' | 'tenderingOffer', options?: any) {
        return InquiryApiFp(this.configuration).getAgencyNumberPrioritizationRequest(inquiryId, offerType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inquiryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryApi
     */
    public getAnswerSetByInquiryRequest(inquiryId: string, options?: any) {
        return InquiryApiFp(this.configuration).getAnswerSetByInquiryRequest(inquiryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inquiryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryApi
     */
    public getInquiryByIDRequest(inquiryId: string, options?: any) {
        return InquiryApiFp(this.configuration).getInquiryByIDRequest(inquiryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inquiryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryApi
     */
    public getInquiryDocsByIDRequest(inquiryId: string, options?: any) {
        return InquiryApiFp(this.configuration).getInquiryDocsByIDRequest(inquiryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inquiryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryApi
     */
    public getOfferGroupByInquiryIDRequest(inquiryId: string, options?: any) {
        return InquiryApiFp(this.configuration).getOfferGroupByInquiryIDRequest(inquiryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inquiryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryApi
     */
    public getSelectedOffersByInquiryIDRequest(inquiryId: string, options?: any) {
        return InquiryApiFp(this.configuration).getSelectedOffersByInquiryIDRequest(inquiryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} questionAnswerSetId 
     * @param {string} inquiryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryApi
     */
    public questionAnswerByIDAndInquiryIDRequest(questionAnswerSetId: string, inquiryId: string, options?: any) {
        return InquiryApiFp(this.configuration).questionAnswerByIDAndInquiryIDRequest(questionAnswerSetId, inquiryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [ids] 
     * @param {Array<string>} [hashes] 
     * @param {number} [offset] 
     * @param {number} [limit] 
     * @param {boolean} [noPagination] 
     * @param {boolean} [includeDeleted] 
     * @param {boolean} [includeLocked] 
     * @param {boolean} [includeInactive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryApi
     */
    public searchInquiryRequest(ids?: Array<string>, hashes?: Array<string>, offset?: number, limit?: number, noPagination?: boolean, includeDeleted?: boolean, includeLocked?: boolean, includeInactive?: boolean, options?: any) {
        return InquiryApiFp(this.configuration).searchInquiryRequest(ids, hashes, offset, limit, noPagination, includeDeleted, includeLocked, includeInactive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inquiryId 
     * @param {SaveContractID} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryApi
     */
    public setContractIDByInquiryIDRequest(inquiryId: string, body?: SaveContractID, options?: any) {
        return InquiryApiFp(this.configuration).setContractIDByInquiryIDRequest(inquiryId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inquiryId 
     * @param {SaveProposalID} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryApi
     */
    public setProposalIDByInquiryIDRequest(inquiryId: string, body?: SaveProposalID, options?: any) {
        return InquiryApiFp(this.configuration).setProposalIDByInquiryIDRequest(inquiryId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inquiryId 
     * @param {string} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryApi
     */
    public setSelectedOfferByInquiryIDRequest(inquiryId: string, offerId: string, options?: any) {
        return InquiryApiFp(this.configuration).setSelectedOfferByInquiryIDRequest(inquiryId, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the assigned tender owner if exists
     * @param {string} inquiryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryApi
     */
    public tenderOwnerRequest(inquiryId: string, options?: any) {
        return InquiryApiFp(this.configuration).tenderOwnerRequest(inquiryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inquiryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryApi
     */
    public tenderRequest(inquiryId: string, options?: any) {
        return InquiryApiFp(this.configuration).tenderRequest(inquiryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inquiryId 
     * @param {string} questionAnswerSetId 
     * @param {UpdateQuestionAnswerStatus} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryApi
     */
    public updateQuestionAnswerStatusRequest(inquiryId: string, questionAnswerSetId: string, body?: UpdateQuestionAnswerStatus, options?: any) {
        return InquiryApiFp(this.configuration).updateQuestionAnswerStatusRequest(inquiryId, questionAnswerSetId, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} conversationHash 
         * @param {MessagesToSendData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMessageRequest: async (conversationHash: string, body?: MessagesToSendData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationHash' is not null or undefined
            assertParamExists('addMessageRequest', 'conversationHash', conversationHash)
            const localVarPath = `/notification/conversations/{conversationHash}/messages`
                .replace(`{${"conversationHash"}}`, encodeURIComponent(String(conversationHash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inquiryHash 
         * @param {string} authorHash 
         * @param {string} memberHash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byInquiryHashRequest: async (inquiryHash: string, authorHash: string, memberHash: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryHash' is not null or undefined
            assertParamExists('byInquiryHashRequest', 'inquiryHash', inquiryHash)
            // verify required parameter 'authorHash' is not null or undefined
            assertParamExists('byInquiryHashRequest', 'authorHash', authorHash)
            // verify required parameter 'memberHash' is not null or undefined
            assertParamExists('byInquiryHashRequest', 'memberHash', memberHash)
            const localVarPath = `/notification/inquiry/{inquiryHash}/messages`
                .replace(`{${"inquiryHash"}}`, encodeURIComponent(String(inquiryHash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorHash !== undefined) {
                localVarQueryParameter['author_hash'] = authorHash;
            }

            if (memberHash !== undefined) {
                localVarQueryParameter['member_hash'] = memberHash;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} conversationHash 
         * @param {string} messageHash 
         * @param {ReviewMessage} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewMessageRequest: async (conversationHash: string, messageHash: string, body?: ReviewMessage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationHash' is not null or undefined
            assertParamExists('reviewMessageRequest', 'conversationHash', conversationHash)
            // verify required parameter 'messageHash' is not null or undefined
            assertParamExists('reviewMessageRequest', 'messageHash', messageHash)
            const localVarPath = `/notification/conversations/{conversationHash}/messages/{messageHash}/review`
                .replace(`{${"conversationHash"}}`, encodeURIComponent(String(conversationHash)))
                .replace(`{${"messageHash"}}`, encodeURIComponent(String(messageHash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [date] 
         * @param {string} [userType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unreviewedInquiriesRequest: async (date?: string, userType?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/notification/inquiry/unreviewed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (userType !== undefined) {
                localVarQueryParameter['userType'] = userType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} conversationHash 
         * @param {MessagesToSendData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMessageRequest(conversationHash: string, body?: MessagesToSendData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SentMessagesData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMessageRequest(conversationHash, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} inquiryHash 
         * @param {string} authorHash 
         * @param {string} memberHash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async byInquiryHashRequest(inquiryHash: string, authorHash: string, memberHash: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Message>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.byInquiryHashRequest(inquiryHash, authorHash, memberHash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} conversationHash 
         * @param {string} messageHash 
         * @param {ReviewMessage} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewMessageRequest(conversationHash: string, messageHash: string, body?: ReviewMessage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewMessageRequest(conversationHash, messageHash, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [date] 
         * @param {string} [userType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unreviewedInquiriesRequest(date?: string, userType?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationInquiry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unreviewedInquiriesRequest(date, userType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} conversationHash 
         * @param {MessagesToSendData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMessageRequest(conversationHash: string, body?: MessagesToSendData, options?: any): AxiosPromise<SentMessagesData> {
            return localVarFp.addMessageRequest(conversationHash, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inquiryHash 
         * @param {string} authorHash 
         * @param {string} memberHash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byInquiryHashRequest(inquiryHash: string, authorHash: string, memberHash: string, options?: any): AxiosPromise<Array<Message>> {
            return localVarFp.byInquiryHashRequest(inquiryHash, authorHash, memberHash, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} conversationHash 
         * @param {string} messageHash 
         * @param {ReviewMessage} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewMessageRequest(conversationHash: string, messageHash: string, body?: ReviewMessage, options?: any): AxiosPromise<void> {
            return localVarFp.reviewMessageRequest(conversationHash, messageHash, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [date] 
         * @param {string} [userType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unreviewedInquiriesRequest(date?: string, userType?: string, options?: any): AxiosPromise<NotificationInquiry> {
            return localVarFp.unreviewedInquiriesRequest(date, userType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @param {string} conversationHash 
     * @param {MessagesToSendData} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public addMessageRequest(conversationHash: string, body?: MessagesToSendData, options?: any) {
        return NotificationApiFp(this.configuration).addMessageRequest(conversationHash, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inquiryHash 
     * @param {string} authorHash 
     * @param {string} memberHash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public byInquiryHashRequest(inquiryHash: string, authorHash: string, memberHash: string, options?: any) {
        return NotificationApiFp(this.configuration).byInquiryHashRequest(inquiryHash, authorHash, memberHash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} conversationHash 
     * @param {string} messageHash 
     * @param {ReviewMessage} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public reviewMessageRequest(conversationHash: string, messageHash: string, body?: ReviewMessage, options?: any) {
        return NotificationApiFp(this.configuration).reviewMessageRequest(conversationHash, messageHash, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [date] 
     * @param {string} [userType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public unreviewedInquiriesRequest(date?: string, userType?: string, options?: any) {
        return NotificationApiFp(this.configuration).unreviewedInquiriesRequest(date, userType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RiskAnalysisApi - axios parameter creator
 * @export
 */
export const RiskAnalysisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} consultationId 
         * @param {ProductPDFRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRiskAnalysisDocumentRequest: async (consultationId: string, body?: ProductPDFRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('postRiskAnalysisDocumentRequest', 'consultationId', consultationId)
            const localVarPath = `/consultations/{consultationId}/riskAnalyses/documents/generate`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RiskAnalysisApi - functional programming interface
 * @export
 */
export const RiskAnalysisApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RiskAnalysisApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} consultationId 
         * @param {ProductPDFRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRiskAnalysisDocumentRequest(consultationId: string, body?: ProductPDFRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRiskAnalysisDocumentRequest(consultationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RiskAnalysisApi - factory interface
 * @export
 */
export const RiskAnalysisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RiskAnalysisApiFp(configuration)
    return {
        /**
         * 
         * @param {string} consultationId 
         * @param {ProductPDFRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRiskAnalysisDocumentRequest(consultationId: string, body?: ProductPDFRequest, options?: any): AxiosPromise<any> {
            return localVarFp.postRiskAnalysisDocumentRequest(consultationId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RiskAnalysisApi - object-oriented interface
 * @export
 * @class RiskAnalysisApi
 * @extends {BaseAPI}
 */
export class RiskAnalysisApi extends BaseAPI {
    /**
     * 
     * @param {string} consultationId 
     * @param {ProductPDFRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RiskAnalysisApi
     */
    public postRiskAnalysisDocumentRequest(consultationId: string, body?: ProductPDFRequest, options?: any) {
        return RiskAnalysisApiFp(this.configuration).postRiskAnalysisDocumentRequest(consultationId, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubscriptionApi - axios parameter creator
 * @export
 */
export const SubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} inquiryId 
         * @param {E2EB2CCreateSubscription} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2EB2CSubscriptionRequest: async (inquiryId: string, body?: E2EB2CCreateSubscription, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryId' is not null or undefined
            assertParamExists('e2EB2CSubscriptionRequest', 'inquiryId', inquiryId)
            const localVarPath = `/inquiries/{inquiryId}/subscriptions`
                .replace(`{${"inquiryId"}}`, encodeURIComponent(String(inquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionApi - functional programming interface
 * @export
 */
export const SubscriptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} inquiryId 
         * @param {E2EB2CCreateSubscription} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async e2EB2CSubscriptionRequest(inquiryId: string, body?: E2EB2CCreateSubscription, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.e2EB2CSubscriptionRequest(inquiryId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubscriptionApi - factory interface
 * @export
 */
export const SubscriptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionApiFp(configuration)
    return {
        /**
         * 
         * @param {string} inquiryId 
         * @param {E2EB2CCreateSubscription} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2EB2CSubscriptionRequest(inquiryId: string, body?: E2EB2CCreateSubscription, options?: any): AxiosPromise<Subscription> {
            return localVarFp.e2EB2CSubscriptionRequest(inquiryId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionApi - object-oriented interface
 * @export
 * @class SubscriptionApi
 * @extends {BaseAPI}
 */
export class SubscriptionApi extends BaseAPI {
    /**
     * 
     * @param {string} inquiryId 
     * @param {E2EB2CCreateSubscription} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public e2EB2CSubscriptionRequest(inquiryId: string, body?: E2EB2CCreateSubscription, options?: any) {
        return SubscriptionApiFp(this.configuration).e2EB2CSubscriptionRequest(inquiryId, body, options).then((request) => request(this.axios, this.basePath));
    }
}


