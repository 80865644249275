/* eslint-disable react/display-name */
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TableData, TableDataHeader, TableDataRow, DataTableExpand } from '@developers/graphite';

import { FilterValues } from '@enums';
import { Loader } from '@shared';
import { offerServices } from '@services/offer';
import { FormattedResult, GetQueryOffersParams } from '@typings/resultPage';
import { OfferListConfigurationResponseDto } from '@api/offerOrchestrator';
import { inquiriesService } from '@services/consultation';
import { useMainState } from '@hooks';

import {
  ResultDetails,
  ResultOfferName,
  ResultOfferHighlight,
  ResultOfferPrices,
  ResultTableSkeleton,
} from '..';
import { useSetActiveParams } from '../../hooks';

type Props = {
  changeCheckedItemsFunc: (arg1: string) => void;
  checkedItems: string[];
  inquiryDataId?: number;
  resultTable: FormattedResult;
  resultConfiguration: OfferListConfigurationResponseDto;
  resultFetching: boolean;
};

type OfferDetails = {
  title: string;
  content: string;
};
const ResultTable: React.FC<Props> = ({
  changeCheckedItemsFunc,
  checkedItems,
  resultTable,
  resultConfiguration,
  resultFetching,
}) => {
  const [loadData, setLoadData] = useState(false);
  const { inquiryData } = useMainState();

  const { t } = useTranslation();

  const { data: offerGroupDetails } = inquiriesService.useGetOfferGroup(inquiryData?.id.toString());

  const params: GetQueryOffersParams = {
    offset: 0,
    limit: 10 + resultTable._embedded.offers.length,
    pagination: 1,
    origin: FilterValues.ResultPage,
  };
  const { params: filterParams, paramsCreated: loadOffers } = useSetActiveParams(params, loadData);
  offerServices.useGetOffers(offerGroupDetails?.id as string, filterParams, loadData && loadOffers);

  const fetchOffersFurther = () => {
    setLoadData(true);
    setLoadData(false);
  };

  const prepareHeaders = () => {
    const headerTitles = [
      `${t('result.tableHeader.tariff')}`,
      `${t('result.tableHeader.details')}`,
      `${t('result.tableHeader.highlights')}`,
      `${t('result.tableHeader.annualFee')}`,
    ];
    const headers: TableDataHeader[] = [];
    headerTitles.forEach((element, index) => {
      const item = {
        key: element,
        header: element,
        align: index === headerTitles.length - 1 ? 'end' : 'start',
      };
      headers.push(item as TableDataHeader);
    });

    return headers;
  };

  const OfferDetails: React.FC<OfferDetails> = ({ title, content }) => {
    return (
      <div className="offerDetails mb-5">
        <h6>{title}</h6>
        <span>{content}</span>
      </div>
    );
  };

  const prepareRowData = () => {
    const rows: TableDataRow[] = [];
    resultTable._embedded.offers.forEach((offer) => {
      const row: TableDataRow = {
        id: offer.id,
        columns: [
          {
            id: '0',
            render: () => (
              <ResultOfferName
                id={offer.id}
                insurer={offer.insurer}
                tariff={offer.tariff}
                salesCampaign={offer.salesCampaign}
                changeCheckedItemsFunc={changeCheckedItemsFunc}
                checkedItems={checkedItems}
                offerType={offer.type}
                offerExpiration={offer?.expiration}
              />
            ),
            className: 'offer__td--name pt-6 pb-5',
          },
          {
            id: '1',
            render: () =>
              offer.basicFeatures.map((feature) => (
                <OfferDetails
                  key={feature.id}
                  title={feature.name}
                  content={feature.formattedValue}
                />
              )),
            className: 'pt-6 pb-5',
          },
          {
            id: '2',
            render: (renderExpandToggle) => (
              <>
                <ResultOfferHighlight highlightFeatures={offer.highlightFeatures} />
                {renderExpandToggle(offer.id)}
              </>
            ),
            className: 'offer__td--highlights pt-6 pb-5',
          },
          {
            id: '3',
            render: () => (
              <ResultOfferPrices
                offerId={offer.id}
                price={offer.price}
                filterHash={resultConfiguration.filterHash}
                offerGroupId={offerGroupDetails?.id as string}
                allowBooking={offer.allowBooking}
                offerExpiration={offer?.expiration}
                premium={offer?.premium}
              />
            ),
            className: 'offer__td--prices pt-6 pb-5',
          },
        ],
        expandedRow: {
          render: (renderCollapseToggle) => (
            <>
              <ResultDetails id={offer.id} />
              {renderCollapseToggle(offer.id)}
            </>
          ),
        },
      };
      rows.push(row);
    });

    return rows;
  };

  const propsForInfiniteScroll = {
    next: fetchOffersFurther,
    hasMore: resultTable.total > resultTable._embedded.offers.length,
    dataLength: resultTable._embedded.offers.length,
    style: { overflow: 'hidden' },
  };

  const tableData: TableData = {
    headers: prepareHeaders(),
    rows: prepareRowData(),
  };

  if (resultFetching) {
    return <ResultTableSkeleton />;
  }

  return (
    <div>
      {tableData && (
        <div className="result-table" data-qa="result-table">
          <InfiniteScroll loader={<Loader />} {...propsForInfiniteScroll}>
            <DataTableExpand
              tableData={tableData}
              toggleClassName="offer__toggle-btn d-print-none"
              expandedToggleText={t('general.showDetails')}
              collapsedToggleText={t('general.hideDetails')}
            />
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};

export default ResultTable;
