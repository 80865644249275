import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { API } from '@helpers';
import { LoadingStatus } from '@enums';

export type Loading =
  | LoadingStatus.Idle
  | LoadingStatus.Pending
  | LoadingStatus.Succeeded
  | LoadingStatus.Failed;

export type LoginForm = {
  email: string;
  password: string;
};

export type UserCredentials = {
  // eslint-disable-next-line camelcase
  broker_code: string;
  password: string;
};

export interface UserState {
  readonly loading: Loading;
  readonly token: boolean;
  readonly errors?: unknown;
}

const initialState: UserState = {
  loading: LoadingStatus.Idle,
  token: false,
};

export const getToken = createAsyncThunk(
  'user/getToken',
  async (args: UserCredentials, thunkApi) => {
    try {
      const token = await API.post('/api/authentication_token', args);
      // TODO: Improve the way of protecting internal pages
      localStorage.setItem('user', JSON.stringify(token));

      return token as string;
    } catch (err) {
      const axiosError = err as AxiosError<Error>;
      return thunkApi.rejectWithValue(axiosError.response?.data);
    }
  }
);

const counterSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getToken.fulfilled, (state) => {
      state.loading = LoadingStatus.Succeeded;
      state.token = true;
      state.errors = '';
    });
    builder.addCase(getToken.pending, (state) => {
      state.loading = LoadingStatus.Pending;
      state.errors = '';
    });
    builder.addCase(getToken.rejected, (state, { payload }) => {
      state.loading = LoadingStatus.Failed;
      state.errors = payload;
    });
  },
});

export default counterSlice.reducer;
