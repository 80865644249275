import React, { useState } from 'react';

import {
  ComposedModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from '@developers/graphite/carbon-components';
import { useTranslation } from 'react-i18next';
import { ButtonWithLoading } from '@developers/graphite';

import { TenderRequestFlowType } from '../../helpers/enums';

type Props = {
  open: boolean;
  close: () => void;
  onSubmit: (arg: string) => void;
  isLoadingTenderRequest: boolean;
};

const SubmitTenderModal: React.FC<Props> = ({ open, close, onSubmit, isLoadingTenderRequest }) => {
  const { t } = useTranslation();
  const [flowType, setFlowType] = useState('');

  const onSubmitHandler = (type: string) => {
    setFlowType(type);
    onSubmit(type);
  };
  return (
    <ComposedModal
      open={open}
      preventCloseOnClickOutside={true}
      onClose={close}
      size="sm"
      data-qa="tender-submit-modal"
    >
      <ModalHeader title={t('tender.startTendering')} />
      <ModalBody>
        <span> {t('tender.startTenderingText')}</span>
      </ModalBody>
      <ModalFooter>
        <Button onClick={close} kind="ghost">
          {t('general.cancel')}
        </Button>
        <ButtonWithLoading
          loading={flowType === TenderRequestFlowType.BrokerManaged && isLoadingTenderRequest}
          loadingDescription={t('general.loading')}
          onClick={() => onSubmitHandler(TenderRequestFlowType.BrokerManaged)}
          kind="primary"
          data-qa="tender-submit-modal__button-broker-managed"
        >
          {t('tender.brokerManaged')}
        </ButtonWithLoading>
        <ButtonWithLoading
          loading={flowType === TenderRequestFlowType.BackOffice && isLoadingTenderRequest}
          loadingDescription={t('general.loading')}
          onClick={() => onSubmitHandler(TenderRequestFlowType.BackOffice)}
          kind="primary"
          data-qa="tender-submit-modal__button-backoffice"
        >
          {t('tender.backoffice')}
        </ButtonWithLoading>
      </ModalFooter>
    </ComposedModal>
  );
};

export default SubmitTenderModal;
