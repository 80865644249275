import {
  UseMutationResult,
  useMutation,
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from 'react-query';
import { AxiosError } from 'axios';
import { AnyObject } from 'immer/dist/internal';

import { ApiQuery } from '@enums';
import {
  OfferDiscountApi,
  DiscountDto,
  OfferGroupCreatedResponseDto,
  AppliedDiscountDto,
} from '@api/offerOrchestrator';
import { helpers } from '@helpers';

const offerDiscountInstance = new OfferDiscountApi(
  undefined,
  helpers.isWidget ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest` : ''
);

const useDicountData = (
  offerGroupId: string,
  offerId: string,
  options: UseQueryOptions<AnyObject, AxiosError> = {}
): UseQueryResult<AnyObject, AxiosError> =>
  useQuery(
    [ApiQuery.DiscountData, offerGroupId, offerId],
    () =>
      offerDiscountInstance
        .offerGroupControllerGetOfferDiscount(offerId, offerGroupId)
        .then((res) => res.data),
    { keepPreviousData: false, refetchOnMount: true, retry: false, ...options }
  );

const useAddDicount = (): UseMutationResult<
  OfferGroupCreatedResponseDto,
  AxiosError,
  { id: string; offerId: string; discountDto: DiscountDto }
> =>
  useMutation((data: { id: string; offerId: string; discountDto: DiscountDto }) =>
    offerDiscountInstance
      .offerGroupControllerAddDiscount(data.id, data.offerId, data.discountDto)
      .then((res) => res.data)
  );

const useUpdateDicount = (): UseMutationResult<
  OfferGroupCreatedResponseDto,
  AxiosError,
  { id: string; offerId: string; appliedDiscount: AppliedDiscountDto }
> =>
  useMutation((data: { id: string; offerId: string; appliedDiscount: AppliedDiscountDto }) =>
    offerDiscountInstance
      .offerGroupControllerUpdateAppliedDiscount(data.id, data.offerId, data.appliedDiscount)
      .then((res) => res.data)
  );

const useDeleteDicount = (): UseMutationResult<
  void,
  AxiosError,
  { id: string; offerId: string; type: string }
> =>
  useMutation((data: { id: string; offerId: string; type: string }) =>
    offerDiscountInstance
      .offerGroupControllerDeleteDiscount(data.id, data.offerId, data.type)
      .then((res) => res.data)
  );

const discounts = {
  useUpdateDicount,
  useAddDicount,
  useDeleteDicount,
  useDicountData,
};

export default discounts;
