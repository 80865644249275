import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';

import { AccountSetting } from '@typings/accountSettings';
import { AccountApi, AccountSettingList, PortalSettingList } from '@api/masterData';
import { AccountSettingId, ApiQuery } from '@enums';
import { helpers } from '@helpers';

const accountInstance = new AccountApi(
  undefined,
  helpers.isWidget
    ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/masterdata/v1`
    : '/api/masterdata/v1'
);

const ThueStringValue = '1';

const useAccountSettings = (
  accountId: number,
  settingIds?: AccountSettingId[],
  options: UseQueryOptions<AccountSettingList, AxiosError> = {}
): UseQueryResult<AccountSettingList, AxiosError> =>
  useQuery(
    [ApiQuery.AccountSettings, accountId, ...(settingIds || [])],
    () => accountInstance.accountSettingRequest(accountId, settingIds).then((res) => res.data),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      ...options,
    }
  );

const useAccountSetting = (
  accountId: number,
  settingId: AccountSettingId,
  options: UseQueryOptions<AccountSettingList, AxiosError, AccountSetting> = {}
): UseQueryResult<AccountSetting, AxiosError> =>
  useQuery(
    [ApiQuery.AccountSettings, accountId, settingId],
    () => accountInstance.accountSettingRequest(accountId, [settingId]).then((res) => res.data),
    {
      ...options,
      select: (data) => {
        const setting = data._embedded.accountSettings?.find(
          (accountSetting) => accountSetting.id === settingId
        );

        switch (setting?.type) {
          case 'checkbox': {
            return setting?.stringValue === ThueStringValue;
          }
          case 'currency': {
            return setting?.jsonValue || {};
          }
          case 'date':
          case 'string':
          case 'textarea':
          default: {
            return setting?.stringValue || '';
          }
        }
      },
    }
  );

const useAccountPortalSettings = (
  accountId: number,
  options: UseQueryOptions<PortalSettingList, AxiosError> = {},
  settingIds?: AccountSettingId[]
): UseQueryResult<PortalSettingList, AxiosError> =>
  useQuery(
    [ApiQuery.AccountSettings, accountId, ...(settingIds || [])],
    () => accountInstance.portalSettingRequest(accountId, settingIds).then((res) => res.data),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      ...options,
    }
  );

const Account = {
  useAccountSettings,
  useAccountSetting,
  useAccountPortalSettings,
};

export default Account;
