import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  UseMutationResult,
} from 'react-query';
import { AxiosError } from 'axios';

import { ComparisonResponseDto, ComparisonOfferApi } from '@api/offerOrchestrator';
import { ApiQuery } from '@enums';
import { helpers } from '@helpers';

const comparisonInstance = new ComparisonOfferApi(
  undefined,
  helpers.isWidget ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest` : ''
);

const useComparisonData = (
  offerGroupId: string | undefined,
  options: UseQueryOptions<ComparisonResponseDto, AxiosError> = {}
): UseQueryResult<ComparisonResponseDto, AxiosError> =>
  useQuery(
    [ApiQuery.ComparisonData, offerGroupId],
    () =>
      comparisonInstance
        .offerGroupControllerGetOffersForComparison(offerGroupId)
        .then((res) => res.data),
    { keepPreviousData: false, refetchOnMount: true, retry: true, ...options }
  );

const useRemoveOfferForComparison = (): UseMutationResult<
  void,
  AxiosError,
  {
    id: string;
    offerId: string;
  }
> =>
  useMutation((data: { id: string; offerId: string }) =>
    comparisonInstance
      .offerGroupControllerRemoveOfferFromComparison(data.offerId, data.id)
      .then((res) => res.data)
  );

const useRemoveOffersFromComparison = (): UseMutationResult<
  void,
  AxiosError,
  {
    offerGroupId: string;
  }
> =>
  useMutation((data: { offerGroupId: string }) =>
    comparisonInstance
      .offerGroupControllerRemoveOffersFromComparison(data.offerGroupId)
      .then((res) => res.data)
  );

const comparisonData = {
  useComparisonData,
  useRemoveOfferForComparison,
  useRemoveOffersFromComparison,
};

export default comparisonData;
