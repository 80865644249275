import { ModalProfessionAlias, AliasesByAlphabet, Profession } from '@typings/professionSearch';

import replaceUmlaut from './replaceUmlaut';

const createAlphabetList = (arr: ModalProfessionAlias[]): string[] => {
  const alphabetList = new Set();
  arr?.forEach((i) => {
    alphabetList.add(replaceUmlaut(i.alias.charAt(0).toUpperCase()));
  });
  return Array.from(alphabetList) as string[];
};

const createGroupedByAlphabetList = (
  alphabetList: string[],
  arr: ModalProfessionAlias[]
): AliasesByAlphabet[] => {
  const aliasesByAlphabet: AliasesByAlphabet[] = [];
  alphabetList?.forEach((letter) => {
    const professionsOfOneLetter: ModalProfessionAlias[] = arr.filter(
      (i) => replaceUmlaut(i.alias.charAt(0).toUpperCase()) === letter
    );
    aliasesByAlphabet.push({
      [letter]: professionsOfOneLetter,
    });
  });
  return aliasesByAlphabet;
};

const checkIfProfAlreadyChosen = (
  hash: string,
  id: string,
  selectedArray: Profession[]
): boolean => {
  const selectedArrayWithoutCurrentId = selectedArray.filter((i) => i.id !== id);
  const isAlreadyChosen = selectedArrayWithoutCurrentId.find((i) => i.hash === hash);
  return !!isAlreadyChosen;
};

const professionSearchHelper = {
  createAlphabetList,
  createGroupedByAlphabetList,
  checkIfProfAlreadyChosen,
};

export default professionSearchHelper;
