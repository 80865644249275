/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  useQuery,
  UseQueryResult,
  UseQueryOptions,
  useMutation,
  UseMutationResult,
} from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

import {
  CreateNewTenderRequest,
  Inquiry,
  InquiryApi,
  InquiryList,
  QuestionAnswerSet,
  TenderRequest,
  OfferGroupReference,
  CreateTenderRequestResponse,
  OfferGroup,
} from '@api/consultation';
import { ApiQuery } from '@enums';
import { helpers } from '@helpers';

interface SumbitQuestionAnswerSetData {
  setId: string;
  inquiryId: string;
}

const inquiriesInstance = new InquiryApi(
  undefined,
  helpers.isWidget
    ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/consultation/v1`
    : '/api/consultation/v1'
);

const useGetInquiry = (
  inquiryId = '',
  options: UseQueryOptions<Inquiry, AxiosError> = {}
): UseQueryResult<Inquiry, AxiosError> =>
  useQuery(
    [ApiQuery.Inquiry, inquiryId],
    () =>
      inquiriesInstance.getInquiryByIDRequest(inquiryId).then((resp) => {
        return resp.data;
      }),
    { enabled: !!inquiryId, refetchOnMount: false, ...options }
  );

const useQuestionAnswerSet = (
  inquiryHash: string,
  options: UseQueryOptions<QuestionAnswerSet[], AxiosError> = {}
): UseQueryResult<QuestionAnswerSet[], AxiosError> =>
  useQuery(
    [ApiQuery.QuestionAnswerSets, inquiryHash],
    () =>
      inquiriesInstance
        .searchInquiryRequest(undefined, [inquiryHash])
        .then((res) =>
          inquiriesInstance
            .cloneAnswerSetByInquiryRequest(res.data._embedded.inquiries[0].id)
            .then((resp) => resp.data)
        ),
    { enabled: !!inquiryHash, ...options }
  );

const useGetInquiries = (
  inquiryHash = '',
  options: UseQueryOptions<InquiryList, AxiosError> = {}
): UseQueryResult<InquiryList, AxiosError> =>
  useQuery(
    [ApiQuery.Inquiries, inquiryHash],
    () => inquiriesInstance.searchInquiryRequest(undefined, [inquiryHash]).then((res) => res.data),
    { enabled: !!inquiryHash, refetchOnMount: false }
  );

const useSubmitQuestionAnswerSet = (): UseMutationResult<
  AxiosResponse<OfferGroupReference>,
  AxiosError,
  { data: SumbitQuestionAnswerSetData; requestOptions?: any },
  string
> =>
  useMutation(
    ({ data, requestOptions }) => {
      return inquiriesInstance.questionAnswerByIDAndInquiryIDRequest(
        data.setId,
        data.inquiryId,
        requestOptions
      );
    },
    { mutationKey: ApiQuery.SubmitQuestionAnswerSet }
  );

const useGetTenderRequest = (
  inquiryId = '',
  options: UseQueryOptions<TenderRequest, AxiosError> = {}
): UseQueryResult<TenderRequest, AxiosError> =>
  useQuery(
    [ApiQuery.TenderRequest, inquiryId],
    () => inquiriesInstance.tenderRequest(inquiryId).then((res) => res.data),
    { enabled: !!inquiryId, ...options }
  );

const useCreateTenderRequest = (
  inquiryId = '',
  questionAnswerSetId = ''
): UseMutationResult<
  void | CreateTenderRequestResponse,
  AxiosError,
  { inquiryId: string; questionAnswerSetId: string; body: CreateNewTenderRequest }
> => {
  return useMutation(({ body }) =>
    inquiriesInstance
      .createTenderRequest(inquiryId, questionAnswerSetId, body)
      .then((res) => res.data)
  );
};

const useAddOfferForComparison = (): UseMutationResult<
  void,
  AxiosError,
  { offerId: string; inquiryId: string }
> =>
  useMutation((data: { offerId: string; inquiryId: string }) =>
    inquiriesInstance
      .addComparisonOfferByInquiryIDRequest(data.inquiryId, data.offerId)
      .then((res) => res.data)
  );

const useGetOfferGroup = (inquiryId = ''): UseQueryResult<OfferGroup, AxiosError> =>
  useQuery(
    [ApiQuery.ConsultationInquiriesOfferGroup, inquiryId],
    () => inquiriesInstance.getOfferGroupByInquiryIDRequest(inquiryId).then((res) => res.data),
    { keepPreviousData: true, enabled: !!inquiryId, refetchOnMount: false }
  );

const inquiries = {
  useGetInquiry,
  useQuestionAnswerSet,
  useSubmitQuestionAnswerSet,
  useGetTenderRequest,
  useGetInquiries,
  useCreateTenderRequest,
  useGetOfferGroup,
  useAddOfferForComparison,
};

export default inquiries;
