import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { API, helpers } from '@helpers';
import { ContractType, LoadingStatus } from '@enums';
import { FeatureFields, Calculation, ParamsTariff } from '@store/comparisonSlice';

export type Loading =
  | LoadingStatus.Idle
  | LoadingStatus.Pending
  | LoadingStatus.Succeeded
  | LoadingStatus.Failed;

export interface PreviousContractState {
  readonly insurersLoading: Loading;
  readonly previousContractErrors?: unknown;
  readonly insurersData?: InsurerDataItem[];
  readonly tariffsLoading: Loading;
  readonly tariffsData?: TariffDataItem[];
  readonly tariffSelected?: TariffDataItem | null;
  readonly insurerSelected?: InsurerDataItem | null;
  readonly featuresLoading: Loading;
  readonly featuresData?: FeatureFields[];
  readonly previousContractLoading: Loading;
  readonly getPreviousContractLoading: Loading;
  readonly previousKnownContractData?: Calculation;
  readonly previousUnknownContractData?: Calculation;
}

export interface Params {
  productId: number;
  insurerId: number;
}

interface DetailsCommon {
  premium: {
    finalGross: string;
  };
}

interface DetailsMiniForm extends DetailsCommon {
  tariff: string;
}

export interface DetailsModal extends DetailsCommon {
  tariffVersion: string;
  features: { value: string; id: number; iconType?: string }[];
}

export interface ParamsSave {
  type: number;
  inquiry: string;
  insurer: string;
  details: DetailsModal | DetailsMiniForm;
}

export interface ParamsSaveFromGet {
  type: number;
  inquiry: string;
  insurer: {
    id: number;
    name: string;
  };
  features: FullFeatureField[];
}

export interface ParamsPut {
  comparisonResult: ParamsSave;
  hash: string;
}

export interface FullFeatureField {
  id: number;
  value: string;
  formattedValue: string;
  iconType: null | string;
}

export interface InsurerDataItem {
  id: number | string;
  name: string;
}

export interface TariffDataItem {
  id: number | string;
  name: string;
  tag: string;
}

export const getInsurers = createAsyncThunk(
  'previousContract/getInsurers',
  async (args, thunkApi) => {
    try {
      return await API.get<InsurerDataItem[]>(
        helpers.isWidget
          ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1/insurers.json?groups[]=name&pagination=false`
          : `/api/e2e/v1/insurers.json?groups[]=name&pagination=false`
      );
    } catch (err) {
      const axiosError = err as AxiosError<Error>;
      return thunkApi.rejectWithValue(axiosError.response?.data);
    }
  }
);

export const getTariffs = createAsyncThunk(
  'previousContract/getTariffs',
  async (args: Params, thunkApi) => {
    try {
      return await API.get<TariffDataItem[]>(
        helpers.isWidget
          ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1/tariffs.json?calculator.product=${args.productId}&calculator.insurer=${args.insurerId}&pagination=false`
          : `/api/e2e/v1/tariffs.json?calculator.product=${args.productId}&calculator.insurer=${args.insurerId}&pagination=false`
      );
    } catch (err) {
      const axiosError = err as AxiosError<Error>;
      return thunkApi.rejectWithValue(axiosError.response?.data);
    }
  }
);

export const getPreviousContract = createAsyncThunk(
  'previousContract/getPreviousContract',
  async (args: string, thunkApi) => {
    try {
      return await API.get<Calculation>(
        helpers.isWidget
          ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1/contracts/${args}`
          : `/api/e2e/v1/contracts/${args}`
      );
    } catch (err) {
      const axiosError = err as AxiosError<Error>;
      return thunkApi.rejectWithValue(axiosError.response?.data);
    }
  }
);

export const postPreviousContract = createAsyncThunk(
  'previousContract/postPreviousContract',
  async (args: ParamsSave, thunkApi) => {
    try {
      return await API.post(
        helpers.isWidget
          ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1/contracts`
          : `/api/e2e/v1/contracts`,
        args,
        null,
        true,
        {
          'Content-Type': 'application/ld+json',
        }
      );
    } catch (err) {
      const axiosError = err as AxiosError<Error>;
      return thunkApi.rejectWithValue(axiosError.response?.data);
    }
  }
);

export const putPreviousContract = createAsyncThunk(
  'previousContract/putPreviousContract',
  async (params: ParamsPut, thunkApi) => {
    try {
      return await API.put(
        helpers.isWidget
          ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1/contracts/${params.hash}`
          : `/api/e2e/v1/contracts/${params.hash}`,
        params.comparisonResult,
        null,
        true,
        {
          'Content-Type': 'application/ld+json',
        }
      );
    } catch (err) {
      const axiosError = err as AxiosError<Error>;
      return thunkApi.rejectWithValue(axiosError.response?.data);
    }
  }
);

export const getFeaturesByTariff = createAsyncThunk(
  'previousContract/getFeaturesByTariff',
  async (args: ParamsTariff, thunkApi) => {
    try {
      return await API.get<FeatureFields[]>(
        helpers.isWidget
          ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1/tariffs/${args.tariffId}/features.json?profession=${args.professionId}&pagination=false`
          : `/api/e2e/v1/tariffs/${args.tariffId}/features.json?profession=${args.professionId}&pagination=false`
      );
    } catch (err) {
      const axiosError = err as AxiosError<Error>;
      return thunkApi.rejectWithValue(axiosError.response?.data);
    }
  }
);

const initialState: PreviousContractState = {
  insurersLoading: LoadingStatus.Idle,
  tariffsLoading: LoadingStatus.Idle,
  featuresLoading: LoadingStatus.Idle,
  previousContractLoading: LoadingStatus.Idle,
  getPreviousContractLoading: LoadingStatus.Idle,
};

const previousContractSlice = createSlice({
  name: 'previousContract',
  initialState,
  reducers: {
    removePreviousContract(state) {
      state.featuresData = undefined;
    },
    removeTariffs(state) {
      state.tariffsData = undefined;
    },
    selectInsurer(state, { payload }) {
      state.insurerSelected = payload;
    },
    removeInsurer(state) {
      state.insurerSelected = null;
      state.tariffSelected = null;
    },
    selectTariff(state, { payload }) {
      state.tariffSelected = payload;
    },
    removeTariff(state) {
      state.tariffSelected = null;
    },
    removePreviousContractPrefilledData(state) {
      state.previousKnownContractData = undefined;
      state.previousUnknownContractData = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInsurers.fulfilled, (state, { payload }) => {
      state.insurersLoading = LoadingStatus.Succeeded;
      state.previousContractErrors = '';
      state.insurersData = payload;
    });
    builder.addCase(getInsurers.pending, (state) => {
      state.insurersLoading = LoadingStatus.Pending;
      state.previousContractErrors = '';
    });
    builder.addCase(getInsurers.rejected, (state, { payload }) => {
      state.insurersLoading = LoadingStatus.Failed;
      state.previousContractErrors = payload;
    });
    builder.addCase(getTariffs.fulfilled, (state, { payload }) => {
      state.tariffsLoading = LoadingStatus.Succeeded;
      state.previousContractErrors = '';
      state.tariffsData = payload;
    });
    builder.addCase(getTariffs.pending, (state) => {
      state.tariffsLoading = LoadingStatus.Pending;
      state.previousContractErrors = '';
    });
    builder.addCase(getTariffs.rejected, (state, { payload }) => {
      state.tariffsLoading = LoadingStatus.Failed;
      state.previousContractErrors = payload;
    });
    builder.addCase(getFeaturesByTariff.fulfilled, (state, { payload }) => {
      state.featuresLoading = LoadingStatus.Succeeded;
      state.previousContractErrors = '';
      state.featuresData = payload;
    });
    builder.addCase(getFeaturesByTariff.pending, (state) => {
      state.featuresLoading = LoadingStatus.Pending;
      state.previousContractErrors = '';
    });
    builder.addCase(getFeaturesByTariff.rejected, (state, { payload }) => {
      state.featuresLoading = LoadingStatus.Failed;
      state.previousContractErrors = payload;
    });
    builder.addCase(getPreviousContract.fulfilled, (state, { payload }) => {
      state.getPreviousContractLoading = LoadingStatus.Succeeded;
      state.previousContractErrors = '';
      if (payload.type === ContractType.KnownPrevious) {
        state.previousKnownContractData = payload;
      } else {
        state.previousUnknownContractData = payload;
      }
    });
    builder.addCase(getPreviousContract.pending, (state) => {
      state.getPreviousContractLoading = LoadingStatus.Pending;
      state.previousContractErrors = '';
    });
    builder.addCase(getPreviousContract.rejected, (state, { payload }) => {
      state.getPreviousContractLoading = LoadingStatus.Failed;
      state.previousContractErrors = payload;
    });
    builder.addCase(postPreviousContract.fulfilled, (state) => {
      state.previousContractLoading = LoadingStatus.Succeeded;
      state.previousContractErrors = '';
    });
    builder.addCase(postPreviousContract.pending, (state) => {
      state.previousContractLoading = LoadingStatus.Pending;
      state.previousContractErrors = '';
    });
    builder.addCase(postPreviousContract.rejected, (state, { payload }) => {
      state.previousContractLoading = LoadingStatus.Failed;
      state.previousContractErrors = payload;
    });
    builder.addCase(putPreviousContract.fulfilled, (state) => {
      state.previousContractLoading = LoadingStatus.Succeeded;
      state.previousContractErrors = '';
    });
    builder.addCase(putPreviousContract.pending, (state) => {
      state.previousContractLoading = LoadingStatus.Pending;
      state.previousContractErrors = '';
    });
    builder.addCase(putPreviousContract.rejected, (state, { payload }) => {
      state.previousContractLoading = LoadingStatus.Failed;
      state.previousContractErrors = payload;
    });
  },
});

export const {
  removePreviousContract,
  removeTariffs,
  selectInsurer,
  removeInsurer,
  selectTariff,
  removeTariff,
  removePreviousContractPrefilledData,
} = previousContractSlice.actions;

export default previousContractSlice.reducer;
