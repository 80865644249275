import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { API, helpers } from '@helpers';
import { ApiQuery } from '@enums';

export interface BrokerSettings {
  id: number;
  brokerCode: string;
  fullName: string;
  pool: {
    id: number;
    name: string;
    tag: string;
    company: string | null;
  };
}

const useBrokerSettings = (
  options: UseQueryOptions<BrokerSettings, Error> = {}
): QueryObserverResult<BrokerSettings, Error> => {
  return useQuery<BrokerSettings, Error>(
    [ApiQuery.BrokerSettings],
    async () => {
      const response = await API.get<BrokerSettings>(
        helpers.isWidget
          ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1/brokers/me`
          : '/api/e2e/v1/brokers/me'
      );

      return response;
    },
    options
  );
};

export default useBrokerSettings;
