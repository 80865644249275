import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { HttpStatusCode } from '@developers/toolbox';

import { API, helpers } from '@helpers';
import { LoadingStatus } from '@enums';
import { UrlParams } from '@typings/global';

export type Loading =
  | LoadingStatus.Idle
  | LoadingStatus.Pending
  | LoadingStatus.Succeeded
  | LoadingStatus.Failed;

export interface ParamsTariff {
  tariffId: number;
  professionId: number;
}

export interface ComparisonResultState {
  readonly loading: Loading;
  readonly loadingSubscription: Loading;
  readonly loadingDeleteContract: Loading;
  readonly isInquiryExpired: boolean;
  readonly data?: ComparisonResult;
  readonly firstDataElement?: Calculation;
  readonly subscriptionData?: SubscriptionData;
  readonly errors?: unknown;
  readonly modalValidationErrors: ValidationError[];
  readonly isMiniForm: boolean;
  readonly isHasDifference: boolean;
}
export interface ValidationError {
  propertyPath: string;
  message: string;
}

export interface SubscriptionData {
  subscribed: boolean;
  email: string;
  phone: string;
  inquiryHash: string;
  calculationHashes: string[];
}

export interface ComparisonResult {
  navigation: Navigation;
  meta: Meta;
  featuresStructure: {
    categories: Category[];
    footnotes?: Footnote[];
  };
  inquiryHash: string;
  elements: Calculation[];
  extraInfo: {
    isProductHaft: boolean;
  };
}

interface Footnote {
  index: number;
  message: string;
}

interface Navigation {
  // eslint-disable-next-line camelcase
  back_url: string;
}

interface Meta {
  backUrl: string;
  hintMessage: string;
  footnotes: Footnotes;
}

interface Footnotes {
  [prop: string]: string;
}

export interface Category {
  id: number;
  name: string;
  featureElements: Field[];
}

export interface Field {
  id: number;
  name: string;
  info?: string;
  example?: string;
  hasDifference: boolean;
  footnoteIndex?: number;
  hasIcon: boolean;
  type: string;
  required: boolean;
}

interface InsurerObject {
  id: number;
  name: string;
  nameTag: string;
  hash: string;
  image: string;
}

export interface Details {
  premium: {
    finalGross: number;
    formattedFinalGross: string;
    discount: null | number;
    gross: number;
    formattedGross: number;
    showDiscount: boolean;
  };
  tariff?: {
    id: number;
    name: string;
  };
  paymentInterval?: {
    value: number;
    formattedValue: string;
  };
  tariffVersion: string;
  features: FeatureFields[];
}

export interface Calculation {
  type: number;
  id: string;
  inquiry: string;
  insurer: InsurerObject;
  details: Details;
  csp: number;
  calculatorId: number;
}

export interface FeatureFields {
  value: string | number | null;
  formattedValue?: string | null;
  id: number;
  iconType?: string | null;
  name: string;
}

interface ErrorsPayload {
  violations?: ValidationError[];
}

export const getComparisonResult = createAsyncThunk(
  'comparison/getComparisonResult',
  async (args: UrlParams, thunkApi) => {
    try {
      return await API.get<ComparisonResult>(
        helpers.isWidget
          ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1/comparison/${args.inquiryHash}?c=${args.datasetCalculationResult}`
          : `/api/e2e/v1/comparison/${args.inquiryHash}?c=${args.datasetCalculationResult}`
      );
      // return await API.get<ComparisonResult>(
      // 	`/public/json/calculationsNew.json`
      // );
    } catch (err) {
      const axiosError = err as AxiosError<Error>;
      if (axiosError.response?.status === HttpStatusCode.Gone) {
        return thunkApi.rejectWithValue(axiosError.response?.data);
      }
      throw err;
    }
  }
);

export const getSubscriptionInfo = createAsyncThunk(
  'comparison/getSubscriptionInfo',
  async (args: UrlParams, thunkApi) => {
    try {
      return await API.get<SubscriptionData>(
        helpers.isWidget
          ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1/comparison/${args.inquiryHash}/subscription?calculation_hashes=${args.datasetCalculationResult}`
          : `/api/e2e/v1/comparison/${args.inquiryHash}/subscription?calculation_hashes=${args.datasetCalculationResult}`
      );
    } catch (err) {
      const axiosError = err as AxiosError<Error>;
      if (axiosError.response?.status !== HttpStatusCode.BadRequest) {
        return thunkApi.rejectWithValue(axiosError.response?.data);
      }
      throw err;
    }
  }
);

export const postSubscriptionInfo = createAsyncThunk<
  SubscriptionData,
  SubscriptionData,
  {
    rejectValue: ErrorsPayload;
  }
>('comparison/postSubscriptionInfo', async (args: SubscriptionData, thunkApi) => {
  try {
    return await API.post(
      helpers.isWidget
        ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1/comparison/subscription`
        : `/api/e2e/v1/comparison/subscription`,
      args
    );
  } catch (err) {
    // TODO: check rejectValue
    const axiosError = err as AxiosError<Error>;
    return thunkApi.rejectWithValue(axiosError.response?.data as ErrorsPayload);
  }
});

export const deleteOldContract = createAsyncThunk(
  'comparison/deleteOldContract',
  async (args: string, thunkApi) => {
    try {
      return await API.remove(`/api/e2e/v1/contracts/${args}`);
    } catch (err) {
      const axiosError = err as AxiosError<Error>;
      return thunkApi.rejectWithValue(axiosError.response?.data);
    }
  }
);

const initialState: ComparisonResultState = {
  loading: LoadingStatus.Idle,
  loadingDeleteContract: LoadingStatus.Idle,
  loadingSubscription: LoadingStatus.Idle,
  modalValidationErrors: [],
  isMiniForm: false,
  isHasDifference: false,
  isInquiryExpired: false,
};

const comparisonSlice = createSlice({
  name: 'comparison',
  initialState,
  reducers: {
    changeMiniFormStatus(state, { payload }) {
      state.isMiniForm = payload;
    },
    toggleHasDifference(state, { payload }) {
      state.isHasDifference = payload;
    },
    removeOldContractFromData(state) {
      state.data?.elements.shift();
      state.firstDataElement = state.data?.elements[0];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getComparisonResult.fulfilled, (state, { payload }) => {
      state.loading = LoadingStatus.Succeeded;
      state.errors = '';
      state.data = payload;
      // eslint-disable-next-line prefer-destructuring
      state.firstDataElement = payload.elements[0];
    });
    builder.addCase(getComparisonResult.pending, (state) => {
      state.loading = LoadingStatus.Pending;
      state.errors = '';
    });
    builder.addCase(getComparisonResult.rejected, (state, { payload, error }) => {
      if (payload) {
        state.loading = LoadingStatus.Idle;
        state.isInquiryExpired = true;
      } else {
        state.loading = LoadingStatus.Failed;
        state.errors = error.message;
      }
    });
    builder.addCase(getSubscriptionInfo.fulfilled, (state, { payload }) => {
      state.loadingSubscription = LoadingStatus.Succeeded;
      state.errors = '';
      state.subscriptionData = payload;
    });
    builder.addCase(getSubscriptionInfo.pending, (state) => {
      state.loadingSubscription = LoadingStatus.Pending;
      state.errors = '';
    });
    builder.addCase(getSubscriptionInfo.rejected, (state, { payload, error }) => {
      state.loadingSubscription = LoadingStatus.Idle;
      if (payload) {
        state.loadingSubscription = LoadingStatus.Failed;
        state.errors = error.message;
      }
    });
    builder.addCase(postSubscriptionInfo.fulfilled, (state, { payload }) => {
      state.loadingSubscription = LoadingStatus.Succeeded;
      state.errors = '';
      state.subscriptionData = payload;
    });
    builder.addCase(postSubscriptionInfo.pending, (state) => {
      state.loadingSubscription = LoadingStatus.Pending;
      state.errors = '';
    });
    builder.addCase(postSubscriptionInfo.rejected, (state, { payload, error }) => {
      state.loadingSubscription = LoadingStatus.Failed;
      if (payload?.violations) {
        state.modalValidationErrors = payload.violations;
      } else {
        state.errors = error.message;
      }
    });
    builder.addCase(deleteOldContract.fulfilled, (state) => {
      state.loadingDeleteContract = LoadingStatus.Succeeded;
      state.errors = '';
      state.data?.elements.shift();
      state.firstDataElement = state.data?.elements[0];
    });
    builder.addCase(deleteOldContract.pending, (state) => {
      state.loadingDeleteContract = LoadingStatus.Pending;
      state.errors = '';
    });
    builder.addCase(deleteOldContract.rejected, (state, { payload }) => {
      state.loadingDeleteContract = LoadingStatus.Failed;
      state.errors = payload;
    });
  },
});

export const { changeMiniFormStatus, removeOldContractFromData, toggleHasDifference } =
  comparisonSlice.actions;

export default comparisonSlice.reducer;
