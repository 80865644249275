/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { HttpStatusCode, Mode } from '@developers/toolbox';
import axios from 'axios';
import axiosQueries from '@developers/queries/axiosInstance';

import authHeader from './authHeader';
import { envVariables } from './helpers';

// INFO: Added default header for IE11 - no cache
axios.defaults.headers.Pragma = 'no-cache';

type ResponseError = { response: { status: HttpStatusCode } };

const redirectIfForbidden = (error: ResponseError) => {
  if (error.response.status === HttpStatusCode.Forbidden) {
    window.location.href = `${envVariables.REACT_APP_BASE_URL}${window.location.pathname}`;
  }

  return Promise.reject(error);
};

if (!envVariables.REACT_APP_IS_WIDGET) {
  axios.interceptors.response.use((response) => response, redirectIfForbidden);

  // INFO: currently queries library uses newer axios version than e2e
  // which means they don't share the same axios instance
  if (axios !== axiosQueries) {
    axiosQueries.interceptors.response.use((response) => response, redirectIfForbidden);
  }
}

/**
 *
 * Get method
 * @param url
 * @param params
 */
const get = async <T>(
  url: string,
  params?: Record<string, any>,
  auth?: boolean,
  headers?: Record<string, any>
): Promise<T> => {
  try {
    const combineHeaders = auth ? { ...headers, ...authHeader() } : { ...headers };
    const res = await axios({
      method: 'get',
      url,
      params,
      headers: combineHeaders,
    });

    return res.data as T;
  } catch (err) {
    if (envVariables.mode === Mode.Development) console.log(err);
    throw err;
  }
};

/**
 *
 * Post method
 * @param url
 * @param data
 * @param params
 */
const post = async <T1, T2>(
  url: string,
  data?: T1,
  params?: string | null,
  auth?: boolean,
  headers?: Record<string, any>
): Promise<T2> => {
  try {
    // TMP make this in helpers
    const combineHeaders = auth ? { ...headers, ...authHeader() } : { ...headers };
    const res = await axios({
      method: 'post',
      url,
      data,
      params,
      headers: combineHeaders,
    });
    return res.data as T2;
  } catch (err) {
    if (envVariables.mode === Mode.Development) console.log(err);
    throw err;
  }
};

/**
 *
 * Remove method
 * @param url
 * @param params
 */
const remove = async (
  url: string,
  params?: string,
  auth?: boolean,
  headers?: Record<string, any>
): Promise<boolean> => {
  try {
    // TMP make this in helpers
    const combineHeaders = auth ? { ...headers, ...authHeader() } : { ...headers };
    const res = await axios({
      method: 'delete',
      url,
      params,
      headers: combineHeaders,
    });

    return res.data as boolean;
  } catch (err) {
    if (envVariables.mode === Mode.Development) console.log(err);
    throw err;
  }
};

/**
 *
 * Put method
 * @param url
 * @param data
 * @param params
 */
const put = async <T1, T2>(
  url: string,
  data?: T1,
  params?: string | null,
  auth?: boolean,
  headers?: Record<string, any>
): Promise<T2> => {
  try {
    // TMP make this in helpers
    const combineHeaders = auth ? { ...headers, ...authHeader() } : { ...headers };
    const res = await axios({
      method: 'put',
      url,
      data,
      params,
      headers: combineHeaders,
    });
    return res.data as T2;
  } catch (err) {
    if (envVariables.mode === Mode.Development) console.log(err);
    throw err;
  }
};

/**
 *
 * Patch method
 * @param url
 * @param data
 * @param params
 */
const patch = async <T1, T2>(
  url: string,
  data?: T1,
  params?: string | null,
  auth?: boolean,
  headers?: Record<string, any>
): Promise<T2> => {
  try {
    // TMP make this in helpers
    const combineHeaders = auth ? { ...headers, ...authHeader() } : { ...headers };
    const res = await axios({
      method: 'patch',
      url,
      data,
      params,
      headers: combineHeaders,
    });
    return res.data as T2;
  } catch (err) {
    if (envVariables.mode === Mode.Development) console.log(err);
    throw err;
  }
};
const helpers = {
  get,
  post,
  remove,
  put,
  patch,
};

export default helpers;
