import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
  UseQueryOptions,
} from 'react-query';
import { AxiosError } from 'axios';

import { DocumentsApi, Document, DocumentList } from '@api/fileService';
import { ApiQuery } from '@enums';
import { helpers } from '@helpers';

const documentsInstance = new DocumentsApi(
  undefined,
  helpers.isWidget ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest` : ''
);

const useUploadDocument = (): UseMutationResult<
  Document,
  AxiosError,
  { docType: string; docPath: string; file: File }
> =>
  useMutation(async (data: { docType: string; docPath: string; file: File }) => {
    const fileNameArr = data.file.name.split('.');
    fileNameArr.splice(fileNameArr.length - 1);
    const res = await documentsInstance.apiFileV1DocumentsPost(
      data.docType,
      fileNameArr.join('.'),
      data.docPath,
      data.file
    );
    return res.data;
  });

const useDeleteDocument = (): UseMutationResult<Document, AxiosError, string> =>
  useMutation(async (id: string) => {
    const res = await documentsInstance.apiFileV1DocumentsIdDelete(id);
    return res.data;
  });

const useGetDocuments = (
  ids?: string[],
  options: UseQueryOptions<DocumentList, AxiosError> = {}
): UseQueryResult<DocumentList, AxiosError> =>
  useQuery(
    [ApiQuery.FileServiceApiFileV1Documents, ids],
    () => documentsInstance.apiFileV1DocumentsGet(ids).then((res) => res.data),
    { keepPreviousData: true, refetchOnMount: false, retry: false, ...options }
  );

const useGetOneDocument = (
  documentId: string,
  options: UseQueryOptions<Document, AxiosError> = {},
  documentType?: string
): UseQueryResult<Document, AxiosError> =>
  useQuery(
    [ApiQuery.FileServiceApiFileV1Documents, documentId, documentType],
    () => documentsInstance.apiFileV1DocumentsIdGet(documentId).then((res) => res.data),
    { keepPreviousData: true, refetchOnMount: false, retry: false, ...options }
  );

const documents = {
  useUploadDocument,
  useDeleteDocument,
  useGetDocuments,
  useGetOneDocument,
};

export default documents;
