import React from 'react';

import {
  ComposedModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  RadioButton,
  RadioButtonGroup,
  TextInput,
} from '@developers/graphite/carbon-components';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { WarningFilled16 } from '@developers/graphite/carbon-icons';

import useStore from '@pages/Result/zustand';

type Props = {
  open: boolean;
  close: () => void;
  goToTenderPage: () => void;
};

const StartTenderingModal: React.FC<Props> = ({ open, close, goToTenderPage }) => {
  const { t } = useTranslation();
  const store = useStore();
  const { control, errors, handleSubmit, getValues, watch } = useForm();
  const { setTenderReason } = store;

  const reasonsValue = watch('furtherOffersRequestReason');

  // TMP: For now decision is that reasons should be hardcoded on fronted before implementing solution on backend
  const reasons = [
    { id: 0, text: `${t('tender.startTenderingModal.options.offersTooExpensive')}` },
    { id: 1, text: `${t('tender.startTenderingModal.options.offersNotPreferred')}` },
    { id: 2, text: `${t('tender.startTenderingModal.options.offersNotSuitable')}` },
    { id: 3, text: `${t('tender.startTenderingModal.options.moreOffers')}` },
    { id: 4, text: `${t('tender.startTenderingModal.options.other')}` },
  ];

  const submitHandler = () => {
    const values = getValues();
    const reason =
      values.furtherOffersRequestReason === 4
        ? values.otherReason
        : reasons[values.furtherOffersRequestReason].text;

    setTenderReason(reason);
    goToTenderPage();
  };

  return (
    <ComposedModal
      open={open}
      preventCloseOnClickOutside={true}
      onClose={close}
      size="sm"
      data-qa="start-tender-modal"
    >
      <ModalHeader title={t('tender.tendering')} />
      <ModalBody hasForm>
        <span>{t('tender.startTenderingModal.text')}</span>
        <div className="start-tender-modal__reasons-section">
          <Controller
            name="furtherOffersRequestReason"
            control={control}
            defaultValue={null}
            rules={{
              required: `${t('tender.startTenderingModal.validationReasonMessage')}`,
            }}
            render={({ onChange, value }) => (
              <RadioButtonGroup
                onChange={onChange}
                valueSelected={value}
                name="radioButtonsGroup"
                orientation="vertical"
              >
                {reasons.map((reason) => (
                  <RadioButton
                    id={`id${reason.id}`}
                    key={reason.id}
                    labelText={reason.text}
                    value={reason.id}
                    data-qa={`reason-${reason.id}`}
                  />
                ))}
              </RadioButtonGroup>
            )}
          />
          {errors && errors.furtherOffersRequestReason && (
            <div className="questions__checkbox-error ml-1 mt-5">
              <WarningFilled16 className="questions__checkbox-error-icon" />
              <span className="questions__checkbox-error-text">
                {errors.furtherOffersRequestReason.message}
              </span>
            </div>
          )}
          {reasonsValue === 4 && (
            <Controller
              name="otherReason"
              defaultValue=""
              control={control}
              rules={{
                required: `${t('validationMessage.notEmpty')}`,
              }}
              render={({ onChange, value }) => (
                <TextInput
                  id="otherReason"
                  labelText=""
                  type="email"
                  value={value}
                  onChange={onChange}
                  invalid={!!errors?.otherReason}
                  invalidText={errors?.otherReason?.message}
                  className="start-tender-modal__reasons-other"
                  data-qa="other-reason-text-field"
                />
              )}
            />
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={close} kind="secondary">
          {t('tender.startTenderingModal.cancelProcess')}
        </Button>
        <Button kind="primary" onClick={handleSubmit(submitHandler)} data-qa="submit-tender-reason">
          {t('tender.startTendering')}
        </Button>
      </ModalFooter>
    </ComposedModal>
  );
};

export default StartTenderingModal;
