import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { helpers, API, professionSearchHelpers as profSH } from '@helpers';
import { LoadingStatus, ProfessionList, SessionStorage } from '@enums';
import { ModalProfessionAlias, AliasesByAlphabet, Profession } from '@typings/professionSearch';

export type Loading =
  | LoadingStatus.Idle
  | LoadingStatus.Pending
  | LoadingStatus.Succeeded
  | LoadingStatus.Failed;

export interface ProfessionSearchState {
  readonly loading: Loading;
  readonly loadingPostProfession: Loading;
  readonly loadingIndustryGroups: Loading;
  readonly loadingModalProfessions: Loading;
  readonly loadingPostInquiries: Loading;
  readonly inquiriesResult?: PostInquiriesResult;
  readonly professionAliases?: ProfessionAlias[];
  readonly selectedProfessions?: Profession[];
  readonly modalProfessions: ModalProfessionAlias[];
  readonly industryGroups?: IndustryGroup[];
  readonly alphabetList?: string[];
  readonly modalProfessionsGrouped?: AliasesByAlphabet[];
  readonly errors?: unknown;
}

interface PostArgs {
  professionAliases: Profession[];
  customer: string | null;
}

export interface ChosenProfessionAlias {
  id: number;
  hash: string;
  professionAlias: string;
  isMixProfession: boolean;
}

export interface ProfessionAlias {
  id?: number;
  alias: string;
  hash: string;
  profession?: string;
}

export interface ProfessionAliasCommon {
  isSuggested?: boolean;
  name?: string;
  description?: string;
}

export interface SelectProps {
  suggestion: ProfessionAlias | ModalProfessionAlias | null;
  name?: string;
  description?: string;
  isSuggested?: boolean;
}

export interface IndustryGroup {
  hash: string;
  name: string;
}

export interface PostInquiriesParams {
  hash: string;
  productTag: string;
}

export interface PostInquiries {
  inquiryGroup: string;
  product: string;
}

export interface PostInquiriesResult {
  hash: string;
}

export interface FilterProfessionsProps {
  industryGroupHash: string;
  modalProfessions: ModalProfessionAlias[];
}

const initialState: ProfessionSearchState = {
  loading: LoadingStatus.Idle,
  loadingPostProfession: LoadingStatus.Idle,
  loadingIndustryGroups: LoadingStatus.Idle,
  loadingModalProfessions: LoadingStatus.Idle,
  loadingPostInquiries: LoadingStatus.Idle,
} as ProfessionSearchState;

export const getProfessionAliases = createAsyncThunk(
  'professionSearch/getProfessionAliases',
  async (args: string, thunkApi) => {
    try {
      return await API.get<ProfessionAlias[]>(
        helpers.isWidget
          ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1/profession-aliases.json?full_text_search=${args}`
          : `/api/e2e/v1/profession-aliases.json?full_text_search=${args}`
      );
    } catch (err) {
      const axiosError = err as AxiosError<Error>;
      return thunkApi.rejectWithValue(axiosError.response?.data);
    }
  }
);

export const sendChosenProfessionAlias = createAsyncThunk(
  'professionSearch/sendChosenProfessionAlias',
  async (args: Profession[], thunkApi) => {
    try {
      const customerId: number | undefined = helpers.jsonParserFromSession(
        SessionStorage.Customer
      )?.id;
      return await API.post<PostArgs, ChosenProfessionAlias>(
        helpers.isWidget
          ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1/inquiry-groups`
          : '/api/e2e/v1/inquiry-groups',
        {
          professionAliases: args,
          customer: customerId ? `customers/${customerId}` : null,
        }
      );
    } catch (err) {
      const axiosError = err as AxiosError<Error>;
      return thunkApi.rejectWithValue(axiosError.response?.data);
    }
  }
);

export const postInquiries = createAsyncThunk(
  'professionSearch/postInquiries',
  async (args: PostInquiriesParams, thunkApi) => {
    try {
      return await API.post<PostInquiries, PostInquiriesResult>(
        helpers.isWidget
          ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1/inquiries`
          : `/api/e2e/v1/inquiries`,
        {
          inquiryGroup: `inquiry-groups/${args.hash}`,
          product: `products/${args.productTag}`,
        }
      );
    } catch (err) {
      const axiosError = err as AxiosError<Error>;
      return thunkApi.rejectWithValue(axiosError.response?.data);
    }
  }
);

export const getIndustryGroups = createAsyncThunk(
  'professionSearch/getIndustryGroups',
  async (args, thunkApi) => {
    try {
      return await API.get<IndustryGroup[]>(
        helpers.isWidget
          ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1/industry-groups.json`
          : `/api/e2e/v1/industry-groups.json`
      );
    } catch (err) {
      const axiosError = err as AxiosError<Error>;
      return thunkApi.rejectWithValue(axiosError.response?.data);
    }
  }
);

export const getProfessionAliasesForModal = createAsyncThunk(
  'professionSearch/getProfessionAliasesForModal',
  async (args, thunkApi) => {
    try {
      return await API.get<ModalProfessionAlias[]>(
        helpers.isWidget
          ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1/profession-aliases.json?groups[]=industry:show&order[alias]=asc&pagination=false`
          : `/api/e2e/v1/profession-aliases.json?groups[]=industry:show&order[alias]=asc&pagination=false`
      );
    } catch (err) {
      const axiosError = err as AxiosError<Error>;
      return thunkApi.rejectWithValue(axiosError.response?.data);
    }
  }
);

const professionSearchSlice = createSlice({
  name: 'professionSearch',
  initialState,
  reducers: {
    filterProfessionsByIndustry(state, action: PayloadAction<FilterProfessionsProps>) {
      let neededProfessions = action.payload.modalProfessions;
      if (action.payload.industryGroupHash !== ProfessionList.All) {
        neededProfessions = neededProfessions.filter(
          (i) => i.industryGroupHash === action.payload.industryGroupHash
        );
      }
      const alphaList = profSH.createAlphabetList(neededProfessions);
      state.alphabetList = alphaList;
      state.modalProfessionsGrouped = profSH.createGroupedByAlphabetList(
        alphaList,
        neededProfessions
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfessionAliases.fulfilled, (state, { payload }) => {
      state.loading = LoadingStatus.Succeeded;
      state.errors = '';
      state.professionAliases = payload;
    });
    builder.addCase(getProfessionAliases.pending, (state) => {
      state.loading = LoadingStatus.Pending;
      state.errors = '';
    });
    builder.addCase(getProfessionAliases.rejected, (state, { payload }) => {
      state.loading = LoadingStatus.Failed;
      state.errors = payload;
    });
    builder.addCase(sendChosenProfessionAlias.fulfilled, (state) => {
      state.loadingPostProfession = LoadingStatus.Succeeded;
      state.errors = '';
    });
    builder.addCase(sendChosenProfessionAlias.pending, (state) => {
      state.loadingPostProfession = LoadingStatus.Pending;
      state.errors = '';
    });
    builder.addCase(sendChosenProfessionAlias.rejected, (state, { payload }) => {
      state.loadingPostProfession = LoadingStatus.Failed;
      state.errors = payload;
    });
    builder.addCase(postInquiries.fulfilled, (state) => {
      state.loadingPostInquiries = LoadingStatus.Succeeded;
      state.errors = '';
    });
    builder.addCase(postInquiries.pending, (state) => {
      state.loadingPostInquiries = LoadingStatus.Pending;
      state.errors = '';
    });
    builder.addCase(postInquiries.rejected, (state, { payload }) => {
      state.loadingPostInquiries = LoadingStatus.Failed;
      state.errors = payload;
    });
    builder.addCase(getIndustryGroups.fulfilled, (state, { payload }) => {
      state.loadingIndustryGroups = LoadingStatus.Succeeded;
      state.errors = '';
      state.industryGroups = payload;
    });
    builder.addCase(getIndustryGroups.pending, (state) => {
      state.loadingIndustryGroups = LoadingStatus.Pending;
      state.errors = '';
    });
    builder.addCase(getIndustryGroups.rejected, (state, { payload }) => {
      state.loadingIndustryGroups = LoadingStatus.Failed;
      state.errors = payload;
    });
    builder.addCase(getProfessionAliasesForModal.fulfilled, (state, { payload }) => {
      state.loadingModalProfessions = LoadingStatus.Succeeded;
      state.errors = '';
      state.modalProfessions = payload;
      const alphaList = profSH.createAlphabetList(payload);
      state.alphabetList = alphaList;
      state.modalProfessionsGrouped = profSH.createGroupedByAlphabetList(alphaList, payload);
    });
    builder.addCase(getProfessionAliasesForModal.pending, (state) => {
      state.loadingModalProfessions = LoadingStatus.Pending;
      state.errors = '';
    });
    builder.addCase(getProfessionAliasesForModal.rejected, (state, { payload }) => {
      state.loadingModalProfessions = LoadingStatus.Failed;
      state.errors = payload;
    });
  },
});

// Actions
export const { filterProfessionsByIndustry } = professionSearchSlice.actions;

export default professionSearchSlice.reducer;
