import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { API, helpers } from '@helpers';
import { LoadingStatus } from '@enums';
import { ResultState, OfferDetailsObj, DetailsGetParams } from '@typings/resultPage';
import { OfferDetailDto } from '@api/offerOrchestrator';

const initialState: ResultState = {
  loading: LoadingStatus.Idle,
  loadingConfiguration: LoadingStatus.Idle,
  loadingDetails: LoadingStatus.Idle,
} as ResultState;

export const getOfferDetails = createAsyncThunk(
  'result/getResultDetails',
  async (args: DetailsGetParams, thunkApi) => {
    try {
      // return await API.get<OfferDetails>(`/json/resultTableDetails.json`);
      return await API.get<OfferDetailDto>(
        helpers.isWidget
          ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/offer/v1/offer-groups/${args.offerGroupId}/offers/${args.offerId}/details`
          : `/api/offer/v1/offer-groups/${args.offerGroupId}/offers/${args.offerId}/details`
      );
    } catch (err) {
      const axiosError = err as AxiosError<Error>;
      return thunkApi.rejectWithValue(axiosError.response?.data);
    }
  }
);

const resultSlice = createSlice({
  name: 'result',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOfferDetails.fulfilled, (state, action) => {
      state.loadingDetails = LoadingStatus.Succeeded;
      const { offerId } = action.meta.arg;
      const item: OfferDetailsObj = { [offerId]: action.payload };
      state.resultTableDetails = { ...state.resultTableDetails, ...item };
      state.errors = '';
    });
    builder.addCase(getOfferDetails.pending, (state) => {
      state.loadingDetails = LoadingStatus.Pending;
      state.errors = '';
    });
    builder.addCase(getOfferDetails.rejected, (state, { payload }) => {
      state.loadingDetails = LoadingStatus.Failed;
      state.errors = payload;
    });
  },
});

export default resultSlice.reducer;
