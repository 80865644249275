import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { TariffDetails } from '@shared';
import { LoadingStatus } from '@enums';
import { getOfferDetails } from '@store/resultSlice';
import { ResultState, DetailsGetParams } from '@typings/resultPage';
import { AppState } from '@store';
import { useMainState } from '@hooks';
import { inquiriesService } from '@services/consultation';

type Props = {
  id: string;
};

const ResultDetails: React.FC<Props> = ({ id }) => {
  const dispatch = useDispatch();
  const { inquiryData } = useMainState();

  const { resultTableDetails, loadingDetails } = useSelector<AppState, ResultState>(
    (state: AppState) => state.result
  );

  const { data: offerGroupDetails } = inquiriesService.useGetOfferGroup(inquiryData?.id.toString());

  useEffect(() => {
    if (inquiryData?.id && (!resultTableDetails || !resultTableDetails[id])) {
      const params: DetailsGetParams = {
        inquiryId: inquiryData.id,
        offerId: id,
        offerGroupId: offerGroupDetails?.id as string,
      };
      dispatch(getOfferDetails(params));
    }
  }, [dispatch, id, inquiryData?.id, resultTableDetails, offerGroupDetails?.id]);

  return (
    <div className="result-details">
      <TariffDetails
        data={resultTableDetails && resultTableDetails[id]}
        isLoading={loadingDetails === LoadingStatus.Pending}
      />
    </div>
  );
};

export default ResultDetails;
