import React, { Suspense, useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import smoothscroll from 'smoothscroll-polyfill';
import '@assets/scss/index.scss';
import '@assets/scss/carbon/app.scss';
import 'react-datetime/css/react-datetime.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/_slick.scss';
import './assets/scss/_slickTheme.scss';
import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { HttpStatusCode, setupToolbox } from '@developers/toolbox';
import { ReactQueryDevtools } from 'react-query/devtools';
import { FlagsProvider } from 'flagged';

import { useIdentifyHeap } from '@hooks';
import store from '@store/index';
import { helpers } from '@helpers';
import { FeatureFlag } from '@enums';
import MaintenancePage from '@pages/MaintenancePage';

import Routes from './Routes';

import './i18n';

const App: React.FC = () => {
  const [showMaintainPage, setShowMaintainPage] = useState(false);

  // INFO: Transform fa-class into svg
  // TODO: there is 'fa-close' class but no icon?
  library.add({ faCheck });
  dom.watch();

  smoothscroll.polyfill();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
    queryCache: new QueryCache({
      onError: (error) => {
        const axiosError = error as AxiosError<{
          maintenance: boolean;
          message: string;
          status: number;
        }>;
        if (
          axiosError?.response?.status === HttpStatusCode.ServiceUnavailable &&
          !!axiosError?.response?.data?.maintenance
        ) {
          setShowMaintainPage(true);
        }
      },
    }),
    mutationCache: new MutationCache({
      onError: (error) => {
        const axiosError = error as AxiosError<{
          maintenance: boolean;
          message: string;
          status: number;
        }>;
        if (
          axiosError?.response?.status === HttpStatusCode.ServiceUnavailable &&
          !!axiosError?.response?.data?.maintenance
        ) {
          setShowMaintainPage(true);
        }
      },
    }),
  });

  useIdentifyHeap();

  useEffect(() => {
    setupToolbox(helpers.getLocale());
  }, []);

  if (showMaintainPage) {
    return <MaintenancePage />;
  }

  return (
    <Suspense fallback={null}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <FlagsProvider
            features={{
              [FeatureFlag.useWidgetLandingPage]:
                helpers.envVariables.REACT_APP_WIDGET_USE_LANDING_PAGE ?? false,
            }}
          >
            <ToastContainer autoClose={3000} />
            <Router>
              <Routes />
            </Router>
          </FlagsProvider>
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Suspense>
  );
};

export default App;
