import { useState, useLayoutEffect } from 'react';

enum Breakpoints {
  Small = 672,
  Medium = 1056,
  Large = 1312,
  XLarge = 1584,
  Max = 1584,
}

interface UseWindowSizeState {
  size: number[];
  isSmall: boolean;
  isMedium: boolean;
  isLarge: boolean;
  isXLarge: boolean;
  isMax: boolean;
}

const useWindowSize = (): UseWindowSizeState => {
  const [state, setState] = useState({
    size: [0, 0],
    isSmall: false,
    isMedium: false,
    isLarge: false,
    isXLarge: false,
    isMax: false,
  });

  useLayoutEffect(() => {
    function updateSize() {
      setState({
        size: [window.innerWidth, window.innerHeight],
        isSmall: window.innerWidth < Breakpoints.Small,
        isMedium:
          window.innerWidth >= Breakpoints.Small &&
          window.innerWidth < Breakpoints.Medium,
        isLarge:
          window.innerWidth >= Breakpoints.Medium &&
          window.innerWidth < Breakpoints.XLarge,
        isXLarge:
          window.innerWidth >= Breakpoints.XLarge &&
          window.innerWidth < Breakpoints.Max,
        isMax: window.innerWidth >= Breakpoints.Max,
      });
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return state;
};

export default useWindowSize;
