import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { API, helpers } from '@helpers';
import { ApiQuery } from '@enums';

export interface FrontendSettings {
  baseUrl: string;
}

const useFrontendSettings = (
  options: UseQueryOptions<FrontendSettings, Error> = {}
): QueryObserverResult<FrontendSettings, Error> => {
  return useQuery<FrontendSettings, Error>(
    [ApiQuery.FrontendSettings],
    async () => {
      const response = await API.get<FrontendSettings>(`/api/frontend-settings`);

      if (response.baseUrl) {
        response.baseUrl = helpers.removeTrailingSlashes(response.baseUrl);
      }

      return response;
    },
    { enabled: !helpers.isWidget, ...options }
  );
};

export default useFrontendSettings;
