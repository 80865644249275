import React from 'react';

import {
  SkeletonText,
  ButtonSkeleton,
  DropdownSkeleton,
} from '@developers/graphite/carbon-components';

const ResultTableHeaderSkeleton: React.FC = () => {
  return (
    <>
      <div className="result__price-range my-3">
        <SkeletonText width="40%" />
      </div>
      <div className="bx--row">
        <div className="bx--col-md-5">
          <ButtonSkeleton className="mr-1" size="md" />
          <ButtonSkeleton size="md" />
        </div>
        <div className="bx--col-md-3">
          <DropdownSkeleton />
        </div>
      </div>
    </>
  );
};

export default ResultTableHeaderSkeleton;
