import { Document } from '@api/fileService';

export enum FileStatus {
  Complete = 'complete',
  Edit = 'edit',
  Uploading = 'uploading',
}

export enum FileError {
  Upload = 'fileUpload',
  Size = 'fileSize',
  Type = 'fileType',
  FilesAmount = 'filesAmount',
  FilesTotalSize = 'FilesTotalSize',
}
export enum Field {
  Upload = 'fileUploadName',
  FileValidation = 'fileUploadValidation',
}
export interface UploaderValue {
  innerId: string;
  file: File | Document;
  status: FileStatus;
  error: boolean;
}

export interface ErrorSubject {
  [FileError.Upload]: string;
  [FileError.Size]: string;
  [FileError.Type]: string;
  [key: string]: string;
}

export interface MultipleFileValidations {
  validate: {
    [key: string]: (value: UploaderValue[]) => boolean | string;
  };
}

export interface SingleFileValidations {
  validate: {
    [key: string]: (value: UploaderValue) => boolean | string;
  };
}

export interface UpdatedUploaderValue {
  name: string;
  id: string;
  extension?: string;
}
