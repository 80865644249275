import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'element-closest-polyfill';
import 'intersection-observer';
import React from 'react';

import ReactDOM from 'react-dom';
import { defaults } from '@developers/queries/defaults';

import { helpers } from '@helpers';

import App from './App';
import reportWebVitals from './reportWebVitals';
import useGlobalState, { TSConfig } from './useGlobalStore';
import { envVariables } from './helpers/helpers';
import { sanitizeConfig } from './helpers/widget';

const B2C_BROKER_ID = '1212121212121212';

defaults.baseUrl = `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest`;

declare global {
  interface Document {
    documentMode?: number;
  }

  interface File {
    invalidFileType?: boolean;
  }

  interface Window {
    ts: TSPortal;
  }
}

interface TSPortal {
  load: (config: TSConfig) => void;
}

const load = (config?: TSConfig) => {
  useGlobalState.setState({
    portalConfig: {
      ...useGlobalState.getState().portalConfig,
      ...sanitizeConfig(config),
    } as TSConfig,
  });

  if (helpers.envVariables.REACT_APP_IS_WIDGET_DEV_MODE) {
    // eslint-disable-next-line no-console
    console.log({ config, env: helpers.envVariables });
  }

  document.body.classList.add('ts-app');

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById(config?.renderId || 'root')
  );
};

window.ts = {
  load,
};

if (!envVariables.REACT_APP_IS_WIDGET || envVariables.REACT_APP_IS_WIDGET_DEV_MODE) {
  load({
    brokerCode: B2C_BROKER_ID,
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
