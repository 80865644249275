/* eslint-disable react/display-name */
import React from 'react';

import {
  SkeletonPlaceholder,
  SkeletonText,
  CheckboxSkeleton,
  ButtonSkeleton,
} from '@developers/graphite/carbon-components';
import { useTranslation } from 'react-i18next';
import { TableData, TableDataHeader, TableDataRow, DataTableExpand } from '@developers/graphite';

const ResultTableSkeleton: React.FC = () => {
  const { t } = useTranslation();

  const prepareHeaders = () => {
    const headerTitles = [
      `${t('result.tableHeader.tariff')}`,
      `${t('result.tableHeader.details')}`,
      `${t('result.tableHeader.highlights')}`,
      `${t('result.tableHeader.annualFee')}`,
    ];
    const headers: TableDataHeader[] = [];
    headerTitles.forEach((element, index) => {
      const item = {
        key: element,
        header: element,
        align: index === headerTitles.length - 1 ? 'end' : 'start',
      };
      headers.push(item as TableDataHeader);
    });

    return headers;
  };

  const ResultOfferNameSkeleton = () => {
    return (
      <div>
        <SkeletonPlaceholder className="result-offer-name-skeleton mb-3" />
        <SkeletonText heading width="40%" />
        <SkeletonText className="mb-3" width="90%" />
        <div className="skeleton-checkbox">
          <CheckboxSkeleton />
        </div>
      </div>
    );
  };

  const OfferDetailsSkeleton = () => {
    return (
      <>
        <div className="mb-5">
          <SkeletonText />
          <SkeletonText width="60%" />
        </div>
        <div className="mb-5">
          <SkeletonText />
          <SkeletonText width="60%" />
        </div>
      </>
    );
  };

  const ResultOfferHighlightSkeleton = () => {
    return (
      <>
        <div>
          <SkeletonText />
          <SkeletonText />
          <SkeletonText />
        </div>

        <div className="test">
          <SkeletonText />
        </div>
      </>
    );
  };

  const ResultOfferPricesSkeleton = () => {
    return (
      <div className="result-offer-prices-skeleton">
        <SkeletonText heading width="60%" />
        <SkeletonText width="20%" />
        <ButtonSkeleton className="action-button pb-5" />
      </div>
    );
  };

  const prepareRowData = () => {
    const rows: TableDataRow[] = [];
    const row = (key: string): TableDataRow => {
      return {
        id: key,
        columns: [
          {
            id: '0',
            render: () => <ResultOfferNameSkeleton />,
            className: 'offer__td--name pt-6 pb-5',
          },
          {
            id: '1',
            render: () => <OfferDetailsSkeleton />,
            className: 'pt-6 pb-5',
          },
          {
            id: '2',
            render: () => <ResultOfferHighlightSkeleton />,
            className: 'offer__td--highlights pt-6 pb-5',
          },
          {
            id: '3',
            render: () => <ResultOfferPricesSkeleton />,
            className: 'offer__td--prices pt-6 pb-5 position-relative',
          },
        ],
      };
    };
    rows.push(row('1'), row('2'), row('3'));

    return rows;
  };

  const tableData: TableData = {
    headers: prepareHeaders(),
    rows: prepareRowData(),
  };

  return (
    <>
      <div className="mb-7">
        <DataTableExpand tableData={tableData} />
      </div>
    </>
  );
};

export default ResultTableSkeleton;
