import { useTranslation } from 'react-i18next';
import { ErrorOption } from 'react-hook-form';

import { helpers } from '@helpers';
import {
  FileError,
  Field,
  UploaderValue,
  ErrorSubject,
  MultipleFileValidations,
  SingleFileValidations,
} from '@typings/fileUploader';

const useUploadValidation = ({
  setError,
  acceptedFileTypes,
  fileSizeLimit = 10485760,
  filesTotalSizeLimit,
  maxFileAmount,
}: {
  setError?: (id: string, error: ErrorOption) => void;
  acceptedFileTypes: string[];
  fileSizeLimit?: number;
  maxFileAmount?: number;
  filesTotalSizeLimit?: number;
}): {
  singleFileValidations: SingleFileValidations;
  multipleFileValidations: MultipleFileValidations;
  errorSubject: ErrorSubject;
} => {
  const { t } = useTranslation();

  const setCustomError = (id: string, type: string, message: string) => {
    setError &&
      setError(id, {
        type,
        message,
      });
  };

  const errorSubject: ErrorSubject = {
    [FileError.Upload]: `${t(`fileUpload.errors.fileUpload.description`)}`,
    [FileError.Size]: `${t('fileUpload.errors.fileSize.description', {
      fileSize: helpers.formatBytes(fileSizeLimit),
    })}`,
    [FileError.Type]: `${t(`fileUpload.errors.fileType.description`, {
      types: acceptedFileTypes.join(', '),
    })}`,
  };

  const multipleFileValidations: MultipleFileValidations = {
    validate: {
      [Field.FileValidation]: (value: UploaderValue[]) => {
        let isValid = true;
        value &&
          value.forEach((element: UploaderValue) => {
            if (element && element.file && (element.file as File)?.size > fileSizeLimit) {
              isValid = false;
              setCustomError(
                element.innerId,
                FileError.Size,
                `${t(`fileUpload.errors.fileSize.title`)}`
              );
            } else if (element && element.file && (element.file as File)?.invalidFileType) {
              isValid = false;
              setCustomError(
                element.innerId,
                FileError.Type,
                `${t(`fileUpload.errors.fileType.title`)}`
              );
            } else if (element && element.error) {
              isValid = false;
              setCustomError(
                element.innerId,
                FileError.Upload,
                `${t(`fileUpload.errors.fileUpload.title`)}`
              );
            }
          });
        return isValid;
      },
      [FileError.FilesAmount]: (value: UploaderValue[]) => {
        if (maxFileAmount && value?.length > maxFileAmount) {
          return t('fileUpload.errors.fileAmount.description', { maxFileAmount }) as string;
        }
        return true;
      },
      [FileError.FilesTotalSize]: (value: UploaderValue[]) => {
        if (filesTotalSizeLimit && value) {
          const totalSize = value.reduce(
            (previousValue, currentValue) => previousValue + (currentValue.file as File)?.size,
            0
          );
          if (totalSize > filesTotalSizeLimit) {
            return t('fileUpload.errors.filesTotalSize.description', {
              filesTotalSizeLimit: helpers.formatBytes(filesTotalSizeLimit),
            }) as string;
          }
        }
        return true;
      },
    },
  };

  const singleFileValidations: SingleFileValidations = {
    validate: {
      [FileError.Size]: (value: UploaderValue) => {
        if (value && value.file && (value.file as File)?.size > fileSizeLimit) {
          return t('fileUpload.errors.fileSize.title') as string;
        }

        return true;
      },
      [FileError.Type]: (value: UploaderValue) => {
        if (value && value.file && (value.file as File)?.invalidFileType) {
          return t('fileUpload.errors.fileType.title') as string;
        }

        return true;
      },
      [FileError.Upload]: (value: UploaderValue) => {
        if (value && value.error) {
          return t('fileUpload.errors.fileUpload.title') as string;
        }

        return true;
      },
    },
  };

  return { singleFileValidations, multipleFileValidations, errorSubject };
};

export default useUploadValidation;
