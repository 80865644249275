import React, { useState } from 'react';

import { Pagination } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { KeyName, URLPart } from '@enums';
import { useGlobalState, useMainState } from '@hooks';
import { ReactRouterValidatedLink } from '@shared';

import QuestionModal from './QuestionModal';

interface Props {
  currentPage: URLPart;
  pageOrder: URLPart[];
}

const OldQuestionnaireStep: React.FC<Props> = ({ currentPage, pageOrder }) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);

  const { frontendSettings } = useGlobalState();
  const { inquiryInfo } = useMainState();

  const questionnairePageUrl = `${frontendSettings.baseUrl}${URLPart.InquiryPage}/${inquiryInfo?.hash}`;

  const currentPageIsActive = currentPage === URLPart.InquiryPage;
  const currentPageIsCompleted =
    pageOrder.indexOf(currentPage) > pageOrder.indexOf(URLPart.InquiryPage);

  return (
    <>
      <div>
        {t('steps.riskIdentification')}{' '}
        {currentPageIsCompleted && (
          <>
            <ReactRouterValidatedLink
              url={questionnairePageUrl}
              fallbackClassName="link-icon_skeleton"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </ReactRouterValidatedLink>{' '}
            |{' '}
            <span
              onClick={() => setShowModal(true)}
              onKeyDown={(e) => e.key === KeyName.Enter && setShowModal(true)}
              tabIndex={0}
              role="button"
            >
              {' '}
              <FontAwesomeIcon icon={faEye} />
            </span>
          </>
        )}
      </div>
      <div>
        <Pagination className="justify-content-center mb-0">
          <Pagination.Item
            active={currentPageIsActive}
            disabled={!currentPageIsCompleted && !currentPageIsActive}
          >
            2
          </Pagination.Item>
          <Pagination.Item
            active={currentPageIsActive}
            disabled={!currentPageIsCompleted && !currentPageIsActive}
          >
            3
          </Pagination.Item>
          <Pagination.Item
            active={currentPageIsActive}
            disabled={!currentPageIsCompleted && !currentPageIsActive}
          >
            4
          </Pagination.Item>
        </Pagination>
      </div>
      <QuestionModal show={showModal} handleClose={handleCloseModal} />
    </>
  );
};

export default OldQuestionnaireStep;
