import React from 'react';

import classNames from 'classnames';
import { Row } from 'react-bootstrap';

type Props = {
  className?: string;
  height?: string;
};

const Spacer: React.FC<Props> = ({ className, height = '1rem' }) => (
  <Row className={classNames('spacer bg-color-body', className)} style={{ height }} />
);

export default Spacer;
