import { configureStore } from '@reduxjs/toolkit';

import userReducer, { UserState } from '@store/userSlice';
import resultReducer from '@store/resultSlice';
import comparisonResultReducer, { ComparisonResultState } from '@store/comparisonSlice';
import documentsReducer from '@store/documentsSlice';
import previousContractReducer, { PreviousContractState } from '@store/previousContractSlice';
import homeReducer from '@store/homeSlice';
import professionSearchReducer, { ProfessionSearchState } from '@store/professionSearchSlice';
import { HomeResultState } from '@typings/home';
import { ResultState } from '@typings/resultPage';
import { DocumentsState } from '@typings/documents';

export type AppState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  router: any;
  user: UserState;
  comparisonResult: ComparisonResultState;
  result: ResultState;
  previousContract: PreviousContractState;
  documents: DocumentsState;
  home: HomeResultState;
  professionSearch: ProfessionSearchState;
};

const store = configureStore({
  reducer: {
    user: userReducer,
    comparisonResult: comparisonResultReducer,
    result: resultReducer,
    previousContract: previousContractReducer,
    documents: documentsReducer,
    home: homeReducer,
    professionSearch: professionSearchReducer,
  },
  devTools: true,
});
export type AppDispatch = typeof store.dispatch;

export default store;
