const replaceUmlaut = (str: string): string => {
  let value = str.replace(/ä/g, 'a');
  value = value.replace(/ö/g, 'o');
  value = value.replace(/ü/g, 'u');
  value = value.replace(/ß/g, 'ss');
  value = value.replace(/Ä/g, 'A');
  value = value.replace(/Ö/g, 'O');
  value = value.replace(/Ü/g, 'U');

  return value;
};

export default replaceUmlaut;
