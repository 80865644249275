import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { API, helpers } from '@helpers';
import { LoadingStatus } from '@enums';
import {
  Params,
  Documents,
  DocumentIds,
  ZipFile,
  DocumentsCollection,
  DocumentsState,
  OldParams,
} from '@typings/documents';

const initialState: DocumentsState = {
  loading: LoadingStatus.Idle,
  loadingZip: LoadingStatus.Idle,
  loadingResult: LoadingStatus.Idle,
} as DocumentsState;

export const getDocuments = createAsyncThunk(
  'documents/getDocuments',
  async (args: Params, thunkApi) => {
    try {
      // return await API.get<Documents>(`/json/documents.json`);
      return await API.get<Documents>(
        helpers.isWidget
          ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1/inquiries/${args.inquiryHash}/documents?calculator_id=${args.calculatorId}&tariff_id=${args.tariffId}&csp=${args.csp}`
          : `/api/e2e/v1/inquiries/${args.inquiryHash}/documents?calculator_id=${args.calculatorId}&tariff_id=${args.tariffId}&csp=${args.csp}`
      );
    } catch (err) {
      const axiosError = err as AxiosError<Error>;
      return thunkApi.rejectWithValue(axiosError.response?.data);
    }
  }
);

// TMP: remove getDocumentsFromOldService when Result page is deployed

export const getDocumentsFromOldService = createAsyncThunk(
  'documents/getDocumentsFromOldService',
  async (args: OldParams, thunkApi) => {
    try {
      return await API.get<Documents>(
        helpers.isWidget
          ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1/inquiries/${args.inquiryHash}/documents?calculation_hash=${args.calculationHash}`
          : `/api/e2e/v1/inquiries/${args.inquiryHash}/documents?calculation_hash=${args.calculationHash}`
      );
    } catch (err) {
      const axiosError = err as AxiosError<Error>;
      return thunkApi.rejectWithValue(axiosError.response?.data);
    }
  }
);

export const createZipFile = createAsyncThunk(
  'documents/createZipFile',
  async (args: string[], thunkApi) => {
    try {
      return await API.post<DocumentIds, ZipFile>(
        helpers.isWidget
          ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/file/v1/archives`
          : `/api/file/v1/archives`,
        {
          ids: args,
        }
      );
    } catch (err) {
      const axiosError = err as AxiosError<Error>;
      return thunkApi.rejectWithValue(axiosError.response?.data);
    }
  }
);

const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDocuments.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded;
      state.errors = '';
      const { offerId } = action.meta.arg;
      if (offerId) {
        const item: DocumentsCollection = {
          [offerId]: action.payload.documents,
        };
        state.documentsCollection = { ...state.documentsCollection, ...item };
      } else {
        state.documents = action.payload.documents;
      }
    });
    builder.addCase(getDocuments.pending, (state) => {
      state.loading = LoadingStatus.Pending;
      state.errors = '';
    });

    builder.addCase(getDocuments.rejected, (state, { payload }) => {
      state.loading = LoadingStatus.Failed;
      state.errors = payload;
    });

    // TMP: remove getDocumentsFromOldService when Result page is deployed
    builder.addCase(getDocumentsFromOldService.fulfilled, (state, { payload }) => {
      state.loading = LoadingStatus.Succeeded;
      state.errors = '';
      state.documents = payload.documents;
    });
    builder.addCase(getDocumentsFromOldService.pending, (state) => {
      state.loading = LoadingStatus.Pending;
      state.errors = '';
    });
    builder.addCase(getDocumentsFromOldService.rejected, (state, { payload }) => {
      state.loading = LoadingStatus.Failed;
      state.errors = payload;
    });

    builder.addCase(createZipFile.fulfilled, (state) => {
      state.loadingZip = LoadingStatus.Succeeded;
      state.errors = '';
    });
    builder.addCase(createZipFile.pending, (state) => {
      state.loadingZip = LoadingStatus.Pending;
      state.errors = '';
    });
    builder.addCase(createZipFile.rejected, (state, { payload }) => {
      state.loadingZip = LoadingStatus.Failed;
      state.errors = payload;
    });
  },
});

export default documentsSlice.reducer;
