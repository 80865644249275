type AuthHeader =
  | {
      Authorization: string;
    }
  | {
      Authorization?: undefined;
    };

const authHeader = (): AuthHeader => {
  const user = localStorage.getItem('user');
  const token = user !== null && JSON.parse(user)?.token;

  if (token) {
    return { Authorization: `Bearer ${token}` };
  }
  return {};
};

export default authHeader;
