import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { ContentType, HttpHeader } from '@developers/toolbox';

import { InsurerApi, InsurerRead } from '@api/e2e';
import { ApiQuery } from '@enums';
import { helpers } from '@helpers';

const insurersInstance = new InsurerApi(
  undefined,
  helpers.isWidget
    ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1`
    : '/api/e2e/v1'
);

const useGetInsurers = (
  groups?: Array<string>,
  page?: number,
  pagination?: boolean,
  options: UseQueryOptions<InsurerRead[], AxiosError> = {}
): UseQueryResult<InsurerRead[], AxiosError> =>
  useQuery(
    [ApiQuery.Insurers],
    () =>
      insurersInstance
        .getInsurerCollection(groups, page, pagination, {
          headers: { [HttpHeader.Accept]: ContentType.Json },
        })
        .then((res) => {
          return res.data;
        }),
    {
      ...options,
    }
  );

const insurer = {
  useGetInsurers,
};

export default insurer;
