import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { helpers } from '@helpers';

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['htmlTag'],
    },
    fallbackLng: 'de',
    whitelist: ['en', 'de', 'fr'],
    backend: {
      loadPath: `${helpers.envVariables.REACT_APP_TRANSLATIONS_URL}/locales/{{lng}}/{{ns}}.json`,
    },
  });

export default i18n;
