import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions, QueryObserverResult } from 'react-query';

import { TokenDetailApi, Broker } from '@api/broker/api';
import { helpers } from '@helpers';

enum TokenDetailQueryKey {
  TokenDetail = '/broker/tokendetail/tokendetail',
}

const tokenDetailInstance = new TokenDetailApi(
  undefined,
  helpers.isWidget
    ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/broker/v1`
    : undefined
);

const useTokenDetail = (
  options: UseQueryOptions<Broker, AxiosError> = {}
): QueryObserverResult<Broker, Error> =>
  useQuery(
    TokenDetailQueryKey.TokenDetail,
    () => tokenDetailInstance.tokenDetail().then((res) => res.data),
    {
      ...options,
    }
  );

const tokenDetail = {
  useTokenDetail,
};

export default tokenDetail;
