import React, { useState } from 'react';

import { Modal, Button, Form, Container } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';

type Props = {
  show: boolean;
  handleClose: () => void;
};

const BrokerTutorialModal: React.FC<Props> = ({ show, handleClose }) => {
  const [isNotShowAgain, changeIsNotShowAgain] = useState(false);
  const { t } = useTranslation();

  const closeTheModal = () => {
    // TMP: change cookie to localStorage when all e2e page are react pages
    if (isNotShowAgain) {
      const cookies = new Cookies();
      cookies.set('showBrokerTutorial', 'true');
    }
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      className="ts"
    >
      <Modal.Body>
        <Container className="mt-4">
          {t('brokerTutorialModal.refer')},
          <br /> {t('brokerTutorialModal.text')}
          <div className="mt-3">
            <Form.Check
              className="mr-3"
              type="checkbox"
              name="checkbox-mixed"
              data-qa="broker-tutorial-checkbox"
              label={t('brokerTutorialModal.checkboxLabel')}
              checked={isNotShowAgain}
              onChange={() => {
                changeIsNotShowAgain(!isNotShowAgain);
              }}
            />
          </div>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" data-qa="schliessen-button" onClick={closeTheModal}>
          {t('general.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BrokerTutorialModal;
