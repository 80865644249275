import { UseMutationResult, useMutation } from 'react-query';
import { AxiosError } from 'axios';

import {
  InquiryGroupApi,
  InquiryGroupInquirygroupWrite,
  InquiryGroupInquirygroupRead,
} from '@api/e2e';
import { helpers } from '@helpers';

const inquiryGroupInstance = new InquiryGroupApi(
  undefined,
  helpers.isWidget
    ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/e2e/v1`
    : '/api/e2e/v1'
);

const useCreateInquiryGroup = (): UseMutationResult<
  InquiryGroupInquirygroupRead,
  AxiosError,
  InquiryGroupInquirygroupWrite
> =>
  useMutation((inquiryGroup: InquiryGroupInquirygroupWrite) =>
    inquiryGroupInstance.postInquiryGroupCollection(inquiryGroup).then((res) => res.data)
  );

const inquiryGroup = {
  useCreateInquiryGroup,
};

export default inquiryGroup;
