/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * This is a sample server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Archive
 */
export interface Archive {
    /**
     * MongoDB ObjectId 
     * @type {string}
     * @memberof Archive
     */
    _id: string;
    /**
     * document type 
     * @type {Array<string>}
     * @memberof Archive
     */
    docIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Archive
     */
    mimeType: string;
    /**
     * 
     * @type {string}
     * @memberof Archive
     */
    extension: string;
    /**
     * use for the name file stored in S3 
     * @type {string}
     * @memberof Archive
     */
    hash: string;
    /**
     * created date 
     * @type {string}
     * @memberof Archive
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof Archive
     */
    url?: string;
    /**
     * version 
     * @type {number}
     * @memberof Archive
     */
    __v?: number;
}
/**
 * 
 * @export
 * @interface ArchiveForm
 */
export interface ArchiveForm {
    /**
     * document ids 
     * @type {Array<string>}
     * @memberof ArchiveForm
     */
    ids: Array<string>;
}
/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * MongoDB ObjectId 
     * @type {string}
     * @memberof Document
     */
    _id: string;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    status?: number;
    /**
     * document type 
     * @type {string}
     * @memberof Document
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    mimeType: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    extension: string;
    /**
     * use for the name file stored in S3 
     * @type {string}
     * @memberof Document
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    path: string;
    /**
     * created date 
     * @type {string}
     * @memberof Document
     */
    created?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Document
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    url?: string;
    /**
     * version 
     * @type {number}
     * @memberof Document
     */
    __v?: number;
}
/**
 * 
 * @export
 * @interface DocumentForm
 */
export interface DocumentForm {
    /**
     * document type 
     * @type {string}
     * @memberof DocumentForm
     */
    docType: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentForm
     */
    docName: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentForm
     */
    docPath: string;
}
/**
 * 
 * @export
 * @interface DocumentList
 */
export interface DocumentList {
    /**
     * 
     * @type {Array<Document>}
     * @memberof DocumentList
     */
    docs: Array<Document>;
    /**
     * 
     * @type {number}
     * @memberof DocumentList
     */
    limit: number;
    /**
     * document type 
     * @type {number}
     * @memberof DocumentList
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentList
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentList
     */
    pagingCounter: number;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentList
     */
    hasPrevPage: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentList
     */
    hasNextPage: boolean;
    /**
     * 
     * @type {number}
     * @memberof DocumentList
     */
    prevPage: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentList
     */
    nextPage: number;
}
/**
 * 
 * @export
 * @interface EditDocumentForm
 */
export interface EditDocumentForm {
    /**
     * document name
     * @type {string}
     * @memberof EditDocumentForm
     */
    name: string;
}
/**
 * 
 * @export
 * @interface Image
 */
export interface Image {
    /**
     * MongoDB ObjectId 
     * @type {string}
     * @memberof Image
     */
    _id: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    mimeType: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    extension: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    path: string;
    /**
     * created date 
     * @type {string}
     * @memberof Image
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    url?: string;
    /**
     * version 
     * @type {number}
     * @memberof Image
     */
    __v?: number;
}
/**
 * 
 * @export
 * @interface SignedUrl
 */
export interface SignedUrl {
    /**
     * 
     * @type {string}
     * @memberof SignedUrl
     */
    url: string;
}
/**
 * 
 * @export
 * @interface TotalDocument
 */
export interface TotalDocument {
    /**
     * - 987
     * @type {number}
     * @memberof TotalDocument
     */
    total: number;
}

/**
 * ArchivesApi - axios parameter creator
 * @export
 */
export const ArchivesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id Archive id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1ArchivesIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFileV1ArchivesIdGet', 'id', id)
            const localVarPath = `/api/file/v1/archives/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ArchiveForm} archive Archive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1ArchivesPost: async (archive: ArchiveForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'archive' is not null or undefined
            assertParamExists('apiFileV1ArchivesPost', 'archive', archive)
            const localVarPath = `/api/file/v1/archives`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(archive, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArchivesApi - functional programming interface
 * @export
 */
export const ArchivesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArchivesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id Archive id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileV1ArchivesIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Archive>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileV1ArchivesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ArchiveForm} archive Archive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileV1ArchivesPost(archive: ArchiveForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Archive>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileV1ArchivesPost(archive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ArchivesApi - factory interface
 * @export
 */
export const ArchivesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArchivesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id Archive id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1ArchivesIdGet(id: string, options?: any): AxiosPromise<Archive> {
            return localVarFp.apiFileV1ArchivesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ArchiveForm} archive Archive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1ArchivesPost(archive: ArchiveForm, options?: any): AxiosPromise<Archive> {
            return localVarFp.apiFileV1ArchivesPost(archive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArchivesApi - object-oriented interface
 * @export
 * @class ArchivesApi
 * @extends {BaseAPI}
 */
export class ArchivesApi extends BaseAPI {
    /**
     * 
     * @param {string} id Archive id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArchivesApi
     */
    public apiFileV1ArchivesIdGet(id: string, options?: any) {
        return ArchivesApiFp(this.configuration).apiFileV1ArchivesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ArchiveForm} archive Archive
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArchivesApi
     */
    public apiFileV1ArchivesPost(archive: ArchiveForm, options?: any) {
        return ArchivesApiFp(this.configuration).apiFileV1ArchivesPost(archive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AssetsApi - axios parameter creator
 * @export
 */
export const AssetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} path asset path eg: pool/12
         * @param {any} file asset file eg: style.css
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1AssetsPost: async (path: string, file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            assertParamExists('apiFileV1AssetsPost', 'path', path)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiFileV1AssetsPost', 'file', file)
            const localVarPath = `/api/file/v1/assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (path !== undefined) { 
                localVarFormParams.append('path', path as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetsApi - functional programming interface
 * @export
 */
export const AssetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} path asset path eg: pool/12
         * @param {any} file asset file eg: style.css
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileV1AssetsPost(path: string, file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileV1AssetsPost(path, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetsApi - factory interface
 * @export
 */
export const AssetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} path asset path eg: pool/12
         * @param {any} file asset file eg: style.css
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1AssetsPost(path: string, file: any, options?: any): AxiosPromise<void> {
            return localVarFp.apiFileV1AssetsPost(path, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetsApi - object-oriented interface
 * @export
 * @class AssetsApi
 * @extends {BaseAPI}
 */
export class AssetsApi extends BaseAPI {
    /**
     * 
     * @param {string} path asset path eg: pool/12
     * @param {any} file asset file eg: style.css
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public apiFileV1AssetsPost(path: string, file: any, options?: any) {
        return AssetsApiFp(this.configuration).apiFileV1AssetsPost(path, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentsApi - axios parameter creator
 * @export
 */
export const DocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {any} [ids] Document ids.
         * @param {any} [hashes] Document hashes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1DocumentsGet: async (ids?: any, hashes?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/file/v1/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }

            if (hashes !== undefined) {
                localVarQueryParameter['hashes'] = hashes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Document id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1DocumentsIdDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFileV1DocumentsIdDelete', 'id', id)
            const localVarPath = `/api/file/v1/documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Document id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1DocumentsIdDownloadGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFileV1DocumentsIdDownloadGet', 'id', id)
            const localVarPath = `/api/file/v1/documents/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Document id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1DocumentsIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFileV1DocumentsIdGet', 'id', id)
            const localVarPath = `/api/file/v1/documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Document id.
         * @param {EditDocumentForm} document Document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1DocumentsIdPatch: async (id: string, document: EditDocumentForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFileV1DocumentsIdPatch', 'id', id)
            // verify required parameter 'document' is not null or undefined
            assertParamExists('apiFileV1DocumentsIdPatch', 'document', document)
            const localVarPath = `/api/file/v1/documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(document, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} docType document type eg: export_files
         * @param {string} docName documetn name eg: Angebot
         * @param {string} docPath document path eg: export_files/
         * @param {any} document document file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1DocumentsPost: async (docType: string, docName: string, docPath: string, document: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'docType' is not null or undefined
            assertParamExists('apiFileV1DocumentsPost', 'docType', docType)
            // verify required parameter 'docName' is not null or undefined
            assertParamExists('apiFileV1DocumentsPost', 'docName', docName)
            // verify required parameter 'docPath' is not null or undefined
            assertParamExists('apiFileV1DocumentsPost', 'docPath', docPath)
            // verify required parameter 'document' is not null or undefined
            assertParamExists('apiFileV1DocumentsPost', 'document', document)
            const localVarPath = `/api/file/v1/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (docType !== undefined) { 
                localVarFormParams.append('docType', docType as any);
            }
    
            if (docName !== undefined) { 
                localVarFormParams.append('docName', docName as any);
            }
    
            if (docPath !== undefined) { 
                localVarFormParams.append('docPath', docPath as any);
            }
    
            if (document !== undefined) { 
                localVarFormParams.append('document', document as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} path Document Path eg:test/50d3966b-6730-41ee-83a9-6fb77d43826e.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1DocumentsSignedUrlGet: async (path: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            assertParamExists('apiFileV1DocumentsSignedUrlGet', 'path', path)
            const localVarPath = `/api/file/v1/documents/signedUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} type Document Type eg: export_files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1DocumentsTypeTotalGet: async (type: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('apiFileV1DocumentsTypeTotalGet', 'type', type)
            const localVarPath = `/api/file/v1/documents/{type}/total`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentsApi - functional programming interface
 * @export
 */
export const DocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {any} [ids] Document ids.
         * @param {any} [hashes] Document hashes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileV1DocumentsGet(ids?: any, hashes?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileV1DocumentsGet(ids, hashes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Document id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileV1DocumentsIdDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileV1DocumentsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Document id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileV1DocumentsIdDownloadGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileV1DocumentsIdDownloadGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Document id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileV1DocumentsIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileV1DocumentsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Document id.
         * @param {EditDocumentForm} document Document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileV1DocumentsIdPatch(id: string, document: EditDocumentForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileV1DocumentsIdPatch(id, document, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} docType document type eg: export_files
         * @param {string} docName documetn name eg: Angebot
         * @param {string} docPath document path eg: export_files/
         * @param {any} document document file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileV1DocumentsPost(docType: string, docName: string, docPath: string, document: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileV1DocumentsPost(docType, docName, docPath, document, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} path Document Path eg:test/50d3966b-6730-41ee-83a9-6fb77d43826e.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileV1DocumentsSignedUrlGet(path: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileV1DocumentsSignedUrlGet(path, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} type Document Type eg: export_files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileV1DocumentsTypeTotalGet(type: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileV1DocumentsTypeTotalGet(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentsApi - factory interface
 * @export
 */
export const DocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentsApiFp(configuration)
    return {
        /**
         * 
         * @param {any} [ids] Document ids.
         * @param {any} [hashes] Document hashes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1DocumentsGet(ids?: any, hashes?: any, options?: any): AxiosPromise<DocumentList> {
            return localVarFp.apiFileV1DocumentsGet(ids, hashes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Document id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1DocumentsIdDelete(id: string, options?: any): AxiosPromise<Document> {
            return localVarFp.apiFileV1DocumentsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Document id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1DocumentsIdDownloadGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiFileV1DocumentsIdDownloadGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Document id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1DocumentsIdGet(id: string, options?: any): AxiosPromise<Document> {
            return localVarFp.apiFileV1DocumentsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Document id.
         * @param {EditDocumentForm} document Document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1DocumentsIdPatch(id: string, document: EditDocumentForm, options?: any): AxiosPromise<Document> {
            return localVarFp.apiFileV1DocumentsIdPatch(id, document, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} docType document type eg: export_files
         * @param {string} docName documetn name eg: Angebot
         * @param {string} docPath document path eg: export_files/
         * @param {any} document document file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1DocumentsPost(docType: string, docName: string, docPath: string, document: any, options?: any): AxiosPromise<Document> {
            return localVarFp.apiFileV1DocumentsPost(docType, docName, docPath, document, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} path Document Path eg:test/50d3966b-6730-41ee-83a9-6fb77d43826e.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1DocumentsSignedUrlGet(path: string, options?: any): AxiosPromise<SignedUrl> {
            return localVarFp.apiFileV1DocumentsSignedUrlGet(path, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} type Document Type eg: export_files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1DocumentsTypeTotalGet(type: string, options?: any): AxiosPromise<TotalDocument> {
            return localVarFp.apiFileV1DocumentsTypeTotalGet(type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentsApi - object-oriented interface
 * @export
 * @class DocumentsApi
 * @extends {BaseAPI}
 */
export class DocumentsApi extends BaseAPI {
    /**
     * 
     * @param {any} [ids] Document ids.
     * @param {any} [hashes] Document hashes.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiFileV1DocumentsGet(ids?: any, hashes?: any, options?: any) {
        return DocumentsApiFp(this.configuration).apiFileV1DocumentsGet(ids, hashes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id Document id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiFileV1DocumentsIdDelete(id: string, options?: any) {
        return DocumentsApiFp(this.configuration).apiFileV1DocumentsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id Document id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiFileV1DocumentsIdDownloadGet(id: string, options?: any) {
        return DocumentsApiFp(this.configuration).apiFileV1DocumentsIdDownloadGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id Document id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiFileV1DocumentsIdGet(id: string, options?: any) {
        return DocumentsApiFp(this.configuration).apiFileV1DocumentsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id Document id.
     * @param {EditDocumentForm} document Document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiFileV1DocumentsIdPatch(id: string, document: EditDocumentForm, options?: any) {
        return DocumentsApiFp(this.configuration).apiFileV1DocumentsIdPatch(id, document, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} docType document type eg: export_files
     * @param {string} docName documetn name eg: Angebot
     * @param {string} docPath document path eg: export_files/
     * @param {any} document document file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiFileV1DocumentsPost(docType: string, docName: string, docPath: string, document: any, options?: any) {
        return DocumentsApiFp(this.configuration).apiFileV1DocumentsPost(docType, docName, docPath, document, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} path Document Path eg:test/50d3966b-6730-41ee-83a9-6fb77d43826e.png
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiFileV1DocumentsSignedUrlGet(path: string, options?: any) {
        return DocumentsApiFp(this.configuration).apiFileV1DocumentsSignedUrlGet(path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} type Document Type eg: export_files
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiFileV1DocumentsTypeTotalGet(type: string, options?: any) {
        return DocumentsApiFp(this.configuration).apiFileV1DocumentsTypeTotalGet(type, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ImagesApi - axios parameter creator
 * @export
 */
export const ImagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id Image id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1ImagesIdDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFileV1ImagesIdDelete', 'id', id)
            const localVarPath = `/api/file/v1/images/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Image id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1ImagesIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFileV1ImagesIdGet', 'id', id)
            const localVarPath = `/api/file/v1/images/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} path asset path eg: pool/12/logo
         * @param {any} image asset file eg: image.png
         * @param {string} [uploadByFileRealName] upload the file to S3 by the file real name instead a HASH eg: true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1ImagesPost: async (path: string, image: any, uploadByFileRealName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            assertParamExists('apiFileV1ImagesPost', 'path', path)
            // verify required parameter 'image' is not null or undefined
            assertParamExists('apiFileV1ImagesPost', 'image', image)
            const localVarPath = `/api/file/v1/images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (path !== undefined) { 
                localVarFormParams.append('path', path as any);
            }
    
            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
            if (uploadByFileRealName !== undefined) { 
                localVarFormParams.append('uploadByFileRealName', uploadByFileRealName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImagesApi - functional programming interface
 * @export
 */
export const ImagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id Image id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileV1ImagesIdDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Image>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileV1ImagesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Image id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileV1ImagesIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Image>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileV1ImagesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} path asset path eg: pool/12/logo
         * @param {any} image asset file eg: image.png
         * @param {string} [uploadByFileRealName] upload the file to S3 by the file real name instead a HASH eg: true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileV1ImagesPost(path: string, image: any, uploadByFileRealName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileV1ImagesPost(path, image, uploadByFileRealName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImagesApi - factory interface
 * @export
 */
export const ImagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImagesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id Image id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1ImagesIdDelete(id: string, options?: any): AxiosPromise<Image> {
            return localVarFp.apiFileV1ImagesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Image id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1ImagesIdGet(id: string, options?: any): AxiosPromise<Image> {
            return localVarFp.apiFileV1ImagesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} path asset path eg: pool/12/logo
         * @param {any} image asset file eg: image.png
         * @param {string} [uploadByFileRealName] upload the file to S3 by the file real name instead a HASH eg: true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileV1ImagesPost(path: string, image: any, uploadByFileRealName?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiFileV1ImagesPost(path, image, uploadByFileRealName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImagesApi - object-oriented interface
 * @export
 * @class ImagesApi
 * @extends {BaseAPI}
 */
export class ImagesApi extends BaseAPI {
    /**
     * 
     * @param {string} id Image id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public apiFileV1ImagesIdDelete(id: string, options?: any) {
        return ImagesApiFp(this.configuration).apiFileV1ImagesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id Image id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public apiFileV1ImagesIdGet(id: string, options?: any) {
        return ImagesApiFp(this.configuration).apiFileV1ImagesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} path asset path eg: pool/12/logo
     * @param {any} image asset file eg: image.png
     * @param {string} [uploadByFileRealName] upload the file to S3 by the file real name instead a HASH eg: true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public apiFileV1ImagesPost(path: string, image: any, uploadByFileRealName?: string, options?: any) {
        return ImagesApiFp(this.configuration).apiFileV1ImagesPost(path, image, uploadByFileRealName, options).then((request) => request(this.axios, this.basePath));
    }
}


