import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';

import {
  OfferListConfigurationResponseDto,
  OfferListConfigurationApi,
} from '@api/offerOrchestrator';
import { ApiQuery } from '@enums';
import { helpers } from '@helpers';

const offerInstance = new OfferListConfigurationApi(
  undefined,
  helpers.isWidget ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest` : ''
);

const useGetConfiguration = (
  offerGroupId: string,
  options: UseQueryOptions<OfferListConfigurationResponseDto, AxiosError> = {}
): UseQueryResult<OfferListConfigurationResponseDto, AxiosError> =>
  useQuery(
    [ApiQuery.OfferListConfigurationOfferOfferGroupsConfiguration, offerGroupId],
    () =>
      offerInstance
        .offerListConfigurationControllerGetOfferListConfiguration(offerGroupId)
        .then((res) => res.data),
    {
      keepPreviousData: false,
      retry: false,
      enabled: !!offerGroupId,
      refetchOnMount: false,
      ...options,
    }
  );

const configuration = {
  useGetConfiguration,
};

export default configuration;
