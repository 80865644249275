import React, { useState } from 'react';

import { Pagination } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { SkeletonPlaceholder } from '@developers/graphite/carbon-components';

import { KeyName, URLPart } from '@enums';
import { useMainState } from '@hooks';
import { inquiriesService } from '@services/consultation';
import { ReactRouterValidatedLink } from '@shared';

import QuestionModal from './QuestionModal';

interface Props {
  currentPage: URLPart;
  pageOrder: URLPart[];
}

const QuestionnaireStep: React.FC<Props> = ({ currentPage, pageOrder }) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);

  const { inquiryInfo, inquiryData } = useMainState();

  const { data: inquiry, isLoading: getInquiryIsLoading } = inquiriesService.useGetInquiry(
    inquiryData?.id.toString()
  );

  const questionnairePageUrl = `${URLPart.InquiryPage}/${inquiryInfo?.hash}`;

  const currentPageIsActive = currentPage === URLPart.InquiryPage;
  const currentPageIsCompleted =
    pageOrder.indexOf(currentPage) > pageOrder.indexOf(URLPart.InquiryPage);

  const userIsAllowedToEditQuestionnarie =
    currentPageIsCompleted && inquiry?.isRiskAssessmentCreatable;

  if (getInquiryIsLoading) {
    return (
      <div
        className="questionnaire-step__skeleton"
        data-qa="questionnaire-page-pagination-skeleton"
      >
        <div className="step-title">{t('steps.riskIdentification')}</div>
        <Pagination className="justify-content-center mb-0">
          <SkeletonPlaceholder className="link-pagination_skeleton" />
        </Pagination>
      </div>
    );
  }

  const isDisabled = !userIsAllowedToEditQuestionnarie && !currentPageIsActive;

  return (
    <>
      <div className="text-center step-title">
        {t('steps.riskIdentification')}{' '}
        {currentPageIsCompleted && (
          <>
            {userIsAllowedToEditQuestionnarie && (
              <span data-qa="questionnaire-page-link">
                <ReactRouterValidatedLink
                  url={questionnairePageUrl}
                  fallbackClassName="link-icon_skeleton"
                >
                  <FontAwesomeIcon icon={faPencilAlt} />
                </ReactRouterValidatedLink>{' '}
                |{' '}
              </span>
            )}
            <span
              onClick={() => setShowModal(true)}
              onKeyDown={(e) => e.key === KeyName.Enter && setShowModal(true)}
              tabIndex={0}
              role="button"
            >
              {' '}
              <FontAwesomeIcon icon={faEye} />
            </span>
          </>
        )}
      </div>
      <div className="text-center">
        <Pagination className="justify-content-center mb-0">
          <Pagination.Item
            active={currentPageIsActive}
            disabled={isDisabled}
            data-qa="questionnaire-pagination-item"
          >
            2
          </Pagination.Item>
        </Pagination>
      </div>
      <QuestionModal show={showModal} handleClose={handleCloseModal} />
    </>
  );
};

export default QuestionnaireStep;
