import React from 'react';

import { ValidatedLink, ValidatedLinkProps } from '@developers/graphite';
import { urlHelpers } from '@developers/toolbox';
import { Link } from 'react-router-dom';

interface Props extends Omit<ValidatedLinkProps, 'render'> {
  render?: ValidatedLinkProps['render'];
}

const defaultRender = (url: string, children: React.ReactNode, className?: string) => {
  if (urlHelpers.isAbsoluteUrl(url)) {
    return (
      <a data-qa="validated-link-link" href={url} className={className}>
        {children}
      </a>
    );
  }

  return (
    <Link data-qa="validated-link-link" to={url} className={className}>
      {children}
    </Link>
  );
};

const ReactRouterValidatedLink: React.FC<Props> = ({
  render = defaultRender,
  ...validatedLinkProps
}) => {
  return <ValidatedLink render={render} {...validatedLinkProps} />;
};

export default ReactRouterValidatedLink;
