import React from 'react';

import { useDispatch } from 'react-redux';
import {
  StructuredListRow,
  StructuredListCell,
  Button,
} from '@developers/graphite/carbon-components';
import { Download16 } from '@developers/graphite/carbon-icons';
import { useTranslation } from 'react-i18next';
import { browserHelpers } from '@developers/toolbox';

import { createZipFile } from '@store/documentsSlice';
import { AppDispatch } from '@store';
import { DocumentWithUrl } from '@api/offerOrchestrator';

type Props = {
  documentsCollection: DocumentWithUrl[];
};

const TariffDetailsDocuments: React.FC<Props> = ({ documentsCollection }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const downloadZip = async () => {
    const fileIds = documentsCollection.map((item) => item.fileId);
    const resultAction = await dispatch(createZipFile(fileIds));
    if (createZipFile.fulfilled.match(resultAction)) {
      browserHelpers.downloadFile(resultAction.payload.url);
    }
  };

  if (!documentsCollection) return null;
  return (
    <StructuredListRow head tabIndex={0}>
      <StructuredListCell head>
        <div>{t('result.documents')}</div>
        {documentsCollection.map((document) => {
          return (
            <div key={document.id}>
              <Button
                data-qa="download-file"
                kind="ghost"
                size="small"
                onClick={() => {
                  document.url && browserHelpers.openUrlInTheNewTab(document.url);
                }}
                renderIcon={Download16}
              >
                {document.title}
              </Button>
            </div>
          );
        })}
        <Button
          data-qa="download-all-files"
          kind="secondary"
          size="small"
          className="mt-3"
          renderIcon={Download16}
          onClick={downloadZip}
        >
          {t('general.downloadAll')}
        </Button>
      </StructuredListCell>
      <StructuredListCell head />
    </StructuredListRow>
  );
};

export default TariffDetailsDocuments;
