import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useFeature } from 'flagged';

import { FeatureFlag, URLPart } from '@enums';

import {
  LandingAndProductStep,
  QuestionnaireStep,
  ResultAndComparisonStep,
  BookingStep,
  OldQuestionnaireStep,
} from './Components';

const pageOrder = [
  URLPart.LandingPage,
  URLPart.ProductPage,
  URLPart.InquiryPage,
  URLPart.TenderPage,
  URLPart.ResultList,
  URLPart.ComparisonPage,
  URLPart.BookingPage,
];

const Steps: React.FC = () => {
  const location = useLocation();

  const currentPage =
    pageOrder.find((page) => location.pathname.includes(page)) || URLPart.LandingPage;

  const showNewQuestionnairePage = useFeature(FeatureFlag.useQuestionnairePage);

  return (
    <Row className="text-dark steps d-print-none">
      <Col xs={12} md={6} lg={3} className="text-center">
        <LandingAndProductStep currentPage={currentPage} pageOrder={pageOrder} />
      </Col>
      <Col xs={12} md={6} lg={3}>
        {showNewQuestionnairePage ? (
          <QuestionnaireStep currentPage={currentPage} pageOrder={pageOrder} />
        ) : (
          <OldQuestionnaireStep currentPage={currentPage} pageOrder={pageOrder} />
        )}
      </Col>
      <Col xs={12} md={6} lg={3} className="text-center">
        <ResultAndComparisonStep currentPage={currentPage} pageOrder={pageOrder} />
      </Col>
      <Col xs={12} md={6} lg={3} className="text-center">
        <BookingStep currentPage={currentPage} pageOrder={pageOrder} />
      </Col>
    </Row>
  );
};

export default Steps;
