import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { AxiosError } from 'axios';

import { ConsultationApi, Analysis, SaveAnalysis, QuestionPageList } from '@api/consultation';
import { ApiQuery } from '@enums';
import { helpers } from '@helpers';

const consultationInstance = new ConsultationApi(
  undefined,
  helpers.isWidget
    ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/consultation/v1`
    : '/api/consultation/v1'
);

const useRiskAnalyses = (
  consultationId: string,
  options: UseQueryOptions<Analysis, AxiosError> = {}
): UseQueryResult<Analysis, AxiosError> =>
  useQuery(
    [ApiQuery.RiskAnalyses, consultationId],
    () => consultationInstance.getRiskAnalysisRequest(consultationId).then((res) => res.data),
    { keepPreviousData: true, refetchOnMount: false, retry: false, ...options }
  );

const useUpdateRiskAnalyses = (
  consultationId: string
): UseMutationResult<void, AxiosError, SaveAnalysis> =>
  useMutation((data: SaveAnalysis) =>
    consultationInstance.updateRiskAnalysisRequest(consultationId, data).then((res) => res.data)
  );

const useRiskAnalysesQuestionPages = (
  consultationId: string,
  options: UseQueryOptions<QuestionPageList, AxiosError> = {}
): UseQueryResult<QuestionPageList, AxiosError> =>
  useQuery(
    [ApiQuery.RiskAnalysesQuestionPages, consultationId],
    () =>
      consultationInstance
        .questionPagesByConsultationIDRequest_1(consultationId)
        .then((res) => res.data),
    { keepPreviousData: true, refetchOnMount: false, ...options }
  );

const riskAnalyses = {
  useRiskAnalyses,
  useUpdateRiskAnalyses,
  useRiskAnalysesQuestionPages,
};

export default riskAnalyses;
