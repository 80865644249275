import React from 'react';

import { QuestionnaireMenuIndentationLevel } from '@developers/questionnaire-module';
import cx from 'classnames';

export interface MenuItemProps {
  title: string;
  icon?: JSX.Element;
  className?: string;
  titleClassName?: string;
  indentationLevel?: QuestionnaireMenuIndentationLevel | boolean;
  onClick?: () => void;
  onClickDisabled?: boolean;
  active?: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({
  title,
  icon,
  className = '',
  titleClassName = '',
  indentationLevel,
  onClick,
  onClickDisabled,
  active,
}) => {
  const preOnClick = () => {
    if (!onClickDisabled && onClick) onClick();
  };

  return (
    <div className={cx({ 'category-item__active': active })}>
      <button
        type="button"
        className={cx('menu-item__button', className, {
          [`menu-item__indent-${indentationLevel}`]: indentationLevel,
        })}
        onClick={preOnClick}
      >
        <div className="menu-item__content">
          {icon}
          <p
            className={cx('menu-item__title', {
              'menu-item__title--icon': icon,
              [titleClassName]: titleClassName,
            })}
          >
            {title}
          </p>
        </div>
      </button>
    </div>
  );
};

export default MenuItem;
