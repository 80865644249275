import React from 'react';

import { useTranslation } from 'react-i18next';
import { Document16 } from '@developers/graphite/carbon-icons';

const TenderingOfferTag: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="tag">
      <Document16 />
      {t('result.tenderingOfferTag')}
    </div>
  );
};

export default TenderingOfferTag;
