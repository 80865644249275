import { AxiosError } from 'axios';
import { useMutation, UseQueryOptions, UseMutationResult } from 'react-query';
import { authorizationApi } from '@developers/api-client-generator';

import { helpers } from '@helpers';

const brokerServiceInstance = new authorizationApi.BrokerApi(
  undefined,
  helpers.isWidget
    ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/authorization`
    : undefined
);

const useBrokerCodeAuthorizationRequest = (
  options: UseQueryOptions<authorizationApi.CompleteTokenHolder, AxiosError> = {}
): UseMutationResult<
  authorizationApi.CompleteTokenHolder,
  AxiosError,
  authorizationApi.CodeCredentialsHolder,
  unknown
> =>
  useMutation(
    ({ code, consultationHash, inquiryHash }: authorizationApi.CodeCredentialsHolder) =>
      brokerServiceInstance
        .brokerCodeAuthorizationRequest({
          code,
          consultationHash,
          inquiryHash,
        })
        .then((res) => res.data),
    { ...options }
  );

const broker = {
  useBrokerCodeAuthorizationRequest,
};

export default broker;
