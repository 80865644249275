import { consultationApi } from '@developers/api-client-generator';

import {
  BookingInputName,
  DocumentsType,
  KeyToOrderArrayBy,
  PaymentIntervalValue,
  PremiumPaymentIntervals,
} from '@enums';
import {
  Errors,
  AdditionalDto,
  ServerErrors,
  ControlledItem,
  PremiumCent,
} from '@typings/bookingSet';
import {
  CreateBookingSetDtoTypeEnum,
  FormFieldDto,
  FormInputDtoViewTypeEnum,
  FormOptionDto,
  FormValidationViolationDto,
  InquirySummaryDto,
  OfferDtoMethodEnum,
  SummaryResponseDto,
  UpdateInquiryDto,
} from '@api/booking';
import { ContractDocumentDto } from '@api/contractService';

import helpers from './helpers';

interface Props {
  field: FormFieldDto;
  serverErrors?: ServerErrors;
  inquiryHash: string;
  additionalData?: unknown;
}

interface CombineSelectTitleProps {
  premiumCents?: PremiumCent[];
  title: string;
  value: number;
}
export interface ReturnValue {
  clientErrorsRules: Errors;
  dynamicInvalidTextRootTranslation: string;
  dynamicInvalidTextCorrectExample: string;
  isInvalid: boolean;
  fieldName: string;
  labelTextRootTranslation: string;
  isControlled?: boolean;
  additionalDataByHash?: AdditionalDto;
  defaultDropdownOption?: FormOptionDto;
  isSingleFileUpload: boolean;
}

const generateBookingInputProperties = ({
  field,
  serverErrors,
  inquiryHash,
  additionalData,
}: Props): ReturnValue => {
  const controlledInputs = [BookingInputName.PaymentInterval, BookingInputName.PaymentMethod];
  // TMP: FE validation is not working for now because of BE concerns
  const clientErrorsRules = {} as Errors;
  if (field.input.constraints) {
    field.input.constraints.forEach((errorRule) => {
      clientErrorsRules[errorRule.name] = {
        value: errorRule.value,
        message: errorRule.expression,
      };
    });
  }

  const fieldName = `${inquiryHash}[${field.name}]`;
  const violation = serverErrors?.violations?.find(
    (item) => item.propertyPath === `${inquiryHash}.${field.name}`
  ) as FormValidationViolationDto;
  const serverError = violation?.rule;
  const dynamicInvalidTextRootTranslation = serverError && `bookingFormError.${serverError}`;
  const dynamicInvalidTextCorrectExample = violation?.propertyExample;
  const isInvalid = !!serverError;
  const isControlled = !!controlledInputs.find((i) => i === field.name);
  const additionalDataByHash = (additionalData as AdditionalDto[])?.find(
    (i) => i.inquiryHash === inquiryHash
  );
  const defaultDropdownOption = getDefaultDropdownOption(field);
  const labelTextRootTranslation =
    field.input.viewType === FormInputDtoViewTypeEnum.CheckBox
      ? `bookingFormLabels.${field.input.label}`
      : `bookingFormLabels.${field.name}`;

  const isSingleFileUpload = field.name === BookingInputName.LogoUpload;

  return {
    clientErrorsRules,
    dynamicInvalidTextRootTranslation,
    dynamicInvalidTextCorrectExample,
    isInvalid,
    fieldName,
    labelTextRootTranslation,
    isControlled,
    additionalDataByHash,
    defaultDropdownOption,
    isSingleFileUpload,
  };
};

// TODO: Remove this when BE stop using many attributes for the same thing (ie. value & defaultValue)
const getDefaultDropdownOption = (field: FormFieldDto): FormOptionDto | undefined => {
  if (!field.input.value || field.input.value === '')
    return field.input.options?.find((i) => i.value === field.input.defaultValue);
  return field.input.options?.find((i) => i.value === field.input.value);
};

const getUniqueInsurerNames = (
  paymentMethodsValues: ControlledItem[],
  additionalData: unknown
): string[] => {
  const newArrOfInsurerNames: string[] = [];
  paymentMethodsValues.forEach((paymentMethod) => {
    (additionalData as AdditionalDto[]).forEach((additionalDataElement) => {
      if (
        additionalDataElement.inquiryHash === paymentMethod.inquiryHash &&
        paymentMethod.value === OfferDtoMethodEnum.DirectDebit
      ) {
        const isExistedInsurerName = !!newArrOfInsurerNames.find(
          (i) => i === additionalDataElement.insurerNames?.name
        );
        if (!isExistedInsurerName && additionalDataElement.insurerNames?.name) {
          newArrOfInsurerNames.push(additionalDataElement.insurerNames.name);
        }
      }
    });
  });
  return newArrOfInsurerNames;
};

const getCombinedSelectLabel = ({
  premiumCents,
  title,
  value,
}: CombineSelectTitleProps): string => {
  const surchargeBlock = premiumCents?.find((i) => i.paymentIntervalValue === value)?.surcharge;
  return `${title} ${surchargeBlock ? `(+${surchargeBlock}%)` : ''}`;
};

const getInsurerNameByInquiryHash = (inquiryHash: string, additional: AdditionalDto[]): string => {
  if (!additional) return '';
  const additionalFoundInquiry = additional.find(
    (additionalItem) => additionalItem.inquiryHash === inquiryHash
  );
  return additionalFoundInquiry ? additionalFoundInquiry.insurerNames.name : '';
};

const getProductNameByInquiryHash = (inquiryHash: string, additional: AdditionalDto[]): string => {
  if (!additional) return '';
  const additionalFoundInquiry = additional.find(
    (additionalItem) => additionalItem.inquiryHash === inquiryHash
  );
  return additionalFoundInquiry ? additionalFoundInquiry.productName : '';
};

const isManyInquiries = (summaryData: SummaryResponseDto): boolean => {
  return summaryData.paymentDetails?.paymentInfo.length > 1;
};

const getInitialInquiries = (inquires: InquirySummaryDto[]): UpdateInquiryDto[] => {
  const initiallySelectedInquiries: UpdateInquiryDto[] = [];
  inquires.forEach((inquiry) => {
    initiallySelectedInquiries.push({
      inquiryHash: inquiry.inquiryHash,
      selected: inquiry.isSelected,
    });
  });
  return initiallySelectedInquiries;
};

const getSelectedMonthFrequencyBasedOn = (
  currentPaymentIntervalValue: string
): PremiumPaymentIntervals => {
  let selectedMonth = PremiumPaymentIntervals.Year;
  switch (currentPaymentIntervalValue) {
    case PaymentIntervalValue.Month:
      selectedMonth = PremiumPaymentIntervals.Month;
      break;
    case PaymentIntervalValue.Quarter:
      selectedMonth = PremiumPaymentIntervals.Quarter;
      break;
    case PaymentIntervalValue.HalfYear:
      selectedMonth = PremiumPaymentIntervals.HalfYear;
      break;
  }
  return selectedMonth;
};

const getPriceBasedOn = (
  currentPaymentIntervalValue: string,
  additionalDataByHash: AdditionalDto
): number => {
  return (
    additionalDataByHash.premiumCents.find(
      (premium) => premium.paymentIntervalValue === parseInt(currentPaymentIntervalValue, 10)
    )?.value || 0
  );
};

const handleInquiriesSelection = (
  item: UpdateInquiryDto,
  inquiries: UpdateInquiryDto[]
): UpdateInquiryDto[] => {
  const newInquiries = inquiries.map((i) => {
    if (i.inquiryHash === item.inquiryHash) {
      return item;
    }
    return i;
  });
  return newInquiries;
};

const checkIfThereAreSelectedInquiries = (inquiries: UpdateInquiryDto[]): boolean => {
  const selectedItem = inquiries.find((i) => i.selected === true);
  return !!selectedItem;
};

const getNotFullyFilledGroups = (
  questionAnswerSets: consultationApi.QuestionAnswerSet[]
): string[] => {
  const notFullyFilledGroups: string[] = [];
  questionAnswerSets.forEach((qaSet) => {
    // TMP: enums inconsistency is being checked with BE
    if (qaSet.fillingStatus !== consultationApi.QuestionAnswerSetFillingStatusEnum.Full) {
      qaSet.groups.forEach((group) => {
        if (group.fillingStatus !== consultationApi.QuestionAnswerSetGroupFillingStatusEnum.Full) {
          notFullyFilledGroups.push(group.id);
        }
      });
    }
  });
  return notFullyFilledGroups;
};

export const isOpenQuestionsExist = (categoriesVisibility: {
  [key: string]: { [key: string]: boolean };
}): boolean => {
  return Object.keys(categoriesVisibility).some((categoryKey: string) => {
    return Object.keys(categoriesVisibility[categoryKey]).some((questionKey: string) => {
      return categoriesVisibility[categoryKey][questionKey];
    });
  });
};

const orderDocumentsRegardingFlow = (
  documentsArr: ContractDocumentDto[],
  flow: CreateBookingSetDtoTypeEnum
): ContractDocumentDto[] => {
  let documentsOrder: DocumentsType[] = [];
  switch (flow) {
    case CreateBookingSetDtoTypeEnum.Proposal: {
      documentsOrder = [
        DocumentsType.ProposalDocument,
        DocumentsType.ProposalOrderDocument,
        DocumentsType.ProposalConsultationDocument,
        DocumentsType.RiskAnalysisDocument,
        DocumentsType.BrokerMandateDocument,
        DocumentsType.FurtherBookingDocument,
        DocumentsType.CustomerBrokerageAgreementDocument,
        DocumentsType.ExternalConsultationProtocollDocument,
        DocumentsType.InsurerDocument,
        DocumentsType.InsurerAttachmentsDocument,
      ];
      break;
    }
    case CreateBookingSetDtoTypeEnum.CoverNote:
      documentsOrder = [
        DocumentsType.OrderDocument,
        DocumentsType.ConsultationDocument,
        DocumentsType.RiskAnalysisDocument,
        DocumentsType.BrokerMandateDocument,
        DocumentsType.FurtherBookingDocument,
        DocumentsType.CustomerBrokerageAgreementDocument,
        DocumentsType.ExternalConsultationProtocollDocument,
        DocumentsType.InsurerDocument,
        DocumentsType.InsurerAttachmentsDocument,
      ];
      break;
  }

  return helpers.orderElementsInArrBySpecificValue<ContractDocumentDto>(
    documentsArr,
    KeyToOrderArrayBy.Type,
    documentsOrder
  );
};

const bookingHelpers = {
  generateBookingInputProperties,
  getUniqueInsurerNames,
  getCombinedSelectLabel,
  isManyInquiries,
  getInitialInquiries,
  handleInquiriesSelection,
  checkIfThereAreSelectedInquiries,
  getInsurerNameByInquiryHash,
  getProductNameByInquiryHash,
  getNotFullyFilledGroups,
  isOpenQuestionsExist,
  orderDocumentsRegardingFlow,
  getSelectedMonthFrequencyBasedOn,
  getPriceBasedOn,
};

export default bookingHelpers;
