import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ToastNotification,
  DropdownSkeleton,
  Accordion,
} from '@developers/graphite/carbon-components';
import { GdsAccordionItem } from '@developers/graphite';
import { useWindowSize } from '@developers/toolbox';

import MenuItem from '@pages/Questionnaire/Questionnaire/Components/Menu/MenuItem';
import { Customer, LegalForm, TenderRequest } from '@api/consultation';
import { ReactComponent as Diamond } from '@assets/images/questionnaire-icons/diamond.svg';

import { CustomerInfoForm } from '..';

const menuRefPadding = 16;

type Props = {
  dataTenderRequest: TenderRequest | undefined;
  consultationId: string;
  setShowQuestionnaire: (arg: boolean) => void;
  setShowCancelTenderModal: (arg: boolean) => void;
  isLoadingData: boolean;
  isLoadingInquiries: boolean;
  offerAvailable: boolean;
};

const CustomerInfoSection: React.FC<Props> = ({
  dataTenderRequest,
  consultationId,
  setShowQuestionnaire,
  setShowCancelTenderModal,
  isLoadingData,
  offerAvailable,
  isLoadingInquiries,
}) => {
  const headerElementHeight =
    (document.getElementById('questionnaire-header')?.offsetHeight || 0) + menuRefPadding;
  const { t } = useTranslation();
  const formMethods = useForm();
  const { isSmall } = useWindowSize();

  const renderMenuContent = () => (
    <MenuItem
      title={t('bookingFormTitle.customerInformation')}
      titleClassName="customer-info_menu-item"
      className="tender-page__customer-info tender-page__customer-info__custom-menu"
      icon={<Diamond className="icon-status__active" />}
    />
  );

  return (
    <div className="questionnaire__content">
      {isSmall ? (
        <div className="tender-request-menu">
          <div className="menu">
            <Accordion size="sm">
              <GdsAccordionItem title="Menu" titleClassName="qaset-item__title">
                {renderMenuContent()}
              </GdsAccordionItem>
            </Accordion>
          </div>
        </div>
      ) : (
        <div className="questionnaire__left-column">
          <div
            className="questionnaire__section questionnaire__menu customer-info__menu"
            style={{ top: `${headerElementHeight}px` }}
          >
            <div>
              <ul>{renderMenuContent()}</ul>
            </div>
          </div>
        </div>
      )}

      <div className="questionnaire__right-column">
        <div className="questionnaire__section questionnaire__question-area">
          <div className="questionnaire-area bx--grid">
            <div className="bx-row">
              <div className="bx--col-lg-9 pl-0">
                {isLoadingInquiries ? (
                  <DropdownSkeleton className="customer-info-section__skeleton" />
                ) : (
                  <ToastNotification
                    lowContrast
                    className="customer-info-section__notification"
                    kind="info"
                    iconDescription="describes the close button"
                    subtitle={
                      <span>
                        {offerAvailable
                          ? t('toastInfoNotification.manualTenderSubtitle')
                          : t('toastInfoNotification.subtitle')}
                      </span>
                    }
                    timeout={0}
                    title={`${t('toastInfoNotification.title')}`}
                  />
                )}
              </div>
            </div>
            <div className="bx--row">
              <div className="bx--col-lg-9">
                <p className="questionnaire-area__title ">
                  {t('bookingFormTitle.customerInformation')}
                </p>
                <FormProvider {...formMethods}>
                  <CustomerInfoForm
                    customerInfo={dataTenderRequest?.customer as Customer}
                    legalForm={dataTenderRequest?.legalForms as LegalForm[]}
                    consultationId={consultationId}
                    setShowQuestionnaire={setShowQuestionnaire}
                    setShowCancelTenderModal={setShowCancelTenderModal}
                    isLoadingData={isLoadingData}
                  />
                </FormProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerInfoSection;
