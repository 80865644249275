import React from 'react';

import classNames from 'classnames';

type Props = {
  className?: string;
  id?: string;
  children: React.ReactNode;
};

const BootstrapWrapper: React.FC<Props> = ({ children, className, id }) => (
  <div className={classNames('ts', className)} id={id}>
    {children}
  </div>
);

export default BootstrapWrapper;
