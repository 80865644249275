import React from 'react';

import { Pagination } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { useFeature } from 'flagged';
import { useTranslation } from 'react-i18next';

import { FeatureFlag, URLPart } from '@enums';
import { useGlobalState, useMainState } from '@hooks';
import { ReactRouterValidatedLink } from '@shared';

interface Props {
  currentPage: URLPart;
  pageOrder: URLPart[];
}

const ResultAndComparisonStep: React.FC<Props> = ({ currentPage, pageOrder }) => {
  const { t } = useTranslation();

  const showResultPage = useFeature(FeatureFlag.useResultPage);
  const showQuestionnairePage = useFeature(FeatureFlag.useQuestionnairePage);

  const currentPageIsActive =
    currentPage === URLPart.ResultList || currentPage === URLPart.ComparisonPage;
  const currentPageIsCompleted =
    pageOrder.indexOf(currentPage) > pageOrder.indexOf(URLPart.ComparisonPage);

  const { frontendSettings } = useGlobalState();
  const { inquiryInfo } = useMainState();

  const resultPageUrl = showResultPage
    ? `${URLPart.ResultList}/${inquiryInfo?.hash}`
    : `${frontendSettings.baseUrl}${URLPart.ResultList}/${inquiryInfo?.hash}`;

  const isDisabled = !currentPageIsCompleted && !currentPageIsActive;

  return (
    <>
      <div className="step-title">
        {t('steps.result')}{' '}
        {(currentPageIsCompleted || currentPage === URLPart.ComparisonPage) && (
          <ReactRouterValidatedLink url={resultPageUrl} fallbackClassName="link-icon_skeleton">
            <FontAwesomeIcon icon={faEye} />
          </ReactRouterValidatedLink>
        )}
      </div>
      <div>
        <Pagination className="justify-content-center mb-0">
          <Pagination.Item active={currentPageIsActive} disabled={isDisabled}>
            {showQuestionnairePage ? 3 : 5}
          </Pagination.Item>
        </Pagination>
      </div>
    </>
  );
};

export default ResultAndComparisonStep;
