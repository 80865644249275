import React from 'react';

import { Checkmark16 } from '@developers/graphite/carbon-icons';

const addCheckmarksToHtmlList = (textHtml: string): React.ReactNode => {
  const splitedText = textHtml?.split('<li>');
  return splitedText.map((el, index) => {
    if (index === 0) {
      return null;
    }
    return (
      // eslint-disable-next-line react/no-array-index-key
      <div key={index} className="product-details-check-wrapper">
        <Checkmark16 className="product-details-checkmark" />
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `<li>${el}` || '',
          }}
        />
      </div>
    );
  });
};

export default addCheckmarksToHtmlList;
