import React, { useContext } from 'react';

import { once } from 'lodash';

import { TokenDetails } from '@typings/tokenDetails';
import { StateDisplayName } from '@enums';

import { FrontendSettings } from './queries/useFrontendSettings';
import { Sessions } from './queries/useCheckSession';
import { ContactInfo } from './queries/useContactInfo';
import { BrokerSettings } from './queries/useBrokerSettings';
import { Settings } from './queries/useSettings';

export interface GlobalState {
  readonly frontendSettings: FrontendSettings;
  readonly sessions: Sessions;
  readonly contactInfo?: ContactInfo;
  readonly brokerSettings: BrokerSettings;
  readonly tokenDetails?: TokenDetails;
  readonly settings?: Settings;
}

const createGlobalStateContext = once(() => React.createContext({} as GlobalState));

export const useGlobalState = (): GlobalState => useContext(createGlobalStateContext());

export const GlobalStateContext = createGlobalStateContext();

export const GlobalStateContextProvider: React.FC<GlobalState> = ({ children, ...globalState }) => {
  GlobalStateContext.displayName = StateDisplayName.global;
  return <GlobalStateContext.Provider value={globalState}>{children}</GlobalStateContext.Provider>;
};
