import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Button,
  ComposedModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@developers/graphite/carbon-components';

import { helpers } from '@helpers';

type Props = {
  accountTag: string;
};

const RedirectToAdvisoryModal: React.FC<Props> = ({ accountTag }) => {
  const { t } = useTranslation();
  const { envVariables } = helpers;

  const redirectToAdvisory = () => {
    const url = envVariables.REACT_APP_ADVISORY_BASE_URL.replace('{{accountTag}}', accountTag);
    window.location.href = url;
  };

  const redirectTimeout = Number(envVariables.REACT_APP_ADVISORY_REDIRECT_TIMEOUT);

  useEffect(() => {
    // INFO Redirect to AS automatically after 5s
    setTimeout(() => {
      redirectToAdvisory();
    }, redirectTimeout);
  });

  return (
    <ComposedModal
      open={true}
      preventCloseOnClickOutside={true}
      size="md"
      onClose={() => false}
      containerClassName="redirect-to-advisory-modal"
    >
      <ModalHeader className="font-weight-bold h5">
        <h5>{t('redirectToAdvisoryModal.title')}</h5>
      </ModalHeader>
      <ModalBody>
        <p>{t('redirectToAdvisoryModal.content')}</p>
      </ModalBody>
      <ModalFooter>
        <Button onClick={redirectToAdvisory} kind="primary" data-qa="redirect-to-advisory-modal">
          {t('redirectToAdvisoryModal.button')}
        </Button>
      </ModalFooter>
    </ComposedModal>
  );
};

export default RedirectToAdvisoryModal;
