import React from 'react';

import { BootingWrapper } from '@shared';

import { MainWrapper, MainContent } from './components';

type Props = {
  children: React.ReactNode;
  pageClassName?: string;
  headerClassName?: string;
  id?: string;
};

const Main: React.FC<Props> = (props) => {
  const { children, pageClassName, headerClassName, id } = props;

  return (
    <BootingWrapper>
      <MainWrapper>
        <MainContent pageClassName={pageClassName} headerClassName={headerClassName} id={id}>
          {children}
        </MainContent>
      </MainWrapper>
    </BootingWrapper>
  );
};

export default Main;
