import React, { useState } from 'react';

import {
  TextInput,
  TextArea,
  NumberInput,
  DatePicker,
  DatePickerInput,
  DatePickerProps,
  Dropdown,
} from '@developers/graphite/carbon-components';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { dateHelpers } from '@developers/toolbox';

import useStore from '@pages/Result/zustand';
import { configuration } from '@services/offer';
import { inquiriesService } from '@services/consultation';
import { helpers } from '@helpers';
import { DiscountType } from '@enums';
import { useMainState } from '@hooks';

interface Props {
  currency: string;
  gross: string;
  id: string;
}

interface DropdownItemProp {
  id: number;
  text: string;
}

const OwnDiscountContent: React.FC<Props> = ({ currency, id, gross }) => {
  const { t } = useTranslation();
  const state = useStore();
  const { control } = useFormContext();
  const { inquiryData } = useMainState();

  const calculateDiscountValue = (value: number) => {
    const numVal1 = parseInt(gross, 10) / 100;
    const numVal2 = value / 100;
    const totalValue = numVal1 - numVal1 * numVal2;
    return totalValue.toFixed(2);
  };
  // eslint-disable-next-line react/no-danger
  const formatedCurrency = <span dangerouslySetInnerHTML={{ __html: `&#${currency};` }} />; // TODO remove dangerouslySetInnerHTML

  const bookingDiscountItem: DropdownItemProp = {
    id: 1,
    text: t('discountModal.specialDiscount'),
  };

  const targetDiscountItem: DropdownItemProp = {
    id: 2,
    text: t('discountModal.targetPremium'),
  };

  const { data: offerGroupDetails } = inquiriesService.useGetOfferGroup(inquiryData?.id.toString());
  const { data: configurationData } = configuration.useGetConfiguration(
    offerGroupDetails?.id as string
  );
  const configurationSettings = configurationData?.settings;

  const [chosenType, chooseType] = useState<DropdownItemProp>(
    state.discountType === DiscountType.BookingDiscount ? bookingDiscountItem : targetDiscountItem
  );
  const [coordinatedValidation, setCoordinatedValidation] = useState(false);
  const [dateValidation, setDateValidation] = useState(false);

  const onItemSelected = (item: DropdownItemProp) => {
    chooseType(item as DropdownItemProp);
    if (item.id === targetDiscountItem.id) {
      state.setDiscountType(DiscountType.TargetDiscount);
    } else if (item.id === bookingDiscountItem.id) {
      state.setDiscountType(DiscountType.BookingDiscount);
    }
  };

  const activeDropdownItems = [];
  if (!configurationSettings?.hideBookingDiscount) {
    activeDropdownItems.push(bookingDiscountItem);
  }
  if (!configurationSettings?.hideTargetPremium) {
    activeDropdownItems.push(targetDiscountItem);
  }

  return (
    <div className="result-own-discount">
      <p>{t('discountModal.ownDiscountDesc')}</p>
      <div className="bx--row">
        <div className="bx--col-md-6">
          <div className="bx--row mb-6 mt-5 first-row">
            <div className="bx--col-md-4">
              <Dropdown
                ariaLabel="art-dropdown"
                id="sort-id"
                label={`${t('discountModal.type')}`}
                titleText={`${t('discountModal.type')}`}
                items={activeDropdownItems}
                itemToString={(item) => (item ? item.text : '')}
                onChange={(item) => {
                  onItemSelected(item.selectedItem as DropdownItemProp);
                }}
                initialSelectedItem={chosenType}
                data-qa="discount-dropdown"
              />
            </div>
            <div className="bx--col-md-4">
              {chosenType.id === 1 ? (
                <NumberInput
                  id="result-own-discount-number-input-percent"
                  label={`${t('result.discount')} (${t('general.in')} %)`}
                  invalidText={`${t('discountModal.invalidMessage')}`}
                  max={100}
                  min={0}
                  step={5}
                  value={state.discount ? state.discount : 0}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement>
                  ) => {
                    state.setDiscount(
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (e as any).imaginaryTarget.value === ''
                        ? 0 // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        : parseInt((e as any).imaginaryTarget.value, 10)
                    );
                  }}
                />
              ) : (
                <NumberInput
                  id="result-own-discount-number-input-currency"
                  label={`${t('discountModal.targetPremium')} (${t('general.in')} €)`}
                  invalidText={`${t('discountModal.invalidMessage')}`}
                  max={10000000}
                  min={0}
                  step={1}
                  // eslint-disable-next-line use-isnan
                  value={state.price}
                  defaultValue={state.price}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement>
                  ) => {
                    state.setPrice(
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (e as any).imaginaryTarget.value === ''
                        ? 0 // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        : parseFloat((e as any).imaginaryTarget.value.replace(/,/g, '.'))
                    );
                  }}
                />
              )}
            </div>
          </div>
          <div className="bx--row mb-6">
            <div className="bx--col-md-4">
              <Controller
                name="coordinated"
                control={control}
                defaultValue=""
                value={state.coordinatedWith}
                render={({ onChange, value }) => (
                  <TextInput
                    id={`own-discount-text-input-${id}`}
                    labelText={`${t('discountModal.representLabel')}`}
                    placeholder={`${t('discountModal.representPlaceholder')}`}
                    onChange={(e) => {
                      onChange(value);
                      state.setCoordinatedWith(e.target.value);
                    }}
                    onBlur={() => setCoordinatedValidation(state.coordinatedWith.length <= 5)}
                    value={state.coordinatedWith}
                    invalid={coordinatedValidation}
                    invalidText={`${t('discountModal.cordinateFieldInvalidMessage')}`}
                  />
                )}
              />
            </div>
            <div className="bx--col-md-4">
              <Controller
                name="date"
                control={control}
                defaultValue={state.agreementDate}
                value={state.agreementDate}
                render={({ onChange, value }) => (
                  <DatePicker
                    datePickerType="single"
                    locale={helpers.getLocale() as DatePickerProps['locale']}
                    dateFormat={dateHelpers.getDatePickerLocalDateFormat()}
                    onChange={(_, currentDateString) => {
                      onChange(currentDateString);
                      state.setAgreementDate(currentDateString);
                      setDateValidation(currentDateString === '');
                    }}
                    value={state.agreementDate}
                    maxDate={new Date().toLocaleDateString()}
                  >
                    <DatePickerInput
                      labelText={`${t('general.date')}`}
                      placeholder={`${t('discountModal.datePlaceholder')}`}
                      id="pra-modal-broker-info-date"
                      onBlur={() => setDateValidation(state.agreementDate === '')}
                      invalid={dateValidation}
                      invalidText={`${t('discountModal.dateFieldInvalidMessage')}`}
                    />
                  </DatePicker>
                )}
              />
            </div>
          </div>
          <div>
            <Controller
              name="comment"
              control={control}
              defaultValue=""
              render={({ onChange, value }) => (
                <TextArea
                  id="own-discount-textarea"
                  labelText={`${t('discountModal.comment')} (${t('general.optional')})`}
                  placeholder={`${t('discountModal.comment')}`}
                  rows={4}
                  value={state.comment}
                  onChange={(e) => {
                    onChange(value);
                    state.setComment(e.target.value);
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className="bx--col-md-2 pt-9">
          {chosenType.id === 1 && (
            <div className="result-own-discount__price">
              {/* TODO: Add price changes when NumberInput is changed  */}
              {t('discountModal.premium')}: {calculateDiscountValue(state.discount)}
              {formatedCurrency}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OwnDiscountContent;
