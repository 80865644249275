import numeral from 'numeral';

// extracts decimal part from a string. ex. '1000' => 10, '12345' => 123.45, '' => '', undefined => '', null => ''
export const convertFromCents = (
  value?: string | number | null
): '' | number => {
  const valueAsIntegerer =
    typeof value === 'string' ? parseInt(value, 10) : (value as number);

  if ((!value && value !== 0) || isNaN(valueAsIntegerer)) {
    return '';
  }

  const integerPart = valueAsIntegerer / 100;

  const decimalPart = value.toString().slice(-2);

  const floatValue = parseFloat(`${integerPart}.${decimalPart}`);

  return floatValue;
};

type FormatCurrencyOptions = {
  decimalScale?: number;
  compact?: boolean;
  showCurrencySymbol?: boolean;
  currencySymbol?: string;
  numberIsInCents?: boolean;
};

export const formatCurrency = (
  number: number,
  options: FormatCurrencyOptions = {}
): string => {
  const defaultOptions = {
    decimalScale: 0,
    compact: false,
    showCurrencySymbol: false,
    currencySymbol: '€',
    numberIsInCents: true,
  };

  const combinedOptions = { ...defaultOptions, ...options };

  const numberToFormat = combinedOptions.numberIsInCents
    ? convertFromCents(number)
    : number;

  const formatTemplateParts = ['0,0'];

  if (combinedOptions.decimalScale) {
    formatTemplateParts.push('.');
    formatTemplateParts.push('0'.repeat(combinedOptions.decimalScale));
  }

  if (combinedOptions.compact) {
    formatTemplateParts.push(' a');
  }

  const formatTemplate = formatTemplateParts.join('');
  const formattedNumber = numeral(numberToFormat).format(formatTemplate);

  if (combinedOptions.showCurrencySymbol) {
    return `${formattedNumber} ${combinedOptions.currencySymbol}`;
  }
  return formattedNumber;
};

export const getLocalizedDecimalSeparator = (): string => {
  const num = 1.1;
  return formatCurrency(num, {
    decimalScale: 1,
    numberIsInCents: false,
  }).substring(1, 2);
};

export const getLocalizedThousandSeparator = (): string => {
  const num = 1111;
  return formatCurrency(num, {
    decimalScale: 1,
    numberIsInCents: false,
  }).substring(1, 2);
};
