import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  UseMutationResult,
} from 'react-query';
import { AxiosError } from 'axios';

import { OfferDetailDto, OfferApi } from '@api/offerOrchestrator';
import { InquiryApi } from '@api/consultation';
import { ApiQuery } from '@enums';
import { API, helpers } from '@helpers';
import { GetQueryOffersParams, FormattedResult } from '@typings/resultPage';
import useStore from '@pages/Result/zustand';

const offerApiInstance = new OfferApi(
  undefined,
  helpers.isWidget ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest` : ''
);
const bookingInstance = new InquiryApi(
  undefined,
  helpers.isWidget
    ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/consultation/v1`
    : '/api/consultation/v1'
);

const useGetOffers = (
  offerGroupId: string,
  params: GetQueryOffersParams,
  loadData: boolean,
  options: UseQueryOptions<FormattedResult, AxiosError> = {}
): UseQueryResult<FormattedResult, AxiosError> => {
  const store = useStore();
  const { setResultTable } = store;
  return useQuery(
    [ApiQuery.Offer, offerGroupId, params, loadData],
    async () => {
      const response = await API.get<FormattedResult>(
        helpers.isWidget
          ? `${helpers.envVariables.REACT_APP_API_CONTROLLER}/rest/api/offer/v1/offer-groups/${offerGroupId}/offers`
          : `/api/offer/v1/offer-groups/${offerGroupId}/offers`,
        params
      );

      setResultTable(response);
      return response;
    },
    { retry: true, enabled: loadData, ...options }
  );
};

const useGetOfferDetails = (
  offerGroupId: string,
  offerId: string,
  options: UseQueryOptions<OfferDetailDto, AxiosError> = {}
): UseQueryResult<OfferDetailDto, AxiosError> =>
  useQuery(
    [ApiQuery.OfferInsurersData, offerGroupId, offerId],
    () =>
      offerApiInstance
        .offerGroupControllerGetOfferDetails(offerGroupId, offerId)
        .then((res) => res.data),
    { keepPreviousData: true, refetchOnMount: false, retry: false, ...options }
  );

const useSetSelectedOfferForBooking = (): UseMutationResult<
  void,
  AxiosError,
  {
    inquiryId: string;
    offerId: string;
  }
> =>
  useMutation((data: { inquiryId: string; offerId: string }) =>
    bookingInstance
      .setSelectedOfferByInquiryIDRequest(data.inquiryId, data.offerId)
      .then((res) => res.data)
  );

const offerServices = {
  useGetOfferDetails,
  useGetOffers,
  useSetSelectedOfferForBooking,
};

export default offerServices;
