import { useEffect, useState } from 'react';

import { GetQueryOffersParams } from '@typings/resultPage';
import useStore from '@pages/Result/zustand';

type Params = {
  params: GetQueryOffersParams;
  paramsCreated: boolean;
};

const useSetActiveParams = (initParams: GetQueryOffersParams, loadData: boolean): Params => {
  const [params, setParams] = useState<GetQueryOffersParams>({} as GetQueryOffersParams);
  const [paramsCreated, setParamsCreated] = useState(false);
  const store = useStore();
  const { activeFilters } = store;
  const filterParams: GetQueryOffersParams = { ...initParams };

  useEffect(() => {
    setParamsCreated(false);
    activeFilters.forEach((el) => {
      filterParams[el.key] = el.value;
    });
    if (loadData) {
      setParams(filterParams);
      setParamsCreated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData, activeFilters]);

  return { params, paramsCreated };
};

export default useSetActiveParams;
