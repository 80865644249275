import { useEffect } from 'react';

import { ConfigAppliedFilters } from '@typings/resultPage';
import { FilterKeyParts, FilterValues, OfferStatus, SortingKeyParts } from '@enums';
import useStore from '@pages/Result/zustand';
import { OfferListConfigurationResponseDto, Filter, FilterOptions } from '@api/offerOrchestrator';

const useSetDefaultActiveFilters = (
  setDefaultActiveFilterCreated: (value: boolean) => void,
  resultConfiguration?: OfferListConfigurationResponseDto
): void => {
  const store = useStore();
  const { setActiveFilters } = store;

  const setDefaultActiveFilters = () => {
    if (resultConfiguration) {
      const { filters, appliedFilters, appliedSorting, showBestOfferPerTariffOnly } =
        resultConfiguration;
      const defaultActiveFilters: ConfigAppliedFilters[] = [];

      const checkIsDefaultActiveFiltersAlreadyContainsFilter = (filterKey: string) => {
        return defaultActiveFilters.filter((e) => e.key === filterKey).length === 0;
      };

      const checkIsFilterValueAll = (filterValue: string) => {
        return filterValue === FilterValues.All;
      };

      const chooseFilterKey = (filter: Filter) => {
        return filter.comparisonOperator === null
          ? `${FilterKeyParts.FilterFeatures}[${filter.featureId}]${FilterKeyParts.FilterValue}`
          : `${FilterKeyParts.FilterFeatures}[${filter.featureId}]${FilterKeyParts.FilterValue}[${filter.comparisonOperator}]`;
      };

      const shouldFilterBeApplied = (filterOptions: FilterOptions, filter: Filter) => {
        return (
          filterOptions.selected &&
          !checkIsFilterValueAll(filterOptions.value) &&
          checkIsDefaultActiveFiltersAlreadyContainsFilter(chooseFilterKey(filter))
        );
      };

      const setPreFiledFilters = () => {
        filters.forEach((filter) => {
          filter.options?.forEach((filterOptions) => {
            if (shouldFilterBeApplied(filterOptions, filter)) {
              defaultActiveFilters.push({
                key: chooseFilterKey(filter),
                value: filterOptions.value,
              });
            }
          });
        });
      };

      const setReleasedFilter = () => {
        defaultActiveFilters.push({
          key: `${FilterKeyParts.FilterStatus}`,
          value: OfferStatus.Released,
        });
      };

      if (appliedFilters && appliedSorting) {
        const setAppliedFilters = () => {
          appliedFilters.forEach((filter: ConfigAppliedFilters) => {
            defaultActiveFilters.push({
              key: `${FilterKeyParts.FilterPrefix}${filter.key}`,
              value: filter.value,
            });
          });
        };

        setPreFiledFilters();
        setReleasedFilter();

        const setAppliedSorting = () => {
          defaultActiveFilters.push({
            key: `${SortingKeyParts.SortingPrefix}[${appliedSorting.code}]`,
            value: appliedSorting.order,
          });
        };

        const setShowBestOfferPerTariffOnly = () => {
          defaultActiveFilters.push({
            key: FilterValues.ShowBestOfferPerTariffOnly,
            value: showBestOfferPerTariffOnly,
          });
        };

        setAppliedFilters();
        appliedSorting && setAppliedSorting();
        setShowBestOfferPerTariffOnly();

        if (defaultActiveFilters) {
          setActiveFilters(defaultActiveFilters);
          setDefaultActiveFilterCreated(true);
        }
      }
    }
  };

  useEffect(() => {
    if (resultConfiguration) {
      setDefaultActiveFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultConfiguration]);
};

export default useSetDefaultActiveFilters;
