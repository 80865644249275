let currentLocale = 'de';

export const setupDateHelpers = (locale: string): void => {
  currentLocale = locale;
};

export function getLocalDateFormat(): string {
  const formats: { [key: string]: string } = {
    de: 'dd.MM.yyyy',
    en: 'M/d/yyyy',
    fr: 'dd/MM/yyyy',
  };

  return formats[currentLocale] || 'M/d/yyyy';
}

export function getLocalDateTimeFormat(): string {
  return `${getLocalDateFormat()} HH:mm`;
}

export function getDatePickerLocalDateFormat(): string {
  const formats: { [key: string]: string } = {
    de: 'd.m.Y',
    en: 'n/j/Y',
    fr: 'd/m/Y',
  };

  return formats[currentLocale] || 'n/j/Y';
}
